import React, { useState, useEffect } from "react";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { useLocation, useNavigate } from "react-router-dom";
import CustomHeader from "../components/CustomHeader";
import {
  createTenant,
  editTenant,
  getSubscriptions,
} from "../api/YTPAdminServices";
import { useSelector } from "react-redux";
import { ConsoleError } from "../utils/ErrorUtils";
import { ERROR_MESSAGE } from "../constants/MessagesConstants";
import { CustomAlert } from "../components/customAlert";
import Swal from "sweetalert2";
import { cognitoSignOut } from "../api/CognitoServices";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import uuid from "react-uuid";

function YTPAdminAddTenant() {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tenantData, setTenantData] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    tenant_name: "",
    // encryption_key: "",
    tenant_email: "",
    subscriptionType: "",
    tenant_logo: null,
    super_admin_name: "",
    super_admin_email: "",
    super_admin_phone: "",
  });
  // const [isPlanActive, setIsPlanActive] = useState(false);
  // const [encryptionKey, setEncryptionKey] = useState("");
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);

  useEffect(() => {
    const state = location.state || {};
    if (state.mode === "editTenant") {
      setIsEditing(true);
      setTenantData(state.tenantData || {});
      setFormValues({
        tenant_id: state.tenantData?.tenant_id || "",
        tenant_name: state.tenantData?.tenant_name || "",
        tenant_email: state.tenantData?.tenant_email || "",
        tenant_logo: state.tenantData?.tenant_logo || {},
        subscriptionType:
          state.tenantData?.subscription?.subscription_type_id || "",
        // encryption_key: state.tenantData?.encryption_key || "",
      });
    } else {
      setIsEditing(false);
      setTenantData(null);
    }
  }, [location]);
  const handleDownload = async () => {
    const toast = Swal.fire({
      title: "Please wait",
      html: "Your file is being downloaded...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const url = tenantData?.tenant_logo?.logo_url;
    const name = tenantData?.tenant_logo?.fileName;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to download the file");
      }
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.close();
      Swal.fire("Success!", "Your file has been downloaded.", "success");
    } catch (error) {
      toast.close();
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Download Failed",
        text: "Unable to download the file. Please try again later.",
      });
    }
  };

  useEffect(() => {
    fetchSubscriptionData(1, 10); // Fetch data on component mount
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Define character limits for specific fields
    const charLimits = {
      tenant_name: 50,
      tenant_email: 100,
      super_admin_name: 50,
      super_admin_email: 100,
    };
  
    if (charLimits[name] && value.length > charLimits[name]) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: `Maximum ${charLimits[name]} characters allowed`,
      }));
      return; // Stop processing if limit exceeded
    }
  
    setFormValues((prev) => ({ ...prev, [name]: value }));
    if (formErrors?.[name]) {
      setFormErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };
  

  const handleAddDocument = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        const docSrcArray = reader.result.split(";")[0];
        let val = document.getElementById("input_document");
        let file = val.files.item(0).name;
        const documentItem = {
          documentId: uuid(),
          comments: "",
          src: reader.result,
          docType: docSrcArray,
          fileName: file,
        };
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          tenant_logo: documentItem, // Update only the tenant_logo
        }));
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };


  const handlePhoneChange = (value, data) => {
    const countryCode = `+${data.dialCode}`; // Extract country code
    const phoneNumber = `+${value}`;

    setFormValues((prev) => ({
      ...prev,
      phone_number: phoneNumber,
      country_code: countryCode,
    }));

    setFormErrors((prev) => ({ ...prev, phone_number: "" }));
  };

  const validate = () => {
    const errors = {};
  
    if (!formValues?.tenant_name?.trim()) {
      errors.tenant_name = "Tenant Name is required";
    }
    if (!formValues?.tenant_email?.trim()) {
      errors.tenant_email = "Tenant Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formValues?.tenant_email)) {
      errors.tenant_email = "Tenant Email is invalid";
    }
    if (!formValues?.subscriptionType) {
      errors.subscriptionType = "Subscription Type is required";
    }
  
    if (!isEditing) {
      if (!formValues?.super_admin_name?.trim()) {
        errors.super_admin_name = "Super Admin Name is required";
      }
      if (!formValues?.super_admin_email?.trim()) {
        errors.super_admin_email = "Super Admin Email is required";
      } else if (!/\S+@\S+\.\S+/.test(formValues?.super_admin_email)) {
        errors.super_admin_email = "Super Admin Email is invalid";
      }
      if (!formValues?.phone_number?.trim()) {
        errors.phone_number = "Phone number is required";
      } else if (!/^\+?[1-9]\d{1,14}$/.test(formValues?.phone_number)) {
        errors.phone_number = "Phone number is invalid (e.g., +123456789)";
      }
    }
  
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) return;

    Swal.fire({
      title: "Processing, please wait",
      html: "loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    const payload = {
      tenant_name: formValues.tenant_name,
      tenant_email: formValues.tenant_email,
      subscription_type_id: formValues.subscriptionType,
      tenant_logo: formValues.tenant_logo,
      ...(isEditing
        ? { tenant_id: formValues.tenant_id }
        : {
            super_admin_name: formValues.super_admin_name,
            super_admin_email: formValues.super_admin_email,
            super_admin_phone: formValues.phone_number,
            super_admin_country_code: formValues.country_code,
          }),
    };

    const apiCall = isEditing
      ? editTenant(payload, currentUser?.jwtToken)
      : createTenant(payload, currentUser?.jwtToken);

    apiCall
      .then((response) => {
        Swal.close();
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => navigate("/"))
            .catch((err) => {
              console.error(err);
            });
        } else if (response.status === 201) {
          showSuccessAlert("Tenant created successfully");
          if (!isEditing) navigate("/all-tenants");
        } else if (response.status === 200) {
          showSuccessAlert("Tenant updated successfully");
          if (!isEditing) navigate("/all-tenants");
        } else if (response.status === 400) {
          showFailAlert("Update Tenant failed due to invalid data");
        } else if (response.status === 409) {
          showFailAlert("Tenant already exists");
        } else {
          showFailAlert("Update Tenant failed");
        }
      })
      .catch((error) => {
        Swal.close();
        console.error("Error:", error);
        showFailAlert("An unexpected error occurred. Please try again later.");
      });
  };

  const showSuccessAlert = (response) => {
    CustomAlert(
      "Saved",
      response,
      "success",
      "OK",
      false,
      document.getElementById("swal-div")
    ).then((result) => {
      if (result.value) navigate("/all-tenants");
    });
  };

  const showFailAlert = (response) => {
    CustomAlert(
      "Failed",
      response,
      "error",
      "OK",
      false,
      document.getElementById("swal-div")
    );
  };

  const fetchSubscriptionData = async (pageNo, pageSize) => {
    try {
      const response = await getSubscriptions(
        pageNo,
        pageSize,
        currentUser?.jwtToken
      );
      if (response?.data) {
        setSubscriptionDetails(response.data.subscription_list || []);
      }
    } catch (error) {
      console.error("Failed to fetch subscription data:", error);
    }
  };

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-auto p-0 body">
      <div className="row flex pl-4 pt-4 pr-4 pb-0">
        <div className="col-lg-12 pb-3 pl-0 pr-0">
          <div className="row pt-1 pb-2 mt-5">
            <div className="d-flex justify-content-end">
              <div className="p-0">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  id="checkin-records-button"
                  onClick={() => navigate("/all-tenants")}
                >
                  ALL TENANTS
                </button>
              </div>
            </div>
          </div>
          <MDBCard className="card p-0 m-0">
            <CustomHeader
              label={isEditing ? "EDIT TENANT" : "ADD TENANT"}
            />
            <MDBCardBody id="cs-scroll">
              <form onSubmit={handleSubmit} className="card formHeight">
                <div className="row mt-4 card-border divMainSL">
                  <div className="row col-lg-12 justify-content-center pt-2 divPaddingHeader">
                    TENANT DETAILS
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6 col-xs-4 col-lg-4 px-4">
                      <label htmlFor="tenant_name">Tenant Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Tenant Name"
                        value={formValues.tenant_name || ""}
                        onChange={handleChange}
                        name="tenant_name"
                        autoComplete="off"
                      />
                      <div className="error-message-validation">
                        {formErrors.tenant_name}
                      </div>
                    </div>
                    <div className="form-group col-md-6 col-xs-4 col-lg-4 px-4">
                      <label htmlFor="tenant_email">Tenant Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Tenant Email"
                        value={formValues.tenant_email || ""}
                        onChange={handleChange}
                        name="tenant_email"
                        autoComplete="off"
                      />
                      <div className="error-message-validation">
                        {formErrors.tenant_email}
                      </div>
                    </div>
                    <div className="form-group col-md-6 col-xs-4 col-lg-4 px-4">
                      <label htmlFor="subscriptionType">
                        Subscription Type
                      </label>
                      <select
                        className="form-control"
                        value={formValues.subscriptionType || ""}
                        onChange={handleChange}
                        name="subscriptionType"
                      >
                        <option value="">Select Subscription Type</option>
                        {subscriptionDetails.map((sub) => (
                          <option
                            key={sub.subscription_type_id}
                            value={sub.subscription_type_id}
                          >
                            {sub.subscription_type_name}
                          </option>
                        ))}
                      </select>
                      <div className="error-message-validation">
                        {formErrors.subscriptionType}
                      </div>
                    </div>
                    <div
                      className="form-group col-md-6 col-xs-4 col-lg-4 px-4 mt-2 d-flex align-items-center"
                      id="document_group_1"
                    >
                      <input
                        type="file"
                        id="input_document"
                        style={{ display: "none" }}
                        onChange={(e) => handleAddDocument(e)} // Single file upload
                        onClick={(event) => {
                          event.target.value = null; // Allow re-selection of the same file
                        }}
                      />

                      <label
                        htmlFor="input_document"
                        className="btn btn-primary btn-sm mt-3 me-2"
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          id="icon_upload"
                          className="fa fa-upload fa-1x me-1"
                          aria-hidden="true"
                        ></i>
                        Tenant Logo
                      </label>

                      {formValues?.tenant_logo?.fileName && (
                        <span
                          className="text-muted mt-3 d-inline-block text-truncate"
                          style={{ maxWidth: "200px" }}
                        >
                          <span className="text-dark fw-bold">
                            {formValues.tenant_logo.fileName}
                          </span>
                        </span>
                      )}
                      {(isEditing && formValues?.tenant_logo?.logo_url) && (
                        <span
                          className="cursor-pointer float-start"
                          onClick={() => handleDownload()}
                        >
                          <i className="fa fa-download pl-2" />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {!isEditing && (
                  <div className="row mt-4 card-border divMainSL">
                    <div className="row col-lg-12 justify-content-center pt-2 divPaddingHeader">
                      SUPER ADMIN DETAILS
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6 col-xs-4 col-lg-4 px-4">
                        <label htmlFor="super_admin_name">
                          Super Admin Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Super Admin Name"
                          value={formValues.super_admin_name || ""}
                          onChange={handleChange}
                          name="super_admin_name"
                          autoComplete="off"
                        />
                        <div className="error-message-validation">
                          {formErrors.super_admin_name}
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-xs-4 col-lg-4 px-4">
                        <label htmlFor="super_admin_email">
                          Super Admin Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Super Admin Email"
                          value={formValues.super_admin_email || ""}
                          onChange={handleChange}
                          name="super_admin_email"
                          autoComplete="off"
                        />
                        <div className="error-message-validation">
                          {formErrors.super_admin_email}
                        </div>
                      </div>

                      <div className="form-group col-md-6 col-xs-4 col-lg-4 px-4">
                        <label htmlFor="phone_number">
                          Super Admin Phone Number
                        </label>
                        <PhoneInput
                          country={"us"} // Default country
                          value={formValues.phone_number || ""}
                          onChange={handlePhoneChange}
                          placeholder="Enter phone number"
                          inputClass={`w-100 ${
                            formErrors.phone_number ? "is-invalid" : ""
                          }`}
                          buttonClass="mr-2"
                          dropdownClass="custom-phone-dropdown"
                          enableSearch
                          disabled={isEditing}
                          inputProps={{
                            name: "phone_number",
                          }}
                        />
                        <div className="error-message-validation">
                          {formErrors.phone_number}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="fieldArea7 pt-2">
                  <Link to="/all-tenants">
                    <button
                      className="btn  btn-sm"
                      id="cancleBtn"
                      type="reset"
                      value="Reset"
                      style={{ color: "#fff" }}
                    >
                      CANCEL
                    </button>
                  </Link>
                  <button type="submit" className="btn  btn-sm btn-primary">
                    {isEditing === null ? "UPDATE" : "SUBMIT"}
                  </button>
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </div>
  );
}

export default YTPAdminAddTenant;
