import axios from "axios";
import service from "./service";
import {
    GET_TEB_BULK_UPLOAD,
  } from "../constants/ApiUrlConstants";

  import { getHeader } from "../utils/Headers";
  import { ConsoleError } from "../utils/ErrorUtils";
  
  export const saveTEBBulkUpload = async (payload, token) => {
    return axios
      .post(service.baseUrl + GET_TEB_BULK_UPLOAD, payload, {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        ConsoleError(error);
        return error;
      });
  };  