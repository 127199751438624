import React, { useEffect, useState } from "react";
import { useFormContext } from "../../context/FormContext";
import { getActionList } from "../../api/ActionService";
import { useSelector } from "react-redux";
import { ConsoleError } from "../../utils/ErrorUtils";
import { PrintDisable } from "../../utils/PrintDisable";

const Section2 = (props) => {
  const { state, handleChange } = useFormContext();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  PrintDisable(currentUser?.print_access_flag);
  const formErrors = props.errors;
  const [boxNotDrilledReasonList, setBoxNotDrilledReasonList] = useState([]);
  const [drillTypeList, setDrillTypeList] = useState([]);

  useEffect(() => {
    fetchBoxNotDrilledReason();
    if (state?.section2?.box_drilled_status) {
      fetchBoxDrillType();
    }
    //eslint-disable-next-line
  }, []);

  async function fetchBoxNotDrilledReason() {
    try {
      getActionList("BoxNotDrilledReason", currentUser?.jwtToken)
        .then((response) => {
          setBoxNotDrilledReasonList(
            response?.map(function (storageTypeRow) {
              return storageTypeRow;
            })
          );
        })
        .catch((error) => {
          ConsoleError(error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const fetchBoxDrillType = async () => {
    const response = await getActionList("BoxDrillType", currentUser?.jwtToken);
    try {
      if (response) {
        setDrillTypeList(
          response.map(function (drillType) {
            return drillType;
          })
        );
      }
    } catch (error) {
      ConsoleError(error);
    }
  };

  return (
    <>
      <div className="row justify-content-center">SECTION II</div>
      <div className="row justify-content-center">(DRILL STATUS)</div>
      <div className="card-border">
        <div className="col-lg-12 mt-4">
          <label data-testid="box">BOX : </label>
          <input
            className="ml-2"
            type="radio"
            data-testid="drilled"
            name="content"
            value={state?.section2?.box_drilled_status}
            checked={state?.section2?.box_drilled_status}
            onClick={(e) => {
              drillTypeList.length === 0 && fetchBoxDrillType();
              handleChange(
                "section2",
                "box_drilled_status",
                true,
                "UPDATE_VALUE"
              );
            }}
            onChange={(e) => {
              handleChange(
                "section2",
                "box_drilled_status",
                true,
                "UPDATE_VALUE"
              );
              drillTypeList.length > 0 && fetchBoxDrillType();
              handleChange(
                "section2",
                "box_not_drilled_reason_id",
                "",
                "UPDATE_VALUE"
              );
              handleChange(
                "section2",
                "box_not_drilled_reason_name",
                "",
                "UPDATE_VALUE"
              );
              handleChange(
                "section2",
                "box_not_drilled_reason_other",
                "",
                "UPDATE_VALUE"
              );
            }}
          />
          <span className="col-lg-4">Drilled</span>

          <input
            type="radio"
            name="content"
            data-testid="not-drilled"
            value={!state?.section2?.box_drilled_status}
            onClick={(e) =>
              handleChange(
                "section2",
                "box_drilled_status",
                false,
                "UPDATE_VALUE"
              )
            }
            checked={!state?.section2?.box_drilled_status}
            onChange={(e) => {
              handleChange(
                "section2",
                "box_drilled_status",
                false,
                "UPDATE_VALUE"
              );
              handleChange("section2", "contents_found", false, "UPDATE_VALUE");
            }}
          />

          <span className="col-lg-4">Not Drilled</span>
          {state?.section2?.box_drilled_status ? (
            <div>
              <div className="col-lg-8 mt-2">
                <div className="mt-4">
                  <div className="form-group col-md-6 col-xs-4  col-lg-4 px-0">
                    <label className="mb-1">Drill Type:</label>
                    <select
                      className="custom-select mr-sm-2 mb-2"
                      data-testid="drilled_types"
                      value={state?.section2?.box_drilled_type}
                      onChange={(e) => {
                        handleChange(
                          "section2",
                          "box_drilled_type",
                          e.target.value,
                          "UPDATE_VALUE"
                        );
                        delete formErrors["drill_type"];
                      }}
                    >
                      <option value="" default disabled>
                        Choose Drill Type
                      </option>
                      {drillTypeList.map((option) => {
                        return (
                          <option
                            data-testid={"drillType" + option.action_id}
                            key={option.action_id}
                            value={option.action_id}
                          >
                            {option.action}
                          </option>
                        );
                      })}
                    </select>

                    <div className="error-message-validation">
                      {formErrors?.drill_type}
                    </div>
                  </div>
                </div>
                <label data-testid="contents">Contents : </label>
                <input
                  className="ml-2"
                  type="radio"
                  name="content2"
                  data-testid="found"
                  onClick={(e) =>
                    handleChange(
                      "section2",
                      "contents_found",
                      true,
                      "UPDATE_VALUE"
                    )
                  }
                  checked={state?.section2?.contents_found}
                  onChange={(e) => {
                    handleChange(
                      "section2",
                      "contents_found",
                      true,
                      "UPDATE_VALUE"
                    );
                  }}
                />
                <span className="col-lg-4">Found</span>
                <input
                  type="radio"
                  name="content2"
                  value="Not Found"
                  data-testid="notFound"
                  onClick={(e) =>
                    handleChange(
                      "section2",
                      "contents_found",
                      false,
                      "UPDATE_VALUE"
                    )
                  }
                  checked={!state?.section2?.contents_found}
                  onChange={(e) => {
                    handleChange(
                      "section2",
                      "contents_found",
                      false,
                      "UPDATE_VALUE"
                    );
                  }}
                />
                <span className="col-lg-4">Not Found</span>
              </div>
            </div>
          ) : (
            <div>
              <div
                className="col-12 mt-2"
                data-testid="box_not_drilled_reasons"
              >
                <label>Reason : </label>
                {boxNotDrilledReasonList.map((opt) => {
                  return (
                    <div className="row mt-2" key={opt.action_id}>
                      <div className="col-lg-6 col-md-8 col-sm-6 mt-2">
                        <input
                          className="ml-2"
                          type="radio"
                          name={"reason" + opt.action_id}
                          data-testid={"reason" + opt.action_id}
                          onClick={(e) => {
                            handleChange(
                              "section2",
                              "box_not_drilled_reason_id",
                              opt.action_id,
                              "UPDATE_VALUE"
                            );
                            handleChange(
                              "section2",
                              "box_not_drilled_reason_name",
                              opt.action,
                              "UPDATE_VALUE"
                            );
                          }}
                          checked={
                            state?.section2?.box_not_drilled_reason_id ===
                            opt.action_id
                          }
                          onChange={(e) => {
                            handleChange(
                              "section2",
                              "box_not_drilled_reason_id",
                              opt.action_id,
                              "UPDATE_VALUE"
                            );
                            handleChange(
                              "section2",
                              "box_not_drilled_reason_name",
                              opt.action,
                              "UPDATE_VALUE"
                            );
                            if (
                              boxNotDrilledReasonList.find(
                                (reason) => reason.action_id === opt.action_id
                              )?.action !== "Other"
                            ) {
                              handleChange(
                                "section2",
                                "box_not_drilled_reason_other",
                                "",
                                "UPDATE_VALUE"
                              );
                            }
                            delete formErrors["box_not_drilled_reason"];
                          }}
                        />
                        <span className="col-lg-4">{opt.action}</span>
                      </div>
                    </div>
                  );
                })}
                <div className="error-message-validation pt-1">
                  {formErrors?.box_not_drilled_reason}
                </div>
              </div>
              {boxNotDrilledReasonList.find(
                (reason) =>
                  reason.action_id ===
                  state?.section2?.box_not_drilled_reason_id
              )?.action === "Other" ? (
                <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4 mt-4">
                  <input
                    type="text"
                    className="form-control"
                    id="reasonText"
                    data-testid="reasonOtherText"
                    autoComplete="off"
                    onChange={(e) => {
                      handleChange(
                        "section2",
                        "box_not_drilled_reason_other",
                        e.target.value,
                        "UPDATE_VALUE"
                      );
                    }}
                    value={state?.section2?.box_not_drilled_reason_other}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>

        {/* <div className="col-lg-12 mt-4">
          <div className="form-group col-md-6 col-xs-4  col-lg-3 px-0">
            <label htmlFor="rental_due">Amount Reported</label>
            <input
              type="number"
              min={0}
              className="form-control"
              id="rental_due"
              autoComplete="off"
              onChange={(e) => {
                handleChange(
                  "section2",
                  "amount_reported",
                  parseInt(e.target.value),
                  "UPDATE_VALUE"
                );
              }}
              value={state?.section2?.amount_reported}
            />
          </div>
        </div> */}
      </div>
    </>
  );
};
export default Section2;
