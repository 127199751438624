import React from "react";
import "../../style/ForceOpen.css";
import { useFormContext } from "../../context/FormContext";
import { PrintDisable } from "../../utils/PrintDisable";
import { useSelector } from "react-redux";

const Section3 = (props) => {
  const { state, handleChange } = useFormContext();
  const formErrors = props.errors;

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  PrintDisable(currentUser?.print_access_flag);

  return (
    <>
      <div className="row justify-content-center">SECTION III</div>
      <div className="row justify-content-center">(NOTARY)</div>
      {!props.printScreen && (
        <>
          <div className="form-row card-border m-3">
            <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
              <label htmlFor="notaryName">Notary Name</label>
              <input
                type="text"
                className="form-control"
                id="notaryName"
                autoComplete="off"
                onChange={(e) => {
                  handleChange(
                    "section1",
                    "notary_name",
                    e.target.value,
                    "UPDATE_VALUE"
                  );
                  if (formErrors && formErrors["locksmith_name"]) {
                    delete formErrors["notary_name"];
                  }
                }}
                value={state?.section1?.notary_name}
              />
              <div className="error-message-validation">
                {formErrors?.notary_name}
              </div>
            </div>
            <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
              <label htmlFor="locksmithName">Locksmith Name</label>
              <input
                type="text"
                className="form-control"
                id="locksmithName"
                autoComplete="off"
                onChange={(e) => {
                  handleChange(
                    "section1",
                    "locksmith_name",
                    e.target.value,
                    "UPDATE_VALUE"
                  );
                  if (formErrors && formErrors["locksmith_name"]) {
                    delete formErrors["locksmith_name"];
                  }
                }}
                value={state?.section1?.locksmith_name}
              />
              <div className="error-message-validation">
                {formErrors?.locksmith_name}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Section3;
