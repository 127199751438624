import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import React from "react";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import "../style/CustomTable.css";

export const CustomTable = (props) => {
  //eslint-disable-next-line
  const { title, columns, data } = props;
  const [rows, setRows] = useState([]);
  //eslint-disable-next-line
  const [pageState, setPageState] = useState({ pageNum: 1, rowsPerPage: 10 });
  const [selectedRow, setSelectedRow] = useState(0);
  const onClickRow = (index) => {
    if (selectedRow !== undefined) {
      setSelectedRow(index);
    }
  };

  const onUpdateShipmentIndex = (item, index) => {
    item.is_edit_input = false;

    const updatedData = [...data];
    updatedData[index] = item;
    props.shipment_props.onChange("step1", "fileuploadrows", updatedData);
    // props.shipment_props.onChange('step1','isEditInput',false)
  };

  const handleShipmentInputChange = (event, item, rowIndex, colIndex) => {
    switch (colIndex) {
      case 0:
        item["BAG#"] = event.target.value;
        break;
      case 1:
        item["SDB#_DRILLED"] = event.target.value;
        break;
      case 2:
        item["CUSTOMER_NAME"] = event.target.value;
        break;
      default:
        item["ORIGINAL_BRANCH_DID"] = event.target.value;
    }
  };

  const handleOnUpdateAllChange = () => {
    props.shipment_props.onChange("step1", "isEditInput", false);
  };

  const renderData = (item, rowIndex) => {
    return columns.map((col, index) => (
      <>
        {props?.isEditInput ? (
          <>
            {index !== 4 && item.is_edit_input ? (
              <td key={`${item[col.field]}-${index}`}>
                <input
                  type="text"
                  defaultValue={item[col.field]}
                  onChange={(e) =>
                    handleShipmentInputChange(e, item, rowIndex, index)
                  }
                />
              </td>
            ) : index !== 4 && !item.is_edit_input ? (
              <td key={`${item[col.field]}-${index}`}>{item[col.field]}</td>
            ) : (
              <></>
            )}

            {index === 4 ? (
              <td>
                <button
                  id="previous-btn2"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onUpdateShipmentIndex(item, rowIndex);
                  }}
                >
                  UPDATE
                </button>
              </td>
            ) : (
              <></>
            )}
          </>
        ) : (
          <td className="tdStyle" key={`${item[col.field]}-${index}`}>
            {item[col.field]}
          </td>
        )}
      </>
    ));
  };
  React.useEffect(() => {
    if (data && data.length) {
      const propsdata = [...data];
      const idx = (pageState.pageNum - 1) * pageState.rowsPerPage;
      setRows(propsdata.slice(idx, idx + pageState.rowsPerPage));
    }
  }, [pageState, data]);
  return (
    <Typography component="div">
      <div
        className={
          props.customBulkCheckin ? "p-0 m-0 divTableMain" : "p-0 m-0 mainTable"
        }
        style={{
          scrollbar: {
            width: "6px",
            backgroundColor: "#ffffff",
          },
        }}
      >
        <MDBTable>
          <MDBTableHead style={{ position: "sticky", top: 0 }}>
            <tr style={{ position: "sticky", background: "#6F7D95" }}>
              {columns.map((col, index) => (
                <th key={`${col.name}-${index}`}>{col.title.toUpperCase()}</th>
              ))}

              {props.actionMenu &&
                props.actionMenu.length &&
                props.actionMenu.map((col, index) => (
                  <th key={`${col.name}-${index}`}>{}</th>
                ))}

              {props?.isEditInput ? (
                <tr style={{ position: "sticky", background: "#6F7D95" }}>
                  <button
                    id="updateAllButton"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleOnUpdateAllChange();
                    }}
                  >
                    UPDATE ALL
                  </button>
                </tr>
              ) : (
                <></>
              )}
            </tr>
          </MDBTableHead>
          <>
            {data.length > 0 ? (
              <MDBTableBody>
                {rows.map((item, index) => {
                  return (
                    <tr
                      className={
                        selectedRow === index ? "tableSelected" : "#FFFFFF"
                      }
                      key={index}
                      style={{
                        background: selectedRow === index ? "#c4c4c480" : "",
                      }}
                      onClick={() => onClickRow(index)}
                    >
                      {renderData(item, index)}
                      {props.actionMenu &&
                        props.actionMenu.length &&
                        props.actionMenu.map((x, idx) => (
                          <td
                            className={
                              x.type === "button" ? "tdbutton" : "tdimg"
                            }
                            key={`${index}-${idx}`}
                            onClick={() => x.callback(item)}
                          >
                            {x.icon + "1"}
                          </td>
                        ))}
                    </tr>
                  );
                })}
              </MDBTableBody>
            ) : (
              <MDBTableBody>
                <tr>
                  <td>
                    <div
                      id="tableError"
                      className="col d-flex  justify-content-center p-5 m-5"
                    >
                      {"No Records Available"}
                    </div>
                  </td>
                </tr>
              </MDBTableBody>
            )}
          </>
        </MDBTable>
      </div>

      {/* <Pager
        totalPages={
          data.length ? Math.ceil(data.length / pageState.rowsPerPage) : 0
        }
        currentPage={pageState.pageNum}
        onPageChange={(newPage) =>
          setPageState({
            pageNum: newPage,
            rowsPerPage: pageState.rowsPerPage,
          })
        }
        rowsPerPage={pageState.rowsPerPage}
        setRowsPerPage={(num) => setPageState({ pageNum: 1, rowsPerPage: num })}
        dataCount={data.length}
        dataLength={rows.length}
      /> */}
    </Typography>
  );
};
