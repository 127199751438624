import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getViewAuditData } from "../api/ViewAuditService";
import { cognitoSignOut } from "../api/CognitoServices";
import Pagination from "../components/Pagination";
import Search from "../components/Search";
import CustomHeader from "../components/CustomHeader";
import Spinner from "react-bootstrap/Spinner";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import { DISPLAY_DATE_FORMAT } from "../constants/Constants";
import { ConsoleError } from "../utils/ErrorUtils";
import "../style/checkinrecords.css";
import "../style/recordAudit.css";

function ViewAllAudit() {
  const navigate = useNavigate();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  // Grouped state for records and pagination
  const [auditState, setAuditState] = useState({
    auditRecords: [],
    currentPage: 1,
    pages: [],
    rowCount: 10,
    searchValue: "",

  });

  // Loading and message states
  const [saveLoader, setSaveLoader] = useState(false);
  const [noRecordsFound, setNoRecordsFound] = useState("");

  dayjs.extend(utc);
  dayjs.extend(tz);
  const localTimezone = dayjs.tz.guess();

  // Fetch audit data
  const fetchAuditData = async (
    page = 1,
    rows = auditState.rowCount,
    search = ""
  ) => {
    try {
      setSaveLoader(true);
      const response = await getViewAuditData(
        search,
        page,
        rows,
        currentUser?.employee_id,
        currentUser?.jwtToken
      );

      if (response.status === 401) {
        await cognitoSignOut();
        navigate("/");
        return;
      }

      if (response.status === 200) {
        const { pagination, audits } = response.data;
        const { pages } = pagination;

        setAuditState((prevState) => ({
          ...prevState,
          auditRecords: audits || [],
          currentPage: page,
          pages: [...Array(pages)].map((_, i) => i + 1),
        }));
        setNoRecordsFound(audits?.length ? "" : "No audit records available");
      }
    } catch (error) {
      ConsoleError(error);
    } finally {
      setSaveLoader(false);
    }
  };

  // Handlers
  const handlePageChange = (page) => {
    fetchAuditData(page);
  };

  const handleRowsPerPageChange = (rows) => {
    setAuditState((prevState) => ({ ...prevState, rowCount: rows }));
    fetchAuditData(1, rows);
  };

  const handleSearchData = (data) => {
    const { pagination, audits, searchValue = "" } = data || {};
    const { pages } = pagination || {};

    // Handle empty search
    if (searchValue.trim().length === 0) {
      setAuditState((prevState) => ({
        ...prevState,
        currentPage: 1,
        searchValue: "",
      }));
      fetchAuditData(1, auditState.rowCount, "");
      return;
    }

    setAuditState((prevState) => ({
      ...prevState,
      auditRecords: audits || [],
      currentPage: 1,
      pages: [...Array(pages)].map((_, i) => i + 1),
    }));
    setNoRecordsFound(audits?.length ? "" : "No audit records available");
    // fetchAuditData(1, auditState.rowCount, searchTerm);
  };

  // Initial fetch
  useEffect(() => {
    fetchAuditData();
    // eslint-disable-next-line
  }, []);

  const { auditRecords, currentPage, pages, rowCount } = auditState;

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 overflow-hidden body">
      <div className="row flex p-4">
        <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden pt-5">
          <div className="row pt-2">
            <div className="col-lg-6 col-md-10">
              <Search
                filterData={(searchTerm) => handleSearchData(searchTerm)}
                screen="Audit"
                pageSize={rowCount}
              />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6">
              <button
                className="btn btn-primary btn-sm float-end"
                onClick={() => fetchAuditData()}
              >
                Refresh
              </button>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6">
              {currentUser?.audit_save_record_access_flag && (
                <button
                  className="btn btn-primary btn-sm float-end"
                  onClick={() => navigate("/createAudit")}
                >
                  NEW AUDIT
                </button>
              )}
            </div>
          </div>
          <div className="card overflow-hidden h-md-100 table-box">
            <CustomHeader label={"AUDIT RECORDS"} />
            <div className="card-body">
              <div className="table-responsive">
                <table className="table safeChiefTable">
                  <thead className="headerArea">
                    <tr>
                      <th scope="col" className="tableHeading1">
                        TAMPER EVIDENT BAG #
                      </th>
                      <th scope="col" className="tableHeading">
                        ACTION TYPE
                      </th>
                      <th scope="col" className="tableHeading">
                        ACTION DATE
                      </th>
                      <th scope="col" className="tableHeading">
                        PERFORMED BY
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {saveLoader ? (
                      <tr>
                        <td colSpan="4" className="text-center">
                          <Spinner animation="border" />
                        </td>
                      </tr>
                    ) : auditRecords?.length ? (
                      auditRecords.map((record, index) => (
                        <tr
                          key={index}
                          onClick={() =>
                            navigate("/auditHistory", { state: record })
                          }
                          className="table-row"
                        >
                          <td>
                            {
                              record?.tamper_evident_bag
                                ?.tamper_evident_bag_number
                            }
                          </td>
                          <td>{record?.action?.action}</td>
                          <td>
                            {dayjs(record?.audit_date)
                              .tz(localTimezone)
                              .format(DISPLAY_DATE_FORMAT)}
                          </td>
                          <td>{record?.performed_by?.employee_name}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          {noRecordsFound}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination
              pages={pages}
              onNextClick={() => handlePageChange(currentPage + 1)}
              onPreviousClick={() => handlePageChange(currentPage - 1)}
              onPageClick={handlePageChange}
              setRowsPerPage={handleRowsPerPageChange}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewAllAudit;
