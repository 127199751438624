import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";
import {
  GET_STORAGE_LOCATIONS,
  SAVE_SHIPMENT,
  CHECK_BULK_SHIPMENT_DATA_EXISTS,
  VIEW_ALL_RECORDS,
  ALL_SHIPMENT,
  SAVE_BULK_SHIPMENT,
  ALL_CHECKIN_DATA_BY_STORAGE_TYPE,
} from "../constants/ApiUrlConstants";
import { ConsoleError } from "../utils/ErrorUtils";

export const saveShipmentData = async (payload, token) => {
  return axios
    .post(service.baseUrl + SAVE_SHIPMENT, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export const handleSubmitBulkShipment = async (dataSource, empId, token) => {
  return axios
    .post(service.baseUrl + SAVE_BULK_SHIPMENT, dataSource, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
    });
};

export const getShipmentData = async (
  searchValue,
  pageNo,
  pageSize,
  empId,
  token
) => {
  return axios
    .get(
      service.baseUrl +
        ALL_SHIPMENT +
        "?emp_id=" +
        empId +
        "&search_value=" +
        searchValue +
        "&page_no=" +
        pageNo +
        "&page_size=" +
        pageSize,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const pagination = async (pageNumber, pageCount, empId, token) => {
  return axios
    .get(
      service.baseUrl +
        VIEW_ALL_RECORDS +
        "/Shipment?page=" +
        pageNumber +
        "&records_per_page=" +
        pageCount +
        "&emp_id=" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const getStorageLocationData = async (data, empId, token) => {
  return axios
    .get(
      service.baseUrl +
        GET_STORAGE_LOCATIONS +
        "?search_text=" +
        data +
        "&emp_id=" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
    });
};

export const handleFormDataSubmit = async (data, empId, token) => {
  return axios
    .post(
      service.baseUrl + CHECK_BULK_SHIPMENT_DATA_EXISTS + "?emp_id=" + empId,
      data,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
    });
};

export const getAllCheckinDataByStorageType = async (
  storageType,
  storageLocation,
  pageNo,
  pageSize,
  empId,
  token
) => {
  return axios
    .get(
      service.baseUrl +
        ALL_CHECKIN_DATA_BY_STORAGE_TYPE +
        "?emp_id=" +
        empId +
        "&storage_type=" +
        storageType +
        "&storage_location=" +
        storageLocation +
        "&page_no=" +
        pageNo +
        "&page_size=" +
        pageSize,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
