import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import AddItemModal from "./AddAddendumModel";
import { getAddendumCategoryList, getItemsList } from "../api/forceOpenService";
import ResponsiveTable from "./Table";
import EditStorage from "../assets/EditStorage.png";
import "../style/addaddendummodel.css";
import CustomHeader from "../components/CustomHeader";
import { useDebounce } from "../utils/useDebounce";
import Swal from "sweetalert2";

const AddAddendum = () => {
  const [addendumList, setAddendumList] = useState([]);
  const [paginationData, setPaginationData] = useState({
    currentPageNo: 1,
    itemCount: 10,
    searchValue: "",
    pages: [],
  });
  const [modalData, setModalData] = useState({
    modalVisible: false,
    modalMode: "add",
    selectedItem: null,
  });
  const [loading, setLoading] = useState(false);
  const [addendumCategoryList, setAddendumCategoryList] = useState({
    Addendum_a: [],
    Addendum_b: [],
  });
  const debouncedSearchQuery = useDebounce(paginationData?.searchValue, 500);
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  useEffect(() => {
    // Trigger API call when debounced query changes
    getAllAddendumList(debouncedSearchQuery); // Fetch API when debouncedValue changes
    setPaginationData((prev) => ({
      ...prev,
      searchValue: debouncedSearchQuery,
    }));
  }, [debouncedSearchQuery]);
  const getAddendumCategory = async () => {
    try {
      const responseA = await getAddendumCategoryList(
        "addendum_a",
        currentUser?.jwtToken
      );
      const responseB = await getAddendumCategoryList(
        "addendum_b",
        currentUser?.jwtToken
      );

      setAddendumCategoryList({
        addendum_a: responseA || [],
        addendum_b: responseB || [],
      });
    } catch (error) {
      console.error("Error fetching addendum categories:", error);
    }
  };

  useEffect(() => {
    getAddendumCategory();
  }, [currentUser?.jwtToken]);

  const getAllAddendumList = async () => {
    setLoading(true);
    try {
      const response = await getItemsList(
        paginationData.currentPageNo,
        paginationData.itemCount,
        currentUser?.jwtToken,
        paginationData.searchValue
      );
      const count = response?.pagination?.total_records || 0;
      const totalPages = Math.ceil(count / paginationData.itemCount) || 1;
      setAddendumList(response?.items_list || []);
      setPaginationData((prev) => ({
        ...prev,
        pages: [...Array(totalPages)].map((_, i) => i + 1),
      }));
    } catch (error) {
      console.error("Error fetching addendum list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllAddendumList();
  }, [paginationData.itemCount, paginationData.currentPageNo]);

  const openModal = (mode, item = null) => {
    setModalData({
      modalVisible: true,
      modalMode: mode,
      selectedItem: item,
    });
  };

  const closeModal = () => {
    setModalData((prev) => ({ ...prev, modalVisible: false }));
  };

  const handleAddendumSubmit = (shouldRefresh, isCategoryAdded) => {
    if (shouldRefresh) getAllAddendumList();
    if (isCategoryAdded) getAddendumCategory();
    closeModal();
  };

  // Pagination Handlers
  const setRowsPerPage = (count) =>
    setPaginationData((prev) => ({
      ...prev,
      itemCount: count,
      currentPageNo: 1,
    }));

  const handleNextPage = () =>
    setPaginationData((prev) => ({
      ...prev,
      currentPageNo: Math.min(prev.currentPageNo + 1, prev.pages.length),
    }));

  const handlePreviousPage = () =>
    setPaginationData((prev) => ({
      ...prev,
      currentPageNo: Math.max(prev.currentPageNo - 1, 1),
    }));

  const handlePageClick = (page) =>
    setPaginationData((prev) => ({ ...prev, currentPageNo: page }));

  // Search Handler with Debounce
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setPaginationData((prev) => ({
      ...prev,
      searchValue: query,
      currentPageNo: 1,
    }));
  };

  // Define table columns
  const columns = [
    { label: "Addendum Item", key: "item_name" },
    {
      label: "Addendum Category",
      accessor: (row) => row?.addendum_category?.category_name,
    },
    {
      label: "Addendum Type",
      accessor: (row) =>
        getAddendumLabel(row?.addendum_category?.addendum_type),
    },
  ];

  // Define table actions
  const tableActions = (row) => (
    <img
      src={EditStorage}
      alt="Edit"
      onClick={() => openModal("editItem", row)}
      className="edit-icon"
    />
  );

  function getAddendumLabel(value) {
    switch (value) {
      case "addendum_c":
        return "Addendum C";
      case "addendum_a":
        return "Addendum A";
      case "addendum_b":
        return "Addendum B";
      default:
        return "Unknown Addendum";
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <input
          type="text"
          className="form-control w-75"
          placeholder="Search by Item Name"
          value={paginationData.searchValue}
          onChange={handleSearchChange}
        />

        <button
          className="btn btn-primary"
          onClick={() => openModal("addCategory")}
        >
          Add Category
        </button>
        <button
          className="btn btn-primary"
          onClick={() => openModal("addItem")}
        >
          Add Item
        </button>
      </div>
      <CustomHeader label={"ADDENDUM MANAGEMENT"} />
      <ResponsiveTable
        columns={columns}
        data={addendumList}
        actions={tableActions}
        isLoading={loading}
        paginationData={{
          currentPage: paginationData.currentPageNo,
          pages: paginationData.pages,
          rowsPerPage: paginationData.itemCount,
          setRowsPerPage,
          onNextClick: handleNextPage,
          onPreviousClick: handlePreviousPage,
          onPageClick: handlePageClick,
        }}
      />
      {modalData.modalVisible && (
        <div className="addendum-modal visible">
          <AddItemModal
            modalMode={modalData.modalMode}
            selectedItem={modalData.selectedItem}
            onSubmit={handleAddendumSubmit}
            onClose={closeModal}
            categories={addendumCategoryList}
          />
        </div>
      )}
    </div>
  );
};

export default AddAddendum;