import { GET_AUTHORIZATION_CONFIGURATION_DETAILS,GET_AUTHORIZATION_CONFIGURATION_DETAIL } from "../constants/ApiUrlConstants";
import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";

export const getAuthorizationData = async (token) => {
    return axios
        .get(
            service.baseUrl +
            GET_AUTHORIZATION_CONFIGURATION_DETAILS,
            {
                headers: getHeader(token),
                validateStatus: function (status) {
                  return status < 500;
                },
              }
        )
        .then((response) => {
            return response;
        }).catch((response)=>{
            return response;
        });
};

export const getAuthorizationDataModule = async (module_name,token) => {
    return axios
        .get(
            service.baseUrl +
            GET_AUTHORIZATION_CONFIGURATION_DETAIL+"?module_name="+module_name,
            {
                headers: getHeader(token),
                validateStatus: function (status) {
                  return status < 500;
                },
              }
        )
        .then((response) => {
            return response;
        }).catch((response)=>{
            return response;
        });
    };
    
    export const updateAuthorizationConfiguration = async(payload, token)=>{
        return axios
        .patch(
            service.baseUrl +
            GET_AUTHORIZATION_CONFIGURATION_DETAILS,
            payload,
            {
                headers: getHeader(token),
                validateStatus: function (status) {
                  return status < 500;
                },
              }
            )
            .then((response) => {
                return response;
            }).catch((response)=>{
                return response;
        });
}