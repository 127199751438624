import dayjs from "dayjs";
import React from "react";
import checkin_timeline from "../assets/checkin_timeline.png";
import audit1 from "../assets/audit1.png";
import enquiries from "../assets/enquiries.png";
import shipment from "../assets/shipment.png";
import "../style/newtimeline.css";
import { DISPLAY_DATE_TIME_FORMAT } from "../constants/Constants";

import { PrintDisable } from "../utils/PrintDisable";
import { useSelector } from "react-redux";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

const HistoryTimeline = (props) => {
  dayjs.extend(utc);
  dayjs.extend(tz);
  //const [selectedEvent, setSelectedEvent] = useState(0);
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  PrintDisable(currentUser?.print_access_flag);

  if (
    document.getElementById("left-slide") &&
    document.getElementById("timeline")
  ) {
    let leftButton = document.getElementById("left-slide");
    leftButton.onclick = function () {
      document.getElementById("timeline").scrollLeft -= 20;
    };
  }

  if (
    document.getElementById("right-slide") &&
    document.getElementById("timeline")
  ) {
    let rightButton = document.getElementById("right-slide");
    rightButton.onclick = function () {
      document.getElementById("timeline").scrollLeft += 20;
    };
  }
  const localTimezone = dayjs.tz.guess();
  return (
    <div className="col-lg-12  col-md-12 col-sm-12 d-flex container overflow-hidden">
      <div
        className="icon-circle d-flex justify-content-center align-items-center mt-5 mr-2 overflow-hidden"
        id="left-slide"
        onClick={() => props.onPageClick("minus")}
      >
        <i
          className="fa fa-angle-left fa-lg fa-fw left-icon "
          aria-hidden="true"
        ></i>
      </div>

      <div className="d-flex mx-0 mr-0 timeline" id="timeline">
        {props?.historyData &&
          props?.historyData.map((item, index) => {
            return (
              <div className="swiper-slide ">
                <div className="timestamp">
                  <span className="date">
                    <div
                      key={index}
                      // className="image-circle d-flex justify-content-center align-items-center"
                      className={
                        props.selectedEvent === index
                          ? "image-circle d-flex justify-content-center align-items-center selected"
                          : "image-circle d-flex justify-content-center align-items-center"
                      }
                      onClick={() => {
                        props.onClick(item, index);
                      }}
                      style={{
                        border:
                          props.clickid === index ? "3px solid #15A4FA" : "",
                      }}
                    >
                      {(() => {
                        if (props.event === "Shipment") {
                          return (
                            <img
                              className="img-responsive "
                              src={shipment}
                              alt="logo"
                              width="20px"
                              height="20px"
                            ></img>
                          );
                        } else if (props.event === "Enquiries") {
                          return (
                            <img
                              className="img-responsive "
                              src={enquiries}
                              alt="logo"
                              width="20px"
                              height="20px"
                            ></img>
                          );
                        } else if (props.event === "Audit") {
                          return (
                            <img
                              className="img-responsive "
                              src={audit1}
                              alt="logo"
                              width="20px"
                              height="20px"
                            ></img>
                          );
                        } else if (props.event === "Checkin") {
                          return (
                            <img
                              className="img-responsive "
                              src={checkin_timeline}
                              alt="logo"
                              width="20px"
                              height="20px"
                            ></img>
                          );
                        } else {
                          return null;
                        }
                      })()}
                    </div>
                  </span>
                </div>
                <div className="status">
                  {props.event === "Shipment" && (
                    <span>
                      {dayjs(item?.shipment?.create_datetime)
                        .tz(localTimezone)
                        .format(DISPLAY_DATE_TIME_FORMAT)}
                    </span>
                  )}
                  {props.event === "Audit" && (
                    <span>
                      {dayjs(item?.audit_date)
                        .tz(localTimezone)
                        .format(DISPLAY_DATE_TIME_FORMAT)}
                    </span>
                  )}
                  {(props.event === "Enquiries" ||
                    props.event === "Checkin") && (
                    <span>
                      {dayjs(item?.create_datetime)
                        .tz(localTimezone)
                        .format(DISPLAY_DATE_TIME_FORMAT)}
                    </span>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      <div
        className="icon-circle d-flex justify-content-center align-items-center mt-5 ml-2"
        id="right-slide"
        onClick={() => props.onPageClick("plus")}
      >
        <i className="fa fa-angle-right fa-lg fa-fw" aria-hidden="true"></i>
      </div>
    </div>
  );
};

export default HistoryTimeline;
