import { MDBCard, MDBCardBody } from "mdbreact";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getTamperEvidentBags } from "../api/CheckInServices";
import { saveEnquiry } from "../api/CreateEnquiryService";
import { CustomAlert } from "../components/customAlert";
import CustomHeader from "../components/CustomHeader";
import DocumentHorizontal from "../components/DocumentHorizontal";
import uuid from "react-uuid";
import { WebcamCapture } from "../components/webcamComponent";
import PerformedBy from "../components/PerformedBy";
import "../style/CreateEnquiry.css";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import { getUpdatedDateInUtc } from "../utils/DateUtils";
import {
  SAVE_ENQUIRY_PAYLOAD,
  ENQUIRY_TEB_LIMIT,
} from "../constants/EnquiryConstants";
import { useTranslation } from "react-i18next";
import SearchPanel from "../components/SearchPanel";
import { ConsoleError } from "../utils/ErrorUtils";
import { ERROR_MESSAGE } from "../constants/MessagesConstants";
import ConfigData from "../config";
import { SearchSelectedListTable } from "../components/SearchSelectedListTable";
import { getDateConfigureData } from "../api/ConfigureDatesService";
import { MODULES } from "../constants/Constants";

function CreateEnquriy(props) {
  let navigate = useNavigate();
  const initialValues = {
    teb_id: "",
    requested_by: "",
    requested_date: "",
    enquiry_summary: "",
    enquiry_notes: "",
  };
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isModelEnabled, setIsModelEnabled] = useState(false);
  const [tebOptionList, setTebOptionList] = useState([]);
  const [tebList, setTebList] = useState([]);
  const [showTebOptions, setShowTebOptions] = useState(true);
  const [tebSearchString, setTebSearchString] = useState("");
  const [isTebNameAssigned, setTebNameAssigned] = useState(false);
  const [tebId, setTebId] = useState("");
  const [documentList, setDocumentList] = useState([]);
  const [pictureList, setPictureList] = useState([]);
  const [selectedTebList, setSelectedTebList] = useState([]);

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const [minRequestedDate, setMinRequestedDate] = useState();
  const [maxRequestedDate, setMaxRequestedDate] = useState();

  const fetchDateFieldData = async (selectedField) => {
    getDateConfigureData(selectedField,MODULES.ENQUIRY, currentUser?.jwtToken).then((response) => {
      if (response?.status === 200) {
        setMinRequestedDate(getUpdatedDateInUtc(-response?.data?.min_value))
        setMaxRequestedDate(getUpdatedDateInUtc(response?.data?.max_value))
      }
    })
  }

  useEffect(()=>{
    fetchDateFieldData("Enquiry Date")
  },[])
  const cancelButton = (e) => {
    navigate("/enquiryview");
  };

  const onCapturePicture = (picture, itemId) => {
    let addedImageList = [...pictureList, { src: picture, typeId: itemId }];
    setPictureList(addedImageList);
    setIsModelEnabled(false);
  };

  const onClickDeleteTEB = (item, index) => {
    const newSelectedTebList = [...selectedTebList]; // make a copy of the original array
    newSelectedTebList.splice(index, 1); // remove the element at the specified index
    setSelectedTebList(newSelectedTebList);
  };

  const OnDeletePicture = (index, itemId) => {
    let shallowCopyImageList = Array.from(pictureList);
    if (index > -1) {
      shallowCopyImageList.splice(index, 1);
    }
    setPictureList(shallowCopyImageList);
  };

  function getTebList() {
    getTamperEvidentBags(
      "enquiry",
      tebSearchString,
      1,
      ENQUIRY_TEB_LIMIT,
      currentUser?.jwtToken
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response?.status === 200) {
          setTebList(response?.data);
          setTebOptionList(
            response?.data
              ?.filter(
                (row) =>
                  !selectedTebList.some(
                    (selectedTeb) =>
                      selectedTeb.tamper_evident_bag_id ===
                      row.tamper_evident_bag_id
                  )
              )
              .map(function (teb) {
                return teb?.tamper_evident_bag_number;
              })
          );
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  }

  useEffect(() => {
    if (!isTebNameAssigned && tebSearchString.length > 0) {
      getTebList();
    }
    //eslint-disable-next-line
  }, [tebSearchString]);

  /**
   * Function to handle updates the comments made to the document
   */
  const handleUpdateDocumentComment = (documentId, value) => {
    let shallowCopyDocumentList = Array.from(documentList);
    const docIndex = shallowCopyDocumentList.findIndex(
      (doc) => doc.documentId === documentId
    );
    shallowCopyDocumentList[docIndex].comments = value;
    setDocumentList(shallowCopyDocumentList);
  };

  /**
   * Function to read files & set to props
   */
  const handleAddDocument = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        const docSrcArray = reader.result.split(";")[0];
        let val = document.getElementById("input_document");
        let file = val.files.item(0).name;
        const documentItem = {
          documentId: uuid(),
          comments: "",
          src: reader.result,
          docType: docSrcArray,
          fileName: file,
        };
        handleNewDocument(documentItem);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  /**
   * Function to handle new documents
   */
  const handleNewDocument = (document) => {
    let addedDocumentList = [...documentList, document];
    setDocumentList(addedDocumentList);
  };
  /**
   * Function to handle delete documents
   */
  const handleDeleteDocument = (documentId) => {
    let shallowCopyDocList = Array.from(documentList);
    const docIndex = shallowCopyDocList.findIndex(
      (doc) => doc.documentId === documentId
    );

    if (docIndex > -1) {
      shallowCopyDocList.splice(docIndex, 1);
    }
    setDocumentList(shallowCopyDocList);
  };

  const onValueChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (formErrors[name] !== undefined && formErrors[name].length > 0) {
      delete formErrors[name];
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length === 0) {
      setIsSubmit(true);
      Swal.fire({
        title: "Processing, please wait",
        html: "loading...",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      let payload = SAVE_ENQUIRY_PAYLOAD;
      payload.tamper_evident_bags = selectedTebList.map(
        (teb) => teb.tamper_evident_bag_id
      );
      payload.requested_date = formValues.requested_date;
      payload.requested_by = formValues.requested_by;
      payload.request_summary = formValues.enquiry_summary;
      payload.notes = formValues.enquiry_notes;
      payload.pictures = pictureList;
      payload.documents = documentList;
      payload.performed_by_id = currentUser?.employee_id;

      saveEnquiry(JSON.stringify(payload), currentUser?.jwtToken)
        .then((response) => {
          Swal.fire({
            title: "Processing, please wait",
            html: "loading...",
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          if (response.status === 401) {
            cognitoSignOut()
              .then(() => {
                navigate("/");
              })
              .catch((err) => {
                console.error(err);
              });
          } else if (response?.status === 200 || response?.status === 201) {
            Swal.close();
            showSuccessAlert();
          } else if (response?.status === 400) {
            Swal.close();
            showFailAlert("Create Enquiry failed due to invalid data");
          }
        })
        .catch((error) => {
          Swal.close();
          ConsoleError(error);
          showFailAlert(ERROR_MESSAGE);
        });
    }
  };
  const showSuccessAlert = () => {
    let title = "Saved";
    let text = "Enquiry saved successfully";
    let type = "success";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      target
    ).then((result) => {
      if (result.value) {
        navigate("/enquiryview");
      }
    });
  };
  const showFailAlert = (response) => {
    let title = "Failed";
    let text = response;
    let type = "error";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(title, text, type, confirmButtonText, showCancelButton, target);
  };
  const validate = (values) => {
    const errors = {};
    if (!values.enquiry_summary) {
      errors.enquiry_summary = "Enquiry Summary is required";
    }
    if (!values.enquiry_notes) {
      errors.enquiry_notes = "Notes is required";
    }
    if (selectedTebList.length < 1) {
      errors.teb_id = "TEB is required";
    }
    if (!values.requested_by) {
      errors.requested_by = "Enquired by is required";
    }
    if (!values.requested_date) {
      errors.requested_date = "Enquiry date is required";
    }

    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors, isSubmit]);

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-lg-100 p-0 body">
      <div className="row flex pl-4 pt-4 pr-4 pb-0">
        <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden">
          <div className="row pt-1 pb-2 mt-5">
            <div className="d-flex justify-content-end">
              <div className="p-0">
                <Link to="/enquiryview">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    id="checkin-records-button"
                  >
                    ENQUIRY RECORDS
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <MDBCard className="card h-md-100 p-0 m-0">
            <CustomHeader label={" ENQUIRY"} />
            <MDBCardBody id="single-scroll">
              <div className="row col-lg-12 justify-content-center pt-2 pl-5">
                ENQUIRY DETAILS
              </div>
              <div className="card-border">
                <div className="form-row">
                  <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                    <SearchPanel
                      label={t("labels.teb")}
                      placeholder="Search by TEB Number"
                      options={tebOptionList}
                      setOptionList={setTebOptionList}
                      setShowOptions={setShowTebOptions}
                      name="teb_id"
                      id="teb_id"
                      showOptions={showTebOptions}
                      onFocusSearch={(e) => {
                        setTebSearchString("");
                      }}
                      handleChange={(e) => {
                        if (isTebNameAssigned) {
                          setTebSearchString(
                            e.target.value.charAt(e.length - 1)
                          );
                          setTebNameAssigned(false);
                          setShowTebOptions(true);
                        } else {
                          setTebSearchString(e.target.value);
                        }
                        setShowTebOptions(true);
                      }}
                      searchString={tebSearchString}
                      onSelectValue={(selectedTeb) => {
                        setTebNameAssigned(true);
                        setShowTebOptions(false);
                        const teb = tebList.filter(
                          (row) =>
                            row?.tamper_evident_bag_number === selectedTeb
                        )[0];
                        setTebSearchString("");
                        setSelectedTebList([teb, ...selectedTebList]);
                        if (
                          formErrors["teb_id"] !== undefined &&
                          formErrors["teb_id"].length > 0
                        ) {
                          delete formErrors["teb_id"];
                        }
                      }}
                    />

                    <i
                      className="fa fa-barcode float-right pr-2 input-scanner-icon h2"
                      aria-hidden="true"
                      title="Scan Tamper Evident Bag"
                    ></i>
                    <div className="error-message-validation">
                      {formErrors.teb_id}
                    </div>
                  </div>

                  {selectedTebList.length > 0 && (
                    <SearchSelectedListTable
                      label={"TAMPER EVIDENT BAGS"}
                      headers={ConfigData.viewSelectedTEBDetails}
                      recordsList={selectedTebList}
                      responseKeys={ConfigData.viewTEBListResponseKeys}
                      onClickDeleteItem={(teb, idx) =>
                        onClickDeleteTEB(teb, idx)
                      }
                    />
                  )}
                </div>

                <div className="form-row mt-2">
                  <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                    <label htmlFor="requested_by">Enquired By</label>
                    <input
                      type="text"
                      className="form-control"
                      id="requested_by"
                      placeholder="Enter Enquired By"
                      autoComplete="off"
                      name="requested_by"
                      value={formValues.requested_by}
                      onChange={onValueChange}
                    />
                    <div className="error-message-validation">
                      {formErrors.requested_by}
                    </div>
                  </div>
                  <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                    <label htmlFor="requested_date">Enquired Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id="requested_date"
                      autoComplete="off"
                      name="requested_date"
                      value={formValues.requested_date}
                      onChange={onValueChange}
                      max={maxRequestedDate}
                      min={minRequestedDate}
                    />
                    <div className="error-message-validation">
                      {formErrors.requested_date}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                    <label htmlFor="enquiry_summary">Enquired Summary</label>
                    <textarea
                      className="form-control"
                      rows="5"
                      name="enquiry_summary"
                      id="enquiry_summary"
                      placeholder="Enter Summary"
                      value={formValues.enquiry_summary}
                      onChange={onValueChange}
                      required
                    ></textarea>
                    <div className="error-message-validation">
                      {formErrors.enquiry_summary}
                    </div>
                  </div>
                  <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                    <label htmlFor="enquiry_notes">Notes</label>
                    <textarea
                      className="form-control"
                      rows="5"
                      name="enquiry_notes"
                      id="enquiry_notes"
                      placeholder="Enter Notes"
                      value={formValues.enquiry_notes}
                      onChange={onValueChange}
                      required
                    ></textarea>
                    <div className="error-message-validation">
                      {formErrors.enquiry_notes}
                    </div>
                  </div>
                  <div
                    className="form-group col-md-6 col-xs-4  col-lg-2 px-4"
                    id={`document_group_1`}
                  >
                    <input
                      type="file"
                      id={`input_document`}
                      multiple
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleAddDocument(e);
                      }}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    ></input>

                    <label
                      htmlFor={`input_document`}
                      className="btn btn-primary btn-sm mt-3"
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      <i
                        id={`icon_upload`}
                        className="fa fa-upload fa-1x me-1"
                        aria-hidden="true"
                      ></i>{" "}
                      Document
                    </label>
                  </div>
                  <div className="form-group col-md-6 col-xs-4  col-lg-2 px-4">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm mt-3"
                      onClick={() => {
                        setIsModelEnabled(true);
                      }}
                    >
                      <i className="fa fa-camera " aria-hidden="true"></i> Add
                      Picture
                    </button>
                  </div>
                </div>
              </div>
              {(documentList.length > 0) && <>
                <div className="row col-lg-12 justify-content-center">
                  DOCUMENTS
                </div>
                <div className="row card-border">

                  <DocumentHorizontal
                    itemId="enquiry"
                    id={`documents_horizontal`}
                    documentList={documentList}
                    onDeleteDocument={handleDeleteDocument}
                    onUpdateDocComment={handleUpdateDocumentComment}
                  />
                </div>
              </>}
              {(pictureList.length > 0) &&
                <div
                  className="row col-lg-12 justify-content-center"
                >
                  PICTURES
                </div>}
              <div className={pictureList.length > 0 ? "row card-border" : ""}>
                <div className="form-row align-items-end">
                  <div className="form-group col-12 px-4">
                    <WebcamCapture
                      itemId="enquiry"
                      pictureList={pictureList}
                      onCapturePicture={(picture, itemId) =>
                        onCapturePicture(picture, itemId)
                      }
                      onDeletePicture={(index, itemId) =>
                        OnDeletePicture(index, itemId)
                      }
                      isModelEnabled={isModelEnabled}
                      disableModal={() => {
                        setIsModelEnabled(false);
                      }}
                    ></WebcamCapture>
                  </div>
                </div>
              </div>
              <PerformedBy />
              <div className="fieldArea7 pt-2 ">
                <button
                  onClick={cancelButton}
                  className="btn btn-sm btn-primary"
                  id="cancel_enquiry"
                  type="reset"
                  value="Reset"
                  style={{ color: "#fff" }}
                >
                  CANCEL
                </button>
                <button className="btn btn-sm btn-primary" onClick={onSubmit}>
                  SUBMIT
                </button>
              </div>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </div>
  );
}
export default CreateEnquriy;
