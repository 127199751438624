import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = { forceOpenData: {} };

export const forceOpenDataSlice = createSlice({
  name: "forceOpenData",
  initialState: { value: initialStateValue },
  reducers: {
    forceOpenData: (state, action) => {
      state.value = { forceOpenData: action.payload };
    },
  },
});

export const { forceOpenData } = forceOpenDataSlice.actions;

export default forceOpenDataSlice.reducer;
