import { MDBCard, MDBCardBody } from "mdbreact";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { getViewEnquiryData } from "../api/CreateEnquiryService";
import CustomHeader from "../components/CustomHeader";
import Pagination from "../components/Pagination";
import Search from "../components/Search";
import "../style/CreateEnquiry.css";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import { ConsoleError } from "../utils/ErrorUtils";
import { DISPLAY_DATE_FORMAT } from "../constants/Constants";
import useFetchNotifications from "../utils/NotificationUtils";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(tz);

function EnquiryView() {
  const navigate = useNavigate();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const notificationResponse = useFetchNotifications(
    currentUser?.employee_id,
    currentUser?.jwtToken
  );
  const localTimezone = dayjs.tz.guess();
  const [state, setState] = useState({
    enquiries: [],
    currentPage: 1,
    pages: [],
    rowCount: 10,
    saveLoader: false,
    noRecordsFound: "",
    searchValue: "",
  });

  const {
    enquiries,
    currentPage,
    pages,
    rowCount,
    saveLoader,
    noRecordsFound,
  } = state;

  const setLoader = (status) =>
    setState((prev) => ({ ...prev, saveLoader: status }));

  const handleApiResponse = (response, onSuccess) => {
    if (response.status === 401) {
      cognitoSignOut()
        .then(() => navigate("/"))
        .catch(ConsoleError);
    } else if (response.status === 200) {
      onSuccess(response);
    }
  };

  const fetchEnquiries = (
    page = 1,
    rowsPerPage = rowCount,
    searchValue = ""
  ) => {
    setLoader(true);
    getViewEnquiryData(
      searchValue,
      page,
      rowsPerPage,
      currentUser?.employee_id,
      currentUser?.jwtToken
    )
      .then((response) => {
        handleApiResponse(response, (res) => {
          const { enquiries, pagination } = res.data;
          const totalPages = pagination?.pages || 1;

          setState((prev) => ({
            ...prev,
            enquiries,
            rowCount: pagination?.per_page || 10,
            currentPage: page,
            pages: [...Array(totalPages)].map((_, i) => i + 1),
            noRecordsFound: enquiries?.length
              ? ""
              : "No enquiry records available",
          }));
        });
      })
      .catch((error) => {
        ConsoleError(error);
        setState((prev) => ({
          ...prev,
          enquiries: [],
          noRecordsFound: "No enquiry records available",
        }));
      })
      .finally(() => setLoader(false));
  };

  const handlePageChange = (page) => {
    if (page === currentPage) return;
    fetchEnquiries(page);
  };

  const handleSearchData = ({ searchValue, enquiries, pagination }) => {
    if (searchValue.trim().length === 0) {
      setState((prev) => ({
        ...prev,
        searchValue:""
      })
      );
      fetchEnquiries(1, rowCount, ""); // Fetch all records if search value is empty
    } else {
      const totalPages = pagination?.pages || 1;

      setState((prev) => ({
        ...prev,
        enquiries,
        currentPage: 1,
        pages: [...Array(totalPages)].map((_, i) => i + 1),
        noRecordsFound: enquiries?.length ? "" : "No enquiry records available",
        searchValue:searchValue
      }));
    }
  };

  useEffect(() => {
    fetchEnquiries();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 overflow-hidden body">
      <div className="row flex p-4">
        <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden pt-5">
          <div className="row pt-2">
            <div className="col-lg-6 col-md-10">
              <Search
                screen="Enquiries"
                pageSize={rowCount}
                filterData={handleSearchData}
              />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 "></div>
            <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 ">
              <button
                className="btn btn-primary btn-sm col-lg-6 col-md-6 col-sm-11 m-0 pl-0 pr-0 float-end"
                onClick={() => fetchEnquiries(1, rowCount, state.searchValue)}
              >
                Refresh
              </button>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 ">
              {currentUser?.enquiry_save_record_access_flag && (
                <Link to="/createEnquiry">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm col-lg-11 col-md-11 col-sm-11 m-0 float-end"
                    id="checkin-records-button"
                  >
                    NEW ENQUIRY
                  </button>
                </Link>
              )}
            </div>
          </div>

          <MDBCard className="card overflow-hidden h-md-100 table-box">
            <CustomHeader label={"ENQUIRY RECORDS"} />
            <MDBCardBody>
              <div className="table-responsive">
                <table className="table safeChiefTable">
                  <thead className="headerArea">
                    <tr>
                      <th scope="col" className="tableHeading1">
                        TAMPER EVIDENT BAG #
                      </th>
                      <th scope="col" className="tableHeading">
                        ENQUIRED BY
                      </th>
                      <th scope="col" className="tableHeading">
                        ENQUIRED DATE
                      </th>
                      <th scope="col" className="tableHeading">
                        PERFORMED BY
                      </th>
                    </tr>
                  </thead>

                  <tbody id="cs-scroll">
                    {saveLoader ? (
                      <div className="text-center py-5 alignself-center">
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      enquiries.map((item, index) => (
                        <tr
                          key={index}
                          className="table-row"
                          onClick={() =>
                            navigate("/enquiryHistory", { state: item })
                          }
                        >
                          <td className="tableDetails1">
                            {
                              item?.tamper_evident_bag
                                ?.tamper_evident_bag_number
                            }
                          </td>
                          <td className="tableDetails">{item?.requested_by}</td>
                          <td className="tableDetails shipmentDateBody">
                            {dayjs(item?.requested_date)
                              .tz(localTimezone)
                              .format(DISPLAY_DATE_FORMAT)}
                          </td>
                          <td className="tableDetails shipmentBody5">
                            {item?.performed_by?.employee_name}
                          </td>
                        </tr>
                      ))
                    )}
                    {!saveLoader && enquiries.length === 0 && (
                      <div className="text-center py-5 alignself-center">
                        {noRecordsFound}
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </MDBCardBody>
            <Pagination
              pages={pages}
              onNextClick={() => handlePageChange(currentPage + 1)}
              onPreviousClick={() => handlePageChange(currentPage - 1)}
              onPageClick={handlePageChange}
              setRowsPerPage={(rows) => fetchEnquiries(1, rows)}
              currentPage={currentPage}
            />
          </MDBCard>
        </div>
      </div>
    </div>
  );
}

export default EnquiryView;
