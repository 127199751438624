import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { DISPLAY_DATE_TIME_FORMAT } from "../../constants/Constants";
import { getTebDetails } from "../../api/CheckOutService";
import { cognitoSignOut } from "../../api/CognitoServices";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConsoleError } from "../../utils/ErrorUtils";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

function ViewIRPTEBHistory() {
  let navigate = useNavigate();

  const { state } = useLocation();

  // eslint-disable-next-line
  const [saveLoader, setSaveLoader] = useState(false);
  const [tebData, setTebData] = useState([]);

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  useEffect(() => {
    setSaveLoader(true);
    getTebDetails(
      state.tamper_evident_bag?.tamper_evident_bag_id,
      currentUser?.jwtToken
    ).then((response) => {
      if (response.status === 401) {
        cognitoSignOut()
          .then(() => {
            navigate("/");
          })
          .catch((error) => {
            ConsoleError(error);
          });
      } else if (response.status === 200) {
        setSaveLoader(false);
        let data = response?.data;
        setTebData(data);
      }
    });
  }, []);

  dayjs.extend(utc);
  dayjs.extend(tz);
  const localTimezone = dayjs.tz.guess();

  return (
    <div className="d-md-flex align-items-center justify-content-center px-0 row pt-5 ">
      <div className="align-items-center pt-5">
        <div className="row white-background px-3">
          <div className="event-div" id="event_div">
            <div className="d border border-5 event-border h-100 ml-5 mr-5">
              <div className="row col-lg-12 justify-content-center pt-3 pb-3">
                {"EVENT DETAILS"}
                <div className="irpButtonStyle">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm float-left mt-3"
                    onClick={() =>
                      navigate("/admin", { state: { activeTab: 2 } })
                    }
                  >
                    IRP Data
                  </button>
                </div>
              </div>

              <form>
                <div className="modal-body row">
                  <div className="row col-lg-6 col-md-6 col-sm-6 ml-2 pb-3 bs-scroll">
                    <div className="form-group row">
                      <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                        CheckOut DateTime{" "}
                      </label>
                      <div className="col-sm-8 p-0">
                        <div className="float-start">
                          {dayjs(state?.create_datetime)
                            .tz(localTimezone)
                            .format(DISPLAY_DATE_TIME_FORMAT)}
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                        Tamper Evident Bag#{" "}
                      </label>
                      <div className="col-sm-8 p-0">
                        <div className="float-start">
                          {state.tamper_evident_bag?.tamper_evident_bag_number}
                        </div>
                      </div>
                    </div>

                    {tebData !== null &&
                      tebData !== undefined &&
                      tebData.map((item) => {
                        return (
                          <>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Name
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {item?.entity[0]?.entity_name}
                                </div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Phone Number
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {item?.entity[0]?.address?.phone_number_1}
                                </div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Email-Id
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {item?.entity[0]?.address?.email_id}
                                </div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Address
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {item?.entity[0]?.address?.street_1}
                                </div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Zip Code
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {item?.entity[0]?.address?.zipcode}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewIRPTEBHistory;
