import { MDBCard, MDBCardBody } from "mdbreact";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import CustomHeader from "../components/CustomHeader";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import "../style/ForceOpenDetails.css";
import Swal from "sweetalert2";
import { getHistory } from "../api/History";
import { useSelector } from "react-redux";

function ForceOpenDetails() {
  const { state } = useLocation();
  const download = (link, name) => {
    saveAs(link, name);
  };
  const [show, setShow] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const [forceOpenHistory, setForceOpenHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleClose = () => setShow(false);
  const index = forceOpenHistory?.activity_list?.findIndex(
    (x) => x.event === "force_open"
  );
  const sharesInStocks =
    forceOpenHistory?.addendum_c?.find((x) => x.stocks !== null) || null;

  const tebId = state?.tamper_evident_bag_id || null;
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  useEffect(() => {
    if (tebId) {
      setLoading(true);
      setError(null);

      getHistory("force_open", tebId, 1, 1, currentUser?.jwtToken)
        .then((response) => {
          if (response.status === 200) {
            setForceOpenHistory(response.data[0]);
          } else {
            const userFriendlyMessage =
              "Unable to fetch force open details. Please try again later.";
            setError(userFriendlyMessage);
            Swal.fire({
              icon: "error",
              title: "Oops!",
              text: userFriendlyMessage,
            });
          }
        })  
        .catch((err) => {
          console.error("Error fetching force open details:", err);
          const userFriendlyMessage =
            "Something went wrong while fetching the data. Please try again.";
          setError(userFriendlyMessage);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: userFriendlyMessage,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [tebId]);

  const handleDownload = (url, name) => {
    try {
      const link = document.createElement("a");
      link.href = url; 
      link.download = name;
      saveAs(url, name);
    } catch (error) {}
  };
  
  return (
    <div className="col-lg-12 col-md-12 col-sm-12  p-0 ">
      <div className="p-0" id="top_div">
        <Link to="/home">
          <button
            type="button"
            className="btn btn-primary btn-sm"
            id="checkin-records-button"
          >
            HOME
          </button>
        </Link>
      </div>
      <div className="row flex pt-4 pl-4 pr-4 pb-0 ">
        {loading ? (
          <div className="loader-container">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : !forceOpenHistory ? (
          <div className="no-data">No data available</div>
        ) : (
          <div className="col-lg-12 pb-3 pl-0 pr-0" id="header">
            <MDBCard className="card p-0 m-0  ">
              <CustomHeader label={"FORCE OPEN DETAILS"} />
              <MDBCardBody>
                <div className="row text-center ">
                  <div className="event-div" id="event_div">
                    <div className="d border border-5 event-border h-100 ml-5 mr-5">
                      <div className="row col-lg-12 col-md-12 col-sm-12 ml-2 pb-3 bs-scroll">
                        <div id="safe_deposit_box_details">
                          Safe Deposit Box Details
                        </div>
                        <div className="row col-lg-6 col-md-6 col-sm-6 ml-2 pb-3 bs-scroll">
                          <table>
                            <tr className="table_row">
                              <th className="table_header">
                                Box drilled Status
                              </th>
                              <td className="table_details">
                                {forceOpenHistory?.activity_list[index]
                                  ?.information?.box_drilled
                                  ?.box_drilled_status === "True"
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            {forceOpenHistory?.activity_list[index]?.information
                              ?.box_drilled?.action_drill_type && (
                              <tr className="table_row">
                                <th className="table_header">
                                  Box drilled Type
                                </th>
                                <td className="table_details">
                                  {forceOpenHistory?.activity_list[index]
                                    ?.information?.box_drilled
                                    ?.action_drill_type
                                    ? forceOpenHistory?.activity_list[index]
                                        ?.information?.box_drilled
                                        ?.action_drill_type?.action
                                    : "Not Drilled"}
                                </td>
                              </tr>
                            )}
                            {forceOpenHistory?.activity_list[index]?.information
                              ?.box_drilled?.action && (
                              <tr className="table_row">
                                <th className="table_header">
                                  Box Not Drilled Reason
                                </th>
                                <td className="table_details">
                                  {forceOpenHistory?.activity_list[index]
                                    ?.information?.box_drilled?.action === null
                                    ? "-"
                                    : forceOpenHistory?.activity_list[index]
                                        ?.information?.box_drilled?.action
                                        ?.action}
                                </td>
                              </tr>
                            )}
                            <tr className="table_row">
                              <th className="table_header">Amount reported</th>
                              <td className="table_details">
                                {forceOpenHistory?.activity_list[index]
                                  ?.information?.box_drilled
                                  ?.amount_reported === null
                                  ? 0
                                  : forceOpenHistory?.activity_list[index]
                                      ?.information.box_drilled
                                      ?.amount_reported}
                              </td>
                            </tr>
                            <tr className="table_row">
                              <th className="table_header">Lock smith name</th>
                              <td className="table_details">
                                {
                                  forceOpenHistory?.activity_list[index]
                                    ?.information?.locksmith_name
                                }
                              </td>
                            </tr>
                            <tr className="table_row">
                              <th className="table_header">Notary name</th>
                              <td className="table_details">
                                {forceOpenHistory?.activity_list[index]
                                  ?.information?.notary_name
                                  ? forceOpenHistory?.activity_list[index]
                                      ?.information?.notary_name
                                  : null}
                              </td>
                            </tr>

                            <tr className="table_row">
                              <th className="table_header">Rental Due</th>
                              <td className="table_details">
                                {" "}
                                {forceOpenHistory?.activity_list[index]
                                  ?.information?.safe_deposit_box_fee
                                  ?.rental_due
                                  ? forceOpenHistory?.activity_list[index]
                                      ?.information?.safe_deposit_box_fee
                                      ?.rental_due
                                  : "0"}
                              </td>
                            </tr>
                          </table>
                        </div>

                        <div className="row col-lg-6 col-md-6 col-sm-6 ml-2 pb-3 bs-scroll">
                          <table>
                            <tr className="table_row">
                              <th className="table_header">Force Open Cost</th>
                              <td className="table_details">
                                {forceOpenHistory?.activity_list[index]
                                  ?.information?.safe_deposit_box_fee
                                  ?.force_open_cost
                                  ? forceOpenHistory?.activity_list[index]
                                      ?.information?.safe_deposit_box_fee
                                      ?.force_open_cost
                                  : "0"}
                              </td>
                            </tr>
                            <tr className="table_row">
                              <th className="table_header">Other Due</th>
                              <td className="table_details">
                                {forceOpenHistory?.activity_list[index]
                                  ?.information?.safe_deposit_box_fee?.other_due
                                  ? forceOpenHistory?.activity_list[index]
                                      ?.information?.safe_deposit_box_fee
                                      ?.other_due
                                  : "0"}
                              </td>
                            </tr>
                            <tr className="table_row">
                              <th className="table_header">Inventory Fee</th>
                              <td className="table_details">
                                {forceOpenHistory?.activity_list[index]
                                  ?.information?.safe_deposit_box_fee
                                  ?.inventory_fee
                                  ? forceOpenHistory?.activity_list[index]
                                      ?.information?.safe_deposit_box_fee
                                      ?.inventory_fee
                                  : "0"}
                              </td>
                            </tr>
                            <tr className="table_row">
                              <th className="table_header">Other Due</th>
                              <td className="table_details">
                                {forceOpenHistory?.activity_list[index]
                                  ?.information?.safe_deposit_box_fee?.other_due
                                  ? forceOpenHistory?.activity_list[index]
                                      ?.information?.safe_deposit_box_fee
                                      ?.other_due
                                  : "0"}
                              </td>
                            </tr>

                            <tr className="table_row">
                              <th className="table_header">Total Due</th>
                              <td className="table_details">
                                {forceOpenHistory?.activity_list[index]
                                  ?.information?.safe_deposit_box_fee?.total_due
                                  ? forceOpenHistory?.activity_list[index]
                                      ?.information?.safe_deposit_box_fee
                                      ?.total_due
                                  : "0"}
                              </td>
                            </tr>
                            <tr className="table_row">
                              <th className="table_header">
                                Past Due Rental Fee
                              </th>
                              <td className="table_details">
                                {forceOpenHistory?.activity_list[index]
                                  ?.information?.safe_deposit_box_fee
                                  ?.past_due_rental_fee
                                  ? forceOpenHistory?.activity_list[index]
                                      ?.information?.safe_deposit_box_fee
                                      ?.past_due_rental_fee
                                  : "0"}
                              </td>
                            </tr>
                          </table>
                        </div>

                        {forceOpenHistory?.activity_list[index]?.information
                          ?.documents?.length > 0 && (
                          <div className="form-group row my-5 mx-3">
                            <label
                              className=" d-flex col-sm-4 col-form-label input-label p-0"
                              id="uploaded_document_label"
                            >
                              Uploaded Document Name{" "}
                            </label>
                            <div className="col-sm-8 p-0">
                              <div className="row">
                                <div className="col-8 d-flex">
                                  <span
                                    className="cursor-pointer float-start"
                                    onClick={() =>
                                      handleDownload(
                                        forceOpenHistory?.activity_list[index]
                                          ?.information?.documents[0]?.url,
                                        forceOpenHistory?.activity_list[index]
                                          ?.information?.documents[0]?.name
                                      )
                                    }
                                  >
                                    {forceOpenHistory?.activity_list[index]
                                      ?.information?.documents[0]?.name
                                      ? forceOpenHistory?.activity_list[index]
                                          ?.information?.documents[0]?.name
                                      : null}
                                    <i className="fa fa-download pl-2" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div id="tamper_evident_bag_details">
                          {" "}
                          Tamper Evident Bag Details
                        </div>

                        <table className="my-0">
                          <tbody>
                            <tr>
                              <th className="table_headings">
                                Addendum Category
                              </th>
                              <th className="table_headings">
                                Addendum Category Name
                              </th>
                              <th className="table_headings">Item Name</th>
                              <th className="table_headings">Quantity</th>
                              {sharesInStocks && (
                                <th className="table_headings">
                                  Number of Shares
                                </th>
                              )}
                              <th className="table_headings">Documents</th>
                              <th className="table_headings">Pictures</th>
                            </tr>
                            {forceOpenHistory?.addendum_a_b.map((addendum) => {
                              return (
                                <tr>
                                  <td>
                                    {
                                      addendum?.addendum_item?.addendum_category
                                        ?.addendum_type
                                    }
                                  </td>
                                  <td>
                                    {
                                      addendum?.addendum_item?.addendum_category
                                        ?.category_name
                                    }
                                  </td>
                                  <td>{addendum?.addendum_item?.item_name}</td>
                                  <td>{addendum?.quantity}</td>
                                  {sharesInStocks && <td>-</td>}
                                  <td>
                                    <a
                                      onClick={() => {
                                        setDocuments(addendum?.documents);
                                        setDocumentType("DOCUMENT");
                                        setShow(true);
                                      }}
                                    >
                                      View Documents
                                    </a>
                                  </td>
                                  <td>
                                    <a
                                      onClick={() => {
                                        setDocuments(addendum?.documents);
                                        setDocumentType("PICTURE");
                                        setShow(true);
                                      }}
                                    >
                                      View Pictures
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                            {forceOpenHistory?.addendum_c.map((addendum) => {
                              return (
                                <tr>
                                  <td>
                                    {
                                      addendum?.addendum_item?.addendum_category
                                        ?.addendum_type
                                    }
                                  </td>
                                  {addendum?.stock &&
                                    addendum?.stock.map((stock_details) => {
                                      return (
                                        <>
                                          <td>Stocks</td>
                                          <td>{stock_details?.title}</td>
                                          <td>{stock_details?.quantity}</td>
                                          <td>
                                            {stock_details?.number_of_shares}
                                          </td>
                                        </>
                                      );
                                    })}
                                  {addendum?.miscellaneous &&
                                    addendum?.miscellaneous.map(
                                      (miscellaneous_details) => {
                                        return (
                                          <>
                                            <td>Miscellaneous</td>
                                            <td>
                                              {miscellaneous_details?.title}
                                            </td>
                                            <td>
                                              {miscellaneous_details?.quantity}
                                            </td>
                                            {sharesInStocks && <td>-</td>}
                                          </>
                                        );
                                      }
                                    )}
                                  {addendum?.other_bonds &&
                                    addendum?.other_bonds.map(
                                      (other_bonds_details) => {
                                        return (
                                          <>
                                            <td>Other Bonds</td>
                                            <td>
                                              {other_bonds_details?.title}
                                            </td>
                                            <td>
                                              {other_bonds_details?.quantity}
                                            </td>
                                            {sharesInStocks && <td>-</td>}
                                          </>
                                        );
                                      }
                                    )}
                                  {addendum?.contra_band &&
                                    addendum?.contra_band.map(
                                      (contra_band_details) => {
                                        return (
                                          <>
                                            <td>Contra Band</td>
                                            <td>
                                              {contra_band_details?.title}
                                            </td>
                                            <td>
                                              {contra_band_details?.quantity}
                                            </td>
                                            {sharesInStocks && <td>-</td>}
                                          </>
                                        );
                                      }
                                    )}

                                  <td>
                                    <a
                                      onClick={() => {
                                        setDocuments(addendum?.documents);
                                        setDocumentType("DOCUMENT");
                                        setShow(true);
                                      }}
                                    >
                                      View Documents
                                    </a>
                                  </td>
                                  <td>
                                    <a
                                      onClick={() => {
                                        setDocuments(addendum?.documents);
                                        setDocumentType("PICTURE");
                                        setShow(true);
                                      }}
                                    >
                                      View Pictures
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </div>
        )}
      </div>
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>{documentType}S</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            {documents
              ?.filter((document) => document?.type === documentType)
              .map((filteredDocument) => {
                return (
                  <div
                    className="cursor-pointer float-start"
                    onClick={() =>
                      handleDownload(filteredDocument?.url, filteredDocument?.name)
                    }
                  >
                    {filteredDocument?.name}
                    <i className="fa fa-download pl-2" />
                  </div>
                );
              })}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => handleClose()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
}

export default ForceOpenDetails;
