import { getHeader } from "../utils/Headers";
import service from "./service";
import axios from "axios";
import { NEW_CUSTOM_BULK } from "../constants/ApiUrlConstants";
import { ConsoleError } from "../utils/ErrorUtils";

export const getCustomData = async (dataSource, token) => {
  //   let data = JSON.stringify(dataSource);

  return axios
    .post(service.baseUrl + NEW_CUSTOM_BULK, dataSource, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};

export const getMergeData = async (dataSource, token) => {
  return axios
    .post(service.baseUrl + NEW_CUSTOM_BULK, dataSource, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
    });
};
