import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { verifySystemUser } from "../api/SystemUserService";
import { CustomAlert } from "../components/customAlert";
import { ConsoleError } from "../utils/ErrorUtils";
import Swal from "sweetalert2";

function VerifyProductSetUp() {
  const [systemUserName, setSystemUserName] = useState("");
  const [systemUserPswd1, setSystemUserPswd1] = useState("");
  //eslint-disable-next-line
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const alert = (response) => {
    let title = "System User Verification";
    let text = response?.data?.message;
    let type = response?.data?.status === "FAILURE" ? "error" : "success";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let cancelButtonText = "CANCEL";
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      cancelButtonText
    ).then((result) => {
      if (result.value === true) {
        navigate("/verifyuser");
      }
    });
  };

  const verifyAndCreateSuperAdminUser = () => {
    Swal.fire({
      title: "Processing, please wait",
      html: "loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    const payload = {
      system_user_name: systemUserName,
      system_user_password: systemUserPswd1,
      employee_id: currentUser.employee_id,
    };
    verifySystemUser(payload, currentUser?.jwtToken)
      .then((response) => {
        Swal.close();
        if (response.status === 401) {
        } else if (response.status === 201 || response.status === 200) {
          alert(response);
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };

  function validateSystemUserName(e) {
    setSystemUserName(e.target.value);
  }

  function validateSystemUserPassword1(e) {
    setSystemUserPswd1(e.target.value);
  }

  return (
    <>
      <div className="d-md-flex align-items-center justify-content-center pl-5 pr-5 row pt-5 ">
        <div className="align-items-center pt-5">
          <div className="row white-background">
            <div className="col-12 py-3 ">Please enter your sytem user.</div>
          </div>
          <form name="create_system_user">
            <div className="row white-background">
              <div className="col-lg-4 py-3 ">
                <label className="pr-2 float-left">System User Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={systemUserName}
                  onChange={validateSystemUserName}
                />
              </div>
            </div>
            <div className="row white-background">
              <div className="col-lg-4 py-3 ">
                <label className="pr-2 float-left" htmlFor="password">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={systemUserPswd1}
                  onChange={validateSystemUserPassword1}
                />
              </div>
              <div className="error-message-validation">
                {formErrors.password}
              </div>
            </div>

            <div className="row white-background">
              <div className="col-lg-4 py-3">
                <button
                  type="button"
                  className="btn btn-primary btn-sm float-right"
                  onClick={verifyAndCreateSuperAdminUser}
                >
                  VERIFY
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default VerifyProductSetUp;
