import React from "react";
import "../style/inputupload.css";
import upload from "../assets/upload.png";

const Inputupload = (props) => {
  const { onChange } = props;
  return (
    <>
      <div>
        <div>
          <img
            className="upload-color"
            style={{
              height: "10%",
              width: "10%",
              textAlign: "center",
            }}
            alt="Add"
            src={upload}
          />{" "}
        </div>
        <label htmlFor="file" style={{ cursor: "pointer", fontSize: "14px" }}>
          UPLOAD FILE
        </label>{" "}
        <div>
          <input
            type="file"
            name="file"
            id="file"
            className="inputfile"
            onChange={onChange}
          />
        </div>
      </div>
    </>
  );
};

export default Inputupload;
