import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";
import { GET_NOTIFICATIONS } from "../constants/ApiUrlConstants";
import { MAX_ALLOWED_NOTIFICATION_COUNT } from "../constants/NotificationConstants";

export const getNotifications = async (empId, token) => {
  return axios
    .get(
      service.baseUrl +
        GET_NOTIFICATIONS +
        "?emp_id=" +
        empId +
        "&count=" +
        MAX_ALLOWED_NOTIFICATION_COUNT,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
