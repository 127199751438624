import { Modal } from "react-bootstrap";
import CustomHeader from "../components/CustomHeader";
import Delete from "../assets/deleteWarehouse.png";
import Edit from "../assets/editWarehouse.png";
import Pagination from "../components/Pagination";
import Spinner from "react-bootstrap/Spinner";

const WarehouseUnitsModal = ({
  dropDownType,
  displayUnits,
  clearData,
  warehousePages,
  onNextClickWareHouse,
  onPreviousWareHouse,
  onWareHousePageClick,
  wareHouseInitial,
  wareHouseEnd,
  setWarehouseRowsPage,
  displayModals,
  warehouseUnitsSuggestions,
  displaySpinner,
}) => {
  return (
    <Modal
      className="modal-dialog modal-xl"
      show={dropDownType.length > 0 ? true : false}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="no-border">
        <>
          <div className="modalHeaderdiv">
            <CustomHeader label={"VIEW STORAGE UNITS"} />
            <input
              id="search-bar"
              className="form-control form-control2 pr-0"
              placeholder="Search For AILSE , BAY, SHELF , TOTE Id's"
              onChange={(e) =>
                warehouseUnitsSuggestions(e.target.value, dropDownType)
              }
              autoComplete="off"
            />
          </div>
        </>
      </Modal.Header>

      {displaySpinner ? (
        <Modal.Body className=" modalheight">
          <Spinner
            animation="border"
            size="xl"
            className="viewStorageSpinner"
          />
        </Modal.Body>
      ) : (
        <Modal.Body id="scroll" className="scrollable show-grid modalheight">
          {displayUnits !== undefined && displayUnits.length > 0 ? (
            displayUnits.map((item, idx) => (
              <>
                <div className="addStorageUnit rowDivisomForSm">
                  <div className="form-row">
                    <div className="form-row">
                      <label
                        id="name"
                        className="labelSmallScreen font-weight-bold"
                      >
                        Aisle Id :{" "}
                      </label>
                      <div className="inputDiv viewStorageEachBlockInput">
                        <label className=" inputStorageUnit2 viewStorageDeleteIcon">
                          {item.aisle_number}
                        </label>
                      </div>
                    </div>

                    <div>
                      <div className="form-row viewStorageEachBlock">
                        <label className="labelSmallScreen font-weight-bold">
                          Bay Id : &nbsp;
                        </label>
                        <div className="inputDiv viewStorageEachBlockInput">
                          <label className=" inputStorageUnit2">
                            {item.bay_number}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="form-row viewStorageEachBlock">
                        <label className="labelSmallScreen font-weight-bold">
                          Shelf Id :{" "}
                        </label>
                        <div className="inputDiv viewStorageEachBlockInput">
                          <label className=" inputStorageUnit2">
                            {item.shelf_number}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-row  viewStorageToteBlockDiv">
                      <label className="labelSmallScreen font-weight-bold">
                        Tote Id :{" "}
                      </label>
                      <div className="inputDiv viewStorageToteBlock">
                        <label className="inputStorageUnit2">
                          {item.tote_number}
                        </label>
                      </div>
                    </div>

                    <div
                      className="center-image3 "
                      onClick={() => {
                        displayModals("Edit", item);
                      }}
                    >
                      <img className="minusIcon2" src={Edit} alt="Edit" />
                    </div>

                    <div className="center-image3 buttonspace">
                      <label
                        onClick={() => {
                          displayModals("Add", item);
                        }}
                        className="font-weight-bold plusIcon2"
                      >
                        +
                      </label>
                    </div>
                    <div
                      onClick={() => {
                        displayModals("Delete", item);
                      }}
                      className="center-image3 buttonspace viewStorageDeleteIcon2"
                    >
                      <img src={Delete} alt="Remove" />
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <p className="viewStorageRecordsNotFound">No Records Found</p>
          )}
        </Modal.Body>
      )}
      {/* )} */}
      <Modal.Footer className="modalFooter  p-1 no-border modal-footer">
        <div className="modalHeaderdiv">
          <button
            onClick={() => clearData("WarehouseUnits", "CANCEL")}
            className="btn btn-sm modalbtn viewStorageCloseButtonAlignment"
          >
            Close
          </button>
          <Pagination
            className="form-control "
            pages={warehousePages}
            onNextClick={onNextClickWareHouse}
            onPreviousClick={onPreviousWareHouse}
            onPageClick={onWareHousePageClick}
            initial={wareHouseInitial}
            // end={wareHouseEnd}
            setRowsPerPage={setWarehouseRowsPage}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default WarehouseUnitsModal;
