import React, { useState } from "react";
import * as XLSX from "xlsx";
import "../../style/bulkshipment.css";
import { CustomTable } from "../../components/customTable";
import Inputupload from "../../components/Inputupload";
import { Pager } from "../../components/pager";
import DocumentUpload from "../../components/documentUpload";
import { CustomAlert } from "../../components/customAlert";
import CustomModal from "../CustomModal";
import { PrintDisable } from "../../utils/PrintDisable";
import { useSelector } from "react-redux";

const StepOne = (props) => {
  const [currentEventData, setCurrentEventData] = useState({
    EventType: "Alert",
    EventData: "",
  });
  const [showMessage, setShowMessage] = useState(false);
  const EXTENSIONS = ["xlsx", "xls", "csv"];
  const [showData, setShowData] = useState([{}]);
  const [rows, setRows] = useState([]);

  const [pageState, setPageState] = useState({ pageNum: 1, rowsPerPage: 10 });
  const getExention = (file) => {
    const parts = file.name.split(".");
    const extension = parts[parts.length - 1];
    return EXTENSIONS.includes(extension);
  };
  const [fileName, setFileName] = useState("File Name ");
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  PrintDisable(currentUser?.print_access_flag);
  const importExcel = async (e) => {
    const file = e.target.files[0];
    setFileName(e.target.files[0].name);
    if (file) {
      if (getExention(file)) {
        if (file.size > 10e6) {
          setCurrentEventData({
            EventType: "Alert",
            EventData: "Please upload a file smaller than 10 MB",
          });
          setShowMessage(true);
        }
      } else {
        setCurrentEventData({
          EventType: "Alert",
          EventData: "Invalid file input, Select Excel, CSV file",
        });
        setShowMessage(true);
      }
      let defaultHeaders = [];
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          // const data = XLSX.utils.sheet_to_json(ws);
          let data = XLSX.utils.sheet_to_json(ws);

          // Clean up data (removing newlines and trimming cells)
          data = data.map((row) => {
            return Object.fromEntries(
              Object.entries(row).map(([key, value]) => [
                key,
                typeof value === "string"
                  ? value.replace(/\n/g, "").trim()
                  : value,
              ])
            );
          });
          let headers = XLSX.utils.sheet_to_json(ws, { header: 1 });
          defaultHeaders = headers[0];
          resolve(data);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
      promise.then((data) => {
        let flag = true;
        let removeDuplicateObjects = (data) => {
          let uniqueArr = [];
          let objStrings = [];
          data.forEach((element) => {
            if (typeof element === "object" && element !== null) {
              let eleString = JSON.stringify(element);
              if (!objStrings.includes(eleString)) {
                uniqueArr.push(element);
                objStrings.push(eleString);
              }
            }
          });
          return uniqueArr;
        };
        const filteredData = removeDuplicateObjects(data);
        if (
          defaultHeaders.includes("BAG#") &&
          defaultHeaders.includes("SDB#_DRILLED") &&
          defaultHeaders.includes("ORIGINAL_BRANCH_DID")
        ) {
          let list = [];
          filteredData.forEach((item, key) => {
            if (
              !(
                "BAG#" in item &&
                "SDB#_DRILLED" in item &&
                "ORIGINAL_BRANCH_DID" in item
              )
            ) {
              list.push(key);
            }
          });
          if (list.length > 0) {
            flag = false;
            setFileName("File Name");
            setShowData([]);
            CustomAlert(
              "Invalid or blank field",
              "Please check " + list + " record and upload again",
              "warning",
              "OK",
              false
            );
          }
        } else {
          flag = false;
          setFileName("File Name");
          setShowData([]);
          CustomAlert(
            "Missing fields",
            "Please check file and upload again",
            "warning",
            "OK",
            false
          );
        }
        if (flag === true) {
          setShowData(filteredData);
          props.onChange("step1", "fileuploadrows", filteredData);
        }
      });
    }
  };
  React.useEffect(() => {
    if (showData && showData.length) {
      const propsdata = [...showData];
      const idx = (pageState.pageNum - 1) * pageState.rowsPerPage;
      setRows(propsdata.slice(idx, idx + pageState.rowsPerPage));
    }
  }, [pageState, showData]);

  return (
    <>
      <CustomModal
        currentEventData={currentEventData}
        show={showMessage}
        onHide={() => setShowMessage(false)}
      />
      <div className="divUpload">
        <DocumentUpload onChange={importExcel} fileName={fileName} />
      </div>

      {props.formData?.step1.fileuploadrows?.length ? (
        <div>
          {props?.formData?.step1?.isEditInput ? (
            <>
              <CustomTable
                columns={[
                  {
                    title: "TAMPER_EVIDENT_BAG#",
                    field: "BAG#",
                  },
                  {
                    title: "SDB_Id",
                    field: "SDB#_DRILLED",
                  },
                  {
                    title: "OWNER'S_NAME",
                    field: "CUSTOMER_NAME",
                  },
                  {
                    title: "ORIGINAL_BRANCH_DID",
                    field: "ORIGINAL_BRANCH_DID",
                  },
                  {
                    title: "ACTION",
                    field: "UPDATE",
                  },
                ]}
                data={props?.formData?.step1?.fileuploadrows}
                isEditInput={props?.formData?.step1?.isEditInput}
                shipment_props={props}
              />
            </>
          ) : (
            <>
              <CustomTable
                columns={[
                  {
                    title: "TAMPER_EVIDENT_BAG#",
                    field: "BAG#",
                  },
                  {
                    title: "SDB_Id",
                    field: "SDB#_DRILLED",
                  },
                  {
                    title: "OWNER'S_NAME",
                    field: "CUSTOMER_NAME",
                  },
                  {
                    title: "ORIGINAL BRANCH DID",
                    field: "ORIGINAL_BRANCH_DID",
                  },
                ]}
                data={
                  rows.length > 1
                    ? rows
                    : props?.formData?.step1?.fileuploadrows
                }
                isEditInput={props?.formData?.step1?.isEditInput}
              />
            </>
          )}

          <Pager
            totalPages={
              showData.length
                ? Math.ceil(showData.length / pageState.rowsPerPage)
                : 0
            }
            currentPage={pageState.pageNum}
            onPageChange={(newPage) =>
              setPageState({
                pageNum: newPage,
                rowsPerPage: pageState.rowsPerPage,
              })
            }
            rowsPerPage={pageState.rowsPerPage}
            setRowsPerPage={(num) =>
              setPageState({ pageNum: 1, rowsPerPage: num })
            }
            dataCount={showData.length}
            dataLength={rows.length}
          />
        </div>
      ) : (
        <div className="d-flex row align-items-center justify-content-center vertical-middle text-center divTableMain">
          <Inputupload onChange={importExcel} label="Upload Document" />
        </div>
      )}
    </>
  );
};

export default StepOne;
