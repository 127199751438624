import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";
import { GET_SUB_STORAGE } from "../constants/ApiUrlConstants";

export const getSubStorageListOnsearch = async (
  parentStorageId,
  storageType,
  searchStr,
  token
) => {
  return axios
    .get(
      service.baseUrl +
        GET_SUB_STORAGE +
        "?storage_type_id=" +
        parentStorageId +
        "&storage_type=" +
        storageType +
        "&search_value="+
        searchStr,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response?.data;
    });
};
