export const validatePassword = (password) => {
  const rules = [
    {
      regex: /[A-Z]/,
      message: "Password must contain at least one uppercase letter.",
    },
    {
      regex: /[a-z]/,
      message: "Password must contain at least one lowercase letter.",
    },
    { regex: /[0-9]/, message: "Password must contain at least one number." },
    {
      regex: /[!@#$%^&*(),.?":{}|<>]/,
      message: "Password must contain at least one special character.",
    },
    {
      regex: /.{8,}/,
      message: "Password must be at least 6 characters long.",
    },
  ];

  for (const { regex, message } of rules) {
    if (!regex.test(password)) {
      return message; // Return the first error message encountered
    }
  }

  return ""; // No error, password is valid
};
