import React, { useState } from "react";
import AddPicture from "../assets/add.png";
import Delete from "../assets/Delete.png";
import pdf from "../assets/pdf.png";
import doc from "../assets/doc.png";
import sheets from "../assets/sheets.png";

const Documents = (props) => {
  //eslint-disable-next-line
  const [selectedFile, setSelectedFile] = useState([]);
  const handleAddImageRow = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        const rows = [...props.formData.step3.Documentrows];
        const imgSrcArray = reader.result.split(";")[0];
        let val = document.getElementById("inputImg");
        let file = val.files.item(0).name;
        const item = {
          ImgComments: "",
          imgSource: reader.result,
          imgType: imgSrcArray,
          filename: file,
        };

        props.onChange("step3", "Documentrows", [...rows, item]);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const handleRemoveSpecificRow = (idx) => {
    const tempRows = [...props.formData.step3.Documentrows];
    tempRows.splice(idx, 1);
    props.onChange("step3", "Documentrows", [...tempRows]);
  };
  //eslint-disable-next-line
  const [textValue, setValue] = useState();
  function updateState(e, index) {
    let prope = e.target.name;
    let fieldValue = e.target.value;
    setValue(e.target.value);
    const rows = [...props.formData.step3.Documentrows];
    const tempRows = [...rows];
    const tempObj = rows[index];
    tempObj[prope] = fieldValue;
    tempRows[index] = tempObj;
    props.onChange("step3", "Documentrows", tempRows);
  }

  return (
    <>
      {props.formData?.step3?.Documentrows.map((item, idx) => (
        <>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-4 divImageContainer ">
              <figure
                className="img-thumbnail btn-sm divFigureContainer "
                style={{ border: "none" }}
              >
                <img
                  className="imageFigure"
                  style={{ height: "100px" }}
                  alt="..."
                  src={(() => {
                    if (item.imgType === "data:image/png") {
                      return item.imgSource;
                    } else if (item.imgType === "data:application/pdf") {
                      return pdf;
                    } else if (
                      item.imgType ===
                      "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    ) {
                      return doc;
                    } else if (
                      item.imgType === "data:text/csv" ||
                      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                      return sheets;
                    }
                  })()}
                />
                <div>{item.filename}</div>
              </figure>
              {/* <div>{item.filename}</div> */}
            </div>
            <div className="col-lg-8 col-md-8 col-sm-8 divDetails">
              <div className="row">
                <label> Description : </label>
                <textarea
                  type="text"
                  id="inputID"
                  placeholder="Enter Comments"
                  name="ImgComments"
                  onChange={(e) => updateState(e, idx)}
                  value={props.formData?.section3?.comments}
                />
                <img
                  style={{
                    height: "30px",
                    width: "30px",
                    paddingBottom: "15px",
                    paddingLeft: "15px",
                    paddingRight: "5px",
                  }}
                  src={Delete}
                  className="figure-img img-fluid rounded"
                  alt=" "
                  onClick={() => handleRemoveSpecificRow(idx)}
                />
              </div>
            </div>
          </div>
        </>
      ))}

      <div className="row">
        <div
          className="col-lg-3 col-md-3 col-sm-4"
          style={{
            paddingLeft: "50px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <figure
            id="imgUpload"
            className="img-thumbnail btn-sm backgroundColor none"
          >
            <img
              style={{
                height: "25%",
                width: "25%",
                marginLeft: "50px",
                marginTop: "44px",
                textAlign: "center",
              }}
              alt="Add"
              src={AddPicture}
            />
            <input
              type="file"
              id="inputImg"
              multiple
              style={{ display: "none" }}
              onChange={handleAddImageRow}
            ></input>
            <figcaption id="inputImgcaption">
              <label htmlFor="inputImg" className="lblText">
                <br /> Add New Document
              </label>
            </figcaption>
          </figure>
        </div>
      </div>
    </>
  );
};

export default Documents;
