import { CustomAlert } from "../components/customAlert";


const decodeToken = (token) => {
    try {
      const parts = token.split('.');
      
      if (parts.length === 3) {
        const decodedPayload = atob(parts[1]); // Decode the base64 payload
        
        const parsedPayload = JSON.parse(decodedPayload);
        return parsedPayload
      } else {
        console.error('Invalid JWT token');
      }
    } catch (error) {
      console.error('Error decoding token:', error);
    }
  };
  

  const  sessionAlert = () =>{
  let text = "Selected TamperEvidentBags will be cleared!";
  let confirmButtonText = "OK";
  let cancelButtonText = "Cancel";
  let showCancelButton = true;
  let title = "";
  let type = "warning";
  let target = document.getElementById("swal-div")
  console.log("object")
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      cancelButtonText,
      target
    ).then((result) => {
      if (result.value) {
        // setSelectedTebList([]);
        // setStorageLocation(selectedOption)
      }
    });
  }


  const validateToken=async(token)=>{
    sessionAlert();
        const decodedToken = decodeToken(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        const expirationTime = decodedToken?.exp;  // Expiration time from token (in seconds)
        if (currentTime > expirationTime) {
          
          // const user = await Auth?.currentAuthenticatedUser();
          // const updatedUserData = {
          //   ...currentUser,  // Assuming authenticateUser returns user data including the token
          //   jwtToken: user?.signInUserSession?.idToken?.jwtToken,  // Set the new token
          // };        
          // dispatch(login(updatedUserData));
        } 
  }




export const getHeader = (token) => {
  // validateToken(token);
  return {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Headers": "Content-Type",
    // "Access-Control-Allow-Methods": "OPTIONS,POST,GET,DELETE,PATCH",
    // "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    Authorization: token,
    "x-api-key": process.env.REACT_APP_API_KEY,
    "is-admin": false,
  };
};

export const getAdminHeader = (token) => {
  // validateToken(token);
  return {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Headers": "Content-Type",
    // "Access-Control-Allow-Methods": "OPTIONS,POST,GET,DELETE,PATCH",
    // "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    Authorization: token,
    "x-api-key": process.env.REACT_APP_API_KEY,
    "is-admin": true,
  };
};
