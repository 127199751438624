export const ADDENDUM_C_TAB_ITEMS = [
  { label: "STOCK CERTIFICATES", value: 1 },
  { label: "OTHER BONDS", value: 2 },
  { label: "CONTRABAND", value: 3 },
  { label: "MISCELLANEOUS", value: 4 },
];
export const FORCE_OPEN_STEPS = [
  "SECTION 1",
  "SECTION 2",
  "SECTION 3",
  "SECTION 4",
  "ADDENDUMS",
  "ADD AUTHORIZER",
  "CONFIRMATION",
];

//TO-DO store in DB and provide super admin UI

export const SECTION_4_ABONDENDED_PROPERTY_MESSAGE = "*******Send signed copy to AbandonedProperty@capitalone.com within 24 hours*******"

export const SECTION_4_NOTE = "In the event a customer retrieves their forced open contents, complete the Release of Contents below and record the fees collected."

export const MAX_LAST_RENTAL_PAYMENT_DATE = 0;

export const MIN_LAST_RENTAL_PAYMENT_DATE = -2555;

export const MIN_EXPIRATION_OF_CONTRACT_DATE = -2;

export const MAX_EXPIRATION_OF_CONTRACT_DATE = 1;

export const MAX_FORCE_OPEN_DATE = 1;

export const MIN_FORCE_OPEN_DATE = -6;

export const FORCE_OPEN_DEAULT_STATE = {
  section1: {
    contract_on_file: false,
    access_log: false,
    legacy: false,
    branch_id: "",
    branch_name: "",
    branchDID: "",
    force_open_date: "",
    safe_deposit_box_number: "",
    rental_due: 0,
    other_due: 0,
    total_due: 0,
    contract_expiration: "",
    last_rental_payment: "",
    address_same_as_owner: false,
    mystery_box:false,
    owner: {
      first_name: "",
      middle_name: "",
      last_name: "",
      ssn: "",
      street_1: "",
      city: "",
      state: "",
      zipcode: "",
      owner_relation_code: "",
      owner_property_type_code: "",
      dormancy_id: "",
      service_member: false,
      company_name: "",
    },
    co_owner: {
      first_name: "",
      middle_name: "",
      last_name: "",
      ssn: "",
      street_1: "",
      city: "",
      state: "",
      zipcode: "",
    },
    locksmith_name: "",
    notary_name: "",
    total_fee: 0,
    past_due_rental_fee: 0,
    force_open_cost: 0,
    inventory_fee: 0,
  },
  section2: {
    box_drilled_status: false,
    contents_found: false,
    box_not_drilled_reason_other: "",
    box_not_drilled_reason_id: "",
    box_not_drilled_reason_name: "",
    amount_reported: 0,
    box_drilled_type: "",
  },
  section4: {
    renterName: "",
    renterID: "",
    sec4Date: "",
  },
  addendum_a: [],
  addendum_b: [],
  addendum_c: [],
  authorizer_list: [],
  authorizer_name: [],
  authorizer_payload : [],
  performed_by_id: "",
  upload_form: {},
  record_id: "",
  items_list:[]
};
