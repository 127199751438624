import * as React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiStepIcon-active": { color: "lightgray" },
    "& .MuiStepIcon-completed": { color: "red" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "#354051" },
  },
}));

export default function CustomStepper(props) {
  const { steps, activeStep } = props;
  const c = useStyles();

  return (
    <Box sx={{ width: "100%" }} mb={2} className={c.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label.toUpperCase()}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
