import { CONFIGURE_DATES } from "../constants/ApiUrlConstants";
import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";

export const getDateConfigureData = async (field, module, token) => {
  return axios
    .get(
      service.baseUrl +
      CONFIGURE_DATES +
      "?field_name=" +
      field +
      "&module_name=" +
      module, // Use '&' to concatenate multiple query parameters
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};


export const putDateConfigureData = async (payload, token) => {
  return axios
    .put(
      service.baseUrl +
      CONFIGURE_DATES,
      payload,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};