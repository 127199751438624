import { Modal } from "react-bootstrap";
import CustomHeader from "../components/CustomHeader";
import ConfigData from "../config";

const BranchModal = ({
  dropDownType,
  displayUnits,
  onClickAdd,
  onChangeUnits,
  removeUnits,
  clearData,
  onClickUpdate,
}) => {
  return (
    <Modal show={dropDownType.length > 0 ? true : false} size="xl" centered>
      <Modal.Header className="no-border">
        <div className="modalHeaderdiv">
          <CustomHeader label={"ADD STORAGE UNIT (BRANCH)"} />
        </div>
      </Modal.Header>

      <Modal.Body closebutton="true">
        <Modal.Body id="scroll" className="scrollable modalheight">
          <>
            {displayUnits.length !== 0 ? (
              displayUnits.map((item, idx) => (
                <div className="form-row inner addStorageUnit rowDivisomForSm">
                  <div className="branchInputMainDiv">
                    <div className="form-row">
                      <label
                        id="name"
                        className="labelSmallScreen2 font-weight-bold"
                      >
                        Unit Name
                      </label>
                      <div>
                        <input
                          maxLength={ConfigData.branchUnitLength}
                          type="text"
                          className="inputvalue inputStorageUnit vaultInputDiv"
                          name="unit_name"
                          onChange={(e) => {
                            onChangeUnits("unit_name", e.target.value, idx);
                          }}
                          value={item.unit_name}
                          autoComplete="off"
                        />
                        {item.error_message !== undefined &&
                          item.error_message !== "" && (
                            <div className="error-message-validation">
                              {item.error_message}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="branchInputMainDiv">
                    <div className="form-row">
                      <label
                        id="name"
                        className="labelSmallScreen2 font-weight-bold"
                      >
                        Unit Type
                      </label>
                      <div>
                        <input
                          type="text"
                          className="inputvalue inputStorageUnit vaultInputDiv"
                          name="unit_type"
                          onChange={(e) => {
                            onChangeUnits("unit_type", e.target.value, idx);
                          }}
                          value={item.unit_type}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="center-image2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      removeUnits(item);
                    }}
                  >
                    <label className="minusIcon"> -</label>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center alignself-center"></div>
            )}
            <div
              onClick={() => {
                onClickAdd();
              }}
              className="center-image2"
              style={{ cursor: "pointer", marginLeft: "48%" }}
            >
              <label className="plusIcon">+</label>
            </div>
          </>
        </Modal.Body>
      </Modal.Body>

      <Modal.Footer className="modalFooter p-1 modal-footer no-border">
        <button
          onClick={() => clearData("Branch", "CANCEL")}
          className="btn btn-sm modalbtn"
        >
          Close
        </button>

        <button
          className="btn btn-sm modalbtn"
          onClick={() => onClickUpdate(dropDownType)}
          style={{ height: "35px" }}
        >
          <p>Update</p>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BranchModal;
