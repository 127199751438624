//eslint-disable-next-line
export default {
  cbdGooglefields: [
    "CONTRACT_ON_FILE",
    "ACCESS_LOG",
    "FORCE_OPEN_DATE",
    "SAFEDEPOSITBOX_NUMBER",
    "OWNER_NAME",
    "OWNER_SSN",
    "OWNER_STREET_1",
    "OWNER_CITY",
    "OWNER_STATE",
    "OWNER_ZIPCODE",
    "CO_OWNER_NAME",
    "CO_OWNER_SSN",
    "CO_OWNER_STREET_1",
    "CO_OWNER_CITY",
    "CO_OWNER_STATE",
    "CO_OWNER_ZIPCODE",
    "LOCKSMITH_NAME",
    "NOTARY_NAME",
    "BOX_DRILLED_STATUS",
    "CONTENTS_FOUND",
    "RENTAL_DUE",
    "OTHER_DUE",
    "TOTAL_DUE",
    "PAST_DUE_RENTAL_FEE",
    "FORCE_OPEN_COST",
    "INVENTORY_FEE",
    "TOTAL_FEE",
    "LEGACY",
    "ACTIVITY_TYPE",
    "TAMPEREVIDENTBAG_STATUS",
    "ADDENDUM_A_TAMPEREVIDENTBAG_NUMBER",
    "ADDENDUM_A_CATEGORY_NAME",
    "ADDENDUM_A_ITEM_NAME",
    "ADDENDUM_A_QUANTITY",
    "ADDENDUM_B_TAMPEREVIDENTBAG_NUMBER",
    "ADDENDUM_B_CATEGORY_NAME",
    "ADDENDUM_B_ITEM_NAME",
    "ADDENDUM_B_QUANTITY",
    "ADDENDUM_C_STOCK_CERTIFICATES_TAMPEREVIDENTBAG_NUMBER",
    "ADDENDUM_C_STOCK_CERTIFICATES_QUANTITY",
    "ADDENDUM_C_STOCK_CERTIFICATE_STOCK_CERTIFICATE",
    "ADDENDUM_C_OTHER_BOND_TAMPEREVIDENTBAG_NUMBER",
    "ADDENDUM_C_OTHER_BOND_QUANTITY",
    "ADDENDUM_C_OTHER_BOND_OTHER_BONDS",
    "ADDENDUM_C_OTHER_BOND_VALUE_OF_BOND",
    "ADDENDUM_C_OTHER_BOND_DESCRIPTION_OF_BONDS",
    "ADDENDUM_C_CONTRA_BAND_TAMPEREVIDENTBAG_NUMBER",
    "ADDENDUM_C_CONTRA_BAND_QUANTITY",
    "ADDENDUM_C_CONTRA_BAND_CONTRA_BAND",
    "ADDENDUM_C_MISCELLANEOUS_TAMPEREVIDENTBAG_NUMBER",
    "ADDENDUM_C_MISCELLANEOUS_QUANTITY",
    "ADDENDUM_C_MISCELLANEOUS_MISCELLANEOUS",
    "BRANCH_ID",
    "UNIQUE_ID",
    "NEXT_AUDIT_DUE_DATE",
    "DAYS_UNTIL_NEXT_AUDIT",
    "AUDIT_DUE_STATUS",
    "CONTENT_RELEASE_STATUS",
    "BRANCH_SDB_AUDIT",
    "STATUS",
    "AUDIT_BAG_STATUS",
    "AUDITOR_NAME",
    "ACTUAL_AUDIT_DATE",
    "LAST_AUDIT_DATE",
    "PREVIOUS_AUDIT_BAG_STATUS",
    "CURRENT_BRANCH_DID",
    "REGION",
    "MARKET",
    "STATE",
    "BRANCH",
    "CUSTOMER_NAME",
    "SDB#_DRILLED",
    "BOX_DRILL_DATE",
    "BAG #",
    "BAG_COUNT",
    "Branch where the box was physically located when drilled (This data in this column should never change)",
    "ORIGINAL_BRANCH_DID",
    "ORIGINAL_BRANCH_NAME",
    "PROHIBITED_ITEM",
    "ROM",
    "OPS_SPECIALIST",
    "MARKET_MANAGER",
    "FORM_LINK",
    "RECORD_ID",
    "BAG_DATA",
    "DUPLICATE_RECORD",
    "Branch Use SDB # (or other specific storage location of drilled content)",
  ],
  cbdFSIfields: [
    "CONTRACT_ON_FILE",
    "ACCESS_LOG",
    "FORCE_OPEN_DATE",
    "SAFEDEPOSITBOX_NUMBER",
    "OWNER_NAME",
    "OWNER_SSN",
    "OWNER_STREET_1",
    "OWNER_CITY",
    "OWNER_STATE",
    "OWNER_ZIPCODE",
    "CO_OWNER_NAME",
    "CO_OWNER_SSN",
    "CO_OWNER_STREET_1",
    "CO_OWNER_CITY",
    "CO_OWNER_STATE",
    "CO_OWNER_ZIPCODE",
    "LOCKSMITH_NAME",
    "NOTARY_NAME",
    "BOX_DRILLED_STATUS",
    "CONTENTS_FOUND",
    "RENTAL_DUE",
    "OTHER_DUE",
    "TOTAL_DUE",
    "PAST_DUE_RENTAL_FEE",
    "FORCE_OPEN_COST",
    "INVENTORY_FEE",
    "TOTAL_FEE",
    "LEGACY",
    "ACTIVITY_TYPE",
    "TAMPEREVIDENTBAG_STATUS",
    "ADDENDUM_A_TAMPEREVIDENTBAG_NUMBER",
    "ADDENDUM_A_CATEGORY_NAME",
    "ADDENDUM_A_ITEM_NAME",
    "ADDENDUM_A_QUANTITY",
    "ADDENDUM_B_TAMPEREVIDENTBAG_NUMBER",
    "ADDENDUM_B_CATEGORY_NAME",
    "ADDENDUM_B_ITEM_NAME",
    "ADDENDUM_B_QUANTITY",
    "ADDENDUM_C_STOCK_CERTIFICATES_TAMPEREVIDENTBAG_NUMBER",
    "ADDENDUM_C_STOCK_CERTIFICATES_QUANTITY",
    "ADDENDUM_C_STOCK_CERTIFICATE_STOCK_CERTIFICATE",
    "ADDENDUM_C_OTHER_BOND_TAMPEREVIDENTBAG_NUMBER",
    "ADDENDUM_C_OTHER_BOND_QUANTITY",
    "ADDENDUM_C_OTHER_BOND_OTHER_BONDS",
    "ADDENDUM_C_OTHER_BOND_VALUE_OF_BOND",
    "ADDENDUM_C_OTHER_BOND_DESCRIPTION_OF_BONDS",
    "ADDENDUM_C_CONTRA_BAND_TAMPEREVIDENTBAG_NUMBER",
    "ADDENDUM_C_CONTRA_BAND_QUANTITY",
    "ADDENDUM_C_CONTRA_BAND_CONTRA_BAND",
    "ADDENDUM_C_MISCELLANEOUS_TAMPEREVIDENTBAG_NUMBER",
    "ADDENDUM_C_MISCELLANEOUS_QUANTITY",
    "ADDENDUM_C_MISCELLANEOUS_MISCELLANEOUS",
    "CURRENT_BRANCH_DID",
    "BRANCH",
    "STATUS",
    "CONTROL_NUMBER",
    "CUSTOMER_FIRST_NAME",
    "CUSTOMER_LAST_NAME",
    "TAMPER_EVIDENT_BAG#",
    "STARTING_TRANSACTION_DATE",
    "SDB_LOCATION_OF_CONTENTS",
    "SDB_TOTE_ID",
    "ACCOUNT_NUMBER",
    "ORIGINAL_BRANCH_DID",
    "Branch where the box was physically located when drilled (This data in this column should never change)",
  ],
  designation: "Login User",
  boxDetails: {
    tamper_evident_bag_number: "Tamper Evident Bag #",
    storage_location: "Storage Location",
    owners_name: "Owner’s Name",
    co_owner_name: "Co-Owner’s Name",
  },
  eventHeading: [
    {
      DB_col: "shipment",
      display_name: "SHIPPING CONTENT",
    },
    {
      DB_col: "enquiry",
      display_name: "ENQUIRIES",
    },
    {
      DB_col: "audit",
      display_name: "AUDIT DETAILS",
    },
    {
      DB_col: "check_out",
      display_name: "CHECK OUT DETAILS",
    },
    {
      DB_col: "check_in",
      display_name: "CHECK IN DETAILS",
    },
    {
      DB_col: "force_open",
      display_name: "FORCE OPEN DETAILS",
    },
    {
      DB_col: "bag_upload",
      display_name: "BAG UPLOAD",
    },
  ],
  recentActivityDetails: {
    safedepositbox_number: "SAFE DEPOSIT BOX #",
    tamper_evident_bag_number: "TAMPER EVIDENT BAG #",
    storage_location: "STORAGE LOCATION",
    storage_ltype: "STORAGE TYPE",
    owners_name: "OWNER’S NAME",
    action_type: "ACTION TYPE",
    sdb_status: "STATUS",
    branch: "BRANCH",
  },
  eventDetails: {
    shipment: [
      {
        DB_col: "from_location.name",
        display_name: "From Location",
        type: "char",
      },
      {
        DB_col: "to_location.name",
        display_name: "To Location",
        type: "char",
      },
      {
        DB_col: "start_date",
        display_name: "Start Date",
        type: "date",
      },
      {
        DB_col: "estimated_arrival_date",
        display_name: "Estimated Arrival Date",
        type: "date",
      },
      {
        DB_col: "notes",
        display_name: "Shipping Notes",
        type: "char",
      },
    ],
    enquiry: [
      {
        DB_col: "requested_by",
        display_name: "Enquired By",
        type: "char",
      },
      {
        DB_col: "requested_date",
        display_name: "Enquired Date",
        type: "date",
      },
      {
        DB_col: "request_summary",
        display_name: "Enquired Summary",
        type: "char",
      },
      {
        DB_col: "notes",
        display_name: "Notes",
        type: "char",
      },
    ],
    audit: [
      {
        DB_col: "tamper_evident_bag.tamper_evident_bag_number",
        display_name: "Tamper Evident Bag #",
        type: "char",
      },
      {
        DB_col: "performed_by.employee_name",
        display_name: "Audit By",
        type: "char",
      },
      {
        DB_col: "audit_date",
        display_name: "Audit Date",
        type: "date",
      },
      {
        DB_col: "notes",
        display_name: "Notes",
        type: "char",
      },
    ],
    check_out: [
      {
        DB_col: "tamper_evident_bag.tamper_evident_bag_number",
        display_name: "Tamper Evident Bag #",
        type: "char",
      },
      {
        DB_col: "performed_by.employee_name",
        display_name: "Audit By",
        type: "char",
      },
      {
        DB_col: "create_datetime",
        display_name: "Check Out Date",
        type: "date",
      },
      {
        DB_col: "action.action",
        display_name: "Action Type",
        type: "char",
      },
      {
        DB_col: "notes",
        display_name: "Notes",
        type: "char",
      },
      {
        DB_col: "tamper_evident_bag.state",
        display_name: "State",
        type: "char",
      },
      {
        DB_col: "tamper_evident_bag.fsi_status",
        display_name: "FSI Status",
        type: "char",
      },
    ],
    check_in: [
      {
        DB_col: "performed_by.employee_name",
        display_name: "Performed By",
        type: "char",
      },
      {
        DB_col: "create_datetime",
        display_name: "Check In Date",
        type: "date",
      },
      {
        DB_col: "action.action",
        display_name: "Action Type",
        type: "char",
      },
      {
        DB_col: "notes",
        display_name: "Notes",
        type: "char",
      },
    ],
    force_open: [
      {
        DB_col: "performed_by.employee_name",
        display_name: "Audit By",
        type: "char",
      },
      {
        DB_col: "box_drilled.box_drilled_status",
        display_name: "Box Drilled Status",
        type: "char",
      },
      {
        DB_col: "box_drilled.amount_reported",
        display_name: "Amount Reported",
        type: "int",
      },
      {
        DB_col: "safe_deposit_box_fee.total_fee",
        display_name: "Total Fee",
        type: "int",
      },
      {
        DB_col: "safe_deposit_box_fee.total_due",
        display_name: "Total Due",
        type: "int",
      },
    ],
    bag_upload: [
      {
        DB_col: "performed_by.employee_name",
        display_name: "Performed By",
        type: "char",
      },
      {
        DB_col: "create_datetime",
        display_name: "Bag Upload Date",
        type: "date",
      },
      {
        DB_col: "bag_upload_data.Bag Status",
        display_name: "State",
        type: "char",
      },
      {
        DB_col: "bag_upload_data.Status",
        display_name: "FSI Status",
        type: "char",
      },
    ],
  },
  viewLocationsDetails: {
    name: "NAME",
    city: "CITY",
    state: "STATE",
    storage_location_type: "STORAGE TYPE",
    authorizer_email: "AUTHORISER EMAIL",
  },
  tenantDetails: {
    name: "NAME",
    domain: "DOMAIN",
    email: "EMAIL",
    owners_name: "OWNER'S NAME",
    owners_email: "OWNER'S EMAIL"
  },
  subscriptionDetails:{
    name:"SUBSCRIPTION NAME",
    type:"SUBSCRIPTION TYPE",
    plan:"SUBSCRIPTION PLAN"
  },
  viewSelectedTEBDetails: {
    tamper_evident_bag_number: "TAMPER EVIDENT BAG #",
    safe_deposit_box_number: "SAFE DEPOSIT BOX #",
    owner_name: "OWNER'S NAME",
    branch_did: "BRANCH DID",
    // status: "STATUS",
  },
  selectedTEBDetailsWarehousekeys: {
    unit_name: "Unit NAME",
    aisle_name: "Aisle NAME",
    bay_name: "Bay NAME",
    shelf_name: "Shelf NAME",
    tote_name: "Tote NAME",
  },
  viewSelectedAuthorizerDetails: {
    employee_name: "EMPLOYEE NAME",
    employee_email: "EMPLOYEE EMAIL",
    // employee_designation: "EMPLOYEE DESIGNATION"
  },
  viewAuthorizerListResponeKeys: [
    "employee_name",
    "employee_email",
    // "employee_designation_configuration.employee_designation"
  ],
  viewTEBListResponseKeys: [
    "tamper_evident_bag_number",
    "safe_deposit_box_number",
    "owner_name",
    "branch_did",
    // "status",
  ],
  aisleNameLength: 10,
  unitNameLength: 16,
  bayNameLength: 10,
  shelfNameLength: 10,
  toteNameLength: 10,
  branchUnitLength: 10,
  vaultUnitLength: 10,
  aisleLocationDetails: {
    name: "STORAGE NAME",
    city: "CITY",
    state: "STATE",
  },
  screenTimeOut: 60000 * 10,
  recordsPerPage: 7,
  employeeDesignationVariableList: [
    "employee_designation",
    "force_open_access_flag",
    "check_in_get_records_access_flag",
    "check_in_save_bulk_upload_access_flag",
    "check_in_save_record_access_flag",
    "shipment_get_records_access_flag",
    "shipment_save_record_access_flag",
    "shipment_save_bulk_upload_access_flag",
    "enquiry_get_records_access_flag",
    "enquiry_save_record_access_flag",
    "audit_get_records_access_flag",
    "audit_save_record_access_flag",
    "storage_management_get_records_access_flag",
    "storage_management_save_record_access_flag",
    "check_out_get_records_access_flag",
    "check_out_save_record_access_flag",
    "check_out_save_bulk_upload_access_flag",
    "approval_get_records_access_flag",
    "approval_save_record_access_flag",
    "reports_access_flag",
    "product_configuration_access_flag",
    "print_access_flag",
    "bag_status_access_flag",
  ],
  bagUploadFSI: [
    "DATE ENTERED",
    "ACCOUNT NUMBER",
    "DORMANCY ID",
    "OWNER FIRST NAME",
    "OWNER MIDDLE NAME",
    "OWNER LAST NAME",
    "COMPANY NAME",
    "OWNER Tax ID",
    "SSN or EIN",
    "OWNER ADDRESS",
    "OWNER CITY",
    "OWNER STATE",
    "OWNER ZIP",
    "OWNER RELATION CODE",
    "OWNER PROPERTY TYPE CODE",
    "SECONDARY OWNER FIRST NAME",
    "SECONDARY OWNER MIDDLE NAME",
    "SECONDARY OWNER LAST NAME",
    "SECONDARY OWNER SSN/TAX ID",
    "SECONDARY OWNER ADDRESS",
    "SECONDARY OWNER CITY",
    "SECONDARY OWNER STATE",
    "SECONDARY OWNER ZIP",
    "REPORTING STATE",
    "Region",
    "Market",
    "BRANCH NAME",
    "BRANCH DID",
    "BOX NUMBER",
    "TAMPER EVIDENT BAG #",
    "Status",
    "CONTENTS Y/N",
    "TANGIBLE INVENTORY",
    "AMOUNT REPORTED",
    "LOUISIANA BOND",
    "MILITARY MEDAL",
    "SCRA",
    "Audit ID",
    "Bag Status",
    "Storage Location",
    "Box Drilled",
    "Box Drill Date",
    "Last Audit Date",
    "Dual Control EID #1",
    "Dual Control EID #2",
    "DATE OF LAST TRANSACTION",
    "UNPAID RENT",
  ],
  designationAccessFlag: [
    "APPROVAL ACCESS",
    "AUDIT ACCESS",
    "AUTHORIZER ACCESS",
    "FORCE OPEN",
    "CHECK-IN VIEW",
    "CHECK-IN CREATE",
    "CHECK-IN BULK UPLOAD",
    "SHIPMENT VIEW",
    "SHIPMENT SAVE",
    "SHIPMENT BULK UPLOAD",
    "ENQUIRY VIEW",
    "ENQUIRY SAVE",
    "AUDIT VIEW",
    "AUDIT SAVE",
    "STORAGE LOCATION VIEW",
    "STORAGE LOCATION SAVE",
    "CHECK-OUT VIEW",
    "CHECK-OUT SAVE",
    "CHECK-OUT BULK UPLOAD",
    "APPROVAL VIEW",
    "APPROVAL SAVE",
    "REPORTS VIEW",
    "PRODUCT CONFIGURATION ACCESS",
    "PRINT SCREEN",
    "BAG UPLOAD"],
  designationAccessFlagTypes: [
    "approval_access_flag",
    "audit_access_flag",
    "authorizer_access_flag",
    "force_open_access_flag",
    "check_in_get_records_access_flag",
    "check_in_save_record_access_flag",
    "check_in_save_bulk_upload_access_flag",
    "shipment_get_records_access_flag",
    "shipment_save_record_access_flag",
    "shipment_save_bulk_upload_access_flag",
    "enquiry_get_records_access_flag",
    "enquiry_save_record_access_flag",
    "audit_get_records_access_flag",
    "audit_save_record_access_flag",
    "storage_management_get_records_access_flag",
    "storage_management_save_record_access_flag",
    "check_out_get_records_access_flag",
    "check_out_save_record_access_flag",
    "check_out_save_bulk_upload_access_flag",
    "approval_get_records_access_flag",
    "approval_save_record_access_flag",
    "reports_access_flag",
    "product_configuration_access_flag",
    "print_access_flag",
    "bag_status_access_flag"
  ],
  preOpenDetails: [
    "contract_on_file",
    "access_log",
    "legacy",
    "branch_name",
    "force_open_date",
    "safe_deposit_box_number",
    "rental_due",
    "other_due",
    "contract_expiration",
    "last_rental_payment",
    "service_member",
    "owner_first_name",
    "owner_middle_name",
    "owner_last_name",
    "owner_ssn",
    "owner_street_1",
    "owner_city",
    "owner_state",
    "owner_zipcode",
    "owner_relation_code",
    "owner_property_type_code",
    "dormancy_id",
    "company_name",
    "address_same_as_owner",
    "co_owner_first_name",
    "co_owner_middle_name",
    "co_owner_last_name",
    "co_owner_ssn",
    "co_owner_street_1",
    "co_owner_city",
    "co_owner_state",
    "co_owner_zipcode",
    "box_drilled_status",
    "drill_type",
    "contents_found",
    "locksmith_name",
    "notary_name",
    "past_due_rental_fee",
    "force_open_cost",
    "inventory_fee",
  ],
  disclaimers : {
    "APPROVAL ACCESS": "Allows the role to be designated as an approver.",
    "AUDIT ACCESS": "Reserved for future use, currently inactive.",
    "AUTHORIZER ACCESS": "Reserved for future use, currently inactive.",
    "FORCE OPEN": "Allows performing force open and pre-open actions.",
    "CHECK-IN VIEW": "Allows viewing check-in details.",
    "CHECK-IN CREATE": "Allows creating new check-in entries.",
    "CHECK-IN BULK UPLOAD": "Allows bulk uploading of check-in data.",
    "SHIPMENT VIEW": "Allows viewing shipment records.",
    "SHIPMENT SAVE": "Allows saving shipment details for TEBs.",
    "SHIPMENT BULK UPLOAD": "Allows bulk uploading of shipment details for TEBs.",
    "ENQUIRY VIEW": "Allows viewing inquiry details.",
    "ENQUIRY SAVE": "Allows saving inquiry details.",
    "AUDIT VIEW": "Allows viewing audit logs.",
    "AUDIT SAVE": "Allows saving audit details for TEBs.",
    "STORAGE LOCATION VIEW": "Allows viewing storage location details.",
    "STORAGE LOCATION SAVE": "Allows creating and saving new storage locations.",
    "CHECK-OUT VIEW": "Allows viewing check-out details.",
    "CHECK-OUT SAVE": "Allows saving check-out details for TEBs.",
    "CHECK-OUT BULK UPLOAD": "Allows bulk uploading of check-out details for TEBs.",
    "APPROVAL VIEW": "Allows viewing approval requests.",
    "APPROVAL SAVE": "Allows saving and approving approval requests.",
    "REPORTS VIEW": "Allows viewing various reports.",
    "PRODUCT CONFIGURATION ACCESS": "Allows configuring product settings.",
    "PRINT SCREEN": "Allows printing the current screen or document.",
    "BAG UPLOAD": "Allows access for bag upload.",
  }
};
