import React, { useRef, useEffect, useState } from "react";
import Modal from "react-modal";
import user from "../assets/user.png";
import { MDBCard, MDBCardBody, MDBCardHeader } from "mdbreact";
import { useSelector } from "react-redux";
import "../style/CreateEnquiry.css";
import { useNavigate } from "react-router-dom";
import { cognitoSignOut } from "../api/CognitoServices";
import { getNotifications } from "../api/NotificationsService";
import { useDispatch } from "react-redux";
import { notification } from "../redux/notification";
import { ConsoleError } from "../utils/ErrorUtils";
import useFetchNotifications from "../utils/NotificationUtils";

function NotificationModal(props) {
  const [inboxData, setNotificationsData] = useState([]);
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const notificationData = useSelector(
    (reduxData) =>
      reduxData?.notificationServiceReducer?.value?.notificationData
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const componentRef = useRef(null);
  const excludeRef = useRef(null);

  useEffect(() => {
    const handleClick = (event) => {
      if (
        excludeRef.current &&
        excludeRef.current.contains(event.target)
      ) {
        return; // Ignore the click
      }
      if (
        !(componentRef.current && componentRef.current.contains(event.target))
      ) {
        props.onCloseModal();
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);


  useEffect(() => {
    if (!props.isAdmin) {
      const getNotificationsData = async () => {
        // Ensure notificationData is valid before setting state
        if (notificationData) {
          setNotificationsData(notificationData);
        }
      };

      getNotificationsData();
    }
  }, [notificationData, props.isAdmin]); // Include props.isAdmin in dependencies

  function onModalClose() {
    props.onCloseModal();
  }

  return (
    <Modal
      isOpen={props.IsModalOpened}
      ariaHideApp={false}
      id="notificationModal"
    >
      <MDBCard>
        <MDBCardHeader className="card text-white justify-content-center form-heading text-center  mb-3">
          <div>
            <div onClick={onModalClose} className="close">
              x
            </div>
            <h6 className="  p-0 m-0">{props.dynData} </h6>
          </div>
        </MDBCardHeader>
        <MDBCardBody>
          <div className="left-side messageBox" id="cs-scroll">
            <div className="table-responsive">
              <table className="table" id="inboxTable">
                <tbody>
                  {inboxData &&
                    inboxData.map((item, index) => (
                      <tr
                        ref={excludeRef}
                        key={index}
                        className="table-row"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/approvalDetails", {
                            state: item.notification,
                          });
                          onModalClose();
                        }}
                      >
                        <td id="profileInbox">
                          <div>
                            <img
                              className="img-responsive"
                              src={user}
                              alt="logo"
                              width="30px"
                              height="30px"
                            />
                          </div>
                        </td>
                        <td>
                          <div
                            className="d-flex align-items-start"
                            id="msgHeaderDetails"
                          >
                            {item?.notification?.action} -{" "}
                            {item?.notification?.description}
                          </div>
                        </td>
                        <td>
                          <div id="dateInbox">
                            {item?.notification?.create_datetime}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </MDBCardBody>
      </MDBCard>
    </Modal>
  );
}

export default NotificationModal;
