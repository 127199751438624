import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MDBCard } from "mdbreact";
import logo from "../assets/logo.png";
import { Auth } from "aws-amplify"; // This uses the configured Amplify
import { userverification } from "../api/CognitoServices";
import { loader } from "../utils/loader";
import { validatePassword } from "../utils/PasswordValidation";
import Swal from "sweetalert2";
import "../style/signIn.css";

export default function ForgotPassword() {
  const [step, setStep] = useState(1); // Steps to handle multi-step form
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false); // Loader state
  const navigate = useNavigate();
  // Step 1: Send OTP to email
  const handleSendOtp = async () => {
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex to validate basic email format
      if (emailRegex.test(email)) {
        setError("");
        setLoading(true);
        const response = await userverification(email);
        if (
          response?.status === 200 &&
          response?.data?.message === "UserExist"
        ) {
          setError("");
          await Auth.forgotPassword(email);
          setStep(2); // Move to OTP input step
        } else if (
          response?.status === 200 &&
          response?.data?.message === "UserNotFoundException"
        ) {
          setLoading(false);
          setError("No user found with this email.");
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "No user found with this email.",
          });
        }
      } else {
        setError("Please enter a valid email address");
        Swal.fire({
          icon: "error",
          title: "Invalid Email",
          text: "Please enter a valid email address.",
        });
        return;
      }
    } catch (err) {
      setLoading(false);
      setError("Oops! Something went wrong. Please try again later.");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Oops! Something went wrong. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };
  // Step 2: Verify OTP and move to password reset
  const handleVerifyOtp = async () => {
    try {
      setLoading(true);
      setError("");
      setStep(3);
    } catch (err) {
      setError("Invalid OTP. Please check and try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    const valid_password = validatePassword(confirmPassword);

    if (valid_password.length === 0) {
      try {
        setLoading(true);
        await Auth.forgotPasswordSubmit(email, otp, newPassword);
        setError(""); // Clear any previous error
        setSuccess("Password changed successfully.");
        setStep(4); // Redirect to a success page or confirmation
      } catch (err) {
        // Error handling for failed password reset
        if (err.code === "InvalidParameterException") {
          setError("Invalid OTP or password. Please try again.");
        } else {
          setError(
            "Failed to reset password. Please check your OTP and try again."
          );
        }
        // Clear inputs for user to retry
        setNewPassword("");
        setConfirmPassword("");
        setOtp("");
        setStep(1); // Optionally allow the user to try again
      } finally {
        setLoading(false); // Ensure loading is stopped
      }
    } else {
      setError(valid_password);
    }
  };

  return (
    <div className="d-md-flex h-md-100 align-items-center justify-content-center pl-5 pr-5">
      <MDBCard className="card card-body d-md-flex align-items-center signin-card p-5 m-5">
        <div className="row wrapper col-lg-12 text-white p-5 m-5">
          <div className="col-lg-6 align-items-center text-center justify-content-center appLogoDiv py-3">
            <img id="appLogo" src={logo} alt="logo" />
            <h1 className="pt-3">SAFECHIEF</h1>
          </div>
          <div className="col-lg-6 text-center p-0">
            <div className="pt-5 pb-2 h1" id="welcometext">
              {step === 1
                ? "Forgot Password"
                : step === 2
                ? "Verification"
                : step === 3 || step === 4
                ? "Reset Password"
                : ""}
            </div>

            {/* <h5 className="pb-3">
              Please click on sign in button to continue.
            </h5> */}
            <div>
              {step === 1 && (
                <>
                  <h5>Enter Email Address</h5>
                  <div className="text-left login-form">
                    <div
                      className="error-container"
                      style={{ visibility: error ? "visible" : "hidden" }}
                    >
                      <span className="error-icon">⚠️</span>
                      <span className="error-text">{error}</span>
                    </div>
                    <div className="form-group align-items-center justify-content-center">
                      <label htmlFor="email" className="login-label">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="form-control login-input"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setError("");
                        }}
                        required
                      />
                      <p
                        className="forgot-password"
                        onClick={() => navigate("/")}
                      >
                        Back to Sign In
                      </p>
                    </div>
                    {loading ? (
                      loader()
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary login-button"
                        onClick={() => {
                          handleSendOtp();
                        }}
                        disabled={email.trim().length == 0}
                      >
                        Send OTP
                      </button>
                    )}
                  </div>

                  {/* {error && <p style={{ color: "red" }}>{error}</p>} */}
                </>
              )}

              {step === 2 && (
                <>
                  <h5>Enter Verification Code</h5>
                  <div className="otp-success-message">
                    <p>
                      A one-time password (OTP) has been sent to your email
                      address. Please check your inbox.
                    </p>
                  </div>

                  <div className="text-left login-form">
                    {error.length > 0 && (
                      <div
                        className="error-container"
                        style={{ visibility: error ? "visible" : "hidden" }}
                      >
                        <span className="error-icon">⚠️</span>
                        <span className="error-text">{error}</span>
                      </div>
                    )}

                    <div className="form-group align-items-center justify-content-center">
                      <label htmlFor="otp" className="login-label">
                        OTP
                      </label>
                      <input
                        type="text"
                        id="otp"
                        className="form-control login-input"
                        value={otp}
                        onChange={(e) => {
                          setOtp(e.target.value);
                          setError("");
                        }}
                        required
                      />
                    </div>
                    {loading ? (
                      loader()
                    ) : (
                      <button
                        className="btn btn-primary login-button"
                        onClick={handleVerifyOtp}
                        disabled={otp.trim().length === 0}
                      >
                        Verify OTP
                      </button>
                    )}
                  </div>
                </>
              )}

              {step === 3 && (
                <>
                  <h5>Enter New Password</h5>
                  <div className="text-left login-form">
                    <div
                      className="error-container"
                      style={{ visibility: error ? "visible" : "hidden" }}
                    >
                      <span className="error-icon">⚠️</span>
                      <span className="error-text">{error}</span>
                    </div>
                    <div className="form-group align-items-center justify-content-center">
                      <label htmlFor="newPassword" className="login-label">
                        New Password:
                      </label>
                      <input
                        type="password"
                        id="newPassword"
                        className="form-control login-input"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group align-items-center justify-content-center">
                      <label htmlFor="confirmPassword" className="login-label">
                        Confirm Password:
                      </label>
                      <input
                        type="password"
                        id="confirmPassword"
                        className="form-control login-input"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                    </div>
                    {loading ? (
                      loader()
                    ) : (
                      <button
                        className="btn btn-primary login-button"
                        onClick={handlePasswordReset}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                  {/* {error && <p style={{ color: "red" }}>{error}</p>} */}
                </>
              )}

              {step === 4 && success && (
                <>
                  <div className="text-center login-form">
                    {/* <p style={{ color: "green" }}>{success}</p> */}
                    <p>
                      Password reset successful. You can now log in with your
                      new password.
                    </p>
                  </div>
                  <button
                    className="btn btn-primary "
                    onClick={() => navigate("/")}
                  >
                    Click here to signIn
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </MDBCard>
    </div>
  );
}
