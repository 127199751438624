import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { MDBCard } from "mdbreact";
import CustomHeader from "../components/CustomHeader";
import CustomModal from '../components/CustomModal';
import DocumentUpload from '../components/documentUpload';
import Inputupload from '../components/Inputupload';
import * as XLSX from "xlsx";
import PRE_OPEN_HEADER from "../config";
import { CustomAlert } from '../components/customAlert';
import { saveRecords, getBranch } from '../api/ForceOpenRecordsService';
import { useSelector } from 'react-redux';
import { ConsoleError } from '../utils/ErrorUtils';
import { getActionList } from '../api/ActionService';
import Swal from "sweetalert2";
import { getAllrecords } from "../api/ForceOpenRecordsService";

function CreatePreOpen() {
    const [fileName, setFileName] = useState("File Name...");
    const [currentEventData, setCurrentEventData] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [keysFromFile, setkeysFromFile] = useState([]);
    const [tableData, setTableData] = useState([]);
    const requiredData = PRE_OPEN_HEADER.preOpenDetails;
    const [boxNotDrilledReasonList, setBoxNotDrilledReasonList] = useState([]);
    const [drillTypeList, setDrillTypeList] = useState([]);
    const [branchDetails, setBranchDetails] = useState([]);
    const EXTENSIONS = ["xlsx", "xls", "csv"];
    const currentUser = useSelector(
        (reduxData) => reduxData?.userReducer?.value?.userDetails
    );
    const [isCheckValidity, setIsCheckValidity] = useState(false)
    // const [tableErrors, setTableErrors] = useState([])
    let navigate = useNavigate()
    const getExention = (file) => {
        let parts = file.name.split(".");
        let extension = parts[parts.length - 1];
        return EXTENSIONS.includes(extension);
    };

    const fileUpload = async (e) => {
        setFileName(e.target.files[0].name)
        let file = e.target.files[0];
        if (getExention(file)) {
            if (file.size > 10e6) {
                setCurrentEventData({
                    EventType: "Alert",
                    EventData: "Please upload a file smaller than 10 MB",
                });
                setShowMessage(true);
            }
        } else {
            setCurrentEventData({
                EventType: "Alert",
                EventData: "Invalid file input, Select Excel, CSV file",
            });

            setShowMessage(true);
        }

        let defaultHeaders = [];
        if (file) {
            let promise = new Promise((resolve, reject) => {
                let fileReader = new FileReader()
                fileReader.readAsArrayBuffer(file);
                fileReader.onload = (e) => {
                    let bufferArray = e.target.result;
                    let wb = XLSX.read(bufferArray, { type: "binary" });
                    let wsname = wb.SheetNames[0];
                    let ws = wb.Sheets[wsname];
                    let data = XLSX.utils.sheet_to_json(ws);
                    let headers = XLSX.utils.sheet_to_json(ws, {
                        header: 1,
                    });
                    defaultHeaders = headers[0];
                    resolve(data);
                }
                fileReader.onerror = (error) => {
                    reject(error);
                };
            })

            promise.then((data) => {
                let keysFromFile = defaultHeaders;
                setkeysFromFile(keysFromFile);
                setTableData(data)
                function formatDate(obj) {
                    const [dd, mm, yyyy] = obj.split('/');
                    return `${yyyy}-${mm.padStart(2, '0')}-${dd.padStart(2, '0')}`;
                }

                function dateFormat(data) {
                    const newData = data.map(item => {
                        return {
                            ...item,
                            force_open_date: formatDate(new Date((item.force_open_date - 25569) * 86400 * 1000).toLocaleDateString()),
                            contract_expiration: formatDate(new Date((item.contract_expiration - 25569) * 86400 * 1000).toLocaleDateString()),
                            last_rental_payment: formatDate(new Date((item.last_rental_payment - 25569) * 86400 * 1000).toLocaleDateString()),
                        };
                    });
                    // set the data
                    setIsCheckValidity(true)
                    setTableData(newData);

                }

                function requiredKeys(keysfromFile, requiredData) {
                    if (JSON.stringify(keysfromFile) === JSON.stringify(requiredData)) {
                        return true
                    } else {
                        return false
                    }
                }

                async function checkDuplicates(data) {
                    var records
                    await getAllrecords("", 0, 1, currentUser?.jwtToken).then((response) => {
                        records = response?.data?.box_list
                    })
                    let duplicates = []
                    for (let i = 0; i < data.length; i++) {
                        if (records.some(item => item.safe_deposit_box_number === data[i].safe_deposit_box_number)) {
                            // return (true);
                            duplicates.push(data[i])
                        }
                    }
                    return (duplicates);

                }

                if (!requiredKeys(keysFromFile, requiredData)) {
                    let differentKeys = requiredData.filter((d) => !keysFromFile.includes(d));
                    let text = differentKeys.length <= 3 ? "Please check " + differentKeys + " and upload again" :
                        "Please check " +
                        differentKeys[0] +
                        "," +
                        differentKeys[1] +
                        "," +
                        differentKeys[2] +
                        " and few other fields, upload  file again"
                    CustomAlert(
                        "Missing Fields",
                        text,
                        "warning",
                        "OK",
                        false
                    );
                    setkeysFromFile([])
                    setTableData([])
                } else {
                    checkDuplicates(data).then((response) => {
                        if (response.length > 0) {
                            CustomAlert(
                                "Duplicate Record Found",
                                "Please check the Boxes and upload again",
                                "warning",
                                "OK",
                                false
                            );
                            setTableData([])
                        } else {
                            dateFormat(data)

                        }
                    })
                }

            })
        }
    }
    useEffect(() => {
        if (isCheckValidity) {
            dataValidity(tableData)
        }
    }, [tableData])

    const dataValidity = async (data) => {
        setIsCheckValidity(false)
        data?.forEach((element, index) => {
            checkDrillType(element, index)
            checkBranchDetails(element, index)
        });
    }

    const checkDrillType = async (data, index) => {
        if (data?.box_drilled_status) {
            let val = drillTypeList.find(item => {
                return item?.action === data?.drill_type
            })
            if (val) {
                setTableData(prevData => {
                    const newData = [...prevData];
                    newData[index] = {
                        ...newData[index],
                        ['box_drilled_type']: val?.action_id,
                        ['box_not_drilled_reason_id']: "",
                    };
                    return newData;
                });
            }
        } else {
            let val = boxNotDrilledReasonList.find(item => {
                return item?.action === data?.drill_type
            })
            if (val) {
                setTableData(prevData => {
                    const newData = [...prevData];
                    newData[index] = {
                        ...newData[index],
                        ['box_not_drilled_reason_id']: val?.action_id,
                        ['box_drilled_type_id']: ""
                    };
                    return newData;
                });
            }
        }
    }

    const checkBranchDetails = async (data, index) => {
        let val = branchDetails.find(item => {
            return item?.name === data?.branch_name
        })
        if (val) {
            setTableData(preData => {
                const newData = [...preData];
                newData[index] = {
                    ...newData[index],
                    ['branch_name']: val?.name,
                    ['branch_id']: val?.branch_id,
                    ['branchDID']: val?.branch_did
                }
                return newData;
            })
        }

    }

    const removeItem = (index) => {
        setTableData(prevArr => {
            const newData = [...prevArr];
            newData.splice(index, 1); // remove the item at the specified index
            return newData;
        });
    }

    const saveRecord = async (e) => {
        Swal.fire({
            title: "Processing, please wait",
            html: "loading...",
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
        saveRecords(tableData, currentUser?.jwtToken).then((response) => {
            Swal.close();
            if (response?.status === 200) {
                CustomAlert(
                    "Saved", "All the Records Saved Successfully", "success", "OK", false, document.getElementById("swal-div")
                ).then((result) => {
                    if (result.value) {
                        navigate("/viewAllPreOpen");
                    }
                });
            } else {
                CustomAlert(
                    "Failed", "Failed Saving Records", "error", "OK", false, document.getElementById("swal-div")
                )
            }
        })
    }

    async function fetchBoxNotDrilledReason() {
        getActionList("BoxNotDrilledReason", currentUser?.jwtToken)
            .then((response) => {
                setBoxNotDrilledReasonList(
                    response?.map(function (storageTypeRow) {
                        return storageTypeRow;
                    })
                );
            })
            .catch((error) => {
                ConsoleError(error);
            });
    }

    async function fetchBoxDrillType() {
        getActionList("BoxDrillType", currentUser?.jwtToken).then((response) => {
            setDrillTypeList(
                response.map(function (drillType) {
                    return drillType;
                })
            );
        }).catch((error) => {
            ConsoleError(error);
        });
    }

    async function fetchBranchDetails() {
        getBranch(currentUser?.jwtToken).then((response) => {
            if (response?.status === 200) {
                setBranchDetails(response?.data)
            }
        }).catch((error) => {
            ConsoleError(error);
        });
    }

    const handleChange = async (index, key, newValue) => {
        setIsCheckValidity(true)
        setTableData(prevData => {
            const newData = [...prevData];
            newData[index] = {
                ...newData[index],
                [key]: newValue
            };
            return newData;
        });
    }

    useEffect(() => {
        fetchBoxNotDrilledReason();
        fetchBoxDrillType();
        fetchBranchDetails();
    }, []);


    return (
        <>
            <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 body">
                <div className="row flex pt-4 pl-4 pr-4 pb-0 " id="swal-div">
                    <div className="col-lg-12 col-md-12 col-sm-12 pb-2git a pl-0 pr-0 overflow-hidden">
                        <div className="row pt-1 pb-2 mt-5">
                            <div className="d-flex justify-content-end">
                                <div className="p-0">
                                    <Link to="/viewAllPreOpen">
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                            id="checkin-records-button"
                                        >
                                            PRE OPEN RECORDS
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <MDBCard className="card">
                            <CustomHeader id="single" label={"ADD NEW PRE OPEN"} />
                            <CustomModal
                                currentEventData={currentEventData}
                                show={showMessage}
                                onHide={() => setShowMessage(false)}
                            />
                            <div className="divUpload pb-0 d-flex  justify-content-between align-items-center mb-2 p-1">
                                <div className="d-flex align-items-center">
                                    <DocumentUpload
                                        onChange={fileUpload}
                                        fileName={fileName}
                                    />
                                </div>
                            </div>
                            {tableData.length > 0 ?
                                <>
                                    <table
                                        className="table_wrapper"
                                        id="cbc-scroll1"
                                    >
                                        <tr
                                            key={"header"}
                                            id="cbc-table-heading"
                                            style={{
                                                position: "sticky",
                                                top: 0,
                                                background: "#6F7D95",
                                                zIndex: 2,
                                            }}
                                        >
                                            {keysFromFile.map((item) => {
                                                return (
                                                    <th>{item.toUpperCase()}</th>
                                                )
                                            })}
                                            <th style={{
                                                position: "sticky",
                                                right: 0,
                                                zIndex: 1,
                                                background: "#6F7D95",
                                            }}>ACTION</th>
                                        </tr>
                                        {tableData.map((outerItem, index) => {
                                            return (<tr style={{ background: 'white', alignContent: 'center' }}>
                                                {keysFromFile.map((item) => {
                                                    return (<td className="td-text">
                                                        {(typeof outerItem[item] == 'boolean') ?
                                                            <input type='checkbox' id="legacy_checkbox"
                                                                checked={outerItem[item]}
                                                                onChange={(e) => {
                                                                    handleChange(index, item, e.target.checked)
                                                                }} />
                                                            : item === "drill_type" ? <select
                                                                className="custom-select mr-sm-2 mb-2"
                                                                value={outerItem['drill_type'] ? outerItem['drill_type'] : ""}
                                                                onChange={(e) => {
                                                                    handleChange(index, item, e.target.value)
                                                                }}>
                                                                <option value=''>Choose...</option>
                                                                {outerItem["box_drilled_status"] ? (
                                                                    drillTypeList.map(item => {
                                                                        return (<option value={item?.action}>{item?.action}</option>)
                                                                    })
                                                                ) : (boxNotDrilledReasonList.map(item => {
                                                                    return (<option value={item?.action}>{item?.action}</option>)
                                                                }))}
                                                            </select> : (item === 'force_open_date' || item === 'contract_expiration' || item === 'last_rental_payment') ?
                                                                <input type='date' className="form-control"
                                                                    value={outerItem[item]}
                                                                    onChange={(e) => {
                                                                        handleChange(index, item, e.target.value)
                                                                    }} />
                                                                :
                                                                <input type='text' className="form-control" value={outerItem[item]} onChange={(e) => {
                                                                    handleChange(index, item, e.target.value)
                                                                }} />}
                                                    </td>)
                                                })}
                                                <td style={{
                                                    position: "sticky",
                                                    right: 0,
                                                    zIndex: 1,
                                                    background: 'rgba(248,249,250)'
                                                }}>
                                                    <i
                                                        className="fa fa-2x fa-trash pb-1"
                                                        aria-hidden="true"
                                                        onClick={() => {
                                                            removeItem(index)
                                                        }}
                                                    ></i>
                                                </td>
                                            </tr>)
                                        })}

                                    </table>
                                    <div className="fieldArea7 pt-2 pb-2 justify-content-center">
                                        <button
                                            className="btn  btn-sm btn-primary"
                                            variant="contained"
                                            onClick={() => {
                                                navigate("/viewAllPreOpen")
                                            }}
                                        >
                                            CANCEL
                                        </button>
                                        <button
                                            className="btn  btn-sm btn-primary"
                                            variant="contained"
                                            onClick={(e) => {
                                                saveRecord(e)
                                            }}
                                        //   disabled={!datafield ? fileuploaded : verified}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </>
                                : <div className="d-flex row align-items-center justify-content-center vertical-middle text-center divTableMain">
                                    <Inputupload onChange={fileUpload} label="Upload Document" />
                                </div>}


                        </MDBCard>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreatePreOpen