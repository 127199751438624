import { MDBCard } from "mdbreact";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { saveBulkCheckOutData } from "../api/BulkCheckOutServices";
import { CustomAlert } from "../components/customAlert";
import CustomHeader from "../components/CustomHeader";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { cognitoSignOut } from "../api/CognitoServices";
import "../style/bulkcheckout.css";
import CustomModal from "../components/CustomModal";
import { regexEmail, regexPhone, regexZipCode } from "../constants/Regex";
import { ERROR_MESSAGE } from "../constants/MessagesConstants";
import { ConsoleError } from "../utils/ErrorUtils";
import DocumentUpload from "../components/documentUpload";
import { getExention } from "../utils/FileUtils";
import * as XLSX from "xlsx";
import {
  checkOutTypes,
  validationKeys,
  requiredFields,
} from "../constants/BulkCheckOutConstants";
import { Pager } from "../components/pager";
import Inputupload from "../components/Inputupload";
import "../style/BulkCheckin.css";
import "../style/customalert.css";
import "../style/custombulkcheckin.css";

const BulkCheckout = (props) => {
  let navigate = useNavigate();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const [isSubmit, setIsSubmit] = useState(true);
  const [formData, setFormData] = useState({
    fileUploadRows: [],
    filename: " File Name ",
    fileUploadHeaders: [],
    Documentrows: [],
    comments: "",
  });
  const [currentEventData, setCurrentEventData] = useState({
    EventType: "Alert",
    EventData: "",
  });
  const [showMessage, setShowMessage] = useState(false);
  const [pageState, setPageState] = useState({ pageNum: 1, rowsPerPage: 10 });
  const [showData, setShowData] = useState([]);
  const [rows, setRows] = useState([]);

  const handleChange = (name, value) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const showSuccessAlert = (response) => {
    let title = "Saved";
    let text = response;
    let type = "success";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      target
    ).then((result) => {
      if (result.value) {
        navigate("/viewAllCheckout");
      }
    });
  };

  const showFailAlert = (response) => {
    let title = "Failed";
    let text = response;
    let type = "error";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(title, text, type, confirmButtonText, showCancelButton, target);
  };

  const checkrequiredFields = (fileHeaders) => {
    if (requiredFields.every((item) => fileHeaders.includes(item))) {
      return true;
    }
    return false;
  };

  const checkCheckOutType = (data) => {
    for (let item of data) {
      if (!checkOutTypes.includes(item["CHECK_OUT_TYPE"])) {
        return false;
      }
    }
    return true;
  };

  const checkDataValidation = (data) => {
    for (let item of data) {
      if (
        item["CHECK_OUT_TYPE"] === checkOutTypes[0] ||
        item["CHECK_OUT_TYPE"] === checkOutTypes[1] ||
        item["CHECK_OUT_TYPE"] === checkOutTypes[2]
      ) {
        if (
          !item[validationKeys[0]] ||
          !regexEmail.test(item[validationKeys[1]]) ||
          !regexPhone.test(
            item[validationKeys[2]] ||
              !item[validationKeys[3]] ||
              !regexZipCode.test(item.validationKeys[4])
          )
        ) {
          return false;
        }
      } else {
        if ((!item[validationKeys[5]], !item[validationKeys[6]])) {
          return false;
        }
      }
    }
    return true;
  };

  const checkDuplicates = (data) => {
    const encounteredNumbers = [];
    let hasDuplicates = false;
    data.some((obj) => {
      const bagNumber = obj.TAMPER_EVIDENT_BAG_NUMBER;
      if (encounteredNumbers.includes(bagNumber)) {
        hasDuplicates = true;
        return true; // Exit the loop
      }
      encounteredNumbers.push(bagNumber);
      return false;
    });
    return hasDuplicates;
  };

  const importExcel = async (e) => {
    const file = e.target.files[0];
    handleChange("filename", e.target.files[0].name);
    let fileHeaders = [];
    if (file) {
      if (getExention(file)) {
        if (file.size > 10e6) {
          handleChange("filename", " File Name ");
          setCurrentEventData({
            EventType: "Alert",
            EventData: "Please upload a file smaller than 10 MB",
          });
          setShowMessage(true);
        }
      } else {
        handleChange("filename", " File Name ");
        setCurrentEventData({
          EventType: "Alert",
          EventData: "Invalid file input, Select Excel, CSV file",
        });
        setShowMessage(true);
      }
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);
          let headers = XLSX.utils.sheet_to_json(ws, { header: 1 });
          fileHeaders = headers[0];
          resolve(data);
          setShowData(data);
          handleChange("fileUploadRows", data);
          handleChange("fileUploadHeaders", headers[0]);
          setIsSubmit(false);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
      promise.then((data) => {
        if (checkrequiredFields(fileHeaders)) {
          handleChange("fileUploadRows", data);
          handleChange("fileUploadHeaders", fileHeaders);
          let list = [];
          data.forEach((o, key) => {
            if (
              !(
                "SAFE_DEPOSIT_BOX_NUMBER" in o &&
                "TAMPER_EVIDENT_BAG_NUMBER" in o
              )
            ) {
              list.push(key);
            }
          });
          let text = "Please check and re-upload the file";
          let confirmButtonText = "OK";
          let showConfirmButton = false;
          let title = "Invalid records";
          let type = "warning";
          if (!checkCheckOutType(data) || checkDataValidation(data)) {
            CustomAlert(
              title,
              text,
              type,
              confirmButtonText,
              showConfirmButton
            );
            handleChange("fileUploadRows", []);
            handleChange("fileUploadHeaders", []);
            handleChange("filename", " File Name ");
            setIsSubmit(true);
          }
          if (list.length > 0) {
            CustomAlert(
              title,
              text,
              type,
              confirmButtonText,
              showConfirmButton
            );
            handleChange("fileUploadRows", []);
            handleChange("fileUploadHeaders", []);
            handleChange("filename", " File Name ");
            setIsSubmit(true);
          }
          if (checkDuplicates(data)) {
            CustomAlert(
              "Duplicate Bags",
              "Please check and re-upload the file",
              "warning",
              "OK",
              false
            );
            handleChange("fileUploadRows", []);
            handleChange("fileUploadHeaders", []);
            handleChange("filename", " File Name ");
            setIsSubmit(true);
          }
        } else {
          let text = "Please check and re-upload the file";
          let confirmButtonText = "OK";
          let showConfirmButton = false;
          let title = "Missing fields";
          let type = "warning";

          CustomAlert(title, text, type, confirmButtonText, showConfirmButton);
          handleChange("fileUploadRows", []);
          handleChange("filename", " File Name ");
          handleChange("fileUploadHeaders", []);
          setIsSubmit(true);
        }
      });
    }
  };

  useEffect(() => {
    if (showData && showData.length) {
      const propsdata = [...showData];
      const idx = (pageState.pageNum - 1) * pageState.rowsPerPage;
      setRows(propsdata.slice(idx, idx + pageState.rowsPerPage));
    }
  }, [pageState, showData]);

  const showAlert = () => {
    let title = "Closing";
    let text = "Redirect to checkout records";
    let type = "warning";
    let confirmButtonText = "Ok";
    let showCancelButton = true;
    let cancelButtonText = "Cancel";
    let target = document.getElementById("swal-div");
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      cancelButtonText,
      target
    ).then((result) => {
      if (result.value) {
        navigate("/viewAllCheckout");
      }
    });
  };

  const handleSubmit = () => {
    const payload = {
      tamper_evident_bag_numbers: formData?.fileUploadRows,
      employee_designation: currentUser?.employee_designation,
      employee_name: currentUser?.employee_name,
      employee_id: currentUser?.employee_id,
    };
    saveBulkCheckOutData(payload, currentUser?.jwtToken)
      .then((response) => {
        Swal.close();
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((err) => {
              console.error(err);
            });
        } else if (response?.data.status === "error") {
          CustomAlert(
            "Invalid Bags",
            response?.data?.message,
            "warning",
            "OK",
            false
          );
          handleChange("fileUploadRows", []);
          handleChange("fileUploadHeaders", []);
          handleChange("filename", " File Name ");
          setIsSubmit(true);
        } else if (response?.data.status === "SUCCESS") {
          showSuccessAlert(response?.data?.message);
        } else {
          showFailAlert("Create Bulk Checkout Failed");
        }
      })
      .catch((error) => {
        ConsoleError(error);
        showFailAlert(ERROR_MESSAGE);
      });
  };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0">
        <div className="row flex p-4 ">
          <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden">
            <div className="row pt-1 pb-2 mt-5">
              <div className="d-flex justify-content-end">
                <div className="p-0">
                  {currentUser?.check_out_save_record_access_flag && (
                    <Link to="/createcheckout">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        id="bulk-checkin-button"
                      >
                        SINGLE CHECK-OUT
                      </button>
                    </Link>
                  )}
                </div>
                <div className="p-0">
                  <Link to="/viewAllCheckout">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      id="bulk-checkin-button"
                    >
                      CHECK-OUT RECORDS
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <MDBCard className="card p-0 m-0 bulk-checkout-card">
              <CustomHeader label={"BULK CHECK-OUT"} />
              <>
                <CustomModal
                  currentEventData={currentEventData}
                  show={showMessage}
                  onHide={() => setShowMessage(false)}
                />
                <div className="divUpload">
                  <DocumentUpload
                    onChange={importExcel}
                    fileName={formData?.filename}
                  />
                </div>
                {formData?.fileUploadRows?.length ? (
                  <div className="tableFixHead">
                    <table className="table_wrapper" id="cbc-scroll">
                      <tr key={"header"} id="heading">
                        {formData?.fileUploadHeaders.map((valueItem, index) => (
                          <>
                            <th key={"header" + index}>{valueItem}</th>
                          </>
                        ))}
                      </tr>
                      {rows.map((item) => (
                        <tr
                          key={item.id}
                          style={{
                            background: "#e3e3e3",
                            marginTop: "20px",
                          }}
                        >
                          {formData?.fileUploadHeaders.map((value, index) => (
                            <td key={"value_" + index}>
                              {item[value] ? item[value] : "-"}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </table>
                    <Pager
                      totalPages={
                        showData.length
                          ? Math.ceil(showData.length / pageState.rowsPerPage)
                          : 0
                      }
                      currentPage={pageState.pageNum}
                      onPageChange={(newPage) =>
                        setPageState({
                          pageNum: newPage,
                          rowsPerPage: pageState.rowsPerPage,
                        })
                      }
                      rowsPerPage={pageState.rowsPerPage}
                      setRowsPerPage={(num) =>
                        setPageState({ pageNum: 1, rowsPerPage: num })
                      }
                      dataCount={showData.length}
                      dataLength={rows.length}
                    />
                  </div>
                ) : (
                  <div className="d-flex row align-items-center justify-content-center vertical-middle text-center divTableMain">
                    <Inputupload
                      onChange={importExcel}
                      label="Upload Document"
                    />
                  </div>
                )}
              </>
              <div className="fieldArea7 pt-2 pb-2 justify-content-center">
                <button
                  type="file"
                  className="btn  btn-sm btn-primary"
                  onClick={() => {
                    showAlert();
                  }}
                >
                  CLOSE
                </button>
                <button
                  type="file"
                  className="btn  btn-sm btn-primary"
                  onClick={handleSubmit}
                  disabled={isSubmit}
                >
                  SUBMIT
                </button>
              </div>
            </MDBCard>
          </div>
        </div>
      </div>
    </>
  );
};
export default BulkCheckout;
