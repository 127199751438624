import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";
import { POST_FORCE_OPEN_RECORDS, FORCE_OPEN_RECORDS, GET_ALL_BRANCH_DETAILS, FORCE_OPEN_RECORD } from "../constants/ApiUrlConstants";

export const getAllrecords = async (searchValue, rowCount, currentPage,token) => {
    return axios
        .get(
            service.baseUrl +
            FORCE_OPEN_RECORDS +
            "?search_value="+
            searchValue+
            "&row="+
            rowCount+
            "&page="+currentPage, {
            headers: getHeader(token),
            validateStatus: function (status) {
                return status < 500;
            },
        }).then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const getRecord = async (record_id, token) => {
    return axios
        .get(
            service.baseUrl +
            FORCE_OPEN_RECORD +
            "?record_id=" +
            record_id, {
            headers: getHeader(token),
            validateStatus: function (status) {
                return status < 500;
            },
        }).then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const saveRecords = async (payload, token) => {
    return axios
        .post(
            service.baseUrl +
            POST_FORCE_OPEN_RECORDS,
            payload, {
            headers: getHeader(token),
            validateStatus: function (status) {
                return status < 500;
            },
        }).then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const deleteRecords = async (record_id, token) => {
    return axios
        .delete(
            service.baseUrl +
            FORCE_OPEN_RECORDS +
            "?record_id=" + record_id, {
            headers: getHeader(token),
            validateStatus: function (status) {
                return status < 500;
            },
        }).then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const updateRecord = async (record_id, payload, token) => {
    return axios
        .patch(
            service.baseUrl +
            FORCE_OPEN_RECORD,{
                "record_id": record_id,
                "payload": payload
            },
            {
                headers: getHeader(token),
                validateStatus: function (status) {
                    return status < 500;
                },
            }).then((response) => {
                return response;
            })
        .catch((error) => {
            return error;
        });
}

export const getBranch = async (token) => {
    return axios
        .get(
            service.baseUrl +
            GET_ALL_BRANCH_DETAILS, {
            headers: getHeader(token),
            validateStatus: function (status) {
                return status < 500;
            },
        }).then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}