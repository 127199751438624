import { MDBCardFooter } from "mdbreact";
import React from "react";
import { Box, Grid } from "@mui/material";
const TablePageOptions = [10, 15, 20];
function Pagination(props) {
  return (
    <MDBCardFooter
      className="text-muted text-white p-0"
      id="footer"
      style={{
        textAlign: "center",
        background: "#6F7D95",
        zIndex: 1,
      }}
    >
      <div className="d-flex justify-content-between text-white text-center align-items-center pl-3">
        <Grid item>
          <Box className="boxData" lineHeight={2}>
            <label className="lblRecords">Records Per Page :</label>
            <select
              className="ddlSelectPage"
              aria-label="Default select example"
              onChange={(event) => props.setRowsPerPage(event.target.value)}
              value={props?.rowsPerPage}
            >
              {TablePageOptions &&
                TablePageOptions.map((ele, idx) => (
                  <option style={{ color: "white" }} key={idx} value={ele}>
                    {`${ele} `}
                  </option>
                ))}
            </select>
          </Box>
        </Grid>

        <div className="text-white pt-2 pb-2">
          <ul className="pagination pagination-circle pg-blue m-0">
            {props.currentPage !== 1 && <li className="page-item ">
              <a
                className="page-link"
                id="pagination"
                aria-label="Previous"
                onClick={() => props.onPreviousClick()}
              >
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>}
            {props.pages &&
              props.pages.slice(props.initial, props.end).map((item, index) => {
                return (
                  <li 
                  className={`page-item ${
                    props.currentPage === item ? "page-item-active" : ""
                  }`}
                    id="test-id" key={index}>
                    <a
                      className="page-link"
                      id="pagination"
                      onClick={() => props.onPageClick(item)}
                    >
                      {item}
                    </a>
                  </li>
                );
              })}

            {props.pages && props.currentPage != props.pages.length && <li className="page-item">
              <a
                className="page-link"
                aria-label="Next"
                id="pagination"
                onClick={() => props.onNextClick()}
              >
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>}
          </ul>
        </div>
      </div>
    </MDBCardFooter>
  );
}

export default Pagination;
