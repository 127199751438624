import axios from "axios";
import service from "./service";
import {
  SAVE_SYSTEM_USER,
  VERIFY_SYSTEM_USER,
} from "../constants/ApiUrlConstants";
import { getHeader } from "../utils/Headers";
import { ConsoleError } from "../utils/ErrorUtils";

export const saveSystemUser = async (payload, token) => {
  return axios
    .post(service.baseUrl + SAVE_SYSTEM_USER, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      return error;
    });
};

export const verifySystemUser = async (payload, token) => {
  return axios
    .post(service.baseUrl + VERIFY_SYSTEM_USER, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      return error;
    });
};
