import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";
import {
  GET_TAMPEREVIDENT_BAG,
  ENQUIRIES,
  VIEW_HISTORY,
  GET_ENQUIRY_NOTES,
  ADD_ENQUIRY_NOTES,
} from "../constants/ApiUrlConstants";
import { ConsoleError } from "../utils/ErrorUtils";

//service call for create Enquiry

export const GetTamperEvidentBags = async (id, token) => {
  return axios
    .get(
      service.baseUrl + GET_TAMPEREVIDENT_BAG + "?safedepositbox_number=" + id,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};

export const saveEnquiry = async (payload, token) => {
  return axios
    .post(service.baseUrl + ENQUIRIES, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};

//service calls for view enquiry

export const getViewEnquiryData = async (
  searchValue,
  pageNo,
  pageSize,
  empId,
  token
) => {
  return axios
    .get(
      service.baseUrl +
        ENQUIRIES +
        "?emp_id=" +
        empId +
        "&page_no=" +
        pageNo +
        "&page_size=" +
        pageSize +
        (searchValue ? "&search_value=" + searchValue : ""),
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const pagination = async (pageNumber, pageCount, empId, token) => {
  return axios
    .get(
      service.baseUrl +
        ENQUIRIES +
        "/Enquiries?page=" +
        pageNumber +
        "&records_per_page=" +
        pageCount +
        "&emp_id=" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

// Enquiry history serivice calls

export const enquiryHistory = async (Id, event, empId, token) => {
  return axios
    .get(
      service.baseUrl +
        VIEW_HISTORY +
        Id +
        "?event=" +
        "Enquiries" +
        "&emp_id" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};

//Enquiry Notes service call
export const getEnquiryNotesData = async (empId, token, enquiries_id) => {
  return axios
    .get(
      service.baseUrl +
        GET_ENQUIRY_NOTES +
        "?enquiries_id=" +
        enquiries_id +
        "&emp_id=" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};

//Save Enquiry Notes service call
export const saveEnquiryNotes = async (params, empId, token) => {
  return axios
    .post(service.baseUrl + ADD_ENQUIRY_NOTES + "?emp_id=" + empId, params, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};
