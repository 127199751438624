import { MDBCard, MDBCardBody } from "mdbreact";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getHistory } from "../api/History";
import HistoryTimeline from "../components/HistoryTimeline";
import "../style/checkinHistory.css";
import CustomHeader from "../components/CustomHeader";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { ConsoleError } from "../utils/ErrorUtils";
import { ENQUIRY_HISTORY_LIMIT } from "../constants/EnquiryConstants";
import { DISPLAY_DATE_FORMAT } from "../constants/Constants";
import { saveAs } from "file-saver";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

function EnquiryHistory(props) {
  let navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [clickid, setClickId] = useState();
  const [selectedEvent, setSelectedEvent] = useState(0);
  const [enquiryData, setEnquiryData] = useState();
  //eslint-disable-next-line
  const [initialArray, setInitialArray] = useState();
  const [bagId, setbagId] = useState();
  const [requestedBy, setRequestedBy] = useState();
  const [requestedDate, setRequestedDate] = useState();
  const [notes, setNotes] = useState();
  const [requestedSummary, setRequestedSummary] = useState();
  const [loader, setLoader] = useState(false);
  const [documents, setDocuments] = useState([]);
  //eslint-disable-next-line
  const [timeLineloader, settimeLineLoader] = useState(false);
  //const [timeLineEvent, setTimeLineEvent] = useState("");
  const event = "Enquiries";
  const Id = state.tamperevidentbag_id ? state.tamperevidentbag_id : null;
  //eslint-disable-next-line
  const tebId = state?.tamper_evident_bag?.tamper_evident_bag_id
    ? state?.tamper_evident_bag?.tamper_evident_bag_id
    : state?.information?.tamper_evident_bag?.tamper_evident_bag_id
    ? state?.information?.tamper_evident_bag?.tamper_evident_bag_id
    : null;

    useEffect(() => {
      const handleEnquiryData = async () => {
        setLoader(true); // Start loading
  
        if (enquiryData) {
          
          // Find the index of the event matching state?.enquiry_id
          const currentEventIndex = enquiryData.findIndex(
            (e) => e.enquiry_id === state?.enquiry_id
          );
  
          // Check if a valid event was found
          if (currentEventIndex >= 0) {
            await onClick(enquiryData[currentEventIndex], currentEventIndex);
          } else {
            console.warn("No matching enquiry event found for the given enquiry_id.");
          }
        }
  
        setLoader(false); // Stop loading
      };
  
      handleEnquiryData();
    }, [enquiryData, state?.enquiry_id]);
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const download = (link, name) => {
    saveAs(link, name);
  };

  const getTimelineData = async () => {
    setLoader(true);
    getHistory(
      "enquiry",
      tebId,
      1,
      ENQUIRY_HISTORY_LIMIT,
      currentUser?.jwtToken
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          setLoader(false);
          setEnquiryData(
            response?.data ? response?.data : null
          );
          //setEnquiriesId(response?.data[0].enquiry_id );
          setbagId(
            response?.data[0]?.tamper_evident_bag?.tamper_evident_bag_number
          );
          setRequestedBy(response?.data[0].requested_by);
          setRequestedDate(response?.data[0].requested_date);
          setRequestedSummary(response?.data[0].request_summary);
          setNotes(response?.data[0].notes);
          setDocuments(response?.data[0].documents);
          props?.eventData({ data: "data" });
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };
  const onPageClick = async (operation, index) => {
    //TO-DO
    setClickId(index);
    // let pageNo;
    // if (operation === "plus") {
    //   pageNo = nextPageNo;
    // } else {
    //   if (perviousPageNo === undefined) {
    //     getTimelineData();
    //   }
    //   pageNo = perviousPageNo;
    // }

    // settimeLineLoader(true);
    // getPaginationHistory(
    //   Id,
    //   event,
    //   pageNo,
    //   currentUser?.employee_id,
    //   currentUser?.jwtToken
    // )
    //   .then((response) => {
    //     if (response.status === 401) {
    //       cognitoSignOut()
    //         .then(() => {
    //           navigate("/");
    //         })
    //         .catch((err) => {
    //           console.error(err);
    //         });
    //     } else if (response.status === 200) {
    //       settimeLineLoader(false);
    //       setEnquiryData(
    //         response?.data.results.data ? response?.data.results.data : null
    //       );
    //       setInitialArray(
    //         response?.data.results.data[0]
    //           ? response?.data.results.data[0]
    //           : null
    //       );
    //       setEnquiriesId(response?.data.results.data[0].enquiries_id);
    //       setbagId(response?.data.results.data[0].tamperevidentbag_number);
    //       setRequestedBy(response?.data.results.data[0].requested_by);
    //       setRequestedDate(response?.data.results.data[0].requested_date);
    //       setRequestedSummary(response?.data.results.data[0].request_summary);
    //       setNotes(response?.data.results.data[0].notes);
    //       props?.eventData({ data: "data" });
    //     }
    //   })
    //   .catch((err) => {
    //     settimeLineLoader(false);
    //   });
  };

  const onClick = (item, index) => {
    setSelectedEvent(index);
    setInitialArray(item);
    //setEnquiriesId(item.enquiry_id);
    setbagId(item.tamper_evident_bag?.tamper_evident_bag_number);
    setRequestedBy(item.requested_by);
    setRequestedDate(item.requested_date);
    setRequestedSummary(item.request_summary);
    setDocuments(item.documents);
    setNotes(item.notes);
    setClickId(index);
  };
  dayjs.extend(utc);
  dayjs.extend(tz);
  const localTimezone = dayjs.tz.guess();

  useEffect(() => {
    getTimelineData();
    //eslint-disable-next-line
  }, [Id]);
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 ">
      <div className="row flex pt-4 pl-4 pr-4 pb-0 ">
        <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden">
          <div className="row pt-1 pb-2">
            <div className="d-flex justify-content-end">
              <div className="p-0">
                {currentUser?.enquiry_save_record_access_flag && (
                  <Link to="/createEnquiry">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      id="bulk-checkin-button"
                    >
                      NEW ENQUIRY{" "}
                    </button>
                  </Link>
                )}
              </div>
              <div className="p-0">
                <Link to="/enquiryview">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    id="checkin-records-button"
                  >
                    ENQUIRY RECORDS{" "}
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <MDBCard className="card p-0 m-0 ">
            <CustomHeader label={"ENQUIRY HISTORY"} />
            {loader ? (
              <div className="text-center py-5 mt-5 alignself-center">
                <Spinner animation="border" />
              </div>
            ) : (
              <MDBCardBody id="scroll-bar">
                <div className="row text-center ">
                  {timeLineloader ? (
                    <div className="text-center py-5 mt-5 alignself-center">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <div className="col-lg-12 col-md-12 pt-4 pb-4 ">
                      <HistoryTimeline
                        historyData={enquiryData}
                        onClick={onClick}
                        clickid={clickid}
                        selectedEvent={selectedEvent}
                        event={event}
                        onPageClick={onPageClick}
                      />
                    </div>
                  )}
                  <div className="event-div">
                    <div className="d border border-5 event-border h-100 ml-5 mr-5">
                      <div className="row col-lg-12 justify-content-center pt-3 pb-3">
                        EVENT DETAILS
                      </div>{" "}
                      <div className="row col-lg-12 col-md-12 col-sm-12 ml-2 pb-3 bs-scroll">
                        <div className={"col-lg-12 col-md-12 col-sm-12  "}>
                          <form>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                {t("labels.teb")}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {" "}
                                  {bagId ? bagId : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Requested By{" "}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {requestedBy ? requestedBy : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Requested Date{" "}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {requestedDate
                                    ? dayjs(requestedDate)
                                        .tz(localTimezone)
                                        .format(DISPLAY_DATE_FORMAT)
                                    : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Requested Summary{" "}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {requestedSummary ? requestedSummary : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Enquiry Notes{" "}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start text-left">
                                  {notes ? notes : null}
                                </div>
                              </div>
                            </div>
                            {documents?.length > 0 && (
                              <div className="form-group row">
                                <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                  Documents{" "}
                                </label>
                                <div className="col-sm-8 p-0">
                                  {documents?.filter(
                                    (document) => document?.type === "DOCUMENT"
                                  ).length > 0 && (
                                    <div className="row ">
                                      <div className="col-8 fw-bold d-flex pl-3">
                                        <span className="float-start">
                                          Uploaded Document Name
                                        </span>
                                      </div>
                                      <div className="col-4 fw-bold">
                                        Description
                                      </div>
                                    </div>
                                  )}
                                  {documents
                                    ?.filter(
                                      (document) =>
                                        document?.type === "DOCUMENT"
                                    )
                                    .map((filteredDocument) => (
                                      <div className="row">
                                        <div className="col-8 d-flex">
                                          <span
                                            className="cursor-pointer float-start"
                                            onClick={() =>
                                              download(
                                                filteredDocument?.url,
                                                filteredDocument?.name
                                              )
                                            }
                                          >
                                            {filteredDocument?.name}
                                            <i className="fa fa-download pl-2" />
                                          </span>
                                        </div>
                                        <div className="col-4 ">
                                          {filteredDocument?.description}
                                        </div>
                                      </div>
                                    ))}
                                  {documents?.filter(
                                    (document) => document?.type === "PICTURE"
                                  ).length > 0 && (
                                    <div className="row pt-3">
                                      <div className="col-8 fw-bold d-flex pl-3">
                                        <span className="float-start">
                                          Added Picture Name
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {documents
                                    ?.filter(
                                      (document) => document?.type === "PICTURE"
                                    )
                                    .map((filteredPicture) => (
                                      <div className="row">
                                        <div className="col-8 d-flex">
                                          <span
                                            className="cursor-pointer float-start"
                                            onClick={() =>
                                              download(
                                                filteredPicture?.url,
                                                filteredPicture?.name
                                              )
                                            }
                                          >
                                            {filteredPicture?.name}
                                            <i className="fa fa-download pl-2" />
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MDBCardBody>
            )}
          </MDBCard>
        </div>
      </div>
    </div>
  );
}

export default EnquiryHistory;
