import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import CustomHeader from "./CustomHeader";
import "../style/customModal.css";
const formatList = [
  { value: "csv", label: ".csv" },
  { value: "xlsx", label: ".xlsx" },
];
function CustomModalFileFormat(props) {
  const [selectradio, setSelectRadio] = useState("csv");

  function handleChange(e) {
    setSelectRadio(e.target.value);
  }

  const onModalClose = () => {
    props.onCloseModal();
  };
  const handleOk = () => {
    if (selectradio !== "") {
      props.onSubmit(selectradio);
      onModalClose();
    }
  };
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.show}
    >
      <Modal.Header
        className="card formheading form-heading mb-3"
        id="modalHeader"
      >
        <CustomHeader label={"export data"} id="modalHeader" />
      </Modal.Header>

      <Modal.Body className="p-10">
        <div className="col-lg-12 col-md-12 col-sm-12" id="modalbodyheight">
          <div className="form-group row ">
            <label className="col-lg-12 col-md-12  col-form-label headingModallabel">
              Please Select the Format you want to Export the Data
            </label>

            <div className="col-lg-12 col-md-12 col-sm-12" id="divradiotext">
              {formatList.map((x, i) => (
                <label key={i} className="radio-inline" id="lblradiotext">
                  <input
                    type="radio"
                    name="format"
                    value={x.value}
                    onChange={handleChange}
                    checked={x.value === selectradio}
                  />
                  {x.label}
                </label>
              ))}
            </div>

            <div className="col-sm-3"></div>

            <div className="form-group row">
              <div
                className="col-lg-12 col-md-12 col-sm-3 flex space-x-8 mt-3"
                id="divradiotext"
              ></div>
              {Boolean(selectradio) ? (
                <> </>
              ) : (
                <p className="text-danger errormsg">
                  * Please Select atleast one Format to Export the Data
                </p>
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <button
                className="btn  btn-sm btn-primary"
                id="btnok"
                onClick={handleOk}
              >
                OK
              </button>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <button
                onClick={onModalClose}
                className="btn  btn-sm btn-primary"
                id="btncancel"
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CustomModalFileFormat;
