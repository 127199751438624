export const ALL_USER = ["admin", "read", "write", "superadmin", "audit"];
export const SUPERADMIN_USER = ["superadmin"];
export const READ_USER = ["read"];
export const WRITE_USER = ["write"];
export const ADMIN_USER = ["admin"];
export const AUDIT_USER = ["audit"];
export const NOT_AUTHORIZED_USER = ["notauthorized"];
export const SAFECHIEF_ADMIN = ["safechief_admin"];
export const SAFECHIEF_ADMIN_PATHS = [
  "/all-tenants",
  "/add-tenant",
  "/subscriptions",
  "/add-subscription",
  // Add any other paths allowed for safechief_admin
];
export const SUPERADMIN_PATHS = ["/admin", "/adminViewIRPTEBHistory"];


export const ACCESS_CONTROL = {
    APPROVAL: {
      view: {
        path: ['/viewAllApprovals', '/approvalDetails'],
        flags: ['approval_get_records_access_flag'],
      },
      post: {
        path: [],
        flags: [],
      },
    },
    ENQUIRY: {
      view: {
        path: ['/enquiryview', '/enquiryHistory'],
        flags: ['enquiry_get_records_access_flag'],
      },
      post: {
        path: ['/createEnquiry'],
        flags: ['enquiry_save_record_access_flag'],
      },
    },
    STORAGE_MANAGEMENT: {
      view: {
        path: ['/storagelocation'],
        flags: ['storage_management_get_records_access_flag'],
      },
      post: {
        path: ['/createstoragelocation'],
        flags: ['storage_management_save_record_access_flag'], 
      },
    },
    AUDIT: {
      view: {
        path: ['/auditRecords', '/auditDetails','/auditView',"/auditHistory"],
        flags: ['audit_get_records_access_flag'],
      },
      post: {
        path: ['/createAudit'],
        flags: ['audit_save_record_access_flag'],
      },
    },
    CHECK_IN: {
      view: {
        path: ['/checkInRecords', '/checkInDetails','/viewAllCheckIn','/checkinHistory'],
        flags: ['check_in_get_records_access_flag'],
      },
      post: {
        path: ['/singleCheckIn', '/bulkCheckIn','/customBulkCheckin'],
        flags: [
          'check_in_save_record_access_flag',
        ],
      },
      bulk_upload:{
        path: [ '/bulkCheckIn','/customBulkCheckin'],
        flags: [
          'check_in_save_bulk_upload_access_flag'
        ],
      }
    },
    CHECK_OUT: {
      view: {
        path: ['/checkOutRecords', '/checkOutDetails','/viewAllCheckout','/checkOutDetails'],
        flags: ['check_out_get_records_access_flag'],
      },
      post: {
        path: ['/createcheckout'],
        flags: [
          'check_out_save_record_access_flag',
        ],
      },
      bulk_upload: {
        path: [ '/bulkCheckOut'],
        flags: [
          'check_out_save_bulk_upload_access_flag'
        ],
      },
    },
    REPORTS: {
      view: {
        path: ['/reports'],
        flags: ['reports_access_flag'],
      },
      post: {
        path: [],
        flags: [], 
      },
    },
    SHIPMENT: {
      view: {
        path: ['/shipmentRecords', '/shipmentDetails','/viewAllShipment','/shipmentHistory'],
        flags: ['shipment_get_records_access_flag'],
      },
      post: {
        path: ['/createShipment'],
        flags: [
          'shipment_save_record_access_flag'
        ],
      },
      bulk_upload: {
        path: [ '/bulkShipment'],
        flags: [
          'shipment_save_bulk_upload_access_flag',
        ],
      },
    },
    BAG_UPLOAD: {
      view: {
        path: ['/uploadbagsfsi'],
        flags: ['bag_status_access_flag'],
      },
      post: {
        path: [],
        flags: []
      },
    },
    FORCE_OPEN: {
      view: {
        path: ['/forceOpen','/forceOpenDetails','/viewAllPreOpen','/preOpen','/updatePreOpen',"/preview"],
        flags: ['force_open_access_flag'],
      },
      post: {
        path: [],
        flags: [],
      },
    },
  };
  