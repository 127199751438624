import { getAdminHeader } from "../utils/Headers";
import service from "./service";
import axios from "axios";
import {
  UPDATE_WAREHOUSE_UNITS,
  GET_TENANTS,
  CREATE_SUBSCRIPTION,
  CREATE_TENANT,
  UPDATE_TENANT,
  GET_SUBSCRIPTIONS,
  UPDATE_TENANT_STATUS,
  DELETE_SUBSCRIPTION,
  GET_ROLES,
  GET_TENANT_DETAILS
} from "../constants/ApiUrlConstants";

export const saveSubscriptionType = async (data, token) => {
  return axios
    .post(service.baseUrl + CREATE_SUBSCRIPTION, data, {
      headers: getAdminHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    });
};

export const getSubscriptions = async (pageNo, pageSize, token) => {
  const payload = {};

  return axios
    .post(
      service.baseUrl +
        GET_SUBSCRIPTIONS +
        "?page_no=" +
        pageNo +
        "&page_size=" +
        pageSize,
      payload,
      {
        headers: getAdminHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
export const fetchRoles = async (pageNo, pageSize, token) => {
  return axios
    .get(
      service.baseUrl +
        GET_ROLES +
        "?page_no=" +
        pageNo +
        "&page_size=" +
        pageSize,
      {
        headers: getAdminHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const updateSubscriptionType = async (unitType, data, empId, token) => {
  return axios
    .put(
      service.baseUrl +
        UPDATE_WAREHOUSE_UNITS +
        "/" +
        unitType +
        "&emp_id=" +
        empId,
      data,
      {
        headers: getAdminHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
export const updateTenantStatus = async (tenant_id, token, action) => {
  return axios
    .post(
      service.baseUrl + UPDATE_TENANT_STATUS, 
      { tenant_id, action }, 
      {
        headers: getAdminHeader(token), 
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};


export const deleteSubscription = async (subscription_type_id, token) => {
  return axios
    .delete(
      service.baseUrl +
        DELETE_SUBSCRIPTION +
        "?subscription_type_id=" +
        subscription_type_id,
      {
        headers: getAdminHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const getTenants = async (searchValue, pageNo, pageSize, token) => {
  const payload = {};
  return axios
    .post(
      service.baseUrl +
        GET_TENANTS +
        "?search_value=" +
        searchValue +
        "&page_no=" +
        pageNo +
        "&page_size=" +
        pageSize,
      payload,
      {
        headers: getAdminHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const createTenant = async (data, token) => {
  return axios
    .post(service.baseUrl + CREATE_TENANT, data, {
      headers: getAdminHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    });
};

export const editTenant = async (data, token) => {
  return axios
    .put(service.baseUrl + UPDATE_TENANT, data, {
      headers: getAdminHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    });
};

export const getTenantDetails = async (token) => {
  return axios
    .get(service.baseUrl + GET_TENANT_DETAILS, {
      headers: getAdminHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    });
};
