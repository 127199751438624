import { MDBCard, MDBCardBody } from "mdbreact";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { getShipmentData } from "../api/ShipmetServices";
import CustomHeader from "../components/CustomHeader";
import Pagination from "../components/Pagination";
import Search from "../components/Search";
import "../style/shipcontents.css";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import { DISPLAY_DATE_FORMAT } from "../constants/Constants";
import { ConsoleError } from "../utils/ErrorUtils";
import useFetchNotifications from "../utils/NotificationUtils";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

function ShipContents() {
  const navigate = useNavigate();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const notificationResponse = useFetchNotifications(
    currentUser?.employee_id,
    currentUser?.jwtToken
  );

  const [shipmentData, setShipmentData] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    rowCount: 10,
    searchValue: "",
    pages: [],
  });
  const [status, setStatus] = useState({
    saveLoader: false,
    noRecordsMessage: "",
  });

  dayjs.extend(utc);
  dayjs.extend(tz);
  const localTimezone = dayjs.tz.guess();

  const fetchShipmentData = async (
    page = 1,
    rows = pagination.rowCount,
    search = pagination.searchValue
  ) => {
    setStatus((prev) => ({ ...prev, saveLoader: true }));
    try {
      const response = await getShipmentData(
        search,
        page,
        rows,
        currentUser?.employee_id,
        currentUser?.jwtToken
      );

      if (response.status === 401) {
        await cognitoSignOut();
        navigate("/");
        return;
      }

      if (response.status === 200) {
        const count = response?.data?.pagination?.total_records || 0;
        const totalPages = Math.ceil(count / rows) || 1;

        setPagination((prev) => ({
          ...prev,
          currentPage: page,
          pages: [...Array(totalPages)].map((_, i) => i + 1),
        }));
        setShipmentData(response?.data?.shipments || []);
        setStatus((prev) => ({
          ...prev,
          noRecordsMessage: count < 1 ? "No shipment records available" : "",
        }));
      }
    } catch (error) {
      ConsoleError(error);
    } finally {
      setStatus((prev) => ({ ...prev, saveLoader: false }));
    }
  };

  const handleSearchData = (data) => {
    const {
      shipments,
      pagination: newPagination,
      searchValue = "",
    } = data || {};

    // Handle empty search
    if (searchValue.trim().length === 0) {
      setPagination((prev) => ({
        ...prev,
        currentPage: 1,
        searchValue: "",
      }));
      fetchShipmentData(1, pagination.rowCount, "");
      return;
    }

    // Handle search with value
    setPagination((prev) => ({
      ...prev,
      currentPage: newPagination?.page || 1,
      searchValue,
      pages: [...Array(newPagination?.pages || 1)].map((_, i) => i + 1),
    }));
    setShipmentData(shipments || []);

    setStatus((prev) => ({
      ...prev,
      noRecordsMessage:
        data?.shipments?.length < 1 ? "No shipment records available" : "",
    }));
  };

  const handleRefresh = () => {
    fetchShipmentData(
      pagination.currentPage,
      pagination.rowCount,
      pagination.searchValue
    );
  };

  const handlePageChange = (page) => {
    if (page === pagination.currentPage) return;
    fetchShipmentData(page, pagination.rowCount, pagination.searchValue);
  };

  const setRowsPerPage = (rows) => {
    setPagination((prev) => ({
      ...prev,
      rowCount: rows,
    }));
    fetchShipmentData(1, rows, pagination.searchValue);
  };

  useEffect(() => {
    fetchShipmentData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 overflow-hidden body pt-5">
      <div className="row flex p-4">
        <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden">
          <div className="row pt-2">
            <div className="col-lg-6 col-md-10">
              <Search
                filterData={handleSearchData}
                screen="Shipment"
                pageSize={pagination.rowCount}
              />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0">
              <button
                className="btn btn-primary btn-sm col-lg-6 col-md-6 col-sm-11 m-0 pl-0 pr-0 float-end"
                onClick={handleRefresh}
              >
                Refresh
              </button>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0">
              {currentUser?.shipment_save_bulk_upload_access_flag && (
                <Link to="/bulkShipment">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm col-lg-11 col-md-11 col-sm-11 m-0 float-end"
                  >
                    BULK SHIPMENT
                  </button>
                </Link>
              )}
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0">
              {currentUser?.shipment_save_record_access_flag && (
                <Link to="/createShipment">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm col-lg-11 col-md-11 col-sm-11 m-0 float-end"
                  >
                    SINGLE SHIPMENT
                  </button>
                </Link>
              )}
            </div>
          </div>

          <MDBCard className="card overflow-hidden h-md-100 table-box">
            <CustomHeader label={"SHIPMENT RECORDS"} />
            <MDBCardBody>
              <div className="table-main">
                <table className="table safeChiefTable">
                  <thead className="headerArea">
                    <tr>
                      <th scope="col" className="tableHeading1">
                        TAMPER EVIDENT BAG #
                      </th>

                      <th scope="col" className="tableHeading">
                        FROM LOCATION{" "}
                      </th>

                      <th scope="col" className="tableHeading">
                        TO LOCATION
                      </th>

                      <th scope="col" className="tableHeading">
                        SHIP START DATE{" "}
                      </th>

                      <th scope="col" className="tableHeading">
                        ARRIVAL DATE
                      </th>

                      <th scope="col" className="tableHeading">
                        PERFORMED BY
                      </th>

                      <th scope="col" className="tableHeading">
                        STATUS{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {status.saveLoader ? (
                      <tr>
                        <td colSpan="7" className="text-center py-5">
                          <Spinner animation="border" />
                        </td>
                      </tr>
                    ) : shipmentData.length > 0 ? (
                      shipmentData.map((item, index) => (
                        <tr
                          key={index}
                          className="table-row"
                          onClick={() =>
                            navigate("/shipmentHistory", { state: item })
                          }
                        >
                          <td>
                            {
                              item?.tamper_evident_bag
                                ?.tamper_evident_bag_number
                            }
                          </td>
                          <td>{item?.shipment?.from_location?.name}</td>
                          <td>{item?.shipment?.to_location?.name}</td>
                          <td>
                            {dayjs(item?.shipment?.start_date)
                              .tz(localTimezone)
                              .format(DISPLAY_DATE_FORMAT)}
                          </td>
                          <td>
                            {dayjs(item?.shipment?.estimated_arrival_date)
                              .tz(localTimezone)
                              .format(DISPLAY_DATE_FORMAT)}
                          </td>
                          <td>{item?.shipment?.performed_by?.employee_name}</td>
                          <td>{item?.status?.status}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center py-5">
                          {status.noRecordsMessage}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </MDBCardBody>
            <Pagination
              pages={pagination.pages}
              onNextClick={() => handlePageChange(pagination.currentPage + 1)}
              onPreviousClick={() =>
                handlePageChange(pagination.currentPage - 1)
              }
              onPageClick={handlePageChange}
              setRowsPerPage={setRowsPerPage}
              currentPage={pagination.currentPage}
            />
          </MDBCard>
        </div>
      </div>
    </div>
  );
}

export default ShipContents;
