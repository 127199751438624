import React from "react";
import Delete from "../assets/Delete.png";
import doc from "../assets/doc.png";
import pdf from "../assets/pdf.png";
import sheets from "../assets/sheets.png";

const DocumentsHorizontal = (props) => {
  if (
    document.getElementById("dh_left_slide") &&
    document.getElementById("timeline")
  ) {
    let leftButton = document.getElementById("dh_left_slide");
    leftButton.onclick = function () {
      document.getElementById("timeline").scrollLeft -= 600;
    };
  }

  if (
    document.getElementById("dh_right_slide") &&
    document.getElementById("timeline")
  ) {
    let rightButton = document.getElementById("dh_right_slide");
    rightButton.onclick = function () {
      document.getElementById("timeline").scrollLeft += 600;
    };
  }
  return (
    <div>
      <div className="col-lg-12  col-md-12 col-sm-12 d-flex container ">
        <div
          className="icon-circle d-flex justify-content-center align-items-center mt-5 mr-2 "
          id="dh_left_slide"
        >
          <i
            id="icon_angle_left"
            className="fa fa-angle-left fa-lg fa-fw left-icon "
            aria-hidden="true"
          ></i>
        </div>
        <div className="d-flex mx-0 mr-0 timeline" id="timeline">
          {props.documentList?.map((document) => (
            <>
              <div
                className="row"
                id={`dh_row_${document.documentId}`}
                key={document.documentId}
                style={{ paddingLeft: "30px" }}
              >
                <div
                  className="col-lg-3 col-md-3 col-sm-6 divImageContainer "
                  id={`dh_col_1_${document.documentId}`}
                >
                  <figure
                    id={`dh_fig_${document.documentId}`}
                    className="img-thumbnail btn-sm divFigureContainer "
                    style={{ border: "none" }}
                  >
                    <img
                      id={`dh_img_${document.documentId}`}
                      className="imageFigure"
                      style={{ height: "50px", marginTop: "40px" }}
                      alt="..."
                      src={(() => {
                        if (
                          document.docType === "data:image/png" ||
                          document.docType === "data:image/jpeg"
                        ) {
                          return document.src;
                        } else if (
                          document.docType === "data:application/pdf"
                        ) {
                          return pdf;
                        } else if (
                          document.docType ===
                          "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        ) {
                          return doc;
                        } else if (
                          document.docType === "data:text/csv" ||
                          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        ) {
                          return sheets;
                        }
                      })()}
                    />
                    <div
                      className="fileNames"
                      id={`dh_file_name_${document.documentId}`}
                    >
                      {document.fileName}
                    </div>
                  </figure>
                </div>
                <div
                  className="col-lg-9 col-md-9 col-sm-6 divDetails"
                  id={`dh_col_2_${document.documentId}`}
                >
                  <div id={`dh_container_${document.documentId}`}>
                    <div
                      className="col-lg-2"
                      id={`dh_container_col_1_${document.documentId}`}
                    >
                      <label> Description : </label>
                    </div>
                    <div
                      className="col-lg-12"
                      style={{ width: "600px" }}
                      id={`dh_container_col_1_${document.documentId}`}
                    >
                      <textarea
                        id={`dh_comments_${document.documentId}`}
                        style={{}}
                        type="text"
                        className="border-start-0 border-end-0 border-top-0"
                        placeholder="Enter Comments"
                        name="ImgComments"
                        onChange={(e) =>
                          props.onUpdateDocComment(
                            document.documentId,
                            e.target.value,
                            props.itemId,
                            props?.addendumType
                          )
                        }
                        value={document?.comments}
                      />
                      <img
                        id={`dh_trash_${document.documentId}`}
                        style={{
                          height: "30px",
                          width: "30px",
                          paddingBottom: "15px",
                          paddingLeft: "15px",
                          paddingRight: "5px",
                          cursor: "pointer",
                          zIndex: 1,
                        }}
                        src={Delete}
                        className="figure-img img-fluid rounded"
                        alt=" "
                        onClick={() =>
                          props.onDeleteDocument(
                            document.documentId,
                            props.itemId,
                            props?.addendumType
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
        <div
          className="icon-circle d-flex justify-content-center align-items-center mt-5 ml-2"
          id="dh_right_slide"
        >
          <i
            id="icon_angle_right"
            className="fa fa-angle-right fa-lg fa-fw"
            aria-hidden="true"
          ></i>
        </div>
      </div>
    </div>
  );
};

export default DocumentsHorizontal;
