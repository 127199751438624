import { MDBCard } from "mdbreact";
import CustomHeader from "../components/CustomHeader";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DocumentUpload from "../components/documentUpload";
import Inputupload from "../components/Inputupload";
import React, { useState } from "react";
import * as XLSX from "xlsx";
import ConfigData from "../config";
import { CustomAlert } from "../components/customAlert";
import Swal from "sweetalert2";
import { saveTEBBulkUpload } from "../api/TamperEvidentBagBulkUploadService";
import { cognitoSignOut } from "../api/CognitoServices";
import { ConsoleError } from "../utils/ErrorUtils";
import { ERROR_MESSAGE } from "../constants/MessagesConstants";
import { Pager } from "../components/pager";
import { getExention } from "../utils/FileUtils";
import useFetchNotifications from "../utils/NotificationUtils";

let CustomBagUploadFsi = (props) => {
  let navigate = useNavigate();

  const [datafield, setDataField] = useState(false);
  //eslint-disable-next-line
  const [currentEventData, setCurrentEventData] = useState({
    EventType: "Alert",
    EventData: "",
  });
  //eslint-disable-next-line
  const [showMessage, setShowMessage] = useState(false);
  //eslint-disable-next-line
  const [requriedData, setRequriedData] = useState(ConfigData.bagUploadFSI);

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  useFetchNotifications(currentUser?.employee_id, currentUser?.jwtToken);

  const [showData, setShowData] = useState([]);

  const [pageState, setPageState] = useState({ pageNum: 1, rowsPerPage: 10 });

  let [fileName, setFileName] = useState("File Name ");

  let fileUpload = async (e) => {
    setDataField(false);
    let file = e.target.files[0];
    setFileName(e.target.files[0].name);
    if (getExention(file)) {
      if (file.size > 10e6) {
        setCurrentEventData({
          EventType: "Alert",
          EventData: "Please upload a file smaller than 10 MB",
        });
        setShowMessage(true);
      }
    } else {
      setCurrentEventData({
        EventType: "Alert",
        EventData: "Invalid file input, Select Excel, CSV file",
      });

      setShowMessage(true);
    }
    let defaultHeaders = [];
    if (file) {
      let uploadFileData = new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
          let bufferArray = e.target.result;
          let wb = XLSX.read(bufferArray, { type: "binary" });
          let wsname = wb.SheetNames[0];
          let ws = wb.Sheets[wsname];
          let data = XLSX.utils.sheet_to_json(ws);

          let headers = XLSX.utils.sheet_to_json(ws, {
            header: 1,
          });
          defaultHeaders = headers[0];
          resolve(data);
          setDataField(true);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
      uploadFileData.then((data) => {
        let keysFromFile = defaultHeaders;
        let defaultKeys = requriedData;
        let differentKeys = [];
        function arraysEqual(keysFromFile, defaultKeys) {
          if (
            keysFromFile === null ||
            defaultKeys === null ||
            keysFromFile.length !== defaultKeys.length
          )
            return false;
          for (let i = 0; i < defaultKeys.length; ++i) {
            if (defaultKeys[i] !== keysFromFile[i]) return false;
          }
          return true;
        }
        arraysEqual(keysFromFile, defaultKeys);
        let flag = arraysEqual(keysFromFile, defaultKeys);
        if (flag === false) {
        }
        let removeDuplicateObjects = (data) => {
          let uniqueArr = [];
          let objStrings = [];
          data.forEach((element) => {
            if (typeof element === "object" && element !== null) {
              let eleString = JSON.stringify(element);
              if (!objStrings.includes(eleString)) {
                uniqueArr.push(element);
                objStrings.push(eleString);
              }
            }
          });
          return uniqueArr;
        };

        let filteredData = removeDuplicateObjects(data);

        if (flag === true) {
          setShowData(filteredData);
          if (data.length > filteredData.length) {
            let removeItem = data.length - filteredData.length;
            let text =
              removeItem + " records were removed out of " + data.length;
            let confirmButtonText = "OK";
            let showConfirmButton = false;
            let title = removeItem + " Duplicate Records Found ";
            let type = "warning";
            CustomAlert(
              title,
              text,
              type,
              confirmButtonText,
              showConfirmButton
            );
          }
        } else {
          setFileName("File Name");
          setShowData([]);
          let text;
          differentKeys = defaultKeys.filter((d) => !keysFromFile.includes(d));
          if (differentKeys.length < 3) {
            text = "Please check " + differentKeys + " and upload again";
          } else {
            text =
              "Please check " +
              differentKeys[0] +
              "," +
              differentKeys[1] +
              "," +
              differentKeys[3] +
              " and few other fields, upload  file again";
          }
          let confirmButtonText = "OK";
          let showConfirmButton = false;
          let title = "Missing fields";
          let type = "warning";
          CustomAlert(title, text, type, confirmButtonText, showConfirmButton);
        }
      });
    }
  };

  let onSubmit = () => {
    Swal.fire({
      title: "Please Wait !",
      html: "Saving the records",
      allowOutsideClick: false,
      target: document.getElementById("swal-div"),
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    let fileUploadEmptyData = [];

    let fileuploadrowsdata = showData.map((fileData) => {
      if (
        fileData["DATE ENTERED"] !== undefined &&
        fileData["ACCOUNT NUMBER"] !== undefined &&
        fileData["DORMANCY ID"] !== undefined &&
        fileData["OWNER FIRST NAME"] !== undefined &&
        fileData["OWNER LAST NAME"] !== undefined &&
        fileData["COMPANY NAME"] !== undefined &&
        fileData["OWNER Tax ID"] !== undefined &&
        fileData["SSN or EIN"] !== undefined &&
        fileData["OWNER ADDRESS"] !== undefined &&
        fileData["OWNER CITY"] !== undefined &&
        fileData["OWNER STATE"] !== undefined &&
        fileData["OWNER ZIP"] !== undefined &&
        fileData["OWNER RELATION CODE"] !== undefined &&
        fileData["OWNER PROPERTY TYPE CODE"] !== undefined &&
        fileData["REPORTING STATE"] !== undefined &&
        fileData["Region"] !== undefined &&
        fileData["Market"] !== undefined &&
        fileData["BRANCH NAME"] !== undefined &&
        fileData["BRANCH DID"] !== undefined &&
        fileData["BOX NUMBER"] !== undefined &&
        fileData["TAMPER EVIDENT BAG #"] !== undefined &&
        fileData["Status"] !== undefined &&
        fileData["CONTENTS Y/N"] !== undefined &&
        fileData["TANGIBLE INVENTORY"] !== undefined &&
        fileData["AMOUNT REPORTED"] !== undefined &&
        fileData["Audit ID"] !== undefined &&
        fileData["Bag Status"] !== undefined &&
        fileData["Storage Location"] !== undefined &&
        fileData["Box Drilled"] !== undefined &&
        fileData["Box Drill Date"] !== undefined &&
        fileData["Last Audit Date"] !== undefined &&
        fileData["Dual Control EID #1"] !== undefined &&
        fileData["Dual Control EID #2"] !== undefined &&
        fileData["DATE OF LAST TRANSACTION"] !== undefined
      ) {
        return {
          "DATE ENTERED": fileData["DATE ENTERED"]
            ? fileData["DATE ENTERED"]
            : "",
          "ACCOUNT NUMBER": fileData["ACCOUNT NUMBER"]
            ? fileData["ACCOUNT NUMBER"]
            : "",
          "DORMANCY ID": fileData["DORMANCY ID"] ? fileData["DORMANCY ID"] : "",
          "OWNER FIRST NAME": fileData["OWNER FIRST NAME"]
            ? fileData["OWNER FIRST NAME"]
            : "",
          "OWNER MIDDLE NAME": fileData["OWNER MIDDLE NAME"]
            ? fileData["OWNER MIDDLE NAME"]
            : "",
          "OWNER LAST NAME": fileData["OWNER LAST NAME"]
            ? fileData["OWNER LAST NAME"]
            : "",
          "COMPANY NAME": fileData["COMPANY NAME"]
            ? fileData["COMPANY NAME"]
            : "",
          "OWNER Tax ID": fileData["OWNER Tax ID"]
            ? fileData["OWNER Tax ID"]
            : "",
          "SSN or EIN": fileData["SSN or EIN"] ? fileData["SSN or EIN"] : "",
          "OWNER ADDRESS": fileData["OWNER ADDRESS"]
            ? fileData["OWNER ADDRESS"]
            : "",
          "OWNER CITY": fileData["OWNER CITY"] ? fileData["OWNER CITY"] : "",
          "OWNER STATE": fileData["OWNER STATE"] ? fileData["OWNER STATE"] : "",
          "OWNER ZIP": fileData["OWNER ZIP"] ? fileData["OWNER ZIP"] : "",
          "OWNER RELATION CODE": fileData["OWNER RELATION CODE"]
            ? fileData["OWNER RELATION CODE"]
            : "",
          "OWNER PROPERTY TYPE CODE": fileData["OWNER PROPERTY TYPE CODE"]
            ? fileData["OWNER PROPERTY TYPE CODE"]
            : "",
          "SECONDARY OWNER FIRST NAME": fileData["SECONDARY OWNER FIRST NAME"]
            ? fileData["SECONDARY OWNER FIRST NAME"]
            : "",
          "SECONDARY OWNER MIDDLE NAME": fileData["SECONDARY OWNER MIDDLE NAME"]
            ? fileData["SECONDARY OWNER MIDDLE NAME"]
            : "",
          "SECONDARY OWNER LAST NAME": fileData["SECONDARY OWNER LAST NAME"]
            ? fileData["SECONDARY OWNER LAST NAME"]
            : "",
          "SECONDARY OWNER SSN/TAX ID": fileData["SECONDARY OWNER SSN/TAX ID"]
            ? fileData["SECONDARY OWNER SSN/TAX ID"]
            : "",
          "SECONDARY OWNER ADDRESS": fileData["SECONDARY OWNER ADDRESS"]
            ? fileData["SECONDARY OWNER ADDRESS"]
            : "",
          "SECONDARY OWNER STATE": fileData["SECONDARY OWNER STATE"]
            ? fileData["SECONDARY OWNER STATE"]
            : "",
          "SECONDARY OWNER CITY": fileData["SECONDARY OWNER CITY"]
            ? fileData["SECONDARY OWNER CITY"]
            : "",
          "SECONDARY OWNER ZIP": fileData["SECONDARY OWNER ZIP"]
            ? fileData["SECONDARY OWNER ZIP"]
            : "",
          "REPORTING STATE": fileData["REPORTING STATE"]
            ? fileData["REPORTING STATE"]
            : "",
          Region: fileData["Region"] ? fileData["Region"] : "",
          Market: fileData["Market"] ? fileData["Market"] : "",
          "BRANCH NAME": fileData["BRANCH NAME"] ? fileData["BRANCH NAME"] : "",
          "BRANCH DID": fileData["BRANCH DID"] ? fileData["BRANCH DID"] : "",
          "BOX NUMBER": fileData["BOX NUMBER"] ? fileData["BOX NUMBER"] : "",
          "TAMPER EVIDENT BAG #": fileData["TAMPER EVIDENT BAG #"]
            ? fileData["TAMPER EVIDENT BAG #"]
            : "",
          Status: fileData["Status"] ? fileData["Status"] : "",
          "CONTENTS Y/N": fileData["CONTENTS Y/N"]
            ? fileData["CONTENTS Y/N"]
            : "",
          "TANGIBLE INVENTORY": fileData["TANGIBLE INVENTORY"]
            ? fileData["TANGIBLE INVENTORY"]
            : "",
          "AMOUNT REPORTED": fileData["AMOUNT REPORTED"]
            ? fileData["AMOUNT REPORTED"]
            : "",
          "LOUISIANA BOND": fileData["LOUISIANA BOND"] ? true : false,
          "MILITARY MEDAL": fileData["MILITARY MEDAL"] ? true : false,
          SCRA: fileData["SCRA"] ? true : false,
          "Audit ID": fileData["Audit ID"] ? fileData["Audit ID"] : "",
          "Bag Status": fileData["Bag Status"] ? fileData["Bag Status"] : "",
          "Storage Location": fileData["Storage Location"]
            ? fileData["Storage Location"]
            : "",
          "Box Drilled": fileData["Box Drilled"] ? fileData["Box Drilled"] : "",
          "Box Drill Date": fileData["Box Drill Date"]
            ? fileData["Box Drill Date"]
            : "",
          "Last Audit Date": fileData["Last Audit Date"]
            ? fileData["Last Audit Date"]
            : "",
          "Dual Control EID #1": fileData["Dual Control EID #1"]
            ? fileData["Dual Control EID #1"]
            : "",
          "Dual Control EID #2": fileData["Dual Control EID #2"]
            ? fileData["Dual Control EID #2"]
            : "",
          "DATE OF LAST TRANSACTION": fileData["DATE OF LAST TRANSACTION"]
            ? fileData["DATE OF LAST TRANSACTION"]
            : "",
          "UNPAID RENT": fileData["UNPAID RENT"] ? fileData["UNPAID RENT"] : "",
        };
      } else {
        fileUploadEmptyData.push(fileData);
        return null;
      }
    });

    if (fileUploadEmptyData.length >= 1) {
      let text = "Please check Critical Field Data is  Missing in File ";

      let confirmButtonText = "OK";
      let showConfirmButton = false;
      let title = "Missing fields";
      let type = "warning";
      CustomAlert(title, text, type, confirmButtonText, showConfirmButton);
    } else {
      let dataSource = {
        data: fileuploadrowsdata,
        employee_name: currentUser?.employee_name,
        employee_id: currentUser?.employee_id,
        employee_designation: currentUser?.employee_designation,
      };

      saveTEBBulkUpload(dataSource, currentUser?.jwtToken)
        .then((response) => {
          Swal.close();
          if (response.status === 401) {
            cognitoSignOut()
              .then(() => {
                navigate("/");
              })
              .catch((error) => {
                ConsoleError(error);
              });
          } else if (response?.status === 400) {
            let title = "Invalid Records";
            let text = response?.data.message;
            let type = "warning";
            let confirmButtonText = "OK";
            let showCancelButton = false;
            let target = document.getElementById("swal-div");
            CustomAlert(
              title,
              text,
              type,
              confirmButtonText,
              showCancelButton,
              target
            ).then((result) => {
              setFileName("File Name");
            });
          } else if (response?.status === 500) {
            let title = ERROR_MESSAGE;
            let text = "Please try after some time";
            let type = "error";
            let confirmButtonText = "OK";
            let showCancelButton = false;
            let target = document.getElementById("swal-div");
            CustomAlert(
              title,
              text,
              type,
              confirmButtonText,
              showCancelButton,
              target
            ).then((result) => {
              setFileName("File Name");
            });
          } else if (response?.data?.status === 200) {
            let title = "Success";
            let text = response?.data.message;
            let type = "success";
            let confirmButtonText = "OK";
            let showCancelButton = false;
            let target = document.getElementById("swal-div");
            CustomAlert(
              title,
              text,
              type,
              confirmButtonText,
              showCancelButton,
              target
            ).then((result) => {
              setFileName("File Name");
              setShowData([]);
            });
          }
        })
        .catch((error) => {
          console.error(error);
          ConsoleError(error);
          let title = ERROR_MESSAGE;
          let text = "Error Message";
          let type = "error";
          let confirmButtonText = "OK";
          let showCancelButton = false;
          let target = document.getElementById("swal-div");
          CustomAlert(
            title,
            text,
            type,
            confirmButtonText,
            showCancelButton,
            target
          ).then((result) => {
            setFileName("File Name");
          });
        });
    }
  };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 h-md-220 p-5 ">
        <div className="row flex pt-5 pl-4 pr-4 pb-0 " id="swal-div">
          <div className="col-lg-12 col-md-12 col-sm-12 pb-2git a pl-0 pr-0 overflow-hidden">
            <MDBCard className="card bagUploadCustomStyle">
              <CustomHeader id="single" label={"CUSTOM BAG STATUS UPLOAD"} />

              <div className="d-flex justify-content-center align-items-center  pt-3">
                <div className="pr-5">
                  <input
                    type="radio"
                    value="fsi"
                    name="radio"
                    className="cb-radio"
                    defaultChecked
                  />
                  <label className="radio-button">FSI</label>
                </div>
              </div>

              <div className="divUpload pb-0 d-flex  justify-content-between align-items-center mb-2 p-1">
                <div className="d-flex align-items-center "></div>
                <div className="d-flex align-items-center">
                  <DocumentUpload onChange={fileUpload} fileName={fileName} />
                </div>
              </div>

              {showData.length > 0 ? (
                <>
                  <table
                    className="table_wrapper"
                    id={!datafield ? "cbc-scroll" : "cbc-scroll1"}
                  >
                    <tr
                      key={"header"}
                      id="cbc-table-heading"
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "#6F7D95",
                        zIndex: 1,
                      }}
                    >
                      {requriedData.map((key, idx) => (
                        <th key={idx}>{key}</th>
                      ))}
                    </tr>
                    <>
                      {showData.map((item, index) => {
                        return (
                          <>
                            <tbody>
                              <tr
                                key={index}
                                style={{
                                  background:
                                    index % 2 === 0 ? "#FFFFFF" : "#e3e3e3",
                                }}
                              >
                                {requriedData.map((key, idx) => (
                                  <td key={idx} className="td-text">
                                    {item[key] ? item[key] : ""}
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </>
                        );
                      })}
                    </>
                  </table>
                  <Pager
                    totalPages={
                      showData.length ? Math.ceil(showData.length / 10) : 0
                    }
                    currentPage={pageState.pageNum}
                    onPageChange={(newPage) =>
                      setPageState({
                        pageNum: newPage,
                        rowsPerPage: 10,
                      })
                    }
                    rowsPerPage={10}
                    setRowsPerPage={(num) =>
                      setPageState({ pageNum: 1, rowsPerPage: num })
                    }
                    dataCount={showData.length}
                    dataLength={showData.length}
                  />
                </>
              ) : (
                <div className="d-flex row align-items-center justify-content-center vertical-middle text-center divTableMain">
                  <Inputupload label="Upload Document" />
                </div>
              )}

              <div className="fieldArea7 pt-2 pb-2 justify-content-center">
                <button
                  className="btn  btn-sm btn-primary"
                  variant="contained"
                  onClick={() => {
                    navigate("/viewAllCheckIn");
                  }}
                >
                  CANCEL
                </button>
                <button
                  className="btn  btn-sm btn-primary"
                  variant="contained"
                  onClick={() => {
                    onSubmit();
                  }}
                  disabled={!datafield}
                >
                  Submit
                </button>
              </div>
            </MDBCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomBagUploadFsi;
