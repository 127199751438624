import React, { useEffect,useState } from "react";

import { useFormContext } from "../../context/FormContext";
import "../../style/ForceOpen.css";
import { FORCE_OPEN_ABANDON_PROP_EMAIL_MESSAGE } from "../../constants/MessagesConstants";
import { getTenantDetails }  from "../../api/YTPAdminServices.js";
import { PrintDisable } from "../../utils/PrintDisable";
import { useSelector } from "react-redux";
import { ConsoleError } from "../../utils/ErrorUtils";

const Section4 = (props) => {
  const { state, handleChange } = useFormContext();
  const [tenantDetails, setTenantDetails] = useState({});

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  PrintDisable(currentUser?.print_access_flag);
  
 
  
  useEffect(()=>{
    getTenantDetails(currentUser?.jwtToken)
      .then((response) => {
        setTenantDetails(response?.data?.tennant_record)
        
      })
      .catch((error) => {
        ConsoleError(error);
      });
  },[])

  return (
    <>
      <div className="row justify-content-center">SECTION IV</div>
      <div className="row justify-content-center">(FEE DETAILS)</div>
      <div className="text-left card-border">
        <p id="p-input">
          {" "}
          In the event a customer retrieves their forced open contents, complete
          the Release of Contents below and record the fees collected.
        </p>
        <div className="form-row ml-3">
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="pastRentalFee" style={{ fontSize: "17px" }}>
              <b>Past Due Rental Fee: $</b>
            </label>
            <input
              type="number"
              min={0}
              className="form-control"
              id="pastRentalFee"
              autoComplete="off"
              onChange={(e) => {
                handleChange(
                  "section1",
                  "past_due_rental_fee",
                  parseFloat(e.target.value),
                  "UPDATE_VALUE"
                );

                handleChange(
                  "section1",
                  "total_fee",
                  parseFloat(state?.section1?.force_open_cost) +
                    parseFloat(state?.section1?.inventory_fee) +
                    parseFloat(e.target.value),
                  "UPDATE_VALUE"
                );
              }}
              value={state?.section1?.past_due_rental_fee}
            />
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="forceOpenCost" style={{ fontSize: "17px" }}>
              <b>Force Open Cost: $</b>
            </label>
            <input
              type="number"
              min={0}
              className="form-control"
              id="forceOpenCost"
              autoComplete="off"
              value={state?.section1?.force_open_cost}
              onChange={(e) => {
                handleChange(
                  "section1",
                  "force_open_cost",
                  parseFloat(e.target.value),
                  "UPDATE_VALUE"
                );
                handleChange(
                  "section1",
                  "total_fee",
                  parseFloat(state?.section1?.past_due_rental_fee) +
                    parseFloat(state?.section1?.inventory_fee) +
                    parseFloat(e.target.value),
                  "UPDATE_VALUE"
                );
              }}
            />
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="inventoryFee" style={{ fontSize: "17px" }}>
              <b>Inventory Fee: $</b>
            </label>
            <input
              type="number"
              min={0}
              className="form-control"
              id="inventoryFee"
              autoComplete="off"
              value={state?.section1?.inventory_fee}
              onChange={(e) => {
                handleChange(
                  "section1",
                  "inventory_fee",
                  parseFloat(e.target.value),
                  "UPDATE_VALUE"
                );
                handleChange(
                  "section1",
                  "total_fee",
                  parseFloat(state?.section1?.past_due_rental_fee) +
                    parseFloat(state?.section1?.force_open_cost) +
                    parseFloat(e.target.value),
                  "UPDATE_VALUE"
                );
              }}
            />
          </div>

          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="totalFee" style={{ fontSize: "17px" }}>
              <b>Total Fee: $</b>
            </label>
            <input
              type="number"
              min={0}
              className="form-control"
              id="totalFee"
              autoComplete="off"
              disabled
              value={state?.section1?.total_fee}
            />
          </div>
        </div>
        <div className="text-center">
          <p id="p-input2">
            <b>*******Send signed copy to AbandonedProperty@{tenantDetails?.tenant_name} within 24 hours*******</b>
          </p>
        </div>
        {props.printScreen && (
          <>
            <div className="text-center">
              <p>
                <b>RELEASE OF CONTENTS</b>
              </p>
            </div>
            <p id="p-input">
              I do hereby acknowledge that the list of the contents above is a
              complete list of all property stored in said Safe Deposit Box and
              I hereby relieve and release {tenantDetails?.tenant_name}, N.A. from all
              liability for said property and contents.
            </p>
            <div className="form-row ml-4">
              <div className="form-group col-md-4 px-4">
                <label htmlFor="renterID">Renter ID</label>
                <input
                  type="text"
                  className="form-control"
                  id="renterID"
                  placeholder="Renter ID"
                  value={state?.section4?.renterID}
                  autoComplete="off"
                  onChange={(e) =>
                    handleChange(
                      "section4",
                      "renterID",
                      e.target.value,
                      "UPDATE_VALUE"
                    )
                  }
                />
              </div>
              <div className="form-group col-md-4 px-4">
                <label htmlFor="renterName">Renter's Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="renterName"
                  placeholder="Renter Name"
                  value={state?.section4?.renterName}
                  autoComplete="off"
                  onChange={(e) =>
                    handleChange(
                      "section4",
                      "renterName",
                      e.target.value,
                      "UPDATE_VALUE"
                    )
                  }
                />
              </div>
              <div className="form-group col-md-4 px-4">
                <label htmlFor="renterSignature">Renter Signature</label>
                <input
                  type="text"
                  min={0}
                  className="form-control"
                  id="renterSignature"
                  value={state?.section4?.renterSignature}
                  onChange={(e) =>
                    handleChange(
                      "section4",
                      "renterSignature",
                      e.target.value,
                      "UPDATE_VALUE"
                    )
                  }
                />
              </div>
            </div>
            <div className="form-row ml-4">
              <div className="form-group col-md-4 px-4">
                <label htmlFor="sec4Date">Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="sec4Date"
                  placeholder="mm/dd/yyyy"
                  autoComplete="off"
                  onChange={(e) =>
                    handleChange(
                      "section4",
                      "sec4Date",
                      e.target.value,
                      "UPDATE_VALUE"
                    )
                  }
                  value={state?.section4?.sec4Date}
                />
              </div>
              <div className="form-group col-md-4 px-4"></div>
              <div className="form-group col-md-4 px-4">
                <label htmlFor="witnessSignature">Witness Signature</label>
                <input
                  type="text"
                  min={0}
                  className="form-control"
                  id="witnessSignature"
                  value={state?.section4?.witnessSignature}
                  onChange={(e) =>
                    handleChange(
                      "section4",
                      "witnessSignature",
                      e.target.value,
                      "UPDATE_VALUE"
                    )
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Section4;
