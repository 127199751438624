import Swal from "sweetalert2";
import "../style/customalert.css";

export const CustomAlert = async (
  title,
  text,
  type,
  confirmButtonText,
  showCancelButton,
  cancelButtonText,
  target,
  footer,

) => {
  return Swal.fire({
    title: title,
    text: text,

    type: type,
    showCancelButton: showCancelButton ? showCancelButton : false,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    // width: 800,
    // padding: 100,
    heightAuto: false,
    target: target?target:null,
    footer: footer?footer:null ,

    // grow: 'row',
    position:"center",
    showConfirmButton: confirmButtonText ? true : false,

    customClass: {
      confirmButton: "swal-button",
      cancelButton: "swal-button",
      container: 'position'

    },
  });
};
