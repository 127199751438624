import React, { useEffect, useState } from 'react';
import { saveAs } from "file-saver";

function ForceopenApproval(props) {
    const [storageLocation, setStorageLocation] = useState();
    const [owner, setOwner] = useState();
    const [documentList, setDocumentList] = useState([]);
    const [pictureList, setPictureList] = useState([]);

    const downloadFile = (link, name) => {
        saveAs(link, name);
    };

    useEffect(() => {
        setOwner(props?.data?.activity?.safe_deposit_box?.owner?.first_name + " " + props?.data?.activity?.safe_deposit_box?.owner?.last_name)
        setStorageLocation(props?.data?.activity?.safe_deposit_box?.branch ? props?.data?.activity?.safe_deposit_box?.branch : null)

        if (props?.data?.activity?.addendum_a_b.length > 0) {
            setDocumentList(props?.data?.activity?.addendum_a_b[0]?.documents?.filter((document)=> document?.type === 'DOCUMENT'))
            setPictureList(props?.data?.activity?.addendum_a_b[0]?.documents?.filter((document)=> document?.type === 'PICTURE'))
        } else {
            setDocumentList(props?.data?.activity?.addendum_c[0]?.documents?.filter((document)=> document?.type === 'DOCUMENT'))
            setPictureList(props?.data?.activity?.addendum_c[0]?.documents?.filter((document)=> document?.type === 'PICTURE'))
        }
    }, [props])
    return (
        <>
            <div className="form-group row">
                <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                    Storage Location{" "}
                </label>
                <div className="col-sm-8 p-0">
                    <div className="float-start">
                        {storageLocation?.name}
                    </div>
                </div>
            </div>
            <div className="form-group row">
                <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                    Storage Type{" "}
                </label>
                <div className="col-sm-8 p-0">
                    <div className="float-start">
                        {storageLocation?.storage_type}
                    </div>
                </div>
            </div>
            <div className="form-group row">
                <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                    Owner{" "}
                </label>
                <div className="col-sm-8 p-0">
                    <div className="float-start">
                        {owner}
                    </div>
                </div>
            </div>
            <div className="form-group row">
                <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                    Notes{" "}
                </label>
                <div className="col-sm-8 p-0">
                    <div className="float-start text-left">
                        {props?.data?.comments ? props?.data?.comments : '-'}
                    </div>
                </div>
            </div>
            {documentList?.length > 0 && (
                <div className="form-group row">
                    <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                        Documents{" "}
                    </label>
                    <div className="col-sm-8 p-0">
                        {documentList?.length > 0 && (
                                <div className="row ">
                                    <div className="col-8 fw-bold d-flex pl-3">
                                        <span className="float-start">
                                            Name
                                        </span>
                                    </div>
                                    <div className="col-4 fw-bold">
                                        Description
                                    </div>
                                </div>
                            )}
                        {documentList
                            ?.map((filteredDocument) => (
                                <div className="row">
                                    <div className="col-8 d-flex">
                                        <span
                                            className="cursor-pointer float-start"
                                            onClick={() =>
                                                downloadFile(
                                                    filteredDocument?.url,
                                                    filteredDocument?.name
                                                )
                                            }
                                        >
                                            {filteredDocument?.name}
                                            <i className="fa fa-download pl-2" />
                                        </span>
                                    </div>
                                    <div className="col-4 ">
                                        {filteredDocument?.description ? filteredDocument?.description : '-'}
                                    </div>
                                </div>
                            ))}


                    </div>
                </div>
            )}
            {pictureList?.length > 0 && (
                <div className="form-group row">
                    <label className='d-flex col-sm-4 col-form-label nput-label p-0'>
                        Pictures {" "}
                    </label>
                    <div className='col-sm-8 p-0'>

                        {pictureList?.length > 0 && (
                                <div className="row">
                                    <div className="col-8 fw-bold d-flex pl-3">
                                        <span className="float-start">
                                            Name
                                        </span>
                                    </div>
                                </div>

                            )}
                        {pictureList
                            .map((filteredPicture) => (
                                <>
                                    <div className="row">
                                        <div className="col-8 d-flex">
                                            <span
                                                className="cursor-pointer float-start"
                                                onClick={() =>
                                                    downloadFile(
                                                        filteredPicture?.url,
                                                        filteredPicture?.name
                                                    )
                                                }
                                            >
                                                {filteredPicture?.name}
                                                <i className="fa fa-download pl-2" />
                                            </span>
                                        </div>
                                    </div>
                                </>
                            ))}
                    </div>
                </div>
            )}
        </>
    )
}

export default ForceopenApproval