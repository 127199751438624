import React, { useState, useEffect } from "react";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { useLocation, useNavigate } from "react-router-dom";
import CustomHeader from "../components/CustomHeader";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { saveSubscriptionType } from "../api/YTPAdminServices"; 
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import { ConsoleError } from "../utils/ErrorUtils";
import { ERROR_MESSAGE } from "../constants/MessagesConstants";
import { CustomAlert } from "../components/customAlert";

function YTPAdminAddSubscription() {
  const location = useLocation();
  const [isEditing, setIsEditing] = useState(false);
  const [tenantData, setTenantData] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  
  const initialValues = {
    subscription_type_name: "",
    plan: "",
    amount: ""
  };
  
  const [formValues, setFormValues] = useState(initialValues);
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  useEffect(() => {
    const state = location.state || {};
    if (state.mode === "editTenant") {
      setIsEditing(true);
      setTenantData(state.tenantData || {});
    } else {
      setIsEditing(false);
      setTenantData(null);
    }
  }, [location]);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Define field-specific character limits
    const charLimits = {
      subscription_type_name: 50,
      plan: 50,
      amount: 10, // Maximum 10 characters including decimals
    };
  
    if (charLimits[name] && value.length > charLimits[name]) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: `Maximum ${charLimits[name]} characters allowed`,
      }));
      return;
    }
  
    // Handle numeric validation for the "amount" field
    if (name === "amount") {
      // Allow numbers, optional decimal, and up to 2 decimal places
      const regex = /^\d*(\.\d{0,2})?$/;
  
      if (!regex.test(value)) {
        setFormErrors((prev) => ({
          ...prev,
          [name]: "Amount must be a valid number with up to two decimals",
        }));
        return;
      }
    }
  
    // Update state
    setFormValues((prev) => ({ ...prev, [name]: value }));
  
    // Clear errors for the field
    if (formErrors?.[name]) {
      setFormErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsSubmit(true);
      Swal.fire({
        title: "Processing, please wait",
        html: "loading...",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      const subscriptionData = {
        subscription_type_name: formValues.subscription_type_name,
        plan: formValues.plan,
        amount: parseFloat(formValues.amount),
      };

      saveSubscriptionType(subscriptionData, currentUser?.jwtToken)
        .then((response) => {
          Swal.close();
          if (response.status === 401) {
            cognitoSignOut()
              .then(() => {
                navigate("/");
              })
              .catch((err) => {
                console.error(err);
              });
          } else if (response?.status === 201) {
            showSuccessAlert("Subscription created successfully");
          } else if (response?.status === 400) {
            showFailAlert("Create Subscription failed due to invalid data");
          } else if (response?.status === 409) {
            showFailAlert("Subscription already exists");
          } else {
            showFailAlert("Create Subscription failed");
          }
        })
        .catch((error) => {
          Swal.close();
          showFailAlert(ERROR_MESSAGE);
          ConsoleError(error);
        });
    }
  };

  const showSuccessAlert = (response) => {
    CustomAlert("Saved", response, "success", "OK", false, document.getElementById("swal-div"))
      .then((result) => {
        if (result.value) {
          navigate("/subscriptions");
        }
      });
  };

  const showFailAlert = (response) => {
    CustomAlert("Failed", response, "error", "OK", false, document.getElementById("swal-div"));
  };

  const validate = (values) => {
    const errors = {};
    const regexWhiteSpaces = /.*\S.*/;
  
    if (!values?.subscription_type_name || !regexWhiteSpaces.test(values?.subscription_type_name)) {
      errors.subscription_type_name = "Name is required";
    }
  
    if (!values?.plan || !regexWhiteSpaces.test(values?.plan)) {
      errors.plan = "Plan is required";
    }
  
    if (!values?.amount) {
      errors.amount = "Amount is required";
    } else if (isNaN(values.amount)) {
      errors.amount = "Amount must be a number";
    } else if (!/^\d+(\.\d{1,2})?$/.test(values.amount)) {
      errors.amount = "Amount must be a valid number with up to two decimals";
    }
  
    return errors;
  };
  

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-auto p-0 body">
      <div className="row flex pl-4 pt-4 pr-4 pb-0">
        <div className="col-lg-12 pb-3 pl-0 pr-0">
          <div className="row pt-1 pb-2 mt-5">
            <div className="d-flex justify-content-end">
              <div className="p-0">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  id="checkin-records-button"
                  onClick={() => navigate("/subscriptions")}
                >
                  SUBSCRIPTIONS
                </button>
              </div>
            </div>
          </div>
          <MDBCard className="card p-0 m-0">
            <CustomHeader label={isEditing ? "EDIT TENANT" : "ADD SUBSCRIPTION"} />
            <MDBCardBody id="cs-scroll">
              <form onSubmit={handleSubmit} className="card formHeight">
                <div className="row mt-4 card-border divMainSL">
                  <div className="row col-lg-12 justify-content-center pt-2 divPaddingHeader">
                    SUBSCRIPTION DETAILS
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                      <label htmlFor="subscription_type_name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Subscription Name"
                        value={formValues?.subscription_type_name || ""}
                        onChange={handleChange}
                        name="subscription_type_name"
                        autoComplete="off"
                      />
                      <div className="error-message-validation">
                        {formErrors.subscription_type_name}
                      </div>
                    </div>
                    <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                      <label htmlFor="plan">Plan</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Plan"
                        value={formValues?.plan || ""}
                        onChange={handleChange}
                        name="plan"
                        autoComplete="off"
                        maxLength="16"
                      />
                      <div className="error-message-validation">
                        {formErrors.plan}
                      </div>
                    </div>
                    <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                      <label htmlFor="amount">Amount</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Amount"
                        value={formValues?.amount || ""}
                        onChange={handleChange}
                        name="amount"
                        autoComplete="off"
                      />
                      <div className="error-message-validation">
                        {formErrors.amount}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fieldArea7 pt-2">
                  {/* <Link to="/all-tenants"> */}
                  <Link to="/subscriptions">
                    <button
                      className="btn  btn-sm"
                      id="cancleBtn"
                      type="reset"
                      value="Reset"
                      style={{ color: "#fff" }}
                    >
                      CANCEL
                    </button>
                  </Link>
                  <button
                    type="submit"
                    className="btn  btn-sm btn-primary"
                  >
                    {location.state === null ? "SUBMIT" : "SUBMIT"}
                  </button>
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </div>
  );
}

export default YTPAdminAddSubscription;
