import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";
import {
  AUDIT,
  AUDIT_SYSTEM_SUGGESTED,
  CHECK_AUDIT_ELIGIBILITY,
} from "../constants/ApiUrlConstants";

export const getViewAuditData = async (
  searchValue,
  pageNo,
  pageSize,
  empId,
  token
) => {
  return axios
    .get(
      service.baseUrl +
      AUDIT +
      "?emp_id=" +
      empId +
      "&page_no=" +
      pageNo +
      "&page_size=" +
      pageSize +
      (searchValue ? "&search_value=" + searchValue : ""),
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const pagination = async (pageNumber, pageCount, empId, token) => {
  return axios
    .get(
      service.baseUrl +
      AUDIT +
      "/Audit?page=" +
      pageNumber +
      "&records_per_page=" +
      pageCount +
      "&emp_id=" +
      empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const checkAuditEligibilityService = async (empId, tebId, token) => {
  return axios
    .get(
      service.baseUrl +
      CHECK_AUDIT_ELIGIBILITY +
      "?emp_id=" +
      empId +
      "&teb_id=" +
      tebId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const systemSuggestedTeb = async (empId, storageId, storageType, token) => {
  return axios
    .get(service.baseUrl + AUDIT_SYSTEM_SUGGESTED + "?emp_id=" + empId + "&storage_id=" + storageId + "&storage_type=" + storageType, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    });
};

export const editAuditDetails = async (payload, token) => {
  return axios
    .put(service.baseUrl + AUDIT, payload,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      })
    .then((response) => {
      return response;
    });
}
