import { MDBCard } from "mdbreact";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  handleSubmitBulkShipment,
  handleFormDataSubmit,
} from "../api/ShipmetServices";
import Section1 from "../components/BulkShipment/Section1";
import Section2 from "../components/BulkShipment/Section2";
import Section3 from "../components/BulkShipment/Section3";
import StorageTypeSelection from "../components/BulkShipment/StorageTypeSelection";
import { CustomAlert } from "../components/customAlert";
import CustomHeader from "../components/CustomHeader";
import CustomStepper from "../components/StepperComponent";
import "../style/bulkshipment.css";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { cognitoSignOut } from "../api/CognitoServices";
import { ConsoleError } from "../utils/ErrorUtils";
import { ERROR_MESSAGE } from "../constants/MessagesConstants";

const BulkShipment = (props) => {
  let navigate = useNavigate();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const steps = ["Section I", "Section II", "Section III"];
  const [activeStep, setActiveStep] = React.useState(0);
  //eslint-disable-next-line
  const [saveLoader, setsaveLoader] = useState(false);
  //eslint-disable-next-line
  const [fileData, setFileData] = useState();
  const [activityType, setActivityType] = useState("file_upload");

  const [formData, setFormData] = useState({
    step1: {
      fileuploafileuploadrowsdrows: [],
      filename: "",
      isEditInput: "",
    },
    step2: {
      startDate: "",
      estimatedArrivalDate: "",
      shippingNotes: "",
      from_storage_type: "",
      from_location: "",
      to_storage_type: "",
      to_location: "",
      isShowFromBranch: "",
      isShowFromVault: "",
      isFromWarehouse: "",
      fromStorageLocationType: "",
      fromSearchPanelLabel: "",
      fromSearchPanelPlaceholder: "",
      from_storage_type_name: "",
      to_storage_type_name: "",
    },
    step3: {
      pictureList: [],
      setDocumentList: [],
    },
  });
  let onChangeActivity = (value) => {
    let type = value.target.value;
    setActivityType(type);
    // setFormData();
    // renderActiveStepComponent()
    // handleChange()
    if (type === "file_upload") {
      setFormData();
    }
  };
  const handleChange = (step, key, value) => {
    const data = { ...formData };
    const selectedStep = { ...data[step] };
    selectedStep[key] = value;
    data[step] = selectedStep;
    setFormData(data);
  };

  const renderActiveStepComponent = () => {

    switch (activeStep) {
      case 0:
        switch (activityType) {
          case "file_upload":
            return <Section1 formData={formData} onChange={handleChange} />;
          case "storage_type":
            return (
              <StorageTypeSelection
                formData={formData}
                onChange={handleChange}
                activityType={setActivityType}
              />
            );
          default:
            break;
        }
        break;
      case 1:
        return <Section2 formData={formData} onChange={handleChange} />;
      case 2:
        return <Section3 formData={formData} onChange={handleChange} />;
      default:
        break;
    }
  };
  const isDisabled = () => {
    switch (activeStep) {
      case 0:
        switch (activityType) {
          case "file_upload":
            return formData?.step1.fileuploadrows?.length;
          case "storage_type":
            return formData?.step1.fileuploadrows?.length;
          default:
            break;
        }
        break;
      case 1:
        return (
          formData?.step2?.from_location?.length &&
          formData?.step2?.to_location?.length &&
          formData?.step2?.startDate?.length &&
          formData?.step2?.estimatedArrivalDate?.length &&
          formData?.step2?.authorizer_id?.length
        );
      default:
        return true;
    }
  };

  const handleSave = () => {
    setsaveLoader(true);

    // let docData = formData?.step3?.Documentrows;
    // docData = docData.map((docRowsData) => {
    //   return {
    //     documents: docRowsData.imgSource,
    //     description: docRowsData.ImgComments.toString(),
    //   };
    // });
    // let tamperbag = fileData.map((i) => i.tamperevidentbag_id);
    // let tamperbaglist = [...tamperbag];
    const dataSource = {
      shipment_file_data: formData?.step1?.fileuploadrows,
      shipment_data: {
        // tamperevidentbag_id: tamperbaglist,
        employee_id: currentUser?.employee_id,
        estimated_arrival_date: new Date(
          formData.step2.estimatedArrivalDate
        ).toISOString(),
        from_location: formData.step2.from_location,
        notes: formData.step2.shippingNotes
          ? formData.step2.shippingNotes
          : null,
        start_date: new Date(formData.step2.startDate).toISOString(),
        status: "Authorizer Approved",
        to_location: formData?.step2?.to_location,
        authorizer_id: formData?.step2?.authorizer_id,
        from_storage_type: formData?.step2?.from_storage_type,
        to_storage_type: formData?.step2?.to_storage_type,
      },
      documentList: formData?.step3?.documentList
        ? formData?.step3?.documentList
        : [],
      pictureList: formData?.step3?.pictureList
        ? formData?.step3?.pictureList
        : [],
    };
    Swal.fire({
      title: "Processing, please wait",
      html: "loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    handleSubmitBulkShipment(
      JSON.stringify(dataSource),
      currentUser?.employee_id,
      currentUser?.jwtToken
    )
      .then((response) => {
        Swal.close();
        setsaveLoader(false);
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((err) => {
              console.error(err);
            });
        } else if (response?.data.status === 201) {
          showSuccessAlert("Bulk Shipment Saved Successfully");
        } else if (response?.data.status === 412) {
          showFailAlert(
            "Bags Are Still Waiting For Pervious Shipment Approval "
          );
        } else {
          showFailAlert("Create Bulk Shipment Failed");
        }
      })
      .catch((error) => {
        ConsoleError(error);
        showFailAlert(ERROR_MESSAGE);
      });
  };
  const showSuccessAlert = (response) => {
    let title = "Saved";
    let text = response;
    let type = "success";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      target
    ).then((result) => {
      if (result.value) {
        navigate("/viewAllShipment");
      }
    });
  };
  const showFailAlert = (response) => {
    let title = "Failed";
    let text = response;
    let type = "error";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(title, text, type, confirmButtonText, showCancelButton, target);
  };

  const submit = () => {
    setsaveLoader(true);
    if (activeStep === 0 && activityType === "file_upload") {
      let fileuploadrowsdata = formData?.step1?.fileuploadrows;
      fileuploadrowsdata = fileuploadrowsdata.map((filedata) => {
        return filedata;
      });
      let obj = {
        shipment_data: fileuploadrowsdata,
        employee_name: currentUser?.employee_name,
        employee_id: currentUser?.employee_id,
        employee_designation: currentUser?.employee_designation,
      };
      handleFormDataSubmit(
        JSON.stringify(obj),
        currentUser?.employee_id,
        currentUser?.jwtToken
      ).then((response) => {
        setsaveLoader(false);
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((err) => {
              console.error(err);
            });
        } else if (
          response?.data.status === 412 &&
          response?.data.data_not_exists.length > 0
        ) {
          CustomAlert(
            "Incorrect Data",
            "Given Tamperbag doesn't exist at current location",
            "warning",
            "OK",
            false
          );
          setFileData(response?.data?.data_not_exists);
          formData.step1.fileuploadrows = response?.data?.data_not_exists;
          formData.step1.isEditInput = response?.data?.is_edit_input;
        } else if (
          response?.data.status === 412 &&
          response?.data.pending_bags.length > 0
        ) {
          CustomAlert(
            " CheckIn Pending",
            "Given Tamperbags are still waiting for checkin approval",
            "warning",
            "OK",
            false
          );
          formData.step1.isEditInput = true;
          formData.step1.isEditInput = response?.data?.is_edit_input;
        } else if (
          response?.data.status === 412 &&
          response?.data.shipment_exists.length > 0
        ) {
          CustomAlert(
            "Shimpent",
            "Duplicates Records are exists. Please Update",
            "warning",
            "OK",
            false
          );
          setFileData(response?.data?.shipment_exists);
          formData.step1.isEditInput = response?.data?.is_edit_input;
          formData.step1.fileuploadrows = response?.data?.shipment_exists;
        } else if (response?.status === 200) {
          setFileData(response?.data.data);

          setActiveStep((prev) => prev + 1);
        }
      });
    }
    if (activeStep === 0 && activityType === "storage_type") {
      setActiveStep((prev) => prev + 1);
    }
    if (activeStep === 1) {
      setActiveStep((prev) => prev + 1);
    }
  };
  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0">
        <div className="row flex p-4 ">
          <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden">
            <div className="row pt-1 pb-2">
              <div className="d-flex justify-content-end">
                <div className="p-0">
                  {currentUser?.shipment_save_record_access_flag && (
                    <Link to="/createShipment">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        id="bulk-checkin-button"
                      >
                        SINGLE SHIPMENT
                      </button>
                    </Link>
                  )}
                </div>
                <div className="p-0">
                  <Link to="/viewAllShipment">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      id="bulk-checkin-button"
                    >
                      SHIPMENT RECORDS
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <MDBCard className="card">
              <CustomHeader label={"BULK SHIPMENT"} />
              {activeStep < 1 ? (
                <div className="d-flex justify-content-center align-items-center  pt-3">
                  <div className="pr-5">
                    <input
                      type="radio"
                      value="file_upload"
                      name="radio"
                      className="cb-radio"
                      onChange={onChangeActivity}
                      checked={activityType === "file_upload"}
                    />
                    <label className="radio-button">File Upload</label>
                  </div>

                  <div>
                    <input
                      type="radio"
                      value="storage_type"
                      name="radio"
                      className="cb-radio"
                      onChange={onChangeActivity}
                      checked={activityType === "storage_type"}
                    />
                    <label className="radio-button">Storage Type</label>
                  </div>
                </div>
              ) : null}
              <div className="pt-2">
                <CustomStepper steps={steps} activeStep={activeStep} />
              </div>

              {renderActiveStepComponent()}

              <div className="fieldArea7 pt-2 pb-2 d-flex justify-content-center">
                {!activeStep ? (
                  <button
                    type="file"
                    className="btn  btn-sm btn-primary"
                    onClick={() => {
                      navigate("/viewAllShipment");
                    }}
                  >
                    CLOSE
                  </button>
                ) : (
                  <div>
                    <button
                      type="file"
                      className="btn  btn-sm btn-primary"
                      onClick={() => {
                        setActiveStep((prev) => prev - 1, formData);
                      }}
                    >
                      PREVIOUS
                    </button>
                  </div>
                )}
                {activeStep === 2 ? (
                  <button
                    type="file"
                    className="btn  btn-sm btn-primary"
                    disabled={!isDisabled()}
                    onClick={handleSave}
                  >
                    SUBMIT
                  </button>
                ) : (
                  <div></div>
                )}
                {activeStep < 2 ? (
                  <button
                    type="file"
                    className="btn  btn-sm btn-primary"
                    disabled={!isDisabled()}
                    onClick={submit}
                  >
                    NEXT
                  </button>
                ) : (
                  <div></div>
                )}
              </div>
            </MDBCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkShipment;
