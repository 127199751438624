import { MDBCard, MDBCardBody } from "mdbreact";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cognitoSignOut } from "../../api/CognitoServices";
import { getAllCheckinDataByStorageType } from "../../api/ShipmetServices";
import Pagination from "../../components/Pagination";
import "../../style/shipcontents.css";
import { ConsoleError } from "../../utils/ErrorUtils";
import CustomModal from "../CustomModal";

function StorageTypeSelection(props) {
  let navigate = useNavigate();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const [pages, setPages] = useState();
  const [currentPage, setCurrentPage] = useState();
  const initial = 0;
  const [rowCount, setRowCount] = useState(10);
  const [shipmentData, setShipmentData] = useState([]);
  const [filteredData] = useState("");
  const [saveLoader, setSaveLoader] = useState(false);
  const [noRecordsFound, setNoRecordsFound] = useState();
  const [currentEventData] = useState({
    EventType: "",
    EventData: "",
    Section: "StorageTypeSelection",
  });
  const [showMessage, setShowMessage] = useState(true);
  const [storageType, setStorageType] = useState("");
  const [storageLocation, setStorageLocation] = useState("");

  const [shouldRender, setShouldRender] = useState(false);

  const setRowsPage = (e) => {
    setRowCount(e);
    setSaveLoader(true);
    getAllCheckinDataByStorageType(
      storageType,
      storageLocation,
      1,
      e,
      currentUser?.employee_id,
      currentUser?.jwtToken
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          setSaveLoader(false);
          let count = response?.data?.pagination?.total_records;
          let c = Math.ceil(count / e);
          c = c === 0 ? 1 : c;
          setPages([...Array(c)].map((_, i) => i + 1));
          let data = response?.data?.check_in_list;
          setShipmentData(data);
          setCurrentPage(1);
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };

  const onPageClick = (item) => {
    setSaveLoader(true);
    getAllCheckinDataByStorageType(
      storageType,
      storageLocation,
      item,
      rowCount,
      currentUser?.employee_id,
      currentUser?.jwtToken
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          setSaveLoader(false);
          let data = response?.data?.check_in_list;
          setShipmentData(data);
          setCurrentPage(item);
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };
  const onNextClick = () => {
    setSaveLoader(true);
    getAllCheckinDataByStorageType(
      storageType,
      storageLocation,
      currentPage + 1,
      rowCount,
      currentUser?.employee_id,
      currentUser?.jwtToken
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          setSaveLoader(false);
          let data = response?.data?.check_in_list;
          setShipmentData(data);
          setCurrentPage(currentPage + 1);
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };
  const onPreviousNext = () => {
    setSaveLoader(true);
    getAllCheckinDataByStorageType(
      storageType,
      storageLocation,
      currentPage - 1,
      rowCount,
      currentUser?.employee_id,
      currentUser?.jwtToken
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          setSaveLoader(false);
          let data = response?.data?.check_in_list;
          setShipmentData(data);
          setCurrentPage(currentPage - 1);
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };
  const handleDelete = (itemId) => {
    const updatedItems = shipmentData.filter(
      (item) => item.tamper_evident_bag !== itemId
    );
    setShipmentData(updatedItems);
    let data1 = updatedItems.map((d) => {
      return {
        "BAG#": d.tamper_evident_bag.tamper_evident_bag_number,
        "SDB#_DRILLED": d.tamper_evident_bag.safe_deposit_box_number,
        CUSTOMER_NAME: d.tamper_evident_bag.owner_name,
        ORIGINAL_BRANCH_DID: d.tamper_evident_bag.branch_did,
      };
    });

    props.onChange("step1", "fileuploadrows", data1);
  };

  useEffect(() => {
    if (shouldRender) {
      const getShipmentRecords = async () => {
        setSaveLoader(true);
        getAllCheckinDataByStorageType(
          storageType,
          storageLocation,
          1,
          rowCount,
          currentUser?.employee_id,
          currentUser?.jwtToken
        )
          .then((response) => {
            if (response.status === 401) {
              cognitoSignOut()
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  ConsoleError(error);
                });
            } else if (response.status === 200) {
              setSaveLoader(false);
              let count = response?.data?.pagination?.total_records;
              if (response?.data.status === 204 || count < 1) {
                setNoRecordsFound("No records available for shipment");
              }
              let c = Math.ceil(count / rowCount);
              c = c === 0 ? 1 : c;
              setPages([...Array(c)].map((_, i) => i + 1));
              let data = response?.data?.check_in_list;
              setShipmentData(data);
              setCurrentPage(1);
              let data1 = data.map((d) => {
                return {
                  "BAG#": d.tamper_evident_bag.tamper_evident_bag_number,
                  "SDB#_DRILLED": d.tamper_evident_bag.safe_deposit_box_number,
                  CUSTOMER_NAME: d.tamper_evident_bag.owner_name,
                  ORIGINAL_BRANCH_DID: d.tamper_evident_bag.branch_did,
                };
              });

              props.onChange("step1", "fileuploadrows", data1);
            }
          })
          .catch((error) => {
            ConsoleError(error);
          });
      };
      getShipmentRecords();
      //eslint-disable-next-line
    }
    // eslint-disable-next-line
  }, [shouldRender]);

  useEffect(() => {
    setSaveLoader(true);
    setSaveLoader(false);
    setShipmentData(filteredData?.filterData);
    if (filteredData?.filterData?.length < 1) {
      setNoRecordsFound("No shipment records available");
    } else {
      setNoRecordsFound("");
    }
    //eslint-disable-next-line
  }, [filteredData]);

  return (
    <>
      <CustomModal
        currentEventData={currentEventData}
        show={showMessage}
        storageValue={setStorageType}
        location={setStorageLocation}
        onHide={() => props.activityType("file_upload")}
        onOkClick={() => {
          setShouldRender(true);
          setShowMessage(false);
        }}
      />

      <MDBCard className="h-md-100">
        <MDBCardBody>
          <div className="table-main">
            <table className="table safeChiefTable">
              <thead className="headerArea">
                <tr>
                  <th scope="col" className="tableHeading1">
                    TAMPER EVIDENT BAG #
                  </th>
                  <th scope="col" className="tableHeading">
                    SDB NUMBER
                  </th>
                  <th scope="col" className="tableHeading">
                    OWNER'S NAME
                  </th>
                  <th scope="col" className="tableHeading">
                    ORIGINAL BRANCH DID
                  </th>
                  <th scope="col" className="tableHeading">
                    ACTIONS{" "}
                  </th>
                </tr>
              </thead>

              <tbody id="cs-scroll">
                {saveLoader ? (
                  <div className="text-center py-5 alignself-center">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <>
                    {shipmentData !== null &&
                      shipmentData !== undefined &&
                      shipmentData.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            className="table-row"
                            // onClick={() =>
                            //   navigate("/shipmentHistory", { state: item })
                            // }
                          >
                            <td className="tableDetails1">
                              {
                                item?.tamper_evident_bag
                                  ?.tamper_evident_bag_number
                              }
                            </td>
                            <td className="tableDetails1">
                              {
                                item?.tamper_evident_bag
                                  ?.safe_deposit_box_number
                              }
                            </td>
                            <td className="tableDetails1">
                              {item?.tamper_evident_bag?.owner_name}
                            </td>
                            <td className="tableDetails1">
                              {item?.tamper_evident_bag?.branch_did}
                            </td>
                            <td className="tableDetails1">
                              <i
                                className="fa fa-2x fa-trash"
                                style={{ marginBottom: "-10px" }}
                                aria-hidden="true"
                                onClick={() => {
                                  handleDelete(item.tamper_evident_bag);
                                }}
                              ></i>
                            </td>
                          </tr>
                        );
                      })}
                    <div className="text-center py-5 alignself-center">
                      {noRecordsFound}
                    </div>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </MDBCardBody>
        <Pagination
          pages={pages}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousNext}
          onPageClick={onPageClick}
          initial={initial}
          setRowsPerPage={setRowsPage}
          currentPage={currentPage}
        />
      </MDBCard>
    </>
  );
}

export default StorageTypeSelection;
