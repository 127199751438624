import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomHeader from "../components/CustomHeader";
import { loader } from "../utils/loader";
import { validatePassword } from "../utils/PasswordValidation";
import { CustomAlert } from "../components/customAlert";

import "../style/signIn.css";

const PasswordChangeModal = ({
  isOpen,
  onClose,
  onChangePassword,
  name,
  setName,
}) => {
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
   

    

    const valid = validatePassword(newPassword);
    if (valid.length === 0) {
      setError("");
      await onChangePassword(newPassword);
      let title = "Password Update";
      let text = "Your password has been successfully updated.";
      let type = "success";
      let confirmButtonText = "OK";
      let showCancelButton = false;
      let cancelButtonText = "";
      let target = document.getElementById("swal-div");
      CustomAlert(
        title,
        text,
        type,
        confirmButtonText,
        showCancelButton,
        cancelButtonText,
        target
      )
      onClose();
      navigate("/");
    } else {
      setError(valid);
    }

    setLoading(false);
  };


  if (!isOpen) return null;
  return (
    <div className="password_change_modal">
      <div className="password_change_modal-content">
        {/* <CustomHeader label={"UPDATE PASSWORD"} /> */}
        <h2 className="password_change_h2">Welcome to SafeChief</h2>
        <p className="password_change_p">
          To make your account secure, please create a new password to replace
          the temporary password you were given in the email invitation.
        </p>
        <form
          onSubmit={handleSubmit}
          className="password_change_form_container"
        >
          <div
            className="error-container"
            style={{ visibility: error ? "visible" : "hidden" }}
          >
            <span className="error-icon">⚠️</span>
            <span className="error-text" style={{ textAlign: "left" }}>
              {error}
            </span>
          </div>
          <div className="password_change_form_group pb-4">
            <label
              htmlFor="email"
              className="login-label head-change2 d-flex justify-content-start  password_change_form"
            >
              Name
            </label>
            <input
              type="text"
              className="form-control login-input password_change_form_input"
              value={name}
              onChange={(e) => setName(e.target.value)} // Handle name input
              placeholder="Enter your name"
              required
            />
          </div>
          <div className="password_change_form_group">
            <label
              htmlFor="email"
              className="login-label head-change2 d-flex justify-content-start  password_change_form"
            >
              New Password
            </label>
            <input
              type="password"
              className="form-control login-input password_change_form_input"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                setError("");
              }}
              placeholder="Enter new password"
              required
            />
          </div>
          {loading ? (
            loader()
          ) : (
            <>
              <button type="submit" className="btn btn-primary login-2 p-2">
                Submit
              </button>
              <button
                type="button"
                className="btn btn-primary login-2 p-2"
                onClick={()=>{onClose();setNewPassword("");setName("")}}
              >
                Cancel
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default PasswordChangeModal;
