export const MAX_ACTION_DATE = 1;

export const MIN_ACTION_DATE = -6;

export const AUDIT_HISTORY_LIMIT = 20;

export const AUDIT_TEB_LIMIT = 20;

export const SAVE_AUDIT_REQUEST = {
  tamper_evident_bags: [""],
  audit_date: "",
  notes: "",
  action: "",
  pictures: [],
  documents: [],
  performed_by_id: "",
};

export const EDIT_FORM_VALUES = {
  owner_id: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  address_id:"",
  street:"",
  city:"",
  state:"",
  zip:""
}