import React, { useEffect, useState } from 'react';
import Search from "../components/Search";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { MDBCard, MDBCardBody } from "mdbreact";
import CustomHeader from "../components/CustomHeader";
import Spinner from "react-bootstrap/Spinner";
import { CustomAlert } from '../components/customAlert';
import { getRecord, updateRecord } from '../api/ForceOpenRecordsService';
import { getActionList } from '../api/ActionService';
import { getBranch } from '../api/ForceOpenRecordsService';
import { ConsoleError } from '../utils/ErrorUtils';

function EditPreOpen() {
    let navigate = useNavigate
    const { state } = useLocation();
    const currentUser = useSelector(
        (reduxData) => reduxData?.userReducer?.value?.userDetails
    );
    const [formData, setFormData] = useState()
    const [recordId, setRecordId] = useState()
    const [keys, setKeys] = useState([])
    const [boxNotDrilledReasonList, setBoxNotDrilledReasonList] = useState([]);
    const [drillTypeList, setDrillTypeList] = useState([]);
    const [branchDetails, setBranchDetails] = useState([]);

    const getCurrentRecord = async () => {
        getRecord(state, currentUser?.jwtToken).then(response => {
            setFormData(response?.data?.force_open_data)
            setRecordId(response?.data?.record_id)
            setKeys(Object.keys(response?.data?.force_open_data))
        })
    }

    useEffect(() => {
        fetchBoxNotDrilledReason()
        fetchBoxDrillType()
        fetchBranchDetails()
        getCurrentRecord()
    }, [state])


    async function fetchBoxNotDrilledReason() {
        getActionList("BoxNotDrilledReason", currentUser?.jwtToken)
            .then((response) => {
                setBoxNotDrilledReasonList(
                    response?.map(function (storageTypeRow) {
                        return storageTypeRow;
                    })
                );
            })
            .catch((error) => {
                ConsoleError(error);
            });
    }

    async function fetchBoxDrillType() {
        getActionList("BoxDrillType", currentUser?.jwtToken).then((response) => {
            setDrillTypeList(
                response.map(function (drillType) {
                    return drillType;
                })
            );
        }).catch((error) => {
            ConsoleError(error);
        });
    }

    async function fetchBranchDetails() {
        getBranch(currentUser?.jwtToken).then((response) => {
            if (response?.status === 200) {
                setBranchDetails(response?.data)
            }
        }).catch((error) => {
            ConsoleError(error);
        });
    }

    const handleChange = async (key, newValue) => {
        setFormData(prevData => {
            return {
                ...prevData,
                [key]: newValue
            };
        });
    }

    const onSubmit = async (id, payload) => {
        updateRecord(recordId, formData, currentUser?.jwtToken).then((response) => {
            if (response?.status === 200) {
                CustomAlert(
                    "Updated", "Force Open Records Updated Successfully", "success", "OK", false, document.getElementById("swal-div")
                ).then(() => {
                    navigate("/viewAllPreOpen");
                });
            } else {
                CustomAlert(
                    "Failed", "Failed Updating Record", "error", "OK", false, document.getElementById("swal-div")
                )
            }
        })
    }

    return (<>
        <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 body">
            <div className="row flex p-4">
                <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden">
                    <div className="row pt-1 pb-2">
                        <div className="d-flex justify-content-end">
                            <div className="p-0">
                                {currentUser?.check_in_save_bulk_upload_access_flag && (
                                    <Link to="/customBulkCheckin">
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                            id="bulk-checkin-button"
                                        >
                                            CUSTOM BULK CHECK-IN
                                        </button>
                                    </Link>
                                )}
                            </div>
                            <div className="p-0">
                                <Link to="/viewAllCheckIn">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        id="checkin-records-button"
                                    >
                                        CHECK-IN RECORDS
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <MDBCard className=" p-0 m-0 overflow-hidden">
                        <CustomHeader id="single" label={"EDIT RECORD"} />
                        <MDBCardBody id="single-scroll">
                            <div className="row col-lg-12 justify-content-center">
                                RECORD DETAILS
                            </div>
                            <div className="row pb-2 card-border">
                                {keys && keys.map((item, index) => {
                                    return (<div className="form-group col-md-6 col-xs-4  col-lg-4">
                                        {typeof formData[item] === typeof true ?
                                            <><input type='checkbox' id="legacy_checkbox" checked={formData[item]}
                                                onChange={(e) => {
                                                    handleChange(item, e.target.checked)
                                                }}
                                            /> <label htmlFor="storage_type">{item}</label></> : item === "drill_type" ? <>
                                                <label htmlFor="storage_type">{item}</label>
                                                <select
                                                    className="custom-select mr-sm-2 mb-2"
                                                    value={formData[item] ? formData[item] : ""}
                                                    onChange={(e) => {
                                                        handleChange(item, e.target.value)
                                                    }}
                                                >
                                                    <option value=''>Choose...</option>
                                                    {formData["box_drilled_status"] ? (
                                                        drillTypeList.map(item => {
                                                            return (<option value={item?.action}>{item?.action}</option>)
                                                        })
                                                    ) : (boxNotDrilledReasonList.map(item => {
                                                        return (<option value={item?.action}>{item?.action}</option>)
                                                    }))}
                                                </select>
                                            </> : <>
                                                <label htmlFor="storage_type">{item}</label>
                                                <input type='text' className="form-control" value={formData[item]}
                                                    onChange={(e) => {
                                                        handleChange(item, e.target.value)
                                                    }}
                                                />
                                            </>}
                                    </div>)
                                })}
                            </div>
                            <div className="fieldArea7 pt-2 pb-2 justify-content-center">
                                <button
                                    type="file"
                                    className="btn  btn-sm btn-primary"
                                // onClick={onClose}
                                >
                                    CANCEL
                                </button>
                                <button
                                    type="file"
                                    className="btn  btn-sm btn-primary"
                                    onClick={onSubmit}
                                >
                                    SUBMIT
                                </button>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </div>
            </div>
        </div>
    </>
    )
}

export default EditPreOpen