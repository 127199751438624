export const DISPLAY_DATE_TIME_FORMAT = "MM/DD/YYYY h:mm:ss A";
export const DISPLAY_DATE_FORMAT = "MM/DD/YYYY";
export const AUTHORIZATION_CONSTANT = [
  "ForceOpen",
  "CheckIn",
  "ShipContent",
  "CheckOut",
];
export const DATE_MODULE_CONSTANT = [
  { module: "Force Open", field: "Force Open Date" },
  { module: "Force Open", field: "Expiration of Contract" },
  { module: "Force Open", field: "Last Rental Payment" },
  { module: "Shipment", field: "Start Date" },
  { module: "Shipment", field: "End Date" },
  { module: "Audit", field: "Action Date" },
  { module: "Audit", field: "Audit Lock Period" },
  { module: "Enquiry", field: "Enquiry Date" },
];
export const MODULES = {
  FORCE_OPEN: "Force Open",
  AUDIT: "Audit",
  SHIPMENT: "Shipment",
  ENQUIRY: "Enquiry",
  CHECK_IN: "CheckIn",
  CHECK_OUT: "CheckOut",
};

export const FIELDS = {
  EXPIRATION_OF_CONTRACT: "Expiration of Contract",
  ACTION_DATE: "Action Date",
  START_DATE: "Start Date",
  LAST_RENTAL_PAYMENT: "Last Rental Payment",
  AUDIT_LOCK_PERIOD: "Audit Lock Period",
  END_DATE: "End Date",
  FORCE_OPEN_DATE: "Force Open Date",
  ENQUIRY_DATE: "Enquiry Date",
};
