import Spinner from "react-bootstrap/Spinner";

export const loader = () => {
  return (
    <div className="loader-container">
      <div className="spinner-border text-light" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export  const loaderDisplay = () =>{
    return(
      <div className="text-center py-5 alignself-center">
        <Spinner animation="border" />
      </div>
    )
  }
