import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Webcam from "react-webcam";
import {PrintDisable}  from "../utils/PrintDisable"
import { useSelector } from "react-redux";

const videoConstraints = {
  width: 400,
  height: 300,
  facingMode: "user",
};

export const WebcamCapture = (props) => {
  const [capturedImage, setCapturedImage] = useState(null);
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
  }, []);

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  PrintDisable(currentUser?.print_access_flag)
  return (
    <>
      <div className="d-block">
        <Modal
          show={props.isModelEnabled}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="d-flex justify-content-center rounded">
            {!capturedImage ? (
              <Webcam
                audio={false}
                height={350}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={450}
                videoConstraints={videoConstraints}
              />
            ) : (
              <img src={capturedImage} alt="Web cam capture" />
            )}
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            {!capturedImage ? (
              <button
                size="md"
                onClick={(e) => {
                  e.preventDefault();
                  capture();
                }}
                className="btn btn-primary btn-sm m-2"
                id="capture"
              >
                CAPTURE
              </button>
            ) : (
              <>
                <button
                  size="md"
                  id="save"
                  onClick={(e) => {
                    props.onCapturePicture(capturedImage, props.itemId,props?.addendumType);
                    setCapturedImage(null);
                  }}
                  className="btn btn-primary btn-sm m-2"
                >
                  SAVE
                </button>
                <button
                  size="md"
                  onClick={(e) => {
                    e.preventDefault();
                    setCapturedImage(null);
                  }}
                  className="btn btn-primary btn-sm m-2"
                >
                  RETAKE
                </button>
              </>
            )}
            <button
              size="md"
              className="btn btn-primary btn-sm m-2"
              onClick={() => {
                props.disableModal(false);
                setCapturedImage(null);
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        <div
          className="row overflow-auto d-flex justify-content-start pr-3"
          style={{ maxHeight: "400px" }}
        >
          {props?.pictureList?.map((picture, index) => (
            <div className="col-sm-4 col-md-3 col-lg-2 px-2" key={index}>
              <img
                src={picture.src}
                alt=""
                width="100%"
                height="100%"
                className="rounded d-block p-2 "
              />
              <i
                className="fa fa-2x fa-trash position-absolute top-0 end-0 pr-4 pt-2 h5"
                aria-hidden="true"
                onClick={(e) => {
                  props.onDeletePicture(index, picture.typeId,props?.currentAddendumType);
                }}
              ></i>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
