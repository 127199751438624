import React, { useEffect, useState } from "react";
import "../style/addnotes.css";
import { MDBCard, MDBCardHeader } from "mdbreact";
import user from "../assets/user.png";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import dayjs from "dayjs";
import {
  saveEnquiryNotes,
  getEnquiryNotesData,
} from "../api/CreateEnquiryService";
import { cognitoSignOut } from "../api/CognitoServices";
import { useNavigate } from "react-router-dom";
import { DISPLAY_DATE_TIME_FORMAT } from "../constants/Constants";
import { ConsoleError } from "../utils/ErrorUtils";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

function AddNotesModal(props) {
  let navigate = useNavigate();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const [notesData, setNotesData] = useState();
  const [loader, setLoader] = useState(true);
  const [show, setShow] = useState();
  const [comment, setComment] = useState();
  const [sendText, setSendText] = useState();
  const handleChange = (e) => {
    setShow(true);
    setComment(e.target.value);
  };

  const getNotesData = () => {
    getEnquiryNotesData(
      currentUser.employee_id,
      currentUser?.jwtToken,
      props.enquiriesId
    )
      .then((response) => {
        if (response?.data.status === 401) {
          setLoader(false);
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response?.data.status === 200) {
          setLoader(false);
          setNotesData(response?.data.data);
        } else if (response?.data.status === 204) {
          setLoader(false);
          setNotesData(null);
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };

  const closeModal = () => {
    setNotesData(null);
  };

  const handleSubmit = (e) => {
    setComment("");
    setSendText("sending...");
    e.preventDefault();
    if (comment !== null) {
      let commentData = {
        enquiries_id: props.enquiriesId,
        comment: comment,
        employee_id: currentUser?.employee_id,
      };

      saveEnquiryNotes(
        commentData,
        currentUser?.employee_id,
        currentUser?.jwtToken
      )
        .then((response) => {
          if (response.status === 401) {
            cognitoSignOut()
              .then(() => {
                navigate("/");
              })
              .catch((error) => {
                ConsoleError(error);
              });
          } else if (response?.data.status === 201) {
            setSendText(null);
            setLoader(true);
            getNotesData();
          } else if (response?.data.status === 400) {
            setLoader(false);
            alert("400");
          } else if (response?.data.status === 409) {
            setLoader(false);
            alert("409");
          } else {
            alert("error");
          }
        })
        .catch((error) => {
          ConsoleError(error);
          alert("error");
        });
    }
  };

  useEffect(
    () => {
      if (props.notesData !== undefined || props.notesData !== null) {
        setNotesData(props.notesData);
        if (notesData !== undefined) {
          setLoader(false);
        }
      }
    },
    //eslint-disable-next-line
    [props.notesData],
    [loader]
  );
  dayjs.extend(utc);
  dayjs.extend(tz);
  const localTimezone = dayjs.tz.guess();

  return (
    <div className="col-lg-6 col-md-12 col-sm-12 p-0 modal-divContent">
      <div className="d border border-5 child-divBorder h-100 wrapper ">
        <MDBCardHeader className="d-flex card mb-2 text-white justify-content-center form-heading text-center fixed-icon">
          <h6 className="p-0 m-0 form-heading">
            NOTES DETAILS{" "}
            <i
              className="fa fa-times fa-lg float-end"
              type="button"
              aria-hidden="true"
              onClick={() => {
                props.modal(false);
                closeModal();
              }}
            ></i>
          </h6>
        </MDBCardHeader>{" "}
        {loader ? (
          <div className="text-center py-5 mt-5 alignself-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <div style={{ scroll: "auto", color: "red" }}>
            {notesData !== null ? (
              notesData.map((item, index) => {
                return (
                  <div
                    className={
                      item.employee_id === currentUser?.employee_id
                        ? "row col-lg-10 col-md-10 col-sm-10 float-end pb-3 "
                        : "row col-lg-10 col-md-10 col-sm-10 float-start pb-3"
                    }
                  >
                    {item.employee_id === currentUser?.employee_id ? (
                      <>
                        <MDBCard className="row col-lg-9 col-md-9 col-sm-9 p-3 ">
                          {item.comment}
                        </MDBCard>
                        <div className="col-lg-3 col-md-3 col-sm-3 p-0 ml-4 d-flex align-items-center justify-content-center flex-column">
                          <div>
                            {" "}
                            <img
                              className="img-responsive "
                              src={user}
                              alt="logo"
                              width="30px"
                              height="30px"
                            ></img>
                          </div>

                          <div>{item.employee_name}</div>
                          <div className="date-class">
                            {dayjs(item.updated_time)
                              .tz(localTimezone)
                              .format(DISPLAY_DATE_TIME_FORMAT)}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-lg-3 col-md-3 col-sm-3 p-0  mr-4 d-flex align-items-center justify-content-center flex-column">
                          <div>
                            {" "}
                            <img
                              className="img-responsive "
                              src={user}
                              alt="logo"
                              width="30px"
                              height="30px"
                            ></img>
                          </div>

                          <div>{item.employee_name}</div>
                          <div className="date-class">
                            {dayjs(item.updated_time)
                              .tz(localTimezone)
                              .format(DISPLAY_DATE_TIME_FORMAT)}
                          </div>
                        </div>
                        <MDBCard className="row col-lg-9 col-md-9 col-sm-9 p-3">
                          {item.comment}
                        </MDBCard>
                      </>
                    )}
                  </div>
                );
              })
            ) : (
              <>
                <div className="text-center pt-5 mt-5 alignself-center">
                  {"No notes to display"}
                </div>
                <div className="text-center alignself-center">
                  {"Add new notes"}
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <div className="fixed-content col-lg-12 col-md-12 col-sm-12 p-0 mt-1 d-flex justify-content-center">
        <textarea
          placeholder="Enter Comments"
          rows="2"
          className="col-lg-12 col-md-12 col-sm-12 mt-4 pt-4 d-flex justify-content-center align-items-center text-area"
          onChange={handleChange}
          value={sendText !== null ? sendText : comment}
          id="text-area"
          // style={{ position: "sticky", bottom: 0 }}
        ></textarea>
        {show ? (
          <i
            className="fa fa-paper-plane sent-icon"
            type="button"
            aria-hidden="true"
            onClick={handleSubmit}
          ></i>
        ) : null}
      </div>
    </div>
  );
}

export default AddNotesModal;
