import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";
import { VIEW_HISTORY } from "../constants/ApiUrlConstants";
import { ConsoleError } from "../utils/ErrorUtils";

export const getHistory = async (
  searchType,
  tebId,
  pageNo,
  pageSize,
  token
) => {
  return axios
    .get(
      service.baseUrl +
        VIEW_HISTORY +
        "?search_type=" +
        searchType +
        "&tamper_evident_bag_id=" +
        tebId +
        "&page_no=" +
        pageNo +
        "&page_size=" +
        pageSize,
      {
        method: "GET",
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};

export const getPaginationHistory = async (Id, event, pageNo, empId, token) => {
  let params = {
    event: event,
    teb_id: Id,
  };
  return axios
    .post(
      service.baseUrl + VIEW_HISTORY + "?page=" + pageNo + "&emp_id=" + empId,
      params,
      {
        method: "POST",
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};
