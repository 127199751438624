import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MDBCard, MDBCardBody } from "mdbreact";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import CustomHeader from "../components/CustomHeader";
import Pagination from "../components/Pagination"; // Custom Pagination component
import { getSubscriptions, deleteSubscription } from "../api/YTPAdminServices";
import Swal from "sweetalert2";
import { CustomAlert } from "../components/customAlert";
import { cognitoSignOut } from "../api/CognitoServices";
import { ConsoleError } from "../utils/ErrorUtils";
import { ERROR_MESSAGE } from "../constants/MessagesConstants";

function YTPAdminSubscription() {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    rowsPerPage: 10, // Default rows per page
  });
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [saveLoader, setsaveLoader] = useState(false);
  const [noRecordsFound, setNoRecordsFound] = useState("");

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  useEffect(() => {
    fetchSubscriptionData(pagination.currentPage, pagination.rowsPerPage);
  }, [pagination.currentPage, pagination.rowsPerPage]);

  const fetchSubscriptionData = async (pageNo, pageSize) => {
    setsaveLoader(true);
    setNoRecordsFound(""); // Reset noRecordsFound state
    try {
      const response = await getSubscriptions(
        pageNo,
        pageSize,
        currentUser?.jwtToken
      );
      if (response && response.data) {
        setSubscriptionDetails(response.data.subscription_list || []);
        setPagination({
          ...pagination,
          totalPages: response.data.pagination.pages,
          currentPage: response.data.pagination.page,
        });
        if (response.data.subscription_list.length === 0) {
          setNoRecordsFound("No records found.");
        }
      }
    } catch (error) {
      console.error("Failed to fetch subscription data:", error);
      setNoRecordsFound("Failed to fetch data. Please try again.");
    } finally {
      setsaveLoader(false);
    }
  };

  const onClickDelete = (item) => {
    CustomAlert(
      "Warning",
      "Are you sure want to delete",
      "warning",
      "Yes",
      true,
      "No",
      document.getElementById("swal-div")
    ).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "Processing, please wait",
          html: "loading...",
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        deleteSubscription(item.subscription_type_id, currentUser?.jwtToken)
          .then((response) => {
            Swal.close();
            if (response.status === 401) {
              cognitoSignOut()
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  ConsoleError(error);
                });
            } else if (response.status === 204) {
              displayAlert(
                "Subscription deleted successfully",
                "success",
                "Deleted"
              );
              fetchSubscriptionData(
                pagination.currentPage,
                pagination.rowsPerPage
              );
            } else if (response?.status === 409) {
              displayAlert(response?.message, "warning", "Failed");
            } else if (response?.status === 400) {
              displayAlert(response?.error, "fail", "Failed");
            } else {
              displayAlert("Failed to delete subscription", "fail", "Failed");
            }
          })
          .catch((error) => {
            ConsoleError(error);
            Swal.close();
            displayAlert(ERROR_MESSAGE, "fail", "Failed");
          });
      }
    });
  };

  const displayAlert = (text, type, title) => {
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(title, text, type, confirmButtonText, showCancelButton, target);
  };

  const handlePageClick = (page) => {
    if (page >= 1 && page <= pagination.totalPages) {
      setPagination({ ...pagination, currentPage: page });
    }
  };

  const handleRowCountChange = (rowsPerPage) => {
    setPagination({ ...pagination, rowsPerPage, currentPage: 1 });
  };

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-auto p-0 body">
      <div className="row flex pl-4 pt-4 pr-4 pb-0">
        <div className="col-lg-12 pb-3 pl-0 pr-0">
          <div className="row pt-1 pb-2 mt-5">
            <div className="d-flex justify-content-end">
              <div className="p-0">
                <Link to="/add-subscription" state={{ mode: "addTenant" }}>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    id="checkin-records-button"
                  >
                    ADD SUBSCRIPTION TYPE
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <MDBCard className="card p-0 m-0">
            <CustomHeader label={"SUBSCRIPTIONS"} />
            <MDBCardBody id="cs-scroll">
              <div className="table-responsive" style={{height:"68vh"}}>
                <table className="table safeChiefTable">
                  <thead className="headerArea">
                    <tr>
                      <th scope="col" className="tableHeading1">
                        Subscription Name
                      </th>
                      <th scope="col" className="tableHeading">
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody id="cs-scroll">
                    {saveLoader ? (
                      <tr>
                        <td
                          colSpan="3"
                          className="text-center py-5 align-self-center"
                        >
                          <Spinner animation="border" />
                        </td>
                      </tr>
                    ) : subscriptionDetails.length > 0 ? (
                      subscriptionDetails.map((item) => (
                        <tr
                          key={item.subscription_type_id}
                          className="table-row"
                        >
                          <td className="tableDetails1">
                            {item?.subscription_type_name}
                          </td>
                          <td className="tableDetails">{item?.amount}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="3"
                          className="text-center py-5 align-self-center"
                        >
                          {noRecordsFound}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Custom Pagination */}
              <Pagination
                pages={[...Array(pagination.totalPages)].map((_, i) => i + 1)}
                onNextClick={() => handlePageClick(pagination.currentPage + 1)}
                onPreviousClick={() =>
                  handlePageClick(pagination.currentPage - 1)
                }
                onPageClick={handlePageClick}
                setRowsPerPage={handleRowCountChange}
                currentPage={pagination.currentPage}
              />
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </div>
  );
}

export default YTPAdminSubscription;
