import React, { useState, useEffect } from "react";
import { MDBCard, MDBCardBody } from "mdbreact";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import CustomHeader from "../components/CustomHeader";
import Pagination from "../components/Pagination";
import { useSelector } from "react-redux";
import { getTenants, updateTenantStatus } from "../api/YTPAdminServices";
import Swal from "sweetalert2";
import { CustomAlert } from "../components/customAlert";
import { ConsoleError } from "../utils/ErrorUtils";
import { ERROR_MESSAGE } from "../constants/MessagesConstants";

function YTPAdminHome() {
  const [tenantDetails, setTenantDetails] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    rowCount: 10,
    totalPages: 1,
  });
  const [saveLoader, setsaveLoader] = useState(false);
  const [noRecordsFound, setNoRecordsFound] = useState("");
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const fetchTenantData = async (
    searchValue = "",
    pageNo = 1,
    rowCount = 10
  ) => {
    setsaveLoader(true);
    try {
      const response = await getTenants(
        searchValue,
        pageNo,
        rowCount,
        currentUser?.jwtToken
      );
      if (response && response.data) {
        setTenantDetails(response.data.tenants);
        const { pages } = response.data;
        setPagination((prev) => ({
          ...prev,
          currentPage: pageNo,
          rowCount,
          totalPages: pages,
        }));
        setNoRecordsFound(
          response.data.tenants.length === 0 ? "No records found." : ""
        );
      }
    } catch (error) {
      ConsoleError(error);
      setNoRecordsFound(ERROR_MESSAGE);
    } finally {
      setsaveLoader(false);
    }
  };

  const displayAlert = (text, type, title) => {
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(title, text, type, confirmButtonText, showCancelButton, target);
  };

  useEffect(() => {
    fetchTenantData("", pagination.currentPage, pagination.rowCount);
  }, [pagination.currentPage, pagination.rowCount]);

  const handlePageClick = (page) => {
    if (page !== pagination.currentPage) {
      fetchTenantData("", page, pagination.rowCount);
    }
  };

  const handleRowCountChange = (rowCount) => {
    setPagination((prev) => ({
      ...prev,
      rowCount,
      currentPage: 1,
    }));
  };

  const onClickActivate = (item, action) => {
    const confirmMessage =
      action === "ACTIVATE"
        ? "Are you sure you want to activate this tenant?"
        : "Are you sure you want to deactivate this tenant?";

    CustomAlert(
      "Confirmation",
      confirmMessage,
      "question",
      "Yes",
      true,
      "No",
      document.getElementById("swal-div")
    ).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "Processing, please wait",
          html: "loading...",
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        updateTenantStatus(item.tenant_id, currentUser?.jwtToken, action)
          .then((response) => {
            Swal.close();
            if (response.status === 200) {
              const successMessage =
                action === "ACTIVATE"
                  ? "Tenant activated successfully"
                  : "Tenant deactivated successfully";
              displayAlert(successMessage, "success", action);

              fetchTenantData("", 1, 10);
            } else {
              const failureMessage =
                action === "ACTIVATE"
                  ? "Tenant activation failed"
                  : "Tenant deactivation failed";
              displayAlert(failureMessage, "fail", "Failed");
            }
          })
          .catch((error) => {
            ConsoleError(error);
            Swal.close();
            displayAlert(ERROR_MESSAGE, "fail", "Failed");
          });
      }
    });
  };

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-auto p-0 body">
      <div className="row flex pl-4 pt-4 pr-4 pb-0">
        <div className="col-lg-12 pb-3 pl-0 pr-0">
          <div className="row pt-1 pb-2 mt-5">
            <div className="d-flex justify-content-end">
              <div className="p-0">
                <Link to="/add-tenant" state={{ mode: "addTenant" }}>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    id="checkin-records-button"
                  >
                    ADD TENANT
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <MDBCard className="card">
            <CustomHeader label={"ALL TENANTS"} />
            <MDBCardBody id="cs-scroll">
              <div className="table-responsive" style={{ height: "68vh" }}>
                <table className="table safeChiefTable">
                  <thead className="headerArea">
                    <tr>
                      <th scope="col" className="tableHeading1">
                        Tenant Name
                      </th>
                      <th scope="col" className="tableHeading1">
                        Subscription Type
                      </th>
                      <th scope="col" className="tableHeading1">
                        Is Active
                      </th>
                      <th scope="col" className="tableHeading1">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody id="cs-scroll">
                    {saveLoader ? (
                      <div className="text-center py-5 alignself-center">
                        <Spinner animation="border" />
                      </div>
                    ) : tenantDetails?.length > 0 ? (
                      tenantDetails.map((item) => (
                        <tr key={item?.tenant_id} className="table-row">
                          <td
                            className="tableDetails"
                            style={{ paddingLeft: "4rem" }}
                          >
                            {item?.tenant_name}
                          </td>
                          <td
                            className="tableDetails"
                            style={{ paddingLeft: "4rem" }}
                          >
                            {item?.subscription?.subscription_type_name}
                          </td>
                          <td
                            className="tableDetails"
                            style={{ paddingLeft: "4rem" }}
                          >
                            {item?.is_active ? "Yes" : "No"}
                          </td>
                          <td
                            id="action-col"
                            className="tableDetails"
                            style={{ paddingLeft: "4rem" }}
                          >
                            <Link
                              to="/add-tenant"
                              state={{
                                mode: "editTenant",
                                tenantData: item,
                              }}
                            >
                              <button className="btn btn-primary btn-sm">
                                Edit
                              </button>
                            </Link>

                            {item?.is_active ? (
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  onClickActivate(item, "DEACTIVATE")
                                }
                              >
                                Deactivate
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  onClickActivate(item, "ACTIVATE")
                                }
                              >
                                Activate
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div className="text-center py-5 alignself-center">
                        {noRecordsFound}
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </MDBCardBody>
            <Pagination
              pages={[...Array(pagination.totalPages)].map((_, i) => i + 1)}
              onNextClick={() => handlePageClick(pagination.currentPage + 1)}
              onPreviousClick={() =>
                handlePageClick(pagination.currentPage - 1)
              }
              onPageClick={handlePageClick}
              setRowsPerPage={handleRowCountChange}
              currentPage={pagination.currentPage}
            />
          </MDBCard>
        </div>
      </div>
    </div>
  );
}

export default YTPAdminHome;
