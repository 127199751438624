export const formatFieldName = (fieldName) => {
    // formating the field_name as Title case
    let formattedFieldName = fieldName.replaceAll("_", " "); // Replace underscores with spaces
    if (formattedFieldName.toLowerCase() === "scra") {
      formattedFieldName = "Service Member";
    }
    formattedFieldName = formattedFieldName
      .split(" ")
      .map((word) => {
        if (word.toLowerCase() === "owner") {
          return "";
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");

    return formattedFieldName.trim(); // Remove leading/trailing whitespace
  };