import React from "react";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import "../../style/preview.css";
import { FORCE_OPEN_ABANDON_PROP_EMAIL_MESSAGE } from "../../constants/MessagesConstants";
import { useSelector } from "react-redux";
import { PrintDisable } from "../../utils/PrintDisable";

const Preview = (props) => {
  const componentRef = useRef();
  const forceOpenData = useSelector(
    (reduxData) => reduxData?.forceOpenDataReducer?.value?.forceOpenData
  );
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  PrintDisable(currentUser?.print_access_flag);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // Do changes in preview screen need to be updated back to force Open form when they click back
  return (
    <>
      <div className="preview">
        <div className="container">
          <div className="row justify-content-center fw-bold">
            SAFECHIEF
          </div>
          <div className="row justify-content-center fw-bold">
            FORCE OPEN INFORMATION
          </div>
          <div className="row">
            <div className="">
              <div ref={componentRef}>
                <div className="row justify-content-center pt-3">SECTION I</div>
                <div className="row justify-content-center pt-3">
                  BOX DETAILS
                </div>
                <div className="row card-border">
                  <div className="row">
                    <div className="form-check col-4 px-4">
                      <input
                        rules={{ required: true }}
                        type="checkbox"
                        disabled
                        checked={forceOpenData?.section1?.contract_on_file}
                      />
                      <label className="form-check-label pl-3">
                        No Contract On File
                      </label>
                    </div>
                    <div className="form-check col-4 px-4">
                      <input
                        type="checkbox"
                        disabled
                        checked={forceOpenData?.section1?.access_log}
                      />
                      <label className="form-check-label pl-3">
                        No Access Log On File
                      </label>
                    </div>
                    <div className="form-check col-4 px-4">
                      <input
                        type="checkbox"
                        disabled
                        checked={forceOpenData?.section1?.legacy}
                      />
                      <label className="form-check-label pl-3">Legacy</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 px-4">
                      <label className="pr-2">Branch Name</label>
                      <input
                        disabled
                        type="text"
                        value={forceOpenData?.section1?.branch_name}
                      />
                    </div>

                    <div className="col-4 px-4">
                      <label className="pr-2">Branch DID</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.branchDID}
                      />
                    </div>

                    <div className="col-4 px-4">
                      <label className="pr-2">Date</label>
                      <input
                        type="date"
                        disabled
                        value={forceOpenData?.section1?.force_open_date}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 px-4">
                      <label className="pr-2">Safe Deposit Box #</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.safe_deposit_box_number}
                      />
                    </div>
                    <div className="col-4 px-4">
                      <label className="pr-2">Rental fee Due</label>
                      <input
                        type="number"
                        min={0}
                        disabled
                        value={forceOpenData?.section1?.rental_due}
                      />
                    </div>
                    <div className="col-4 px-4">
                      <label className="pr-2">Other Fee Due</label>
                      <input
                        type="number"
                        min={0}
                        disabled
                        value={forceOpenData?.section1?.other_due}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 px-4">
                      <label className="pr-2">Total Due</label>
                      <input
                        type="number"
                        disabled
                        value={forceOpenData?.section1?.total_due}
                      />
                    </div>
                    <div className="col-4 px-4">
                      <label className="pr-2">Expiration of Contract</label>
                      <input
                        type="date"
                        disabled
                        value={forceOpenData?.section1?.contract_expiration}
                      />
                    </div>
                    <div className="col-4 px-4">
                      <label className="pr-2">
                        Date of Last Rental Payment
                      </label>
                      <input
                        type="date"
                        disabled
                        value={forceOpenData?.section1?.last_rental_payment}
                        placeholder="mm/dd/yyyy"
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">OWNERS INFO</div>
                <div className="row card-border">
                  <div className="row mt-2">
                    <div className="col-4 px-4">
                      <label className="pr-2">First Name</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.owner?.first_name}
                      />
                    </div>
                    <div className="col-4 px-4">
                      <label className="pr-2">Middle Name</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.owner?.middle_name}
                      />
                    </div>
                    <div className="col-4 px-4">
                      <label className="pr-2">Last Name</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.owner?.last_name}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 px-4">
                      <label className="pr-2">SSN</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.owner?.ssn}
                      />
                    </div>
                    <div className="col-4 px-4">
                      <label className="pr-2">Street</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.owner?.street_1}
                      />
                    </div>

                    <div className="col-4 px-4">
                      <label className="pr-2">City</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.owner?.city}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 px-4">
                      <label className="pr-2">State</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.owner?.state}
                      />
                    </div>
                    <div className="col-4 px-4">
                      <label className="pr-2">Zip</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.owner?.zipcode}
                      />
                    </div>
                    <div className="col-4 px-4">
                      <label className="pr-2">Relation Code</label>
                      <input
                        type="text"
                        disabled
                        value={
                          forceOpenData?.section1?.owner?.owner_relation_code
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 px-4">
                      <label className="pr-2">Property Type Code</label>
                      <input
                        type="text"
                        disabled
                        value={
                          forceOpenData?.section1?.owner
                            ?.owner_property_type_code
                        }
                      />
                    </div>
                    <div className="col-4 px-4">
                      <label className="pr-2">Dormancy Id</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.owner?.dormancy_id}
                      />
                    </div>
                    <div className="col-4 px-4">
                      <label className="pr-2">Company Name</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.owner?.company_name}
                      />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">CO-OWNERS INFO</div>
                <div className="row card-border">
                  <div className="row">
                    <div className="col-4 px-4">
                      <label className="pr-2">First Name</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.co_owner?.first_name}
                      />
                    </div>
                    <div className="col-4 px-4">
                      <label className="pr-2">Middle Name</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.co_owner?.middle_name}
                      />
                    </div>
                    <div className="col-4 px-4">
                      <label className="pr-2">Last Name</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.co_owner?.last_name}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 px-4">
                      <label className="pr-2">SSN</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.co_owner?.ssn}
                      />
                    </div>
                    <div className="col-4 px-4">
                      <label className="pr-2">Street</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.co_owner?.street_1}
                      />
                    </div>

                    <div className="col-4 px-4">
                      <label className="pr-2">City</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.co_owner?.city}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 px-4">
                      <label className="pr-2">State</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.co_owner?.state}
                      />
                    </div>
                    <div className="col-4 px-4">
                      <label className="pr-2">Zip</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.co_owner?.zipcode}
                      />
                    </div>
                  </div>
                </div>

                <div className="row justify-content-center">SECTION II</div>
                <div className="card-border">
                  <div className="col-lg-12 mt-4">
                    <label>BOX : </label>
                    <input
                      className="ml-2"
                      type="radio"
                      name="content"
                      disabled
                      value={forceOpenData?.section2?.box_drilled_status}
                      checked={forceOpenData?.section2?.box_drilled_status}
                    />
                    <span className="col-lg-4">Drilled</span>

                    <input
                      type="radio"
                      name="content"
                      disabled
                      value={!forceOpenData?.section2?.box_drilled_status}
                      checked={!forceOpenData?.section2?.box_drilled_status}
                    />
                    <span className="col-lg-4">Not Drilled</span>
                    {forceOpenData?.section2?.box_drilled_status ? (
                      <div>
                        <div className="col-lg-8 mt-2">
                          <label>Contents : </label>
                          <input
                            className="ml-2"
                            type="radio"
                            name="content2"
                            disabled
                            checked={forceOpenData?.section2?.contents_found}
                          />
                          <span className="col-lg-4">Found</span>
                          <input
                            type="radio"
                            name="content2"
                            value="Not Found"
                            disabled
                            checked={!forceOpenData?.section2?.contents_found}
                          />
                          <span className="col-lg-4">Not Found</span>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className=" mt-2">
                          <label>Reason : </label>

                          <div className="row mt-2">
                            <div className="col-lg-6 col-md-8 col-sm-6 mt-2">
                              <span className="col-lg-4">
                                {
                                  forceOpenData?.section2
                                    ?.box_not_drilled_reason_name
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                        {forceOpenData?.section2
                          ?.box_not_drilled_reason_other && (
                          <div className="col-4 px-4 mt-4">
                            <input
                              type="text"
                              value={
                                forceOpenData?.section2
                                  ?.box_not_drilled_reason_other
                              }
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-12 mt-4">
                    <div className="col-4 px-0">
                      <label className="pr-2">Amount Reported</label>
                      <input
                        type="number"
                        min={0}
                        disabled
                        value={forceOpenData?.section2?.amount_reported}
                      />
                    </div>
                  </div>
                </div>

                <div className="row justify-content-center">SECTION III</div>

                <div className="flex text-left card-border">
                  <p>
                    On
                    <input
                      type="text"
                      className="pl-2"
                      value={forceOpenData?.section1?.force_open_date}
                    />
                    appeared{" "}
                    <input
                      type="text"
                      value={currentUser?.employee_name}
                      placeholder="Employee's Name"
                    />
                    and
                  </p>

                  <p>
                    <input
                      type="text"
                      value={forceOpenData?.section3?.emplyeeName2}
                      disabled
                      placeholder="Employee's Name(Lousiana only)"
                    />
                    and the unsigned notary public of
                    <input
                      className="ml-1"
                      type="text"
                      value={forceOpenData?.section1?.branch_name}
                      placeholder="Address of Branch"
                    />
                    for the force open of the Safe Deposite Box #
                  </p>
                  <p>
                    <input
                      type="text"
                      disabled
                      value={forceOpenData?.section1?.safe_deposit_box_number}
                      placeholder="SDB#"
                    />
                    to gain entrence therein. Said box was forced open in the
                    presence of the anmed employees above and the said notary of
                    public
                  </p>

                  <p>
                    <input
                      type="text"
                      value={forceOpenData?.section1?.notary_name}
                      placeholder="Notary Name"
                    />
                    the contents thereof were inventoried herein and said
                    witness declared under oath, that the items shown and
                  </p>
                  <p>
                    {" "}
                    listed herein above were the only contents contained in the
                    said box.
                  </p>
                  <div className="row">
                    <div className="col-4 px-4">
                      <label className="pr-2">
                        Witness Signature (Office or designated employee){" "}
                      </label>
                      <input type="text" disabled />
                    </div>

                    <div className="col-4 px-4">
                      <label className="pr-2">Notary Public Signature</label>
                      <input type="text" disabled />
                    </div>
                    <div className="col-4 px-4">
                      <label className="pr-2">Locksmith Name</label>
                      <input
                        type="text"
                        disabled
                        value={forceOpenData?.section1?.locksmith_name}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 px-4">
                      <label className="pr-2">
                        Witness Signature (Lousiana only)
                      </label>
                      <input type="text" disabled />
                    </div>
                    <div className="col-4 px-4">
                      <label className="pr-2 form-group ">Notary</label>
                      <input type="text" disabled />
                    </div>
                    <div className="col-4 px-4">
                      <p> Seal:</p>
                    </div>
                  </div>
                </div>

                <div className="row justify-content-center" id="section4">
                  SECTION IV
                </div>
                <div className="text-left card-border">
                  <p>
                    {" "}
                    In the event a customer retrieves their forced open
                    contents, complete the Release of Contents below and record
                    the fees collected.
                  </p>
                  <div className="row ml-3">
                    <div className="col-4 px-4">
                      <b>Past Due Rental Fee:&nbsp; $</b>
                      <input
                        type="number"
                        min={0}
                        disabled
                        value={forceOpenData?.section1?.past_due_rental_fee}
                      />
                    </div>
                    <div className="col-4 px-4">
                      <b>Force Open Cost: &nbsp; $</b>
                      <input
                        type="number"
                        value={forceOpenData?.section1?.force_open_cost}
                        disabled
                      />
                    </div>
                    <div className="col-4 px-4">
                      <b>Inventory Fee:&nbsp; $</b>
                      <input
                        type="number"
                        value={forceOpenData?.section1?.inventory_fee}
                        disabled
                      />
                    </div>

                    <div className="col-4 px-4">
                      <b>Total Fee:&nbsp; $</b>
                      <input
                        type="number"
                        disabled
                        value={forceOpenData?.section1?.total_fee}
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <p>
                      <b>{FORCE_OPEN_ABANDON_PROP_EMAIL_MESSAGE}</b>
                    </p>
                  </div>
                  <div className="text-center">
                    <p>
                      <b>RELEASE OF CONTENTS</b>
                    </p>
                  </div>
                  <p>
                    I do hereby acknowledge that the list of the contents above
                    is a complete list of all property stored in said Safe
                    Deposit Box and I hereby relieve and release Capital One
                    bank, N.A. from all liability for said property and
                    contents.
                  </p>
                  <div className="row ml-4">
                    <div className="form-group col-md-4 px-4">
                      <label className="pr-2">Renter ID</label>
                      <input
                        type="text"
                        placeholder="Renter ID"
                        value={forceOpenData?.section4?.renterID}
                      />
                    </div>
                    <div className="form-group col-md-4 px-4">
                      <label className="pr-2">Renter's Name</label>
                      <input
                        type="text"
                        placeholder="Renter Name"
                        value={forceOpenData?.section1?.owner?.name}
                      />
                    </div>
                    <div className="form-group col-md-4 px-4">
                      <label className="pr-2">Renter Signature</label>
                      <input
                        type="number"
                        min={0}
                        value={forceOpenData?.section4?.renterSignature}
                      />
                    </div>
                  </div>
                  <div className="row ml-4">
                    <div className="form-group col-md-4 px-4">
                      <label className="pr-2">Date</label>
                      <input type="text" disabled />
                    </div>
                    <div className="form-group col-md-4 px-4"></div>
                    <div className="form-group col-md-4 px-4">
                      <label className="pr-2">Witness Signature</label>
                      <input
                        type="number"
                        value={forceOpenData?.section4?.witnessSignature}
                      />
                    </div>
                  </div>
                </div>

                {forceOpenData?.addendum_a.length > 0 && (
                  <>
                    <div className="row justify-content-center">ADDENDUM A</div>
                    <div className="card-border">
                      <div className="col-lg-12 mt-4">
                        <div className="row">
                          <div className="col-3 fw-bold">
                            Tamper Evident Bag #
                          </div>
                          <div className="col-3 fw-bold">Category</div>
                          <div className="col-3 fw-bold">Item</div>
                          <div className="col-3 fw-bold">Quantity</div>
                        </div>

                        {forceOpenData?.addendum_a?.map((addendum) => (
                          <div className="row">
                            <div className="col-3 ">
                              {addendum?.tamper_evident_bag_number}
                            </div>
                            <div className="col-3">
                              {addendum?.addendum_category_name}
                            </div>
                            <div className="col-3">
                              {addendum?.addendum_item_name}
                            </div>
                            <div className="col-3">{addendum?.quantity}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}

                {forceOpenData?.addendum_b.length > 0 && (
                  <>
                    <div className="row justify-content-center">ADDENDUM B</div>
                    <div className="card-border">
                      <div className="col-lg-12 mt-4">
                        <div className="row">
                          <div className="col-3 fw-bold">
                            Tamper Evident Bag #
                          </div>
                          <div className="col-3 fw-bold">Category</div>
                          <div className="col-3 fw-bold">Item</div>
                          <div className="col-3 fw-bold">Quantity</div>
                        </div>

                        {forceOpenData?.addendum_b?.map((addendum) => (
                          <div className="row">
                            <div className="col-3 ">
                              {addendum?.tamper_evident_bag_number}
                            </div>
                            <div className="col-3">
                              {addendum?.addendum_category_name}
                            </div>
                            <div className="col-3">
                              {addendum?.addendum_item_name}
                            </div>
                            <div className="col-3">{addendum?.quantity}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}

                {forceOpenData?.addendum_c.length > 0 && (
                  <>
                    <div className="row justify-content-center">ADDENDUM C</div>
                    <div className="card-border">
                      <div className="col-lg-12 mt-4">
                        {forceOpenData?.addendum_c
                          ?.filter(
                            (data) => data?.addendum_item_type === "ContraBand"
                          )
                          ?.map((addendum) => (
                            <>
                              <div className="row">
                                <div className="col-1 fw-bold">
                                  Louisiana Bond
                                </div>
                                <div className="col-3 fw-bold">
                                  Tamper Evident Bag #
                                </div>
                                <div className="col-3 fw-bold">Contraband</div>
                                <div className="col-3 fw-bold">Quantity</div>
                              </div>

                              <div className="row">
                                <div className="col-1">
                                  {addendum?.louisiana_bond.toString()}
                                </div>
                                <div className="col-3 ml-4">
                                  {addendum?.tamper_evident_bag_number}
                                </div>
                                <div className="col-3">
                                  {" "}
                                  {addendum?.addendum_item_type}
                                </div>
                                <div className="col-3">
                                  {addendum?.quantity}
                                </div>
                              </div>
                            </>
                          ))}
                        {forceOpenData?.addendum_c
                          ?.filter(
                            (data) => data?.addendum_item_type === "Stock"
                          )
                          ?.map((addendum) => (
                            <>
                              <div className="row pt-2">
                                <div className="col-1 fw-bold">
                                  Louisiana Bond
                                </div>
                                <div className="col-3 fw-bold">
                                  Tamper Evident Bag #
                                </div>
                                <div className="col-3 fw-bold">
                                  Stock Certificate
                                </div>
                                <div className="col-2 fw-bold">Certificate</div>
                                <div className="col-1 fw-bold"># Of Shares</div>
                                <div className="col-1 fw-bold">Quantity</div>
                              </div>

                              <div className="row">
                                <div className="col-1">
                                  {addendum?.louisiana_bond.toString()}
                                </div>
                                <div className="col-3 ml-4">
                                  {addendum?.tamper_evident_bag_number}
                                </div>
                                <div className="col-3 "> {addendum?.title}</div>
                                <div className="col-2 ">
                                  {" "}
                                  {addendum?.certificate}
                                </div>
                                <div className="col-1 ">
                                  {" "}
                                  {addendum?.number_of_shares}
                                </div>
                                <div className="col-1 ">
                                  {addendum?.quantity}
                                </div>
                              </div>
                            </>
                          ))}
                        {forceOpenData?.addendum_c
                          ?.filter(
                            (data) => data?.addendum_item_type === "OtherBond"
                          )
                          ?.map((addendum) => (
                            <>
                              <div className="row pt-2">
                                <div className="col-1 fw-bold">
                                  Louisiana Bond
                                </div>
                                <div className="col-3 fw-bold">
                                  Tamper Evident Bag #
                                </div>
                                <div className="col-3 fw-bold">Other Bonds</div>
                                <div className="col-1 fw-bold">
                                  Value Of Bond
                                </div>
                                <div className="col-2 fw-bold">
                                  Description Of Bonds
                                </div>
                                <div className="col-1 fw-bold">Quantity</div>
                              </div>

                              <div className="row">
                                <div className="col-1 ">
                                  {addendum?.louisiana_bond.toString()}
                                </div>
                                <div className="col-3 ml-4">
                                  {addendum?.tamper_evident_bag_number}
                                </div>
                                <div className="col-3"> {addendum?.title}</div>
                                <div className="col-1">
                                  {" "}
                                  {addendum?.value_of_bond}
                                </div>
                                <div className="col-2">
                                  {" "}
                                  {addendum?.description}
                                </div>
                                <div className="col-1">
                                  {addendum?.quantity}
                                </div>
                              </div>
                            </>
                          ))}
                        {forceOpenData?.addendum_c
                          ?.filter(
                            (data) =>
                              data?.addendum_item_type === "Miscellaneous"
                          )
                          ?.map((addendum) => (
                            <>
                              <div className="row pt-2">
                                <div className="col-1 fw-bold">
                                  Louisiana Bond
                                </div>
                                <div className="col-3 fw-bold">
                                  Tamper Evident Bag #
                                </div>
                                <div className="col-3 fw-bold">
                                  Miscellaneous
                                </div>
                                <div className="col-2 fw-bold">Quantity</div>
                              </div>

                              <div className="row">
                                <div className="col-1">
                                  {addendum?.louisiana_bond.toString()}
                                </div>
                                <div className="col-3 ml-4">
                                  {addendum?.tamper_evident_bag_number}
                                </div>
                                <div className="col-3"> {addendum?.title}</div>
                                <div className="col-2">
                                  {addendum?.quantity}
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    </div>
                  </>
                )}

                <div className="row justify-content-center">AUTHORIZER</div>
                <div className="card-border">
                  <div className="col-lg-12 mt-4">
                    <label className="pr-2">Authorizer Assigned</label>
                    <input
                      disabled
                      type="text"
                      value={forceOpenData?.authorizer_name}
                    />
                  </div>
                </div>
              </div>
              <button onClick={handlePrint} className="print_button">
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Preview;
