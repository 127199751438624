import Documents from "../../components/Documents";
import "../../style/BulkCheckin.css";
import "../../style/Sectionthree.css";

const StepThree = (props) => {
  const formData = props.formData;
  const onChange = props.onChange;
  return (
    <>
      <div className="divContent">
        <div className="d border divMainBorder h-100">
          <div className="row col-lg-12 justify-content-center pt-3 pb-3">
            DOCUMENTS
          </div>
          <div
            style={{
              height: "480px",
              overflow: "hidden auto",
              scrollbar: {
                width: "6px",
                backgroundColor: "red",
              },
            }}
          >
            <Documents formData={formData} onChange={onChange} />
          </div>
        </div>
      </div>
    </>
  );
};

export default StepThree;
