import { MDBCard, MDBCardBody } from "mdbreact";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { getCheckInData } from "../api/CheckInServices";
import CustomHeader from "../components/CustomHeader";
import Pagination from "../components/Pagination";
import Search from "../components/Search";
import "../style/checkinrecords.css";
import { useSelector } from "react-redux";
import { cognitoSignOut } from "../api/CognitoServices";
import { ConsoleError } from "../utils/ErrorUtils";
import { PrintDisable } from "../utils/PrintDisable";
import useFetchNotifications from "../utils/NotificationUtils";
import { loaderDisplay } from "../utils/loader";


function ViewAllCheckIn() {
  let navigate = useNavigate();

  // State management
  const [pagination, setPagination] = useState({
    currentPage: 1,
    rowCount: 10,
    searchValue: "", // Moved searchValue inside pagination
  });

  const [pages, setPages] = useState([]); // Pages array
  const [status, setStatus] = useState({
    isLoading: false,
    noRecordsMessage: "",
  });

  const [checkInRecords, setCheckInRecords] = useState([]);
  const [reloader, setReloader] = useState(1);

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  PrintDisable(currentUser?.print_access_flag);
  const notificationResponse = useFetchNotifications(currentUser?.employee_id, currentUser?.jwtToken);

  // Fetch check-in data
  const fetchCheckInData = async (page = pagination.currentPage, rowCount = pagination.rowCount, search = pagination.searchValue) => {
    setStatus((prev) => ({ ...prev, isLoading: true }));

    try {
      const response = await getCheckInData(
        page,
        rowCount,
        currentUser?.employee_id,
        currentUser?.jwtToken,
        search
      );

      if (response.status === 401) {
        cognitoSignOut().then(() => {
          navigate("/");
        });
        return;
      }

      if (response.status === 200) {
        const data = response?.data?.check_in_list || [];
        const { total, pages } = response?.data?.pagination;

        setCheckInRecords(data);
        setPages([...Array(pages)].map((_, i) => i + 1)); // Create array of page numbers
        setPagination((prev) => ({ ...prev, currentPage: page }));

        setStatus((prev) => ({
          ...prev,
          isLoading: false,
          noRecordsMessage: data.length < 1 ? "No check-in records available" : "",
        }));
      }
    } catch (error) {
      ConsoleError(error);
      setStatus((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const handleRowCountChange = (rowCount) => {
    setPagination((prev) => ({ ...prev, rowCount }));
    fetchCheckInData(1, rowCount);
  };

  const handlePageClick = (page) => {
    if (page !== pagination.currentPage) {
      fetchCheckInData(page, pagination.rowCount);
    }
  };

  const handleSearchData = (data) => {
    const {check_in_list, pagination: newPagination, searchValue = "" } = data || {};
  
    // Handle case when searchValue is empty
    if (searchValue.trim().length === 0) {
      setPagination((prev) => ({
        ...prev,
        currentPage: (newPagination?.page || 1),
        searchValue: "", // Clear searchValue
      }));
      fetchCheckInData(1, pagination.rowCount, ""); // Fetch all data
      return;
    }
  setCheckInRecords(check_in_list);
    // Handle case when searchValue is provided
    setPages([...Array(newPagination?.pages || 1)].map((_, i) => i + 1));
  
    setPagination((prev) => ({
      ...prev,
      currentPage: (newPagination?.page || 1),
      searchValue: searchValue, // Update searchValue
    }));
  
    setStatus((prev) => ({
      ...prev,
      noRecordsMessage: data?.check_in_list?.length < 1 ? "No check-in records available" : "",
    }));
  
  };
  

  useEffect(() => {
    fetchCheckInData();
  }, [pagination.rowCount, pagination.searchValue]);

  useEffect(() => {
    fetchCheckInData();
  }, [reloader]);

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 overflow-hidden body pt-5">
      <div className="row flex p-4">
        <div className="col-lg-12 pb-3 pl-0 pr-0 ">
          <div className="row pt-2">
            <div className="col-lg-6 col-md-10">
              <Search
                filterData={handleSearchData}
                pageSize={pagination.rowCount}
                screen="Checkin"
              />
            </div>

            <div className="col-lg-1 col-md-6 col-sm-6 pl-0 pr-0 ">
              <button
                className="btn btn-primary btn-sm col-lg-10 col-md-10 col-sm-11 m-0 pl-0 pr-0 float-end"
                onClick={() => setReloader(Math.random())}
              >
                Refresh
              </button>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 pl-0 pr-0 ">
              {currentUser?.check_in_save_bulk_upload_access_flag && (
                <Link to="/customBulkCheckin">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm col-lg-11 col-md-11 col-sm-11 m-0 float-end"
                    id="bulk-checkin-button"
                  >
                    CUSTOM BULK CHECK-IN
                  </button>
                </Link>
              )}
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 ">
              {" "}
              {currentUser?.check_in_save_record_access_flag && (
                <Link to="/singleCheckIn">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm col-lg-11 col-md-11 col-sm-11 m-0 float-end"
                    id="checkin-records-button"
                  >
                    SINGLE CHECK-IN
                  </button>
                </Link>
              )}
            </div>
          </div>

          <MDBCard className="card overflow-hidden h-md-100 table-box">
            <CustomHeader label={"CHECK-IN RECORDS"} />
            <MDBCardBody>
              <div className="table-responsive">
                <table className="table safeChiefTable">
                  <thead className="headerArea">
                    <tr>
                      <th scope="col" className="tableHeading1">
                        TAMPER EVIDENT BAG #
                      </th>
                      <th scope="col" className="tableHeading">
                        ACTIVITY TYPE
                      </th>
                      <th scope="col" className="tableHeading">
                        STORAGE LOCATION
                      </th>
                      <th scope="col" className="tableHeading1">
                        STORAGE TYPE
                      </th>
                      <th scope="col" className="tableHeading">
                        PERFORMED BY
                      </th>
                      <th scope="col" className="tableHeading">
                        STATUS
                      </th>
                    </tr>
                  </thead>

                  <tbody id="cs-scroll">
                    {status.isLoading ? 
                    (<tr>
                      <td colSpan="6" className="text-center py-5">
                      {loaderDisplay()}
                      </td>
                     </tr>
                    )  
                    : (
                      <>
                        {checkInRecords.length > 0 ? (
                          checkInRecords.map((item, index) => (
                            <tr
                              key={index}
                              className="table-row"
                              onClick={() =>
                                navigate("/checkinHistory", { state: item })
                              }
                            >
                              <td className="tableDetails1">
                                {
                                  item.tamper_evident_bag
                                    ?.tamper_evident_bag_number
                                }
                              </td>
                              <td className="tableDetails">
                                {item?.action?.action}
                              </td>
                              <td className="tableDetails">
                                {item?.branch_storage?.branch?.name}
                                {item?.vault_storage?.vault?.name}
                                {item?.warehouse_storage?.warehouse?.name}
                              </td>
                              <td className="tableDetails1">
                                {item.check_in_storage_type?.action}
                              </td>
                              <td className="tableDetails shipmentBody5">
                                {item?.performed_by?.employee_name}
                              </td>
                              <td className="tableDetails shipmentBody5">
                                {item?.status?.status}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div className="text-center py-5 alignself-center">
                            {status.noRecordsMessage}
                          </div>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </MDBCardBody>
            <Pagination
              pages={pages}
              onNextClick={() => fetchCheckInData(pagination.currentPage + 1)}
              onPreviousClick={() => fetchCheckInData(pagination.currentPage - 1)}
              onPageClick={handlePageClick}
              setRowsPerPage={handleRowCountChange}
              currentPage={pagination.currentPage}
            />
          </MDBCard>
        </div>
      </div>
    </div>
  );
}

export default ViewAllCheckIn;
