import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { MDBCard, MDBCardBody } from "mdbreact";
import Spinner from "react-bootstrap/Spinner";
import CustomHeader from "../components/CustomHeader";
import { useSelector } from "react-redux";
import { getTebDetails } from "../api/CheckOutService";
import { saveAs } from "file-saver";
// import NewTimeline from '../components/NewTimeline';
// import { getTimeline } from '../api/HomeServices';
// import { cognitoSignOut } from '../api/CognitoServices';
// import { ConsoleError } from '../utils/ErrorUtils';

function CheckOutDetails() {
  const [loader, setLoader] = useState(false);
  const { state } = useLocation();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const [checkOutData, setCheckOutData] = useState();
  const [entityDetails, setEntityDetails] = useState();
  const [documentList, setDocumentList] = useState([]);
  const [pictureList, setPictureList] = useState([]);
  const downloadFile = (link, name) => {
    saveAs(link, name);
  };
  // const [timeLineEvent, setTimeLineEvent] = useState("");
  // const [timeLineLoader, setTimeLineLoader] = useState(false);
  // const [saveEventLoader, setSaveEventLoader] = useState(false);
  // const [saveLoader, setSaveLoader] = useState(false);
  // const [eventData, setEventData] = useState([]);

  useEffect(() => {
    setLoader(true);
    getCurrentCheckOut();
  }, []);

  const getCurrentCheckOut = async () => {
    getTebDetails(
      state?.information?.tamper_evident_bag?.tamper_evident_bag_id
        ? state?.information?.tamper_evident_bag?.tamper_evident_bag_id
        : state,
      currentUser?.jwtToken
    ).then((response) => {
      setCheckOutData(response?.data[0]);
      setEntityDetails(response?.data[0]?.entity[0]);
      setPictureList(
        response?.data[0]?.documents.filter(
          (document) => document?.type === "PICTURE"
        )
      );
      setDocumentList(
        response?.data[0]?.documents.filter(
          (document) => document?.type === "DOCUMENT"
        )
      );
      setLoader(false);
    });
  };
  return (
    <div className="col-lg-12 col-md-12 col-sm-12  p-0 ">
      <div className="row flex pt-4 pl-4 pr-4 pb-0 ">
        <div className="col-lg-12 pb-3 pl-0 pr-0 ">
          <div className="row pt-1 pb-2">
            <div className="d-flex justify-content-end">
              <div className="pt-5">
                <Link to="/viewAllCheckOut">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    id="checkin-records-button"
                  >
                    CHECK-OUT RECORDS
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <MDBCard className="card p-0 m-0  ">
            <CustomHeader label={"CHECK - OUT Details"} />
            {loader ? (
              <div className="text-center py-5 mt-5 alignself-center">
                <Spinner animation="border" />
              </div>
            ) : (
              <MDBCardBody id="scroll-bar">
                <div className="row text-center ">
                  {/* <div className="col-lg-12 col-md-12 pt-4 pb-4 ">
                                     TODO: Implement Timeline if future requirements show up
                                        <NewTimeline
                                            handle={handle}
                                            tebId={checkOutData?.tamper_evident_bag?.tamper_evident_bag_id}
                                            eventData={eventData}
                                            timeLineLoader={timeLineLoader}
                                            // clickid={clickid}
                                            // onClickEachTimeline={onClickEachTimeline}
                                            // onPageClick={onPageClick}
                                        />
                                    </div> */}
                  <div className="event-div py-3 my-3">
                    <div className="d border border-5 event-border ml-5 mr-5 ">
                      <div className="row col-lg-12 justify-content-center pt-3 pb-3">
                        EVENT DETAILS
                      </div>{" "}
                      <div className="row col-lg-12 col-md-12 col-sm-12 ml-2 pb-3 bs-scroll">
                        <div className={"col-lg-12 col-md-12 col-sm-12 "}>
                          <form className="mt-3">
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Check Out Type
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {checkOutData?.action?.action}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Status
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {checkOutData?.status?.status}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Tamper Evident Bag#
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {
                                    checkOutData?.tamper_evident_bag
                                      ?.tamper_evident_bag_number
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Safe Deposit Box#
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {
                                    checkOutData?.tamper_evident_bag
                                      ?.safe_deposit_box_number
                                  }
                                </div>
                              </div>
                            </div>
                            {entityDetails && (
                              <>
                                <div className="form-group row">
                                  <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                    {checkOutData?.action?.action ===
                                    "Handing over to Customer"
                                      ? "Customer Name"
                                      : checkOutData?.action?.action ===
                                        "Handing over to the State"
                                      ? "State Person"
                                      : "Auctioneer Name"}
                                  </label>
                                  <div className="col-sm-8 p-0">
                                    <div className="float-start">
                                      {entityDetails?.entity_name}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                    {checkOutData?.action?.action ===
                                    "Handing over to Customer"
                                      ? "Customer Email"
                                      : "Email"}
                                  </label>
                                  <div className="col-sm-8 p-0">
                                    <div className="float-start">
                                      {entityDetails?.address?.email_id}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            {checkOutData?.contents && (
                              <div className="form-group row">
                                <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                  Contents
                                </label>
                                <div className="col-sm-8 p-0">
                                  <div className="float-start">
                                    {checkOutData?.contents}
                                  </div>
                                </div>
                              </div>
                            )}
                            {checkOutData?.notes && (
                              <div className="form-group row">
                                <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                  Notes
                                </label>
                                <div className="col-sm-8 p-0">
                                  <div className="float-start">
                                    {checkOutData?.notes}
                                  </div>
                                </div>
                              </div>
                            )}
                            {documentList?.length > 0 && (
                              <div className="form-group row">
                                <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                  Documents{" "}
                                </label>
                                <div className="col-sm-8 p-0">
                                  {documentList?.length > 0 && (
                                    <div className="row ">
                                      <div className="col-8 fw-bold d-flex pl-3">
                                        <span className="float-start">
                                          Name
                                        </span>
                                      </div>
                                      <div className="col-4 fw-bold">
                                        Description
                                      </div>
                                    </div>
                                  )}
                                  {documentList?.map((filteredDocument) => (
                                    <div className="row">
                                      <div className="col-8 d-flex">
                                        <span
                                          className="cursor-pointer float-start"
                                          onClick={() =>
                                            downloadFile(
                                              filteredDocument?.url,
                                              filteredDocument?.name
                                            )
                                          }
                                        >
                                          {filteredDocument?.name}
                                          <i className="fa fa-download pl-2" />
                                        </span>
                                      </div>
                                      <div className="col-4 ">
                                        {filteredDocument?.description
                                          ? filteredDocument?.description
                                          : "-"}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                            {pictureList?.length > 0 && (
                              <div className="form-group row">
                                <label className="d-flex col-sm-4 col-form-label nput-label p-0">
                                  Pictures{" "}
                                </label>
                                <div className="col-sm-8 p-0">
                                  {pictureList?.length > 0 && (
                                    <div className="row">
                                      <div className="col-8 fw-bold d-flex pl-3">
                                        <span className="float-start">
                                          Name
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {pictureList?.map((filteredPicture) => (
                                    <>
                                      <div className="row">
                                        <div className="col-8 d-flex">
                                          <span
                                            className="cursor-pointer float-start"
                                            onClick={() =>
                                              downloadFile(
                                                filteredPicture?.url,
                                                filteredPicture?.name
                                              )
                                            }
                                          >
                                            {filteredPicture?.name}
                                            <i className="fa fa-download pl-2" />
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                </div>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MDBCardBody>
            )}
          </MDBCard>
        </div>
      </div>
    </div>
  );
}

export default CheckOutDetails;
