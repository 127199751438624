import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";
import {
  CHECK_OUT,
  SAVE_BULK_SHIPMENT,
  VIEW_ALL_RECORDS,
  GET_IRP_HANDLING,
  DELETE_SOFT_HANDLING,
  GET_TEB_DETAILS
} from "../constants/ApiUrlConstants";
import { ConsoleError } from "../utils/ErrorUtils";

export const getViewCheckOutData = async (
  searchValue,
  pageNo,
  pageSize,
  empId,
  token
) => {
  return axios
    .get(
      service.baseUrl +
      CHECK_OUT +
      "?emp_id=" +
      empId +
      "&page_no=" +
      pageNo +
      "&page_size=" +
      pageSize +
      (searchValue ? "&search_value=" + searchValue : ""),
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const pagination = async (pageNumber, pageCount, empId, token) => {
  return axios
    .get(
      service.baseUrl +
      VIEW_ALL_RECORDS +
      "/Checkout/" +
      pageCount +
      "?page=" +
      pageNumber +
      "&emp_id=" +
      empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const saveBulkCheckoutData = async (dataSource, empId, token) => {
  return axios
    .post(
      service.baseUrl + SAVE_BULK_SHIPMENT + "?emp_id=" + empId,
      dataSource,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
    });
};

export const getIRPHandling = async (pageNo,
  pageSize, token) => {
  return axios
    .get(
      service.baseUrl +
      GET_IRP_HANDLING +
      "?page_no=" +
      pageNo +
      "&page_size=" +
      pageSize,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};


export const deleteCheckOut = async (checkOutId, token) => {
  return axios
    .delete(
      service.baseUrl +
      DELETE_SOFT_HANDLING +
      "?check_out_id=" +
      checkOutId ,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const getTebDetails = async (tamperEvidentBagId, token) => {
  return axios
    .get(
      service.baseUrl +
      GET_TEB_DETAILS +
      "?tamper_evident_bag_id=" +
      tamperEvidentBagId ,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};


