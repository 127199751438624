export const CHECKOUT_TEB_LIMIT = 20;

export const SAVE_CHECKOUT_REQUEST = {
  notes: "",
  contents: "",
  pictures: [
    {
      src: "",
    },
  ],
  documents: [
    {
      src: "",
      comments: "",
      fileName: "",
    },
  ],
  performed_by_id: "",
  activity_type: "",
  tamper_evident_bags: [""],
  authorizer_id: [""],
  phone_number: "",
  entity_name: "",
};
