import React, { useEffect, useState } from 'react';
import Search from "../components/Search";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { MDBCard, MDBCardBody } from "mdbreact";
import CustomHeader from "../components/CustomHeader";
import Pagination from "../components/Pagination";
import { getAllrecords, deleteRecords } from "../api/ForceOpenRecordsService";
import Spinner from "react-bootstrap/Spinner";
import { CustomAlert } from '../components/customAlert';
import DeleteStorage from "../assets/Delete.png";


function ViewAllPreOpen() {
    let navigate = useNavigate()
    const [saveLoader, setSaveLoader] = useState(false);
    const [rowCount, setRowCount] = useState(10)
    let initial = 0
    const [pages, setPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState("")
    const currentUser = useSelector(
        (reduxData) => reduxData?.userReducer?.value?.userDetails
    );
    const [filterData, setFilteredData] = useState([])
    const getRecords = (searchTerm = searchValue) => {
        getAllrecords(searchTerm, rowCount, currentPage, currentUser?.jwtToken).then((response) => {
            const total_pages = response?.data?.pagination?.pages;
            setPages([...Array(total_pages)].map((_, i) => i + 1));
            setFilteredData(response?.data?.records_list);
        });
    };
    
    const handleSearchValueChange = (value) => {
        setSearchValue(value); 
        if(value.trim().length === 0) getRecords(value);
    };
      
    const deleteRecord = async (record_id) => {
        deleteRecords(record_id, currentUser?.jwtToken).then((response) => {
            if (response?.status === 200) {
                CustomAlert(
                    "Record Deleted",
                    "Force Open Record Deleted Successfully",
                    "success",
                    "OK",
                    false
                );
                getRecords()
            } else {
                CustomAlert(
                    "failed",
                    "Failed deleting Force Open Record",
                    "error",
                    "OK",
                    false
                );
            }
        })
    }

    const onNextClick = async () => {
        setCurrentPage(currentPage + 1)
    }

    const onPreviousClick = async () => {
        setCurrentPage(currentPage - 1)
    }
    const onPageClick = async (e) => {
        setCurrentPage(e)
    }

    const setRowsPage = async (item) => {
        setRowCount(item)
    }

    useEffect(() => {
        getRecords()
    }, [currentPage, rowCount])
    return (
        <>
            <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 overflow-hidden body">
                <div className="row flex p-4">
                    <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden pt-5">
                        <div className="row pt-2">
                            <div className="col-lg-6 col-md-10">
                                <Search
                                    screen="PreOpen"
                                    setSearchValuePreOpen={handleSearchValueChange}
                                    searchValuePreOpen={searchValue}
                                    onClickSearch={getRecords}
                                // pageSize={rowCount}
                                />
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 "></div>
                            <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 ">
                                <button
                                    className="btn btn-primary btn-sm col-lg-6 col-md-6 col-sm-11 m-0 pl-0 pr-0 float-end"
                                    onClick={() => { getRecords()}}
                                >
                                    Refresh
                                </button>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 ">
                                {" "}
                                {currentUser?.enquiry_save_record_access_flag && (
                                    <Link to="/preOpen">
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-sm col-lg-11 col-md-11 col-sm-11 m-0 float-end"
                                            id="checkin-records-button"
                                        >
                                            NEW RECORD
                                        </button>
                                    </Link>
                                )}
                            </div>
                        </div>

                        <MDBCard className="card overflow-hidden h-md-100 table-box">
                            <CustomHeader label={"PRE OPEN RECORDS"} />
                            <MDBCardBody>
                                <div className="table-responsive">
                                    <table className="table safeChiefTable">
                                        <thead className="headerArea">
                                            <tr>
                                                <th scope="col" className="tableHeading1">
                                                    SAFE DEPOSIT BOX#
                                                </th>
                                                <th scope="col" className="tableHeading">
                                                    BRANCH
                                                </th>
                                                <th scope="col" className="tableHeading">
                                                    OWNER
                                                </th>
                                                <th scope="col" className="tableHeading">
                                                    FORCE OPEN DATE
                                                </th>

                                                <th scope="col" className="tableHeading">
                                                    ACTIONS
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody id="cs-scroll">
                                            {saveLoader ? (
                                                <div className="text-center py-5 alignself-center">
                                                    <Spinner animation="border" />
                                                </div>
                                            ) : (filterData.length > 0 ?
                                                <>
                                                    {filterData.map((item, index) => {
                                                        return (<tr className="table-row" key={item?.record_id} >
                                                            <td className="tableDetails1" >
                                                                {item.force_open_data?.safe_deposit_box_number}
                                                            </td>
                                                            <td className="tableDetails shipmentBody5">
                                                                {item?.force_open_data?.branch_name}
                                                            </td>
                                                            <td className="tableDetails">
                                                                {(item?.force_open_data?.owner_first_name ? item?.force_open_data?.owner_first_name : "")
                                                                    + " " +
                                                                    (item?.force_open_data?.owner_middle_name ? item?.force_open_data?.owner_middle_name : "")
                                                                    + " " +
                                                                    (item?.force_open_data?.owner_last_name ? item?.force_open_data?.owner_last_name : "")}
                                                            </td>
                                                            <td className="tableDetails shipmentBody5">
                                                                {item?.force_open_data?.force_open_date}
                                                            </td>

                                                            <td className="tableDetails shipmentBody5"  >
                                                                <>
                                                                    <button
                                                                        className="btn btn-primary btn-sm"
                                                                        value="Reset"
                                                                        onClick={() => {
                                                                            navigate("/forceOpen", {
                                                                                state: item,
                                                                            })
                                                                        }}
                                                                    >
                                                                        FORCE OPEN
                                                                    </button>
                                                                    {/* <button
                                                                        className="btn  btn-sm"
                                                                        id="approve-btn"
                                                                        onClick={() => {
                                                                            navigate("/updatePreOpen", {
                                                                                state: item?.record_id,
                                                                            })
                                                                        }}
                                                                    >
                                                                        Edit{" "}
                                                                    </button> */}
                                                                    <i
                                                                        className="fa fa-2x fa-trash ms-1"
                                                                        style={{marginBottom:'-10px'}}
                                                                        aria-hidden="true"
                                                                        onClick={() => {
                                                                            deleteRecord(item?.record_id)
                                                                        }}
                                                                    ></i>
                                                                </>
                                                            </td>

                                                        </tr>)
                                                    })}
                                                </>
                                                : <div className="text-center py-5 alignself-center">
                                                    {"No Records Found"}
                                                </div>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </MDBCardBody>
                            <Pagination
                                pages={pages}
                                onNextClick={onNextClick}
                                onPreviousClick={onPreviousClick}
                                onPageClick={onPageClick}
                                initial={initial}
                                // end={end}
                                setRowsPerPage={setRowsPage}
                                currentPage={currentPage}
                            />
                        </MDBCard>
                    </div>
                </div>
            </div >
        </>
    )
}

export default ViewAllPreOpen