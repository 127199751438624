

/**
 * RequireAuth Component
 * Manages access to routes based on `currentPath` by matching against `ACCESS_CONTROL` and `SAFECHIEF_ADMIN_PATHS`,
 * allowing access based on user role and flags.
 * 
 * - `ACCESS_CONTROL` defines paths and the necessary access flags.
 * - `SAFECHIEF_ADMIN_PATHS` grants direct access for the `safechief_admin` role.
 * - Performs async authentication check on mount and grants access with <Outlet /> if allowed;
 *   otherwise, redirects the user appropriately.
 */

import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ACCESS_CONTROL, SAFECHIEF_ADMIN_PATHS, SUPERADMIN_PATHS } from "../constants/RoleContants";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { login } from "../redux/user";

const RequireAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authVerified, setAuthVerified] = useState(false);

  const { userDetails } = useSelector((state) => state.userReducer.value);
  const { role } = userDetails || {};
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location.pathname;

  // Perform async authentication check on mount
  useEffect(() => {
    const authenticateUser = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (error) {
        console.error("User not authenticated:", error);
        dispatch(login(null));
      } finally {
        setAuthVerified(true);
      }
    };
    authenticateUser();
  }, [dispatch]);

  if (!authVerified) {
    return null; // Optionally, show a loading spinner if necessary
  }

  // Redirect to login page if user is not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // Grant access to "/home" for non-safechief_admin users
  if (currentPath === "/home" && role !== "safechief_admin") {
    return <Outlet />;
  }

  // Grant access to predefined admin paths for "safechief_admin" role
  if (role === "safechief_admin" && SAFECHIEF_ADMIN_PATHS.includes(currentPath)) {
    return <Outlet />;
  }
// Inside RequireAuth component, after the check for safechief_admin
if (role === "superadmin" && SUPERADMIN_PATHS.includes(currentPath)) {
  return <Outlet />;
}

  // Find the relevant access control entry based on currentPath
  const accessControlEntry = Object.values(ACCESS_CONTROL).find((entry) =>
    [entry?.view?.path, entry?.post?.path, entry?.bulk_upload?.path].some(paths => paths?.includes(currentPath))
  );

  // Redirect to "Page Not Found" if no matching access control entry is found
  if (!accessControlEntry) {
    return <Navigate to="/page-not-found" state={{ from: location }} replace />;
  }

  // Validate access based on the user's assigned flags and the access requirements for the current path
  const hasAccess = 
    (accessControlEntry.view.path.includes(currentPath) && accessControlEntry.view.flags.every(flag => userDetails[flag])) ||
    (accessControlEntry.post.path.includes(currentPath) && accessControlEntry.post.flags.every(flag => userDetails[flag])) ||
    (accessControlEntry.bulk_upload?.path.includes(currentPath) &&
      accessControlEntry.bulk_upload.flags.every(flag => userDetails[flag]));

  // Render the protected content if access is granted, otherwise redirect to "Page Not Found"
  return hasAccess ? <Outlet /> : <Navigate to="/page-not-found" state={{ from: location }} replace />;
};

export default RequireAuth;

