import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getddlStorageLocation,
  getddlStorageUnit,
} from "../../api/BulkCheckinServices.js";
import { cognitoSignOut } from "../../api/CognitoServices.js";
import "../../style/BulkCheckin.css";
import { useSelector } from "react-redux";
import { ConsoleError } from "../../utils/ErrorUtils.js";

const StepTwo = (props) => {
  let navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [SelectedStorageValue, setSelectedStorageValue] = useState("");

  //eslint-disable-next-line
  const [optionsStorageUnit, setOptionsStorageUnit] = useState([]);
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  // const handleAddRow = () => {
  //   const rows = [...props.formData.step2.storagerows];
  //   const item = { storageunitid: "", storageunitddl: 1 };
  //   props.onChange("step2", "storagerows", [...rows, item]);
  // };
  // const handleRemoveSpecificRow = (idx) => {
  //   const tempRows = [...props.formData.step2.storagerows];
  //   tempRows.splice(idx, 1);
  //   props.onChange("step2", "storagerows", [...tempRows]);
  // };
  // function updateState(e, index) {
  //   let prope = e.target.name;
  //   let fieldValue = e.target.value;
  //   const rows = [...props.formData.step2.storagerows];
  //   const tempRows = [...rows];
  //   const tempObj = rows[index];
  //   tempObj[prope] = fieldValue;
  //   tempRows[index] = tempObj;
  //   props.onChange("step2", "storagerows", tempRows);
  // }

  function handleSelectStorageLoc(e) {
    e.preventDefault();
    props.onChange("step2", "storageLoc", e.target.value);
    setSelectedStorageValue(e.target.value);
  }

  const getddlStorageLoc = async () => {
    getddlStorageLocation(currentUser?.employee_id, currentUser?.jwtToken)
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          setOptions(response?.data.data);
          props?.formData?.step2?.storageLoc &&
            setSelectedStorageValue(
              response?.data.find(
                (locationItem) =>
                  locationItem.storage_location_id ===
                  props?.formData?.step2?.storageLoc
              )?.storage_location_type
            );
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getddlStorageLoc();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getddlStorageUnitData();
    //eslint-disable-next-line
  }, [SelectedStorageValue]);

  const getddlStorageUnitData = async () => {
    getddlStorageUnit(
      SelectedStorageValue,
      currentUser?.employee_id,
      currentUser?.jwtToken
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200 || response.status === 204) {
          setOptionsStorageUnit(response?.data);
        }
      })

      .catch((err) => {});
  };

  return (
    <>
      <div className="d border divMain">
        <div className="row g-3 col-lg-12 col-md-12 col-sm-12 divFirstContent">
          <div className="col-lg-3 col-md-3 col-sm-3 col-form-label input-label">
            <label className="lblAlign">Activity Type </label>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-9 custom-control custom-radio">
            <input
              type="radio"
              className="custom-control-input"
              id="rbtnFOpen"
              name="rbnType"
              value={props.formData?.step2?.activityType}
              checked={props.formData?.step2?.activityType}
              onChange={(e) =>
                props.onChange("step2", "activityType", e.target.checked)
              }
            />
            <label
              className="col-lg-3 col-md-3 col-sm-3 custom-control-label"
              htmlFor="rbtnFOpen"
            >
              Force Open
            </label>
            <input
              type="radio"
              className="custom-control-input"
              id="rbtnSContent"
              name="rbnType"
              value={!props.formData?.step2?.activityType}
              checked={!props.formData?.step2?.activityType}
              onChange={(e) =>
                props.onChange("step2", "activityType", !e.target.checked)
              }
            />
            <label
              className="col-lg-3 col-md-3 col-sm-3 custom-control-label"
              htmlFor="rbtnSContent"
            >
              Shipped Content
            </label>
          </div>
        </div>

        <div className="row g-3  col-lg-12 col-md-12 col-sm-12 divStorageLoc">
          <div className="col-lg-3 col-md-3 col-sm-3 col-form-label input-label">
            <label className="lblAlign">Storage Location</label>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <select
              required
              className="select ddlStorageLoc"
              aria-label="Default select example"
              value={props.formData?.step2?.storageLoc}
              onChange={handleSelectStorageLoc}
            >
              <option value="">Select...</option>
              <option value="hj">h</option>

              {options &&
                options.length &&
                options.map((storageLocationddl) => (
                  <option
                    key={storageLocationddl.storage_location_id}
                    value={storageLocationddl.storage_location_id}
                  >
                    {storageLocationddl.name}
                  </option>
                ))}
            </select>
            {Boolean(props.formData?.step2?.storageLoc) ? (
              <> </>
            ) : (
              <p className="text-danger errormsg">Field is required *</p>
            )}
          </div>
        </div>

        <div className="d-flex divStorageRowData">
          <div></div>
        </div>

        <div className="row col-lg-12 col-md-12 col-sm-12 g-3 divComments">
          <div className="col-lg-3 col-md-3 col-sm-3 col-form-label input-label">
            <label className="lblAlign">Comments</label>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <textarea
              type="text"
              maxLength="500"
              className="textComments"
              id="txtComments"
              value={props.formData?.step2?.comments}
              onChange={(e) =>
                props.onChange("step2", "comments", e.target.value)
              }
            ></textarea>
          </div>
        </div>
      </div>

      <div className="mt-4 d border divPerformedBy">
        <div className="mx-auto lblPerformedBy">PERFORMED BY</div>

        <div className="form-group row">
          <div className="col-lg-2 col-md-2 col-sm-2 col-form-label input-label">
            <label className="lblEmpId">Employee ID </label>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <input
              type="text"
              className="form-control-plaintext"
              readOnly
              value={currentUser?.employee_id}
            />
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2 col-form-label input-label lblEmpName">
            <label>Employee Name </label>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2 divEmpName">
            <input
              type="text"
              className="form-control-plaintext"
              readOnly
              value={currentUser.employee_name}
            />
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2 col-form-label input-label divEmpDesignation">
            <label>Employee Designation</label>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            {/* TO-DO Check why value is Manager */}
            <input
              type="text"
              className="form-control-plaintext"
              readOnly
              value="Manager"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StepTwo;
