import { getHeader } from "../utils/Headers";
import service from "./service";
import axios from "axios";
import {
  SEARCH_TAMPER_EVIDENT_BAG,
  GET_STORAGE_LOCATIONS,
} from "../constants/ApiUrlConstants";
import { ConsoleError } from "../utils/ErrorUtils";

export const SearchService = async (
  searchType,
  pageNo,
  pageSize,
  searchValue,
  token
) => {
  return axios
    .get(
      service.baseUrl +
        SEARCH_TAMPER_EVIDENT_BAG +
        "?search_type=" +
        searchType +
        "&search_value=" +
        searchValue +
        "&page_no=" +
        pageNo +
        "&page_size=" +
        pageSize,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};

export const getStorageLocationResults = async (searchText, empId, token) => {
  return axios
    .get(
      service.baseUrl +
        GET_STORAGE_LOCATIONS +
        "?search_text=" +
        searchText +
        "&emp_id=" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
