import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";
import { GET_STORAGE_TYPE } from "../constants/ApiUrlConstants";

export const getStorageListOnsearch = async (
  storageType,
  searchValue,
  token
) => {
  return axios
    .get(
      service.baseUrl +
        GET_STORAGE_TYPE +
        "?storage_type=" +
        storageType +
        "&search_value=" +
        searchValue,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response?.data;
    });
};
