import axios from "axios";
import { getHeader } from "../utils/Headers";
import service from "./service";
import {
  GET_RECENT_ACTIVITY,
  DISPLAY_TIMELINE,
} from "../constants/ApiUrlConstants";
import { ConsoleError } from "../utils/ErrorUtils";

export const getRecentData = async (
  empId,
  pageNo,
  pageSize,
  token,
  safedepositValue,
  branchValue,
  tebValue,
  customerName
) => {
  return axios
    .get(
      service.baseUrl +
        GET_RECENT_ACTIVITY +
        "?emp_id=" +
        empId +
        "&page_no=" +
        pageNo +
        "&page_size=" +
        pageSize+
        "&teb_number="+
        tebValue+
        "&safe_deposit_box_number="+
        safedepositValue+
        "&branch_name="+
        branchValue+
        "&customer_name="+
        customerName,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};

let cancelTokenSource = axios.CancelToken.source();

export const getTimeline = async (teb_id, token) => {
  cancelTokenSource.cancel();
  cancelTokenSource = axios.CancelToken.source();
  return axios
    .get(service.baseUrl + DISPLAY_TIMELINE + "?teb_id=" + teb_id, {
      cancelToken: cancelTokenSource.token,
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })

    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      return "";
    });
};

export const getTimelinePagination = async (teb_id, pageNo, empId, token) => {
  cancelTokenSource.cancel();
  cancelTokenSource = axios.CancelToken.source();
  return axios
    .get(
      service.baseUrl +
        DISPLAY_TIMELINE +
        "?tamperevidentbag_id=" +
        teb_id +
        "&emp_id=" +
        empId +
        "&page=" +
        pageNo,
      {
        cancelToken: cancelTokenSource.token,
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )

    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      return "";
    });
};
