import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";
import {
  GET_TEB_FOR_EVENT,
  SEARCH_UNITS,
  GET_STORAGE_LOCATIONS,
  CHECKIN,
  GET_STORAGE_UNITS,
} from "../constants/ApiUrlConstants";
import { ConsoleError } from "../utils/ErrorUtils";

export const getCheckInData = async (pageNo, pageSize, empId, token, search_value) => {
  return axios
    .get(
      service.baseUrl +
        CHECKIN +
        "?emp_id=" +
        empId +
        "&page_no=" +
        pageNo +
        "&page_size=" +
        pageSize +
        "&search_value=" +
        search_value,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
export const getStorageLocationUnitRecordsCheckin = async (
  locID,
  storagetype,
  pageCount,
  empId,
  token
) => {
  return axios
    .get(
      service.baseUrl +
        GET_STORAGE_UNITS +
        "?storage_location_id=" +
        locID +
        "&storage_type=" +
        storagetype +
        "&records_per_page=" +
        pageCount +
        "&emp_id=" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
export const getStorageLocationRecords = async (
  storagetype,
  pageCount,
  empId,
  token
) => {
  return axios
    .get(
      service.baseUrl +
        GET_STORAGE_LOCATIONS +
        "?search_text=" +
        storagetype +
        "&records_per_page=" +
        pageCount +
        "&emp_id=" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )

    .then((response) => {
      return response;
    });
};

export const searchTamperEvidentBags = async (
  searchType,
  searchValue,
  pageNo,
  pageSize,
  token
) => {
  return axios
    .get(
      service.baseUrl +
        GET_TEB_FOR_EVENT +
        "?search_type=" +
        searchType +
        "&search_value=" +
        searchValue +
        "&page_no=" +
        pageNo +
        "&page_size=" +
        pageSize,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )

    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};

export const getTamperEvidentBags = async (
  activityType,
  searchValue,
  pageNo,
  pageSize,
  token,
  storageLocationId=null,
  storageLocationStorageType=null,
) => {
  const payload={
    "activity_type": activityType,
    "search_value":searchValue,
    "page_no":pageNo,
    "page_size":pageSize,
    "storageLocationId":storageLocationId,
    "storageLocationStorageType":storageLocationStorageType
  }
  return axios
    .post(
      service.baseUrl +
        GET_TEB_FOR_EVENT ,payload,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )

    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
    });
};

export const getstorageUnitsforBrancAndVault = async (
  id,
  Type,
  text,
  empId,
  token
) => {
  return axios
    .get(
      service.baseUrl +
        SEARCH_UNITS +
        "?unit_id=" +
        id +
        "&unit_type=" +
        Type +
        "&search_text=" +
        text +
        "&emp_id=" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )

    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
    });
};
export const saveSingleCheckin = async (payload, empId, token) => {
  return axios
    .post(service.baseUrl + CHECKIN, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })

    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      return error;
    });
};
