import { Amplify, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { awsconfig } from "../aws-exports";
import "../style/styles.css";
import { createSafechiefAdmin } from "../api/CognitoServices";
import ConfigData from "../config";
import { useDispatch } from "react-redux";
import { login } from "../redux/user";
import ReactLoading from "react-loading";
import { ConsoleError } from "../utils/ErrorUtils";
import { NOT_AUTHORIZED_USER } from "../constants/RoleContants";

const AdminVerification = () => {
  Amplify.configure(awsconfig);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const getUserDetails = () => {
    setLoader(true);
    Auth.currentAuthenticatedUser({})
      .then((user) => {
        let userData = {
          jwtToken: user.signInUserSession.idToken.jwtToken,
          employee_id: user.signInUserSession.idToken.payload.sub,
          employee_name: user.signInUserSession.idToken.payload.name,
          employee_email: user.signInUserSession.idToken.payload.email,
          employee_designation: user.signInUserSession.idToken.payload
            ?.designation
            ? user.signInUserSession.idToken.payload?.designation
            : ConfigData.designation,
          employee_org_id: user.signInUserSession.idToken.payload.name,
        };

        dispatch(login(userData));
        let employee_details = {
          employee_id: user.signInUserSession.idToken.payload.sub,
          employee_name: user.signInUserSession.idToken.payload.name,
          employee_email: user.signInUserSession.idToken.payload.email,
          employee_designation: user.signInUserSession.idToken.payload
            ?.designation
            ? user.signInUserSession.idToken.payload?.designation
            : ConfigData.designation,
          employee_org_id: user.signInUserSession.idToken.payload.name,
        };
        createSafechiefAdmin(
          employee_details,
          user.signInUserSession.idToken.jwtToken
        ).then((response) => {
          if (
            (response?.data !== undefined && response?.status === 200) ||
            (response?.data !== undefined && response?.status === 201) ||
            (response?.data !== undefined && response?.status === 409)
          ) {
            if (
              response?.data?.employee_designation_configuration?.role_name ===
              "safechief_admin"
            ) {
              navigateTo("/all-tenants");
            } else if (
              !NOT_AUTHORIZED_USER.includes(
                response?.data?.employee_designation_configuration?.role_name
              )
            ) {
              navigateTo("/home");
            } else {
              navigate("/welcome");
            }

            function navigateTo(path) {
              const employeeDesignationConfigurationData = {
                role: response?.data.employee_designation_configuration
                  .role_name,
              };

              ConfigData.employeeDesignationVariableList.forEach(
                (employeeDesignationVariable) => {
                  employeeDesignationConfigurationData[
                    employeeDesignationVariable
                  ] =
                    response?.data.employee_designation_configuration[
                      employeeDesignationVariable
                    ];
                }
              );

              const userDataCopy = {
                ...userData,
                ...employeeDesignationConfigurationData,
              };
              dispatch(login(userDataCopy));
              navigate(path);
              setLoader(false);
            }
          } else if (response?.status === 500) {
            dispatch(login(null));
            setLoader(false);
            navigate("/admin-login");
          } else if (response?.status === 404) {
            navigate("/admin-login");
          } else {
            dispatch(login(null));
            navigate("/admin-login");
          }
        });
      })
      .catch((error) => {
        if (error === "The user is not authenticated") {
          navigate("/admin-login", { state: { authorizedUser: false } });
          setLoader(false);
        }
        ConsoleError(error);
      });
  };
  useEffect(() => {
    getUserDetails();
  }, []);
  return (
    <>
      {loader ? (
        <>
          <h2
            style={{ textAlign: "center", paddingTop: "25%", color: "#6f7d95" }}
          >
            We are getting things ready for Admin{" "}
          </h2>
          <div
            style={{
              position: "absolute",
              top: "40%",
              left: "48%",
              color: "#6f7d95",
            }}
          >
            <ReactLoading
              type="spinningBubbles"
              color="#6f7d95"
              height={100}
              width={50}
            />
          </div>
        </>
      ) : null}
    </>
  );
};
export default AdminVerification;
