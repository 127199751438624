import { getHeader } from "../utils/Headers";
import service from "./service";
import axios from "axios";
import { GET_REPORTS, REPORT_TYPES } from "../constants/ApiUrlConstants";

export const getReportList = async (searchValue = null, token) => {
  return axios
    .get(
      service.baseUrl +
        REPORT_TYPES +
        "?" +
        (searchValue ? "&search_value=" + searchValue : ""),
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const createReportType = async (payload, token) => {
  return axios
    .post(service.baseUrl + REPORT_TYPES, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    });
};

export const getReportRecords = async (
  reportType,
  fromDate = null,
  toDate = null,
  storageType = null,
  location = null,
  token
) => {
  let dateRangeString =
    fromDate && toDate ? "&from_date=" + fromDate + "&to_date=" + toDate : "";
  let storageString =
    storageType && location
      ? "&storage_type=" + storageType + "&location=" + location
      : "";
  return axios
    .get(
      service.baseUrl +
        GET_REPORTS +
        "?report_type=" +
        reportType +
        dateRangeString +
        storageString,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
