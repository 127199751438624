import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { getEmployeesOnsearch } from "../api/EmployeeService";
import Swal from "sweetalert2";
import { fetchRoles } from "../api/YTPAdminServices";
import AddUserModal from "../pages/AddUserModal";
import Pagination from "../components/Pagination";
import { Auth } from "aws-amplify";
import ResponsiveTable from "./Table";
import EditStorage from "../assets/EditStorage.png";
import CustomHeader from "../components/CustomHeader";
import { useDebounce } from "../utils/useDebounce";

const ViewUsers = () => {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10,
    totalPages: 0,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalState, setModalState] = useState({
    isOpen: false,
    isEdit: false,
    currentUserData: null,
  });

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const fetchEmployees = useCallback(
    async (page, query, perPage) => {
      setIsLoading(true);
      try {
        const response = await getEmployeesOnsearch(
          null,
          null,
          null,
          query,
          currentUser?.jwtToken,
          page,
          perPage
        );
        setEmployeeList(response.data || []);
        setPagination((prev) => ({
          ...prev,
          totalPages: Math.ceil((response.total_records || 0) / perPage),
        }));
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error fetching users",
          text: "There was an issue fetching the data. Please try again later.",
        });
        console.error("Failed to fetch users:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [currentUser?.jwtToken]
  );

  const fetchRoleData = useCallback(async () => {
    try {
      const response = await fetchRoles(1, 10, currentUser?.jwtToken);
      setRoles(response?.data?.roles_list || []);
    } catch (error) {
      console.error("Failed to fetch roles:", error);
    }
  }, [currentUser?.jwtToken]);

  const openAddUserModal = () => {
    setModalState({ isOpen: true, isEdit: false, currentUserData: null });
  };

  const openEditUserModal = async (user) => {
    try {
      setModalState({
        isOpen: true,
        isEdit: true,
        currentUserData: user,
      });
    } catch (error) {
      console.error("Error fetching user phone number from Cognito:", error);
    }
  };

  const closeModal = (shouldRefresh = false) => {
    setModalState({ isOpen: false, isEdit: false, currentUserData: null });
    if (shouldRefresh) {
      fetchEmployees(pagination.currentPage, debouncedSearchQuery, pagination.perPage);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > pagination.totalPages) return;
    setPagination((prev) => ({ ...prev, currentPage: newPage }));
  };

  const handlePerPageChange = (e) => {
    setPagination({
      currentPage: 1,
      perPage: Number(e),
      totalPages: pagination.totalPages,
    });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  const columns = [
    { label: "Name", key: "employee_name" },
    { label: "Email", key: "employee_email" },
    {
      label: "Current Role",
      key: "employee_designation",
      accessor: (row) =>
        row.employee_designation_configuration?.employee_designation || "N/A",
    },
  ];

  const actions = (row) => (
    <img
      src={EditStorage}
      alt="Edit"
      onClick={() => openEditUserModal(row)}
      className="edit-icon"
    />
  );

  const paginationData = useMemo(
    () => ({
      pages: Array.from({ length: pagination.totalPages }, (_, i) => i + 1),
      currentPage: pagination.currentPage,
      onNextClick: () => handlePageChange(pagination.currentPage + 1),
      onPreviousClick: () => handlePageChange(pagination.currentPage - 1),
      onPageClick: handlePageChange,
      setRowsPerPage: handlePerPageChange,
      rowsPerPage: pagination?.perPage,
    }),
    [pagination]
  );

  useEffect(() => {
    fetchEmployees(pagination.currentPage, debouncedSearchQuery, pagination.perPage);
  }, [pagination.currentPage, debouncedSearchQuery, pagination.perPage, fetchEmployees]);

  useEffect(() => {
    fetchRoleData();
  }, [fetchRoleData]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <input
          type="text"
          className="form-control w-75"
          placeholder="Search by Name or Email"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <button className="btn btn-primary ms-3" onClick={openAddUserModal}>
          Add User
        </button>
      </div>

      <CustomHeader label={"USER MANAGEMENT"} />

      {isLoading ? (
        <div className="loader" aria-live="polite">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <ResponsiveTable
          columns={columns}
          data={employeeList}
          actions={actions}
          isLoading={isLoading}

          paginationData={paginationData}
        />
      )}

      {modalState.isOpen && (
        <AddUserModal
          isOpen={modalState.isOpen}
          roles={roles}
          onClose={(shouldRefresh) => closeModal(shouldRefresh)}
          isEdit={modalState.isEdit}
          currentUserData={modalState.currentUserData}
        />
      )}
    </>
  );
};

export default ViewUsers;
