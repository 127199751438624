import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";
import { GET_ACTION } from "../constants/ApiUrlConstants";

export const getActionList = async (actionType, token) => {
  return axios
    .get(service.baseUrl + GET_ACTION + "?action_type=" + actionType, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response?.data;
    });
};
