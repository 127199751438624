import React from "react";
import Navigation from "../src/Router";

import { Amplify } from "aws-amplify";
import { awsconfig } from "./aws-exports"; // Import the admin configuration

Amplify.configure(awsconfig); // Initialize Amplify with the admin user pool config

function App() {
  return <Navigation />;
}

export default App;
