import React, { useState, useEffect } from "react";
import { getStorageLocationData } from "../../api/ShipmetServices";
import "../../style/bulkshipment.css";
import { useSelector } from "react-redux";
import { cognitoSignOut } from "../../api/CognitoServices";
import { useNavigate } from "react-router-dom";
import PerformedBy from "../../components/PerformedBy";
import { getActionList } from "../../api/ActionService";
import { ConsoleError } from "../../utils/ErrorUtils";
import SearchPanel from "../../components/SearchPanel";
import { getStorageListOnsearch } from "../../api/StorageListService";
import { getEmployeesOnsearch } from "../../api/EmployeeService";
import { PrintDisable } from "../../utils/PrintDisable";

const Section2 = (props) => {
  let navigate = useNavigate();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  PrintDisable(currentUser?.print_access_flag);
  // const [storageLocationlist, setStorageLocationlist] = useState([]);
  // const inputRef = useRef();
  // const [displayStorageDropdown, setDisplayStorageDropdown] = useState(false);
  const [storageTypeList, setStorageTypeList] = useState([]);
  const [isShowFromBranch, setIsShowFromBranch] = useState(false);
  const [isShowToBranch, setIsShowToBranch] = useState(false);
  //eslint-disable-next-line
  const [fromBranchOptionList, setFromBranchOptionList] = useState([]);
  //eslint-disable-next-line
  const [fromBranchId, setFromBranchId] = useState([]);

  const [fromBranchList, setFromBranchList] = useState([]);
  const [toBranchOptionList, setToBranchOptionList] = useState([]);
  const [toBranchList, setToBranchList] = useState([]);
  //eslint-disable-next-line
  const [toBranchId, setToBranchId] = useState([]);

  const [isShowFromVault, setIsShowFromVault] = useState(false);
  const [isShowFromWarehouse, setIsFromWarehouse] = useState(false);
  const [fromSearchPanelLabel, setFromSearchPanelLabel] = useState("");
  const [fromSearchPanelPlaceholder, setFromSearchPanelPlaceholder] =
    useState("");

  const [isShowToVault, setIsShowToVault] = useState(false);
  const [isShowToWarehouse, setIsToWarehouse] = useState(false);
  const [toSearchPanelLabel, setToSearchPanelLabel] = useState("");
  const [toSearchPanelPlaceholder, setToSearchPanelPlaceholder] = useState("");

  // const [formErrors, setFormErrors] = useState({});
  //const [isModelEnabled, setIsModelEnabled] = useState(false);
  //eslint-disable-next-line
  //const [storageLocation, setStorageLocation] = useState([]);
  //eslint-disable-next-line
  // const [storageLocId, setStorageLocId] = useState("");
  const [fromStorageLocationType, setFromStorageLocationType] = useState("");
  const [toStorageLocationType, setToStorageLocationType] = useState("");
  //eslint-disable-next-line
  const [fromVaultId, setFromVaultId] = useState("");
  //eslint-disable-next-line
  const [fromWarehouseId, setFromWarehouseId] = useState("");
  //eslint-disable-next-line
  const [toVaultId, setToVaultId] = useState("");
  //eslint-disable-next-line
  const [toWarehouseId, setToWarehouseId] = useState("");
  //eslint-disable-next-line
  const [showToOptions, setShowToOptions] = useState(true);
  const [showFromOptions, setShowFromOptions] = useState(true);
  const [fromSearchString, setFromSearchString] = useState("");
  const [toSearchString, setToSearchString] = useState("");
  //eslint-disable-next-line
  const [isFromStorageNameAssigned, setFromStorageNameAssigned] =
    useState(false);
  const [isToStorageNameAssigned, setToStorageNameAssigned] = useState(false);

  // const setFromBranchDid = (branch_did) => {
  //   props.formData.step2 = props.formData.step2 ?? {};
  //   props.formData.step2.from_branch_did = branch_did;
  // };

  //eslint-disable-next-line
  const [fromBranchDid, setFromBranchDid] = useState("");
  //eslint-disable-next-line
  const [toBranchDid, setToBranchDid] = useState("");

  const [employeeOptionList, setEmployeeOptionList] = useState([]);
  const [authorizerSearchString, setAuthorizerSearchString] = useState("");
  const [isAuthorizerAssigned, setIsAuthorizerAssigned] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [authorizerShowOptions, setAuthorizerShowOptions] = useState(true);
  //eslint-disable-next-line
  const [authorizerId, setAuthorizerId] = useState("");

  //eslint-disable-next-line
  let error;
  // const sdbValues = (value, type) => {
  //   if (type === "storageLocation") {
  //     props.onChange("step2", "toLocation", value);
  //     setDisplayStorageDropdown(false);
  //   }
  // };

  const getEmployeesBySearch = () => {
    const approvalAccessFlag = true;
    const authorizerAcessFlag = null;
    const auditAccessFlag = null;
    getEmployeesOnsearch(
      approvalAccessFlag,
      authorizerAcessFlag,
      auditAccessFlag,
      authorizerSearchString,
      currentUser?.jwtToken
    )
      .then((response) => {
        response = response?.data;
        setEmployeeList(response);
        setEmployeeOptionList(
          response
            .filter(
              (employee) => employee.employee_id !== currentUser?.employee_id
            )
            .map(function (emp) {
              return (
                (emp.employee_name ? emp.employee_name : "") +
                (emp.employee_email ? " - " + emp.employee_email : "") +
                (emp.employee_org_id ? " - " + emp.employee_org_id : "")
              );
            })
        );
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };

  const onChange = (e) => {
    // const { name, value } = e.target;
    const { name, value, options, selectedIndex } = e.target;

    // Retrieve the label (text) and the custom name attribute from the selected <option>
    const selectedOptionText = options[selectedIndex].text;

    setShowFromOptions(false);
    setShowToOptions(false);
    // if (formErrors[name] !== undefined && formErrors[name].length > 0) {
    //   delete formErrors[name];
    // }
    props.onChange("step2", name, value);

    if (name === "from_storage_type") {
      setFromSearchString("");
      setFromBranchDid("");

      //TO-DO see how to decouple with storage type id
      // if (e.target.value === "81e44f48-b167-49a3-8890-bdb758506e10") {
      if (selectedOptionText === "Branch") {
        setIsShowFromBranch(true);

        setIsShowFromVault(false);

        setIsFromWarehouse(false);

        setFromStorageLocationType("branch");

        setFromSearchPanelLabel("Branch Name");

        setFromSearchPanelPlaceholder("Search by Branch Name or DID");

        props.onChange("step2", "from_storage_type", e.target.value);
        // props.onChange('step2','isShowFromBranch',true)
        // props.onChange('step2','isShowFromVault',false)
        //  props.onChange('step2','isFromWarehouse',false)
        // props.onChange('step2','fromSearchPanelPlaceholder','Search by Branch Name or DID')
        // props.onChange('step2','fromSearchPanelLabel','Branch Name')
        // props.onChange('step2','fromStorageLocationType','branch')
        // } else if (e.target.value === "892763eb-b360-43fb-b020-5cc3c80baebd") {
      } else if (selectedOptionText === "Warehouse") {
        setIsShowFromBranch(false);
        setIsShowFromVault(false);
        setIsFromWarehouse(true);
        setFromStorageLocationType("warehouse");
        setFromSearchPanelLabel("Warehouse Name");
        setFromSearchPanelPlaceholder("Search by Warehouse Name");

        //   "enter into warehouse",
        //   props?.formData?.step2?.from_storage_type
        // );
        props.onChange("step2", "from_storage_type", e.target.value);
        // } else if (e.target.value === "23137f9a-6bcb-4796-b456-d90394cd251a") {
      } else if (selectedOptionText === "Warehouse") {
        setIsShowFromBranch(false);
        setIsShowFromVault(true);
        setIsFromWarehouse(false);
        setFromStorageLocationType("vault");
        setFromSearchPanelLabel("Vault Name");
        setFromSearchPanelPlaceholder("Search by Vault Name");

        props.onChange("step2", "from_storage_type", e.target.value);
      }
    }
  };

  useEffect(() => {
    getActionList("StorageType", currentUser?.jwtToken)
      .then((response) => {
        setStorageTypeList(
          response?.map(function (storageTypeRow) {
            return storageTypeRow;
          })
        );
      })
      .catch((error) => {
        ConsoleError(error);
      });
  }, [currentUser?.jwtToken]);

  useEffect(() => {
    getStorageList("from");

    //eslint-disable-next-line
  }, [fromSearchString]);

  useEffect(() => {
    getStorageList("to");

    //eslint-disable-next-line
  }, [toSearchString]);

  useEffect(() => {
    if (!isAuthorizerAssigned && authorizerSearchString.length > 0) {
      getEmployeesBySearch();
    }
    //eslint-disable-next-line
  }, [authorizerSearchString]);

  function getStorageList(type) {
    let storage_search_type = "";
    if (type === "from") {
      storage_search_type = isShowFromBranch
        ? "branch"
        : isShowFromVault
        ? "vault"
        : "warehouse";
    } else {
      storage_search_type = isShowToBranch
        ? "branch"
        : isShowToVault
        ? "vault"
        : "warehouse";
    }
    const searchString = type === "from" ? fromSearchString : toSearchString;
    getStorageListOnsearch(
      storage_search_type,
      searchString,
      currentUser?.jwtToken
    )
      .then((response) => {
        // TO-DO handle no data and update variable names as generic
        if (type === "from") {
          setFromBranchList(response);
          setFromBranchOptionList(
            response?.map(function (branchRow) {
              return (
                (branchRow.name ? branchRow.name : "") +
                (branchRow.branch_did ? " - " + branchRow.branch_did : "")
              );
            })
          );
        } else {
          setToBranchList(response);
          setToBranchOptionList(
            response?.map(function (branchRow) {
              return (
                (branchRow.name ? branchRow.name : "") +
                (branchRow.branch_did ? " - " + branchRow.branch_did : "")
              );
            })
          );
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  }

  const handleChange = (e) => {
    // const { name, value } = e.target;
    const { name, value, options, selectedIndex } = e.target;

    // Retrieve the label (text) and the custom name attribute from the selected <option>
    const selectedOptionText = options[selectedIndex].text;

    if (e.target.id === "toLocation") {
      //setDisplayStorageDropdown(true);
      props.onChange("step2", name, value);
      let array;
      getStorageLocationData(
        value,
        currentUser?.employee_id,
        currentUser?.jwtToken
      ).then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((err) => {
              console.error(err);
            });
        } else if (response?.data.status === 200) {
          let list = response?.data.data;
          array = list.filter(function (list) {
            return (
              list.storage_location_id === props.formData?.step2?.fromLocationId
            );
          });
          //eslint-disable-next-line
          let list1 = list.filter(
            (ar) =>
              !array.find(
                (rm) => rm.storage_location_id === ar.storage_location_id
              )
          );
          // setStorageLocationlist(list1);
        }
      });
    } else if (name === "to_storage_type") {
      setToSearchString("");
      // setToBranchDid("");
      props.onChange("step2", name, value);
      //TO-DO see how to decouple with storage type id
      // if (e.target.value === "81e44f48-b167-49a3-8890-bdb758506e10") {
      if (selectedOptionText === "Branch") {
        setIsShowToBranch(true);
        setIsShowToVault(false);
        setIsToWarehouse(false);
        setToStorageLocationType("branch");
        setToSearchPanelLabel("Branch Name");
        setToSearchPanelPlaceholder("Search by Branch Name or DID");
        props.onChange("step2", "to_storage_type", e.target.value);
      } else if (selectedOptionText === "Warehouse") {
        setIsShowToBranch(false);
        setIsShowToVault(false);
        setIsToWarehouse(true);
        setToStorageLocationType("warehouse");
        setToSearchPanelLabel("Warehouse Name");
        setToSearchPanelPlaceholder("Search by Warehouse Name");
        props.onChange("step2", "to_storage_type", e.target.value);
      } else if (selectedOptionText === "Vault") {
        setIsShowToBranch(false);
        setIsShowToVault(true);
        setIsToWarehouse(false);
        setToStorageLocationType("vault");
        setToSearchPanelLabel("Vault Name");
        setToSearchPanelPlaceholder("Search by Vault Name");
        props.onChange("step2", "to_storage_type", e.target.value);
      }
    } else {
      props.onChange("step2", name, value);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      props.formData?.step2?.startDate !== "" &&
      props.formData?.step2?.estimatedArrivalDate !== "" &&
      props.formData?.step2?.toLocation !== ""
    ) {
      return true;
    }
  };
  // const setCurrentDateTime = () => {
  //   let date = new Date();
  //   let day = date.getDate();
  //   let month = date.getMonth() + 1;
  //   let year = date.getFullYear();
  //   let hour = date.getHours();
  //   let min = date.getMinutes();
  //   if (month < 10) month = "0" + month;
  //   if (day < 10) day = "0" + day;
  //   if (hour < 10) hour = "0" + hour;
  //   if (min < 10) min = "0" + min;
  //   return year + "-" + month + "-" + day + "T" + hour + ":" + min;
  // };

  const estimatedArrivalDate = () => {
    if (
      props.formData?.step2?.estimatedArrivalDate <
      props.formData?.step2?.startDate
    ) {
      error = "Estimated arrival aate should be greater then start date ";
    }
    return props.formData?.step2?.startDate;
  };
  return (
    <>
      <div className="d border divMainBorderShipment">
        <div className="col-lg-12 col-md-12 col-sm-12 ">
          <form autoComplete="on" onSubmit={submitHandler}>
            <div className="col-lg-12 pt-2">
              <div className="row pt-3">
                {/* <div className="col-lg-6 form-group row">
                  <label
                    id="lblAlignLeft"
                    className="d-flex col-sm-5 col-form-label input-label"
                  >
                    From Location{" "}
                  </label>
                  <div className="col-sm-6 p-0">
                    <input
                      type="text"
                      name="fromLocation"
                      readOnly
                      className="form-control-plaintext"
                      value={props.formData?.step2?.fromLocation}
                    />
                  </div>
                </div> */}

                <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                  <label htmlFor="from_storage_type">From Storage Type</label>
                  <select
                    className="custom-select mr-sm-2"
                    id="from_storage_type"
                    name="from_storage_type"
                    value={props.formData.step2.from_storage_type}
                    onChange={onChange}
                  >
                    <option value="">Choose...</option>
                    {storageTypeList?.map((opt, index) => {
                      return (
                        <option
                          key={index}
                          value={opt.action_id}
                          name={`option-${index}`}
                        >
                          {opt.action}
                        </option>
                      );
                    })}
                  </select>
                  <div className="error-message-validation">
                    {/* {formErrors.from_storage_type} */}
                  </div>
                </div>

                {(isShowFromBranch ||
                  isShowFromVault ||
                  isShowFromWarehouse) && (
                  <>
                    <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                      <SearchPanel
                        label={fromSearchPanelLabel}
                        placeholder={fromSearchPanelPlaceholder}
                        options={fromBranchOptionList}
                        setOptionList={setFromBranchOptionList}
                        setShowOptions={setShowFromOptions}
                        name="from_storage_location_id"
                        id="from_storage_location_id"
                        showOptions={showFromOptions}
                        onFocusSearch={(e) => {
                          setFromSearchString("");
                          setFromBranchDid("");
                          setShowFromOptions(true);
                        }}
                        handleChange={(e) => {
                          if (isToStorageNameAssigned) {
                            setFromSearchString(
                              e.target.value.charAt(e.length - 1)
                            );
                            setFromStorageNameAssigned(false);
                          } else {
                            setFromSearchString(e.target.value);
                          }
                          setShowFromOptions(true);
                        }}
                        searchString={fromSearchString}
                        onSelectValue={(selectedStorage) => {
                          setFromStorageNameAssigned(true);
                          setShowFromOptions(false);
                          const branch = fromBranchList.filter(
                            (branchRow) =>
                              branchRow?.branch_did ===
                              selectedStorage.split(" - ")[1]
                          )[0];
                          setFromSearchString(branch?.name);
                          setFromBranchId(branch?.branch_id);
                          setFromBranchDid(branch?.branch_did);
                          setFromVaultId(branch?.vault_id);
                          setFromWarehouseId(branch?.warehouse_id);
                          if (fromStorageLocationType === "warehouse") {
                            props.onChange(
                              "step2",
                              "from_location",
                              branch?.warehouse_id
                            );
                          } else if (fromStorageLocationType === "branch") {
                            props.onChange(
                              "step2",
                              "from_location",
                              branch?.branch_id
                            );
                          } else if (fromStorageLocationType === "vault") {
                            props.onChange(
                              "step2",
                              "from_location",
                              branch?.vault_id
                            );
                          }

                          // if(branch?.branch_id != undefined){
                          //   props.onChange("step2", 'from_storage_type_name', 'Branch');
                          // }
                          // else if(branch?.vault_id != undefined){
                          //   props.onChange("step2", 'from_storage_type_name', 'Vault');
                          // }
                          // else if(branch?.warehouse_id != undefined){
                          //   props.onChange("step2", 'from_storage_type_name', 'Warehouse');
                          // }
                        }}
                      />
                    </div>
                  </>
                )}
                {isShowFromBranch && (
                  <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                    <label htmlFor="to_branch_did">Branch DID</label>
                    <input
                      type="text"
                      className="form-control"
                      id="from_branch_did"
                      name="from_branch_did"
                      disabled
                      autoComplete="off"
                      value={fromBranchDid}
                    />
                  </div>
                )}

                <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                  <label htmlFor="to_storage_type"> To Storage Type</label>
                  <select
                    className="custom-select mr-sm-2"
                    id="to_storage_type"
                    name="to_storage_type"
                    defaultValue=""
                    value={props?.formData?.step2?.to_storage_type}
                    //onChange={onChange}
                    onChange={handleChange}

                    // onChange={(e) =>
                    //   <>
                    //     props.onChange("step2", "to_storage_type", e.target.value)
                    //     handleChange(e)
                    //   </>

                    // }
                  >
                    <option value="">Choose...</option>
                    {storageTypeList?.map((opt, index) => {
                      return (
                        <option key={index} value={opt.action_id}>
                          {opt.action}
                        </option>
                      );
                    })}
                  </select>
                  <div className="error-message-validation">
                    {/* {formErrors.to_storage_type} */}
                  </div>
                </div>

                {(isShowToBranch || isShowToVault || isShowToWarehouse) && (
                  <>
                    <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                      <SearchPanel
                        label={toSearchPanelLabel}
                        placeholder={toSearchPanelPlaceholder}
                        options={toBranchOptionList}
                        setOptionList={setToBranchOptionList}
                        setShowOptions={setShowToOptions}
                        name="storage_location_value"
                        id="storage_location_value"
                        showOptions={showToOptions}
                        onFocusSearch={(e) => {
                          setToSearchString("");
                          setToBranchDid("");
                          setShowToOptions(true);
                        }}
                        handleChange={(e) => {
                          if (isToStorageNameAssigned) {
                            setToSearchString(
                              e.target.value.charAt(e.length - 1)
                            );
                            setToStorageNameAssigned(false);
                          } else {
                            setToSearchString(e.target.value);
                          }
                          setShowToOptions(true);
                        }}
                        searchString={toSearchString}
                        onSelectValue={(selectedStorage) => {
                          setToStorageNameAssigned(true);
                          setShowToOptions(false);
                          const branch = toBranchList.filter(
                            (branchRow) =>
                              branchRow?.branch_did ===
                              selectedStorage.split(" - ")[1]
                          )[0];
                          setToSearchString(branch?.name);
                          setToBranchDid(branch?.branch_did);
                          setToVaultId(branch?.vault_id);
                          setToWarehouseId(branch?.warehouse_id);
                          setToBranchId(branch?.branch_id);

                          if (toStorageLocationType === "warehouse") {
                            props.onChange(
                              "step2",
                              "to_location",
                              branch?.warehouse_id
                            );
                          } else if (toStorageLocationType === "branch") {
                            props.onChange(
                              "step2",
                              "to_location",
                              branch?.branch_id
                            );
                          } else if (toStorageLocationType === "vault") {
                            props.onChange(
                              "step2",
                              "to_location",
                              branch?.vault_id
                            );
                          }

                          // if(branch?.branch_id != undefined){
                          //   props.onChange("step2", 'to_storage_type_name', 'Branch');
                          // }
                          // else if(branch?.vault_id != undefined){
                          //   props.onChange("step2", 'to_storage_type_name', 'Vault');
                          // }
                          // else if(branch?.warehouse_id != undefined){
                          //   props.onChange("step2", 'to_storage_type_name', 'Warehouse');
                          // }
                        }}
                      />

                      <div className="error-message-validation">
                        {/* {formErrors.storage_location_id} */}
                      </div>
                    </div>
                  </>
                )}
                {isShowToBranch && (
                  <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                    <label htmlFor="to_branch_did">Branch DID</label>
                    <input
                      type="text"
                      className="form-control"
                      id="to_branch_did"
                      name="to_branch_did"
                      disabled
                      autoComplete="off"
                      value={toBranchDid}
                    />
                  </div>
                )}

                {/* <div className="form-control-plaintext ">
                    {props.formData?.step2?.toLocation !== null &&
                      props.formData?.step2?.toLocation !== "" &&
                      displayStorageDropdown && (
                        <div className="searchbulkBoxShipment scroll">
                          {storageLocationlist.length > 0 ? (
                            storageLocationlist.map((item, key) => {
                              return (
                                <div
                                  onClick={() => {
                                    inputRef.current.value = item.name;
                                    sdbValues(item, "storageLocation");
                                  }}
                                  key={item.storage_location_id}
                                >
                                  <a
                                    className="dataItem"
                                    target="_blank"
                                    href="/"
                                  >
                                    <button
                                      type="button"
                                      className="list-group-item list-group-item-action"
                                    >
                                      {item.name}
                                    </button>
                                  </a>
                                </div>
                              );
                            })
                          ) : (
                            <a className="dataItem" href="#/">
                              <p> Storage Location not exists </p>
                            </a>
                          )}
                        </div>
                      )}
                  </div> */}
              </div>
              <div className="row pt-3">
                <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                  <label htmlFor="shipment_start_date">Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="shipment_start_date"
                    name="startDate"
                    autoComplete="off"
                    value={props.formData?.step2?.startDate}
                    placeholder="mm/dd/yyyy"
                    onChange={(e) =>
                      props.onChange("step2", "startDate", e.target.value)
                    }
                  />
                  <div className="error-message-validation">
                    {/* {formErrors.shipment_start_date} */}
                  </div>
                </div>
                <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                  <label htmlFor="shipment_start_date">
                    Estimated Arrival Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    min={estimatedArrivalDate()}
                    autoComplete="off"
                    placeholder="Enter Date"
                    name="estimatedArrivalDate"
                    value={props.formData?.step2?.estimatedArrivalDate}
                    id="estimatedDate"
                    onChange={(e) =>
                      props.onChange(
                        "step2",
                        "estimatedArrivalDate",
                        e.target.value
                      )
                    }
                  />
                  <div className="error-message-validation">
                    {/* {formErrors.shipment_start_date} */}
                  </div>
                </div>
                <div className="form-group col-md-6 col-xs-12  col-lg-6 px-4">
                  <label>Shipping Notes</label>
                  <textarea
                    className="form-control"
                    name="shipping_notes"
                    id="shipping_notes"
                    onChange={(e) =>
                      props.onChange("step2", "shippingNotes", e.target.value)
                    }
                    value={props.formData?.step2?.shippingNotes}
                    placeholder="Enter Shipping Comments"
                    rows="5"
                    style={{ width: "100%" }}
                  ></textarea>
                </div>

                <div className="col-md-6 col-lg-6 col-sm-12">
                  <SearchPanel
                    label="Authorizer Assigned"
                    placeholder="Search by Employee Name or Email"
                    options={employeeOptionList}
                    setOptionList={setEmployeeOptionList}
                    setShowOptions={setAuthorizerShowOptions}
                    showOptions={authorizerShowOptions}
                    onFocusSearch={(e) => {
                      setAuthorizerSearchString("");
                      setAuthorizerShowOptions(true);
                    }}
                    handleChange={(str) => {
                      if (isAuthorizerAssigned) {
                        setAuthorizerSearchString(
                          str.target.value.charAt(str.length - 1)
                        );
                        setIsAuthorizerAssigned(false);
                        setAuthorizerShowOptions(true);
                      } else {
                        setAuthorizerSearchString(str.target.value);
                      }
                    }}
                    searchString={authorizerSearchString}
                    onSelectValue={(selectedAuthorizer) => {
                      setIsAuthorizerAssigned(true);
                      setAuthorizerShowOptions(false);
                      const authorizer = employeeList.filter(
                        (emp) =>
                          emp.employee_email ===
                          selectedAuthorizer.split(" - ")[1]
                      )[0];
                      setAuthorizerSearchString(authorizer.employee_name);
                      setAuthorizerId(authorizer.employee_id);

                      props.onChange(
                        "step2",
                        "authorizer_id",
                        authorizer.employee_id
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <PerformedBy />
    </>
  );
};

export default Section2;
