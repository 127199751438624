import { getNotifications } from "../api/NotificationsService";
import { useDispatch } from "react-redux";
import { notification } from "../redux/notification";
import { notificationService } from "../redux/notificationService";
import { useEffect } from "react";

const useFetchNotifications = (employee_id, token) => {
  const dispatch = useDispatch();
  useEffect(() => {
    getNotifications(employee_id, token)
      .then((response) => {
        if (response.status === 200) {
          dispatch(notificationService(response?.data));
        }
      })
      .catch((err) => {});
  }, []);

  return null;
};

export default useFetchNotifications;