import axios from "axios";
import service from "./service";
import {
  GET_ADDENDUM_CATEGORY,
  SAVE_FORCEOPEN,
  GET_ALL_ITEMS,
  VALIDATE_NUMBER,
} from "../constants/ApiUrlConstants";
import { getHeader } from "../utils/Headers";

export const getAddendumCategoryList = async (addendumType, token) => {
  return axios
    .get(
      service.baseUrl +
        GET_ADDENDUM_CATEGORY +
        "?addendum_type=" +
        addendumType,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response?.data;
    });
};

export const getItemsList = async (page_no, page_size, token, searchValue) => {
  return axios
    .get(
      service.baseUrl +
        GET_ALL_ITEMS +
        "?page_no=" +
        page_no +
        "&page_size=" +
        page_size +
        (searchValue ? "&search_value=" + searchValue : ""),
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response?.data;
    });
};

export const saveForceOpen = async (payload, token) => {
  return axios
    .post(service.baseUrl + SAVE_FORCEOPEN, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const validateItemNumber = async (itemNumber, itemType, token) => {
  // Function to validate the uniqueness of a safe deposit box (SDB) or tamper evident bag (TEB) number
  // `number` is the value of the SDB/TEB number being validated
  // `type` is an integer: 1 represents SDB number, 2 represents TEB number
  try {
    const response = await axios.get(
      `${service.baseUrl}${VALIDATE_NUMBER}?number=${itemNumber}&type=${itemType}`,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          // Accept any status below 500 (handle 4xx errors gracefully)
          return status < 500;
        },
      }
    );

    // Return the response data which indicates whether the item exists or not
    return response?.data;
  } catch (error) {
    console.error("Error validating item number:", error);
    throw error;
  }
};
