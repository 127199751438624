import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = { notificationCount: "" };

export const notificationSlice = createSlice({
  name: "notification",
  initialState: { value: initialStateValue },
  reducers: {
    notification: (state, action) => {
      state.value = { notificationCount: action.payload };
    },
  },
});

export const { notification } = notificationSlice.actions;

export default notificationSlice.reducer;
