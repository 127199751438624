import axios from "axios";
import { getHeader } from "../utils/Headers";

export const getGoogleStudioMockData = async (limit, token) => {
  return axios
    .get("http://localhost:8000/api/gs-mock-data/" + limit, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    });
};

export const getFSIMockData = async (limit, token) => {
  return axios
    .get("http://localhost:8000/api/fsi-mock-data/" + limit, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    });
};
