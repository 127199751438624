import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  postAddendumCategory,
  postAddendumItem,
  updateAddendumItem,
} from "../api/AddendumService";
import { useSelector } from "react-redux";

const FormInput = ({
  label,
  name,
  value,
  onChange,
  errors,
  placeholder,
  type = "text",
  disabled,
}) => (
  <div className="unique-form-group">
    <div className="unique-form-row">
      <label className="unique-form-label">{label}</label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className={`unique-form-control ${errors ? "unique-is-invalid" : ""}`}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
    {errors && (
      <div className="unique-form-row">
        <label className="unique-form-label"></label>
        <div className="unique-invalid-feedback">
          <span>{errors}</span>
        </div>
      </div>
    )}
  </div>
);

const FormSelect = ({
  label,
  name,
  value,
  onChange,
  errors,
  options,
  disabled,
}) => (
  <div className="unique-form-group">
    <div className="unique-form-row">
      <label className="unique-form-label">{label}</label>
      <select
        name={name}
        value={value}
        onChange={onChange}
        className={`unique-form-control ${errors ? "unique-is-invalid" : ""}`}
        disabled={disabled}
      >
        <option value="">Select {label}</option>
        {options?.map((opt) => (
          <option key={opt?.value} value={opt?.value}>
            {opt?.label}
          </option>
        ))}
      </select>
    </div>
    {errors && (
      <div className="unique-form-row">
        <label className="unique-form-label"></label>
        <div className="unique-invalid-feedback">
          <span>{errors}</span>
        </div>
      </div>
    )}
  </div>
);

const AddItemModal = ({
  modalMode,
  selectedItem,
  onSubmit,
  onClose,
  categories,
}) => {
  const [formData, setFormData] = useState({
    category_name: "",
    addendum_type: "",
    item_name: "",
    addendum_category_id: "",
    addendum_amount: "",
    isAmountChecked: false,
  });

  const [formErrors, setFormErrors] = useState({});

  const isAmountOriginallyChecked =
    !!selectedItem?.addendum_value && modalMode === "editItem";

  useEffect(() => {
    if (modalMode === "editItem" && selectedItem) {
      setFormData({
        category_name: "",
        addendum_type: selectedItem.addendum_category?.addendum_type || "",
        item_name: selectedItem.item_name || "",
        addendum_category_id:
          selectedItem.addendum_category?.addendum_category_id || "",
        addendum_amount: selectedItem.addendum_value || "",
        isAmountChecked: !!selectedItem.addendum_value,
      });
    } else {
      resetForm();
    }
  }, [modalMode, selectedItem]);

  const isFieldDisabled = (fieldName) => {
    if (modalMode === "editItem") {
      if (
        fieldName === "addendum_type" ||
        fieldName === "addendum_category_id"
      ) {
        return true;
      }
      if (fieldName === "isAmountChecked" && isAmountOriginallyChecked) {
        return true;
      }
    }
    return false;
  };

  const resetForm = () => {
    setFormData({
      category_name: "",
      addendum_type: "",
      item_name: "",
      addendum_category_id: "",
      addendum_amount: "",
      isAmountChecked: false,
    });
    setFormErrors({});
  };

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "addendum_type") {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
        addendum_category_id: "",
        item_name: "",
        isAmountChecked: false,
        addendum_amount: "",
      }));
    } else if (name === "addendum_category_id") {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
        item_name: "",
        isAmountChecked: false,
        addendum_amount: "",
      }));
    } else if (name === "isAmountChecked") {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
        addendum_amount: "",
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }

    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const errors = {};
    const {
      category_name,
      addendum_type,
      item_name,
      addendum_category_id,
      isAmountChecked,
    } = formData;

    if (modalMode === "addCategory") {
      if (!category_name) errors.category_name = "Category name is required.";
      if (!addendum_type) errors.addendum_type = "Addendum type is required.";
    }

    if (modalMode === "addItem" || modalMode === "editItem") {
      if (!addendum_type) errors.addendum_type = "Addendum type is required.";
      if (!item_name) errors.item_name = "Item name is required.";
      if (!addendum_category_id)
        errors.addendum_category_id = "Category is required.";
      if (isAmountChecked && !formData.addendum_amount)
        errors.addendum_amount = "Amount is required.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    Swal.fire({
      title: "Processing...",
      allowOutsideClick: false,
      onBeforeOpen: () => Swal.showLoading(),
    });

    let apiFunction;
    let payload;

    const trimValue = (value) => (typeof value === "string" ? value.trim() : value);
  
    switch (modalMode) {
      case "addCategory":
        apiFunction = postAddendumCategory;
        payload = {
          addendum_category: trimValue(formData.category_name),
          addendum_type: trimValue(formData.addendum_type),
        };
        break;

      case "addItem":
        apiFunction = postAddendumItem;
        payload = {
          addendum_type: trimValue(formData.addendum_type),
          addendum_category: trimValue(formData.addendum_category_id),
          addendum_item: trimValue(formData.item_name),
          ...(formData.isAmountChecked && {
            addendum_amount: parseFloat(trimValue(formData.addendum_amount)),
          }),
        };
        break;

      case "editItem":
        apiFunction = updateAddendumItem;
        payload = {
          addendum_item: selectedItem.addendum_item_id,
          new_value: trimValue(formData.item_name),
          ...(formData.isAmountChecked && {
            new_amount: parseFloat(trimValue(formData.addendum_amount)),
          }),
        };
        break;

      default:
        Swal.fire("Error", "Invalid mode.", "error");
        return;
    }

    try {
      const response = await apiFunction(payload, currentUser?.jwtToken);
      if (response.status === 200 || response.status === 201) {
        Swal.fire(
          "Success",
          `Successfully ${
            modalMode === "addCategory"
              ? "added category"
              : modalMode === "addItem"
              ? "added item"
              : "updated item"
          }.`,
          "success"
        );
        onSubmit(true, true);
        onClose();
      } else if (response.status === 409) {
        Swal.fire("Error", response.data.message, "error");
      } else {
        throw new Error("Failed to save data.");
      }
    } catch (error) {
      Swal.fire("Error", error.message || "Something went wrong.", "error");
      setFormErrors({ general: error.message || "Something went wrong." });
    }
  };

  return (
    <div className={`addendum-modal ${modalMode ? "visible" : ""}`}>
      <div className="addendum-modal-content">
        <div className="addendum-modal-header">
          <h4>
            {modalMode === "addCategory"
              ? "Add Category"
              : modalMode === "addItem"
              ? "Add Item"
              : "Edit Item"}
          </h4>
          <button className="addendum-close" onClick={onClose}>
            &times;
          </button>
        </div>
        <form>
          <div className="addendum-modal-body">
            {formErrors.general && (
              <div className="alert alert-danger">{formErrors.general}</div>
            )}
            {(modalMode === "addCategory" ||
              modalMode === "addItem" ||
              modalMode === "editItem") && (
              <FormSelect
                label="Addendum Type"
                name="addendum_type"
                value={formData.addendum_type}
                onChange={handleChange}
                errors={formErrors.addendum_type}
                options={[
                  { value: "addendum_a", label: "Addendum A" },
                  { value: "addendum_b", label: "Addendum B" },
                ]}
                disabled={isFieldDisabled("addendum_type")}
              />
            )}
            {modalMode === "addCategory" && (
              <FormInput
                label="Category Name"
                name="category_name"
                value={formData.category_name}
                onChange={handleChange}
                errors={formErrors.category_name}
                placeholder="Enter category name"
              />
            )}
            {(modalMode === "addItem" || modalMode === "editItem") && (
              <>
                <FormSelect
                  label="Category"
                  name="addendum_category_id"
                  value={formData.addendum_category_id}
                  onChange={handleChange}
                  errors={formErrors.addendum_category_id}
                  options={
                    formData.addendum_type
                      ? categories?.[formData.addendum_type]?.map(
                          (category) => ({
                            value: category.addendum_category_id,
                            label: category.category_name,
                          })
                        )
                      : []
                  }
                  disabled={isFieldDisabled("addendum_category_id")}
                />
                <FormInput
                  label="Item Name"
                  name="item_name"
                  value={formData.item_name}
                  onChange={handleChange}
                  errors={formErrors.item_name}
                  placeholder="Enter item name"
                />
                {formData.addendum_type === "addendum_b" && (
                  <>
                    <div className="unique-form-group">
                      <div className="unique-form-row">
                        <label
                          htmlFor="amount-checkbox"
                          className="unique-form-label"
                        >
                          Amount
                        </label>
                        <input
                          type="checkbox"
                          style={{ width: "fit-content" }}
                          name="isAmountChecked"
                          checked={formData.isAmountChecked}
                          onChange={handleChange}
                          className={`unique-form-control`}
                          disabled={isFieldDisabled("isAmountChecked")}
                          id="amount-checkbox"
                        />
                      </div>
                    </div>
                    {formData.isAmountChecked && (
                      <FormInput
                        label="Amount"
                        name="addendum_amount"
                        value={formData.addendum_amount}
                        onChange={handleChange}
                        errors={formErrors.addendum_amount}
                        placeholder="Enter amount"
                        type="number"
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className="addendum-modal-footer">
            <button
              type="button"
              className="addendum-btn addendum-btn-primary"
              onClick={handleSubmit}
            >
              {modalMode === "addCategory"
                ? "Add Category"
                : modalMode === "addItem"
                ? "Add Item"
                : "Save Changes"}
            </button>
            <button
              type="button"
              className="addendum-btn addendum-btn-secondary"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddItemModal;
