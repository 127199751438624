import { useEffect } from 'react';

export const PrintDisable = (value) => {

   

    useEffect(() => {

        
        if (!value === true) {
            const handleContextMenu = (e) => {

                // prevent  right-click acction 
                e.preventDefault()
            }
            // attach the event listener to 
            // the document object action
            document.addEventListener("contextmenu", handleContextMenu)

            // clean up the event listener when 
            // the component unmounts action
            return () => {
                document.removeEventListener("contextmenu", handleContextMenu)
            }
        }
    }, [])
   
    useEffect(() => {
        if (!value === true) {
        const handleKeyDown = (event) => {
          if (event.ctrlKey && event.key === 'p') {
            event.preventDefault();
          }
        };
    
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
    }
      }, []);
 
    
}
