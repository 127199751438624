import { MDBCard, MDBCardBody } from "mdbreact";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getHistory } from "../api/History";
import AddNotesModal from "../components/AddNotesModal";
import CustomHeader from "../components/CustomHeader";
import HistoryTimeline from "../components/HistoryTimeline";
import "../style/checkinHistory.css";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CHECKIN_HISTORY_LIMIT } from "../constants/CheckInConstants";
import { saveAs } from "file-saver";
import { ConsoleError } from "../utils/ErrorUtils";

function CheckinHistory(props) {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const [clickid, setClickId] = useState();
  const [selectedEvent, setSelectedEvent] = useState(0);
  const [checkInData, setCheckInData] = useState();
  const [tebNo, setTebNo] = useState();
  const [storageLocation, setStorageLocation] = useState();
  const [storageUnit, setStorageUnit] = useState();
  const [tote, setTote] = useState();
  const [activityType, setActivityType] = useState();
  const [notes, setNotes] = useState();
  const [documents, setDocuments] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const event = "Checkin";
  const tebId = state?.tamper_evident_bag?.tamper_evident_bag_id
    ? state?.tamper_evident_bag?.tamper_evident_bag_id
    : state?.information?.tamper_evident_bag?.tamper_evident_bag_id
    ? state?.information?.tamper_evident_bag?.tamper_evident_bag_id
    : null;

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  function setStorageLocationBasedOnType(data) {
    let storageLocation = data?.branch_storage
      ? data?.branch_storage?.branch?.name
      : data?.vault_storage
      ? data?.vault_storage?.vault?.name
      : data?.warehouse_storage?.warehouse?.name;
    setStorageLocation(storageLocation);
  }

  const getTimelineData = async () => {
    setLoader(true);
    getHistory(
      "check-in",
      tebId,
      1,
      CHECKIN_HISTORY_LIMIT,
      currentUser?.jwtToken
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          setLoader(false);
          setCheckInData(
            response?.data ? response?.data : null
          );
          setTebNo(
            response?.data[0]?.tamper_evident_bag?.tamper_evident_bag_number
          );
          setStorageLocationBasedOnType(response?.data[0]);
          setStorageUnit(response?.data[0]?.check_in_storage_type?.action);
          setTote(response?.data[0]?.warehouse_storage?.tote?.tote_number);
          setActivityType(response?.data[0]?.action?.action);
          setNotes(response?.data[0].notes);
          setDocuments(response?.data[0].documents);
          props?.eventData({ data: "data" });
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };
useEffect(() => {
  const handleCheckInData = async () => {
    setLoader(true);

    if (checkInData && checkInData.length > 0) {
      // Find the index of the event matching state?.check_in_id
      const currentEventIndex = checkInData.findIndex(
        (e) => e.check_in_id === state?.check_in_id
      );

      // Fallback to 0 if no match is found
      const resolvedIndex = currentEventIndex >= 0 ? currentEventIndex : 0;

      // Trigger the onClick function with the selected event and index
      await onClick(checkInData[resolvedIndex], resolvedIndex);
    }

    setLoader(false);
  };

  handleCheckInData();
}, [checkInData, state?.check_in_id]);
  const onPageClick = (item, index) => {
    //TO-DO
    setClickId(index);
  };
  const onClick = (item, index) => {
    setTebNo(item?.tamper_evident_bag?.tamper_evident_bag_number);
    setActivityType(item?.action?.action);
    setStorageLocationBasedOnType(item);
    setStorageUnit(item?.check_in_storage_type?.action);
    setTote(item?.warehouse_storage?.tote?.tote_number);
    setNotes(item?.notes);
    setDocuments(item?.documents);
    setClickId(index);
    setSelectedEvent(index);
  };

  const download = (link, name) => {
    saveAs(link, name);
  };

  useEffect(() => {
    getTimelineData();
    //eslint-disable-next-line
  }, [tebId]);
  return (
    <div className="col-lg-12 col-md-12 col-sm-12  p-0 ">
      <div className="row flex pt-4 pl-4 pr-4 pb-0 ">
        <div className="col-lg-12 pb-3 pl-0 pr-0 ">
          <div className="row pt-1 pb-2">
            <div className="d-flex justify-content-end">
              <div className="p-0">
                {currentUser?.check_in_save_bulk_upload_access_flag && (
                  <Link to="/customBulkCheckin">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      id="bulk-checkin-button"
                    >
                      CUSTOM BULK CHECK-IN
                    </button>
                  </Link>
                )}
              </div>
              <div className="p-0">
                {currentUser?.check_in_save_record_access_flag && (
                  <Link to="/singleCheckIn">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      id="checkin-records-button"
                    >
                      SINGLE CHECK-IN
                    </button>
                  </Link>
                )}
              </div>
              <div className="p-0">
                <Link to="/viewAllCheckIn">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    id="checkin-records-button"
                  >
                    CHECK-IN RECORDS
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <MDBCard className="card p-0 m-0  ">
            <CustomHeader label={"CHECK-IN HISTORY"} />
            {loader ? (
              <div className="text-center py-5 mt-5 alignself-center">
                <Spinner animation="border" />
              </div>
            ) : (
              <MDBCardBody id="scroll-bar">
                <div className="row text-center ">
                  <div className="col-lg-12 col-md-12 pt-4 pb-4 ">
                    <HistoryTimeline
                      historyData={checkInData}
                      onClick={onClick}
                      clickid={clickid}
                      selectedEvent={selectedEvent}
                      event={event}
                      onPageClick={onPageClick}
                    />
                  </div>
                  <div className="event-div">
                    <div className="d border border-5 event-border h-100 ml-5 mr-5">
                      <div className="row col-lg-12 justify-content-center pt-3 pb-3">
                        {showModal ? "HISTORY DETAILS" : "EVENT DETAILS"}
                      </div>{" "}
                      <div className="row col-lg-12 col-md-12 col-sm-12 ml-2 pb-3 bs-scroll">
                        <div
                          className={
                            showModal
                              ? "col-lg-6 col-md-12 col-sm-12 "
                              : "col-lg-12 col-md-12 col-sm-12  "
                          }
                        >
                          <form>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                {t("labels.teb")}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {" "}
                                  {tebNo ? tebNo : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Activity Type{" "}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {activityType ? activityType : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Storage Location{" "}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {storageLocation ? storageLocation : null}
                                </div>
                              </div>
                            </div>
                            {tote && (
                              <div className="form-group row">
                                <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                  Tote{" "}
                                </label>
                                <div className="col-sm-8 p-0">
                                  <div className="float-start">{tote}</div>
                                </div>
                              </div>
                            )}
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Storage Type{" "}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {storageUnit ? storageUnit : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Check-in Notes{" "}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start text-left">
                                  {notes ? notes : null}
                                </div>
                              </div>
                            </div>
                            {documents?.length > 0 && (
                              <div className="form-group row">
                                <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                  Documents{" "}
                                </label>
                                <div className="col-sm-8 p-0">
                                  {documents?.filter(
                                    (document) => document?.type === "DOCUMENT"
                                  ).length > 0 && (
                                    <div className="row ">
                                      <div className="col-8 fw-bold d-flex pl-3">
                                        <span className="float-start">
                                          Uploaded Document Name
                                        </span>
                                      </div>
                                      <div className="col-4 fw-bold">
                                        Description
                                      </div>
                                    </div>
                                  )}
                                  {documents
                                    ?.filter(
                                      (document) =>
                                        document?.type === "DOCUMENT"
                                    )
                                    .map((filteredDocument) => (
                                      <div className="row">
                                        <div className="col-8 d-flex">
                                          <span
                                            className="cursor-pointer float-start"
                                            onClick={() =>
                                              download(
                                                filteredDocument?.url,
                                                filteredDocument?.name
                                              )
                                            }
                                          >
                                            {filteredDocument?.name}
                                            <i className="fa fa-download pl-2" />
                                          </span>
                                        </div>
                                        <div className="col-4 ">
                                          {filteredDocument?.description}
                                        </div>
                                      </div>
                                    ))}
                                  {documents?.filter(
                                    (document) => document?.type === "PICTURE"
                                  ).length > 0 && (
                                    <div className="row pt-3">
                                      <div className="col-8 fw-bold d-flex pl-3">
                                        <span className="float-start">
                                          Added Picture Name
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {documents
                                    ?.filter(
                                      (document) => document?.type === "PICTURE"
                                    )
                                    .map((filteredPicture) => (
                                      <div className="row">
                                        <div className="col-8 d-flex">
                                          <span
                                            className="cursor-pointer float-start"
                                            onClick={() =>
                                              download(
                                                filteredPicture?.url,
                                                filteredPicture?.name
                                              )
                                            }
                                          >
                                            {filteredPicture?.name}
                                            <i className="fa fa-download pl-2" />
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}
                          </form>
                        </div>
                        {showModal ? (
                          <AddNotesModal
                            modal={(showModal) => setShowModal(showModal)}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </MDBCardBody>
            )}
          </MDBCard>
        </div>
      </div>
    </div>
  );
}
export default CheckinHistory;
