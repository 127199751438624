export const FormReducer = (state, { step, key, payload, type }) => {
  switch (type) {
    case "UPDATE_VALUE":
      state[step][key] = payload.newValue;
      break;
    case "UPDATE_CHILD_VALUE":
      state[step][key.split(".")[0]][key.split(".")[1]] = payload.newValue;
      break;
    case "ADD_ADDENDUM_A_B":
      state[step].push(payload.newValue);
      break;
    case "DELETE_ADDENDUM_A_B":
      state[step].splice(
        state[step].findIndex((item) => item.id === payload.newValue),
        1
      );
      break;
    case "UPDATE_ADDENDUM_A_B":
      let indexOfState = state[step].findIndex(
        (item) => item.id === payload.newValue.id
      );
      state[step][indexOfState][key] = payload.newValue.value;
      break;
    case "UPDATE_ADDENDUM_DOCUMENT_COMMENT":
      let index = state[step].findIndex(
        (item) => item.id === payload.newValue.id
      );
      let documentIndex = state[step][index][key].findIndex(
        (item) => item.documentId === payload.newValue.documentId
      );
      state[step][index][key][documentIndex]["comments"] =
        payload.newValue.value;
      break;
    case "UPDATE_PARENT_VALUE":
      state[step] = payload.newValue;
      break;
    case "DELETE":
      state[step].splice(
        state[step].findIndex((item) => item === payload.newValue),
        1
      );
      break;
    case "DELETE_AUTHORIZER_PAYLOAD":
      const id = payload.newValue;
      state[step] = state[step].filter((item) => item.employee_id !== id);
      break;
    default:
      break;
  }
  return;
};

export const getItemName = (itemId, itemList) => {
  return itemList[
    itemList.findIndex((item) => item?.addendum_item_id === itemId)
  ]?.item_name;
};
export const getItemValue = (itemId, itemList) => {
  return itemList[
    itemList.findIndex((item) => item?.addendum_item_id === itemId)
  ]?.addendum_value;
};
export const getCategoryName = (categoryId, categoryList) => {
  const value =
    categoryList[
      categoryList.findIndex(
        (item) => item?.addendum_category_id === categoryId
      )
    ]?.category_name;
  return value;
};
