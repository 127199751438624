import React from "react";
import {
  FORCE_OPEN_CONFIRMATION_MSG_1,
  FORCE_OPEN_CONFIRMATION_MSG_2,
} from "../../constants/MessagesConstants";
import { useDispatch } from "react-redux";
import { useFormContext } from "../../context/FormContext";
import { forceOpenData } from "../../redux/forceOpen";
import { PrintDisable } from "../../utils/PrintDisable";
import { useSelector } from "react-redux";

const Confirmation = () => {
  const { state, handleChange } = useFormContext();
  const dispatch = useDispatch();

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  PrintDisable(currentUser?.print_access_flag);

  const handlePrintPreview = () => {
    window.open("/preview", "_blank");
    dispatch(forceOpenData(state));
  };
  const handleAddDocument = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        const docSrcArray = reader.result.split(";")[0];
        let val = document.getElementById("upload_form");
        let file = val.files.item(0).name;
        const documentItem = {
          src: reader.result,
          docType: docSrcArray,
          fileName: file,
        };
        handleChange("upload_form", null, documentItem, "UPDATE_PARENT_VALUE");
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };  

  return (
    <>
      <div className="row justify-content-center">CONFIRMATION</div>
      <div className="card-border">
        <p id="p-input">{FORCE_OPEN_CONFIRMATION_MSG_1}</p>
        <p id="p-input">{FORCE_OPEN_CONFIRMATION_MSG_2}</p>
        <div className="row col-12 justify-content-center pt-5 mt-5">
          <div className="col-6 text-right">
            <button
              type="button"
              className="btn  btn-sm btn-primary"
              onClick={handlePrintPreview}
            >
              Print
            </button>
          </div>
          <div className="col-3">
            <input
              type="file"
              id="upload_form"
              multiple
              style={{ display: "none" }}
              onChange={(e) => {
                handleAddDocument(e);
              }}
              onClick={(event) => {
                event.target.value = null;
              }}
            ></input>
            <label
              htmlFor="upload_form"
              className="btn btn-primary btn-sm"
              style={{ cursor: "pointer" }}
            >
              {" "}
              <i
                id={`icon_upload`}
                className="fa fa-upload fa-1x"
                aria-hidden="true"
              ></i>{" "}
              Upload Form
            </label>
          </div>
          <div className="col-3">
            {state?.upload_form?.fileName ? (
              <>
                {" "}
                <span className="fw-bold">File Name : </span>
                <span>{state?.upload_form?.fileName}</span>
              </>
            ) : (
              <div className="error-message-validation mx-5  mt-2">
                <p>Please upload the form</p>
                </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
