import React, { useState } from "react";
import { Navbar } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import "../style/header.css";
import NotificationModal from "../pages/NotificationModal";
import { cognitoSignOut } from "../api/CognitoServices";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../redux/user";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Badge } from "../components/Badge";
import { ALL_USER, SUPERADMIN_USER } from "../constants/RoleContants";

function Header(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const signOut = () => {
    dispatch(logout());
    cognitoSignOut().catch((err) => {
      navigate("/");

      console.error(err);
    });
    navigate("/");
  };
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalTitle, setmodalTitle] = useState();
  const [modalData, setmodalData] = useState();
  //eslint-disable-next-line
  const [elementindex, setIndex] = useState();

  const notificationCount = useSelector(
    (reduxData) =>
      reduxData?.notificationServiceReducer?.value?.notificationData.length
  );
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  function openNotifications(e) {
    setIsOpen(true);
    setmodalTitle("NOTIFICATIONS");
    setmodalData(e);
  }
  function handleCloseModal(event, data) {
    setIsOpen(false);
  }

  function openConfigurations() {
    navigate("/admin");
  }
  function openChangePassword() {
    navigate("/change-password");
  }
  const navigateBasedonRole =()=>{
    if (currentUser?.role === "safechief_admin") {
      navigate("/all-tenants");
    } else {
      navigate("/home");
    }
  }
  return (
    <div className="container-fluid ">
      <div className="row flex ">
        {" "}
        <Navbar className="header">
          <Nav.Link  className="p-0">
            <Navbar.Brand className="brand" onClick={navigateBasedonRole}> {t("app.title")}</Navbar.Brand>
          </Nav.Link>
          <Nav className="me-auto"></Nav>
          <Nav>
            {notificationCount > 0 && ALL_USER.includes(currentUser?.role) && (
              <Nav.Link onClick={() => openNotifications()}>
                <i
                  className="fa fa-bell px-3 text-white"
                  aria-hidden="true"
                ></i>
                <Badge count={notificationCount}></Badge>
              </Nav.Link>
            )}
            {SUPERADMIN_USER.includes(currentUser?.role) && (
              <Nav.Link onClick={() => openConfigurations()}>
                <i
                  className="fa fa-cog pl-3 text-white h4"
                  aria-hidden="true"
                ></i>
                <span className="fw-bold text-white px-2">
                  Product Configuration
                </span>
              </Nav.Link>
            )}
            <Nav.Link onClick={() => openChangePassword()}>
              <i
                className="fa fa-lock pl-3 text-white h4"
                aria-hidden="true"
              ></i>
              <span className="fw-bold text-white px-2 change">
                Change Password
              </span>
            </Nav.Link>
            <Nav.Link eventKey={2} onClick={signOut}>
              <i
                className="fa fa-sign-out pl-3 text-white h4"
                aria-hidden="true"
              ></i>
              <span className="fw-bold text-white px-2">Sign out</span>
            </Nav.Link>
          </Nav>
        </Navbar>
        <NotificationModal
          dynData={modalTitle}
          data={modalData}
          IsModalOpened={modalIsOpen}
          index={elementindex}
          onCloseModal={handleCloseModal}
          isAdmin={props.path}
        />
      </div>
    </div>
  );
}

export default Header;
