import React, { useState,useEffect } from "react";
import ViewUsers from "../components/ViewUsers";
import IRPData from "../components/ProductConfiguration/IRPData";
import { PRODUCT_CONFIGURATION_TAB_ITEMS } from "../constants/ProductConfiguration";
import CustomHeader from "../components/CustomHeader";
import { useLocation } from "react-router-dom";
import AddAddendums from "../components/AddAddendums";
import Roles from "../components/Roles"
import EditAuthorization from "../components/EditAuthorization";
import ConfigureDates from "../components/ConfigureDates";
import ConfigureEditFields from "../components/ProductConfiguration/ConfigureEditFields";

const ProductConfiguration = () => {
  const [activeTab, setActiveTab] = useState(1);
  const { state } = useLocation();

  useEffect(() => {
    if (state?.activeTab != null) {
      setActiveTab(state?.activeTab)
      state.activeTab = null
      
    }
}, [])

  return (
    <>
      <div className="d-md-flex align-items-center justify-content-center px-0 row pt-5 mx-1">
        <div className="align-items-center pt-5">
          <div className="row white-background">
            <div className="col-md-12 px-0">
              <CustomHeader label={"Product Configuration"} />
            </div>
          </div>
          <div className="row white-background px-3">
            <ul className="nav nav-tabs justify-content-left white-background">
              {PRODUCT_CONFIGURATION_TAB_ITEMS.map((tabItem, index) => {
                return (
                  <li
                    className={`nav-item ${activeTab === tabItem.value ? "active" : ""
                      }`}
                    key={index}
                  >
                    <button
                      className={`nav-link ${activeTab === tabItem.value ? "text-white" : ""
                        }`}
                      aria-current="page"
                      onClick={(e) => setActiveTab(tabItem.value)}
                    >
                      {tabItem.label}
                    </button>
                  </li>

                );
              })}
            </ul>
            {activeTab === 1 ? <ViewUsers></ViewUsers> : null}
            {activeTab === 2 ? <IRPData></IRPData> : null}
            {activeTab === 3 ? <AddAddendums></AddAddendums> : null}
            {activeTab === 4 ? <Roles></Roles> : null}
            {activeTab === 5 ? <EditAuthorization></EditAuthorization> : null}
            {activeTab === 6 ? <ConfigureDates></ConfigureDates> : null}
            {activeTab === 7 ? <ConfigureEditFields/> : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductConfiguration;
