import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";
import {
  GET_ALL_EMPLOYEES,
  GET_ALL_EMPLOYEE_DESIGNATION,
  UPDATE_EMPLOYEE_DESIGNATION,
  UPDATE_ROLE_FLAG
} from "../constants/ApiUrlConstants";

export const getEmployeesOnsearch = async (
  approvalAccessFlag,
  authorizerAccessFlag,
  auditAccessFlag,
  searchValue,
  token,
  pageNo = 1,
  perPage = 10
) => {
  // Construct the base URL
  let url =
    service.baseUrl +
    GET_ALL_EMPLOYEES +
    "?search_value=" +
    encodeURIComponent(searchValue || "") +
    (approvalAccessFlag
      ? "&approval_access_flag=" + approvalAccessFlag
      : "") +
    (auditAccessFlag
      ? "&audit_access_flag=" + auditAccessFlag
      : "") +
    (authorizerAccessFlag
      ? "&authorizer_access_flag=" + authorizerAccessFlag
      : "") +
    `&page_no=${pageNo}&per_page=${perPage}`;

  // Perform the GET request
  return axios
    .get(url, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500; // Allow handling errors gracefully
      },
    })
    .then((response) => {
      if (response?.status === 200) {
        return response.data;
      } else {
        throw new Error(response?.data?.message || "Error fetching employees");
      }
    })
    .catch((error) => {
      console.error("Error in getEmployeesOnsearch:", error.message);
      throw error;
    });
};

export const getEmployeesDesignationType = async (token) => {
  let url = service.baseUrl + GET_ALL_EMPLOYEE_DESIGNATION;

  return axios
    .get(url, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response?.data;
    });
};

export const updateEmployeesDesignationType = async (payload, token) => {
  let url = service.baseUrl + UPDATE_EMPLOYEE_DESIGNATION;

  return axios
    .post(url, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response?.data;
    });
};

export const updateEmployeesRoleFlag = async (payload, token) => {

  return axios
    .put(service.baseUrl +
      UPDATE_ROLE_FLAG, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response?.data;
    });
};