import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import CustomHeader from "../components/CustomHeader";
import { addUser } from "../api/CognitoServices";
import { updateEmployeesDesignationType } from "../api/EmployeeService";
import "../style/addUser.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const validateField = (name, value) => {
  const validations = {
    employee_name: (v) => !v && "Name is required.",
    employee_email: (v) =>
      !v ? "Email is required." : !/\S+@\S+\.\S/.test(v) && "Email is invalid.",
    employee_designation: (v) => !v && "Designation is required.",
    // need to enable phone number validation 
    // phone_number: (v) =>
    //   !v
    //     ? "Phone number is required."
    //     : !/^\d+$/.test(v) && "Phone number must be numeric.",
  };

  return validations[name]?.(value) || "";
};

const FormInput = ({
  label,
  name,
  value,
  onChange,
  errors,
  placeholder,
  type = "text",
  disabled,
}) => (
  <div className="form-group row">
    <label className="col-sm-2 col-form-label">{label}</label>
    <div className="col-sm-10 col-md-8">
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className={`form-control ${errors ? "is-invalid" : ""} w-100`}
        placeholder={placeholder}
        disabled={disabled}
      />
      {errors && <div className="invalid-feedback">{errors}</div>}
    </div>
  </div>
);

export default function AddUserModal({
  isOpen,
  roles,
  onClose,
  isEdit,
  currentUserData,
}) {
  const [formData, setFormData] = useState({
    employee_name: "",
    employee_email: "",
    employee_designation: "",
    phone_number: "",
    country_code: "+1",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const resetForm = useCallback(() => {
    setFormData({
      employee_name: "",
      employee_email: "",
      employee_designation: "",
      phone_number: "",
      country_code: "+1",
    });
    setErrors({});
  }, []);

  useEffect(() => {
    if (isEdit && currentUserData) {
      const { employee_name = "", employee_email = "", employee_designation_configuration } = currentUserData;
      const fullPhoneNumber = currentUserData?.employee_phone_number || ""; 
      const [countryCode = "+1", ...phoneParts] = fullPhoneNumber.split("-");
      
      setFormData({
        employee_name,
        employee_email,
        employee_designation: employee_designation_configuration?.employee_designation_id || "",
        phone_number: `${countryCode}${phoneParts.join("-")}`,
        country_code: countryCode,
      });
    } else {
      resetForm();
    }
  }, [isEdit, currentUserData, resetForm]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handlePhoneChange = (value, data) => {
    const countryCode = `+${data.dialCode}`; // Extract country code
    const phoneNumber = `+${value}`
    
    setFormData((prev) => ({
      ...prev,
      phone_number: phoneNumber,
      country_code: countryCode,
    }));
  
    setErrors((prev) => ({ ...prev, phone_number: "" }));
  };

  const validateForm = () => {
    const newErrors = Object.keys(formData).reduce((acc, field) => {
      const error = validateField(field, formData[field]);
      if (error) acc[field] = error;
      return acc;
    }, {});
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setLoading(false);
      return;
    }

    try {
      const response = isEdit
        ? await updateEmployeesDesignationType(
            {
              employee_designation_id: formData.employee_designation,
              employee_id: currentUserData?.employee_id,
            },
            currentUser?.jwtToken
          )
        : await addUser(
            formData,
            currentUser?.jwtToken
          );

      if (
        response?.status === 200 ||
        response?.status === 201 ||
        response?.status === "SUCCESS"
      ) {
        Swal.fire({
          icon: "success",
          title: isEdit ? "Updated!" : "Added!",
          text: isEdit
            ? "User details updated successfully."
            : "User added successfully.",
        });
        resetForm();
        onClose(true);
      } else if (response && response.status === 409) {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text:
            response?.data?.message,
        });
      } else {
        throw new Error(
          response?.data?.message || "Unexpected response from the server."
        );
      }
    } catch (error) {
      console.error("Error handling user:", error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text:
          error?.response?.data?.message ||
          "Failed to handle user. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="adduser_modal" style={{zIndex:"2"}}>
      <div className="adduser_modal-content add-user-header">
        <CustomHeader label={isEdit ? "EDIT USER" : "ADD USER"} />
        {loading ? (
          <div className="text-center py-5 mt-5 align-self-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <FormInput
              label="Name"
              name="employee_name"
              value={formData.employee_name}
              onChange={handleChange}
              errors={errors.employee_name}
              placeholder="Name"
              disabled={isEdit}
            />
            <FormInput
              label="Email"
              name="employee_email"
              value={formData.employee_email}
              onChange={handleChange}
              errors={errors.employee_email}
              type="email"
              placeholder="Email"
              disabled={isEdit}
            />
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Role</label>
              <div className="col-sm-10 col-md-8">
                <select
                  name="employee_designation"
                  value={formData.employee_designation}
                  onChange={handleChange}
                  className={`form-control ${
                    errors.employee_designation ? "is-invalid" : ""
                  } w-100`}
                >
                  <option value="">Select Role</option>
                  {roles.map((role) => (
                    <option
                      key={role.employee_role_id}
                      value={role.employee_role_id}
                    >
                      {role.role_name}
                    </option>
                  ))}
                </select>
                {errors.employee_designation && (
                  <div className="invalid-feedback">
                    {errors.employee_designation}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Phone Number</label>
              <div className="col-sm-10 col-md-8">
                <PhoneInput
                  country={"us"} // Default country
                  value={formData.phone_number}
                  onChange={handlePhoneChange}
                  placeholder="Enter phone number"
                  inputClass={`w-100 ${
                    errors.phone_number ? "is-invalid" : ""
                  }`}
                  buttonClass="mr-2"
                  dropdownClass="custom-phone-dropdown"
                  enableSearch
                  disabled={isEdit}
                  inputProps={{
                    name: "phone_number",
                  }}
                />
                {errors.phone_number && (
                  <div className="invalid-feedback d-block">
                    {errors.phone_number}
                  </div>
                )}
              </div>
            </div>
            <div className="modal-actions add_user_btngrp">
              <button type="submit" className="btn btn-primary">
                {isEdit ? "Update" : "Submit"}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => onClose(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
