export const SHIPMENT_HISTORY_LIMIT = 20;
export const SHIPMENT_TEB_LIMIT = 20;
export const SAVE_SHIPMENT_PAYLOAD = {
  tamper_evident_bags: [],
  start_date: "",
  estimated_date: "",
  from_location: "",
  to_location: "",
  notes: "",
  pictures: [],
  documents: [],
  performed_by_id: "",
  authorizer_id: [],
  from_storage_type: "",
  to_storage_type: "",
};

export const SHIPPING_HISTORY_LIMIT = 20;

export const MAX_START_DATE = 1;

export const MIN_START_DATE = -6;

export const MAX_ESTIMATED_DATE = 1;

export const MIN_ESTIMATED_DATE = -6;
