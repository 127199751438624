export const awsconfig = {
  aws_project_region: process.env.REACT_APP_REGION,
  aws_cognito_region: process.env.REACT_APP_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_REGION,
    scope: ["email", "openid", "phone"],
    redirectSignIn: process.env.REACT_APP_URL,
    redirectSignOut: process.env.REACT_APP_URL,
    responseType: "token",
  },
};
