import { MDBCard } from "mdbreact";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { useImmerReducer } from "use-immer";
import { saveForceOpen, validateItemNumber } from "../api/forceOpenService";
import CustomHeader from "../components/CustomHeader";
import AddAuthorizer from "../components/ForceOpenComponents/AddAuthorizer";
import AddendumA from "../components/ForceOpenComponents/AddendumA";
import AddendumB from "../components/ForceOpenComponents/AddendumB";
import AddendumC from "../components/ForceOpenComponents/AddendumC";
import Confirmation from "../components/ForceOpenComponents/Confirmation";
import Section1 from "../components/ForceOpenComponents/Section1";
import Section2 from "../components/ForceOpenComponents/Section2";
import Section3 from "../components/ForceOpenComponents/Section3";
import Section4 from "../components/ForceOpenComponents/Section4";
import CustomStepper from "../components/StepperComponent";
import { CustomAlert } from "../components/customAlert";
import {
  FORCE_OPEN_DEAULT_STATE,
  FORCE_OPEN_STEPS,
} from "../constants/ForceOpenConstants";
import { FormProvider } from "../context/FormContext";
import "../style/ForceOpen.css";
import { FormReducer } from "../utils/ForceOpenUtils";
import { ERROR_MESSAGE } from "../constants/MessagesConstants";
import { ConsoleError } from "../utils/ErrorUtils";
import useFetchNotifications from "../utils/NotificationUtils";
import Addendums from "../components/ForceOpenComponents/Addendums";
import { getAuthorizationDataModule } from "../api/AuthorizationConfigurationService";
import { regexZipCode, regexName } from "../constants/Regex";
import { MODULES } from "../constants/Constants";

const PreData = () => {
  const { state } = useLocation();
  return state;
};

const ForceOpenStepper = (props) => {
  const [activeStep, setActiveStep] = useState(
    props?.activeStep ? props?.activeStep : 0
  );
  const [formErrors, setFormErrors] = useState({});
  const [state, dispatch] = useImmerReducer(
    FormReducer,
    FORCE_OPEN_DEAULT_STATE
  );
  const preData = PreData();

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const notificationResponse = useFetchNotifications(
    currentUser?.employee_id,
    currentUser?.jwtToken
  );

  const [isAuthroization, setIsAuthorization] = useState(true);
  const [moduleData, setModuleData] = useState();
  const [forceOpenSteps, setForceOpenSteps] = useState([]);
  const [validatedTEBNumbers, setValidatedTEBNumbers] = useState([]); // Holds the IDs of validated TEB numbers

  const getAuthorizationFlag = async () => {
    getAuthorizationDataModule(MODULES.FORCE_OPEN, currentUser?.jwtToken).then(
      (response) => {
        if (response?.status === 200) {
          setIsAuthorization(response?.data?.authorization_flag);
          setModuleData(response?.data);
        }
      }
    );
  };

  useEffect(() => {
    getAuthorizationFlag();
  }, []);

  useEffect(() => {
    if (!isAuthroization) {
      setForceOpenSteps(
        FORCE_OPEN_STEPS.filter((item) => item !== "ADD AUTHORIZER")
      );
      handleChange(
        "authorizer_list",
        null,
        [currentUser?.employee_id],
        "UPDATE_PARENT_VALUE"
      );
      handleChange(
        "authorizer_name",
        null,
        [currentUser?.employee_name],
        "UPDATE_PARENT_VALUE"
      );
    } else {
      setForceOpenSteps(FORCE_OPEN_STEPS);
    }
  }, [isAuthroization]);

  /**
   * Function to update the state
   * @param {string} step - step is the section of the force open
   * @param {string} name - name of the field
   * @param {string / number / array} newValue - value of the field
   */
  const handleChange = useCallback(
    (step, name, value, type) => {
      let newValue = typeof value === "string" ? value.trim() : value;
      dispatch({
        step: step,
        key: name,
        payload: { newValue },
        type: type,
      });
    },
    [dispatch]
  );

  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // do not submit form
    let section = "confirmation";
    let errors = validate(section);
    setFormErrors(errors);
    if (
      activeStep !== forceOpenSteps.length - 1 ||
      Object.keys(errors).length > 0
    ) {
      return false;
    }

    Swal.fire({
      title: "Processing, please wait",
      html: "loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    saveForceOpen(state, currentUser?.jwtToken)
      .then((response) => {
        Swal.close();
        if (response.status === 201) {
          showSuccessAlert("Force Open Saved Successfully");
        } else {
          showFailAlert("Force Open Failed");
        }
      })
      .catch((error) => {
        showFailAlert(ERROR_MESSAGE);
        ConsoleError(error);
      });
  };
  const showSuccessAlert = (response) => {
    let title = "Saved";
    let text = response;
    let type = "success";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      target
    ).then((result) => {
      if (result.value) {
        navigate("/home");
      }
    });
  };
  // Reusable validation function for TEB and SDB number uniqueness
  const validateNumberUniqueness = async (number, type, fieldName) => {
    try {
      if (number) {
        const response = await validateItemNumber(
          number,
          type,
          currentUser?.jwtToken
        ); // Use the reusable API call
        if (response.exists) {
          return `${fieldName} already exists`;
        }
        return null; // No errors if the number is unique
      }
    } catch (error) {
      console.error(`Error validating ${fieldName}:`, error);
      return `Failed to validate ${fieldName}`;
    }
  };

  const validate = (section) => {
    resetErrors();
    const errors = {};
    const promises = [];
    setValidatedTEBNumbers([]);
    if (section === "section1") {
      if (state?.section1?.branch_name.length < 1) {
        errors.branch = "Branch is required";
      }
      if (state?.section1?.force_open_date.length < 1) {
        errors.force_open_date = "Force open date is required";
      }
      if (state?.section1?.safe_deposit_box_number.length < 1) {
        errors.safe_deposit_box_number = "Safe deposit box number is required";
      } else {
        // Create a new promise for the async API call
        const sdbPromise = validateNumberUniqueness(
          state.section1.safe_deposit_box_number,
          1,
          "Safe deposit box number"
        )
          .then((sdbError) => {
            if (sdbError) {
              errors.safe_deposit_box_number = sdbError; // Set error if found
            }
          })
          .catch((error) => {
            console.error("Error during validation:", error);
            errors.safe_deposit_box_number =
              "An error occurred during validation";
          });

        // Push this promise to the array
        promises.push(sdbPromise);
      }
      if (state?.section1?.contract_expiration.length < 1) {
        errors.contract_expiration = "Contract expiration date is required";
      }
      if (state?.section1?.last_rental_payment.length < 1) {
        errors.last_rental_payment = "Last rental payment date is required";
      }
      if (state?.section1?.mystery_box === false) {
        if (state?.section1?.owner?.first_name.length < 1) {
          errors.first_name = "First name is required";
        }
        if (
          state?.section1?.owner?.first_name.length > 0 &&
          !regexName.test(state?.section1?.owner?.first_name)
        ) {
          errors.first_name = "Please provide valid first name";
        }
        if (state?.section1?.owner?.last_name.length < 1) {
          errors.last_name = "Last name is required";
        }
        if (
          state?.section1?.owner?.last_name.length > 0 &&
          !regexName.test(state?.section1?.owner?.last_name)
        ) {
          errors.last_name = "Please provide valid last name";
        }
       // Validate Owner's SSN
        const ownerSSN = state?.section1?.owner?.ssn;
        if (!ownerSSN || ownerSSN.length < 1) {
          errors.ssn = "Owner SSN is required";
        } else if (ownerSSN.length !== 9) { 
          errors.ssn = `Owner SSN must be 9 digits long`;
        } else if (!/^\d+$/.test(ownerSSN)) { 
          errors.ssn = "Owner SSN must contain only numbers";
        }

        // Validate Co-Owner's SSN
        const coOwnerSSN = state?.section1?.co_owner?.ssn;
        if (coOwnerSSN) {
          if (coOwnerSSN.length !== 9) { 
            errors.co_owner_ssn = `Co-Owner SSN must be 9 digits long`;
          } else if (!/^\d+$/.test(coOwnerSSN)) { 
            errors.co_owner_ssn = "Co-Owner SSN must contain only numbers";
          }
          
          // Check if Owner and Co-Owner have the same SSN
          if (ownerSSN === coOwnerSSN) {
            errors.ssn = "Owner and Co-Owner cannot have the same SSN";
            errors.co_owner_ssn = "Owner and Co-Owner cannot have the same SSN";
          }
        }
        if (state?.section1?.owner?.street_1.length < 1) {
          errors.street = "Street is required";
        }
        if (state?.section1?.owner?.city.length < 1) {
          errors.city = "City is required";
        }
        if (state?.section1?.owner?.state.length < 1) {
          errors.state = "State is required";
        }
        if (state?.section1?.owner?.zipcode.length < 1) {
          errors.zip = "Zip is required";
        }
        if (
          state?.section1?.owner?.zipcode.length > 0 &&
          !regexZipCode.test(state?.section1?.owner?.zipcode)
        ) {
          errors.zip = "Please provide valid zip code";
        }
        if (state?.section1?.owner?.owner_relation_code.length < 1) {
          errors.owner_relation_code = "Relation code is required";
        }
        if (state?.section1?.owner?.owner_relation_code.length === 1) {
          errors.owner_relation_code = "Please provide valid relation code";
        }
        if (state?.section1?.owner?.owner_property_type_code.length < 1) {
          errors.owner_property_type_code = "Property type code is required";
        }
        if (state?.section1?.owner?.owner_property_type_code.length === 1) {
          errors.owner_property_type_code =
            "Please provide valid property type code";
        }
        if (state?.section1?.owner?.dormancy_id.length < 1) {
          errors.dormancy_id = "Dormancy is required";
        }
        if (
          state?.section1?.owner?.dormancy_id.length > 1 &&
          state?.section1?.owner?.dormancy_id.length < 4
        ) {
          errors.dormancy_id = "Please provide valid dormancy id";
        }
        if (state?.section1?.owner?.company_name.length < 1) {
          errors.company_name = "Company name is required";
        }
      }
    }
    if (section === "section2") {
      if (state?.section2?.box_drilled_status === false) {
        if (state?.section2?.box_not_drilled_reason_id.length < 1) {
          errors.box_not_drilled_reason = "Please select a reason";
        }
      }
      if (state?.section2?.box_drilled_status === true) {
        if (state?.section2?.box_drilled_type.length < 1) {
          errors.drill_type = "Please select drill type";
        }
      }
    }
    if (section === "section3") {
      if (state?.section1?.notary_name.length < 1) {
        errors.notary_name = "Notary name is required";
      }
      if (state?.section1?.locksmith_name.length < 1) {
        errors.locksmith_name = "Locksmith name is required";
      }
    }
    if (section === "addendum") {
      const addendumPayloadA = addendumABPayload("addendum_a");
      if (addendumPayloadA.teb.length > 0) {
        errors.teb_number = "Teb Number is required";
      }
      if (addendumPayloadA.teb_numbers.length > 0) {
        // Validate each TEB number using the API
        const tebPromises = addendumPayloadA.teb_numbers.map((teb) => {
          return validateNumberUniqueness(teb.teb_number, 2, "TEB number")
            .then((tebError) => {
              if (tebError) {
                errors.teb_number_exists = tebError; // Set error if the TEB number already exists
                setValidatedTEBNumbers((prev) => [...prev, teb?.id]); // Store ID and TEB number if needed
              }
            })
            .catch((error) => {
              console.error(
                `Error during validation for TEB number ${teb.teb_number}:`,
                error
              );
              errors.teb_number_exists = "An error occurred during validation";
            });
        });

        promises.push(...tebPromises);
      }
      if (addendumPayloadA.category.length > 0) {
        errors.category = "Please select category";
      }
      if (addendumPayloadA.item.length > 0) {
        errors.item = "Please select item";
      }
      if (addendumPayloadA.quantity.length > 0) {
        errors.quantity = "Please enter quantity";
      }
      const addendumPayloadB = addendumABPayload("addendum_b");
      if (addendumPayloadB.teb.length > 0) {
        errors.teb_number = "Teb Number is required";
      }
      if (addendumPayloadB.teb_numbers.length > 0) {
        // Validate each TEB number using the API
        const tebPromises = addendumPayloadB.teb_numbers.map((teb) => {
          return validateNumberUniqueness(teb.teb_number, 2, "TEB number")
            .then((tebError) => {
              if (tebError) {
                errors.teb_number_exists = tebError; // Set error if the TEB number already exists
                setValidatedTEBNumbers((prev) => [...prev, teb?.id]); // Store ID and TEB number if needed
              }
            })
            .catch((error) => {
              console.error(
                `Error during validation for TEB number ${teb.teb_number}:`,
                error
              );
              errors.teb_number_exists = "An error occurred during validation";
            });
        });

        promises.push(...tebPromises);
      }
      if (addendumPayloadB.category.length > 0) {
        errors.category = "Please select category";
      }
      if (addendumPayloadB.item.length > 0) {
        errors.item = "Please select item";
      }
      if (addendumPayloadB.quantity.length > 0) {
        errors.quantity = "Please enter quantity";
      }
      const addendumPayloadC = addendumCPayload();
      if (addendumPayloadC.teb.length > 0) {
        errors.teb_number = "Teb Number is Required";
      }
      if (addendumPayloadC.teb_numbers.length > 0) {
        // Validate each TEB number using the API
        const tebPromises = addendumPayloadC.teb_numbers.map((teb) => {
          return validateNumberUniqueness(teb.teb_number, 2, "TEB number")
            .then((tebError) => {
              if (tebError) {
                errors.teb_number_exists = tebError; // Set error if the TEB number already exists
                setValidatedTEBNumbers((prev) => [...prev, teb?.id]); // Store ID and TEB number if needed
              }
            })
            .catch((error) => {
              console.error(
                `Error during validation for TEB number ${teb.teb_number}:`,
                error
              );
              errors.teb_number_exists = "An error occurred during validation";
            });
        });

        promises.push(...tebPromises);
      }
      if (addendumPayloadC.title.length) {
        errors.title = "Please Enter a Title";
      }
      if (addendumPayloadC.quantity.length > 0) {
        errors.quantity = "Please Enter Quantity";
      }
      if (addendumPayloadC.stock.noOfShares.length > 0) {
        errors.number_of_shares = "Please Enter no. of shares";
      }
      if (addendumPayloadC.other.bondValue.length > 0) {
        errors.value_of_bond = "Please Enter Bond Value";
      }
    }
    if (section === "authoriser") {
      if (state?.authorizer_list.length < 1) {
        errors.authoriser = "Please add an authoriser";
      }
    }
    if (section === "confirmation") {
      if (!state?.upload_form?.fileName) {
        errors.upload_form = "Please upload the form";
      }
    }

    return Promise.all(promises).then(() => {
      console.log("Validation completed, errors:", errors);
      return errors; // Now errors object is fully updated after all async calls
    });
  };
  const addendumABPayload = (type) => {
    let tebIndices = [];
    let tebErrors = []; // Array for invalid TEBs
    let categoryIndices = [];
    let itemIndices = [];
    let quantityIndices = [];
    for (var i = 0; i < state[type].length; i++) {
      var addendumItem = state[type][i];

      if (addendumItem?.tamper_evident_bag_number.length < 1) {
        tebIndices.push(addendumItem?.id);
      } else {
        tebErrors.push({
          teb_number: addendumItem?.tamper_evident_bag_number,
          id: addendumItem?.id,
        }); // Collect the TEB number for validation
      }

      if (addendumItem?.addendum_category_id.length < 1) {
        categoryIndices.push(addendumItem?.id);
      }

      if (addendumItem?.addendum_item_id.length < 1) {
        itemIndices.push(addendumItem?.id);
      }

      if (addendumItem?.quantity < 1) {
        quantityIndices.push(addendumItem?.id);
      }
    }

    return {
      teb_numbers: tebErrors,
      teb: tebIndices,
      category: categoryIndices,
      item: itemIndices,
      quantity: quantityIndices,
    };
  };
  const addendumCPayload = () => {
    let tebIndices = [];
    let quantityStockIndices = [];
    let tebErrors = []; // Array for invalid TEBs
    let noOfSharesIndices = [];
    let titleIndices = [];
    let valueOfBondIndices = [];
    let quantityIndices = [];

    for (var i = 0; i < state?.addendum_c.length; i++) {
      var addendumItem = state?.addendum_c[i];
      if (addendumItem?.tamper_evident_bag_number.length < 1) {
        tebIndices.push(addendumItem?.id);
      } else {
        tebErrors.push({
          teb_number: addendumItem?.tamper_evident_bag_number,
          id: addendumItem?.id,
        }); // Collect the TEB number for validation
      }
      if (addendumItem?.title.length < 1) {
        titleIndices.push(addendumItem?.id);
      }
      if (addendumItem?.quantity < 1) {
        quantityIndices.push(addendumItem?.id);
      }
      if (addendumItem.addendum_item_type === "Stock") {
        if (addendumItem?.quantity < 1) {
          quantityStockIndices.push(addendumItem?.id);
        }
        if (addendumItem?.number_of_shares < 1) {
          noOfSharesIndices.push(addendumItem?.id);
        }
      }
      if (addendumItem.addendum_item_type === "OtherBond") {
        if (addendumItem?.value_of_bond < 1) {
          valueOfBondIndices.push(addendumItem?.id);
        }
      }
    }
    return {
      teb: tebIndices,
      teb_numbers: tebErrors,
      title: titleIndices,
      quantity: quantityIndices,
      stock: {
        noOfShares: noOfSharesIndices,
      },
      other: {
        bondValue: valueOfBondIndices,
      },
    };
  };

  function resetErrors() {
    setFormErrors({});
  }
  const onSubmit = () => {
    if (activeStep === 0) {
      let section = "section1";

      // Call the validate function which returns a promise
      validate(section)
        .then((errors) => {
          // Set form errors based on the result
          setFormErrors(errors);

          // If there are no errors, proceed to the next step
          if (Object.keys(errors).length === 0) {
            setActiveStep((prev) => prev + 1);
          } else {
            console.log("Errors found, staying on the same step:", errors);
          }
        })
        .catch((error) => {
          console.error("Validation process encountered an error:", error);
        });
    }

    if (activeStep === 1) {
      let section = "section2";

      validate(section)
        .then((errors) => {
          setFormErrors(errors);

          if (Object.keys(errors).length === 0) {
            setActiveStep((prev) => prev + 1);
          } else {
            console.log("Errors found, staying on the same step:", errors);
          }
        })
        .catch((error) => {
          console.error("Validation process encountered an error:", error);
        });
    }

    if (activeStep === 2) {
      let section = "section3";

      validate(section)
        .then((errors) => {
          setFormErrors(errors);

          if (Object.keys(errors).length === 0) {
            setActiveStep((prev) => prev + 1);
          } else {
            console.log("Errors found, staying on the same step:", errors);
          }
        })
        .catch((error) => {
          console.error("Validation process encountered an error:", error);
        });
    }

    if (activeStep === 3) {
      setActiveStep((prev) => prev + 1);
    }

    if (activeStep === 4) {
      validate("addendum")
        .then((errors) => {
          setFormErrors(errors);

          if (Object.keys(errors).length === 0) {
            setActiveStep((prev) => prev + 1);
          } else {
            console.log("Errors found, staying on the same step:", errors);
          }
        })
        .catch((error) => {
          console.error("Validation process encountered an error:", error);
        });
    }

    if (activeStep === 5) {
      let section = "authoriser";

      validate(section)
        .then((errors) => {
          setFormErrors(errors);

          if (Object.keys(errors).length === 0) {
            setActiveStep((prev) => prev + 1);
          } else {
            console.log("Errors found, staying on the same step:", errors);
          }
        })
        .catch((error) => {
          console.error("Validation process encountered an error:", error);
        });
    }
  };

  const showFailAlert = (response) => {
    let title = "Failed";
    let text = response;
    let type = "error";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(title, text, type, confirmButtonText, showCancelButton, target);
  };
  const onNextHandle = () => {
    onSubmit();
  };

  const setSection1 = async () => {
    for (const keys in FORCE_OPEN_DEAULT_STATE["section1"]) {
      if (
        !(keys === "owner" || keys === "co_owner") &&
        preData?.force_open_data[keys]
      ) {
        handleChange(
          "section1",
          keys,
          preData?.force_open_data[keys],
          "UPDATE_VALUE"
        );
      }
    }
  };

  const setSection2 = async () => {
    for (const keys in FORCE_OPEN_DEAULT_STATE["section2"]) {
      if (preData?.force_open_data[keys]) {
        handleChange(
          "section2",
          keys,
          preData?.force_open_data[keys],
          "UPDATE_VALUE"
        );
      }
    }
  };

  const setOwner = async () => {
    for (const keys in FORCE_OPEN_DEAULT_STATE["section1"]["owner"]) {
      let key = keys;
      if (
        !(
          keys === "owner_relation_code" ||
          keys === "owner_property_type_code" ||
          keys === "dormancy_id" ||
          keys === "company_name" ||
          keys === "service_member"
        )
      ) {
        key = "owner_" + keys;
      }
      if (preData?.force_open_data[key]) {
        handleChange(
          "section1",
          "owner." + keys,
          typeof preData?.force_open_data[key] === "boolean"
            ? preData?.force_open_data[key]
            : String(preData?.force_open_data[key]),
          "UPDATE_CHILD_VALUE"
        );
      }
    }
    for (const keys in FORCE_OPEN_DEAULT_STATE["section1"]["co_owner"]) {
      let key = "co_owner_" + keys;
      if (preData?.force_open_data[key]) {
        handleChange(
          "section1",
          "co_owner." + keys,
          String(preData?.force_open_data[key]),
          "UPDATE_CHILD_VALUE"
        );
      }
    }
  };

  useEffect(() => {
    if (preData) {
      handleChange(
        "record_id",
        null,
        preData?.record_id,
        "UPDATE_PARENT_VALUE"
      );
      setSection1();
      setOwner();
      setSection2();
    }
  }, [preData]);

  const renderActiveStepComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Section1
            errors={formErrors}
            branch_name={preData?.force_open_data.branch_name}
          />
        );
      case 1:
        return <Section2 errors={formErrors} />;
      case 2:
        return <Section3 errors={formErrors} />;
      case 3:
        return (
          <Section4 errors={formErrors} printScreen={props?.printScreen} />
        );
      case 4:
        return (
          <Addendums
            errors={formErrors}
            addendumAErrors={addendumABPayload("addendum_a")}
            addendumBErrors={addendumABPayload("addendum_b")}
            addendumCErrors={addendumCPayload()}
            existingTebNumbersIds={validatedTEBNumbers}
          />
        );
      case 5:
        return isAuthroization ? (
          <AddAuthorizer errors={formErrors} />
        ) : (
          <Confirmation errors={formErrors}> </Confirmation>
        );
      case 6:
        return <Confirmation errors={formErrors}> </Confirmation>;
      default:
        break;
    }
  };
  return (
    <>
      <div className="col-lg-12 md-12 sm-12  pl-0 pr-0" id="head-height">
        <MDBCard className=" p-0 m-0">
          <form onSubmit={handleSubmit}>
            <CustomHeader label={"FORCE OPEN"} />
            <FormProvider value={{ state, handleChange }}>
              <CustomStepper
                steps={forceOpenSteps}
                activeStep={activeStep}
                className="mt-4"
              />
              <div className="fieldArea7 pt-2 pb-2 justify-content-center">
                <button
                  type="button"
                  className="btn  btn-sm btn-primary float-left ml-4"
                  disabled={activeStep === 0}
                  onClick={() => {
                    setActiveStep((prev) => prev - 1);
                  }}
                >
                  PREVIOUS
                </button>
                {activeStep === forceOpenSteps.length - 1 ? (
                  <button
                    type="button"
                    className="btn  btn-sm btn-primary float-right mr-4"
                    onClick={handleSubmit}
                    disabled={state?.upload_form?.fileName ? false : true}
                  >
                    SUBMIT
                  </button>
                ) : (
                  <></>
                )}
                {activeStep < forceOpenSteps.length - 1 ? (
                  <button
                    type="button"
                    className="btn  btn-sm btn-primary float-right mr-4"
                    onClick={() => {
                      onNextHandle();
                    }}
                  >
                    NEXT
                  </button>
                ) : (
                  <div></div>
                )}
              </div>
              {renderActiveStepComponent()}
            </FormProvider>
          </form>
        </MDBCard>
      </div>
    </>
  );
};
export default ForceOpenStepper;
