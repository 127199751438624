export const SEARCH_TAMPER_EVIDENT_BAG = "/common/search-teb";
export const GET_RECENT_ACTIVITY = "/recent-activity";
export const DISPLAY_TIMELINE = "/recent-activity/timeline";
export const GET_SAFEDEPOSIT_BOX = "/common/safedepositbox";
export const GET_TAMPEREVIDENT_BAG = "/api/get-tamper-evident-bags";
export const CHECKIN = "/check-in";
export const NEW_CUSTOM_BULK = "/custom-bulk-check-in";
export const NEW_MERGE_DUPLICATES = "/api/new_merge_duplicates";
export const ENQUIRIES = "/enquiry";
export const SAVE_AUDIT = "/audit";
export const STORAGE_LOCATION = "/storage-location";
export const GET_STORAGE_LOCATIONS = "/api/get-storage-locations";
export const DELETE_STORAGE_LOCATION = "/api/delete-storage-location";
export const SAVE_STORAGE_UNIT = "/api/save-storage-unit";
export const GET_STORAGE_UNITS = "/api/get-storage-units";
export const SEARCH_UNITS = "/api/search-units";
export const UPDATE_WAREHOUSE_UNITS = "/api/update-warehouse-unit";
export const DELETE_WAREHOUSE_UNITS = "/api/delete-storage-unit";
export const SEARCH_WAREHOUSE_UNITS = "/api/warehouse-units-suggestion";
export const SAVE_SHIPMENT = "/shipment";
export const AUDIT = "/audit";
export const CHECK_BULK_SHIPMENT_DATA_EXISTS =
  "/custom-bulk-check-in/check-uploaded-shipment-data";
export const SAVE_BULK_SHIPMENT = "/custom-bulk-check-in/bulk-shipment-data";
export const SAVE_BULK_CHECKOUT = "/api/save_bulk_checkout";
export const VIEW_ALL_APPROVAL_REQUESTS = "/approval";
export const UPDATE_APPROVAL = "/approval/update-approval";
export const VIEW_HISTORY = "/common/view-history";
export const CHECK_OUT = "/check-out";
export const VIEW_ALL_RECORDS = "/api/view_all_records";
export const GET_REPORTS = "/reports";
export const REPORT_TYPES = "/reports/report-type";
export const ADD_ENQUIRY_NOTES = "/api/add-enquiry-notes";
export const GET_ENQUIRY_NOTES = "/api/get-enquiry-notes";
export const EMPLOYEE_LOGIN = "/employee/login";
export const ADD_USER = "/employee/add-user";
export const SAFECHIEF_ADMIN_LOGIN = "/employee/safechief-admin-login";
export const GET_ALL_EMPLOYEES = "/employee/get-employees";
export const GET_NOTIFICATIONS = "/notification";
export const SAVE_BULK_CHECKIN = "/api/save-bulk-checkin";
export const CHECK_AUDIT_ELIGIBILITY = "/audit/check-eligibility";
export const AUDIT_SYSTEM_SUGGESTED = "/audit/surprise-me";
export const GET_CHECKIN_DATA = "/api/get-checkin-data";
export const SAVE_FORCEOPEN = "/force-open";
export const GET_STORAGE_TYPE = "/common/storage-type";
export const GET_VAULT = "/common/vault";
export const GET_WAREHOUSE = "/common/warehouse";
export const GET_ACTION = "/action";
export const GET_ADDENDUM_CATEGORY = "/addendum-category";
export const GET_TEB_FOR_EVENT = "/common/get-tebs";
export const GET_SUB_STORAGE = "/common/sub-storage-type";
export const SAVE_SYSTEM_USER = "/product-set-up/save-user";
export const VERIFY_SYSTEM_USER = "/product-set-up/verify-user";
export const GET_ALL_EMPLOYEE_DESIGNATION = "/employee-designation";
export const UPDATE_EMPLOYEE_DESIGNATION = "/employee/update-employee";
export const ALL_SHIPMENT = "/shipment";
export const WAREHOUSE_ITEM = "/storage-location/warehouse-item";
export const SPECIFIC_APPROVAL_REQUEST = "/approval/get-details";
export const BULK_CHECKOUT_DATA = "/bulk-check-out";
export const GET_IRP_HANDLING = "/check-out/get-irphandling";
export const DELETE_SOFT_HANDLING = "/check-out/delete-checkout";
export const GET_TEB_DETAILS = "/check-out/get-teb-details";
export const GET_TEB_BULK_UPLOAD = "/bag_upload";
export const POST_ADDENDUM_CATEGORY = "/addendum-category/add-category";
export const POST_ADDENDUM_ITEM = "/addendum-category/add-item";
export const UPDATE_ROLE_FLAG =
  "/employee-designation/update-employee-designation";
export const GET_ALL_ITEMS = "/addendum-category/get-item";
export const PUT_ADDENDUM_ITEM = "/addendum-category/update-item";
export const DELTE_ADDENDUM_ITEM = "/addendum-category/delete-item";
export const POST_ADDENDUM_VALUE = "/addendum-category/addendum-item/add-value";
export const POST_FORCE_OPEN_RECORDS = "/force-open-record/new-records";
export const FORCE_OPEN_RECORDS = "/force-open-record";
export const GET_ALL_BRANCH_DETAILS = "/storage-location/branch";
export const FORCE_OPEN_RECORD = "/force-open-record/record";
export const ALL_CHECKIN_DATA_BY_STORAGE_TYPE = "/check-in/by-storage-type";
export const GET_AUTHORIZATION_CONFIGURATION_DETAILS =
  "/authorizer-configuration";
export const GET_AUTHORIZATION_CONFIGURATION_DETAIL =
  "/authorizer-configuration/module";
export const CONFIGURE_DATES = "/configure-dates";
export const EDIT_FIELDS = "/edit-fields";
export const GET_SUBSCRIPTIONS = "/subscription/fetch-subscriptions";
export const GET_ROLES = "/employee/get-roles";
export const CREATE_SUBSCRIPTION = "/subscription/create-subscriptions";
export const GET_TENANTS = "/subscription/fetch-tenants";
export const CREATE_TENANT = "/subscription/tenant-registration";
export const UPDATE_TENANT = "/subscription/tenant-update";
export const DELETE_TENANT = "/subscription/delete-tenant";
export const UPDATE_TENANT_STATUS = "/subscription/update-tenant-status"
export const DELETE_SUBSCRIPTION = "/subscription/delete-subscription";
export const VALIDATE_NUMBER = "/force-open/validate-number";
export const FORGOT_PASSWORD = "/verification/employee/forgotpassword";
export const GET_TENANT_DETAILS = "/subscription/tenant-details";

