import { MDBCard, MDBCardBody } from "mdbreact";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { CustomAlert } from "../components/customAlert";
import Swal from "sweetalert2";
import "../style/approvals.css";
import {
  getViewApprovalsData,
  postApproveStatus,
} from "../api/ApprovalsService";
import CustomHeader from "../components/CustomHeader";
import Pagination from "../components/Pagination";
import Search from "../components/Search";
import ApprovedModal from "./ApprovedModal";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UPDATE_APPROVAL_PAYLOAD } from "../constants/ApprovalConstants";
import { DISPLAY_DATE_TIME_FORMAT } from "../constants/Constants";
import { ConsoleError } from "../utils/ErrorUtils";
import { ERROR_MESSAGE } from "../constants/MessagesConstants";
import useFetchNotifications from "../utils/NotificationUtils";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { loaderDisplay } from "../utils/loader";


function Approvals() {
  let navigate = useNavigate();
  const [pages, setPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const initial = 0;
  const [saveLoader, setSaveLoader] = useState(false);
  const [rowCount, setRowCount] = useState(10);
  const [approvalRecords, setApprovalRecords] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalTitle, setmodalTitle] = useState();
  const [modalData, setmodalData] = useState();
  const [elementindex, setIndex] = useState();
  const [noRecordsFound, setNoRecordsFound] = useState();
  //eslint-disable-next-line
  const [submitIndex, setSubmitIndex] = useState([]);
  const [approvalType, setApprovalType] = useState("Authorizer Pending");
  const [update, setUpdate] = useState(false);
  const [comment, setComment] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  //eslint-disable-next-line
  const notificationResponse = useFetchNotifications(
    currentUser?.employee_id,
    currentUser?.jwtToken
  );

  dayjs.extend(utc);
  dayjs.extend(tz);
  const localTimezone = dayjs.tz.guess();

  function openApproval(e, index) {
    setIsOpen(true);
    setmodalTitle("APPROVED");
    setmodalData(e);
    setIndex(index);
  }
  function openDecline(e, index) {
    setIsOpen(true);
    setmodalTitle("DECLINED");
    setmodalData(e);
    setIndex(index);
  }

  function handleCloseModal(event, data) {
    setIsOpen(false);
  }

  function onSubmit(data, comments) {
    Swal.fire({
      title: "Processing, please wait",
      html: "loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    setIsOpen(false);
    let payload = UPDATE_APPROVAL_PAYLOAD;
    payload.approved_by = currentUser?.employee_id;
    payload.comment = comments;
    payload.approval_id = data?.data?.approval_id;
    if (data.dynData === "APPROVED") {
      payload.status = "Authorizer Approved";
    } else if (data.dynData === "DECLINED") {
      payload.status = "Authorizer Declined";
    }
    postApproveStatus(payload, currentUser?.jwtToken)
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 201 || response.status === 200) {
          // getApprovalRecords();
          Swal.close();
          let key = data.index;
          let value = data.dynData;
          // setSubmitIndex((submitIndex) => [{...submitIndex,key}]);
          submitIndex[key] = value;
          setUpdate(!update);
          setComment();
          let status = "Approval Status";
          let title = status;
          let text =
            "Record" +
            " " +
            status +
            " " +
            "successfully " +
            data.dynData +
            " , you can check the status in" +
            " home" +
            " " +
            "screen";
          let type = "success";
          let confirmButtonText = "OK";
          let showCancelButton = false;
          CustomAlert(
            title,
            text,
            type,
            confirmButtonText,
            showCancelButton
          ).then((result) => {});
        } else if (response.status === 500) {
          Swal.close();

          let title = ERROR_MESSAGE;
          let text = "Please try after some time";
          let type = "error";
          let confirmButtonText = "OK";
          let showCancelButton = false;
          CustomAlert(
            title,
            text,
            type,
            confirmButtonText,
            showCancelButton
          ).then((result) => {});
        }
      })
      .catch((error) => {
        Swal.close();
      });
  }

  const setRowsPage = (e) => {
    setRowCount(e);
    setSaveLoader(true);
    getViewApprovalsData(
      approvalType,
      e,
      currentUser?.employee_id,
      currentUser?.jwtToken,
      1,
      searchValue
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          setSaveLoader(false);
          let count = response?.data?.pagination?.total_records;
          let c = Math.ceil(count / e);
          c = c === 0 ? 1 : c;
          setPages([...Array(c)].map((_, i) => i + 1));
          let data = response?.data?.approvals;
          setApprovalRecords(data);
          setCurrentPage(1);
          setSubmitIndex([]);
        }
      })
      .catch((error) => {});
  };

  const onPageClick = (item) => {
    if (item === currentPage) return;
    setSaveLoader(true);
    getViewApprovalsData(
      approvalType,
      rowCount,
      currentUser?.employee_id,
      currentUser?.jwtToken,
      item,
      searchValue
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          setSaveLoader(false);
          let data = response?.data?.approvals;
          setApprovalRecords(data);
          setCurrentPage(item);
          setSubmitIndex([]);
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };
  const onNextClick = () => {
    getViewApprovalsData(
      approvalType,
      rowCount,
      currentUser?.employee_id,
      currentUser?.jwtToken,
      currentPage + 1,
      searchValue
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          setSaveLoader(false);
          let count = response?.data?.pagination?.total_records;
          if (response?.data.status === 204 || count < 1) {
            setNoRecordsFound("No approval records available");
          }
          let c = Math.ceil(count / rowCount);
          c = c === 0 ? 1 : c;
          setPages([...Array(c)].map((_, i) => i + 1));
          let data = response?.data?.approvals;
          setApprovalRecords(data);
          setCurrentPage(currentPage + 1);
          setSubmitIndex([]);
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };
  const onPreviousNext = () => {
    getViewApprovalsData(
      approvalType,
      rowCount,
      currentUser?.employee_id,
      currentUser?.jwtToken,
      currentPage - 1,
      searchValue
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          setSaveLoader(false);
          let count = response?.data?.pagination?.total_records;
          if (response?.data.status === 204 || count < 1) {
            setNoRecordsFound("No approval records available");
          }
          let c = Math.ceil(count / rowCount);
          c = c === 0 ? 1 : c;
          setPages([...Array(c)].map((_, i) => i + 1));
          let data = response?.data?.approvals;
          setApprovalRecords(data);
          setCurrentPage(currentPage - 1);
          setSubmitIndex([]);
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };

  const getApprovalRecords = async () => {
    setSaveLoader(true);
    getViewApprovalsData(
      approvalType,
      rowCount,
      currentUser?.employee_id,
      currentUser?.jwtToken,
      1,
      searchValue
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          setSaveLoader(false);
          let count = response?.data?.pagination?.total_records;
          if (response?.data.status === 204 || count < 1) {
            setNoRecordsFound("No approval records available");
          }
          let c = Math.ceil(count / rowCount);
          c = c === 0 ? 1 : c;
          setPages([...Array(c)].map((_, i) => i + 1));
          let data = response?.data?.approvals;
          setApprovalRecords(data);
          setCurrentPage(1);
          setSubmitIndex([]);
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };

  useEffect(() => {
    getApprovalRecords();
    //eslint-disable-next-line
  }, [approvalType]);

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 body overflow-hidden">
      <div className="row flex p-4">
        <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden pt-5">
          <div className="row pt-2 pl-4">
            <div className="col-lg-6 col-md-12 p-0 ">
              <Search
                screen="Approvals"
                pageSize={rowCount}
                setSearchValueApproval={setSearchValue}
                searchValueApproval={searchValue}
                onClickSearch={getApprovalRecords}
              />
            </div>
            <div className="col-lg-1 col-md-6 col-sm-6 pl-0 pr-0 "></div>

            <div className="col-lg-3 col-md-6 col-sm-6 pl-0 pr-0">
              <select
                className="custom-select mr-sm-2"
                id="storage_type"
                name="storage_type"
                value={approvalType}
                onChange={(e) => {
                  setApprovalType(e.target.value);
                }}
              >
                <option value="Authorizer Pending">Pending</option>
                <option value="Authorizer Approved">Approved</option>
                <option value="Authorizer Declined">Declined</option>
                <option value="">All</option>
              </select>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 ">
              <button
                type="button"
                className="btn btn-primary btn-sm col-lg-11 col-md-11 col-sm-11 m-0 float-end"
                id="bulk-checkin-button"
                onClick={() => getApprovalRecords()}
              >
                REFRESH
              </button>
            </div>
          </div>

          <MDBCard className="card overflow-hidden h-md-100 table-box">
            <CustomHeader label={"REQUESTS"} />
            <MDBCardBody>
              <div>
                <table className="table safeChiefTable">
                  <thead className="headerArea">
                    <tr>
                      <th scope="col" className="tableHeading1">
                        TAMPER EVIDENT BAG #
                      </th>
                      <th scope="col" className="tableHeading">
                        ACTION TYPE
                      </th>
                      <th scope="col" className="tableHeading">
                        ACTION DATE
                      </th>
                      <th scope="col" className="tableHeading">
                        PERFORMED BY
                      </th>
                      <th scope="col" className="tableHeading">
                        STATUS
                      </th>
                    </tr>
                  </thead>

                  <tbody id="cs-scroll">
                    {saveLoader ? 
                    (<tr>
                      <td colSpan="5" className="text-center py-5">
                      {loaderDisplay()}
                      </td>
                     </tr>
                    )  
                    : (
                      <>
                        {approvalRecords !== null &&
                          approvalRecords !== undefined &&
                          approvalRecords.map((item, index) => {
                            return (
                              <tr className="table-row" key={index}>
                                <td
                                  className="tableDetails1"
                                  onClick={() => {
                                    navigate("/approvalDetails", {
                                      state: item,
                                    });
                                  }}
                                >
                                  {
                                    item?.tamper_evident_bag
                                      ?.tamper_evident_bag_number
                                  }
                                </td>
                                <td
                                  className="tableDetails"
                                  onClick={() => {
                                    navigate("/approvalDetails", {
                                      state: item,
                                    });
                                  }}
                                >
                                  {item?.action?.action}
                                </td>
                                <td
                                  className="tableDetails shipmentDateBody2"
                                  onClick={() => {
                                    navigate("/approvalDetails", {
                                      state: item,
                                    });
                                  }}
                                >
                                  {dayjs(item?.create_datetime)
                                    .tz(localTimezone)
                                    .format(DISPLAY_DATE_TIME_FORMAT)}
                                </td>
                                <td
                                  className="tableDetails shipmentBody5"
                                  onClick={() => {
                                    navigate("/approvalDetails", {
                                      state: item,
                                    });
                                  }}
                                >
                                  {item?.performed_by?.employee_name}
                                </td>
                                <td className="tableDetails">
                                  {item?.status?.status ===
                                    "Authorizer Approved" ||
                                  submitIndex[index] === "APPROVED"
                                    ? "APPROVED"
                                    : item?.status?.status ===
                                        "Authorizer Declined" ||
                                      submitIndex[index] === "DECLINED"
                                    ? "DECLINED"
                                    : currentUser?.approval_save_record_access_flag ? (
                                        <>
                                          <button
                                            className="btn btn-sm"
                                            id="approve-btn"
                                            type="button"
                                            onClick={() =>
                                              openApproval(item, index)
                                            }
                                          >
                                            APPROVE
                                          </button>
                                          <button
                                            className="btn btn-sm"
                                            id="decline-btn"
                                            type="button"
                                            onClick={() =>
                                              openDecline(item, index)
                                            }
                                          >
                                            DECLINE
                                          </button>
                                        </>
                                      ): "PENDING"}
                                </td>
                              </tr>
                            );
                          })}
                        {approvalRecords && approvalRecords.length === 0 && (
                          <div className="text-center py-5 alignself-center">
                            {noRecordsFound}
                          </div>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </MDBCardBody>
            <Pagination
              pages={pages}
              onNextClick={onNextClick}
              onPreviousClick={onPreviousNext}
              onPageClick={onPageClick}
              initial={initial}
              setRowsPerPage={setRowsPage}
              currentPage={currentPage}
            />
          </MDBCard>
          <ApprovedModal
            dynData={modalTitle}
            data={modalData}
            IsModalOpened={modalIsOpen}
            index={elementindex}
            // commentData={(comment) => setComment(comment)}
            onCloseModal={handleCloseModal}
            commentData={comment}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </div>
  );
}

export default Approvals;
