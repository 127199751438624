import "../style/CreateEnquiry.css";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import CustomModalFileFormat from "../components/CustomModalFileFormat";
import {
  getGoogleStudioMockData,
  getFSIMockData,
} from "../api/MockDataService";
import "../style/Reports.css";
import * as XLSX from "xlsx";
import { ConsoleError } from "../utils/ErrorUtils";

function GenerateMockData() {
  const [loading, setLoading] = useState(true);
  const [radioValue, setRadioValue] = useState("");
  const [activityType, setActivityType] = useState("google_studio");
  const [reportName, setReportName] = useState("");
  const [loadData, setLoadData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const csvLinkEl = React.createRef();
  const [values, setValues] = useState({
    noOfRecords: useState(0),
  });

  const handleChange = (key, value) => {
    let obj = { ...values };
    obj[key] = value;
    setValues(obj);
  };

  let onChangeActivity = (value) => {
    setActivityType(value.target.value);
  };

  const getGSMockData = async () => {
    await getGoogleStudioMockData(values.noOfRecords)
      .then((response) => {
        if (response.status === 200) {
          setLoadData(response?.data);
          setShow(false);
          handleClose();
          setLoading(false);
        } else if (response.status === 204 || response.status === 404) {
          setLoadData([]);
          handleClose();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getFsiMockData = async () => {
    await getFSIMockData(values.noOfRecords)
      .then((response) => {
        if (response.status === 200) {
          setLoadData(response?.data);
          setShow(false);
          handleClose();
          setLoading(false);
        } else if (response.status === 204 || response.status === 404) {
          setLoadData([]);
          handleClose();
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };

  function onSubmit(selectradio) {
    setShow(false);
    handleShow();
  }

  function getReportName(selectradiovalue) {
    if (selectradiovalue !== "") {
      setRadioValue(selectradiovalue);
      if (activityType === "google_studio") {
        setReportName("GoogleStudioData_" + values.noOfRecords);
        getGSMockData();
      } else {
        setReportName("FSIData_" + values.noOfRecords);
        getFsiMockData();
      }
    }
  }

  const headers = [
    { label: "BRANCH_ID", key: "BRANCH_ID" },
    { label: "UNIQUE_ID", key: "UNIQUE_ID" },
    { label: "NEXT AUDIT DUE DATE", key: "NEXT AUDIT DUE DATE" },
    { label: "DAYS UNTIL NEXT AUDIT", key: "DAYS UNTIL NEXT AUDIT" },
    { label: "AUDIT DUE STATUS", key: "AUDIT DUE STATUS" },
    { label: "CONTENT RELEASE STATUS", key: "CONTENT RELEASE STATUS" },
    { label: "BRANCH SDB AUDIT", key: "BRANCH SDB AUDIT" },
    { label: "STATUS", key: "STATUS" },
    { label: "AUDIT BAG STATUS", key: "AUDIT BAG STATUS" },
    { label: "AUDITOR NAME", key: "AUDITOR NAME" },
    { label: "ACTUAL AUDIT DATE", key: "ACTUAL AUDIT DATE" },
    { label: "LAST AUDIT DATE", key: "LAST AUDIT DATE" },
    { label: "PREVIOUS AUDIT BAG STATUS", key: "PREVIOUS AUDIT BAG STATUS" },
    { label: "CURRENT BRANCH DID", key: "CURRENT BRANCH DID" },
    { label: "REGION", key: "REGION" },
    { label: "MARKET", key: "MARKET" },
    { label: "STATE", key: "STATE" },
    { label: "BRANCH", key: "BRANCH" },
    { label: "CUSTOMER NAME", key: "CUSTOMER NAME" },
    { label: "SDB# DRILLED", key: "SDB# DRILLED" },
    { label: "BOX DRILL DATE", key: "BOX DRILL DATE" },
    { label: "BAG #", key: "BAG #" },
    { label: "BAG COUNT", key: "BAG COUNT" },
    {
      label:
        "Branch where the box was physically located when drilled (This data in this column should never change)",
      key: "Branch where the box was physically located when drilled (This data in this column should never change)",
    },
    { label: "ORIGINAL BRANCH DID", key: "ORIGINAL BRANCH DID" },
    { label: "ORIGINAL BRANCH NAME", key: "ORIGINAL BRANCH NAME" },
    { label: "PROHIBITED ITEM", key: "PROHIBITED ITEM" },
    { label: "ROM", key: "ROM" },
    { label: "OPS SPECIALIST", key: "OPS SPECIALIST" },
    { label: "MARKET MANAGER", key: "MARKET MANAGER" },
    { label: "FORM LINK", key: "FORM LINK" },
    { label: "RECORD_ID", key: "RECORD_ID" },
    { label: "BAG_DATA", key: "BAG_DATA" },
    { label: "DUPLICATE RECORD", key: "DUPLICATE RECORD" },
    {
      label:
        "Branch Use SDB # (or other specific storage location of drilled content)",
      key: "Branch Use SDB # (or other specific storage location of drilled content)",
    },
  ];

  const downloadReport = async () => {
    if (radioValue === "csv") {
      //   setLoading(false);
      csvLinkEl.current.link.click();
    } else {
      //   setLoading(false);
      const workSheet = XLSX.utils.json_to_sheet(loadData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Report_Sheet1");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, reportName + ".xlsx");
    }
    // setLoading(true);
  };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 ">
        <div className="row flex pt-4 pl-4 pr-4 pb-0 " id="swal-div">
          <div className="col-lg-12 col-md-12 col-sm-12 pb-3 pl-0 pr-0 overflow-hidden">
            <div className="row pt-2 pb-4">
              <div className="col-lg-3 col-md-10 "></div>
              <div className="col-lg-3 col-md-6 col-sm-6 pt-5 pl-5 pr-0">
                <div className="d-flex justify-content-center align-items-center  pt-5">
                  <div className="pr-5">
                    <input
                      type="radio"
                      value="google_studio"
                      name="radio"
                      onChange={onChangeActivity}
                      checked={activityType === "google_studio"}
                    />
                    <label className="radioLabel">Google Studio</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      value="fsi"
                      name="radio"
                      onChange={onChangeActivity}
                      checked={activityType === "fsi"}
                    />
                    <label className="radioLabel">FSI</label>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="row">
                    <label className="col-sm-5 col-form-label input-label">
                      Number Of Records{" "}
                    </label>
                    <input
                      type="number"
                      name="no of records"
                      placeholder="No Of Records"
                      className="form-control-plaintext"
                      onChange={(e) =>
                        handleChange("noOfRecords", e.target.value)
                      }
                      value={values.noOfRecords}
                    />
                  </div>
                </div>
                <button onClick={onSubmit} className="btn  btn-sm btn-primary">
                  SUBMIT
                </button>
                <CustomModalFileFormat
                  show={show}
                  backdrop="static"
                  onCloseModal={handleClose}
                  onSubmit={getReportName}
                />
                <button
                  onClick={downloadReport}
                  type="submit"
                  className="float-end btn  btn-sm btn-primary col-lg-4 col-md-4 col-sm-4 pl-0 pr-0"
                  disabled={loading}
                >
                  Download
                </button>
                <CSVLink
                  filename={reportName}
                  data={loadData ? loadData : []}
                  headers={headers}
                  ref={csvLinkEl}
                ></CSVLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GenerateMockData;
