import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { cognitoSignOut } from "../api/CognitoServices";
import CustomHeader from "../components/CustomHeader";
import CustomModalFileFormat from "../components/CustomModalFileFormat";
import { CustomAlert } from "../components/customAlert";
import {
  MAX_END_DATE,
  MAX_START_DATE,
  MIN_END_DATE,
  MIN_START_DATE,
  REPORT_ATTRIBUTIES,
  REPORT_HEADERS,
  REPORT_TYPE_DATE_RANGE_OPTION,
  REPORT_TYPE_STORAGE_LOCATION_OPTION,
  DISABLED_CAPACITY_ATTRIBUTES,
  CAPACITY_REPORT,
  DISABLED_COMMON_ATTRIBUTES,
} from "../constants/ReportsConstants";
import "../style/Reports.css";
import "../style/viewReports.css";
import { ConsoleError } from "../utils/ErrorUtils";

import { getActionList } from "../api/ActionService";
import { getStorageListOnsearch } from "../api/StorageListService";
import {
  createReportType,
  getReportList,
  getReportRecords,
} from "../api/ViewReportsService";
import SearchPanel from "../components/SearchPanel";
import { ERROR_MESSAGE } from "../constants/MessagesConstants";
import { getUpdatedDateInUtc } from "../utils/DateUtils";

function ViewReports() {
  let navigate = useNavigate();
  const initialValues = {
    report_type: "",
    report_from_date: null,
    report_to_date: null,
    report_driver: true,
    report_attribuites: null,
    report_name: "",
    storage_type: null,
    storage_location_id: null,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [reportTypeList, setReportTypeList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [reportRecords, setReportRecords] = useState([]);
  const [showReportRecordsTable, setShowReportRecordsTable] = useState(false);
  const [reportName, setReportName] = useState("");
  const [isDownloading, SetIsDownloading] = useState(false);
  const [attributeListString, setAttributeListString] = useState(null);
  const [reportHeaders, setReportHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [storageTypeList, setStorageTypeList] = useState([]);
  const [isShowBranch, setIsShowBranch] = useState(false);
  const [BranchOptionList, setBranchOptionList] = useState([]);
  const [BranchList, setBranchList] = useState([]);
  const [location, setLocation] = useState("");

  const [isShowVault, setIsShowVault] = useState(false);
  const [isShowWarehouse, setIsWarehouse] = useState(false);
  const [SearchPanelLabel, setSearchPanelLabel] = useState("");
  const [SearchPanelPlaceholder, setSearchPanelPlaceholder] = useState("");

  //eslint-disable-next-line
  const [StorageLocationType, setStorageLocationType] = useState("");

  const [VaultId, setVaultId] = useState("");
  const [WarehouseId, setWarehouseId] = useState("");
  //eslint-disable-next-line
  const [showOptions, setShowOptions] = useState(true);

  const [searchString, setSearchString] = useState("");
  useState(false);
  const [isStorageNameAssigned, setStorageNameAssigned] = useState(false);
  const setBranchDid = (branch_did) => {
    setFormValues({ ...formValues, branch_did: branch_did });
  };

  const [downloadFileType, setDownloadFileType] = useState("");
  const csvLinkEl = React.createRef();

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const clearData = (e) => {
    e.preventDefault();
    setFormValues({
      ...formValues,
      report_type: "",
      report_from_date: null,
      report_to_date: null,
      report_driver: true,
      report_attribuites: null,
      report_name: "",
      storage_type: null,
      storage_location_id: null,
      parent_report_name: "",
    });
    setFormErrors("");
    setReportRecords([]);
    setReportName(null);
    setReportHeaders([]);
    setShowReportRecordsTable(false);
  };

  const maxReportFromDate = getUpdatedDateInUtc(MAX_START_DATE);
  const minReportFromDate = getUpdatedDateInUtc(MIN_START_DATE);
  const maxReportToDate = getUpdatedDateInUtc(MAX_END_DATE);
  const minReportToDate = getUpdatedDateInUtc(MIN_END_DATE);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "report_type") {
      let attributesList = reportTypeList.filter(
        (item) => item.report_name === value
      )[0];
      //eslint-disable-next-line
      setFormValues({
        ...formValues,
        [name]: value,
        report_attribuites: attributesList.attributes,
        report_from_date: null,
        report_to_date: null,
        storage_type: null,
        storage_location_id: null,
        parent_report_name: attributesList.parent_report_name,
      });
      setAttributeListString(attributesList?.attributes?.join(", "));
    } else {
      setFormValues({ ...formValues, [name]: value });
      if (e.target.value === "Branch") {
        setIsShowBranch(true);
        setIsShowVault(false);
        setIsWarehouse(false);
        setStorageLocationType("branch");
        setSearchPanelLabel("Branch Name");
        setSearchPanelPlaceholder("Search by Branch Name or DID");
        // setIsStorageTypeError(false);
      } else if (e.target.value === "Warehouse") {
        setIsShowBranch(false);
        setIsShowVault(false);
        setIsWarehouse(true);
        setStorageLocationType("warehouse");
        setSearchPanelLabel("Warehouse Name");
        setSearchPanelPlaceholder("Search by Warehouse Name");
        // setIsStorageTypeError(false);
      } else if (e.target.value === "Vault") {
        setIsShowBranch(false);
        setIsShowVault(true);
        setIsWarehouse(false);
        setStorageLocationType("vault");
        setSearchPanelLabel("Vault Name");
        setSearchPanelPlaceholder("Search by Vault Name");
        // setIsStorageTypeError(false);
      }
    }
    if (formErrors[name] !== undefined && formErrors[name].length > 0) {
      delete formErrors[name];
    }
  };
  function loadReportType() {
    // TODO Use search Value to filter report types
    getReportList(null, currentUser?.jwtToken)
      .then((response) => {
        setReportTypeList(response?.data);
      })
      .catch((error) => {
        ConsoleError(error);
      });
  }

  useEffect(() => {
    loadReportType();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAttributeListString(formValues?.report_attribuites?.join(", "));
  }, [formValues?.report_attribuites]);

  const showSuccessAlert = (response) => {
    let title = "Saved";
    let text = response;
    let type = "success";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      target
    ).then((result) => {
      if (result.value) {
        navigate("/reports");
      }
    });
  };

  const showFailAlert = (response) => {
    let title = "Error";
    let text = response;
    let type = "error";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(title, text, type, confirmButtonText, showCancelButton, target);
  };

  function onSaveReportType(e) {
    let errors = validate(formValues);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const reportSameNameList = reportTypeList.filter(
        (report) =>
          report.report_name.toLowerCase() ===
          formValues?.report_name.toLowerCase()
      );
      if (reportSameNameList.length === 0) {
        Swal.fire({
          title: "Processing, please wait",
          html: "loading...",
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        let payload = {
          report_name: formValues?.report_name.trim(),
          parent_report: formValues?.report_type,
          attributes: formValues?.report_attribuites,
        };
        createReportType(payload, currentUser?.jwtToken)
          .then((response) => {
            if (response.status === 401) {
              cognitoSignOut()
                .then(() => {
                  navigate("/");
                })
                .catch((error) => {
                  ConsoleError(error);
                });
            } else if (response?.status === 201) {
              Swal.close();
              showSuccessAlert("Report Type saved successfully");
              clearData(e);
              loadReportType();
            } else if (response?.status === 400) {
              Swal.close();
              showFailAlert("Create Report Type failed due to invalid data");
            } else {
              Swal.close();
              showFailAlert("Create Report Type failed");
            }
          })
          .catch((error) => {
            Swal.close();
            showFailAlert(ERROR_MESSAGE);
            ConsoleError(error);
          });
      } else {
        showFailAlert(
          "New Report Type Name Should be different from existing Report Types"
        );
      }
    } else {
      return false;
    }
  }

  /**
   * Retrieves records based on the specified report type and date range.
   *
   * @param {string} reportType - The type of report to retrieve records for.
   * @param {string} [fromDate] - The start date of the date range to retrieve records for (optional).
   * @param {string} [toDate] - The end date of the date range to retrieve records for (optional).
   * @returns {Array} An array of records that match the specified report type and date range.
   */
  function getRecords(
    reportType,
    fromDate = null,
    toDate = null,
    storageType = null,
    location = null
  ) {
    // code to retrieve records based on report type and date range
    getReportRecords(
      reportType,
      fromDate,
      toDate,
      storageType,
      location,
      currentUser?.jwtToken
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          Swal.close();
          setShowReportRecordsTable(true);
          setReportRecords(response.data);
        } else if (
          response?.data.status === 204 ||
          response?.data.status === 404
        ) {
          setReportRecords([]);
          setShowReportRecordsTable(false);
          handleClose();
          showAlert();
        }
      })
      .catch((error) => {
        Swal.close();
        ConsoleError(error);
      });
  }

  const showAlert = () => {
    CustomAlert(
      "Records not available for the entered Dates",
      "",
      "info",
      "OK",
      false,
      document.getElementById("swal-div")
    );
  };

  function showProcessing() {
    Swal.fire({
      title: "Generating Report, please wait",
      html: "loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
  }
  function resetErrors() {
    setFormErrors({});
  }

  const validate = (values) => {
    resetErrors();
    const errors = {};
    if (!values.report_type) {
      errors.report_type = "Report Type is required";
    } else if (values?.report_driver && REPORT_TYPE_DATE_RANGE_OPTION.includes(values.report_type)) {
      if (!values.report_from_date) {
        errors.report_from_date = "Report From Date is required";
      }
      if (!values.report_to_date) {
        errors.report_to_date = "Report To Date is required";
      } else if (values.report_from_date > values.report_to_date) {
        errors.report_from_date = "From Date should be less than To Date";
      }
    }
    if (values?.report_driver && REPORT_TYPE_STORAGE_LOCATION_OPTION.includes(values.report_type)) {
      if (!values.storage_type) {
        errors.storage_type = "Storage Type is required";
      }
      if (values.storage_type === "Branch") {
        if (!values.branch_did) {
          errors.storage_location_id = "Branch Name is required";
        }
      } else if (values.storage_type === "Warehouse") {
        if (!WarehouseId) {
          errors.storage_location_id = "Warehouse Name is required";
        }
      } else if (values.storage_type === "Vault") {
        if (!VaultId) {
          errors.storage_location_id = "Vault Name is required";
        }
      }
    } else if (!values.report_driver) {
      if (values?.report_name.length === 0) {
        errors.report_name = "New Report Type Name is required";
      }
    }

    return errors;
  };

  const onSubmit = () => {
    let errors = validate(formValues);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setShow(true);
    } else {
      return false;
    }
  };

  function onSelectionFileType(selectedFileType) {
    if (selectedFileType !== "") {
      setDownloadFileType(selectedFileType);
      showProcessing();
      setReportName(
        formValues["report_type"].replace(/\s+/g, "_").toLowerCase() +
          (formValues["report_from_date"] && formValues["report_to_date"]
            ? "_from_" +
              formValues["report_from_date"] +
              "_to_" +
              formValues["report_from_date"]
            : "") +
          "." +
          selectedFileType
      );
      let selectedReportObj = reportTypeList.find(
        (report) => report?.report_name === formValues?.report_type
      );
      const sortedHeaders = selectedReportObj?.attributes.map((item) => {
        const header = REPORT_HEADERS.find((header) => header.label === item);
        return header;
      });
      setReportHeaders(sortedHeaders);
      getRecords(
        formValues["report_type"],
        formValues["report_from_date"],
        formValues["report_to_date"],
        formValues["storage_type"],
        location
      );
    }
  }

  const downloadReport = async () => {
    if (downloadFileType === "csv") {
      SetIsDownloading(true);
      csvLinkEl.current.link.click();
    } else if (downloadFileType === "xlsx") {
      SetIsDownloading(true);
      const reportRecordsWithHeader = [
        reportHeaders.map((header) => header.label),
        ...reportRecords.map((record) =>
          reportHeaders.map((header) => {
            // Handle array fields by joining with ", "
            const value = record[header.key];
            return Array.isArray(value) ? value.join(", ") : value;
          })
        ),
      ];
      const workSheet = XLSX.utils.aoa_to_sheet(reportRecordsWithHeader);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Report_Sheet1");
      XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, reportName);
    }
    SetIsDownloading(false);
  };

  useEffect(() => {
    getActionList("StorageType", currentUser?.jwtToken)
      .then((response) => {
        setStorageTypeList(
          response?.map(function (storageTypeRow) {
            return storageTypeRow;
          })
        );
      })
      .catch((error) => {
        ConsoleError(error);
      });
  }, [currentUser?.jwtToken]);

  function getStorageList() {
    const storage_search_type = isShowBranch
      ? "branch"
      : isShowVault
      ? "vault"
      : "warehouse";
    getStorageListOnsearch(
      storage_search_type,
      searchString,
      currentUser?.jwtToken
    )
      .then((response) => {
        // TO-DO handle no data and update variable names as generic
        setBranchList(response);
        setBranchOptionList(
          response?.map(function (branchRow) {
            return (
              (branchRow.name ? branchRow.name : "") +
              (branchRow.branch_did ? " - " + branchRow.branch_did : "")
            );
          })
        );
      })
      .catch((err) => {
        ConsoleError(err);
      });
  }

  useEffect(() => {
    if (!isStorageNameAssigned && searchString.length > 0) {
      getStorageList();
    }
    //eslint-disable-next-line
  }, [searchString]);

  return (
    <>
      <div className="d-md-flex ">
        <div className="row  col-lg-12 col-md-12 col-sm-12" id="swal-div">
          <div className="col-lg-12 col-md-12 col-sm-12 pl-2 ">
            <div className="row pt-2 pl-1 pr-1">
              <div
                className="col-lg-12 col-md-12 col-sm-12  pl-0 pr-0"
                id="divHeight"
              >
                <MDBCard id="mdbHeight" className=" p-0 m-0">
                  <CustomHeader label={"Export Data"} />
                  <>
                    <div className="form-row">
                      <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                        <label htmlFor="report_type">Report Type</label>
                        <select
                          className="custom-select mr-sm-2"
                          id="report_type"
                          name="report_type"
                          defaultValue=""
                          value={formValues?.report_type}
                          onChange={(e) => {
                            clearData(e);
                            handleChange(e);
                          }}
                        >
                          <option value="">Choose Report Type...</option>
                          {reportTypeList.map((opt, index) => {
                            return (
                              <option key={index} value={opt.report_name}>
                                {opt.report_name}
                              </option>
                            );
                          })}
                        </select>
                        <div className="error-message-validation">
                          {formErrors.report_type}
                        </div>
                      </div>
                      {formValues?.report_driver &&
                      (REPORT_TYPE_STORAGE_LOCATION_OPTION.includes(
                        formValues?.report_type
                      ) ||
                        REPORT_TYPE_STORAGE_LOCATION_OPTION.includes(
                          formValues?.parent_report_name
                        )) ? (
                        <>
                          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                            <label htmlFor="storage_type"> Storage Type</label>
                            <select
                              className="custom-select mr-sm-2"
                              id="storage_type"
                              name="storage_type"
                              defaultValue=""
                              value={formValues.storage_type}
                              onChange={(e) => {
                                setSearchString("");
                                handleChange(e);
                              }}
                            >
                              <option value="">Choose...</option>
                              {storageTypeList?.map((opt, index) => {
                                return (
                                  <option key={index} value={opt.action}>
                                    {opt.action}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="error-message-validation">
                              {formErrors.storage_type}
                            </div>
                          </div>
                          {(isShowBranch || isShowVault || isShowWarehouse) && (
                            <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                              <SearchPanel
                                label={SearchPanelLabel}
                                placeholder={SearchPanelPlaceholder}
                                options={BranchOptionList}
                                setOptionList={setBranchOptionList}
                                setShowOptions={setShowOptions}
                                name="storage_location_id"
                                id="storage_location_id"
                                showOptions={showOptions}
                                onFocusSearch={(e) => {
                                  setSearchString("");
                                  setBranchDid("");
                                  setLocation("");
                                }}
                                handleChange={(e) => {
                                  if (isStorageNameAssigned) {
                                    setSearchString(
                                      e.target.value.charAt(e.length - 1)
                                    );
                                    setStorageNameAssigned(false);
                                  } else {
                                    setSearchString(e.target.value);
                                  }
                                  setShowOptions(true);
                                }}
                                searchString={searchString}
                                onSelectValue={(selectedStorage) => {
                                  setStorageNameAssigned(true);
                                  setShowOptions(false);
                                  // formValues["storage_location_id"]=== selectedStorage
                                  if (isShowBranch) {
                                    const branch = BranchList.filter(
                                      (branchRow) =>
                                        branchRow?.branch_did ===
                                        selectedStorage.split(" - ")[1]
                                    )[0];
                                    setSearchString(branch.name);
                                    setBranchDid(branch?.branch_did);
                                    setLocation(branch.name);
                                  } else if (isShowVault) {
                                    const vault = BranchList.filter(
                                      (vaultRow) =>
                                        vaultRow?.name === selectedStorage
                                    )[0];
                                    setSearchString(vault.name);
                                    setLocation(vault.name);

                                    setVaultId(vault?.vault_id);
                                  } else {
                                    const warehouse = BranchList.filter(
                                      (warehouseRow) =>
                                        warehouseRow?.name === selectedStorage
                                    )[0];
                                    setSearchString(warehouse.name);
                                    setWarehouseId(warehouse?.warehouse_id);
                                    setLocation(warehouse.name);
                                  }
                                  if (
                                    formErrors["storage_location_id"] !==
                                      undefined &&
                                    formErrors["storage_location_id"].length > 0
                                  ) {
                                    delete formErrors["storage_location_id"];
                                  }
                                }}
                              />

                              <div className="error-message-validation">
                                {formErrors.storage_location_id}
                              </div>
                            </div>
                          )}
                          {isShowBranch && (
                            <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                              <label htmlFor="branch_did">Branch DID</label>
                              <input
                                type="text"
                                className="form-control"
                                id="branch_did"
                                name="branch_did"
                                disabled
                                autoComplete="off"
                                value={formValues.branch_did}
                              />
                            </div>
                          )}
                        </>
                      ) : null}
                      {formValues?.report_driver &&
                      (REPORT_TYPE_DATE_RANGE_OPTION.includes(
                        formValues?.report_type
                      ) ||
                        REPORT_TYPE_DATE_RANGE_OPTION.includes(
                          formValues?.parent_report_name
                        )) ? (
                        <>
                          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                            <label htmlFor="report_from_date">From Date</label>
                            <input
                              type="date"
                              className="form-control"
                              id="report_from_date"
                              name="report_from_date"
                              autoComplete="off"
                              value={formValues?.report_from_date}
                              placeholder="mm/dd/yyyy"
                              onChange={handleChange}
                              min={minReportFromDate}
                              max={maxReportFromDate}
                            />
                            <div className="error-message-validation">
                              {formErrors.report_from_date}
                            </div>
                          </div>
                          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                            <label htmlFor="report_to_date">End Date</label>
                            <input
                              type="date"
                              className="form-control"
                              id="report_to_date"
                              name="report_to_date"
                              autoComplete="off"
                              placeholder="mm/dd/yyyy"
                              onChange={handleChange}
                              value={formValues?.report_to_date}
                              min={minReportToDate}
                              max={maxReportToDate}
                            />
                            <div className="error-message-validation">
                              {formErrors.report_to_date}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                    {formValues?.report_type &&
                    formValues?.report_type !== "" ? (
                      <div className="form-row mt-2">
                        <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                          <input
                            className="ml-2"
                            type="radio"
                            name="report_driver"
                            value={formValues?.report_driver}
                            checked={formValues?.report_driver}
                            onClick={(e) => {
                              setFormValues({
                                ...formValues,
                                report_driver: true,
                              });
                            }}
                            onChange={(e) => {
                              setFormValues({
                                ...formValues,
                                report_driver: true,
                              });
                            }}
                          />
                          <span className="col-lg-4">Canned Report</span>
                          <input
                            type="radio"
                            name="report_driver"
                            value={!formValues?.report_driver}
                            onClick={(e) => {
                              setFormValues({
                                ...formValues,
                                report_driver: false,
                              });
                            }}
                            checked={!formValues?.report_driver}
                            onChange={(e) => {
                              setFormValues({
                                ...formValues,
                                report_driver: false,
                              });
                            }}
                          />
                          <span className="col-lg-4">Ad hoc Report</span>
                        </div>
                      </div>
                    ) : null}

                    <div className="form-row mt-2 ml-4">
                      {!formValues?.report_driver &&
                        REPORT_ATTRIBUTIES.map((attribute, idx) => {
                          return (
                            <div
                              className="form-check truncateDataInCell col-lg-4 col-md-6 col-sm-12"
                              key={idx}
                            >
                              <input
                                key={idx}
                                type="checkbox"
                                id={`report_attributes_${idx}`}
                                autoComplete="off"
                                name={`${attribute}`}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setFormValues((prevState) => ({
                                      ...prevState,
                                      report_attribuites: [
                                        ...prevState.report_attribuites,
                                        e.target.name,
                                      ],
                                    }));
                                  } else {
                                    setFormValues((prevState) => ({
                                      ...prevState,
                                      report_attribuites:
                                        prevState.report_attribuites.filter(
                                          (item, i) => item !== e.target.name
                                        ),
                                    }));
                                  }
                                }}
                                checked={formValues?.report_attribuites.includes(
                                  attribute
                                )}
                                disabled={
                                  ((formValues.report_type ===
                                    CAPACITY_REPORT ||
                                    formValues.parent_report_name ===
                                      CAPACITY_REPORT) &&
                                    DISABLED_CAPACITY_ATTRIBUTES.includes(
                                      attribute
                                    )) ||
                                  (formValues.report_type !== CAPACITY_REPORT &&
                                    formValues.parent_report_name !==
                                      CAPACITY_REPORT &&
                                    DISABLED_COMMON_ATTRIBUTES.includes(
                                      attribute
                                    ))
                                }
                              />
                              <label
                                className="form-check-label pl-3"
                                htmlFor={`report_attributes_${idx}`}
                              >
                                {attribute}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                    {!formValues?.report_driver && (
                      <>
                        <div className="form-row mt-2">
                          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4 col-lg-12 col-md-12 col-sm-12 ">
                            <label htmlFor="order_of_attributes">
                              Order of Attributes
                            </label>
                            <textarea
                              style={{ overflowY: "scroll" }}
                              placeholder="Order of Attributes"
                              rows="4"
                              className="col-lg-12 col-md-12 col-sm-12 mt-0 pt-1 d-flex justify-content-center align-items-center text-area overflow-y-auto"
                              onChange={handleChange}
                              value={attributeListString}
                              id="order_of_attributes"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-row mt-2">
                          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                            <label htmlFor="new_report_name">
                              New Report Type Name
                            </label>
                            <input
                              type="text"
                              name="report_name"
                              className="form-control"
                              id="new_report_name"
                              autoComplete="off"
                              onChange={handleChange}
                              value={formValues?.report_name}
                              maxLength={25}
                            />
                            <div className="error-message-validation">
                              {formErrors.report_name}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="form-row mt-2">
                      <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                        {!formValues?.report_driver ? (
                          <button
                            type="file"
                            className="btn  btn-sm btn-primary"
                            onClick={onSaveReportType}
                          >
                            SAVE NEW REPORT TYPE
                          </button>
                        ) : (
                          <button
                            type="file"
                            className="btn  btn-sm btn-primary"
                            onClick={onSubmit}
                          >
                            SUBMIT
                          </button>
                        )}
                        <button
                          type="file"
                          className="btn  btn-sm btn-primary"
                          onClick={clearData}
                        >
                          CANCEL
                        </button>
                      </div>
                    </div>
                    <CustomModalFileFormat
                      show={show}
                      backdrop="static"
                      onCloseModal={handleClose}
                      onSubmit={onSelectionFileType}
                    />
                    {formValues?.report_driver &&
                    Boolean(showReportRecordsTable) ? (
                      reportRecords.length > 0 ? (
                        <>
                          <div className="row">
                            <div className="col-lg-7 col-md-7 col-sm-12">
                              <div
                                className="form-group row"
                                id="divActivityType"
                              >
                                <label
                                  className="d-flex col-sm-7 col-form-label input-label"
                                  id="lblAlignActivityType"
                                >
                                  {reportName}
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                              <div className="form-group row" id="divAlignbtn">
                                <button
                                  onClick={downloadReport}
                                  type="submit"
                                  className="float-end btn  btn-sm btn-primary col-lg-4 col-md-4 col-sm-4 pl-0 pr-0"
                                  // disabled={SetIsDownloading}
                                >
                                  {isDownloading ? (
                                    <div className="text-center py-5 alignself-center">
                                      <Spinner animation="border" />
                                    </div>
                                  ) : (
                                    "Download"
                                  )}
                                </button>
                                <CSVLink
                                  filename={reportName}
                                  data={reportRecords ? reportRecords : []}
                                  headers={reportHeaders}
                                  ref={csvLinkEl}
                                ></CSVLink>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className="row">
                            <div className="col-lg-7 col-md-7 col-sm-12">
                              <div
                                className="form-group row"
                                id="divActivityType"
                              >
                                <label
                                  className="d-flex col-sm-7 col-form-label input-label"
                                  id="lblAlignActivityType"
                                >
                                  No records found
                                </label>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    ) : (
                      <></>
                    )}
                  </>
                </MDBCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewReports;
