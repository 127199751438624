import React, { useState } from "react";
import "../style/documents.css"


const DocumentUpload = (props) => {
  const { onChange } = props;
  const onInputClick = (event) => {
    event.target.value = ''
  }
  return (
    <>
      <label className="custom-file-upload p-2 m-0"> Upload Document</label>

      <input className="filenameView" disabled value={" " + props.fileName}></input>

      <label id="uploadButton-1" htmlFor="file" className="button p-1">UPLOAD </label>

      <input type="file" id="file" name="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv, text/plain,"
        onChange={onChange} onClick={onInputClick} />

    </>
  );
};

export default DocumentUpload;
