import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";
import { CHECK_OUT } from "../constants/ApiUrlConstants";

export const saveCheckOut = async (payload, token) => {
  return axios
    .post(service.baseUrl + CHECK_OUT, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};
