import { MDBCard, MDBCardBody } from "mdbreact";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { WebcamCapture } from "../components/webcamComponent";
import { getActionList } from "../api/ActionService";
import { useNavigate } from "react-router-dom";
import DocumentHorizontal from "../components/DocumentHorizontal";
import CustomHeader from "../components/CustomHeader";
import Swal from "sweetalert2";
import SearchPanel from "../components/SearchPanel";
import { getStorageListOnsearch } from "../api/StorageListService";
import { getTamperEvidentBags } from "../api/CheckInServices";
import { getEmployeesOnsearch } from "../api/EmployeeService";
import { CustomAlert } from "../components/customAlert";
import PerformedBy from "../components/PerformedBy";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ConsoleError } from "../utils/ErrorUtils";
import uuid from "react-uuid";
import { saveShipmentData } from "../api/ShipmetServices";
import {
  SAVE_SHIPMENT_PAYLOAD,
  SHIPMENT_TEB_LIMIT,
} from "../constants/ShipmentConstants";
import { getUpdatedDateInUtc } from "../utils/DateUtils";
import ConfigData from "../config";
import { SearchSelectedListTable } from "../components/SearchSelectedListTable";
import { getAuthorizationDataModule } from "../api/AuthorizationConfigurationService";
import { getDateConfigureData } from "../api/ConfigureDatesService";
import { MODULES } from "../constants/Constants";
import Select from 'react-select';
import { fetchStorageOptions } from "../utils/StorageManagement";

function CreateShipment() {
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    storage_location: "",
    storage_unit: "",
    storage_location_id: "",
    from_storage_type: "",
    to_storage_type: "",
    tamperevidentbag_id: "",
    shipping_notes: "",
    employee_name: "",
    employee_designation: "",
    employee_id: "",
    shipment_start_date: "",
    shipment_estimated_date: "",
  });
  const [pictureList, setPictureList] = useState([]);
  const [storageTypeList, setStorageTypeList] = useState([]);
  const [isShowFromBranch, setIsShowFromBranch] = useState(false);
  const [isShowToBranch, setIsShowToBranch] = useState(false);
  const [toBranchOptionList, setToBranchOptionList] = useState([]);
  const [toBranchList, setToBranchList] = useState([]);
  const [tebOptionList, setTebOptionList] = useState([]);
  const [tebList, setTebList] = useState([]);
  const [showTebOptions, setShowTebOptions] = useState(true);
  const [tebSearchString, setTebSearchString] = useState("");
  const [isTebNameAssigned, setTebNameAssigned] = useState(false);
  const [isShowFromVault, setIsShowFromVault] = useState(false);
  const [isShowToVault, setIsShowToVault] = useState(false);
  const [isShowToWarehouse, setIsToWarehouse] = useState(false);
  const [toSearchPanelLabel, setToSearchPanelLabel] = useState("");
  const [toSearchPanelPlaceholder, setToSearchPanelPlaceholder] = useState("");

  const [formErrors, setFormErrors] = useState({});
  const [isModelEnabled, setIsModelEnabled] = useState(false);
  //eslint-disable-next-line
  const [fromStorageLocationType, setFromStorageLocationType] = useState("");
  const [toStorageLocationType, setToStorageLocationType] = useState("");
  const [shippingNotes, setShippingNotes] = useState("");
  const [fromVaultId, setFromVaultId] = useState("");
  const [fromWarehouseId, setFromWarehouseId] = useState("");
  const [toVaultId, setToVaultId] = useState("");
  const [toWarehouseId, setToWarehouseId] = useState("");
  //eslint-disable-next-line
  const [showToOptions, setShowToOptions] = useState(true);
  const [fromSearchString, setFromSearchString] = useState("");
  const [toSearchString, setToSearchString] = useState("");
  const [isToStorageNameAssigned, setToStorageNameAssigned] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const [employeeOptionList, setEmployeeOptionList] = useState([]);

  const [employeeList, setEmployeeList] = useState([]);

  const [authorizerShowOptions, setAuthorizerShowOptions] = useState(true);
  const [authorizerSearchString, setAuthorizerSearchString] = useState("");
  //eslint-disable-next-line
  const [authorizerId, setAuthorizerId] = useState("");
  const [isAuthorizerAssigned, setIsAuthorizerAssigned] = useState(false);
  const [selectedTebList, setSelectedTebList] = useState([]);
  const [selectedAuthorizerList, setSelectedAuthorizerList] = useState([]);
  const [isAuthorization, setIsAuthorization] = useState();
  const [lastTimelineStatus, setLastTimelineStatus] = useState();
  const [fromLocationName, setFromLocationName] = useState("");
  const [fromBranchId, setFromBranchId] = useState("");
  const [limit, setLimit] = useState("");

  const [fromStorageList, setFromStorageList] = useState([]);
  const [fromstorageLocation, setFromStorageLocation]= useState({});
  const [fromPage, setFromPage] = useState(1);
  const [toPage, setToPage] = useState(1);
  const [toStorageList, setToStorageList] = useState([]);
  const [toStorageTypeList, setToStorageTypeList] = useState([]);
  const [toStorageLocation, setToStorageLocation]= useState({});

  //eslint-disable-next-line
  const [formData, setFormData] = useState({
    step3: {
      Documentrows: [],
      filename: "",
      comments: "",
    },
  });

  const [fromStorageID, setFromStorageId] = useState([]);

  const onClickDeleteTEB = (item, index) => {
    const newSelectedTebList = [...selectedTebList]; // make a copy of the original array
    newSelectedTebList.splice(index, 1); // remove the element at the specified index
    setSelectedTebList(newSelectedTebList);
  };

  const onClickDeleteAuthorizer = (item, index) => {
    const newSelectedAuthorizerList = [...selectedAuthorizerList]; // make a copy of the original array
    newSelectedAuthorizerList.splice(index, 1); // remove the element at the specified index
    setSelectedAuthorizerList(newSelectedAuthorizerList);
  };

  const [minShipmentStartDate, setMinShipmentStartDate] = useState();
  const [maxShipmentStartDate, setMaxShipmentStartDate] = useState();
  const [maxShipmentEstimatedDate, setMaxShipmentEstimatedDate] = useState();
  const [minShipmentEstimatedDate, setMinShipmentEstimatedDate] = useState();

  const fetchDateFieldData = async (selectedField) => {
    getDateConfigureData(
      selectedField,
      MODULES.SHIPMENT,
      currentUser?.jwtToken
    ).then((response) => {
      if (response?.status === 200) {
        if (selectedField === "Start Date") {
          setMinShipmentStartDate(
            getUpdatedDateInUtc(-response?.data?.min_value)
          );
          setMaxShipmentStartDate(
            getUpdatedDateInUtc(response?.data?.max_value)
          );
          setLimit(response?.data?.min_value);
        } else {
          setMaxShipmentEstimatedDate(
            getUpdatedDateInUtc(+response?.data?.max_value)
          );
          setMinShipmentEstimatedDate(
            getUpdatedDateInUtc(-response?.data?.min_value)
          );
        }
      }
    });
  };

  let navigate = useNavigate();

  const onChange = (e) => {
    const { name, value } = e.target;
    formValues[name] = value;
    setShowToOptions(false);
    if (formErrors[name] !== undefined && formErrors[name].length > 0) {
      delete formErrors[name];
    }
    if (name === "shipping_notes") {
      setShippingNotes(value);
    } else if (name === "from_storage_type") {
      setFromSearchString("");
      setFromBranchDid("");
      //TO-DO see how to decouple with storage type id
      if (e.target.value === "Branch") {
        setIsShowFromBranch(true);
        setIsShowFromVault(false);
        setFromStorageLocationType("branch");
      } else if (e.target.value === "Warehouse") {
        setIsShowFromBranch(false);
        setIsShowFromVault(false);
        setFromStorageLocationType("warehouse");
      } else if (e.target.value === "Vault") {
        setIsShowFromBranch(false);
        setIsShowFromVault(true);
        setFromStorageLocationType("vault");
      }
    } else if (name === "to_storage_type") {
      setToSearchString("");
      setToBranchDid("");
      delete formErrors["storage_location_id"];
      //TO-DO see how to decouple with storage type id
      if (e.target.value === "Branch") {
        setIsShowToBranch(true);
        setIsShowToVault(false);
        setIsToWarehouse(false);
        setToStorageLocationType("branch");
        setToSearchPanelLabel("Branch Name");
        setToSearchPanelPlaceholder("Search by Branch Name or DID");
      } else if (e.target.value === "Warehouse") {
        setIsShowToBranch(false);
        setIsShowToVault(false);
        setIsToWarehouse(true);
        setToStorageLocationType("warehouse");
        setToSearchPanelLabel("Warehouse Name");
        setToSearchPanelPlaceholder("Search by Warehouse Name");
      } else if (e.target.value === "Vault") {
        setIsShowToBranch(false);
        setIsShowToVault(true);
        setIsToWarehouse(false);
        setToStorageLocationType("vault");
        setToSearchPanelLabel("Vault Name");
        setToSearchPanelPlaceholder("Search by Vault Name");
      }
    }
  };
  function resetErrors() {
    setFormErrors({});
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "shipment_start_date") {
      setMinShipmentStartDate(getUpdatedDateInUtc(-limit));
      setMinShipmentEstimatedDate(e.target.value);
      setMaxShipmentEstimatedDate(getUpdatedDateInUtc(+limit));

      setFormValues({
        ...formValues,
        shipment_estimated_date: "",
        [name]: value,
      });
    } else if (name === "shipment_estimated_date") {
      setMinShipmentEstimatedDate(e.target.value);
      setFormValues({ ...formValues, [name]: value });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    if (formErrors[name] !== undefined && formErrors[name].length > 0) {
      delete formErrors[name];
    }
  };

  const validate = (values) => {
    resetErrors();
    const errors = {};
    if (selectedTebList.length < 1) {
      errors.teb_id = "TEB is required";
    }
    if (isAuthorization && selectedAuthorizerList.length < 1) {
      errors.authorizer = "Authorizer is required";
    }
    if (!values.to_storage_type) {
      errors.to_storage_type = "To Storage Type is required";
    }
    if (!values.shipping_notes) {
      errors.shipping_notes = "Comment is required";
    }
    if (!values.shipment_start_date) {
      errors.shipment_start_date = "Start Date is required";
    }
    if (!values.shipment_estimated_date) {
      errors.shipment_estimated_date = "Estimated Date is required";
    }
    if (values.storage_type === "branch") {
      if (!values.to_branch_did) {
        errors.storage_location_id = "Branch Name is required";
      }
    } else if (values.storage_type === "warehouse") {
      if (!toWarehouseId) {
        errors.storage_location_id = "Warehouse Name is required";
      }
    } else if (values.storage_type === "vault") {
      if (!toVaultId) {
        errors.storage_location_id = "Vault Name is required";
      }
    }
    return errors;
  };

  const setFromBranchDid = (branch_did) => {
    setFormValues({ ...formValues, from_branch_did: branch_did });
  };

  const setToBranchDid = (branch_did) => {
    setFormValues({ ...formValues, to_branch_did: branch_did });
  };

  const onCapturePicture = (picture, itemId) => {
    let addedImageList = [...pictureList, { src: picture, typeId: itemId }];
    setPictureList(addedImageList);
    setIsModelEnabled(false);
  };

  const OnDeletePicture = (index, itemId) => {
    let shallowCopyImageList = Array.from(pictureList);
    if (index > -1) {
      shallowCopyImageList.splice(index, 1);
    }
    setPictureList(shallowCopyImageList);
  };

  useEffect(() => {
    getActionList("StorageType", currentUser?.jwtToken)
      .then((response) => {
        setStorageTypeList(
          response?.map(function (storageTypeRow) {
            return storageTypeRow;
          })
        );
        setToStorageTypeList(
          response?.map(function (storageTypeRow) {
            return storageTypeRow;
          })
        );
      })
      .catch((error) => {
        ConsoleError(error);
      });

    getAuthorizationFlag();
    fetchDateFieldData("Start Date");
    fetchDateFieldData("End Date");
    //eslint-disable-next-line
  }, [currentUser?.jwtToken]);

  const saveAlert = (text, type, input) => {
    let title = "Saved";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      target
    ).then((result) => {
      if (result.value) {
        navigate("/viewAllShipment");
        if (input === "tote") {
        }
      }
    });
  };
  const failAlert = (text, type) => {
    let title = "Failed";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      target
    ).then((result) => {
      if (result.value) {
      }
    });
  };

  const onClose = (e) => {
    e.preventDefault();
    navigate("/viewAllShipment");
  };

  function save(payload) {
    saveShipmentData(payload, currentUser?.jwtToken)
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (
          response !== undefined &&
          (response.status === 201 || response.status === 200)
        ) {
          Swal.close();
          let type = "success";
          let text = "Shipment Created Successfully";

          saveAlert(text, type, "branch");
        } else {
          let type = "fail";
          let text = "Failed to save single shipment";
          failAlert(text, type);
        }
      })
      .catch((error) => {
        Swal.close();
        ConsoleError(error);
        let type = "fail";
        let text = "Failed to save shipment";
        failAlert(text, type);
      });
  }

  function getStorageList(type) {
    let storage_search_type = "";
    if (type === "from") {
      storage_search_type = isShowFromBranch
        ? "branch"
        : isShowFromVault
        ? "vault"
        : "warehouse";
    } else {
      storage_search_type = isShowToBranch
        ? "branch"
        : isShowToVault
        ? "vault"
        : "warehouse";
    }
    const searchString = type === "from" ? fromSearchString : toSearchString;
    getStorageListOnsearch(
      storage_search_type,
      searchString,
      currentUser?.jwtToken
    )
      .then((response) => {
        // TO-DO handle no data and update variable names as generic

        storage_search_type += "_id";
        setToBranchList(response);
        setToBranchOptionList(
          response
            ?.filter((item) => {
              return item[storage_search_type] !== fromStorageID;
            })
            ?.map(function (branchRow) {
              return (
                (branchRow.name ? branchRow.name : "") +
                (branchRow.branch_did ? " - " + branchRow.branch_did : "")
              );
            })
        );
      })
      .catch((error) => {
        ConsoleError(error);
      });
  }

  function getTebList() {
    getTamperEvidentBags(
      "shipment",
      tebSearchString,
      1,
      SHIPMENT_TEB_LIMIT,
      currentUser?.jwtToken,
      fromstorageLocation?.value,
      fromstorageLocation?.storageType
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response?.status === 200) {
          setTebList(response?.data);
          setTebOptionList(
            response?.data
              ?.filter(
                (row) =>
                  !selectedTebList.some(
                    (selectedTeb) =>
                      selectedTeb.tamper_evident_bag_id ===
                      row.tamper_evident_bag_id
                  )
              )
              .map(function (teb) {
                return teb?.tamper_evident_bag_number;
              })
          );
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  }
  const getEmployeesBySearch = () => {
    const approvalAccessFlag = true;
    const authorizerAcessFlag = null;
    const auditAccessFlag = null;
    getEmployeesOnsearch(
      approvalAccessFlag,
      authorizerAcessFlag,
      auditAccessFlag,
      authorizerSearchString,
      currentUser?.jwtToken
    )
      .then((response) => {
        response = response?.data;
        setEmployeeList(response);
        setEmployeeOptionList(
          response
            .filter(
              (employee) => employee.employee_id !== currentUser?.employee_id
            )
            .filter(
              (row) =>
                !selectedAuthorizerList.some(
                  (authorizer) => authorizer.employee_id === row.employee_id
                )
            )
            .map(function (emp) {
              return (
                (emp.employee_name ? emp.employee_name : "") +
                (emp.employee_email ? " - " + emp.employee_email : "") +
                (emp.employee_org_id ? " - " + emp.employee_org_id : "")
              );
            })
        );
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };
  useEffect(() => {
    const latest_record = selectedTebList[0]?.activity_list
      ?.filter((activity) => activity.event === "check_in") // Get all check_in activities
      ?.sort(
        (a, b) => new Date(b.create_datetime) - new Date(a.create_datetime)
      )?.[0]?.information; // Sort them by create_datetime in descending order

    setLastTimelineStatus(latest_record);
    if (latest_record?.branch_storage) {
      setFromStorageLocationType("branch");
      setIsShowFromBranch(true);
      setFromBranchDid(latest_record?.branch_storage?.branch?.branch_did);
      setFromBranchId(latest_record?.branch_storage?.branch?.branch_id);
      setFromStorageId(latest_record?.branch_storage?.branch?.branch_id);
      setFromLocationName(latest_record?.branch_storage?.branch?.name);
    } else if (latest_record?.warehouse_storage) {
      setFromStorageLocationType("warehouse");
      setFromWarehouseId(
        latest_record?.warehouse_storage?.warehouse?.warehouse_id
      );
      setFromStorageId(
        latest_record?.warehouse_storage?.warehouse?.warehouse_id
      );
      setFromLocationName(latest_record?.warehouse_storage?.warehouse?.name);
    } else if (latest_record?.vault_storage) {
      setFromStorageLocationType("vault");
      setFromVaultId(latest_record?.vault_storage?.vault?.vault_id);
      setFromStorageId(latest_record?.vault_storage?.vault?.vault_id);
      setFromLocationName(latest_record?.vault_storage?.vault?.name);
    } else if (!latest_record) {
      setFromStorageLocationType("");
      setFromBranchDid("");
      setFromStorageId("");
      setFromLocationName("");
      setFromLocationName("");
      setFromWarehouseId("");
      setFromVaultId("");
      setLastTimelineStatus();
      setIsShowFromBranch();
    }
    //eslint-disable-next-line
  }, [selectedTebList]);

  useEffect(() => {
    if (!isToStorageNameAssigned && toSearchString.length > 0) {
      getStorageList("to");
    }
    //eslint-disable-next-line
  }, [toSearchString]);

  useEffect(() => {
    if (!isTebNameAssigned && tebSearchString.length > 0) {
      getTebList();
    }
    //eslint-disable-next-line
  }, [tebSearchString]);

  useEffect(() => {
    if (!isAuthorizerAssigned && authorizerSearchString.length > 0) {
      getEmployeesBySearch();
    }
    //eslint-disable-next-line
  }, [authorizerSearchString]);
  //eslint-disable-next-line
  function getBranchId(branchList, compareValue) {
    let branchSelected = {};
    branchSelected = branchList.find((item) => {
      return item.branch_did === compareValue;
    });
    return branchSelected?.branch_id;
  }

  function showProcessing() {
    Swal.fire({
      title: "Processing, please wait",
      html: "loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
  }

  function populateSaveShipmentPayload() {
    let payload = SAVE_SHIPMENT_PAYLOAD;
    payload.tamper_evident_bags = selectedTebList.map(
      (teb) => teb.tamper_evident_bag_id
    );
    payload.start_date = formValues.shipment_start_date;
    payload.estimated_date = formValues.shipment_estimated_date;
    // if (fromStorageLocationType === "warehouse") {
    //   payload.from_location = fromWarehouseId;
    // } else if (fromStorageLocationType === "branch") {
    //   payload.from_location = fromBranchId;
    // } else if (fromStorageLocationType === "vault") {
    //   payload.from_location = fromVaultId;
    // }
    payload.from_location = fromstorageLocation?.value;
    payload.to_location = toStorageLocation?.value;
    // if (toStorageLocationType === "warehouse") {
    //   payload.to_location = toWarehouseId;
    // } else if (toStorageLocationType === "branch") {
    //   payload.to_location = getBranchId(toBranchList, formValues.to_branch_did);
    // } else if (toStorageLocationType === "vault") {
    //   payload.to_location = toVaultId;
    // }
    payload.notes = formValues.shipping_notes;
    payload.pictures = pictureList;
    payload.documents = documentList;
    payload.performed_by_id = currentUser?.employee_id;
    payload.authorizer_id = selectedAuthorizerList.map(
      (authorizer) => authorizer.employee_id
    );
    payload.from_storage_type = fromstorageLocation?.storageType;
    payload.to_storage_type = toStorageLocation?.storageType;
    // payload.to_storage_type = toStorageLocationType;
    return payload;
  }
  const onSubmit = () => {
    formValues["from_storage_type"] = toStorageLocationType;
    let errors = validate(formValues);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      showProcessing();
      let payload = populateSaveShipmentPayload();
      save(payload);
    } else {
      return false;
    }
  };

  /**
   * Function to handle updates the comments made to the document
   */
  const handleUpdateDocumentComment = (documentId, value) => {
    let shallowCopyDocumentList = Array.from(documentList);
    const docIndex = shallowCopyDocumentList.findIndex(
      (doc) => doc.documentId === documentId
    );
    shallowCopyDocumentList[docIndex].comments = value;
    setDocumentList(shallowCopyDocumentList);
  };

  /**
   * Function to read files & set to props
   */
  const handleAddDocument = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        const docSrcArray = reader.result.split(";")[0];
        let val = document.getElementById("input_document");
        let file = val.files.item(0).name;
        const documentItem = {
          documentId: uuid(),
          comments: "",
          src: reader.result,
          docType: docSrcArray,
          fileName: file,
        };
        handleNewDocument(documentItem);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  /**
   * Function to handle new documents
   */
  const handleNewDocument = (document) => {
    let addedDocumentList = [...documentList, document];
    setDocumentList(addedDocumentList);
  };
  /**
   * Function to handle delete documents
   */
  const handleDeleteDocument = (documentId) => {
    let shallowCopyDocList = Array.from(documentList);
    const docIndex = shallowCopyDocList.findIndex(
      (doc) => doc.documentId === documentId
    );

    if (docIndex > -1) {
      shallowCopyDocList.splice(docIndex, 1);
    }
    setDocumentList(shallowCopyDocList);
  };
  const getAuthorizationFlag = async () => {
    getAuthorizationDataModule(MODULES.SHIPMENT, currentUser?.jwtToken).then(
      (response) => {
        if (response?.status === 200) {
          setIsAuthorization(response?.data?.authorization_flag);
          if (!response?.data?.authorization_flag) {
            setSelectedAuthorizerList([
              { employee_id: response?.data?.authorization?.employee_id },
            ]);
          }
        }
      }
    );
  };

  const handleTebSearch = (location, searchValue) => {
    const isFromStorage = location === "from_storage";
    const storageList = isFromStorage ? fromStorageList : toStorageList;
    const hasMatchingOption = storageList.some(option =>
      option?.name.toLowerCase().includes(searchValue?.toLowerCase())
    );

    if (!hasMatchingOption && searchValue?.trim() !== '') {      
      fetchStorageoptionsOnScroll(location, searchValue);
    }
  };

const fetchStorageoptionsOnScroll = async (location, searchValue) => {
  // Common variables and function logic
  const isFromStorage = location === "from_storage";
  const page = isFromStorage ? fromPage : toPage;
  const storageList = isFromStorage ? fromStorageList : toStorageList;
  const storageType = isFromStorage ? formValues?.from_storage_type : formValues?.to_storage_type;

  const response = await fetchStorageOptions(
    "", 
    page, 
    storageList, 
    storageType, 
    currentUser?.jwtToken
  );

  // Update state based on location
  if (isFromStorage) {
    setFromStorageList((prevList) => [...prevList, ...response["locations"]]);
    setFromPage(response["UpdatedpageNo"]);
  } else {
    setToStorageList((prevList) => [...prevList, ...response["locations"]]);
    setToPage(response["UpdatedpageNo"]);
  }
};

  useEffect(() => {
    if(formValues?.from_storage_type){

      fetchStorageoptionsOnScroll("from_storage", "")
    }
  }, [formValues?.from_storage_type, formValues?.from_storage_type]);


  useEffect(() => {
    if(formValues?.to_storage_type){

      fetchStorageoptionsOnScroll("to_storage", "")
    }
  }, [formValues?.to_storage_type]);

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 body">
        <div className="row flex p-4">
          <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden pt-5">
            <div className="row pt-1 pb-2">
              <div className="d-flex justify-content-end">
                <div className="p-0">
                  {currentUser?.shipment_save_bulk_upload_access_flag && (
                    <Link to="/bulkShipment">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        id="bulk-shipment-button"
                      >
                        BULK SHIPMENT
                      </button>
                    </Link>
                  )}
                </div>
                <div className="p-0">
                  <Link to="/viewAllShipment">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      id="shipment-records-button"
                    >
                      SHIPMENT RECORDS
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <MDBCard className=" p-0 m-0 overflow-hidden">
              <CustomHeader id="single" label={"SINGLE SHIPMENT"} />
              <MDBCardBody id="single-scroll">
                <div className="row col-lg-12 justify-content-center">
                  SHIPPING DETAILS
                </div>
                <div className="row pb-2 card-border">
                  <>
                    
                    <form className="mt-2">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="form-group" style={{width:"30%"}}>
                      <label htmlFor="storageType" className="form-label">
                        Select From Storage Type
                      </label>
                      <select
                          className="custom-select mr-sm-2"
                          id="from_storage_type"
                          name="from_storage_type"
                          defaultValue=""
                          value={formValues?.from_storage_type}
                          onChange={(e)=>{onChange(e);
                          setFormValues({ ...formValues, ["from_storage_type"]: e.target.value });
                          setFromStorageList([]);}}
                        >
                          <option value="">Choose...</option>
                          {storageTypeList?.map((opt, index) => {
                            return (
                              <option
                                key={index}
                                value={opt?.action_id}
                                data-testid="from_storage_type"
                              >
                                {opt?.action}
                              </option>
                            );
                          })}
                        </select>
                    </div>

                    <div className="form-group" style={{width:"30%"}} >
                      <label htmlFor="storageLocation" className="form-label">
                        Select From Storage Name
                      </label>
                      <Select
                        isDisabled={fromStorageList.length === 0}
                        id="storageLocation"
                        onInputChange={(e)=>{handleTebSearch("from_storage", e)}}
                        value={fromstorageLocation}
                        onChange={(selectedOption) => {
                          if(selectedOption?.value!==toStorageLocation?.value){
                            let errors = {from_storage_locatton: "" };

                              setFormErrors((prevErrors) => ({
                                ...prevErrors,
                                ...errors, // Add/overwrite errors
                              }));
                              setFromStorageLocation(selectedOption)                          }
                          else{
                            let errors = {from_storage_locatton: "From location and to location can't be same." };

                              setFormErrors((prevErrors) => ({
                                ...prevErrors,
                                ...errors, // Add/overwrite errors
                              }));
                          }
                        }}
                        options={fromStorageList.map((record) => ({
                          value:
                            record?.branch_id || record?.Vault_id || record?.Warehouse_id, // Unique identifier
                            label: record?.name, // Display field
                            storageType: record?.storage_type,
                            branchDid: record?.branch_did || null,
                        }))}
                        isClearable        

                        onMenuScrollToBottom={() => fetchStorageoptionsOnScroll("from_storage")} // Trigger fetch when scrolled to bottom
                        menuPlacement="auto"
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            maxHeight: "200px", // Set max height for scrollable dropdown
                            overflowY: "auto", // Enable scrolling
                          }),
                        }}
                        placeholder="Select Storage Location"
                      />
                      <div className="error-message-validation">
                          {formErrors?.from_storage_locatton}
                        </div>
                    </div>
                    
                    <div className="form-group" style={{ width: "30%" }}>
                      {fromstorageLocation?.branchDid !==  undefined && (
                        <>
                          <label htmlFor="branch_did" className="form-label">
                            Branch DID
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="branch_did"
                            name="branch_did"
                            disabled
                            autoComplete="off"
                            value={fromstorageLocation?.branchDid}
                            placeholder="No Branch DID available"
                          />
                        </>
                      )}
                    </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="form-group " style={{width:"30%"}}>
                        <label htmlFor="to_storage_type">
                          {" "}
                          Select To Storage Type
                        </label>
                        <select
                          className="custom-select mr-sm-2"
                          id="to_storage_type"
                          name="to_storage_type"
                          defaultValue=""
                          value={formValues?.to_storage_type}
                          onChange={(e)=>{onChange(e);
                            setFormValues({ ...formValues, ["to_storage_type"]: e.target.value });
                            setToStorageList([]);}}
                          // onChange={onChange}
                        >
                          <option value="">Choose...</option>
                          {toStorageTypeList?.map((opt, index) => {
                            return (
                              <option
                                key={index}
                                value={opt.action_id}
                                data-testid="to_storage_type"
                              >
                                {opt.action}
                              </option>
                            );
                          })}
                        </select>
                        <div className="error-message-validation">
                          {formErrors?.to_storage_type}
                        </div>
                      </div>
                      {/* {isShowFromBranch && (
                        <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                          <label htmlFor="from_branch_did">Branch DID</label>
                          <input
                            type="text"
                            className="form-control"
                            id="from_branch_did"
                            name="from_branch_did"
                            disabled
                            autoComplete="off"
                            value={
                              selectedTebList.length > 0
                                ? lastTimelineStatus?.branch_storage?.branch
                                    ?.branch_did
                                : ""
                            }
                          />
                        </div>
                      )} */}
                      <div className="form-group" style={{width:"30%", position: "relative" }}>
                      <label htmlFor="to_storage_type">
                          {" "}
                          Select To Storage Name
                        </label>
                        <Select
                        isDisabled={toStorageList.length === 0}
                        id="storageLocation"
                        onInputChange={(e)=>{handleTebSearch("to_storage", e)}}
                        value={toStorageLocation}
                        onChange={(selectedOption) => {
                          if(selectedOption?.value!==fromstorageLocation?.value){
                            let errors = {storage_location_id: "" };

                              setFormErrors((prevErrors) => ({
                                ...prevErrors,
                                ...errors, // Add/overwrite errors
                              }));
                            setToStorageLocation(selectedOption)
                          }
                          else{
                            let errors = {storage_location_id: "From location and to location can't be same." };

                              setFormErrors((prevErrors) => ({
                                ...prevErrors,
                                ...errors, // Add/overwrite errors
                              }));
                          }
                        }}
                        options={toStorageList.map((record) => ({
                          value:
                            record?.branch_id || record?.Vault_id || record?.Warehouse_id, // Unique identifier
                            label: record?.name, // Display field
                            storageType: record?.storage_type,
                            branchDid: record?.branch_did || null,
                        }))}
                        isClearable
                        onMenuScrollToBottom={() => fetchStorageoptionsOnScroll("to_storage")} // Trigger fetch when scrolled to bottom
                        menuPlacement="auto"
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            maxHeight: "200px", // Set max height for scrollable dropdown
                            overflowY: "auto", // Enable scrolling
                          }),
                        }}
                        placeholder="Select Storage Location"
                      />
                            {/* <SearchPanel
                              // label={toSearchPanelLabel}
                              placeholder={toSearchPanelPlaceholder}
                              options={toBranchOptionList}
                              setOptionList={setToBranchOptionList}
                              setShowOptions={setShowToOptions}
                              name="storage_location_id"
                              id="storage_location_id"
                              showOptions={showToOptions}
                              onFocusSearch={(e) => {
                                setToSearchString("");
                                setToBranchDid("");
                              }}
                              handleChange={(e) => {
                                if (isToStorageNameAssigned) {
                                  setToSearchString(
                                    e.target.value.charAt(e.length - 1)
                                  );
                                  setToStorageNameAssigned(false);
                                } else {
                                  setToSearchString(e.target.value);
                                }
                                setShowToOptions(true);
                              }}
                              searchString={toSearchString}
                              onSelectValue={(selectedStorage) => {
                                setToStorageNameAssigned(true);
                                setShowToOptions(false);
                                if (isShowToBranch) {
                                  const branch = toBranchList.filter(
                                    (branchRow) =>
                                      branchRow?.branch_did ===
                                      selectedStorage.split(" - ")[1]
                                  )[0];
                                  setToSearchString(branch.name);
                                  setToBranchDid(branch?.branch_did);
                                } else if (isShowToVault) {
                                  const vault = toBranchList.filter(
                                    (vaultRow) =>
                                      vaultRow?.name === selectedStorage
                                  )[0];
                                  setToSearchString(vault.name);

                                  setToVaultId(vault?.vault_id);
                                } else {
                                  const warehouse = toBranchList.filter(
                                    (warehouseRow) =>
                                      warehouseRow?.name === selectedStorage
                                  )[0];
                                  setToSearchString(warehouse.name);
                                  setToWarehouseId(warehouse?.warehouse_id);
                                }
                                if (formErrors) {
                                  delete formErrors["storage_location_id"];
                                }
                              }}
                            /> */}

                            <div className="error-message-validation">
                              {formErrors?.storage_location_id}
                            </div>
                      </div>

                        {/* <div className="form-group " style={{width:"30%"}}>
                      {isShowToBranch && (
                        <>
                          <label htmlFor="to_branch_did">Branch DID</label>
                          <input
                            type="text"
                            className="form-control"
                            id="to_branch_did"
                            name="to_branch_did"
                            disabled
                            autoComplete="off"
                            value={tostorageLocation?.branchDid}
                          />
                          </>
                        )}
                        </div> */}

                        <div className="form-group" style={{ width: "30%" }}>
                      {toStorageLocation?.branchDid !==  undefined && (
                        <>
                          <label htmlFor="branch_did" className="form-label">
                            Branch DID
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="branch_did"
                            name="branch_did"
                            disabled
                            autoComplete="off"
                            value={toStorageLocation?.branchDid}
                            placeholder="No Branch DID available"
                          />
                        </>
                      )}
                    </div>
                    </div>
                    <div className="d-flex flex-row  align-items-center">

                      <div className="form-group " style={{ width: "30%" }}>
                        <label htmlFor="shipment_start_date">Start Date</label>
                        <input
                          type="date"
                          className="form-control"
                          id="shipment_start_date"
                          name="shipment_start_date"
                          autoComplete="off"
                          value={formValues.shipment_start_date}
                          placeholder="mm/dd/yyyy"
                          onChange={handleChange}
                          min={minShipmentStartDate}
                          max={maxShipmentStartDate}
                        />
                        <div className="error-message-validation">
                          {formErrors.shipment_start_date}
                        </div>
                      </div>

                      <div className="form-group " style={{ width: "30%",marginLeft:"5%" }}>
                        <label htmlFor="shipment_estimated_date">
                          Estimated Date of Arrival
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="shipment_estimated_date"
                          name="shipment_estimated_date"
                          autoComplete="off"
                          placeholder="mm/dd/yyyy"
                          onChange={handleChange}
                          value={formValues.shipment_estimated_date}
                          min={minShipmentEstimatedDate}
                          max={maxShipmentEstimatedDate}
                          disabled={!formValues.shipment_start_date} 
                        />
                        <div className="error-message-validation">
                          {formErrors?.shipment_estimated_date}
                        </div>
                      </div>
                    </div>



                      {/* <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                        <label htmlFor="from_storage_type">
                          From Storage Type
                        </label>
                        <input
                          className="form-control"
                          id="from_storage_type"
                          disabled={true}
                          value={
                            fromStorageLocationType === "branch"
                              ? "Branch"
                              : fromStorageLocationType === "warehouse"
                              ? "Warehouse"
                              : fromStorageLocationType === "vault"
                              ? "Vault"
                              : ""
                          }
                        />
                        <div className="error-message-validation">
                          {formErrors?.from_storage_type}
                        </div>
                      </div> */}

                      {/* <>
                        <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                          <label htmlFor="from_storage_name">
                            From Storage Name
                          </label>
                          <input
                            id="from_storage_name"
                            className="form-control"
                            disabled={true}
                            value={
                              selectedTebList.length > 0 ? fromLocationName : ""
                            }
                          />
                        </div>
                      </> */}

                      
                      

                      
                    </form>
                    <div className="form-row">
                    
                      <div className="col-md-6 col-lg-6 col-sm-12" >
                        <SearchPanel  
                          
                          // isDisabled={(fromstorageLocation?.value && toStorageLocation?.value) == undefined}
                          label={t("labels.teb")}
                          setOptionList={setTebOptionList}
                          setShowOptions={setShowTebOptions}
                          placeholder="Search by TEB Number"
                          options={tebOptionList}
                          name="teb_id"
                          id="teb_id"
                          showOptions={showTebOptions}
                          onFocusSearch={(e) => {
                            setTebSearchString("");
                          }}
                          handleChange={(e) => {
                            if(((fromstorageLocation?.value && toStorageLocation?.value) !== undefined)){
                              let errors = {teb_id: "" };

                              setFormErrors((prevErrors) => ({
                                ...prevErrors,
                                ...errors, // Add/overwrite errors
                              }));

                              if (e.target.value.length > 0   ) {
                                setShowTebOptions(true);
                                if (isTebNameAssigned) {
                                  setTebSearchString(
                                    e.target.value.charAt(e.length - 1)
                                  );
                                  setTebNameAssigned(false);
                                } else {
                                  setTebSearchString(e.target.value);
                                }
                              } else {
                                setTebSearchString(
                                  e.target.value.charAt(e.length - 1)
                                );
                                setShowTebOptions(false);
                              }
                            }else{
                              
                              let errors = {teb_id: "Please select the from and to storage details." };

                              setFormErrors((prevErrors) => ({
                                ...prevErrors,
                                ...errors, // Add/overwrite errors
                              }));
                             
                            }
                          }}
                          searchString={tebSearchString}
                          onSelectValue={(selectedTeb) => {
                            setTebNameAssigned(true);
                            setShowTebOptions(false);
                            const teb = tebList.filter(
                              (row) =>
                                row?.tamper_evident_bag_number === selectedTeb
                            )[0];
                            setTebSearchString("");
                            setSelectedTebList([teb, ...selectedTebList]);
                            if (
                              formErrors["teb_id"] !== undefined &&
                              formErrors["teb_id"].length > 0
                            ) {
                              delete formErrors["teb_id"];
                            }
                          }}
                        />
                        <i
                          className="fa fa-barcode float-right pr-2 input-scanner-icon h2"
                          aria-hidden="true"
                          title="Scan Tamper Evident Bag"
                        ></i>
                        <div className="error-message-validation errorMessage">
                          {formErrors?.teb_id}
                        </div>
                      </div>
                      {selectedTebList.length > 0 && (
                        <SearchSelectedListTable
                          label={"TAMPER EVIDENT BAGS"}
                          headers={ConfigData.viewSelectedTEBDetails}
                          recordsList={selectedTebList}
                          responseKeys={ConfigData.viewTEBListResponseKeys}
                          onClickDeleteItem={(teb, idx) =>
                            onClickDeleteTEB(teb, idx)
                          }
                        />
                      )}
                    </div>
                  </>
                  {isAuthorization && (
                    <>
                      <div className="form-row">
                        <div className="col-md-6 col-lg-6 col-sm-12">
                          <SearchPanel
                            label="Authorizer Assigned"
                            placeholder="Search by Employee Name or Email"
                            options={employeeOptionList}
                            setOptionList={setEmployeeOptionList}
                            setShowOptions={setAuthorizerShowOptions}
                            showOptions={authorizerShowOptions}
                            onFocusSearch={(e) => {
                              setAuthorizerSearchString("");
                            }}
                            handleChange={(str) => {
                              if (str.target.value.length > 0) {
                                setAuthorizerShowOptions(true);
                                if (isAuthorizerAssigned) {
                                  setAuthorizerSearchString(
                                    str.target.value.charAt(str.length - 1)
                                  );
                                  setIsAuthorizerAssigned(false);
                                } else {
                                  setAuthorizerSearchString(str.target.value);
                                }
                              } else {
                                setAuthorizerSearchString(
                                  str.target.value.charAt(str.length - 1)
                                );
                                setIsAuthorizerAssigned(true);
                                setAuthorizerShowOptions(false);
                              }
                            }}
                            searchString={authorizerSearchString}
                            onSelectValue={(selectedAuthorizer) => {
                              setIsAuthorizerAssigned(true);
                              setAuthorizerShowOptions(false);
                              const authorizer = employeeList.filter(
                                (emp) =>
                                  emp.employee_email ===
                                  selectedAuthorizer.split(" - ")[1]
                              )[0];
                              setAuthorizerSearchString("");
                              setSelectedAuthorizerList([
                                authorizer,
                                ...selectedAuthorizerList,
                              ]);
                              if (
                                formErrors["authorizer"] !== undefined &&
                                formErrors["authorizer"].length > 0
                              ) {
                                delete formErrors["authorizer"];
                              }
                            }}
                          />
                          <div className="error-message-validation errorMessage">
                            {formErrors?.authorizer}
                          </div>
                        </div>
                      </div>
                      {selectedAuthorizerList.length > 0 && (
                        <SearchSelectedListTable
                          label={"AUTHORIZERS"}
                          headers={ConfigData.viewSelectedAuthorizerDetails}
                          recordsList={selectedAuthorizerList}
                          responseKeys={
                            ConfigData.viewAuthorizerListResponeKeys
                          }
                          onClickDeleteItem={(authorizer, idx) =>
                            onClickDeleteAuthorizer(authorizer, idx)
                          }
                        />
                      )}
                    </>
                  )}
                  <div className="form-row mt-3">
                    <div className="form-group col-md-6 col-xs-12  col-lg-6 pe  -4">
                      <label htmlFor="shipping_notes">Shipping Comments</label>
                      <textarea
                        className="form-control"
                        name="shipping_notes"
                        id="shipping_notes"
                        onChange={onChange}
                        value={shippingNotes}
                        placeholder="Enter Shipping Comments"
                        rows="5"
                        style={{ width: "100%" }}
                      ></textarea>
                      <div className="error-message-validation">
                        {formErrors?.shipping_notes}
                      </div>
                    </div>
                    <div
                      className="form-group col-md-6 col-xs-4  col-lg-2 px-4"
                      id={`document_group_1`}
                    >
                      <input
                        type="file"
                        id={`input_document`}
                        multiple
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleAddDocument(e);
                        }}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                      ></input>

                      <label
                        htmlFor={`input_document`}
                        className="btn btn-primary btn-sm mt-3"
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        <i
                          id={`icon_upload`}
                          className="fa fa-upload fa-1x me-1"
                          aria-hidden="true"
                        ></i>{" "}
                        Document
                      </label>
                    </div>
                    <div className="form-group col-md-6 col-xs-4  col-lg-2 px-4">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm mt-3"
                        onClick={() => {
                          setIsModelEnabled(true);
                        }}
                      >
                        <i className="fa fa-camera me-1" aria-hidden="true"></i>
                        Picture
                      </button>
                    </div>
                  </div>
                </div>

                {documentList.length > 0 && (
                  <>
                    <div className="row col-lg-12 justify-content-center">
                      DOCUMENTS
                    </div>
                    <div className="row card-border">
                      <DocumentHorizontal
                        itemId="shipment"
                        id={`documents_horizontal`}
                        documentList={documentList}
                        onDeleteDocument={handleDeleteDocument}
                        onUpdateDocComment={handleUpdateDocumentComment}
                      />
                    </div>
                  </>
                )}
                {pictureList.length > 0 && (
                  <div className="row col-lg-12 justify-content-center">
                    PICTURES
                  </div>
                )}
                <div
                  className={pictureList.length > 0 ? "row card-border" : ""}
                >
                  <div className="form-row align-items-end">
                    <div className="form-group col-12 px-4">
                      <WebcamCapture
                        itemId="shipment"
                        pictureList={pictureList}
                        onCapturePicture={(picture, itemId) =>
                          onCapturePicture(picture, itemId)
                        }
                        onDeletePicture={(index, itemId) =>
                          OnDeletePicture(index, itemId)
                        }
                        isModelEnabled={isModelEnabled}
                        disableModal={() => {
                          setIsModelEnabled(false);
                        }}
                      ></WebcamCapture>
                    </div>
                  </div>
                </div>
                <PerformedBy />
                <div className="fieldArea7 pt-2 pb-2 justify-content-center">
                  <button
                    type="file"
                    className="btn  btn-sm btn-primary"
                    onClick={onClose}
                  >
                    CANCEL
                  </button>
                  <button
                    type="file"
                    className="btn  btn-sm btn-primary"
                    onClick={onSubmit}
                  >
                    SUBMIT
                  </button>
                </div>
              </MDBCardBody>
            </MDBCard>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateShipment;
