import { Auth } from "aws-amplify";
import axios from "axios";
import service from "./service";
import { getHeader, getAdminHeader } from "../utils/Headers";
import {
  ADD_USER,
  EMPLOYEE_LOGIN,
  SAFECHIEF_ADMIN_LOGIN,
  FORGOT_PASSWORD,
} from "../constants/ApiUrlConstants";
import { ConsoleError } from "../utils/ErrorUtils";

export const cognitoSignOut = async () => {
  return Auth.signOut()
    .then((response) => {
      sessionStorage.clear();
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};

export const createUser = async (user, token) => {
  return axios
    .post(service.baseUrl + EMPLOYEE_LOGIN, user, {
      method: "POST",
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};

export const userverification = async (email) => {
  const encodedEmail = encodeURIComponent(email);
  return axios
  .get(`${service.baseUrl}${FORGOT_PASSWORD}?email=${encodedEmail}`, {
      headers: getHeader(""),
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};

export const addUser = async (user, token) => {
  return axios
    .post(service.baseUrl + ADD_USER, user, {
      method: "POST",
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};

export const createSafechiefAdmin = async (user, token) => {
  return axios
    .post(service.baseUrl + SAFECHIEF_ADMIN_LOGIN, user, {
      method: "POST",
      headers: getAdminHeader(token),
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};
