import React, { useEffect, useState } from 'react';
import { getEmployeesDesignationType,updateEmployeesRoleFlag } from '../api/EmployeeService';
import { useSelector } from "react-redux";
import { ConsoleError } from "../utils/ErrorUtils";
import ROLES_ACCESS_FLAG from "../config";
import Swal from 'sweetalert2';
import { CustomAlert } from './customAlert';
import { ROLE_FLAGS_UPDATED_SUCCESS,ROLE_FLAGS_UPDATED_FAILED } from '../constants/MessagesConstants';

function Roles() {
    const [employeeDesignationList, setEmployeeDesignationList] = useState()
    const [formValues, setFormValues] = useState()
    const [isactiveButtons, setIsActveButtons] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const currentUser = useSelector(
        (reduxData) => reduxData?.userReducer?.value?.userDetails
    );
    const getEmployeesDesignationTypeList = () => {
        setIsLoading(true); // Start loading
    
        getEmployeesDesignationType(currentUser?.jwtToken)
            .then((response) => {
                setEmployeeDesignationList(response);
                setFormValues(response);
            })
            .catch((error) => {
                ConsoleError(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getEmployeesDesignationTypeList();
    }, [])

    useEffect(() => {
        if(JSON.stringify(employeeDesignationList) === JSON.stringify(formValues)){
            setIsActveButtons(false)
        }else{
            setIsActveButtons(true)
        }
    }, [formValues])

    function handelFlagChange(index, key) {
        setFormValues(prevFlags => {
            const newFlags = [...prevFlags];
            const updatedObject = {
                ...newFlags[index], // copy the original object
                [key]: !prevFlags[index][key] // update the specified key
            };
            newFlags[index] = updatedObject;
            return newFlags;
        });
    }

    const submitFlagChange = async() =>{
        Swal.fire({
            title: "Processing, please wait",
            html: "loading...",
            allowOutsideClick: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
        updateEmployeesRoleFlag(formValues,currentUser?.jwtToken)
        .then((response)=>{
            Swal.close();
            if(response?.status === 200){
                showSuccessAlert(ROLE_FLAGS_UPDATED_SUCCESS)
                getEmployeesDesignationTypeList()
            }else {
                showFailAlert(ROLE_FLAGS_UPDATED_FAILED)
            }
        })
    }

    const showSuccessAlert = (response) => {
        CustomAlert(
            "Saved",
            response,
            "success",
            "OK",
            false,
            document.getElementById("swal-div")
        )
    };

    const showFailAlert = (response) => {
        CustomAlert("Failed", response, "error", "OK", false, document.getElementById("swal-div"));
    };

    return (<>
        <div className="row white-background pt-3 pb-4">
            <div className="form-group col-md-4 col-xs-4 col-lg-4 px-4  ">
                <button className='btn btn-primary btn-sm float-right mb-3'
                 disabled={!isactiveButtons}
                 onClick={() => {submitFlagChange()
                }}>Submit</button>
                <button className='btn btn-primary btn-sm float-right mb-3'
                disabled={!isactiveButtons}
                 onClick={() => {getEmployeesDesignationTypeList()
                }}>Reset</button>

            {isLoading ? (
                            <div className="loader-container">
                            <div className="spinner-border text-light" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            </div>
                        ):

                <table
                    className="table_wrapper">
                    <thead>
                        <tr id="cbc-table-heading">
                            <th style={{position:"sticky", zIndex:100,left:0, background:"#6F7D95"}}>DESIGNATION</th>
                            {ROLES_ACCESS_FLAG?.designationAccessFlag?.map((item, index) => {
                                return (
                                    // <th key={item + index} className='px-3' title={ROLES_ACCESS_FLAG?.disclaimers[item] || "No description available"}>{item}</th>
                                    <th key={item + index} className='px-3' style={{ position: "relative" }}>
                                    {item}
                                    <span
                                      className="ml-2"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={ROLES_ACCESS_FLAG?.disclaimers[item] || "No description available"}
                                      style={{
                                        cursor: "pointer",
                                        // color: "#007bff",
                                        // fontSize: "14px",
                                        marginLeft: "5px",
                                        textDecoration: "underline",
                                      }}
                                    >
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                    </span>
                                  </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {formValues?.map((item, index) => {
                            return (
                                <tr>
                                    <td style={{position:"sticky", zIndex:100,left:0,background: "rgba(248,249,250)",}}>{item?.employee_designation}</td>
                                    {ROLES_ACCESS_FLAG?.designationAccessFlagTypes?.map((itemType) => {
                                        return (
                                            <td key={item + itemType + index} className='px-3'>
                                                <div className="form-check truncateDataInCell col-lg-4 col-md-6 col-sm-12">
                                                    <input
                                                        type="checkbox"
                                                        autoComplete="off"
                                                        checked={formValues[index][itemType]}
                                                        id={itemType + index}
                                                        onChange={(e) => { handelFlagChange(index, itemType) }}
                                                    />
                                                </div>
                                            </td>
                                        )
                                    })
                                    }
                                </tr>
                            )
                        })}
                    </tbody>
                </table>}

            </div>
        </div>
    </>
    )
}

export default Roles