import { getHeader } from "../utils/Headers";
import service from "./service";
import axios from "axios";
import { BULK_CHECKOUT_DATA } from "../constants/ApiUrlConstants";
import { ConsoleError } from "../utils/ErrorUtils";
export const saveBulkCheckOutData = async (data, token) => {
  return axios
    .post(service.baseUrl + BULK_CHECKOUT_DATA, data, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
    });
};
