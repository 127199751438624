import { createSlice } from "@reduxjs/toolkit";
import { notificationUtil } from "../utils/NotificationUtils";

const initialStateValue = { notificationData: []};


export const notificationServiceSlice = createSlice({
    name: "notificationService",
    initialState: {value:initialStateValue} ,
    reducers : {
        notificationService : (state,action)=>{
            state.value={notificationData: action.payload}
        }
    }
});

export const { notificationService } = notificationServiceSlice.actions;

export default notificationServiceSlice.reducer;

