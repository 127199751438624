import React, { useState } from "react";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import "../style/signIn.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import CustomHeader from "../components/CustomHeader";
import { useSelector } from "react-redux";
import Swal from "sweetalert2"; // Import SweetAlert2
import { validatePassword } from "../utils/PasswordValidation";

const poolData = {
  UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
  ClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

const userPool = new CognitoUserPool(poolData);

const ChangePassword = () => {
  const navigate = useNavigate();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handlePasswordChange = (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    // Basic validation
    if (!oldPassword || !newPassword || !confirmPassword) {
      setError("Please fill in all fields");
      setIsLoading(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("New Password and Confirm Password do not match");
      setIsLoading(false);
      return;
    }
    
    const valid = validatePassword(newPassword);
    if (valid.length > 0) {
      setError(valid);
      setIsLoading(false);
      return;
    }

    const currentUserInstance = userPool.getCurrentUser();

    if (currentUserInstance) {
      currentUserInstance.getSession((err, session) => {
        if (err) {
          setIsLoading(false);
          if (err.message.includes("Token expired")) {
            Swal.fire({
              title: "Session Expired",
              text: "Your session has expired. Please log in to continue.",
              icon: "warning",
              confirmButtonText: "Log In",
            }).then(() => {
              navigate("/login"); // Redirect to login page
            });
            return;
          } else {
            Swal.fire({
              title: "Error!",
              text: "An error occurred while retrieving the session.",
              icon: "error",
              confirmButtonText: "OK",
            });
            return;
          }
        }

        currentUserInstance.changePassword(oldPassword, newPassword, (err) => {
          setIsLoading(false); // Stop loading
          if (err) {
            Swal.fire({
              title: "Failed",
              text: "Failed to change password. Please try again.",
              icon: "error",
              confirmButtonText: "OK",
            });
          } else {
            setError("");
            Swal.fire({
              title: "Success!",
              text: "Password successfully changed.",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              navigateBasedonRole();
              // navigate("/home");
            });
          }
        });
      });
    } else {
      setIsLoading(false);
      Swal.fire({
        title: "Error!",
        text: "User is not logged in. Please log in and try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };


  const resetForm = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setError("");
    setMessage("");
  };

  const navigateBasedonRole =()=>{
    if (currentUser?.role === "safechief_admin") {
      navigate("/all-tenants");
    } else {
      navigate("/home");
    }
  }
  const handleCancel = () => {
    resetForm();
    navigateBasedonRole();
    // navigate("/home");
  };

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-auto p-0 body">
      <div className="row flex pl-4 pt-4 pr-4 pb-0">
        <div className="col-lg-12 pb-3 pl-0 pr-0">
          <div className="row pt-1 pb-2 mt-5"></div>
          <MDBCard className="card p-0 m-0">
            <CustomHeader label={"CHANGE PASSWORD"} />
            <MDBCardBody id="cs-scroll">
              {isLoading && (
                <div className="loader-container">
                  <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              <form onSubmit={handlePasswordChange} className="card formHeight">
                <div className="row mt-4 card-border divMainSL">
                  <div className="row col-lg-12 justify-content-center pt-2 divPaddingHeader">
                    UPDATE PASSWORD
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6 col-xs-4 col-lg-4 px-4">
                      <label htmlFor="old_password">Old Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter Old Password"
                        value={oldPassword}
                        onChange={(e) => {
                          setOldPassword(e.target.value);
                          setError("");
                        }}
                        name="old_password"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-group col-md-6 col-xs-4 col-lg-4 px-4">
                      <label htmlFor="new_password">New Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter New Password"
                        value={newPassword}
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                          setError("");
                        }}
                        name="new_password"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-group col-md-6 col-xs-4 col-lg-4 px-4">
                      <label htmlFor="confirm_password">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                          setError("");
                        }}
                        name="confirm_password"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div class="container">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4"></div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                          {error && (
                            <div className="error-message">{error}</div>
                          )}
                      </div>
                      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4"></div>
                    </div>
                  </div>
                </div>
                <div className="fieldArea7 pt-2">
                  <button
                    className="btn btn-sm"
                    id="cancleBtn"
                    type="reset"
                    value="Reset"
                    style={{ color: "#fff" }}
                    onClick={()=>handleCancel()}
                  >
                    CANCEL
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary">
                    {"SUBMIT"}
                  </button>
                </div>
              </form>
              {/* {message && <div className="success-message">{message}</div>} */}
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
