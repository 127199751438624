function PageNotFound() {
  return (
    <>
      <div className="d-md-flex align-items-center justify-content-center pl-5 pr-5 pt-5 row ">
        <div className="align-items-center pt-5">
          <div className="row white-background">
            <div className="col-12 py-3 ">Oops Something went wrong !!</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
