import React, { useState, useEffect } from "react";
import "../style/header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faClipboardList,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import checkin from "../assets/checkin.png";
import shipcontents from "../assets/shipcontents.png";
import genralrequest from "../assets/genralrequest.png";
import audit from "../assets/audit.png";
import checkout from "../assets/checkout-white.png";
import approval from "../assets/approval.png";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { useSelector } from "react-redux";
import {
  ADMIN_USER,
  ALL_USER,
  SUPERADMIN_USER,
  SAFECHIEF_ADMIN,
} from "../constants/RoleContants";
import { faUnlock } from "@fortawesome/free-solid-svg-icons";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faClipboardQuestion } from "@fortawesome/free-solid-svg-icons";
import { faWarehouse } from "@fortawesome/free-solid-svg-icons";
import { PrintDisable } from "../utils/PrintDisable";

const useWindowSize = () => {
  const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerHeight, window.innerWidth]);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return size;
};

const SideMenu = () => {
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  PrintDisable(currentUser?.print_access_flag);
  const [height, width] = useWindowSize();
  const details = {
    role: currentUser?.role,
    name: currentUser?.employee_name,
    designation: currentUser?.role,
  };

  const drawerToggleClickHandler = () => {
    document.getElementById("sidebar").style.marginLeft = "0px";
  };
  const backdropClickHandler = () => {
    document.getElementById("sidebar").style.marginLeft = "-280px";
  };
  if (document.getElementById("sidebar") && width > 760) {
    document.getElementById("sidebar").style.marginLeft = "0px";
  } else if (document.getElementById("sidebar") && width < 760) {
    document.getElementById("sidebar").style.marginLeft = "-280px";
  }

  const formatString = (input) => {
    if (input?.toLowerCase() === "superadmin") {
      return "Super Admin"
    }
  
    return input?.charAt(0)?.toUpperCase() + input?.slice(1)?.toLowerCase();
  };

  const path = useLocation();
  return (
    <div>
      <div
        style={{ position: "relative" }}
        onClick={() => drawerToggleClickHandler()}
      >
        <span
          style={{
            position: "absolute",
            top: "80px",
            cursor: "pointer",
            zIndex: 1,
          }}
          className="toggleIcon"
          id="sideBarToggle"
        >
          <FontAwesomeIcon
            icon={faBars}
            style={{ height: "30px", width: "30px" }}
          />
        </span>
      </div>
      <div className="wrapper">
        <nav id="sidebar" className="sidebar">
          <button className="box" onClick={() => backdropClickHandler()}>
            <h2>x</h2>
          </button>
          <div className="sidebar-header">
            <div className="d-flex flex-column align-items-center text-center ">
              <img id="logo" src={logo} alt="app logo" />
              <span className="profileName pt-4">
                {details.name}
                <br />
                {formatString(details.designation)}
              </span>
            </div>
            <hr className="line"></hr>
          </div>

          {SAFECHIEF_ADMIN.includes(details.role) ? (
            <ul className="list-unstyled components">
              <li className="nav-item">
                <Link
                  as={Link}
                  to="/all-tenants"
                  className={path.pathname === "/all-tenants" ? "active" : null}
                >
                  <FontAwesomeIcon
                    icon={faUserTie}
                    style={{
                      color: "#ffffff",
                      height: "21px",
                      width: "21px",
                    }}
                  />
                  <span className="media-body">&nbsp;TENANTS</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  as={Link}
                  to="/subscriptions"
                  className={
                    path.pathname === "/subscriptions" ? "active" : null
                  }
                >
                  <FontAwesomeIcon
                    icon={faClipboardList}
                    style={{
                      color: "#ffffff",
                      height: "21px",
                      width: "21px",
                    }}
                  />
                  <span className="media-body">&nbsp;SUBSCRIPTIONS</span>
                </Link>
              </li>
            </ul>
          ) : (
            ALL_USER.includes(details?.role) && (
              <div id="sideMenuOptions">
                <ul className="list-unstyled components">
                  <li className="nav-item ms-1">
                    <Link
                      as={Link}
                      to="/home"
                      className={path.pathname === "/" ? "active" : null}
                    >
                      <i className="fa fa-home pl-2 h5" aria-hidden="true"></i>
                      <span className="media-body">&nbsp;HOME</span>
                    </Link>
                  </li>
                  {currentUser?.force_open_access_flag && (
                    <li className="nav-item ms-1">
                      <Link
                        as={Link}
                        to="/forceOpen"
                        className={
                          path.pathname === "/forceOpen" ? "active" : null
                        }
                      >
                        <i
                          className="fa fa-unlock pl-2 h5"
                          aria-hidden="true"
                        ></i>
                        <span className="media-body">&nbsp;FORCE OPEN</span>
                      </Link>
                    </li>
                  )}

                  {currentUser?.force_open_access_flag && (
                    <li className="nav-item ms-2">
                      <Link
                        as={Link}
                        to="/viewAllPreOpen"
                        className={
                          path.pathname === "/viewAllPreOpen" ||
                          path.pathname === "/preOpen"
                            ? "active"
                            : null
                        }
                      >
                        <FontAwesomeIcon
                          icon={faUnlock}
                          style={{
                            color: "#ffffff",
                            height: "21px",
                            width: "21px",
                          }}
                        />
                        <span className="media-body">&nbsp;PRE OPEN</span>
                      </Link>
                    </li>
                  )}
                  {currentUser?.check_in_get_records_access_flag && (
                    <li className="nav-item">
                      <Link
                        as={Link}
                        to="/viewAllCheckIn"
                        className={
                          path.pathname === "/viewAllCheckIn" ||
                          path.pathname === "/customBulkCheckin" ||
                          path.pathname === "/singleCheckIn" ||
                          path.pathname === "/bulkCheckIn" ||
                          path.pathname === "/checkinHistory"
                            ? "active"
                            : null
                        }
                      >
                        <img
                          className="material-icons pmd-list-icon pmd-sm"
                          id="menu-icon"
                          src={checkin}
                          alt="checkin"
                        />
                        <span className="media-body">&nbsp;CHECK IN</span>
                      </Link>
                    </li>
                  )}
                  {currentUser?.shipment_get_records_access_flag && (
                    <li className="nav-item">
                      <Link
                        as={Link}
                        to="/viewAllShipment"
                        className={
                          path.pathname === "/viewAllShipment" ||
                          path.pathname === "/bulkShipment" ||
                          path.pathname === "/createShipment" ||
                          path.pathname === "/shipmentHistory"
                            ? "active"
                            : null
                        }
                        style={{ display: "flex", flexWrap: "wrap" }}
                      >
                        <img
                          className="material-icons pmd-list-icon pmd-sm"
                          id="menu-icon"
                          src={shipcontents}
                          alt="shipcontents"
                        />
                        <span className="media-body">&nbsp;SHIP CONTENT</span>
                      </Link>
                    </li>
                  )}
                  {currentUser?.audit_get_records_access_flag && (
                    <li className="nav-item">
                      <Link
                        as={Link}
                        to="/auditView"
                        className={
                          path.pathname === "/auditView" ||
                          path.pathname === "/createAudit" ||
                          path.pathname === "/auditHistory"
                            ? "active"
                            : null
                        }
                      >
                        <img
                          className="material-icons pmd-list-icon pmd-sm"
                          id="menu-icon"
                          src={audit}
                          alt="audit"
                        />
                        <span className="media-body">&nbsp;AUDIT</span>
                      </Link>
                    </li>
                  )}
                  {currentUser?.check_out_get_records_access_flag && (
                    <li className="nav-item ">
                      <Link
                        as={Link}
                        to="/viewAllCheckout"
                        className={
                          path.pathname === "/checkOutView" ||
                          path.pathname === "/createcheckout"
                            ? "active"
                            : null
                        }
                      >
                        <img
                          className="material-icons pmd-list-icon pmd-sm"
                          id="menu-icon"
                          src={checkout}
                          alt="approval"
                        />

                        <span className="media-body">&nbsp;CHECK OUT</span>
                      </Link>
                    </li>
                  )}

                  {
                  currentUser?.storage_management_get_records_access_flag ? (
                    <li className="nav-item ms-2">
                      <Link
                        as={Link}
                        to="/storagelocation"
                        className={
                          path.pathname === "/storagelocation" ||
                          path.pathname === "/createstoragelocation"
                            ? "active"
                            : null
                        }
                        style={{ display: "flex", flexWrap: "wrap" }}
                      >
                        <FontAwesomeIcon
                          icon={faWarehouse}
                          style={{
                            color: "#ffffff",
                            height: "21px",
                            width: "21px",
                          }}
                        />
                        <div className="media-body storageLocationText">
                          &nbsp;STORAGE MANAGEMENT
                        </div>
                      </Link>
                    </li>
                  ) : null}

                  {/* {SUPERADMIN_USER.includes(details.role) ||
                  ADMIN_USER.includes(details.role) ? ( */}
                  {currentUser?.approval_get_records_access_flag ? (
                    <li className="nav-item">
                      <Link
                        as={Link}
                        to="/viewAllApprovals"
                        className={
                          path.pathname === "/approvals" ? "active" : null
                        }
                      >
                        <img
                          className="material-icons pmd-list-icon pmd-sm"
                          id="menu-icon"
                          src={approval}
                          alt="approval"
                        />
                        <span className="media-body">&nbsp;APPROVALS</span>
                      </Link>
                    </li>
                  ) : null}
                  {currentUser?.reports_access_flag && (
                    <li className="nav-item">
                      <Link
                        as={Link}
                        to="/reports"
                        className={
                          path.pathname === "/reports" ? "active" : null
                        }
                      >
                        <img
                          className="material-icons pmd-list-icon pmd-sm"
                          id="menu-icon"
                          src={genralrequest}
                          alt="genralrequest"
                        />
                        <span className="media-body">&nbsp;REPORTS</span>
                      </Link>
                    </li>
                  )}
                  {currentUser?.enquiry_get_records_access_flag && (
                    <li className="nav-item ms-2">
                      <Link
                        as={Link}
                        to="/enquiryview"
                        className={
                          path.pathname === "/enquiryview" ||
                          path.pathname === "/createEnquiry" ||
                          path.pathname === "/enquiryHistory"
                            ? "active"
                            : null
                        }
                      >
                        <FontAwesomeIcon
                          icon={faClipboardQuestion}
                          style={{
                            color: "#ffffff",
                            height: "21px",
                            width: "21px",
                          }}
                        />

                        <span className="media-body">&nbsp;ENQUIRIES</span>
                      </Link>
                    </li>
                  )}

                  {currentUser?.bag_status_access_flag && (
                    <li className="nav-item ms-2">
                      <Link
                        as={Link}
                        to="/uploadbagsfsi"
                        className={
                          path.pathname === "/uploadbagsfsi" ? "active" : null
                        }
                      >
                        <FontAwesomeIcon
                          icon={faFileArrowUp}
                          style={{
                            color: "#ffffff",
                            height: "21px",
                            width: "21px",
                          }}
                        />
                        <span className="media-body">&nbsp;BAG UPLOAD</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            )
          )}
        </nav>
      </div>
    </div>
  );
};

export default SideMenu;
