import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";
import {
  VIEW_ALL_APPROVAL_REQUESTS,
  UPDATE_APPROVAL,
  SPECIFIC_APPROVAL_REQUEST,
} from "../constants/ApiUrlConstants";
import { ConsoleError } from "../utils/ErrorUtils";

export const getViewApprovalsData = async (
  type,
  pageSize,
  performedById,
  token,
  pageNo,
  searchValue
) => {
  return axios
    .get(
      service.baseUrl +
        VIEW_ALL_APPROVAL_REQUESTS +
        "?performed_by_id=" +
        performedById +
        "&type=" +
        type +
        "&page_no=" +
        pageNo +
        "&page_size=" +
        pageSize +
        (searchValue ? "&search_value=" + searchValue : ""),
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const pagination = async (pageNumber, empId, token) => {
  return axios
    .get(
      service.baseUrl +
        VIEW_ALL_APPROVAL_REQUESTS +
        "?page=" +
        pageNumber +
        "&emp_id=" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const postApproveStatus = async (payload, token) => {
  return axios
    .patch(service.baseUrl + UPDATE_APPROVAL, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};

export const getApprovalDetails = async (approvalId, token) => {
  return axios
    .get(
      service.baseUrl +
        SPECIFIC_APPROVAL_REQUEST +
        "?approval_id=" +
        approvalId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
