import { MDBCard, MDBCardBody } from "mdbreact";
import React, { useState } from "react";
import CustomHeader from "./CustomHeader";
import ConfigDate from '../config'

export const SearchSelectedListTable = (props) => {
  const [searchType, setSearchType] = useState("")
  const [currentFieldBagId,setCurrentFieldBagId] = useState("")
  return (
    <>
      <MDBCard className="card mb-3">
        <CustomHeader label={props.label} />
        <MDBCardBody>

          {props?.screen && props?.screen === "CheckIn" && props?.isShowWarehouse ? (<>
            <table
              className="table_wrapper"
              id="cbc-scroll1"
              >
              <thead>
                <tr
                  key={"header"}
                  id="cbc-table-heading"
                  style={{
                    position: "sticky",
                    top: 0,
                    background: "#6F7D95",
                    zIndex: 1,
                  }}
                  >
                  {Object.entries(props?.headers).map(([key, value], index) => {
                    return (
                      <th
                      key={index}
                      scope="col"
                      className={
                        "tableHeading1"
                      }
                      >
                        {" "}
                        {value}{" "}
                      </th>

);
                  })}
                  {Object.entries(ConfigDate.selectedTEBDetailsWarehousekeys).map(([key, value], index) => {
                    return (<th scope="col" className="tableHeading1" key={key + index}>
                      {value.toUpperCase()}
                    </th>)
                  })}
                  <th style={{
                    position: "sticky",
                    right: 0,
                    zIndex: 1,
                    background: "#6F7D95",
                  }}>ACTION</th>

                </tr>
              </thead>
              <tbody
                id="cs-scroll"
                style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                <>
                  {props?.recordsList &&
                    props?.recordsList.map((item, idx) => {
                      return (
                        <tr key={idx} style={{ background: 'white', alignContent: 'center' }} >
                          {props?.responseKeys.map((responseKey, index) => {
                            return (
                              <td
                              className={
                                "tableHeading1"
                              }
                              key={index}
                              >
                                {" "}
                                {item[responseKey]}{" "}
                              </td>
                            );
                          })}
                          {Object.entries(ConfigDate.selectedTEBDetailsWarehousekeys).map(([key, value], index) => {
                            let previousKey = index > 0 ? Object.keys(ConfigDate.selectedTEBDetailsWarehousekeys)[index - 1] : null;
                            let details = props?.warehouseToteDetails?.find((toteItem) => {
                              return toteItem?.teb_id === item?.tamper_evident_bag_id
                            })
                            const errorMessage = props?.errors?.[details?.teb_id]?.[key];
                            return (<td key={index + key}>
                              <input type='text' className="form-control" placeholder={value} key={key + index}
                                disabled={
                                  index === 0 ? (props?.warehouseId ? false : true) : (details[previousKey] !== "" ? false : true)
                                }
                                value={details[key]}
                                onFocus={()=>{
                                  setSearchType(key)
                                  props?.clearData(key,details?.teb_id)
                                  setCurrentFieldBagId(details?.teb_id)
                                  props?.setWarehouseItemSuggestions([])
                                  props?.setSuggestions([])
                                }}
                                onChange={(e) => {
                                  let parentKey = previousKey?.split("_")[0] + "_id"
                                  let parentId = index === 0 ? props?.warehouseId : details[parentKey]
                                  props?.handleItemInputChange(key,details?.teb_id,e.target.value)
                                  props?.handleInputChange(parentId, value?.split(" ")[0], e.target.value);
                                }}
                                />
                                    {
                                      errorMessage && (
                                        <div
                                          style={{
                                            color: "red", 
                                            fontSize: "12px", 
                                            marginTop: "5px", 
                                            display: "block", 
                                          }}
                                        >
                                          {errorMessage}
                                        </div>
                                      )}

                              {details?.teb_id === currentFieldBagId && searchType == key && props?.warehouseItemSuggestions.length > 0 && (<>

                                <div
                                  className="list-group search-result-panel"
                                  // style={{ position: "absolute" }}
                                  >
                                  {props.warehouseItemSuggestions.map((item, index) => {
                                    return (
                                      <div className="search-result-panel-row" key={index} onClick={(e) => {
                                        props.selectWarehouseItem(item, key.split("_")[0],details?.teb_id)
                                      }}>
                                        <p className="pr-3 pl-2 py-2 mb-0">{item}</p>
                                      </div>
                                    );
                                  })}
                                </div>

                              </>)}
                            </td>)
                          })}
                          <td style={{
                            position: "sticky",
                            right: 0,
                            zIndex: 1,
                            background: 'rgba(248,249,250)'
                          }} className="tableDetails">
                            <i
                              className="fa fa-2x fa-trash mt-2"
                              aria-hidden="true"
                              onClick={() => props.onClickDeleteItem(item, idx)}
                            ></i>
                          </td>
                        </tr>
                      );
                    })}
                </>
              </tbody>
            </table>
          </>) :
            (<div className="table-responsive" style={{ maxHeight: "500px" }}>
              <table className="table safeChiefTable">
                <thead
                  className="headerArea"
                  style={{ position: "sticky", top: 0 }}
                >
                  <tr style={{ position: "sticky", background: "#6F7D95" }}>
                    {Object.entries(props?.headers).map(([key, value], index) => {
                      return (
                        <th
                          key={index}
                          scope="col"
                          className={
                            index === 0 ? "tableHeading1" : "tableHeading"
                          }
                        >
                          {" "}
                          {value}{" "}
                        </th>
                      );
                    })}
                    <th scope="col" className="tableHeading">
                      {" "}
                      ACTION{" "}
                    </th>
                  </tr>
                </thead>

                <tbody
                  id="cs-scroll"
                  style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                  <>
                    {props?.recordsList &&
                      props?.recordsList.map((item, idx) => {
                        return (
                          <tr className="table-row" key={idx}>
                            {props?.responseKeys.map((responseKey, index) => {
                              return (
                                <td
                                  className={
                                    index === 0 ? "tableHeading1" : "tableHeading"
                                  }
                                  key={index}
                                >
                                  {" "}
                                  {item[responseKey]}{" "}
                                </td>
                              );
                            })}
                            <td className="tableDetails">
                              <i
                                className="fa fa-2x fa-trash mt-2"
                                aria-hidden="true"
                                onClick={() => props.onClickDeleteItem(item, idx)}
                              ></i>
                            </td>
                          </tr>
                        );
                      })}
                  </>
                </tbody>
              </table>
            </div>
            )}
        </MDBCardBody>
      </MDBCard >
    </>
  );
};
