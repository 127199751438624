import React from "react";
import "../style/header.css";

function SearchPanel(props) {
  const handleBlur = () => {
    function handleClick(event) {
      const clickedElement = event.target;
      if (
        clickedElement.id === "suggestions-result-panel" ||
        clickedElement.id === "suggestions-result-item"
      ) {
        return;
      }
      props?.setShowOptions(false);
      props?.setOptionList([]);

      // Removing the click event listener after handling the click to avoid future conflicts
      document.removeEventListener("click", handleClick);
    }

    document.addEventListener("click", handleClick);
  };

  return (
    <>
      <label htmlFor="search-input-bar"> {props.label} </label>
      <input
        id="search-input-bar"
        className="form-control pr-0 pl-5"
        placeholder={props.placeholder}
        autoComplete="off"
        data-testid = "search_panel"
        value={props.searchString}
        onChange={props.handleChange}
        onFocus={props.onFocusSearch}
        onBlur={() => {
          handleBlur();
        }}
      />
      <i className="fa fa-search input-search-icon pl-3" aria-hidden="true"></i>
      {props.showOptions && props.options && props.options.length > 0 && (
        <div
          className="list-group search-result-panel"
          style={{ position: "absolute" }}
          data-testid = "search-result-panel"
        >
          {props.options.map((item, key) => {
            return (
              <div
                className="search-result-panel-row"
                id="suggestions-result-panel"
                key={key}
                onClick={(e) => {
                  props.onSelectValue(item);
                }}
              >
                <p className="pr-3 pl-2 py-2 mb-0" id="suggestions-result-item"  data-testid = "search-results"
>
                  {item}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
export default SearchPanel;
