export function prepareOwnerData(owner){
    let data ={owner_id: owner?.owner_id ? owner?.owner_id : "",
      first_name: owner?.first_name ? owner?.first_name : "",
      middle_name: owner?.middle_name? owner?.middle_name: "",
      last_name: owner?.last_name ? owner?.last_name: "",
      address_id:owner?.address?.address_id ? owner?.address?.address_id : "",
      street:owner?.address?.street_1 ? owner?.address?.street_1: "",
      city:owner?.address?.city ? owner?.address?.city : "",
      state:owner?.address?.state ? owner?.address?.state : "",
      zipcode:owner?.address?.zipcode ? owner?.address?.zipcode : "",
      owner_fields_id: owner?.owner_fields?.owner_fields_id ? owner?.owner_fields?.owner_fields_id : "",
      owner_relation_code: owner?.owner_fields?.owner_relation_code ? owner?.owner_fields?.owner_relation_code : "",
      owner_property_type_code: owner?.owner_fields?.owner_property_type_code ? owner?.owner_fields?.owner_property_type_code : "",
      dormancy_id: owner?.owner_fields?.dormancy_id ? owner?.owner_fields?.dormancy_id : "",
      scra: owner?.owner_fields?.scra,
      company_name: owner?.owner_fields?.company_name ? owner?.owner_fields?.company_name : "",}

      return data
}