import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";
import {
  GET_TAMPEREVIDENT_BAG,
  SAVE_AUDIT,
} from "../constants/ApiUrlConstants";
import { ConsoleError } from "../utils/ErrorUtils";

export const GetTamperEvidentBags = async (sdbBoxNo, token) => {
  return axios
    .get(
      service.baseUrl +
        GET_TAMPEREVIDENT_BAG +
        "?safedepositbox_number=" +
        sdbBoxNo,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};

export const saveAuditRecord = async (data, token) => {
  return axios
    .post(service.baseUrl + SAVE_AUDIT, data, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
      throw error;
    });
};
