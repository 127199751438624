export const MAX_REQUESTED_DATE = 1;

export const MIN_REQUESTED_DATE = -6;

export const ENQUIRY_TEB_LIMIT = 20;

export const SAVE_ENQUIRY_PAYLOAD = {
  tamper_evident_bags: [],
  requested_date: "",
  requested_by: "",
  request_summary: "",
  notes: "",
  pictures: [
    {
      src: "",
    },
  ],
  documents: [
    {
      src: "",
      comments: "",
      fileName: "",
    },
  ],
  performed_by_id: "",
};
export const ENQUIRY_HISTORY_LIMIT = 20;
