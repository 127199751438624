import React, { useState, useEffect } from "react";
// import Pagination from "./Pagination";  // Adjust the import path as needed
import { Box, Grid } from "@mui/material";
import { MDBCardFooter } from "mdbreact";
import { Spinner } from "react-bootstrap";
import Pagination from "./Pagination";
import "../style/tablecomponent.css";
import { UsbTwoTone } from "@material-ui/icons";

const ResponsiveTable = ({
  columns = [],
  data = [],
  actions = null,
  noRecordsMessage = "No records found.",
  isLoading = false,
  paginationData = {},
}) => {
  const {
    currentPage,
    pages,
    onNextClick,
    onPreviousClick,
    onPageClick,
    setRowsPerPage,
    rowsPerPage
  } = paginationData;


  // ! Need to make the pagination logic more efficient
  // ! Currently uses pagination logic from parent component
  // const [currentPage, setCurrentPage] = useState(1); // Track the current page
  // const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
  // const [paginatedData, setPaginatedData] = useState([]); // Store paginated data

  // const totalPages = Math.ceil(data.length / rowsPerPage);

  // Logic to handle page click and update displayed rows
  // const handlePageClick = (page) => {
  //   setCurrentPage(page);
  // };

  // const handlePreviousClick = () => {
  //   if (currentPage > 1) setCurrentPage(currentPage - 1);
  // };

  // const handleNextClick = () => {
  //   if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  // };

  // const handleRowsPerPageChange = (newRowsPerPage) => {
  //   setRowsPerPage(parseInt(newRowsPerPage, 10));
  //   setCurrentPage(1); // Reset to first page when rows per page change
  // };

  // Paginate the data based on the current page and rows per page
  // useEffect(() => {
  //   const startIndex = (currentPage - 1) * rowsPerPage;
  //   const endIndex = startIndex + rowsPerPage;
  //   setPaginatedData(transformedData.slice(startIndex, endIndex));
  // }, [data, currentPage, rowsPerPage]);

  // const TablePageOptions = [10, 15, 20];

  //   const transformedData = data.map(item => {
  //     return {
  //         name: item.name,
  //         'address.city': item.address.city,
  //         'address.state': item.address.state,
  //         storage_type: item.storage_type,
  //         'authorized_by.employee_email': item.authorized_by.employee_email,
  //     };
  // });

  return (
<div className="responsive-table-container">
  <div className="responsive-table-wrapper scrollable">
    {isLoading ? (
      <div className="loading-container">
        <Spinner animation="border" />
      </div>
    ):
    <table className="responsive-table">
      <thead className="responsive-table-header">
        <tr>
          {columns.map((col, index) => (
            <th
              key={index}
              className={
                index === 0
                  ? "responsive-table-heading primary-column"
                  : "responsive-table-heading"
              }
            >
              {col.label}
            </th>
          ))}
          {actions && <th className="responsive-table-heading">Actions</th>}
        </tr>
      </thead>
      <tbody className="responsive-table-body">
        {!isLoading &&
          (data.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr key={rowIndex} className="responsive-table-row">
                {columns.map((col, colIndex) => (
                  <td
                    key={colIndex}
                    className={
                      colIndex === 0
                        ? "responsive-table-cell primary-column"
                        : "responsive-table-cell"
                    }
                  >
                    {col.accessor ? col.accessor(row) : row[col.key]}
                  </td>
                ))}
                {actions && (
                  <td className="responsive-table-cell action-column">
                    {actions(row, rowIndex)}
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length + (actions ? 1 : 0)}>
                <div className="no-records-container">{noRecordsMessage}</div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
    }
    <div className="pagination-container">
      <Pagination
        currentPage={currentPage}
        pages={pages} // Page numbers array
        onPageClick={onPageClick}
        onPreviousClick={onPreviousClick}
        onNextClick={onNextClick}
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
      />
    </div>
  </div>
</div>

  );
};

export default ResponsiveTable;
