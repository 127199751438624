import React, { useState } from "react";
import Modal from "react-modal";
import { MDBCard, MDBCardBody, MDBCardHeader } from "mdbreact";

import "../style/CreateEnquiry.css";

function ApprovedModal(props) {
  const initialValues = {
    comments: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  //eslint-disable-next-line
  const [formErrors, setFormErrors] = useState({});
  const [comment, setcomment] = useState("");
  function handleComments(e) {
    setcomment(e.target.value);
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (formErrors[name] !== undefined && formErrors[name].length > 0) {
      delete formErrors[name];
    }
  }
  function onModalClose() {
    props.onCloseModal();
  }

  return (
    <Modal isOpen={props.IsModalOpened} ariaHideApp={false} id="approvalModal">
      <MDBCard>
        <MDBCardHeader className="card text-white justify-content-center form-heading text-center  mb-3">
          <div>
            <div onClick={onModalClose} className="close">
              x
            </div>
            <h6 className="  p-0 m-0">{props.dynData} </h6>
          </div>
        </MDBCardHeader>

        <MDBCardBody>
          <div className="col-lg-12  ">
            <div className="form-group row ">
              <label
                className="col-lg-3 col-sm-12  col-form-label input-label"
                htmlFor="approval_note"
              >
                Comments{" "}
              </label>
              <div className=" col-lg-9 col-sm-12">
                <textarea
                  id="approval_note"
                  value={comment}
                  className="formcontrol"
                  rows="3"
                  style={{ width: "100%" }}
                  name="approval_note"
                  onChange={handleComments}
                  required
                ></textarea>
              </div>
            </div>
          </div>

          <button
            className="btn  btn-sm btn-primary float-right"
            onClick={() => {
              setcomment("");
              props.onSubmit(props, comment);
            }}
          >
            SUBMIT
          </button>
        </MDBCardBody>
      </MDBCard>
    </Modal>
  );
}

export default ApprovedModal;
