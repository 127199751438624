import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getActionList } from "../api/ActionService";
import { getStorageListOnsearch } from "../api/StorageListService";
import SearchPanel from "../components/SearchPanel";
import "../style/customModal.css";
import { ConsoleError } from "../utils/ErrorUtils";
import CustomHeader from "./CustomHeader";
function CustomModal(props) {
  const { currentEventData } = props;
  const [activityType, setActivityType] = useState("");
  const [location, setLocation] = useState("");

  const [isShowBranch, setIsShowBranch] = useState(false);
  const [branchOptionList, setBranchOptionList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [storageTypeList, setStorageTypeList] = useState([]);
  const [isStorageTypeError, setIsStorageTypeError] = useState(false);
  const [isStorageLocationError, setIsStorageLocationError] = useState(false);
  const [isShowVault, setIsShowVault] = useState(false);
  const [isShowWarehouse, setIsWarehouse] = useState(false);
  const [searchPanelLabel, setSearchPanelLabel] = useState("");
  const [searchPanelPlaceholder, setSearchPanelPlaceholder] = useState("");
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const [storageLocationType, setStorageLocationType] = useState("");
  const [showOptions, setShowOptions] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [isStorageNameAssigned, setStorageNameAssigned] = useState(false);
  const [formValues, setFormValues] = useState({
    storage_location_id: "",
    storage_type: "",
  });

  const setBranchDid = (branch_did) => {
    setFormValues({ ...formValues, branch_did: branch_did });
  };
  function getStorageList() {
    const storage_search_type = isShowBranch
      ? "branch"
      : isShowVault
      ? "vault"
      : "warehouse";
    getStorageListOnsearch(
      storage_search_type,
      searchString,
      currentUser?.jwtToken
    )
      .then((response) => {
        // TO-DO handle no data and update variable names as generic
        setBranchList(response);
        setBranchOptionList(
          response?.map(function (branchRow) {
            return (
              (branchRow.name ? branchRow.name : "") +
              (branchRow.branch_did ? " - " + branchRow.branch_did : "")
            );
          })
        );
      })
      .catch((err) => {
        ConsoleError(err);
      });
  }
  useEffect(() => {
    if (!isStorageNameAssigned && searchString.length > 0) {
      getStorageList();
    }
    //eslint-disable-next-line
  }, [searchString]);

  const handleSubmit = () => {
    if (activityType === "") {
      setIsStorageTypeError(true);
    } else {
      if (location === "") {
        setIsStorageLocationError(true);
      } else {
        props.storageValue(activityType);
        props.location(location);
        props.onOkClick();
      }
    }
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    formValues[name] = value;
    setShowOptions(false);

    if (name === "storage_type") {
      setSearchString("");
      setBranchDid("");
      //TO-DO see how to decouple with storage type id
      if (e.target.value === "81e44f48-b167-49a3-8890-bdb758506e10") {
        setIsShowBranch(true);
        setIsShowVault(false);
        setIsWarehouse(false);
        setStorageLocationType("branch");
        setSearchPanelLabel("Branch Name");
        setActivityType("Branch");
        setSearchPanelPlaceholder("Search by Branch Name or DID");
        setIsStorageTypeError(false);
      } else if (e.target.value === "892763eb-b360-43fb-b020-5cc3c80baebd") {
        setIsShowBranch(false);
        setIsShowVault(false);
        setIsWarehouse(true);
        setStorageLocationType("warehouse");
        setSearchPanelLabel("Warehouse Name");
        setSearchPanelPlaceholder("Search by Warehouse Name");
        setActivityType("Warehouse");
        setIsStorageTypeError(false);
      } else if (e.target.value === "23137f9a-6bcb-4796-b456-d90394cd251a") {
        setIsShowBranch(false);
        setIsShowVault(true);
        setIsWarehouse(false);
        setStorageLocationType("vault");
        setSearchPanelLabel("Vault Name");
        setSearchPanelPlaceholder("Search by Vault Name");
        setActivityType("Vault");
        setIsStorageTypeError(false);
      }
    }
  };
  useEffect(() => {
    getActionList("StorageType", currentUser?.jwtToken)
      .then((response) => {
        setStorageTypeList(
          response?.map(function (storageTypeRow) {
            return storageTypeRow;
          })
        );
      })
      .catch((error) => {
        ConsoleError(error);
      });
  }, [currentUser?.jwtToken]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="no-border">
        <CustomHeader
          label={
            currentEventData.EventType === "Alert"
              ? currentEventData.EventType
              : "Storage Type"
          }
        />
      </Modal.Header>
      <Modal.Body
        style={{
          alignContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <p> {currentEventData.EventData} </p>
        {currentEventData.Section === "StorageTypeSelection" ? (
          <div className="form-row mt-2 d-flex justify-content-center">
            <div className="form-row col-10">
              <label htmlFor="storage_type">Storage Type</label>
              <select
                className="custom-select mr-sm-2"
                id="storage_type"
                name="storage_type"
                value={formValues.storage_type}
                onChange={onChange}
              >
                <option value="" default disabled>
                  Choose...
                </option>
                {storageTypeList?.map((opt, index) => {
                  return (
                    <option key={index} value={opt.action_id}>
                      {opt.action}
                    </option>
                  );
                })}
              </select>
              {isStorageTypeError && (
                <div className="error-message-validation">
                  Storage Type is required
                </div>
              )}
            </div>

            {(isShowBranch || isShowVault || isShowWarehouse) && (
              <div className="form-row pt-4 pb-2 col-10">
                {/* <div className="form-group col-10 flex-row"> */}
                <SearchPanel
                  label={searchPanelLabel}
                  placeholder={searchPanelPlaceholder}
                  options={branchOptionList}
                  setOptionList={setBranchOptionList}
                  setShowOptions={setShowOptions}
                  name="storage_location_id"
                  id="storage_location_id"
                  showOptions={showOptions}
                  onFocusSearch={(e) => {
                    setSearchString("");
                    setBranchDid("");
                    setLocation("");
                  }}
                  handleChange={(e) => {
                    if (isStorageNameAssigned) {
                      setSearchString(e.target.value.charAt(e.length - 1));
                      setStorageNameAssigned(false);
                    } else {
                      setSearchString(e.target.value);
                    }
                    setShowOptions(true);
                  }}
                  searchString={searchString}
                  onSelectValue={(selectedStorage) => {
                    setStorageNameAssigned(true);
                    setShowOptions(false);
                    setIsStorageLocationError(false);
                    const branch = branchList.filter((branchRow) => {
                      let returnValue;
                      if (storageLocationType === "branch") {
                        returnValue =
                          branchRow?.branch_did ===
                          selectedStorage.split(" - ")[1];
                      } else {
                        returnValue = branchRow?.name === selectedStorage;
                      }
                      return returnValue;
                    })[0];
                    setSearchString(branch.name);
                    setLocation(branch.name);
                    setBranchDid(branch?.branch_did);
                  }}
                />
              </div>
            )}
            {isStorageLocationError && (
              <div className="error-message-validation">
                Storage Location is required
              </div>
            )}
            {isShowBranch && (
              <div className="form-row col-10">
                <label htmlFor="branchDid">Branch DID</label>
                <input
                  type="text"
                  className="form-control"
                  id="branch_did"
                  name="branch_did"
                  disabled
                  autoComplete="off"
                  value={formValues.branch_did}
                />
              </div>
            )}
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer className="modalFooter p-1 no-border">
        {currentEventData.EventType === "Alert" ? (
          <button
            className="btn  btn-sm modalbtn btnWidth"
            onClick={(e) => {
              props.onHide();
            }}
          >
            CLOSE
          </button>
        ) : (
          <>
            <button
              className="btn  btn-sm modalbtn"
              onClick={(e) => {
                props.onHide();
              }}
            >
              CLOSE
            </button>
            <button className="btn  btn-sm modalbtn" onClick={handleSubmit}>
              OK
            </button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default CustomModal;
