import React, { useEffect, useState } from "react";
import {
  getAuthorizationData,
  updateAuthorizationConfiguration,
} from "../api/AuthorizationConfigurationService";
import { useSelector } from "react-redux";
import { cognitoSignOut } from "../api/CognitoServices";
import { useNavigate } from "react-router-dom";
import { ConsoleError } from "../utils/ErrorUtils";
import { CustomAlert } from "./customAlert";
import Swal from "sweetalert2";

function EditAuthorization() {
  let navigate = useNavigate();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  const [moduleData, setModuleData] = useState();
  const [formData, setFormData] = useState([]);

  const fetchAuthrizationData = async () => {
    getAuthorizationData(currentUser?.jwtToken)
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response?.status === 200) {
          setModuleData(response?.data);
          setFormData(response?.data);
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };

  const generateData = () => {
    let result = [];
    for (let i = 0; i < moduleData.length; i++) {
      let data = moduleData[i];
      let item = formData.find((value) => value?.authorization_configuration_id === data?.authorization_configuration_id);
      if (data.authorization_flag !== item.authorization_flag) {
        result.push(item);
      }
    }
    return result;
  };

  const updateAuthorizationData = async () => {
    Swal.fire({
      title: "Processing, please wait",
      html: "loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    let payload = {
      data: generateData(),
      modified_by: currentUser?.employee_id,
    };
    updateAuthorizationConfiguration(payload, currentUser?.jwtToken).then(
      (response) => {
        Swal.close();
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response?.data?.message === "success") {
          CustomAlert(
            "Updated",
            "Configurations has been saved.",
            "success",
            "OK",
            false,
            document.getElementById("swal-div")
          ).then(() => {
            fetchAuthrizationData()
          });
        } else {
          CustomAlert(
            "Failed",
            "Configurations are failed to update.",
            "error",
            "OK",
            false,
            document.getElementById("swal-div")
          );
        }
      }
    );
  };

  const handleChange = (moduleName, newAuthorizationFlag) => {
    setFormData((prevData) =>
      prevData.map((item) => {
        if (item.module_name === moduleName) {
          return {
            ...item,
            authorization_flag: newAuthorizationFlag,
          };
        }
        return item;
      })
    );
  };

  useEffect(() => {
    fetchAuthrizationData();
  }, []);
  return (
    <div className="row white-background pt-3">
      <div className="card-border mx-2" style={{ borderRadius: "10px" }}>
        {formData &&
          formData.map((module, index) => {
            return (
              <div className="form-row" key={index}>
                <div className="form-group col-md-6 col-xs-4  col-lg-1 px-4 mt-4">
                  <label htmlFor="from_storage_type">
                    {module?.module_name}
                  </label>
                </div>
                <div className="form-group col-md-6 col-xs-4  col-lg-1 pe-4 mt-4">
                  <input
                    type="checkbox"
                    checked={module?.authorization_flag}
                    onChange={(e) => {
                      handleChange(module?.module_name, e.target.checked);
                    }}
                  />
                </div>
                <div className="form-group col-md-6 col-xs-4  col-lg-2 ps-5 pe-0 mt-4">
                  <label>Last Modified By:</label>
                </div>
                {module?.authorization ? (
                  <div className="form-group col-md-6 col-xs-4  col-lg-5 pe-4 ps-0 mt-4">
                    <span>
                      {module?.authorization?.employee_name +
                        " - " +
                        module?.authorization?.employee_email}
                    </span>
                  </div>
                ) : (
                  <div className="form-group col-md-6 col-xs-4  col-lg-5 pe-4 mt-4">
                    -
                  </div>
                )}
              </div>
            );
          })}
      </div>
      <div className="justify-content-center col-md-6 col-lg-12 col-sm-12 mb-4">
        <button
          className="btn btn-primary btn-sm float-right"
          disabled={JSON.stringify(moduleData) === JSON.stringify(formData)}
          onClick={() => updateAuthorizationData()}
        >
          Submit
        </button>
        <button
          className="btn btn-primary btn-sm float-right"
          onClick={() => {
            setFormData(moduleData);
          }}
        >
          Reset
        </button>
      </div>
    </div>
  );
}

export default EditAuthorization;
