import { useFormContext } from "../../context/FormContext";
import SearchPanel from "../SearchPanel";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getStorageListOnsearch } from "../../api/StorageListService";
import { getUpdatedDateInUtc } from "../../utils/DateUtils";
import { ConsoleError } from "../../utils/ErrorUtils";
import { PrintDisable } from "../../utils/PrintDisable";
import { getDateConfigureData } from "../../api/ConfigureDatesService";
import { MODULES } from "../../constants/Constants";
const Section1 = (props) => {
  // const { state, handleChange } = useFormContext();
  const formContext = useFormContext();
  const state = formContext ? formContext.state : null;
  const handleChange = formContext ? formContext.handleChange : null;
  const [branchList, setBranchList] = useState([]);
  const [branchOptionList, setBranchOptionList] = useState([]);
  const [isCoOwnerAddressEnabled, setIsCoOwnerAddressEnabled] = useState(false);

  const [searchString, setSearchString] = useState(
    props?.branch_name !== "" ? props?.branch_name : state?.branch_name
  );
  const [isBranchDidAssigned, setIsBranchDidAssigned] = useState(
    state?.section1?.branchDID !== "" ? true : false
  );
  const [showOptions, setShowOptions] = useState(true);

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  PrintDisable(currentUser?.print_access_flag);
  const [maxLastRentalPaymentDate, setMaxLastRentalPaymentDate] = useState();
  const [minLastRentalPaymentDate, setMinLastRentalPaymentDate] = useState();

  const [maxExpirationOfContractDate, setMaxExpirationOfContractDate] =
    useState();
  const [minExpirationOfContractDate, setMinExpirationOfContractDate] =
    useState();

  const [maxForceOpenDate, setMaxForceOpenDate] = useState();
  const [minForceOpenDate, setMinForceOpenDate] = useState();

  const fetchDateFieldData = async (selectedField) => {
    getDateConfigureData(selectedField,MODULES.FORCE_OPEN, currentUser?.jwtToken).then(
      (response) => {
        if (response?.status === 200) {
          if (selectedField === "Force Open Date") {
            setMinForceOpenDate(
              getUpdatedDateInUtc(-response?.data?.min_value)
            );
            setMaxForceOpenDate(getUpdatedDateInUtc(response?.data?.max_value));
          } else if (selectedField === "Expiration of Contract") {
            setMinExpirationOfContractDate(
              getUpdatedDateInUtc(-response?.data?.min_value)
            );
            setMaxExpirationOfContractDate(
              getUpdatedDateInUtc(response?.data?.max_value)
            );
          } else if (selectedField === "Last Rental Payment") {
            setMinLastRentalPaymentDate(
              getUpdatedDateInUtc(-response?.data?.min_value)
            );
            setMaxLastRentalPaymentDate(
              getUpdatedDateInUtc(response?.data?.max_value)
            );
          }
        }
      }
    );
  };

  useEffect(() => {
    handleChange(
      "performed_by_id",
      null,
      currentUser?.employee_id,
      "UPDATE_PARENT_VALUE"
    );
    if (state?.section1?.branchDID) {
      setIsBranchDidAssigned(true);
      setShowOptions(false);
    }
    fetchDateFieldData("Force Open Date");
    fetchDateFieldData("Expiration of Contract");
    fetchDateFieldData("Last Rental Payment");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    function getBranchesBySearch() {
      getStorageListOnsearch("branch", searchString, currentUser?.jwtToken)
        .then((response) => {
          setBranchList(response);
          setBranchOptionList(
            response?.map(function (branchRow) {
              return (
                (branchRow.name ? branchRow.name : "") +
                (branchRow.branch_did ? " - " + branchRow.branch_did : "")
              );
            })
          );
        })
        .catch((error) => {
          ConsoleError(error);
        });
    }
    if (!isBranchDidAssigned && searchString) {
      getBranchesBySearch();
    }
  }, [currentUser?.jwtToken, searchString, isBranchDidAssigned]);

  const formErrors = props.errors;
  return (
    <>
      <div className="row justify-content-center">SECTION I</div>
      <div className="row justify-content-center">(BOX DETAILS)</div>
      <div className="row card-border">
        <div className="form-row">
          <div className="form-check col-md-6 col-xs-4 col-lg-4 px-4">
            <input
              rules={{ required: true }}
              type="checkbox"
              id="no_contract_on_file"
              autoComplete="off"
              onChange={(e) => {
                handleChange(
                  "section1",
                  "contract_on_file",
                  !state?.section1?.contract_on_file,
                  "UPDATE_VALUE"
                );
              }}
              checked={state?.section1?.contract_on_file}
            />
            <label
              className="form-check-label pl-3"
              htmlFor="no_contract_on_file"
            >
              No Contract On File
            </label>
          </div>
          <div className="form-check col-md-6 col-xs-4  col-lg-4 px-4">
            <input
              type="checkbox"
              id="no_access_log_on_file"
              autoComplete="off"
              onChange={(e) =>
                handleChange(
                  "section1",
                  "access_log",
                  !state?.section1?.access_log,
                  "UPDATE_VALUE"
                )
              }
              checked={state?.section1?.access_log}
            />
            <label
              className="form-check-label pl-3"
              htmlFor="no_access_log_on_file"
            >
              No Access Log On File
            </label>
          </div>
          <div className="form-check col-md-6 col-xs-4  col-lg-4 px-4">
            <input
              type="checkbox"
              id="legacy_checkbox"
              autoComplete="off"
              onChange={(e) =>
                handleChange(
                  "section1",
                  "legacy",
                  !state?.section1?.legacy,
                  "UPDATE_VALUE"
                )
              }
              checked={state?.section1?.legacy}
            />
            <label className="form-check-label pl-3" htmlFor="legacy_checkbox">
              Legacy
            </label>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <SearchPanel
              label="Branch Name"
              placeholder="Search by Branch Name or DID"
              options={branchOptionList}
              setOptionList={setBranchOptionList}
              setSearchString={setSearchString}
              setShowOptions={setShowOptions}
              showOptions={showOptions}
              onFocusSearch={(e) => {
                setSearchString("");
                handleChange("section1", "branch_name", "", "UPDATE_VALUE");
                handleChange("section1", "branch_id", "", "UPDATE_VALUE");
                handleChange("section1", "branchDID", "", "UPDATE_VALUE");
                setShowOptions(true);
              }}
              handleChange={(str) => {
                if (isBranchDidAssigned) {
                  setSearchString(str.target.value.charAt(str.length - 1));
                  setIsBranchDidAssigned(false);
                  setShowOptions(true);
                } else {
                  setSearchString(str.target.value);
                }
              }}
              searchString={searchString}
              onSelectValue={(selectedBranch) => {
                setIsBranchDidAssigned(true);
                setShowOptions(false);
                const branch = branchList.filter(
                  (branchRow) =>
                    branchRow.branch_did === selectedBranch.split(" - ")[1]
                )[0];
                setSearchString(branch.name);
                handleChange(
                  "section1",
                  "branchDID",
                  branch.branch_did,
                  "UPDATE_VALUE"
                );
                handleChange(
                  "section1",
                  "branch_name",
                  branch.name,
                  "UPDATE_VALUE"
                );
                handleChange(
                  "section1",
                  "branch_id",
                  branch.branch_id,
                  "UPDATE_VALUE"
                );
                if (
                  formErrors["branch"] !== undefined &&
                  formErrors["branch"].length > 0
                ) {
                  delete formErrors["branch"];
                }
              }}
            />
            <div
              style={{ position: "relative", bottom: "20px" }}
              className="error-message-validation"
            >
              {formErrors?.branch}
            </div>
          </div>

          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="branchDid">Branch DID</label>
            <input
              type="text"
              className="form-control"
              id="branchDid"
              disabled
              autoComplete="off"
              value={state?.section1?.branchDID}
            />
          </div>

          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="force_open_date">Force Open Date</label>
            <input
              type="date"
              className="form-control"
              id="force_open_date"
              autoComplete="off"
              onChange={(e) => {
                handleChange(
                  "section1",
                  "force_open_date",
                  e.target.value,
                  "UPDATE_VALUE"
                );
                delete formErrors["force_open_date"];
              }}
              value={
                state?.section1?.force_open_date
                  ? state?.section1?.force_open_date
                      .split("/")
                      .reverse()
                      .join("-")
                  : state?.section1?.force_open_date
              }
              // value={state?.section1?.force_open_date}
              max={maxForceOpenDate}
              min={minForceOpenDate}
              // readOnly
              onFocus={(e) => e.target.showPicker()}
            />
            <div className="error-message-validation">
              {formErrors?.force_open_date}
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="safeDepositBox">Safe Deposit Box #</label>
            <input
              type="text"
              className="form-control"
              id="safeDepositBox"
              autoComplete="off"
              onChange={(e) => {
                handleChange(
                  "section1",
                  "safe_deposit_box_number",
                  e.target.value,
                  "UPDATE_VALUE"
                );
                delete formErrors["safe_deposit_box_number"];
              }}
              value={state?.section1?.safe_deposit_box_number}
            />
            <div className="error-message-validation">
              {formErrors?.safe_deposit_box_number}
            </div>
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="rental_due">Rental fee Due</label>
            <input
              type="number"
              min={0}
              className="form-control"
              id="rental_due"
              autoComplete="off"
              onChange={(e) => {
                const inputValue =
                  e.target.value.trim() === "" ? 0 : e.target.value;

                handleChange(
                  "section1",
                  "rental_due",
                  isNaN(inputValue) ? 0 : inputValue,
                  "UPDATE_VALUE"
                );
                handleChange(
                  "section1",
                  "total_due",
                  parseInt(state?.section1?.other_due) +
                    parseInt(isNaN(inputValue) ? 0 : inputValue),
                  "UPDATE_VALUE"
                );
                handleChange(
                  "section1",
                  "rental_due",
                  parseFloat(isNaN(inputValue) ? 0 : inputValue),
                  "UPDATE_VALUE"
                );
                handleChange(
                  "section1",
                  "total_due",
                  parseFloat(state?.section1?.other_due) +
                    parseFloat(isNaN(inputValue) ? 0 : inputValue),
                  "UPDATE_VALUE"
                );
              }}
              value={state?.section1?.rental_due}
            />
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="other_due">Other Fee Due</label>
            <input
              type="number"
              min={0}
              className="form-control"
              id="other_due"
              autoComplete="off"
              onChange={(e) => {
                const inputValue =
                  e.target.value.trim() === "" ? 0 : e.target.value;

                handleChange(
                  "section1",
                  "other_due",
                  parseFloat(isNaN(inputValue) ? 0 : inputValue),
                  "UPDATE_VALUE"
                );
                handleChange(
                  "section1",
                  "total_due",
                  parseFloat(state?.section1?.rental_due) +
                    parseFloat(isNaN(inputValue) ? 0 : inputValue),
                  "UPDATE_VALUE"
                );
              }}
              value={state?.section1?.other_due}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="total_due">Total Due</label>
            <input
              type="number"
              min={0}
              className="form-control"
              id="total_due"
              autoComplete="off"
              disabled
              value={state?.section1?.total_due}
            />
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="contract_expiration">Expiration of Contract</label>
            <input
              type="date"
              className="form-control"
              id="contract_expiration"
              autoComplete="off"
              onChange={(e) => {
                handleChange(
                  "section1",
                  "contract_expiration",
                  e.target.value,
                  "UPDATE_VALUE"
                );
                delete formErrors["contract_expiration"];
              }}
              value={state?.section1?.contract_expiration}
              max={maxExpirationOfContractDate}
              min={minExpirationOfContractDate}
              placeholder="mm/dd/yyyy"
              onFocus={(e) => e.target.showPicker()}

            />
            <div className="error-message-validation">
              {formErrors?.contract_expiration}
            </div>
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="last_rental_payment">
              Date of Last Rental Payment
            </label>
            <input
              type="date"
              className="form-control"
              id="last_rental_payment"
              autoComplete="off"
              onChange={(e) => {
                handleChange(
                  "section1",
                  "last_rental_payment",
                  e.target.value,
                  "UPDATE_VALUE"
                );
                delete formErrors["last_rental_payment"];
              }}
              value={state?.section1?.last_rental_payment}
              max={maxLastRentalPaymentDate}
              min={minLastRentalPaymentDate}
              placeholder="mm/dd/yyyy"
              onFocus={(e) => e.target.showPicker()}

            />
            <div className="error-message-validation">
              {formErrors?.last_rental_payment}
            </div>
          </div>
        </div>
      </div>
      <div
        className="row col-lg-12 col-xs-12 justify-content-center"
        id="force-internal-head"
      >
        OWNERS INFO
      </div>

      <div className="row card-border">
        <div className="form-row mt-2">
          <div className="form-check col-md-6 col-xs-4  col-lg-4 px-4">
            <input
              type="checkbox"
              id="owner_service_member"
              autoComplete="off"
              name="owner_service_member"
              disabled={state?.section1?.mystery_box ? true : false}
              onChange={(e) => {
                handleChange(
                  "section1",
                  "owner.service_member",
                  !state?.section1?.owner?.service_member,
                  "UPDATE_CHILD_VALUE"
                );
              }}
              checked={state?.section1?.mystery_box ? false : state?.section1?.owner?.service_member}
            />
            <label
              className="form-check-label pl-3"
              htmlFor="owner_service_member"
            >
              Service Member
            </label>
          </div>
          <div className="form-check col-md-6 col-xs-4  col-lg-4 px-4">
            <input
              type="checkbox"
              id="mystery_box"
              autoComplete="off"
              name="mystery_box"
              onChange={(e) => {
                handleChange(
                  "section1",
                  "mystery_box",
                  e.target.checked,
                  "UPDATE_VALUE"
                );
              }}
              checked={state?.section1?.mystery_box}
            />
            <label className="form-check-label pl-3" htmlFor="mystery_box">
              Mystery Box
            </label>
          </div>
        </div>
        <div className="form-row mt-2">
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="ownerFirstName">Name</label>
            <input
              type="text"
              className="form-control"
              id="ownerFirstName"
              autoComplete="off"
              disabled={state?.section1?.mystery_box ? true : false}
              onChange={(e) => {
                handleChange(
                  "section1",
                  "owner.first_name",
                  e.target.value,
                  "UPDATE_CHILD_VALUE"
                );
                delete formErrors["first_name"];
              }}
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.owner?.first_name
              }
            />
            {!state?.section1?.mystery_box && (
              <div className="error-message-validation">
                {formErrors?.first_name}
              </div>
            )}
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="ownerMiddleName">Middle Name</label>
            <input
              type="text"
              className="form-control"
              id="ownerMiddleName"
              disabled={state?.section1?.mystery_box ? true : false}
              autoComplete="off"
              onChange={(e) =>
                handleChange(
                  "section1",
                  "owner.middle_name",
                  e.target.value,
                  "UPDATE_CHILD_VALUE"
                )
              }
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.owner?.middle_name
              }
            />
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="ownerLastName">Last Name</label>
            <input
              type="text"
              className="form-control"
              id="ownerLastName"
              autoComplete="off"
              disabled={state?.section1?.mystery_box ? true : false}
              onChange={(e) => {
                handleChange(
                  "section1",
                  "owner.last_name",
                  e.target.value,
                  "UPDATE_CHILD_VALUE"
                );
                delete formErrors["last_name"];
              }}
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.owner?.last_name
              }
            />
            {!state?.section1?.mystery_box && (
              <div className="error-message-validation">
                {formErrors?.last_name}
              </div>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="ownerSSN">SSN</label>
            <input
              type="password"
              className="form-control"
              id="ownerSSN"
              autoComplete="off"
              disabled={state?.section1?.mystery_box ? true : false}
              maxLength={9}
              onChange={(e) => {
                handleChange(
                  "section1",
                  "owner.ssn",
                  e.target.value,
                  "UPDATE_CHILD_VALUE"
                );
                delete formErrors["ssn"];
              }}
              value={
                state?.section1?.mystery_box ? "" : state?.section1?.owner?.ssn
              }
            />
            {!state?.section1?.mystery_box && (
              <div className="error-message-validation">{formErrors?.ssn}</div>
            )}
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="street_1">Street</label>
            <input
              type="text"
              className="form-control"
              id="street_1"
              autoComplete="off"
              disabled={state?.section1?.mystery_box ? true : false}
              onChange={(e) => {
                handleChange(
                  "section1",
                  "owner.street_1",
                  e.target.value,
                  "UPDATE_CHILD_VALUE"
                );
                delete formErrors["street"];
                if (state?.section1?.address_same_as_owner) {
                  handleChange(
                    "section1",
                    "co_owner.street_1",
                    e.target.value,
                    "UPDATE_CHILD_VALUE"
                  );
                }
              }}
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.owner?.street_1
              }
            />
            {!state?.section1?.mystery_box && (
              <div className="error-message-validation">
                {formErrors?.street}
              </div>
            )}
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="city">City</label>
            <input
              type="text"
              className="form-control"
              id="city"
              disabled={state?.section1?.mystery_box ? true : false}
              autoComplete="off"
              onChange={(e) => {
                handleChange(
                  "section1",
                  "owner.city",
                  e.target.value,
                  "UPDATE_CHILD_VALUE"
                );
                delete formErrors["city"];
                if (state?.section1?.address_same_as_owner) {
                  handleChange(
                    "section1",
                    "co_owner.city",
                    e.target.value,
                    "UPDATE_CHILD_VALUE"
                  );
                }
              }}
              value={
                state?.section1?.mystery_box ? "" : state?.section1?.owner?.city
              }
            />
            {!state?.section1?.mystery_box && (
              <div className="error-message-validation">{formErrors?.city}</div>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="ownerState">State</label>
            <input
              type="text"
              className="form-control"
              id="ownerState"
              autoComplete="off"
              disabled={state?.section1?.mystery_box ? true : false}
              onChange={(e) => {
                handleChange(
                  "section1",
                  "owner.state",
                  e.target.value,
                  "UPDATE_CHILD_VALUE"
                );
                delete formErrors["state"];
                if (state?.section1?.address_same_as_owner) {
                  handleChange(
                    "section1",
                    "co_owner.state",
                    e.target.value,
                    "UPDATE_CHILD_VALUE"
                  );
                }
              }}
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.owner?.state
              }
            />
            {!state?.section1?.mystery_box && (
              <div className="error-message-validation">
                {formErrors?.state}
              </div>
            )}
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="zipcode">Zip</label>
            <input
              type="number"
              className="form-control"
              id="zipcode"
              autoComplete="off"
              disabled={state?.section1?.mystery_box ? true : false}
              onChange={(e) => {
                if (e.target.value.length < 11) {
                  handleChange(
                    "section1",
                    "owner.zipcode",
                    e.target.value,
                    "UPDATE_CHILD_VALUE"
                  );
                  delete formErrors["zip"];
                  if (state?.section1?.address_same_as_owner) {
                    handleChange(
                      "section1",
                      "co_owner.zipcode",
                      e.target.value,
                      "UPDATE_CHILD_VALUE"
                    );
                  }
                }
              }}
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.owner?.zipcode
              }
            />
            {!state?.section1?.mystery_box && (
              <div className="error-message-validation">{formErrors?.zip}</div>
            )}
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="owner_relation_code">Relation Code</label>
            <input
              type="text"
              className="form-control"
              id="owner_relation_code"
              autoComplete="off"
              disabled={state?.section1?.mystery_box ? true : false}
              onChange={(e) => {
                if (e.target.value.length < 3) {
                  handleChange(
                    "section1",
                    "owner.owner_relation_code",
                    e.target.value,
                    "UPDATE_CHILD_VALUE"
                  );
                }
                delete formErrors["owner_relation_code"];
              }}
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.owner?.owner_relation_code
              }
            />
            {!state?.section1?.mystery_box && (
              <div className="error-message-validation">
                {formErrors?.owner_relation_code}
              </div>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="owner_property_type_code">Property Type Code</label>
            <input
              type="text"
              className="form-control"
              disabled={state?.section1?.mystery_box ? true : false}
              id="owner_property_type_code"
              autoComplete="off"
              onChange={(e) => {
                if (e.target.value.length < 3) {
                  handleChange(
                    "section1",
                    "owner.owner_property_type_code",
                    e.target.value,
                    "UPDATE_CHILD_VALUE"
                  );
                }
                delete formErrors["owner_property_type_code"];
              }}
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.owner?.owner_property_type_code
              }
            />
            {!state?.section1?.mystery_box && (
              <div className="error-message-validation">
                {formErrors?.owner_property_type_code}
              </div>
            )}
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="dormancy_id">Dormancy Id</label>
            <input
              type="text"
              className="form-control"
              id="dormancy_id"
              disabled={state?.section1?.mystery_box ? true : false}
              autoComplete="off"
              onChange={(e) => {
                if (e.target.value.length < 5) {
                  handleChange(
                    "section1",
                    "owner.dormancy_id",
                    e.target.value,
                    "UPDATE_CHILD_VALUE"
                  );
                }
                delete formErrors["dormancy_id"];
              }}
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.owner?.dormancy_id
              }
            />
            {!state?.section1?.mystery_box && (
              <div className="error-message-validation">
                {formErrors?.dormancy_id}
              </div>
            )}
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="company_name">Company Name</label>
            <input
              type="text"
              className="form-control"
              id="company_name"
              autoComplete="off"
              disabled={state?.section1?.mystery_box ? true : false}
              onChange={(e) => {
                handleChange(
                  "section1",
                  "owner.company_name",
                  e.target.value,
                  "UPDATE_CHILD_VALUE"
                );
                delete formErrors["company_name"];
              }}
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.owner?.company_name
              }
            />
            {!state?.section1?.mystery_box && (
              <div className="error-message-validation">
                {formErrors?.company_name}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="row col-lg-12 col-xs-12 justify-content-center"
        id="force-internal-head"
      >
        CO-OWNERS INFO
      </div>
      <div className="row card-border">
        <div className="form-row mt-2">
          <div className="form-check col-md-6 col-xs-4  col-lg-4 px-4">
            <input
              type="checkbox"
              id="cowoner_address_check"
              autoComplete="off"
              name="cowoner_address_check"
              onChange={(e) => {
                handleChange(
                  "section1",
                  "address_same_as_owner",
                  !state?.section1?.address_same_as_owner,
                  "UPDATE_VALUE"
                );
                setIsCoOwnerAddressEnabled(e.target.checked);
                if (e.target.checked) {
                  handleChange(
                    "section1",
                    "co_owner.street_1",
                    state?.section1?.owner?.street_1,
                    "UPDATE_CHILD_VALUE"
                  );
                  handleChange(
                    "section1",
                    "co_owner.city",
                    state?.section1?.owner?.city,
                    "UPDATE_CHILD_VALUE"
                  );
                  handleChange(
                    "section1",
                    "co_owner.state",
                    state?.section1?.owner?.state,
                    "UPDATE_CHILD_VALUE"
                  );
                  handleChange(
                    "section1",
                    "co_owner.zipcode",
                    state?.section1?.owner?.zipcode,
                    "UPDATE_CHILD_VALUE"
                  );
                } else {
                  handleChange(
                    "section1",
                    "co_owner.street_1",
                    "",
                    "UPDATE_CHILD_VALUE"
                  );
                  handleChange(
                    "section1",
                    "co_owner.city",
                    "",
                    "UPDATE_CHILD_VALUE"
                  );
                  handleChange(
                    "section1",
                    "co_owner.state",
                    "",
                    "UPDATE_CHILD_VALUE"
                  );
                  handleChange(
                    "section1",
                    "co_owner.zipcode",
                    "",
                    "UPDATE_CHILD_VALUE"
                  );
                }
              }}
              checked={state?.section1?.mystery_box ? false : state?.section1?.address_same_as_owner}
              disabled={state?.section1?.mystery_box ? true : false}

            />
            <label
              className="form-check-label pl-3"
              htmlFor="cowoner_address_check"
            >
              Address same as owner
            </label>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="coOwnerFirstName">First Name</label>
            <input
              type="text"
              className="form-control"
              id="coOwnerFirstName"
              autoComplete="off"
              disabled={state?.section1?.mystery_box ? true : false}
              onChange={(e) =>
                handleChange(
                  "section1",
                  "co_owner.first_name",
                  e.target.value,
                  "UPDATE_CHILD_VALUE"
                )
              }
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.co_owner?.first_name
              }
            />
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="coOwnerMiddleName">Middle Name</label>
            <input
              type="text"
              className="form-control"
              id="coOwnerMiddleName"
              autoComplete="off"
              disabled={state?.section1?.mystery_box ? true : false}
              onChange={(e) =>
                handleChange(
                  "section1",
                  "co_owner.middle_name",
                  e.target.value,
                  "UPDATE_CHILD_VALUE"
                )
              }
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.co_owner?.middle_name
              }
            />
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="coOwnerLastName">Last Name</label>
            <input
              type="text"
              className="form-control"
              id="coOwnerLastName"
              autoComplete="off"
              disabled={state?.section1?.mystery_box ? true : false}
              onChange={(e) =>
                handleChange(
                  "section1",
                  "co_owner.last_name",
                  e.target.value,
                  "UPDATE_CHILD_VALUE"
                )
              }
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.co_owner?.last_name
              }
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="coOwnerSSN">SSN</label>
            <input
              type="password"
              className="form-control"
              id="coOwnerSSN"
              maxLength={9}
              autoComplete="off"
              disabled={state?.section1?.mystery_box ? true : false}
              onChange={(e) =>
              {
                handleChange(
                  "section1",
                  "co_owner.ssn",
                  e.target.value,
                  "UPDATE_CHILD_VALUE"
                );
                delete formErrors["co_owner_ssn"];
              }
              }
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.co_owner?.ssn
              }
            />
              <div className="error-message-validation">
                {formErrors?.co_owner_ssn}
              </div>
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="co_street_1">Street</label>
            <input
              type="text"
              className="form-control"
              id="co_street_1"
              autoComplete="off"
              disabled={
                state?.section1?.mystery_box ? true : isCoOwnerAddressEnabled
              }
              onChange={(e) =>
                handleChange(
                  "section1",
                  "co_owner.street_1",
                  e.target.value,
                  "UPDATE_CHILD_VALUE"
                )
              }
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.co_owner?.street_1
              }
            />
          </div>

          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="co_city">City</label>
            <input
              type="text"
              className="form-control"
              id="co_city"
              autoComplete="off"
              disabled={
                state?.section1?.mystery_box ? true : isCoOwnerAddressEnabled
              }
              onChange={(e) =>
                handleChange(
                  "section1",
                  "co_owner.city",
                  e.target.value,
                  "UPDATE_CHILD_VALUE"
                )
              }
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.co_owner?.city
              }
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="coOwnerState">State</label>
            <input
              type="text"
              className="form-control"
              id="coOwnerState"
              autoComplete="off"
              disabled={
                state?.section1?.mystery_box ? true : isCoOwnerAddressEnabled
              }
              onChange={(e) =>
                handleChange(
                  "section1",
                  "co_owner.state",
                  e.target.value,
                  "UPDATE_CHILD_VALUE"
                )
              }
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.co_owner?.state
              }
            />
          </div>
          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
            <label htmlFor="co_zip">Zip</label>
            <input
              type="text"
              className="form-control"
              id="co_zip"
              autoComplete="off"
              disabled={
                state?.section1?.mystery_box ? true : isCoOwnerAddressEnabled
              }
              onChange={(e) =>
                handleChange(
                  "section1",
                  "co_owner.zipcode",
                  e.target.value,
                  "UPDATE_CHILD_VALUE"
                )
              }
              value={
                state?.section1?.mystery_box
                  ? ""
                  : state?.section1?.co_owner?.zipcode
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Section1;
