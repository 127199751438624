import { getHeader } from "../utils/Headers";
import service from "./service";
import axios from "axios";
import {
  GET_STORAGE_LOCATIONS,
  GET_STORAGE_UNITS,
  SAVE_BULK_CHECKIN,
} from "../constants/ApiUrlConstants";
import { ConsoleError } from "../utils/ErrorUtils";

//For StorageLocation
export const getddlStorageLocation = async (empId, token) => {
  return axios
    .get(service.baseUrl + GET_STORAGE_LOCATIONS + "?emp_id=" + empId, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    });
};

//For StorageUnit based on StorageLocation

export const getddlStorageUnit = async (storageLocType, empId, token) => {
  return axios
    .get(
      service.baseUrl +
        GET_STORAGE_UNITS +
        "?storage_location_id=" +
        storageLocType +
        "&emp_id" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

//save bulkcheckin data

export const handleSubmitBulkCheckIn = async (dataSource, empId, token) => {
  return axios
    .post(
      service.baseUrl + SAVE_BULK_CHECKIN + "?emp_id=" + empId,
      dataSource,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ConsoleError(error);
    });
};
