import { MDBCard } from "mdbreact";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { getCustomData, getMergeData } from "../api/CustomBulkCheckinService";
import GreenTick from "../assets/CheckmarkGreen.png";
import EditStorage from "../assets/EditStorage.png";
import { CustomAlert } from "../components/customAlert";
import CustomHeader from "../components/CustomHeader";
import CustomModal from "../components/CustomModal";
import DocumentUpload from "../components/documentUpload";
import Inputupload from "../components/Inputupload";
import { Pager } from "../components/pager";
import BULK_CHECK_IN from "../config";
import "../style/BulkCheckin.css";
import "../style/customalert.css";
import "../style/custombulkcheckin.css";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import { ConsoleError } from "../utils/ErrorUtils";
import { ERROR_MESSAGE } from "../constants/MessagesConstants";

let CustomBulkCheckin = (props) => {
  let navigate = useNavigate();
  const [activityType, setActivityType] = useState("fsi");
  const [fileMergedRecords, setfilemergedRecords] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const [fileuploaded, setFileUploaded] = useState(true);
  const [currentEventData, setCurrentEventData] = useState({
    EventType: "Alert",
    EventData: "",
  });
  const [showMessage, setShowMessage] = useState(false);
  const EXTENSIONS = ["xlsx", "xls", "csv"];
  const [showData, setShowData] = useState([]);
  // let [fileMergedRecords, setfilemergedRecords] = useState([]);
  const [fileData, setFileData] = useState();
  const [conflictData, setConflictData] = useState();
  const [update, setUpdate] = useState(true);
  const [clickIndex, setClickIndex] = useState([]);
  const [review, setReview] = useState(false);
  const [formData, setFormData] = useState({
    step1: {
      fileuploadrows: [],
      wsFileName: "",
    },
  });
  const [conflictRecords, setConflictRecords] = useState();
  const [rows, setRows] = useState([]);
  const [pageState, setPageState] = useState({ pageNum: 1, rowsPerPage: 10 });
  const [requriedData, setRequriedData] = useState(BULK_CHECK_IN.cbdFSIfields);
  const [datafield, setDataField] = useState(false);
  const [dataMerge, setDataMerge] = useState(false);
  const [verified, setVerified] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [error, setError] = useState(false);
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  let handleChange = (step, key, value) => {
    let data = { ...formData };
    let selectedStep = { ...data[step] };
    selectedStep[key] = value;
    data[step] = selectedStep;
    setFormData(data);
  };
  const handleOnChange = () => {
    setIsChecked(!isChecked);
    if (isChecked === false) {
      setReadOnly(true);
      setVerified(false);
    } else {
      setReadOnly(false);
      setVerified(true);
    }
  };
  let onChangeActivity = (value) => {
    setFileName("File Name");
    setFileUploaded(true);
    setDataField(false);
    setVerified(true);
    setReview(false);
    setShowData([]);
    let type = value.target.value;
    setActivityType(type);
    if (type === "fsi") {
      setRequriedData(BULK_CHECK_IN.cbdFSIfields);
    } else if (type === "google_studio") {
      setRequriedData(BULK_CHECK_IN.cbdGooglefields);
    }
  };

  let handleInputChange = (text, item, column_name, index) => {
    let data = item.uploaded_data;
    // data[column_name] = text.target.value;
    if (
      column_name === "DAYS_UNTIL_NEXT_AUDIT" ||
      column_name === "BAG_COUNT"
    ) {
      data[column_name] = text.target.validity.valid ? text.target.value : null;
    } else {
      data[column_name] = text.target.value;
    }
    setUpdate(!update);
    if (data[column_name] !== "") {
      setError(false);
      if (!error) {
        setVerified(false);
      }
      item["errorIndex"] = "no";
    } else {
      setError(true);
      setVerified(true);
      item["errorIndex"] = "yes";
    }
  };

  let onDataMerge = () => {
    setReview(false);
    let conflictRecords = conflictData.map(function (item, i) {
      if (activityType === "google_studio") {
        return item.uploaded_data;
      } else {
        return item.uploaded_data;
      }
    });
    setConflictRecords(conflictRecords);
    //eslint-disable-next-line
    conflictRecords.map((conflict_data) => {
      //eslint-disable-next-line
      fileData.map((file_data, i) => {
        if (activityType === "google_studio") {
          if (
            conflict_data["BAG #"] === file_data["BAG #"] &&
            conflict_data["ORIGINAL_BRANCH_DID"] ===
              file_data["ORIGINAL_BRANCH_DID"] &&
            conflict_data["SDB# DRILLED"] === file_data["SDB# DRILLED"]
          ) {
            fileData.splice(i, 1);
          }
        } else {
          if (
            conflict_data["TAMPER_EVIDENT_BAG#"] ===
              file_data["TAMPER_EVIDENT_BAG#"] &&
            conflict_data["ORIGINAL_BRANCH_DID"] ===
              file_data["ORIGINAL_BRANCH_DID"] &&
            conflict_data["SAFEDEPOSITBOX_NUMBER"] ===
              file_data["SAFEDEPOSITBOX_NUMBER"]
          ) {
            fileData.splice(i, 1);
          }
        }
      });
    });
    // let data =[...fileMergedRecords] ;
    let data = conflictRecords;
    // setFilteredData(data.concat(fileData));
    let title = "Success";
    let text =
      "Records updated successfully, Please review them and submit the file";
    let type = "success";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      target
    ).then((result) => {
      setDataField(false);
      setRows(data.concat(fileData));
      // setfilemergedRecords(data);
      setfilemergedRecords(data.concat(fileData));
      setDataMerge(true);
    });
  };

  const onFinalSubmit = () => {
    Swal.fire({
      title: "Please Wait !",
      html: "Saving the records",
      allowOutsideClick: false,
      target: document.getElementById("swal-div"),
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    // let non_conflict = validData;
    // let conflict = fileMergedRecords;
    let dataSource = {
      // data: ([...data, file]),
      // data: { non_conflict, conflict },
      data: fileMergedRecords,
      activity_type: activityType,
      employee_name: currentUser?.employee_name,
      employee_id: currentUser?.employee_id,
      employee_designation: currentUser?.employee_designation,
    };
    getMergeData(dataSource, currentUser?.jwtToken)
      .then((response) => {
        Swal.close();
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response?.data.status === 200) {
          let title = "Success";
          let text = response?.data.message;
          let type = "success";
          let confirmButtonText = "OK";
          let showCancelButton = false;
          let target = document.getElementById("swal-div");
          CustomAlert(
            title,
            text,
            type,
            confirmButtonText,
            showCancelButton,
            target
          ).then((result) => {
            setFileUploaded(true);
            handleChange("step1", "fileuploadrows", null);
            setDataField(false);
            setDataMerge(false);
            setFileName("File Name");
          });
        } else if (response?.data.status === 409) {
          setReview(true);
          // setCustomData(responseData.conflict_data);
          let data = response?.data.data;
          // setFileData(fileuploadrowsdata)
          setRows(data);
          setConflictData(data);
          let title = "Duplicate Records Found";
          let text =
            "Please update each record or modify the data and reupload the file";
          let type = "warning";
          let confirmButtonText = "OK";
          let showCancelButton = false;
          let target = document.getElementById("swal-div");
          // let target = "swal-div";
          CustomAlert(
            title,
            text,
            type,
            confirmButtonText,
            showCancelButton,
            target
          ).then((result) => {
            setFileUploaded(false);
          });
          setDataField(true);
          //item.uploaded_data = data
        } else if (response?.data.status === 500) {
          let title = ERROR_MESSAGE;
          let text = "Please try after some time";
          let type = "error";
          let confirmButtonText = "OK";
          let showCancelButton = false;
          let target = document.getElementById("swal-div");
          CustomAlert(
            title,
            text,
            type,
            confirmButtonText,
            showCancelButton,
            target
          ).then((result) => {
            handleChange("step1", "fileuploadrows", null);
            setDataMerge(false);
            setFileName("File Name");
          });
        }
      })

      .catch((error) => {
        ConsoleError(error);
        let title = ERROR_MESSAGE;
        let text = error;
        let type = "error";
        let confirmButtonText = "OK";
        let showCancelButton = false;
        let target = document.getElementById("swal-div");
        CustomAlert(
          title,
          text,
          type,
          confirmButtonText,
          showCancelButton,
          target
        ).then((result) => {
          handleChange("step1", "fileuploadrows", null);
          setDataMerge(false);
          setFileName("File Name");
        });
      });
  };
  let getExention = (file) => {
    let parts = file.name.split(".");
    let extension = parts[parts.length - 1];
    return EXTENSIONS.includes(extension);
  };
  let [fileName, setFileName] = useState("File Name ");
  let fileUpload = async (e) => {
    setDataField(false);
    let file = e.target.files[0];
    setFileName(e.target.files[0].name);
    if (getExention(file)) {
      if (file.size > 10e6) {
        setCurrentEventData({
          EventType: "Alert",
          EventData: "Please upload a file smaller than 10 MB",
        });
        setShowMessage(true);
      }
    } else {
      setCurrentEventData({
        EventType: "Alert",
        EventData: "Invalid file input, Select Excel, CSV file",
      });

      setShowMessage(true);
    }
    let defaultHeaders = [];
    if (file) {
      let promise = new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
          let bufferArray = e.target.result;
          let wb = XLSX.read(bufferArray, { type: "binary" });
          //let wb = XLSX.read(bufferArray, { type: "binary",cellDates: true,cellText:false,raw: true});
          let wsname = wb.SheetNames[0];
          let ws = wb.Sheets[wsname];
          let data = XLSX.utils.sheet_to_json(ws);
          // data["key"]="yes"
          // let headers = XLSX.utils.sheet_to_json(ws, {
          //   header: 1,
          //   dateNF: "yyyy-mm-dd",
          //   cellDates: true

          // });
          let headers = XLSX.utils.sheet_to_json(ws, {
            header: 1,
          });
          defaultHeaders = headers[0];
          resolve(data);
          handleChange("step1", "fileuploadrows", data, "keyValue", "yes");
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
      promise.then((data) => {
        let keysFromFile = defaultHeaders;
        let defaultKeys = requriedData;
        let differentKeys = [];
        function arraysEqual(keysFromFile, defaultKeys) {
          if (keysFromFile === defaultKeys) return true;
          if (keysFromFile === null || defaultKeys === null) return false;
          if (keysFromFile.length !== defaultKeys.length) return false;
          for (let i = 0; i < defaultKeys.length; ++i) {
            if (defaultKeys[i] !== keysFromFile[i]) return false;
          }
          return true;
        }
        arraysEqual(keysFromFile, defaultKeys);
        let flag = arraysEqual(keysFromFile, defaultKeys);
        if (flag === false) {
          differentKeys = defaultKeys.filter((d) => !keysFromFile.includes(d));
        }
        let removeDuplicateObjects = (data) => {
          let uniqueArr = [];
          let objStrings = [];
          data.forEach((element) => {
            if (typeof element === "object" && element !== null) {
              let eleString = JSON.stringify(element);
              if (!objStrings.includes(eleString)) {
                uniqueArr.push(element);
                objStrings.push(eleString);
              }
            }
          });
          return uniqueArr;
        };

        let filteredData = removeDuplicateObjects(data);

        if (flag === true) {
          setShowData(filteredData);
          setFileUploaded(false);

          if (showData && data.length > filteredData.length) {
            let removeItem = data.length - filteredData.length;
            let text =
              removeItem + " records were removed out of " + data.length;
            let confirmButtonText = "OK";
            let showConfirmButton = false;
            let title = removeItem + " Duplicate Records Found ";
            let type = "warning";
            CustomAlert(
              title,
              text,
              type,
              confirmButtonText,
              showConfirmButton
            );
          }
        } else {
          setFileName("File Name");
          setShowData([]);
          let text;
          if (differentKeys.length < 3) {
            text = "Please check " + differentKeys + " and upload again";
          } else {
            text =
              "Please check " +
              differentKeys[0] +
              "," +
              differentKeys[1] +
              "," +
              differentKeys[3] +
              " and few other fields, upload  file again";
          }
          let confirmButtonText = "OK";
          let showConfirmButton = false;
          let title = "Missing fields";
          let type = "warning";
          CustomAlert(title, text, type, confirmButtonText, showConfirmButton);
        }
      });
    }
  };
  let fileuploadedData = true;

  let onSubmit = () => {
    Swal.fire({
      title: "Please Wait !",
      html: "Saving the records",
      allowOutsideClick: false,
      target: document.getElementById("swal-div"),
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    // let fileuploadrowsdata = formData?.step1?.fileuploadrows;
    let fileuploadrowsdata = showData;
    //eslint-disable-next-line
    fileuploadrowsdata = fileuploadrowsdata.map((fileData) => {
      if (activityType === "google_studio") {
        if (
          fileData.CONTRACT_ON_FILE !== undefined &&
          fileData.ACCESS_LOG !== undefined &&
          fileData.FORCE_OPEN_DATE !== undefined &&
          fileData.SAFEDEPOSITBOX_NUMBER !== undefined &&
          fileData.OWNER_NAME !== undefined &&
          fileData.OWNER_SSN !== undefined &&
          fileData.OWNER_STREET_1 !== undefined &&
          fileData.OWNER_CITY !== undefined &&
          fileData.OWNER_STATE !== undefined &&
          fileData.OWNER_ZIPCODE !== undefined &&
          fileData.CO_OWNER_NAME !== undefined &&
          fileData.CO_OWNER_SSN !== undefined &&
          fileData.CO_OWNER_STREET_1 !== undefined &&
          fileData.CO_OWNER_CITY !== undefined &&
          fileData.CO_OWNER_STATE !== undefined &&
          fileData.CO_OWNER_ZIPCODE !== undefined &&
          fileData.LOCKSMITH_NAME !== undefined &&
          fileData.NOTARY_NAME !== undefined &&
          fileData.BOX_DRILLED_STATUS !== undefined &&
          fileData.BRANCH_ID !== undefined &&
          fileData.UNIQUE_ID !== undefined &&
          fileData.NEXT_AUDIT_DUE_DATE !== undefined &&
          fileData.CONTENTS_FOUND !== undefined &&
          fileData.RENTAL_DUE !== undefined &&
          fileData.OTHER_DUE !== undefined &&
          fileData.TOTAL_DUE !== undefined &&
          fileData.PAST_DUE_RENTAL_FEE !== undefined &&
          fileData.FORCE_OPEN_COST !== undefined &&
          fileData.INVENTORY_FEE !== undefined &&
          fileData.TOTAL_FEE !== undefined &&
          fileData.LEGACY !== undefined &&
          fileData.ACTIVITY_TYPE !== undefined &&
          fileData.TAMPEREVIDENTBAG_STATUS !== undefined &&
          fileData.ADDENDUM_A_TAMPEREVIDENTBAG_NUMBER !== undefined &&
          fileData.ADDENDUM_A_CATEGORY_NAME !== undefined &&
          fileData.ADDENDUM_A_ITEM_NAME !== undefined &&
          fileData.ADDENDUM_A_QUANTITY !== undefined &&
          fileData.ADDENDUM_B_TAMPEREVIDENTBAG_NUMBER !== undefined &&
          fileData.ADDENDUM_B_CATEGORY_NAME !== undefined &&
          fileData.ADDENDUM_B_ITEM_NAME !== undefined &&
          fileData.ADDENDUM_B_QUANTITY !== undefined &&
          fileData.ADDENDUM_C_STOCK_CERTIFICATES_TAMPEREVIDENTBAG_NUMBER !==
            undefined &&
          fileData.ADDENDUM_C_STOCK_CERTIFICATES_QUANTITY !== undefined &&
          fileData.ADDENDUM_C_STOCK_CERTIFICATE_STOCK_CERTIFICATE !==
            undefined &&
          fileData.ADDENDUM_C_OTHER_BOND_TAMPEREVIDENTBAG_NUMBER !==
            undefined &&
          fileData.ADDENDUM_C_OTHER_BOND_QUANTITY !== undefined &&
          fileData.ADDENDUM_C_OTHER_BOND_OTHER_BONDS !== undefined &&
          fileData.ADDENDUM_C_OTHER_BOND_VALUE_OF_BOND !== undefined &&
          fileData.ADDENDUM_C_OTHER_BOND_DESCRIPTION_OF_BONDS !== undefined &&
          fileData.ADDENDUM_C_CONTRA_BAND_TAMPEREVIDENTBAG_NUMBER !==
            undefined &&
          fileData.ADDENDUM_C_CONTRA_BAND_QUANTITY !== undefined &&
          fileData.ADDENDUM_C_CONTRA_BAND_CONTRA_BAND !== undefined &&
          fileData.ADDENDUM_C_MISCELLANEOUS_TAMPEREVIDENTBAG_NUMBER !==
            undefined &&
          fileData.ADDENDUM_C_MISCELLANEOUS_QUANTITY !== undefined &&
          fileData.ADDENDUM_C_MISCELLANEOUS_MISCELLANEOUS !== undefined &&
          fileData.DAYS_UNTIL_NEXT_AUDIT !== undefined &&
          fileData.AUDIT_DUE_STATUS !== undefined &&
          fileData.CONTENT_RELEASE_STATUS !== undefined &&
          fileData.BRANCH_SDB_AUDIT !== undefined &&
          fileData.AUDIT_BAG_STATUS !== undefined &&
          fileData.AUDITOR_NAME !== undefined &&
          fileData.ACTUAL_AUDIT_DATE !== undefined &&
          fileData.LAST_AUDIT_DATE !== undefined &&
          fileData.PREVIOUS_AUDIT_BAG_STATUS !== undefined &&
          fileData.CURRENT_BRANCH_DID !== undefined &&
          fileData.REGION !== undefined &&
          fileData.MARKET !== undefined &&
          fileData.STATE !== undefined &&
          fileData.BRANCH !== undefined &&
          fileData.CUSTOMER_NAME !== undefined &&
          fileData["SDB#_DRILLED"] !== undefined &&
          fileData.BOX_DRILL_DATE !== undefined &&
          fileData["BAG #"] !== undefined &&
          fileData[
            "Branch where the box was physically located when drilled (This data in this column should never change)"
          ] !== undefined &&
          fileData.ORIGINAL_BRANCH_DID !== undefined &&
          fileData.PROHIBITED_ITEM !== undefined &&
          fileData.OPS_SPECIALIST !== undefined &&
          fileData.MARKET_MANAGER !== undefined &&
          fileData.FORM_LINK !== undefined &&
          fileData.RECORD_ID !== undefined &&
          fileData.BAG_DATA !== undefined &&
          fileData.DUPLICATE_RECORD !== undefined &&
          fileData[
            "Branch Use SDB # (or other specific storage location of drilled content)"
          ] !== undefined
        ) {
          return {
            CONTRACT_ON_FILE: fileData.CONTRACT_ON_FILE ? true : false,
            ACCESS_LOG: fileData.ACCESS_LOG ? true : false,
            FORCE_OPEN_DATE: fileData.FORCE_OPEN_DATE
              ? fileData.FORCE_OPEN_DATE
              : "",
            SAFEDEPOSITBOX_NUMBER: fileData.SAFEDEPOSITBOX_NUMBER
              ? fileData.SAFEDEPOSITBOX_NUMBER
              : "",
            OWNER_NAME: fileData.OWNER_NAME ? fileData.OWNER_NAME : "",
            OWNER_SSN: fileData.OWNER_SSN ? fileData.OWNER_SSN : "",
            OWNER_STREET_1: fileData.OWNER_STREET_1
              ? fileData.OWNER_STREET_1
              : "",
            OWNER_CITY: fileData.OWNER_CITY ? fileData.OWNER_CITY : "",
            OWNER_STATE: fileData.OWNER_STATE ? fileData.OWNER_STATE : "",
            OWNER_ZIPCODE: fileData.OWNER_ZIPCODE ? fileData.OWNER_ZIPCODE : "",
            CO_OWNER_NAME: fileData.CO_OWNER_NAME ? fileData.CO_OWNER_NAME : "",
            CO_OWNER_SSN: fileData.CO_OWNER_SSN ? fileData.CO_OWNER_SSN : "",
            CO_OWNER_STREET_1: fileData.OWNER_STREET_1
              ? fileData.OWNER_STREET_1
              : "",
            CO_OWNER_CITY: fileData.OWNER_CITY ? fileData.OWNER_CITY : "",
            CO_OWNER_STATE: fileData.OWNER_STATE ? fileData.OWNER_STATE : "",
            CO_OWNER_ZIPCODE: fileData.OWNER_ZIPCODE
              ? fileData.OWNER_ZIPCODE
              : "",
            LOCKSMITH_NAME: fileData.LOCKSMITH_NAME
              ? fileData.LOCKSMITH_NAME
              : "",
            NOTARY_NAME: fileData.NOTARY_NAME ? fileData.NOTARY_NAME : "",
            BOX_DRILLED_STATUS: fileData.BOX_DRILLED_STATUS ? true : false,
            CONTENTS_FOUND: fileData.CONTENTS_FOUND ? true : false,
            RENTAL_DUE: fileData.RENTAL_DUE ? fileData.RENTAL_DUE : "",
            OTHER_DUE: fileData.OTHER_DUE ? fileData.OTHER_DUE : "",
            TOTAL_DUE: fileData.TOTAL_DUE ? fileData.TOTAL_DUE : "",
            FORCE_OPEN_COST: fileData.FORCE_OPEN_COST
              ? fileData.FORCE_OPEN_COST
              : "",
            BRANCH_ID: fileData.BRANCH_ID ? fileData.BRANCH_ID : "",
            UNIQUE_ID: fileData.UNIQUE_ID ? fileData.UNIQUE_ID : "",
            PAST_DUE_RENTAL_FEE: fileData.PAST_DUE_RENTAL_FEE
              ? fileData.PAST_DUE_RENTAL_FEE
              : "",
            INVENTORY_FEE: fileData.INVENTORY_FEE ? fileData.INVENTORY_FEE : "",
            TOTAL_FEE: fileData.TOTAL_FEE ? fileData.TOTAL_FEE : "",
            LEGACY: fileData.LEGACY ? true : false,
            ACTIVITY_TYPE: fileData.ACTIVITY_TYPE ? fileData.ACTIVITY_TYPE : "",
            TAMPEREVIDENTBAG_STATUS: fileData.TAMPEREVIDENTBAG_STATUS
              ? fileData.TAMPEREVIDENTBAG_STATUS
              : "",
            ADDENDUM_A_TAMPEREVIDENTBAG_NUMBER:
              fileData.ADDENDUM_A_TAMPEREVIDENTBAG_NUMBER
                ? fileData.ADDENDUM_A_TAMPEREVIDENTBAG_NUMBER
                : "",
            ADDENDUM_A_CATEGORY_NAME: fileData.ADDENDUM_A_CATEGORY_NAME
              ? fileData.ADDENDUM_A_CATEGORY_NAME
              : "",
            ADDENDUM_A_ITEM_NAME: fileData.ADDENDUM_A_ITEM_NAME
              ? fileData.ADDENDUM_A_ITEM_NAME
              : "",
            ADDENDUM_A_QUANTITY: fileData.ADDENDUM_A_QUANTITY
              ? fileData.ADDENDUM_A_QUANTITY
              : "",
            ADDENDUM_B_TAMPEREVIDENTBAG_NUMBER:
              fileData.ADDENDUM_B_TAMPEREVIDENTBAG_NUMBER
                ? fileData.ADDENDUM_B_TAMPEREVIDENTBAG_NUMBER
                : "",
            ADDENDUM_B_CATEGORY_NAME: fileData.ADDENDUM_B_CATEGORY_NAME
              ? fileData.ADDENDUM_B_CATEGORY_NAME
              : "",
            ADDENDUM_B_ITEM_NAME: fileData.ADDENDUM_B_ITEM_NAME
              ? fileData.ADDENDUM_B_ITEM_NAME
              : "",
            ADDENDUM_B_QUANTITY: fileData.ADDENDUM_B_QUANTITY
              ? fileData.ADDENDUM_B_QUANTITY
              : "",
            ADDENDUM_C_STOCK_CERTIFICATES_TAMPEREVIDENTBAG_NUMBER:
              fileData.ADDENDUM_C_STOCK_CERTIFICATES_TAMPEREVIDENTBAG_NUMBER
                ? fileData.ADDENDUM_C_STOCK_CERTIFICATES_TAMPEREVIDENTBAG_NUMBER
                : "",
            ADDENDUM_C_STOCK_CERTIFICATES_QUANTITY:
              fileData.ADDENDUM_C_STOCK_CERTIFICATES_QUANTITY
                ? fileData.ADDENDUM_C_STOCK_CERTIFICATES_QUANTITY
                : "",
            ADDENDUM_C_STOCK_CERTIFICATE_STOCK_CERTIFICATE:
              fileData.ADDENDUM_C_STOCK_CERTIFICATE_STOCK_CERTIFICATE
                ? fileData.ADDENDUM_C_STOCK_CERTIFICATE_STOCK_CERTIFICATE
                : "",
            ADDENDUM_C_OTHER_BOND_TAMPEREVIDENTBAG_NUMBER:
              fileData.ADDENDUM_C_OTHER_BOND_TAMPEREVIDENTBAG_NUMBER
                ? fileData.ADDENDUM_C_OTHER_BOND_TAMPEREVIDENTBAG_NUMBER
                : "",
            ADDENDUM_C_OTHER_BOND_QUANTITY:
              fileData.ADDENDUM_C_OTHER_BOND_QUANTITY
                ? fileData.ADDENDUM_C_OTHER_BOND_QUANTITY
                : "",
            ADDENDUM_C_OTHER_BOND_OTHER_BONDS:
              fileData.ADDENDUM_C_OTHER_BOND_OTHER_BONDS
                ? fileData.ADDENDUM_C_OTHER_BOND_OTHER_BONDS
                : "",
            ADDENDUM_C_OTHER_BOND_VALUE_OF_BOND:
              fileData.ADDENDUM_C_OTHER_BOND_VALUE_OF_BOND
                ? fileData.ADDENDUM_C_OTHER_BOND_VALUE_OF_BOND
                : "",
            ADDENDUM_C_OTHER_BOND_DESCRIPTION_OF_BONDS:
              fileData.ADDENDUM_C_OTHER_BOND_DESCRIPTION_OF_BONDS
                ? fileData.ADDENDUM_C_OTHER_BOND_DESCRIPTION_OF_BONDS
                : "",
            ADDENDUM_C_CONTRA_BAND_TAMPEREVIDENTBAG_NUMBER:
              fileData.ADDENDUM_C_CONTRA_BAND_TAMPEREVIDENTBAG_NUMBER
                ? fileData.ADDENDUM_C_CONTRA_BAND_TAMPEREVIDENTBAG_NUMBER
                : "",
            ADDENDUM_C_CONTRA_BAND_QUANTITY:
              fileData.ADDENDUM_C_CONTRA_BAND_QUANTITY
                ? fileData.ADDENDUM_C_CONTRA_BAND_QUANTITY
                : "",
            ADDENDUM_C_CONTRA_BAND_CONTRA_BAND:
              fileData.ADDENDUM_C_CONTRA_BAND_CONTRA_BAND
                ? fileData.ADDENDUM_C_CONTRA_BAND_CONTRA_BAND
                : "",
            ADDENDUM_C_MISCELLANEOUS_TAMPEREVIDENTBAG_NUMBER:
              fileData.ADDENDUM_C_MISCELLANEOUS_TAMPEREVIDENTBAG_NUMBER
                ? fileData.ADDENDUM_C_MISCELLANEOUS_TAMPEREVIDENTBAG_NUMBER
                : "",
            ADDENDUM_C_MISCELLANEOUS_QUANTITY:
              fileData.ADDENDUM_C_MISCELLANEOUS_QUANTITY
                ? fileData.ADDENDUM_C_MISCELLANEOUS_QUANTITY
                : "",
            ADDENDUM_C_MISCELLANEOUS_MISCELLANEOUS:
              fileData.ADDENDUM_C_MISCELLANEOUS_MISCELLANEOUS
                ? fileData.ADDENDUM_C_MISCELLANEOUS_MISCELLANEOUS
                : "",
            NEXT_AUDIT_DUE_DATE: fileData.NEXT_AUDIT_DUE_DATE
              ? fileData.NEXT_AUDIT_DUE_DATE
              : "",

            DAYS_UNTIL_NEXT_AUDIT: fileData.DAYS_UNTIL_NEXT_AUDIT
              ? fileData.DAYS_UNTIL_NEXT_AUDIT
              : "",
            AUDIT_DUE_STATUS: fileData.AUDIT_DUE_STATUS
              ? fileData.AUDIT_DUE_STATUS
              : "",
            CONTENT_RELEASE_STATUS: fileData.CONTENT_RELEASE_STATUS
              ? fileData.CONTENT_RELEASE_STATUS
              : "",
            BRANCH_SDB_AUDIT: fileData.BRANCH_SDB_AUDIT
              ? fileData.BRANCH_SDB_AUDIT
              : "",
            STATUS: fileData.STATUS ? fileData.STATUS : "",
            AUDIT_BAG_STATUS: fileData.AUDIT_BAG_STATUS
              ? fileData.AUDIT_BAG_STATUS
              : "",
            AUDITOR_NAME: fileData.AUDITOR_NAME ? fileData.AUDITOR_NAME : "",
            ACTUAL_AUDIT_DATE: fileData["ACTUAL_AUDIT_DATE"]
              ? fileData["ACTUAL_AUDIT_DATE"]
              : "",

            LAST_AUDIT_DATE: fileData["LAST_AUDIT_DATE"]
              ? fileData["LAST_AUDIT_DATE"]
              : "",

            PREVIOUS_AUDIT_BAG_STATUS: fileData["PREVIOUS_AUDIT_BAG_STATUS"]
              ? fileData["PREVIOUS_AUDIT_BAG_STATUS"]
              : "",
            CURRENT_BRANCH_DID: fileData.CURRENT_BRANCH_DID
              ? fileData.CURRENT_BRANCH_DID
              : "",
            REGION: fileData.REGION ? fileData.REGION : "",
            MARKET: fileData.MARKET ? fileData.MARKET : "",
            STATE: fileData.STATE ? fileData.STATE : "",
            BRANCH: fileData.BRANCH ? fileData.BRANCH : "",
            CUSTOMER_NAME: fileData["CUSTOMER_NAME"]
              ? fileData["CUSTOMER_NAME"]
              : "",
            "SDB#_DRILLED": fileData["SDB#_DRILLED"]
              ? fileData["SDB#_DRILLED"]
              : "",
            BOX_DRILL_DATE: fileData.BOX_DRILL_DATE
              ? fileData.BOX_DRILL_DATE
              : "",

            "BAG #": fileData["BAG #"] ? fileData["BAG #"] : "",
            BAG_COUNT: fileData["BAG_COUNT"] ? fileData["BAG_COUNT"] : "",
            "Branch where the box was physically located when drilled (This data in this column should never change)":
              fileData[
                "Branch where the box was physically located when drilled (This data in this column should never change)"
              ]
                ? fileData[
                    "Branch where the box was physically located when drilled (This data in this column should never change)"
                  ]
                : "",
            ORIGINAL_BRANCH_DID: fileData["ORIGINAL_BRANCH_DID"]
              ? fileData["ORIGINAL_BRANCH_DID"]
              : "",
            ORIGINAL_BRANCH_NAME: fileData["ORIGINAL_BRANCH_NAME"]
              ? fileData["ORIGINAL_BRANCH_NAME"]
              : "",
            PROHIBITED_ITEM: fileData["PROHIBITED_ITEM"]
              ? fileData["PROHIBITED_ITEM"]
              : "",
            ROM: fileData.ROM ? fileData.ROM : "",
            OPS_SPECIALIST: fileData["OPS_SPECIALIST"]
              ? fileData["OPS_SPECIALIST"]
              : "",
            MARKET_MANAGER: fileData["MARKET_MANAGER"]
              ? fileData["MARKET_MANAGER"]
              : "",
            FORM_LINK: fileData["FORM_LINK"] ? fileData["FORM_LINK"] : "",
            RECORD_ID: fileData.RECORD_ID ? fileData.RECORD_ID : "",
            BAG_DATA: fileData.BAG_DATA ? fileData.BAG_DATA : "",
            DUPLICATE_RECORD: fileData["DUPLICATE_RECORD"]
              ? fileData["DUPLICATE_RECORD"]
              : "",
            "Branch Use SDB # (or other specific storage location of drilled content)":
              fileData[
                "Branch Use SDB # (or other specific storage location of drilled content)"
              ]
                ? fileData[
                    "Branch Use SDB # (or other specific storage location of drilled content)"
                  ]
                : "",
          };
        } else {
          fileuploadedData = false;

          let title = "Missing Data";
          let text = "Please check and re-upload the file";
          let type = "info";
          let confirmButtonText = "OK";
          let showCancelButton = false;
          let target = document.getElementById("swal-div");
          CustomAlert(
            title,
            text,
            type,
            confirmButtonText,
            showCancelButton,
            target
          ).then((result) => {});
        }
      } else {
        if (
          fileData.CONTRACT_ON_FILE !== undefined &&
          fileData.ACCESS_LOG !== undefined &&
          fileData.FORCE_OPEN_DATE !== undefined &&
          fileData.SAFEDEPOSITBOX_NUMBER !== undefined &&
          fileData.OWNER_NAME !== undefined &&
          fileData.OWNER_SSN !== undefined &&
          fileData.OWNER_STREET_1 !== undefined &&
          fileData.OWNER_CITY !== undefined &&
          fileData.OWNER_STATE !== undefined &&
          fileData.OWNER_ZIPCODE !== undefined &&
          fileData.CO_OWNER_NAME !== undefined &&
          fileData.CO_OWNER_SSN !== undefined &&
          fileData.CO_OWNER_STREET_1 !== undefined &&
          fileData.CO_OWNER_CITY !== undefined &&
          fileData.CO_OWNER_STATE !== undefined &&
          fileData.CO_OWNER_ZIPCODE !== undefined &&
          fileData.LOCKSMITH_NAME !== undefined &&
          fileData.NOTARY_NAME !== undefined &&
          fileData.BOX_DRILLED_STATUS !== undefined &&
          fileData.CONTENTS_FOUND !== undefined &&
          fileData.RENTAL_DUE !== undefined &&
          fileData.OTHER_DUE !== undefined &&
          fileData.TOTAL_DUE !== undefined &&
          fileData.PAST_DUE_RENTAL_FEE !== undefined &&
          fileData.FORCE_OPEN_COST !== undefined &&
          fileData.INVENTORY_FEE !== undefined &&
          fileData.TOTAL_FEE !== undefined &&
          fileData.LEGACY !== undefined &&
          fileData.ACTIVITY_TYPE !== undefined &&
          fileData.TAMPEREVIDENTBAG_STATUS !== undefined &&
          fileData.ADDENDUM_A_TAMPEREVIDENTBAG_NUMBER !== undefined &&
          fileData.ADDENDUM_A_CATEGORY_NAME !== undefined &&
          fileData.ADDENDUM_A_ITEM_NAME !== undefined &&
          fileData.ADDENDUM_A_QUANTITY !== undefined &&
          fileData.ADDENDUM_B_TAMPEREVIDENTBAG_NUMBER !== undefined &&
          fileData.ADDENDUM_B_CATEGORY_NAME !== undefined &&
          fileData.ADDENDUM_B_ITEM_NAME !== undefined &&
          fileData.ADDENDUM_B_QUANTITY !== undefined &&
          fileData.ADDENDUM_C_STOCK_CERTIFICATES_TAMPEREVIDENTBAG_NUMBER !==
            undefined &&
          fileData.ADDENDUM_C_STOCK_CERTIFICATES_QUANTITY !== undefined &&
          fileData.ADDENDUM_C_STOCK_CERTIFICATE_STOCK_CERTIFICATE !==
            undefined &&
          fileData.ADDENDUM_C_OTHER_BOND_TAMPEREVIDENTBAG_NUMBER !==
            undefined &&
          fileData.ADDENDUM_C_OTHER_BOND_QUANTITY !== undefined &&
          fileData.ADDENDUM_C_OTHER_BOND_OTHER_BONDS !== undefined &&
          fileData.ADDENDUM_C_OTHER_BOND_VALUE_OF_BOND !== undefined &&
          fileData.ADDENDUM_C_OTHER_BOND_DESCRIPTION_OF_BONDS !== undefined &&
          fileData.ADDENDUM_C_CONTRA_BAND_TAMPEREVIDENTBAG_NUMBER !==
            undefined &&
          fileData.ADDENDUM_C_CONTRA_BAND_QUANTITY !== undefined &&
          fileData.ADDENDUM_C_CONTRA_BAND_CONTRA_BAND !== undefined &&
          fileData.ADDENDUM_C_MISCELLANEOUS_TAMPEREVIDENTBAG_NUMBER !==
            undefined &&
          fileData.ADDENDUM_C_MISCELLANEOUS_QUANTITY !== undefined &&
          fileData.ADDENDUM_C_MISCELLANEOUS_MISCELLANEOUS !== undefined &&
          fileData.CURRENT_BRANCH_DID !== undefined &&
          fileData.BRANCH !== undefined &&
          fileData.STATUS !== undefined &&
          fileData.CONTROL_NUMBER !== undefined &&
          fileData.CUSTOMER_FIRST_NAME !== undefined &&
          fileData.CUSTOMER_LAST_NAME !== undefined &&
          //fileData["TAMPER_EVIDENT_BAG#"] !== undefined &&
          fileData.STARTING_TRANSACTION_DATE !== undefined &&
          fileData.SDB_LOCATION_OF_CONTENTS !== undefined &&
          fileData.ACCOUNT_NUMBER !== undefined &&
          fileData.ORIGINAL_BRANCH_DID !== undefined
        ) {
          return {
            CONTRACT_ON_FILE: fileData.CONTRACT_ON_FILE ? true : false,
            ACCESS_LOG: fileData.ACCESS_LOG ? true : false,
            FORCE_OPEN_DATE: fileData.FORCE_OPEN_DATE
              ? fileData.FORCE_OPEN_DATE
              : "",
            SAFEDEPOSITBOX_NUMBER: fileData.SAFEDEPOSITBOX_NUMBER
              ? fileData.SAFEDEPOSITBOX_NUMBER
              : "",
            OWNER_NAME: fileData.OWNER_NAME ? fileData.OWNER_NAME : "",
            OWNER_SSN: fileData.OWNER_SSN ? fileData.OWNER_SSN : "",
            OWNER_STREET_1: fileData.OWNER_STREET_1
              ? fileData.OWNER_STREET_1
              : "",
            OWNER_CITY: fileData.OWNER_CITY ? fileData.OWNER_CITY : "",
            OWNER_STATE: fileData.OWNER_STATE ? fileData.OWNER_STATE : "",
            OWNER_ZIPCODE: fileData.OWNER_ZIPCODE ? fileData.OWNER_ZIPCODE : "",
            CO_OWNER_NAME: fileData.CO_OWNER_NAME ? fileData.CO_OWNER_NAME : "",
            CO_OWNER_SSN: fileData.CO_OWNER_SSN ? fileData.CO_OWNER_SSN : "",
            CO_OWNER_STREET_1: fileData.OWNER_STREET_1
              ? fileData.OWNER_STREET_1
              : "",
            CO_OWNER_CITY: fileData.OWNER_CITY ? fileData.OWNER_CITY : "",
            CO_OWNER_STATE: fileData.OWNER_STATE ? fileData.OWNER_STATE : "",
            CO_OWNER_ZIPCODE: fileData.OWNER_ZIPCODE
              ? fileData.OWNER_ZIPCODE
              : "",
            LOCKSMITH_NAME: fileData.LOCKSMITH_NAME
              ? fileData.LOCKSMITH_NAME
              : "",
            NOTARY_NAME: fileData.NOTARY_NAME ? fileData.NOTARY_NAME : "",
            BOX_DRILLED_STATUS: fileData.BOX_DRILLED_STATUS ? true : false,
            CONTENTS_FOUND: fileData.CONTENTS_FOUND ? true : false,
            RENTAL_DUE: fileData.RENTAL_DUE ? fileData.RENTAL_DUE : "",
            OTHER_DUE: fileData.OTHER_DUE ? fileData.OTHER_DUE : "",
            TOTAL_DUE: fileData.TOTAL_DUE ? fileData.TOTAL_DUE : "",
            FORCE_OPEN_COST: fileData.FORCE_OPEN_COST
              ? fileData.FORCE_OPEN_COST
              : "",
            PAST_DUE_RENTAL_FEE: fileData.PAST_DUE_RENTAL_FEE
              ? fileData.PAST_DUE_RENTAL_FEE
              : "",
            INVENTORY_FEE: fileData.INVENTORY_FEE ? fileData.INVENTORY_FEE : "",
            TOTAL_FEE: fileData.TOTAL_FEE ? fileData.TOTAL_FEE : "",
            LEGACY: fileData.LEGACY ? true : false,
            ACTIVITY_TYPE: fileData.ACTIVITY_TYPE ? fileData.ACTIVITY_TYPE : "",
            TAMPEREVIDENTBAG_STATUS: fileData.TAMPEREVIDENTBAG_STATUS
              ? fileData.TAMPEREVIDENTBAG_STATUS
              : "",
            ADDENDUM_A_TAMPEREVIDENTBAG_NUMBER:
              fileData.ADDENDUM_A_TAMPEREVIDENTBAG_NUMBER
                ? fileData.ADDENDUM_A_TAMPEREVIDENTBAG_NUMBER
                : "",
            ADDENDUM_A_CATEGORY_NAME: fileData.ADDENDUM_A_CATEGORY_NAME
              ? fileData.ADDENDUM_A_CATEGORY_NAME
              : "",
            ADDENDUM_A_ITEM_NAME: fileData.ADDENDUM_A_ITEM_NAME
              ? fileData.ADDENDUM_A_ITEM_NAME
              : "",
            ADDENDUM_A_QUANTITY: fileData.ADDENDUM_A_QUANTITY
              ? fileData.ADDENDUM_A_QUANTITY
              : "",
            ADDENDUM_B_TAMPEREVIDENTBAG_NUMBER:
              fileData.ADDENDUM_B_TAMPEREVIDENTBAG_NUMBER
                ? fileData.ADDENDUM_B_TAMPEREVIDENTBAG_NUMBER
                : "",
            ADDENDUM_B_CATEGORY_NAME: fileData.ADDENDUM_B_CATEGORY_NAME
              ? fileData.ADDENDUM_B_CATEGORY_NAME
              : "",
            ADDENDUM_B_ITEM_NAME: fileData.ADDENDUM_B_ITEM_NAME
              ? fileData.ADDENDUM_B_ITEM_NAME
              : "",
            ADDENDUM_B_QUANTITY: fileData.ADDENDUM_B_QUANTITY
              ? fileData.ADDENDUM_B_QUANTITY
              : "",
            ADDENDUM_C_STOCK_CERTIFICATES_TAMPEREVIDENTBAG_NUMBER:
              fileData.ADDENDUM_C_STOCK_CERTIFICATES_TAMPEREVIDENTBAG_NUMBER
                ? fileData.ADDENDUM_C_STOCK_CERTIFICATES_TAMPEREVIDENTBAG_NUMBER
                : "",
            ADDENDUM_C_STOCK_CERTIFICATES_QUANTITY:
              fileData.ADDENDUM_C_STOCK_CERTIFICATES_QUANTITY
                ? fileData.ADDENDUM_C_STOCK_CERTIFICATES_QUANTITY
                : "",
            ADDENDUM_C_STOCK_CERTIFICATE_STOCK_CERTIFICATE:
              fileData.ADDENDUM_C_STOCK_CERTIFICATE_STOCK_CERTIFICATE
                ? fileData.ADDENDUM_C_STOCK_CERTIFICATE_STOCK_CERTIFICATE
                : "",
            ADDENDUM_C_OTHER_BOND_TAMPEREVIDENTBAG_NUMBER:
              fileData.ADDENDUM_C_OTHER_BOND_TAMPEREVIDENTBAG_NUMBER
                ? fileData.ADDENDUM_C_OTHER_BOND_TAMPEREVIDENTBAG_NUMBER
                : "",
            ADDENDUM_C_OTHER_BOND_QUANTITY:
              fileData.ADDENDUM_C_OTHER_BOND_QUANTITY
                ? fileData.ADDENDUM_C_OTHER_BOND_QUANTITY
                : "",
            ADDENDUM_C_OTHER_BOND_OTHER_BONDS:
              fileData.ADDENDUM_C_OTHER_BOND_OTHER_BONDS
                ? fileData.ADDENDUM_C_OTHER_BOND_OTHER_BONDS
                : "",
            ADDENDUM_C_OTHER_BOND_VALUE_OF_BOND:
              fileData.ADDENDUM_C_OTHER_BOND_VALUE_OF_BOND
                ? fileData.ADDENDUM_C_OTHER_BOND_VALUE_OF_BOND
                : "",
            ADDENDUM_C_OTHER_BOND_DESCRIPTION_OF_BONDS:
              fileData.ADDENDUM_C_OTHER_BOND_DESCRIPTION_OF_BONDS
                ? fileData.ADDENDUM_C_OTHER_BOND_DESCRIPTION_OF_BONDS
                : "",
            ADDENDUM_C_CONTRA_BAND_TAMPEREVIDENTBAG_NUMBER:
              fileData.ADDENDUM_C_CONTRA_BAND_TAMPEREVIDENTBAG_NUMBER
                ? fileData.ADDENDUM_C_CONTRA_BAND_TAMPEREVIDENTBAG_NUMBER
                : "",
            ADDENDUM_C_CONTRA_BAND_QUANTITY:
              fileData.ADDENDUM_C_CONTRA_BAND_QUANTITY
                ? fileData.ADDENDUM_C_CONTRA_BAND_QUANTITY
                : "",
            ADDENDUM_C_CONTRA_BAND_CONTRA_BAND:
              fileData.ADDENDUM_C_CONTRA_BAND_CONTRA_BAND
                ? fileData.ADDENDUM_C_CONTRA_BAND_CONTRA_BAND
                : "",
            ADDENDUM_C_MISCELLANEOUS_TAMPEREVIDENTBAG_NUMBER:
              fileData.ADDENDUM_C_MISCELLANEOUS_TAMPEREVIDENTBAG_NUMBER
                ? fileData.ADDENDUM_C_MISCELLANEOUS_TAMPEREVIDENTBAG_NUMBER
                : "",

            ADDENDUM_C_MISCELLANEOUS_QUANTITY:
              fileData.ADDENDUM_C_MISCELLANEOUS_QUANTITY
                ? fileData.ADDENDUM_C_MISCELLANEOUS_QUANTITY
                : "",
            ADDENDUM_C_MISCELLANEOUS_MISCELLANEOUS:
              fileData.ADDENDUM_C_MISCELLANEOUS_MISCELLANEOUS
                ? fileData.ADDENDUM_C_MISCELLANEOUS_MISCELLANEOUS
                : "",

            CURRENT_BRANCH_DID: fileData.CURRENT_BRANCH_DID
              ? fileData.CURRENT_BRANCH_DID
              : "",

            BRANCH: fileData.BRANCH ? fileData.BRANCH : "",
            STATUS: fileData.STATUS ? fileData.STATUS : "",
            CONTROL_NUMBER: fileData.CONTROL_NUMBER
              ? fileData.CONTROL_NUMBER
              : "",
            CUSTOMER_FIRST_NAME: fileData.CUSTOMER_FIRST_NAME
              ? fileData.CUSTOMER_FIRST_NAME
              : "",
            CUSTOMER_LAST_NAME: fileData.CUSTOMER_LAST_NAME
              ? fileData.CUSTOMER_LAST_NAME
              : "",
            "TAMPER_EVIDENT_BAG#": fileData["TAMPER_EVIDENT_BAG#"]
              ? fileData["TAMPER_EVIDENT_BAG#"]
              : "",
            STARTING_TRANSACTION_DATE: fileData.STARTING_TRANSACTION_DATE
              ? fileData.STARTING_TRANSACTION_DATE
              : "",
            SDB_LOCATION_OF_CONTENTS: fileData.SDB_LOCATION_OF_CONTENTS
              ? fileData.SDB_LOCATION_OF_CONTENTS
              : "",
            SDB_TOTE_ID: fileData.SDB_TOTE_ID ? fileData.SDB_TOTE_ID : "",
            ACCOUNT_NUMBER: fileData.ACCOUNT_NUMBER
              ? fileData.ACCOUNT_NUMBER
              : "",
            ORIGINAL_BRANCH_DID: fileData.ORIGINAL_BRANCH_DID
              ? fileData.ORIGINAL_BRANCH_DID
              : "",
            // "Location of Contents": fileData["Location of Contents"] ? fileData["Location of Contents"] : "",
            "Branch where the box was physically located when drilled (This data in this column should never change)":
              fileData[
                "Branch where the box was physically located when drilled (This data in this column should never change)"
              ]
                ? fileData[
                    "Branch where the box was physically located when drilled (This data in this column should never change)"
                  ]
                : "",
          };
        } else {
          fileuploadedData = false;
          let title = "Missing Data";
          let text = "Please check and re-upload the file";
          let type = "info";
          let confirmButtonText = "OK";
          let showCancelButton = false;
          let target = document.getElementById("swal-div");
          CustomAlert(
            title,
            text,
            type,
            confirmButtonText,
            showCancelButton,
            target
          ).then((result) => {});
        }
      }
    });
    if (fileuploadedData !== false) {
      setFileData(fileuploadrowsdata);

      let dataSource = {
        data: fileuploadrowsdata,
        activity_type: activityType,
        employee_name: currentUser?.employee_name,
        employee_id: currentUser?.employee_id,
        employee_designation: currentUser?.employee_designation,
      };
      getCustomData(JSON.stringify(dataSource), currentUser?.jwtToken)
        .then((response) => {
          Swal.close();
          if (response.status === 401) {
            cognitoSignOut()
              .then(() => {
                navigate("/");
              })
              .catch((error) => {
                ConsoleError(error);
              });
          } else if (response?.data.status === 400) {
            let title = "Invalid Records";
            let text = response?.data.message;
            let type = "warning";
            let confirmButtonText = "OK";
            let showCancelButton = false;
            let target = document.getElementById("swal-div");
            // let target = "swal-div";
            CustomAlert(
              title,
              text,
              type,
              confirmButtonText,
              showCancelButton,
              target
            ).then((result) => {
              setFileUploaded(true);
              handleChange("step1", "fileuploadrows", null);
              setFileName("File Name");
            });
          } else if (response?.data.status === 409) {
            setReview(true);
            // setCustomData(responseData.conflict_data);
            let data = response?.data.data;
            // setFileData(fileuploadrowsdata)
            setRows(data);
            setConflictData(data);
            let title = "Duplicate Records Found";
            let text =
              "Please update each record or modify the data and reupload the file";
            let type = "warning";
            let confirmButtonText = "OK";
            let showCancelButton = false;
            let target = document.getElementById("swal-div");
            // let target = "swal-div";
            CustomAlert(
              title,
              text,
              type,
              confirmButtonText,
              showCancelButton,
              target
            ).then((result) => {
              setFileUploaded(false);
            });
            setDataField(true);
            //item.uploaded_data = data
          } else if (response?.status === 500) {
            let title = ERROR_MESSAGE;
            let text = "Please try after some time";
            let type = "error";
            let confirmButtonText = "OK";
            let showCancelButton = false;
            let target = document.getElementById("swal-div");
            // let target = "swal-div";
            CustomAlert(
              title,
              text,
              type,
              confirmButtonText,
              showCancelButton,
              target
            ).then((result) => {
              handleChange("step1", "fileuploadrows", null);
              setFileName("File Name");
            });
          } else if (response?.data?.status === 200) {
            let title = "Success";
            let text = response?.data.message;
            let type = "success";
            let confirmButtonText = "OK";
            let showCancelButton = false;
            let target = document.getElementById("swal-div");
            // let target = "swal-div";
            CustomAlert(
              title,
              text,
              type,
              confirmButtonText,
              showCancelButton,
              target
            ).then((result) => {
              handleChange("step1", "fileuploadrows", null);
              setFileUploaded(true);
              setFileName("File Name");
            });
          }
        })
        .catch((error) => {
          console.error(error);
          ConsoleError(error);
          let title = ERROR_MESSAGE;
          let text = "Error Message";
          let type = "error";
          let confirmButtonText = "OK";
          let showCancelButton = false;
          let target = document.getElementById("swal-div");
          // let target = "swal-div";
          CustomAlert(
            title,
            text,
            type,
            confirmButtonText,
            showCancelButton,
            target
          ).then((result) => {
            handleChange("step1", "fileuploadrows", null);
            setFileName("File Name");
          });
        });
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    if (showData && showData.length) {
      let propsdata = [...showData];
      let idx = (pageState.pageNum - 1) * pageState.rowsPerPage;
      // setRows(!datafield?propsdata.slice(idx, idx + pageState.rowsPerPage):showData);
      setRows(propsdata.slice(idx, idx + pageState.rowsPerPage));
    }
  }, [pageState, showData]);

  let onUpdateClick = (item, index) => {
    item["key"] = "yes";
    setRows(rows);
    // setInputIndex(index);
    setClickIndex((clickIndex) => [...clickIndex, index]);
    if (clickIndex.length >= conflictData.length - 1) {
      setVerified(false);
    } else {
      setVerified(true);
    }
  };

  let onEditInput = (item, index) => {
    clickIndex.splice(index, 1);
    item["key"] = "no";
    setRows(rows);
    setVerified(true);
    // setInputIndex(index);
    setUpdate(!update);
  };
  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 ">
        <div className="row flex pt-4 pl-4 pr-4 pb-0 " id="swal-div">
          <div className="col-lg-12 col-md-12 col-sm-12 pb-2git a pl-0 pr-0 overflow-hidden">
            <div className="row pt-1 pb-2">
              <div className="d-flex justify-content-end">
                <div className="p-0">
                  {currentUser?.check_in_save_record_access_flag && (
                    <Link to="/singleCheckIn">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        id="bulk-checkin-button"
                      >
                        SINGLE CHECK-IN
                      </button>
                    </Link>
                  )}
                </div>
                <div className="p-0">
                  <Link to="/viewAllCheckIn">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      id="checkin-records-button"
                    >
                      CHECK-IN RECORDS
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <MDBCard className="card">
              <CustomHeader id="single" label={"CUSTOM BULK CHECK-IN"} />

              <div className="d-flex justify-content-center align-items-center  pt-3">
                <div className="pr-5">
                  <input
                    type="radio"
                    value="fsi"
                    name="radio"
                    className="cb-radio"
                    onChange={onChangeActivity}
                    checked={activityType === "fsi"}
                  />
                  <label className="radio-button">FSI</label>
                </div>
                <div>
                  <input
                    type="radio"
                    value="google_studio"
                    name="radio"
                    className="cb-radio"
                    onChange={onChangeActivity}
                    checked={activityType === "google_studio"}
                  />
                  <label className="radio-button">Google Studio</label>
                </div>
              </div>
              <CustomModal
                currentEventData={currentEventData}
                show={showMessage}
                onHide={() => setShowMessage(false)}
              />
              <div className="divUpload pb-0 d-flex  justify-content-between align-items-center mb-2 p-1">
                <div className="d-flex align-items-center ">
                  {datafield ? (
                    <>
                      <i
                        className="fa fa-info-circle fa-lg pl-3 info-icon pr-2"
                        aria-hidden="true"
                      />
                      <h5
                        className="m-0 p-0 justify-content-start d-flex info-text"
                        style={{ textAlign: "start" }}
                      >
                        Please update all the records to submit the file
                      </h5>
                    </>
                  ) : null}
                </div>
                <div className="d-flex align-items-center">
                  <DocumentUpload onChange={fileUpload} fileName={fileName} />
                </div>
              </div>

              {showData.length > 0 && formData?.step1.fileuploadrows?.length ? (
                <div className="tableFixHead">
                  {(() => {
                    if (activityType === "google_studio") {
                      return (
                        <>
                          <table
                            className="table_wrapper"
                            id={!datafield ? "cbc-scroll" : "cbc-scroll1"}
                          >
                            <tr
                              key={"header"}
                              id="cbc-table-heading"
                              style={{
                                position: "sticky",
                                top: 0,
                                background: "#6F7D95",
                                zIndex: 1,
                              }}
                            >
                              {datafield ? (
                                <th className="headcol1">SELECTED</th>
                              ) : null}
                              {requriedData.map((key, idx) => (
                                <th key={idx}>{key}</th>
                              ))}
                              {datafield ? (
                                <th className="headcol2">
                                  ACTIONS &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <button
                                    id="update-all-btn"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      handleOnChange();
                                    }}
                                    disabled={
                                      error && !isChecked ? true : false
                                    }
                                  >
                                    {!isChecked ? "UPDATE ALL" : "CANCEL ALL"}
                                  </button>
                                </th>
                              ) : null}
                            </tr>
                            <>
                              {rows.map((item, index) => {
                                if (item["key"] === "yes") {
                                  item["key"] = "yes";
                                } else if (item["key"] === "no") {
                                  item["key"] = "no";
                                } else {
                                  item["key"] = "no";
                                }
                                if (item["errorIndex"] === "yes") {
                                  item["errorIndex"] = "yes";
                                } else if (item["errorIndex"] === "no") {
                                  item["errorIndex"] = "no";
                                } else {
                                  item["errorIndex"] = "no";
                                }
                                return (
                                  <>
                                    {datafield ? (
                                      <tr
                                        style={{
                                          background:
                                            index % 2 === 0
                                              ? "#FFFFFF"
                                              : "#e3e3e3",
                                        }}
                                      >
                                        <th
                                          // className="td-text"
                                          className="headcol-text"
                                          style={{
                                            background:
                                              index % 2 === 0
                                                ? "#FFFFFF"
                                                : "#e3e3e3",
                                          }}
                                        ></th>
                                        <td className="first-column">
                                          {item.existing_data
                                            ? item.existing_data
                                                .CONTRACT_ON_FILE
                                              ? "true"
                                              : "false"
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.ACCESS_LOG
                                              ? "true"
                                              : "false"
                                            : null}
                                        </td>
                                        <td className="td-date">
                                          {item.existing_data
                                            ? item.existing_data.FORCE_OPEN_DATE
                                            : null}
                                        </td>

                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .SAFEDEPOSITBOX_NUMBER
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.OWNER_NAME
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.OWNER_SSN
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.OWNER_STREET_1
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.OWNER_CITY
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.OWNER_STATE
                                            : null}
                                        </td>

                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.OWNER_ZIPCODE
                                            : null}
                                        </td>

                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.CO_OWNER_NAME
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.CO_OWNER_SSN
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .CO_OWNER_STREET_1
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.CO_OWNER_CITY
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.CO_OWNER_STATE
                                            : null}
                                        </td>

                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .CO_OWNER_ZIPCODE
                                            : null}
                                        </td>

                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.LOCKSMITH_NAME
                                            : null}
                                        </td>

                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.NOTARY_NAME
                                            : null}
                                        </td>

                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .BOX_DRILLED_STATUS
                                              ? "true"
                                              : "false"
                                            : null}
                                        </td>

                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.CONTENTS_FOUND
                                              ? "true"
                                              : "false"
                                            : null}
                                        </td>

                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.RENTAL_DUE
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.OTHER_DUE
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.TOTAL_DUE
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .PAST_DUE_RENTAL_FEE
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.FORCE_OPEN_COST
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.INVENTORY_FEE
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.TOTAL_FEE
                                            : null}
                                        </td>

                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.LEGACY
                                              ? "true"
                                              : "false"
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.ACTIVITY_TYPE
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .TAMPEREVIDENTBAG_STATUS
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_A_TAMPEREVIDENTBAG_NUMBER
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_A_CATEGORY_NAME
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_A_ITEM_NAME
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_A_QUANTITY
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_B_TAMPEREVIDENTBAG_NUMBER
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_B_CATEGORY_NAME
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_B_ITEM_NAME
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_B_QUANTITY
                                            : ""}
                                        </td>

                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_C_STOCK_CERTIFICATES_TAMPEREVIDENTBAG_NUMBER
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_C_STOCK_CERTIFICATES_QUANTITY
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_C_STOCK_CERTIFICATE_STOCK_CERTIFICATE
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_C_OTHER_BOND_TAMPEREVIDENTBAG_NUMBER
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_C_OTHER_BOND_QUANTITY
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_C_OTHER_BOND_OTHER_BONDS
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_C_OTHER_BOND_VALUE_OF_BOND
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_C_OTHER_BOND_DESCRIPTION_OF_BONDS
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_C_CONTRA_BAND_TAMPEREVIDENTBAG_NUMBER
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_C_CONTRA_BAND_QUANTITY
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_C_CONTRA_BAND_CONTRA_BAND
                                            : null}
                                        </td>

                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_C_MISCELLANEOUS_TAMPEREVIDENTBAG_NUMBER
                                            : null}
                                        </td>

                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_C_MISCELLANEOUS_QUANTITY
                                            : null}
                                        </td>

                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ADDENDUM_C_MISCELLANEOUS_MISCELLANEOUS
                                            : null}
                                        </td>

                                        {/* <td className="first-column">
                                          {item.existing_data
                                            ? item.existing_data
                                                .storage_location
                                            : null}
                                        </td> */}

                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.BRANCH_ID
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.UNIQUE_ID
                                            : null}
                                        </td>
                                        <td className="td-date">
                                          {item.existing_data
                                            ? item.existing_data
                                                .NEXT_AUDIT_DUE_DATE
                                            : null}
                                        </td>
                                        <td className="td-date">
                                          {item.existing_data
                                            ? item.existing_data
                                                .DAYS_UNTIL_NEXT_AUDIT
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .AUDIT_DUE_STATUS
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .CONTENT_RELEASE_STATUS
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .BRANCH_SDB_AUDIT
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.STATUS
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .AUDIT_BAG_STATUS
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.AUDITOR_NAME
                                            : null}
                                        </td>

                                        <td className="td-date">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ACTUAL_AUDIT_DATE
                                            : null}
                                        </td>

                                        <td className="td-date">
                                          {item.existing_data
                                            ? item.existing_data.LAST_AUDIT_DATE
                                            : null}
                                        </td>

                                        <td>
                                          {item.existing_data
                                            ? item.existing_data
                                                .PREVIOUS_AUDIT_BAG_STATUS
                                            : null}
                                        </td>
                                        <td>
                                          {item.existing_data
                                            ? item.existing_data
                                                .CURRENT_BRANCH_DID
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.REGION
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.MARKET
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.STATE
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.BRANCH
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.CUSTOMER_NAME
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data["SDB#_DRILLED"]
                                            : null}
                                        </td>
                                        <td className="td-date">
                                          {item.existing_data
                                            ? item.existing_data.BOX_DRILL_DATE
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data["BAG #"]
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.BAG_COUNT
                                            : null}
                                        </td>
                                        <td>
                                          {item.existing_data
                                            ? item.existing_data[
                                                "Branch where the box was physically located when drilled (This data in this column should never change)"
                                              ]
                                            : null}
                                        </td>
                                        <td>
                                          {item.existing_data
                                            ? item.existing_data
                                                .ORIGINAL_BRANCH_DID
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .ORIGINAL_BRANCH_NAME
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.PROHIBITED_ITEM
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.ROM
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.OPS_SPECIALIST
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.MARKET_MANAGER
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.FORM_LINK
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.RECORD_ID
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data.BAG_DATA
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data
                                                .DUPLICATE_RECORD
                                            : null}
                                        </td>
                                        <td className="td-text">
                                          {item.existing_data
                                            ? item.existing_data[
                                                "Branch Use SDB # (or other specific storage location of drilled content)"
                                              ]
                                            : null}
                                        </td>
                                        <th
                                          className="headcol-text1 pr-5"
                                          rowSpan={2}
                                        >
                                          {item.key === "yes" || isChecked ? (
                                            <>
                                              <h5 className="input-label verifiedText">
                                                Verified{" "}
                                                <img
                                                  className="greenTick"
                                                  src={GreenTick}
                                                  alt="Edit"
                                                  onClick={() => {
                                                    onEditInput(item, index);
                                                  }}
                                                />
                                              </h5>

                                              <img
                                                src={EditStorage}
                                                alt="Edit"
                                                className="pencil-icon updateButton"
                                                onClick={() => {
                                                  onEditInput(item, index);
                                                }}
                                              />
                                            </>
                                          ) : (
                                            <button
                                              id="previous-btn2"
                                              variant="contained"
                                              color="primary"
                                              onClick={() => {
                                                onUpdateClick(item, index);
                                              }}
                                              disabled={
                                                item.errorIndex === "yes"
                                                  ? true
                                                  : false
                                              }
                                            >
                                              UPDATE{" "}
                                            </button>
                                          )}
                                          <div
                                            className="fa fa-info-circle"
                                            id={
                                              clickIndex.includes(index)
                                                ? "hoverIcon"
                                                : "hoverIcon2"
                                            }
                                            data-placement="left"
                                            data-html="true"
                                            style={{ cursor: "pointer" }}
                                            title="If you are not intrested to merge , Please go back to file make changes the try to re-upload"
                                          ></div>
                                        </th>
                                      </tr>
                                    ) : null}

                                    <tr
                                      style={{
                                        background:
                                          datafield && index % 2 === 0
                                            ? "#FFFFFF"
                                            : "#e3e3e3",
                                      }}
                                    >
                                      {" "}
                                      {datafield ? (
                                        <th
                                          className="headcol-text"
                                          style={{
                                            background:
                                              index % 2 === 0
                                                ? "#FFFFFF"
                                                : "#e3e3e3",
                                          }}
                                        >
                                          <input
                                            type="radio"
                                            name={index}
                                            value={conflictRecords}
                                            id="selected-radio"
                                            defaultChecked
                                            style={{
                                              height: "15px",
                                              width: "15px",
                                              textAlign: "center",
                                            }}
                                          />
                                        </th>
                                      ) : null}
                                      <td className="first-column">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data[
                                              "CONTRACT_ON_FILE"
                                            ]
                                            ? "true"
                                            : "false"
                                          : item["CONTRACT_ON_FILE"]
                                          ? "true"
                                          : "false"}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.ACCESS_LOG
                                            ? "true"
                                            : "false"
                                          : item.ACCESS_LOG
                                          ? "true"
                                          : "false"}
                                      </td>
                                      <td className="td-date">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.FORCE_OPEN_DATE
                                          : item.FORCE_OPEN_DATE}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .SAFEDEPOSITBOX_NUMBER
                                          : item.SAFEDEPOSITBOX_NUMBER}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.OWNER_NAME
                                          : item.OWNER_NAME}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.OWNER_SSN
                                          : item.OWNER_SSN}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.OWNER_STREET_1
                                          : item.OWNER_STREET_1}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.OWNER_CITY
                                          : item.OWNER_CITY}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.OWNER_STATE
                                          : item.OWNER_STATE}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.OWNER_ZIPCODE
                                          : item.OWNER_ZIPCODE}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.CO_OWNER_NAME
                                          : item.CO_OWNER_NAME}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.CO_OWNER_SSN
                                          : item.CO_OWNER_SSN}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.CO_OWNER_STREET_1
                                          : item.CO_OWNER_STREET_1}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.CO_OWNER_CITY
                                          : item.CO_OWNER_CITY}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.CO_OWNER_STATE
                                          : item.CO_OWNER_STATE}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.CO_OWNER_ZIPCODE
                                          : item.CO_OWNER_ZIPCODE}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.LOCKSMITH_NAME
                                          : item.LOCKSMITH_NAME}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.NOTARY_NAME
                                          : item.NOTARY_NAME}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .BOX_DRILLED_STATUS
                                            ? "true"
                                            : "false"
                                          : item.BOX_DRILLED_STATUS
                                          ? "true"
                                          : "false"}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.CONTENTS_FOUND
                                            ? "true"
                                            : "false"
                                          : item.CONTENTS_FOUND
                                          ? "true"
                                          : "false"}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.RENTAL_DUE
                                          : item.RENTAL_DUE}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.OTHER_DUE
                                          : item.OTHER_DUE}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.TOTAL_DUE
                                          : item.TOTAL_DUE}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .PAST_DUE_RENTAL_FEE
                                          : item.PAST_DUE_RENTAL_FEE}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.FORCE_OPEN_COST
                                          : item.FORCE_OPEN_COST}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.INVENTORY_FEE
                                          : item.INVENTORY_FEE}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.TOTAL_FEE
                                          : item.TOTAL_FEE}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.LEGACY
                                            ? "true"
                                            : "false"
                                          : item.LEGACY
                                          ? "true"
                                          : "false"}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.ACTIVITY_TYPE
                                          : item.ACTIVITY_TYPE}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .TAMPEREVIDENTBAG_STATUS
                                          : item.TAMPEREVIDENTBAG_STATUS}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_A_TAMPEREVIDENTBAG_NUMBER
                                          : item.ADDENDUM_A_TAMPEREVIDENTBAG_NUMBER}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_A_CATEGORY_NAME
                                          : item.ADDENDUM_A_CATEGORY_NAME}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_A_ITEM_NAME
                                          : item.ADDENDUM_A_ITEM_NAME}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_A_QUANTITY
                                          : item.ADDENDUM_A_QUANTITY}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_B_TAMPEREVIDENTBAG_NUMBER
                                          : item.ADDENDUM_B_TAMPEREVIDENTBAG_NUMBER}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_B_CATEGORY_NAME
                                          : item.ADDENDUM_B_CATEGORY_NAME}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_B_ITEM_NAME
                                          : item.ADDENDUM_B_ITEM_NAME}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_B_QUANTITY
                                          : item.ADDENDUM_B_QUANTITY}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_C_STOCK_CERTIFICATES_TAMPEREVIDENTBAG_NUMBER
                                          : item.ADDENDUM_C_STOCK_CERTIFICATES_TAMPEREVIDENTBAG_NUMBER}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_C_STOCK_CERTIFICATES_QUANTITY
                                          : item.ADDENDUM_C_STOCK_CERTIFICATES_QUANTITY}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_C_STOCK_CERTIFICATE_STOCK_CERTIFICATE
                                          : item.ADDENDUM_C_STOCK_CERTIFICATE_STOCK_CERTIFICATE}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_C_OTHER_BOND_TAMPEREVIDENTBAG_NUMBER
                                          : item.ADDENDUM_C_OTHER_BOND_TAMPEREVIDENTBAG_NUMBER}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_C_OTHER_BOND_QUANTITY
                                          : item.ADDENDUM_C_OTHER_BOND_QUANTITY}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_C_OTHER_BOND_OTHER_BONDS
                                          : item.ADDENDUM_C_OTHER_BOND_OTHER_BONDS}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_C_OTHER_BOND_VALUE_OF_BOND
                                          : item.ADDENDUM_C_OTHER_BOND_VALUE_OF_BOND}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_C_OTHER_BOND_DESCRIPTION_OF_BONDS
                                          : item.ADDENDUM_C_OTHER_BOND_DESCRIPTION_OF_BONDS}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_C_CONTRA_BAND_TAMPEREVIDENTBAG_NUMBER
                                          : item.ADDENDUM_C_CONTRA_BAND_TAMPEREVIDENTBAG_NUMBER}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_C_CONTRA_BAND_QUANTITY
                                          : item.ADDENDUM_C_CONTRA_BAND_QUANTITY}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_C_CONTRA_BAND_CONTRA_BAND
                                          : item.ADDENDUM_C_CONTRA_BAND_CONTRA_BAND}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_C_MISCELLANEOUS_TAMPEREVIDENTBAG_NUMBER
                                          : item.ADDENDUM_C_MISCELLANEOUS_TAMPEREVIDENTBAG_NUMBER}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_C_MISCELLANEOUS_QUANTITY
                                          : item.ADDENDUM_C_MISCELLANEOUS_QUANTITY}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .ADDENDUM_C_MISCELLANEOUS_MISCELLANEOUS
                                          : item.ADDENDUM_C_MISCELLANEOUS_MISCELLANEOUS}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.BRANCH_ID
                                          : item.BRANCH_ID}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.UNIQUE_ID
                                          : item.UNIQUE_ID}
                                      </td>
                                      <td className="td-date">
                                        {" "}
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            type="date"
                                            id="td-date"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              // item.key=="yes"?true : false
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data
                                                .NEXT_AUDIT_DUE_DATE
                                            }
                                            // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "NEXT_AUDIT_DUE_DATE"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-date">
                                            {" "}
                                            {datafield && item.uploaded_data
                                              ? item.uploaded_data
                                                  .NEXT_AUDIT_DUE_DATE
                                              : item.NEXT_AUDIT_DUE_DATE}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data
                                          .NEXT_AUDIT_DUE_DATE === "" ||
                                          item.uploaded_data
                                            .NEXT_AUDIT_DUE_DATE ===
                                            undefined) ? (
                                          <div className="error-message-validation">
                                            NEXT_AUDIT_DUE_DATE is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-date">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-date"
                                            type="number"
                                            pattern="[0-9]"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data
                                                .DAYS_UNTIL_NEXT_AUDIT
                                            }
                                            // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "DAYS_UNTIL_NEXT_AUDIT"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-date">
                                            {" "}
                                            {item.DAYS_UNTIL_NEXT_AUDIT}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data
                                          .DAYS_UNTIL_NEXT_AUDIT === "" ||
                                          item.uploaded_data
                                            .DAYS_UNTIL_NEXT_AUDIT ===
                                            undefined) ? (
                                          <div className="error-message-validation">
                                            DAYS_UNTIL_NEXT_AUDIT is required
                                            and it must be number
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data
                                                .AUDIT_DUE_STATUS
                                            }
                                            // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "AUDIT_DUE_STATUS"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item.AUDIT_DUE_STATUS}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data.AUDIT_DUE_STATUS ===
                                          "" ||
                                          item.uploaded_data
                                            .AUDIT_DUE_STATUS === undefined) ? (
                                          <div className="error-message-validation">
                                            AUDIT_DUE_STATUS is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data[
                                                "CONTENT_RELEASE_STATUS"
                                              ]
                                            }
                                            // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "CONTENT_RELEASE_STATUS"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item["CONTENT_RELEASE_STATUS"]}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data[
                                          "CONTENT_RELEASE_STATUS"
                                        ] === "" ||
                                          item.uploaded_data[
                                            "CONTENT_RELEASE_STATUS"
                                          ] === undefined) ? (
                                          <div className="error-message-validation">
                                            CONTENT_RELEASE_STATUS is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data[
                                                "BRANCH_SDB_AUDIT"
                                              ]
                                            }
                                            // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "BRANCH_SDB_AUDIT"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item["BRANCH_SDB_AUDIT"]}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data[
                                          "BRANCH_SDB_AUDIT"
                                        ] === "" ||
                                          item.uploaded_data[
                                            "BRANCH_SDB_AUDIT"
                                          ] === undefined) ? (
                                          <div className="error-message-validation">
                                            BRANCH_SDB_AUDIT is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.STATUS
                                          : item.STATUS}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data[
                                                "AUDIT_BAG_STATUS"
                                              ]
                                            }
                                            // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "AUDIT_BAG_STATUS"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item["AUDIT_BAG_STATUS"]}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data[
                                          "AUDIT_BAG_STATUS"
                                        ] === "" ||
                                          item.uploaded_data[
                                            "AUDIT_BAG_STATUS"
                                          ] === undefined) ? (
                                          <div className="error-message-validation">
                                            AUDIT_BAG_STATUS is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data["AUDITOR_NAME"]
                                            }
                                            // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "AUDITOR_NAME"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item["AUDITOR_NAME"]}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data["AUDITOR_NAME"] ===
                                          "" ||
                                          item.uploaded_data["AUDITOR_NAME"] ===
                                            undefined) ? (
                                          <div className="error-message-validation">
                                            AUDITOR_NAME is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-date">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-date"
                                            type="date"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data[
                                                "ACTUAL_AUDIT_DATE"
                                              ]
                                            }
                                            // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "ACTUAL_AUDIT_DATE"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-date">
                                            {" "}
                                            {datafield && item.uploaded_data
                                              ? item.uploaded_data
                                                  .ACTUAL_AUDIT_DATE
                                              : item.ACTUAL_AUDIT_DATE}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data[
                                          "ACTUAL_AUDIT_DATE"
                                        ] === "" ||
                                          item.uploaded_data[
                                            "ACTUAL_AUDIT_DATE"
                                          ] === undefined) ? (
                                          <div className="error-message-validation">
                                            ACTUAL_AUDIT_DATE is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-date">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-date"
                                            type="date"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data[
                                                "LAST_AUDIT_DATE"
                                              ]
                                            }
                                            // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "LAST_AUDIT_DATE"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-date">
                                            {" "}
                                            {datafield && item.uploaded_data
                                              ? item.uploaded_data
                                                  .LAST_AUDIT_DATE
                                              : item.LAST_AUDIT_DATE}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data[
                                          "LAST_AUDIT_DATE"
                                        ] === "" ||
                                          item.uploaded_data[
                                            "LAST_AUDIT_DATE"
                                          ] === undefined) ? (
                                          <div className="error-message-validation">
                                            LAST_AUDIT_DATE is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data[
                                                "PREVIOUS_AUDIT_BAG_STATUS"
                                              ]
                                            }
                                            // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "PREVIOUS_AUDIT_BAG_STATUS"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item["PREVIOUS_AUDIT_BAG_STATUS"]}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data[
                                          "PREVIOUS_AUDIT_BAG_STATUS"
                                        ] === "" ||
                                          item.uploaded_data[
                                            "PREVIOUS_AUDIT_BAG_STATUS"
                                          ] === undefined) ? (
                                          <div className="error-message-validation">
                                            PREVIOUS_AUDIT_BAG_STATUS is
                                            required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data
                                              .CURRENT_BRANCH_DID
                                          : item.CURRENT_BRANCH_DID}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data.REGION
                                            }
                                            // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "REGION"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item.REGION}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data["REGION"] === "" ||
                                          item.uploaded_data["REGION"] ===
                                            undefined) ? (
                                          <div className="error-message-validation">
                                            REGION is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data.MARKET
                                            }
                                            // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "MARKET"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item.MARKET}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data["MARKET"] === "" ||
                                          item.uploaded_data["MARKET"] ===
                                            undefined) ? (
                                          <div className="error-message-validation">
                                            MARKET is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data.STATE
                                            }
                                            // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "STATE"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item.STATE}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data["STATE"] === "" ||
                                          item.uploaded_data["STATE"] ===
                                            undefined) ? (
                                          <div className="error-message-validation">
                                            STATE is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data.BRANCH
                                            }
                                            // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "BRANCH"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item.BRANCH}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data["BRANCH"] === "" ||
                                          item.uploaded_data["BRANCH"] ===
                                            undefined) ? (
                                          <div className="error-message-validation">
                                            BRANCH is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data[
                                                "CUSTOMER_NAME"
                                              ]
                                            }
                                            // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "CUSTOMER_NAME"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item["CUSTOMER_NAME"]}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data["CUSTOMER_NAME"] ===
                                          "" ||
                                          item.uploaded_data[
                                            "CUSTOMER_NAME"
                                          ] === undefined) ? (
                                          <div className="error-message-validation">
                                            CUSTOMER_NAME is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data["SDB#_DRILLED"]
                                          : item["SDB#_DRILLED"]}
                                      </td>
                                      <td className="td-date">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data.BOX_DRILL_DATE
                                          : item.BOX_DRILL_DATE}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data["BAG #"]
                                          : item["BAG #"]}{" "}
                                      </td>
                                      <td className="td-date">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-date"
                                            type="number"
                                            pattern="[0-9]"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data["BAG_COUNT"]
                                            } // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "BAG_COUNT"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-date">
                                            {" "}
                                            {item["BAG_COUNT"]}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data["BAG_COUNT"] ===
                                          "" ||
                                          item.uploaded_data["BAG_COUNT"] ===
                                            undefined) ? (
                                          <div className="error-message-validation">
                                            BAG_COUNT is required and it must be
                                            number
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data[
                                                "Branch where the box was physically located when drilled (This data in this column should never change)"
                                              ]
                                            }
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "Branch where the box was physically located when drilled (This data in this column should never change)"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {
                                              item[
                                                "Branch where the box was physically located when drilled (This data in this column should never change)"
                                              ]
                                            }
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data[
                                          "Branch where the box was physically located when drilled (This data in this column should never change)"
                                        ] === "" ||
                                          item.uploaded_data[
                                            "Branch where the box was physically located when drilled (This data in this column should never change)"
                                          ] === undefined) ? (
                                          <div className="error-message-validation">
                                            This field is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data
                                          ? item.uploaded_data[
                                              "ORIGINAL_BRANCH_DID"
                                            ]
                                          : item["ORIGINAL_BRANCH_DID"]}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data[
                                                "ORIGINAL_BRANCH_NAME"
                                              ]
                                            } // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "ORIGINAL_BRANCH_NAME"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item["ORIGINAL_BRANCH_NAME"]}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data[
                                          "ORIGINAL_BRANCH_NAME"
                                        ] === "" ||
                                          item.uploaded_data[
                                            "ORIGINAL_BRANCH_NAME"
                                          ] === undefined) ? (
                                          <div className="error-message-validation">
                                            ORIGINAL_BRANCH_NAME is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data[
                                                "PROHIBITED_ITEM"
                                              ]
                                            } // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "PROHIBITED_ITEM"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item["PROHIBITED_ITEM"]}
                                          </td>
                                        )}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data.ROM
                                            } // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(e, item, "ROM")
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item.ROM}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data["ROM"] === "" ||
                                          item.uploaded_data["ROM"] ===
                                            undefined) ? (
                                          <div className="error-message-validation">
                                            ROM is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data[
                                                "OPS_SPECIALIST"
                                              ]
                                            } // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "OPS_SPECIALIST"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item["OPS_SPECIALIST"]}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data[
                                          "OPS_SPECIALIST"
                                        ] === "" ||
                                          item.uploaded_data[
                                            "OPS_SPECIALIST"
                                          ] === undefined) ? (
                                          <div className="error-message-validation">
                                            OPS _SPECIALIST is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data[
                                                "MARKET_MANAGER"
                                              ]
                                            } // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "MARKET_MANAGER"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item["MARKET_MANAGER"]}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data[
                                          "MARKET_MANAGER"
                                        ] === "" ||
                                          item.uploaded_data[
                                            "MARKET_MANAGER"
                                          ] === undefined) ? (
                                          <div className="error-message-validation">
                                            MARKET_ MANAGER is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data["FORM_LINK"]
                                            } // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "FORM_LINK"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item["FORM_LINK"]}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data["FORM_LINK"] ===
                                          "" ||
                                          item.uploaded_data["FORM_LINK"] ===
                                            undefined) ? (
                                          <div className="error-message-validation">
                                            FORM_LINK is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data.RECORD_ID
                                            } // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "RECORD_ID"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item.RECORD_ID}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data["RECORD_ID"] ===
                                          "" ||
                                          item.uploaded_data["RECORD_ID"] ===
                                            undefined) ? (
                                          <div className="error-message-validation">
                                            RECORD_ID is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data.BAG_DATA
                                            } // onBlur={handleGSInputChange}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "BAG_DATA"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item.BAG_DATA}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data["BAG_DATA"] ===
                                          "" ||
                                          item.uploaded_data["BAG_DATA"] ===
                                            undefined) ? (
                                          <div className="error-message-validation">
                                            BAG_DATA is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data[
                                                "DUPLICATE_RECORD"
                                              ]
                                            }
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "DUPLICATE_RECORD"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {item["DUPLICATE_RECORD"]}
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data[
                                          "DUPLICATE_RECORD"
                                        ] === "" ||
                                          item.uploaded_data[
                                            "DUPLICATE_RECORD"
                                          ] === undefined) ? (
                                          <div className="error-message-validation">
                                            DUPLICATE_RECORD is required
                                          </div>
                                        ) : null}
                                      </td>
                                      <td className="td-text">
                                        {datafield && item.uploaded_data ? (
                                          <input
                                            style={{
                                              fontSize: "14px !important",
                                            }}
                                            id="td-text"
                                            type="text"
                                            className="form-control-plaintext cdb-input"
                                            readOnly={
                                              item.key === "yes" || readOnly
                                                ? true
                                                : false
                                            }
                                            defaultValue={
                                              item.uploaded_data[
                                                "Branch Use SDB # (or other specific storage location of drilled content)"
                                              ]
                                            }
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                item,
                                                "Branch Use SDB # (or other specific storage location of drilled content)"
                                              )
                                            }
                                          />
                                        ) : (
                                          <td className="td-text">
                                            {" "}
                                            {
                                              item[
                                                "Branch Use SDB # (or other specific storage location of drilled content)"
                                              ]
                                            }
                                          </td>
                                        )}
                                        {item.uploaded_data &&
                                        (item.uploaded_data[
                                          "Branch Use SDB # (or other specific storage location of drilled content)"
                                        ] === "" ||
                                          item.uploaded_data[
                                            "Branch Use SDB # (or other specific storage location of drilled content)"
                                          ] === undefined) ? (
                                          <div className="error-message-validation">
                                            This field is required
                                          </div>
                                        ) : null}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </>
                            {/* )} */}
                          </table>
                          {!datafield ? (
                            <Pager
                              totalPages={
                                showData.length
                                  ? Math.ceil(showData.length / 10)
                                  : 0
                              }
                              currentPage={pageState.pageNum}
                              onPageChange={(newPage) =>
                                setPageState({
                                  pageNum: newPage,
                                  rowsPerPage: 10,
                                })
                              }
                              rowsPerPage={10}
                              setRowsPerPage={(num) =>
                                setPageState({ pageNum: 1, rowsPerPage: num })
                              }
                              dataCount={showData.length}
                              dataLength={rows.length}
                            />
                          ) : null}
                        </>
                      );
                    } else if (activityType === "fsi") {
                      return (
                        <>
                          <table
                            className="table_wrapper"
                            id={!datafield ? "cbc-scroll" : "cbc-scroll1"}
                          >
                            <tr
                              key={"header"}
                              id="cbc-table-heading"
                              style={{
                                position: "sticky",
                                top: 0,
                                background: "#6F7D95",
                                zIndex: 1,
                              }}
                            >
                              {datafield ? (
                                <th className="headcol1">SELECTED</th>
                              ) : null}
                              {requriedData.map((key, idx) => (
                                <th key={idx}>{key}</th>
                              ))}
                              {datafield ? (
                                <th className="headcol2">
                                  ACTIONS &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {/* <button style={{color:"white"}}>UPDATE ALL</button> */}
                                  <button
                                    id="update-all-btn"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      handleOnChange();
                                    }}
                                    disabled={
                                      error && !isChecked ? true : false
                                    }
                                  >
                                    {!isChecked ? "UPDATE ALL" : "CANCEL ALL"}
                                  </button>
                                </th>
                              ) : null}
                            </tr>
                            <>
                              {rows &&
                                rows.map((item, index) => {
                                  if (item["key"] === "yes") {
                                    item["key"] = "yes";
                                  } else if (item["key"] === "no") {
                                    item["key"] = "no";
                                  } else {
                                    item["key"] = "no";
                                  }
                                  if (item["errorIndex"] === "yes") {
                                    item["errorIndex"] = "yes";
                                  } else if (item["errorIndex"] === "no") {
                                    item["errorIndex"] = "no";
                                  } else {
                                    item["errorIndex"] = "no";
                                  }

                                  return (
                                    <>
                                      {datafield ? (
                                        <tr
                                          style={{
                                            background:
                                              index % 2 === 0
                                                ? "#FFFFFF"
                                                : "#e3e3e3",
                                          }}
                                        >
                                          <th
                                            className="headcol-text"
                                            style={{
                                              background:
                                                index % 2 === 0
                                                  ? "#FFFFFF"
                                                  : "#e3e3e3",
                                            }}
                                          ></th>
                                          <td className="first-column">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .CONTRACT_ON_FILE
                                                ? "true"
                                                : "false"
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.ACCESS_LOG
                                                ? "true"
                                                : "false"
                                              : null}
                                          </td>
                                          <td className="td-date">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .FORCE_OPEN_DATE
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .SAFEDEPOSITBOX_NUMBER
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.OWNER_NAME
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.OWNER_SSN
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .OWNER_STREET_1
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.OWNER_CITY
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.OWNER_STATE
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.OWNER_ZIPCODE
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.CO_OWNER_NAME
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.CO_OWNER_SSN
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .CO_OWNER_STREET_1
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.CO_OWNER_CITY
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .CO_OWNER_STATE
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .CO_OWNER_ZIPCODE
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .LOCKSMITH_NAME
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.NOTARY_NAME
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .BOX_DRILLED_STATUS
                                                ? "true"
                                                : "false"
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .CONTENTS_FOUND
                                                ? "true"
                                                : "false"
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.RENTAL_DUE
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.OTHER_DUE
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.TOTAL_DUE
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .PAST_DUE_RENTAL_FEE
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .FORCE_OPEN_COST
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.INVENTORY_FEE
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.TOTAL_FEE
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.LEGACY
                                                ? "true"
                                                : "false"
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.ACTIVITY_TYPE
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .TAMPEREVIDENTBAG_STATUS
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_A_TAMPEREVIDENTBAG_NUMBER
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_A_CATEGORY_NAME
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_A_ITEM_NAME
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_A_QUANTITY
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_B_TAMPEREVIDENTBAG_NUMBER
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_B_CATEGORY_NAME
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_B_ITEM_NAME
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_B_QUANTITY
                                              : ""}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_C_STOCK_CERTIFICATES_TAMPEREVIDENTBAG_NUMBER
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_C_STOCK_CERTIFICATES_QUANTITY
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_C_STOCK_CERTIFICATE_STOCK_CERTIFICATE
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_C_OTHER_BOND_TAMPEREVIDENTBAG_NUMBER
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_C_OTHER_BOND_QUANTITY
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_C_OTHER_BOND_OTHER_BONDS
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_C_OTHER_BOND_VALUE_OF_BOND
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_C_OTHER_BOND_DESCRIPTION_OF_BONDS
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_C_CONTRA_BAND_TAMPEREVIDENTBAG_NUMBER
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_C_CONTRA_BAND_QUANTITY
                                              : null}
                                          </td>
                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_C_CONTRA_BAND_CONTRA_BAND
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_C_MISCELLANEOUS_TAMPEREVIDENTBAG_NUMBER
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_C_MISCELLANEOUS_QUANTITY
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ADDENDUM_C_MISCELLANEOUS_MISCELLANEOUS
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .CURRENT_BRANCH_DID
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.BRANCH
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.STATUS
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .CONTROL_NUMBER
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .CUSTOMER_FIRST_NAME
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .CUSTOMER_LAST_NAME
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data[
                                                  "TAMPER_EVIDENT_BAG#"
                                                ]
                                              : null}
                                          </td>

                                          <td className="td-date">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .STARTING_TRANSACTION_DATE
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .SDB_LOCATION_OF_CONTENTS
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data.SDB_TOTE_ID
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ACCOUNT_NUMBER
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data
                                                  .ORIGINAL_BRANCH_DID
                                              : null}
                                          </td>

                                          <td className="td-text">
                                            {item.existing_data
                                              ? item.existing_data[
                                                  "Branch where the box was physically located when drilled (This data in this column should never change)"
                                                ]
                                              : null}
                                          </td>

                                          <th
                                            className="headcol-text1 pr-5"
                                            rowSpan={2}
                                          >
                                            {/* {clickIndex.includes(index) ? ( */}
                                            {item.key === "yes" || isChecked ? (
                                              <>
                                                <h5 className="input-label verifiedText">
                                                  Verified{" "}
                                                  <img
                                                    className="greenTick"
                                                    src={GreenTick}
                                                    alt="Edit"
                                                    onClick={() => {
                                                      onEditInput(item, index);
                                                    }}
                                                  />
                                                </h5>

                                                <img
                                                  src={EditStorage}
                                                  alt="Edit"
                                                  className="pencil-icon updateButton"
                                                  onClick={() => {
                                                    onEditInput(item, index);
                                                  }}
                                                />
                                              </>
                                            ) : (
                                              <button
                                                id="previous-btn2"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                  onUpdateClick(item, index);
                                                }}
                                                disabled={
                                                  item.errorIndex === "yes"
                                                    ? true
                                                    : false
                                                }
                                              >
                                                UPDATE{" "}
                                              </button>
                                            )}
                                            <div
                                              className="fa fa-info-circle"
                                              id={
                                                clickIndex.includes(index)
                                                  ? "hoverIcon"
                                                  : "hoverIcon2"
                                              }
                                              data-placement="left"
                                              data-html="true"
                                              style={{ cursor: "pointer" }}
                                              title="If you are not intrested to merge , Please go back to file make changes the try to re-upload"
                                            ></div>
                                          </th>
                                        </tr>
                                      ) : null}

                                      <tr
                                        style={{
                                          background:
                                            datafield && index % 2 === 0
                                              ? "#FFFFFF"
                                              : "#e3e3e3",
                                        }}
                                      >
                                        {" "}
                                        {datafield ? (
                                          <th
                                            className="headcol-text"
                                            style={{
                                              background:
                                                index % 2 === 0
                                                  ? "#FFFFFF"
                                                  : "#e3e3e3",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              name={index}
                                              value={conflictRecords}
                                              defaultChecked
                                              id="selected-radio"
                                              style={{
                                                height: "15px",
                                                width: "15px",
                                                textAlign: "center",
                                              }}
                                            />
                                          </th>
                                        ) : null}
                                        <td className="first-column">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .CONTRACT_ON_FILE
                                              ? "true"
                                              : "false"
                                            : item["CONTRACT_ON_FILE"]
                                            ? "true"
                                            : "false"}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.ACCESS_LOG
                                              ? "true"
                                              : "false"
                                            : item.ACCESS_LOG
                                            ? "true"
                                            : "false"}
                                        </td>
                                        <td className="td-date">
                                          {datafield && item.uploaded_data
                                            ? item?.uploaded_data
                                                .FORCE_OPEN_DATE
                                            : item?.FORCE_OPEN_DATE}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item?.uploaded_data
                                                .SAFEDEPOSITBOX_NUMBER
                                            : item?.SAFEDEPOSITBOX_NUMBER}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.OWNER_NAME
                                            : item.OWNER_NAME}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.OWNER_SSN
                                            : item.OWNER_SSN}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.OWNER_STREET_1
                                            : item.OWNER_STREET_1}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.OWNER_CITY
                                            : item.OWNER_CITY}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.OWNER_STATE
                                            : item.OWNER_STATE}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.OWNER_ZIPCODE
                                            : item.OWNER_ZIPCODE}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.CO_OWNER_NAME
                                            : item.CO_OWNER_NAME}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.CO_OWNER_SSN
                                            : item.CO_OWNER_SSN}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .CO_OWNER_STREET_1
                                            : item.CO_OWNER_STREET_1}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.CO_OWNER_CITY
                                            : item.CO_OWNER_CITY}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.CO_OWNER_STATE
                                            : item.CO_OWNER_STATE}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .CO_OWNER_ZIPCODE
                                            : item.CO_OWNER_ZIPCODE}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.LOCKSMITH_NAME
                                            : item.LOCKSMITH_NAME}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.NOTARY_NAME
                                            : item.NOTARY_NAME}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .BOX_DRILLED_STATUS
                                              ? "true"
                                              : "false"
                                            : item.BOX_DRILLED_STATUS
                                            ? "true"
                                            : "false"}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.CONTENTS_FOUND
                                              ? "true"
                                              : "false"
                                            : item.CONTENTS_FOUND
                                            ? "true"
                                            : "false"}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.RENTAL_DUE
                                            : item.RENTAL_DUE}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.OTHER_DUE
                                            : item.OTHER_DUE}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.TOTAL_DUE
                                            : item.TOTAL_DUE}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .PAST_DUE_RENTAL_FEE
                                            : item.PAST_DUE_RENTAL_FEE}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.FORCE_OPEN_COST
                                            : item.FORCE_OPEN_COST}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.INVENTORY_FEE
                                            : item.INVENTORY_FEE}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.TOTAL_FEE
                                            : item.TOTAL_FEE}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.LEGACY
                                              ? "true"
                                              : "false"
                                            : item.LEGACY
                                            ? "true"
                                            : "false"}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.ACTIVITY_TYPE
                                            : item.ACTIVITY_TYPE}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .TAMPEREVIDENTBAG_STATUS
                                            : item.TAMPEREVIDENTBAG_STATUS}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_A_TAMPEREVIDENTBAG_NUMBER
                                            : item.ADDENDUM_A_TAMPEREVIDENTBAG_NUMBER}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_A_CATEGORY_NAME
                                            : item.ADDENDUM_A_CATEGORY_NAME}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_A_ITEM_NAME
                                            : item.ADDENDUM_A_ITEM_NAME}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_A_QUANTITY
                                            : item.ADDENDUM_A_QUANTITY}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_B_TAMPEREVIDENTBAG_NUMBER
                                            : item.ADDENDUM_B_TAMPEREVIDENTBAG_NUMBER}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_B_CATEGORY_NAME
                                            : item.ADDENDUM_B_CATEGORY_NAME}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_B_ITEM_NAME
                                            : item.ADDENDUM_B_ITEM_NAME}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_B_QUANTITY
                                            : item.ADDENDUM_B_QUANTITY}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_C_STOCK_CERTIFICATES_TAMPEREVIDENTBAG_NUMBER
                                            : item.ADDENDUM_C_STOCK_CERTIFICATES_TAMPEREVIDENTBAG_NUMBER}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_C_STOCK_CERTIFICATES_QUANTITY
                                            : item.ADDENDUM_C_STOCK_CERTIFICATES_QUANTITY}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_C_STOCK_CERTIFICATE_STOCK_CERTIFICATE
                                            : item.ADDENDUM_C_STOCK_CERTIFICATE_STOCK_CERTIFICATE}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_C_OTHER_BOND_TAMPEREVIDENTBAG_NUMBER
                                            : item.ADDENDUM_C_OTHER_BOND_TAMPEREVIDENTBAG_NUMBER}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_C_OTHER_BOND_QUANTITY
                                            : item.ADDENDUM_C_OTHER_BOND_QUANTITY}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_C_OTHER_BOND_OTHER_BONDS
                                            : item.ADDENDUM_C_OTHER_BOND_OTHER_BONDS}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_C_OTHER_BOND_VALUE_OF_BOND
                                            : item.ADDENDUM_C_OTHER_BOND_VALUE_OF_BOND}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_C_OTHER_BOND_DESCRIPTION_OF_BONDS
                                            : item.ADDENDUM_C_OTHER_BOND_DESCRIPTION_OF_BONDS}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_C_CONTRA_BAND_TAMPEREVIDENTBAG_NUMBER
                                            : item.ADDENDUM_C_OTHER_BOND_TAMPEREVIDENTBAG_NUMBER}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_C_CONTRA_BAND_QUANTITY
                                            : item.ADDENDUM_C_CONTRA_BAND_QUANTITY}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_C_CONTRA_BAND_CONTRA_BAND
                                            : item.ADDENDUM_C_CONTRA_BAND_CONTRA_BAND}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_C_MISCELLANEOUS_TAMPEREVIDENTBAG_NUMBER
                                            : item.ADDENDUM_C_MISCELLANEOUS_TAMPEREVIDENTBAG_NUMBER}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_C_MISCELLANEOUS_QUANTITY
                                            : item.ADDENDUM_C_MISCELLANEOUS_QUANTITY}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ADDENDUM_C_MISCELLANEOUS_MISCELLANEOUS
                                            : item.ADDENDUM_C_MISCELLANEOUS_MISCELLANEOUS}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .CURRENT_BRANCH_DID
                                            : item.CURRENT_BRANCH_DID}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.BRANCH
                                            : item.BRANCH}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.STATUS
                                            : item.STATUS}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.CONTROL_NUMBER
                                            : item.CONTROL_NUMBER}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data ? (
                                            <input
                                              id="td-text"
                                              style={{
                                                fontSize: "14px !important",
                                              }}
                                              type="text"
                                              className="form-control-plaintext cdb-input"
                                              readOnly={
                                                item.key === "yes" || readOnly
                                                  ? true
                                                  : false
                                              }
                                              defaultValue={
                                                item.uploaded_data
                                                  .CUSTOMER_FIRST_NAME
                                              }
                                              // onBlur={handleGSInputChange}
                                              onChange={(e) =>
                                                handleInputChange(
                                                  e,
                                                  item,
                                                  "CUSTOMER_FIRST_NAME",
                                                  index
                                                )
                                              }
                                            />
                                          ) : (
                                            <td className="td-text">
                                              {" "}
                                              {item.CUSTOMER_FIRST_NAME}
                                            </td>
                                          )}
                                          {item.uploaded_data &&
                                          (item.uploaded_data[
                                            "CUSTOMER_FIRST_NAME"
                                          ] === "" ||
                                            item.uploaded_data[
                                              "CUSTOMER_FIRST_NAME"
                                            ] === undefined) ? (
                                            // setError(true),

                                            <div className="error-message-validation">
                                              CUSTOMER_FIRST_NAME is required
                                            </div>
                                          ) : null}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data ? (
                                            <input
                                              id="td-text"
                                              style={{
                                                fontSize: "14px !important",
                                              }}
                                              type="text"
                                              className="form-control-plaintext cdb-input"
                                              readOnly={
                                                item.key === "yes" || readOnly
                                                  ? true
                                                  : false
                                              }
                                              defaultValue={
                                                item.uploaded_data
                                                  .CUSTOMER_LAST_NAME
                                              }
                                              // onBlur={handleGSInputChange}
                                              onChange={(e) =>
                                                handleInputChange(
                                                  e,
                                                  item,
                                                  "CUSTOMER_LAST_NAME",
                                                  index
                                                )
                                              }
                                            />
                                          ) : (
                                            <td className="td-text">
                                              {" "}
                                              {item.CUSTOMER_LAST_NAME}
                                            </td>
                                          )}
                                          {item.uploaded_data &&
                                          (item.uploaded_data[
                                            "CUSTOMER_LAST_NAME"
                                          ] === "" ||
                                            item.uploaded_data[
                                              "CUSTOMER_LAST_NAME"
                                            ] === undefined) ? (
                                            <div className="error-message-validation">
                                              CUSTOMER_LAST_NAME is required
                                            </div>
                                          ) : null}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data[
                                                "TAMPER_EVIDENT_BAG#"
                                              ]
                                            : item["TAMPER_EVIDENT_BAG#"]}
                                        </td>
                                        <td className="td-date">
                                          {" "}
                                          {datafield && item.uploaded_data ? (
                                            <input
                                              id="td-date"
                                              style={{
                                                fontSize: "14px !important",
                                              }}
                                              type="date"
                                              className="form-control-plaintext cdb-input"
                                              readOnly={
                                                item.key === "yes" || readOnly
                                                  ? true
                                                  : false
                                              }
                                              defaultValue={
                                                item.uploaded_data
                                                  .STARTING_TRANSACTION_DATE
                                              }
                                              // onBlur={handleGSInputChange}
                                              onChange={(e) =>
                                                handleInputChange(
                                                  e,
                                                  item,
                                                  "STARTING_TRANSACTION_DATE",
                                                  index
                                                )
                                              }
                                            />
                                          ) : (
                                            <td className="td-date">
                                              {datafield && item.uploaded_data
                                                ? item.uploaded_data
                                                    .STARTING_TRANSACTION_DATE
                                                : item.STARTING_TRANSACTION_DATE}
                                            </td>
                                          )}
                                          {item.uploaded_data &&
                                          (item.uploaded_data[
                                            "STARTING_TRANSACTION_DATE"
                                          ] === "" ||
                                            item.uploaded_data[
                                              "STARTING_TRANSACTION_DATE"
                                            ] === undefined) ? (
                                            <div className="error-message-validation">
                                              STARTING_TRANSACTION_DATE is
                                              required
                                            </div>
                                          ) : null}
                                        </td>
                                        <td className="td-date">
                                          {datafield && item.uploaded_data ? (
                                            <input
                                              id="td-date"
                                              style={{
                                                fontSize: "14px !important",
                                              }}
                                              type="text"
                                              className="form-control-plaintext cdb-input"
                                              readOnly={
                                                item.key === "yes" || readOnly
                                                  ? true
                                                  : false
                                              }
                                              defaultValue={
                                                item.uploaded_data
                                                  .SDB_LOCATION_OF_CONTENTS
                                              }
                                              // onBlur={handleGSInputChange}
                                              onChange={(e) =>
                                                handleInputChange(
                                                  e,
                                                  item,
                                                  "SDB_LOCATION_OF_CONTENTS",
                                                  index
                                                )
                                              }
                                            />
                                          ) : (
                                            <td className="td-date">
                                              {" "}
                                              {item.SDB_LOCATION_OF_CONTENTS}
                                            </td>
                                          )}
                                          {item.uploaded_data &&
                                          (item.uploaded_data[
                                            "SDB_LOCATION_OF_CONTENTS"
                                          ] === "" ||
                                            item.uploaded_data
                                              .SDB_LOCATION_OF_CONTENTS ===
                                              undefined) ? (
                                            <div className="error-message-validation">
                                              SDB_LOCATION_OF_CONTENTS is
                                              required
                                            </div>
                                          ) : null}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data ? (
                                            <input
                                              id="td-text"
                                              style={{
                                                fontSize: "14px !important",
                                              }}
                                              type="text"
                                              className="form-control-plaintext cdb-input"
                                              readOnly={
                                                item.key === "yes" || readOnly
                                                  ? true
                                                  : false
                                              }
                                              defaultValue={
                                                item.uploaded_data[
                                                  "SDB_TOTE_ID"
                                                ]
                                              }
                                              // onBlur={handleGSInputChange}
                                              onChange={(e) =>
                                                handleInputChange(
                                                  e,
                                                  item,
                                                  "SDB_TOTE_ID",
                                                  index
                                                )
                                              }
                                            />
                                          ) : (
                                            <td className="td-text">
                                              {" "}
                                              {item.SDB_TOTE_ID}
                                            </td>
                                          )}
                                          {item.uploaded_data &&
                                          (item.uploaded_data.SDB_TOTE_ID ===
                                            "" ||
                                            item.uploaded_data.SDB_TOTE_ID ===
                                              undefined) ? (
                                            <div className="error-message-validation">
                                              SDB Tote ID is required
                                            </div>
                                          ) : null}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data.ACCOUNT_NUMBER
                                            : item.ACCOUNT_NUMBER}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data
                                                .ORIGINAL_BRANCH_DID
                                            : item.ORIGINAL_BRANCH_DID}
                                        </td>
                                        <td className="td-text">
                                          {datafield && item.uploaded_data
                                            ? item.uploaded_data[
                                                "Branch where the box was physically located when drilled (This data in this column should never change)"
                                              ]
                                            : item[
                                                "Branch where the box was physically located when drilled (This data in this column should never change)"
                                              ]}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </>
                            {/* )} */}
                          </table>
                          {!datafield ? (
                            <Pager
                              totalPages={
                                showData.length
                                  ? Math.ceil(showData.length / 10)
                                  : 0
                              }
                              currentPage={pageState.pageNum}
                              onPageChange={(newPage) =>
                                setPageState({
                                  pageNum: newPage,
                                  rowsPerPage: 10,
                                })
                              }
                              rowsPerPage={10}
                              setRowsPerPage={(num) =>
                                setPageState({ pageNum: 1, rowsPerPage: num })
                              }
                              dataCount={showData.length}
                              dataLength={rows.length}
                            />
                          ) : null}
                        </>
                      );
                    }
                  })()}
                </div>
              ) : (
                <div className="d-flex row align-items-center justify-content-center vertical-middle text-center divTableMain">
                  <Inputupload onChange={fileUpload} label="Upload Document" />
                </div>
              )}

              <div className="fieldArea7 pt-2 pb-2 justify-content-center">
                <button
                  className="btn  btn-sm btn-primary"
                  variant="contained"
                  onClick={() => {
                    navigate("/viewAllCheckIn");
                  }}
                >
                  CANCEL
                </button>
                <button
                  className="btn  btn-sm btn-primary"
                  variant="contained"
                  onClick={() => {
                    (() => {
                      if (!datafield && !dataMerge) {
                        onSubmit();
                      } else if (!datafield && dataMerge) {
                        onFinalSubmit();
                      } else {
                        onDataMerge();
                      }
                    })();
                  }}
                  disabled={!datafield ? fileuploaded : verified}
                >
                  {!review ? "Submit" : "Review & Submit"}
                </button>
              </div>
            </MDBCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomBulkCheckin;
