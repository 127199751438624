export const CHECKIN_HISTORY_LIMIT = 20;
export const CHECKIN_TEB_LIMIT = 20;
export const SAVE_CHECKIN_PAYLOAD = {
  safe_deposit_box_id: "",
  storage_type: "",
  storage_location_id: "",
  notes: "",
  pictures: [],
  documents: [],
  performed_by_id: "",
  temp_tote: "",
  tote_id: "",
  temp_location: "",
  activity_type: "",
  tamper_evident_bags: [],
  authorizer_id: "",
  tote_details:[]
};
