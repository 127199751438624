import dayjs from "dayjs";
import React from "react";
import Spinner from "react-bootstrap/Spinner";
import { DISPLAY_DATE_FORMAT } from "../constants/Constants";
import audit1 from "../assets/audit1.png";
import enquiries from "../assets/enquiries.png";
import shipment from "../assets/shipment.png";
import checkin from "../assets/checkin_timeline.png";
import checkout from "../assets/checkout-black.png";
import forceopen from "../assets/forceopen-black.png";
import bagupload from "../assets/bagupload.svg";
import { PrintDisable } from "../utils/PrintDisable";
import { useSelector } from "react-redux";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import "../style/newtimeline.css";
const NewTimeline = (props) => {
  dayjs.extend(utc);
  dayjs.extend(tz);
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const localTimezone = dayjs.tz.guess();
  PrintDisable(currentUser?.print_access_flag);

  // Reference to the timeline container
  const timelineRef = React.useRef(null);

  // Scroll the timeline left
  const scrollLeft = () => {
    if (timelineRef.current) {
      timelineRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  // Scroll the timeline right
  const scrollRight = () => {
    if (timelineRef.current) {
      timelineRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  return (
    <>
      {props.timeLineLoader ? (
        <div className="text-center alignself-center" id="timeline-loader">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {props?.eventData?.length === 0 ? (
            <div
              className="col-ld-12 col-md-12 text-center pr-5 mt-5 mb-5"
              id="right-slide"
            >
              {"No timeline available for selected record"}
            </div>
          ) : (
            <>
              <div
                className="col-lg-12  col-md-12 col-sm-12 d-flex container "
                id="time-line-home"
              >
                <div
                  className="icon-circle d-flex justify-content-center align-items-center mt-5 mr-2 "
                  id="left-slide"
                  onClick={scrollLeft}
                >
                  <i
                    className="fa fa-angle-left fa-lg fa-fw left-icon "
                    aria-hidden="true"
                  ></i>
                </div>
                <div
                  className="d-flex mx-0 mr-0 timeline"
                  id="timeline"
                  ref={timelineRef}
                >
                  {props?.eventData?.activity_list?.length > 0 &&
                    props?.eventData?.activity_list.map((item, index) => {
                      return (
                        <div className="swiper-slide ">
                          <div className="timestamp">
                            <span className="date">
                              <div
                                key={index}
                                className={
                                  "image-circle d-flex justify-content-center align-items-center"
                                }
                                onClick={() =>
                                  props.onClickEachTimeline(item, index)
                                }
                                style={{
                                  border:
                                    props.clickid === index
                                      ? "3px solid #15A4FA"
                                      : "",
                                }}
                              >
                                <img
                                  className="img-responsive "
                                  src={
                                    item.event === "shipment"
                                      ? shipment
                                      : item.event === "enquiry"
                                      ? enquiries
                                      : item.event === "audit"
                                      ? audit1
                                      : item.event === "check_in"
                                      ? checkin
                                      : item.event === "force_open"
                                      ? forceopen
                                      : item.event === "check_out"
                                      ? checkout
                                      : item.event === "bag_upload"
                                      ? bagupload
                                      : null
                                  }
                                  alt="logo"
                                  width="20px"
                                  height="20px"
                                ></img>
                              </div>
                            </span>
                          </div>
                          <div className="status">
                            <span>
                              {dayjs(item.create_datetime)
                                .tz(localTimezone)
                                .format(DISPLAY_DATE_FORMAT)}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div
                  className="icon-circle d-flex justify-content-center align-items-center mt-5 ml-2"
                  id="right-slide"
                  onClick={scrollRight}
                >
                  <i
                    className="fa fa-angle-right fa-lg fa-fw"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default NewTimeline;
