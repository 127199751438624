import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "../../context/FormContext";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import uuid from "react-uuid";
import Select from "react-select";
import {
  getCategoryName,
  getItemName,
  getItemValue,
} from "../../utils/ForceOpenUtils";
import { getAddendumCategoryList } from "../../api/forceOpenService";
import DocumentsHorizontal from "../DocumentHorizontal";
import { WebcamCapture } from "../webcamComponent";

function Addendums(props) {
  const { state, handleChange } = useFormContext();
  const { t } = useTranslation();
  const [selectedAddendumType, setSelectedAddendumtype] = useState("");
  const [isItemChanged, setIsItemChanged] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const [addendumAStaticCategoryList, setAddendumAStaticCategoryList] =
    useState([]);
  const [addendumBStaticCategoryList, setAddendumBStaticCategoryList] =
    useState([]);
  const [isModelEnabled, setIsModelEnabled] = useState(false);
  const [addendumType, setAddendumtype] = useState("");
  const [selectedAddendumItem, setSelectedAddendumItem] = useState("");
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const inputRef = useRef(null);
  useEffect(() => {
    loadAddendumCategory("addendum_a");
    loadAddendumCategory("addendum_b");
  }, []);

  useEffect(() => {
    setItemsList(state?.items_list);
    handleScroll();
    setIsItemChanged(false);
  }, [isItemChanged]);

  const handleScroll = () => {
    //Scroll to the new addendum item and focus on the TEB# input field
    if (
      inputRef.current &&
      typeof inputRef.current.scrollIntoView === "function"
    ) {
      inputRef.current.scrollIntoView({ behavior: "smooth" });
      inputRef.current.focus();
    }
  };

  const loadAddendumCategory = (type) => {
    getAddendumCategoryList(type, currentUser?.jwtToken)
      .then((response) => {
        type === "addendum_a"
          ? setAddendumAStaticCategoryList(response)
          : setAddendumBStaticCategoryList(response);
        // const options = response.map((opt) => ({ key: opt.addendum_category_id, value: opt.addendum_category_id, label: opt.category_name }))
        // setOptions(options);
      })
      .catch((error) => {
        // ConsoleError(error);
      });
  };

  const addAddendumItem = () => {
    let newId = uuid();
    if (selectedAddendumType === "addendum_a") {
      handleChange(
        selectedAddendumType,
        null,
        {
          id: newId,
          tamper_evident_bag_number: "",
          addendum_category_id: "",
          addendum_category_name: "",
          addendum_item_id: "",
          addendum_item_name: "",
          addendum_item_list: [],
          quantity: 0,
          pictures: [],
          documents: [],
        },
        "ADD_ADDENDUM_A_B"
      );
    } else if (selectedAddendumType === "addendum_b") {
      handleChange(
        "addendum_b",
        null,
        {
          id: newId,
          tamper_evident_bag_number: "",
          addendum_category_id: "",
          addendum_category_name: "",
          addendum_item_id: "",
          addendum_item_name: "",
          addendum_item_list: [],
          quantity: 0,
          pictures: [],
          documents: [],
          amount: 0,
          addendum_value: "",
        },
        "ADD_ADDENDUM_A_B"
      );
    } else {
      handleChange(
        selectedAddendumType.split(" ")[0],
        null,
        {
          id: newId,
          addendum_item_type: selectedAddendumType.split(" ")[1],
          tamper_evident_bag_number: "",
          certificate: "",
          number_of_shares: 0,
          value_of_bond: 0,
          title: "",
          quantity: 0,
          pictures: [],
          documents: [],
          louisiana_bond: false,
        },
        "ADD_ADDENDUM_A_B"
      );
    }

    handleChange(
      "items_list",
      null,
      {
        id: newId,
        type: selectedAddendumType.split(" ")[0],
      },
      "ADD_ADDENDUM_A_B"
    );
    setIsItemChanged(true);
  };

  const deleteAddendumItem = (type, itemId) => {
    handleChange("items_list", null, itemId, "DELETE_ADDENDUM_A_B");
    handleChange(type, null, itemId, "DELETE_ADDENDUM_A_B");
    setIsItemChanged(true);
  };

  const onChangeAddendumCategory = (categoryId, itemId, type) => {
    let selectedCategory;
    if (type === "addendum_a") {
      selectedCategory = addendumAStaticCategoryList.find(
        (category) => category.addendum_category_id === categoryId
      );
    } else {
      selectedCategory = addendumBStaticCategoryList.find(
        (category) => category.addendum_category_id === categoryId
      );
    }
    handleChange(
      type,
      "addendum_item_list",
      { id: itemId, value: selectedCategory?.addendum_item },
      "UPDATE_ADDENDUM_A_B"
    );
  };

  const handleAddDocument = (e, currentTeb, type) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        const imgSrcArray = reader.result.split(";")[0];
        let val = document.getElementById("input_document_" + currentTeb?.id);
        let file = val.files.item(0).name;
        const documentItem = {
          documentId: uuid(),
          comments: "",
          src: reader.result,
          docType: imgSrcArray,
          fileName: file,
        };
        handleNewDocument(documentItem, currentTeb, type);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  /**
   * Function to handle new documents
   */
  const handleNewDocument = (document, currentTeb, type) => {
    let documentList = currentTeb?.documents;
    let addedDocumentList = [...documentList, document];
    handleChange(
      type,
      "documents",
      { id: currentTeb?.id, value: addedDocumentList },
      "UPDATE_ADDENDUM_A_B"
    );
    // setSelectedAddendumItem(null);
  };

  const handleUpdateDocumentComment = (documentId, comments, itemId, type) => {
    handleChange(
      type,
      "documents",
      { id: itemId, value: comments, documentId: documentId },
      "UPDATE_ADDENDUM_DOCUMENT_COMMENT"
    );
  };

  /**
   * Function to handle delete documents
   */
  const handleDeleteDocument = (documentId, itemId, type) => {
    let shallowCopyDocList;
    if (type === "addendum_a") {
      shallowCopyDocList = Array.from(
        state?.addendum_a[
          state?.addendum_a.findIndex((item) => item.id === itemId)
        ]?.documents
      );
    } else if (type === "addendum_b") {
      shallowCopyDocList = Array.from(
        state?.addendum_b[
          state?.addendum_b.findIndex((item) => item.id === itemId)
        ]?.documents
      );
    }
    const docIndex = shallowCopyDocList.findIndex(
      (doc) => doc.documentId === documentId
    );
    if (docIndex > -1) {
      shallowCopyDocList.splice(docIndex, 1);
    }

    handleChange(
      type,
      "documents",
      { id: itemId, value: shallowCopyDocList },
      "UPDATE_ADDENDUM_A_B"
    );
    setSelectedAddendumItem(null);
  };

  const onCapturePicture = (picture, itemId, type) => {
    let pictureList;
    if (type === "addendum_a") {
      pictureList =
        state?.addendum_a[
          state?.addendum_a.findIndex((item) => item.id === itemId)
        ]?.pictures;
    } else if (type === "addendum_b") {
      pictureList =
        state?.addendum_b[
          state?.addendum_b.findIndex((item) => item.id === itemId)
        ]?.pictures;
    } else {
      pictureList =
        state?.addendum_c[
          state?.addendum_c.findIndex((item) => item.id === itemId)
        ]?.pictures;
    }
    let addedImageList = [...pictureList, { src: picture, typeId: itemId }];
    handleChange(
      type,
      "pictures",
      { id: itemId, value: addedImageList },
      "UPDATE_ADDENDUM_A_B"
    );
    setIsModelEnabled(false);
    setSelectedAddendumItem(null);
  };

  const OnDeletePicture = (index, itemId, type) => {
    let pictureList;
    if (type === "addendum_a") {
      pictureList =
        state?.addendum_a[
          state?.addendum_a.findIndex((item) => item.id === itemId)
        ]?.pictures;
    } else if (type === "addendum_b") {
      pictureList =
        state?.addendum_b[
          state?.addendum_b.findIndex((item) => item.id === itemId)
        ]?.pictures;
    } else {
      pictureList =
        state?.addendum_c[
          state?.addendum_c.findIndex((item) => item.id === itemId)
        ]?.pictures;
    }

    let shallowCopyImageList = Array.from(pictureList);
    if (index > -1) {
      shallowCopyImageList.splice(index, 1);
    }
    handleChange(
      type,
      "pictures",
      { id: itemId, value: shallowCopyImageList },
      "UPDATE_ADDENDUM_A_B"
    );
  };
  return (
    <>
      <div className="row justify-content-center">TAMPER EVIDENT BAG</div>
      <div className="row justify-content-center">(ADDENDUMS)</div>
      <div className="card-border">
        <div
          style={{
            position: "sticky",
            top: 72,
            zIndex: 1,
            background: "white",
            borderBottom: "1px solid grey",
          }}
        >
          <div className="form-row align-items-end">
            <div className="form-group col-md-6 col-xs-4  col-lg-3 px-4">
              <label htmlFor="ownersName">{t("labels.ownerName")}</label>
              <input
                type="text"
                className="form-control ml-2"
                id="ownersName"
                name="addNewList"
                disabled
                value={
                  state?.section1?.owner?.first_name +
                  " " +
                  state?.section1?.owner?.last_name
                }
              />
            </div>
            <div className="form-group col-md-6 col-xs-4  col-lg-3 px-4">
              <label htmlFor="safeDepositBox">{t("labels.sdb")}</label>
              <input
                type="text"
                className="form-control ml-2"
                id="safeDepositBox"
                name="addNewList"
                disabled
                value={state?.section1?.safe_deposit_box_number}
              />
            </div>
            <div className="form-group col-md-6 col-xs-4  col-lg-3 px-4">
              <select
                className="custom-select mr-sm-2"
                value={selectedAddendumType}
                data-testid="selectAddendum"
                onChange={(e) => {
                  setSelectedAddendumtype(e.target.value);
                }}
              >
                <option value="">Choose...</option>
                <option value="addendum_a">
                  Addendum A (JEWELLERY and DOCUMENTS)
                </option>
                <option value="addendum_b">Addendum B (CASH)</option>
                <option value="addendum_c Stock">Addendum C (Stocks)</option>
                <option value="addendum_c OtherBond">
                  Addendum C (OTHER BONDS)
                </option>
                <option value="addendum_c ContraBand">
                  Addendum C (CONTRABAND)
                </option>
                <option value="addendum_c Miscellaneous">
                  Addendum C (MISCELLANEOUS)
                </option>
              </select>
            </div>
            <div className="form-group col-md-6 col-xs-4  col-lg-2">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                id="addItem"
                data-testid="addItem"
                disabled={selectedAddendumType === "" ? true : false}
                onClick={() => {
                  addAddendumItem();
                }}
              >
                <i className="fa fa-plus pr-3" aria-hidden="true"></i> Add Item
              </button>
            </div>
          </div>
        </div>

        {itemsList?.map((listItem, index) => {
          let currentTeb;
          let currentTebErrors;
          let existingTebNumbersIds = props?.existingTebNumbersIds;
          if (listItem?.type === "addendum_a") {
            currentTeb = state?.addendum_a?.find((item) => {
              return item?.id === listItem?.id;
            });
            currentTebErrors = props?.addendumAErrors;
          } else if (listItem?.type === "addendum_b") {
            currentTeb = state?.addendum_b?.find((item) => {
              return item?.id === listItem?.id;
            });
            currentTebErrors = props?.addendumBErrors;
          } else {
            currentTeb = currentTeb = state?.addendum_c?.find((item) => {
              return item?.id === listItem?.id;
            });
            currentTebErrors = props?.addendumCErrors;
          }
          return (
            <>
              <div className="card-border" id={`card_container_${listItem.id}`}>
                {listItem?.type === "addendum_a" ||
                listItem?.type === "addendum_b" ? (
                  <div
                    className="form-row"
                    id={`card_row_${listItem.id}`}
                    key={"card_row_" + listItem.id}
                  >
                    <div
                      className="form-group col-md-6 col-xs-4  col-lg-4 px-4 mb-0"
                      id={`card_group_${listItem.id}`}
                      key={"card_group_1_" + listItem.id}
                    >
                      <label htmlFor={`addendum_a_teb_bag_no_${listItem.id}`}>
                        {" "}
                        {t("labels.teb")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        ref={inputRef}
                        data-testid={`addendum_a_teb_bag_no_${index}`}
                        id={`addendum_a_teb_bag_no_${listItem.id}`}
                        onChange={(e) => {
                          handleChange(
                            listItem?.type,
                            "tamper_evident_bag_number",
                            { id: listItem.id, value: e.target.value },
                            "UPDATE_ADDENDUM_A_B"
                          );
                          const currentTebId = listItem.id;
                          if (existingTebNumbersIds.includes(currentTebId)) {
                            const errorIndex =
                              existingTebNumbersIds.indexOf(currentTebId);
                            if (errorIndex > -1) {
                              existingTebNumbersIds.splice(errorIndex, 1); // Remove the "TEB exists" error
                            }
                          }
                          if (currentTebErrors.teb.includes(currentTebId)) {
                            const errorIndex =
                              currentTebErrors.teb.indexOf(currentTebId);
                            if (errorIndex > -1) {
                              currentTebErrors.teb.splice(errorIndex, 1); // Remove the "TEB is missing" error
                            }
                          }
                        }}
                        value={currentTeb?.tamper_evident_bag_number}
                      />

                      <i
                        id={`icon_barcode_${listItem.id}`}
                        className="fa fa-barcode float-right pr-3 h2 input-scanner-icon"
                        aria-hidden="true"
                        title="Scan Tamper Evident Bag"
                      ></i>
                      {currentTebErrors.teb.map((item) => {
                        if (item === currentTeb?.id) {
                          return (
                            <div
                              className="error-message-validation"
                              key={"error_1" + item}
                            >
                              {props?.errors.teb_number}
                            </div>
                          );
                        }
                      })}
                      {currentTebErrors.teb_numbers.map((item) => {
                        if (
                          item.id === currentTeb?.id &&
                          existingTebNumbersIds.includes(item.id)
                        ) {
                          return (
                            <div
                              className="error-message-validation"
                              key={"error_1" + item}
                            >
                              {props?.errors?.teb_number_exists}
                            </div>
                          );
                        }
                      })}
                    </div>
                    {listItem?.type === "addendum_a" ? (
                      <>
                        <div
                          className="form-group col-md-6 col-xs-4  col-lg-4 px-4 mb-0"
                          id={`card_group_${listItem.id}`}
                          key={"card_group_2" + listItem.id}
                        >
                          <label
                            className="mr-sm-2"
                            htmlFor={`in_line_form_custom_select_${listItem.id}`}
                          >
                            Category
                          </label>
                          <Select
                            data-testid={`addendum_a_category_${index}`}
                            id={`in_line_form_custom_select_${listItem.id}`}
                            placeholder="Choose"
                            isSearchable={true}
                            key={"in_line_form_custom_select_" + listItem.id}
                            onChange={(e) => {
                              onChangeAddendumCategory(
                                e.value,
                                currentTeb?.id,
                                listItem?.type
                              );
                              handleChange(
                                "addendum_a",
                                "addendum_category_id",
                                { id: currentTeb?.id, value: e.value },
                                "UPDATE_ADDENDUM_A_B"
                              );
                              handleChange(
                                "addendum_a",
                                "addendum_category_name",
                                {
                                  id: currentTeb?.id,
                                  value: getCategoryName(
                                    e.value,
                                    addendumAStaticCategoryList
                                  ),
                                },
                                "UPDATE_ADDENDUM_A_B"
                              );
                            }}
                            value={{
                              value: currentTeb?.addendum_category_id,
                              label: currentTeb?.addendum_category_name,
                            }}
                            options={addendumAStaticCategoryList.map((opt) => ({
                              key: opt.addendum_category_id,
                              value: opt.addendum_category_id,
                              label: opt.category_name,
                            }))}
                          ></Select>
                          {currentTebErrors.category.map((item) => {
                            if (item === currentTeb?.id) {
                              return (
                                <div
                                  className="error-message-validation"
                                  key={"error_1" + item}
                                >
                                  {props?.errors.category}
                                </div>
                              );
                            }
                          })}
                        </div>
                        <div
                          className="form-group col-md-6 col-xs-4  col-lg-4 px-4 mb-0"
                          id={`card_group_${listItem.id}`}
                          key={"card_group_3" + listItem.id}
                        >
                          <label
                            className="mr-sm-2"
                            htmlFor={`in_line_form_custom_select_${listItem.id}`}
                          >
                            Item
                          </label>
                          <Select
                            id={`in_line_form_custom_select_${listItem.id}`}
                            placeholder="Choose"
                            isSearchable={true}
                            onChange={(e) => {
                              handleChange(
                                "addendum_a",
                                "addendum_item_id",
                                { id: currentTeb?.id, value: e.value },
                                "UPDATE_ADDENDUM_A_B"
                              );
                              handleChange(
                                "addendum_a",
                                "addendum_item_name",
                                {
                                  id: currentTeb?.id,
                                  value: getItemName(
                                    e.value,
                                    currentTeb?.addendum_item_list
                                  ),
                                },
                                "UPDATE_ADDENDUM_A_B"
                              );
                            }}
                            value={{
                              value: currentTeb?.addendum_item_id,
                              label: currentTeb?.addendum_item_name,
                            }}
                            options={currentTeb?.addendum_item_list.map(
                              (opt) => ({
                                key: opt.addendum_item_id,
                                value: opt.addendum_item_id,
                                label: opt.item_name,
                              })
                            )}
                          ></Select>
                          {currentTebErrors.item.map((item) => {
                            if (item === currentTeb?.id) {
                              return (
                                <div
                                  className="error-message-validation"
                                  key={"error_1" + item}
                                >
                                  {props?.errors.item}
                                </div>
                              );
                            }
                          })}
                        </div>
                        <div
                          className="form-group col-md-6 col-xs-4 col-lg-3 px-4 mb-2"
                          id={`card_group_${listItem.id}`}
                          key={"card_group_5" + listItem.id}
                        >
                          <label htmlFor={`addendum_a_quantity_${listItem.id}`}>
                            Quantity
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id={`addendum_a_quantity_${listItem.id}`}
                            min={0}
                            onChange={(e) =>
                              handleChange(
                                "addendum_a",
                                "quantity",
                                {
                                  id: currentTeb?.id,
                                  value: parseInt(e.target.value),
                                },
                                "UPDATE_ADDENDUM_A_B"
                              )
                            }
                            value={currentTeb?.quantity}
                          />
                          {currentTebErrors.quantity.map((item) => {
                            if (item === currentTeb?.id) {
                              return (
                                <div
                                  className="error-message-validation"
                                  key={"error_1" + item}
                                >
                                  {props?.errors.quantity}
                                </div>
                              );
                            }
                          })}
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="form-group col-md-6 col-xs-4  col-lg-4 px-4 mb-0"
                          id={`card_group_${listItem.id}`}
                          key={"card_group_6" + listItem.id}
                        >
                          <label
                            className="mr-sm-2"
                            htmlFor="inlineFormCustomSelect"
                          >
                            Category
                          </label>
                          <Select
                            id="inlineFormCustomSelect"
                            placeholder="Choose"
                            isSearchable={true}
                            onChange={(e) => {
                              onChangeAddendumCategory(
                                e.value,
                                currentTeb?.id,
                                listItem?.type
                              );
                              handleChange(
                                "addendum_b",
                                "addendum_category_id",
                                { id: currentTeb?.id, value: e.value },
                                "UPDATE_ADDENDUM_A_B"
                              );
                              handleChange(
                                "addendum_b",
                                "addendum_category_name",
                                {
                                  id: currentTeb?.id,
                                  value: getCategoryName(
                                    e.value,
                                    addendumBStaticCategoryList
                                  ),
                                },
                                "UPDATE_ADDENDUM_A_B"
                              );
                            }}
                            value={{
                              value: currentTeb?.addendum_category_id,
                              label: currentTeb?.addendum_category_name,
                            }}
                            options={addendumBStaticCategoryList.map((opt) => ({
                              key: opt.addendum_category_id,
                              value: opt.addendum_category_id,
                              label: opt.category_name,
                            }))}
                          ></Select>
                          {currentTebErrors.category.map((item) => {
                            if (item === currentTeb?.id) {
                              return (
                                <div
                                  className="error-message-validation"
                                  key={"error_1" + item}
                                >
                                  {props?.errors.category}
                                </div>
                              );
                            }
                          })}
                        </div>
                        <div
                          className="form-group col-md-6 col-xs-4  col-lg-4 px-4 mb-0"
                          id={`card_group_${listItem.id}`}
                          key={"card_group_7" + listItem.id}
                        >
                          <label
                            className="mr-sm-2"
                            htmlFor="inlineFormCustomSelect"
                          >
                            Item
                          </label>
                          <Select
                            id="inlineFormCustomSelect"
                            placeholder="Choose"
                            isSearchable={true}
                            onChange={(e) => {
                              handleChange(
                                "addendum_b",
                                "addendum_item_id",
                                { id: currentTeb?.id, value: e.value },
                                "UPDATE_ADDENDUM_A_B"
                              );
                              handleChange(
                                "addendum_b",
                                "addendum_item_name",
                                {
                                  id: currentTeb?.id,
                                  value: getItemName(
                                    e.value,
                                    currentTeb?.addendum_item_list
                                  ),
                                },
                                "UPDATE_ADDENDUM_A_B"
                              );
                              handleChange(
                                "addendum_b",
                                "addendum_value",
                                {
                                  id: currentTeb.id,
                                  value: getItemValue(
                                    e.value,
                                    currentTeb?.addendum_item_list
                                  ),
                                },
                                "UPDATE_ADDENDUM_A_B"
                              );
                            }}
                            value={{
                              value: currentTeb?.addendum_item_id,
                              label: currentTeb?.addendum_item_name,
                            }}
                            options={currentTeb?.addendum_item_list.map(
                              (opt) => ({
                                key: opt.addendum_item_id,
                                value: opt.addendum_item_id,
                                label: opt.item_name,
                              })
                            )}
                          ></Select>
                          {currentTebErrors.item.map((item) => {
                            if (item === currentTeb?.id) {
                              return (
                                <div
                                  className="error-message-validation"
                                  key={"error_1" + item}
                                >
                                  {props?.errors.item}
                                </div>
                              );
                            }
                          })}
                        </div>
                        <div
                          className="form-group col-md-6 col-xs-4  col-lg-3 px-4 mb-2"
                          id={`card_group_${listItem.id}`}
                          key={"card_group_8" + listItem.id}
                        >
                          <label htmlFor="inputPassword4">Quantity</label>
                          <input
                            type="number"
                            className="form-control"
                            id="inputPassword4"
                            min={0}
                            onChange={(e) =>
                              handleChange(
                                "addendum_b",
                                "quantity",
                                {
                                  id: currentTeb?.id,
                                  value: parseInt(e.target.value),
                                },
                                "UPDATE_ADDENDUM_A_B"
                              )
                            }
                            value={currentTeb?.quantity}
                          />
                          {currentTebErrors.quantity.map((item) => {
                            if (item === currentTeb?.id) {
                              return (
                                <div
                                  className="error-message-validation"
                                  key={"error_1" + item}
                                >
                                  {props?.errors.quantity}
                                </div>
                              );
                            }
                          })}
                        </div>
                        {currentTeb?.addendum_value !== null ? (
                          <div className="form-group col-md-6 col-xs-4 col-lg-3 px-4 mb-0">
                            <label htmlFor="addendum_b_amount">Amount</label>
                            <input
                              type="number"
                              className="form-control"
                              id="addendum_b_amount"
                              min={0}
                              onChange={(e) =>
                                handleChange(
                                  "addendum_b",
                                  "addendum_value",
                                  {
                                    id: currentTeb?.id,
                                    value: parseFloat(e.target.value),
                                  },
                                  "UPDATE_ADDENDUM_A_B"
                                )
                              }
                              value={
                                currentTeb?.addendum_value *
                                currentTeb?.quantity
                              }
                            />
                          </div>
                        ) : null}
                      </>
                    )}
                    <div
                      className="form-group col-md-6 col-xs-4  col-lg-2 px-4 mb-0"
                      id={`card_group_${listItem.id}`}
                      key={"card_group_9" + listItem.id}
                    >
                      <input
                        type="file"
                        id={`input_document_${listItem.id}`}
                        multiple
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleAddDocument(e, currentTeb, listItem?.type);
                        }}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                      ></input>
                      <label
                        htmlFor={`input_document_${currentTeb?.id}`}
                        className="btn btn-primary btn-sm mt-3 mx-0"
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        <i
                          id={`icon_upload_${currentTeb?.id}`}
                          className="fa fa-upload fa-1x"
                          aria-hidden="true"
                        ></i>{" "}
                        Document
                      </label>
                    </div>
                    <div
                      className="form-group col-md-6 col-xs-4  col-lg-2 px-4 mb-0"
                      id={`card_group_${listItem.id}`}
                      key={"card_group_10" + listItem.id}
                    >
                      <button
                        id={`document_add_picture_1_${listItem.id}`}
                        type="button"
                        className="btn btn-primary btn-sm mt-3 mx-0"
                        onClick={() => {
                          setIsModelEnabled(true);
                          setSelectedAddendumItem(currentTeb?.id);
                          setAddendumtype(listItem?.type);
                        }}
                      >
                        <i
                          id={`icon_camera_${currentTeb?.id}`}
                          className="fa fa-camera me-1"
                          aria-hidden="true"
                        ></i>{" "}
                        Picture
                      </button>
                    </div>
                    <div
                      className="form-group col-md-6 col-xs-4  col-lg-1 px-4 pt-1"
                      id={`card_group_5_${listItem.id}`}
                      // style={{
                      //     position: "relative",
                      //     left: "1320px",
                      //     bottom: "105px",
                      // }}
                    >
                      <i
                        id={`icon_trash_${listItem.id}`}
                        className="fa fa-2x fa-trash mt-3"
                        aria-hidden="true"
                        onClick={() => {
                          deleteAddendumItem(listItem?.type, currentTeb?.id);
                        }}
                      ></i>
                    </div>
                    {currentTeb?.pictures.length > 0 && (
                      <div
                        className="row col-lg-12 justify-content-center"
                        id={`document_heading_${currentTeb?.id}`}
                      >
                        PICTURES
                      </div>
                    )}
                    <div
                      className={
                        currentTeb?.pictures.length > 0
                          ? "row card-border col-lg-11"
                          : ""
                      }
                      id={`document_row_${currentTeb?.id}`}
                    >
                      <div
                        className="form-row align-items-end mt-5"
                        id={`document_camera_row_${currentTeb?.id}`}
                      >
                        <div
                          className="form-group col-12 px-4"
                          id={`document_camera_group_1_${currentTeb?.id}`}
                        >
                          <WebcamCapture
                            className="card-border"
                            id={`web_cam_capture_${currentTeb?.id}`}
                            itemId={
                              selectedAddendumItem
                                ? selectedAddendumItem
                                : currentTeb?.id
                            }
                            addendumType={addendumType}
                            currentAddendumType={listItem?.type}
                            pictureList={currentTeb?.pictures}
                            onCapturePicture={(picture, itemId, addendumType) =>
                              onCapturePicture(picture, itemId, addendumType)
                            }
                            onDeletePicture={(index, itemId, addendumType) =>
                              OnDeletePicture(index, itemId, addendumType)
                            }
                            isModelEnabled={isModelEnabled}
                            disableModal={() => {
                              setIsModelEnabled(false);
                              setSelectedAddendumItem(null);
                            }}
                          ></WebcamCapture>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  // </div>
                  <>
                    {/* <div className="card-border" key={listItem.id}> */}
                    <div className="form-row mt-2">
                      <div className="form-check col-md-6 col-xs-4  col-lg-4 px-4">
                        <input
                          type="checkbox"
                          id="stock_louisiana_bond"
                          autoComplete="off"
                          name="stock_louisiana_bond"
                          onChange={(e) => {
                            handleChange(
                              "addendum_c",
                              "louisiana_bond",
                              { id: currentTeb?.id, value: e.target.checked },
                              "UPDATE_ADDENDUM_A_B"
                            );
                          }}
                          checked={currentTeb?.louisiana_bond}
                        />
                        <label
                          className="form-check-label pl-3"
                          htmlFor="stock_louisiana_bond"
                        >
                          Louisiana Bond
                        </label>
                      </div>

                      {/*<div className="form-group col-md-6 col-xs-4  col-lg-3 px-4 mb-0" id={`card_group_${listItem.id}`} key={"card_group_12" + listItem.id}></div> */}
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                        <label htmlFor="stockTebBagNo">
                          {" "}
                          {t("labels.teb")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="stockTebBagNo"
                          ref={inputRef}
                          onChange={(e) => {
                            handleChange(
                              "addendum_c",
                              "tamper_evident_bag_number",
                              { id: currentTeb?.id, value: e.target.value },
                              "UPDATE_ADDENDUM_A_B"
                            );
                            const currentTebId = listItem.id;
                            if (existingTebNumbersIds.includes(currentTebId)) {
                              const errorIndex =
                                existingTebNumbersIds.indexOf(currentTebId);
                              if (errorIndex > -1) {
                                existingTebNumbersIds.splice(errorIndex, 1); // Remove the "TEB exists" error
                              }
                            }
                            if (currentTebErrors.teb.includes(currentTebId)) {
                              const errorIndex =
                                currentTebErrors.teb.indexOf(currentTebId);
                              if (errorIndex > -1) {
                                currentTebErrors.teb.splice(errorIndex, 1); // Remove the "TEB is missing" error
                              }
                            }
                          }}
                          value={currentTeb?.tamper_evident_bag_number}
                        />
                        <i
                          className="fa fa-barcode float-right pr-3 h2 input-scanner-icon"
                          aria-hidden="true"
                          title="Scan Tamper Evident Bag"
                        ></i>
                        {currentTebErrors?.teb.map((item) => {
                          if (item === currentTeb?.id) {
                            return (
                              <div
                                className="error-message-validation"
                                key={"error_1" + item}
                              >
                                {props?.errors.teb_number}
                              </div>
                            );
                          }
                        })}
                        {currentTebErrors.teb_numbers.map((item) => {
                          if (
                            item.id === currentTeb?.id &&
                            existingTebNumbersIds.includes(item.id)
                          ) {
                            return (
                              <div
                                className="error-message-validation"
                                key={"error_1" + item}
                              >
                                {props?.errors?.teb_number_exists}
                              </div>
                            );
                          }
                        })}
                      </div>
                      {currentTeb?.addendum_item_type === "Stock" ? (
                        <>
                          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                            <label htmlFor="stockCertificate">
                              {t("labels.stockCertificate")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="stockCertificate"
                              onChange={(e) =>
                                handleChange(
                                  listItem?.type,
                                  "title",
                                  { id: currentTeb?.id, value: e.target.value },
                                  "UPDATE_ADDENDUM_A_B"
                                )
                              }
                              value={currentTeb?.title}
                            />
                            {currentTebErrors.title.map((item) => {
                              if (item === currentTeb?.id) {
                                return (
                                  <div
                                    className="error-message-validation"
                                    key={"error_3" + item}
                                  >
                                    {props.errors.title}
                                  </div>
                                );
                              }
                            })}
                          </div>
                          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                            <label htmlFor="stockCertificateNo">
                              {t("labels.certificate")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="stockCertificateNo"
                              onChange={(e) =>
                                handleChange(
                                  "addendum_c",
                                  "certificate",
                                  { id: currentTeb?.id, value: e.target.value },
                                  "UPDATE_ADDENDUM_A_B"
                                )
                              }
                              value={currentTeb?.certificate}
                            />
                          </div>
                          <div className="form-group col-md-6 col-xs-4 col-lg-3 px-4">
                            <label htmlFor="noOfShares">
                              {t("labels.noOfShares")}
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="noOfShares"
                              min={0}
                              onChange={(e) =>
                                handleChange(
                                  "addendum_c",
                                  "number_of_shares",
                                  {
                                    id: currentTeb?.id,
                                    value: parseInt(e.target.value),
                                  },
                                  "UPDATE_ADDENDUM_A_B"
                                )
                              }
                              value={currentTeb?.number_of_shares}
                            />
                            {currentTebErrors.stock.noOfShares.map((item) => {
                              if (item === currentTeb?.id) {
                                return (
                                  <div
                                    className="error-message-validation"
                                    key={"error_3" + item}
                                  >
                                    {props.errors.value_of_bond}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </>
                      ) : currentTeb?.addendum_item_type === "OtherBond" ? (
                        <>
                          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                            <label htmlFor="otherBonds">
                              {t("labels.otherBonds")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="otherBonds"
                              onChange={(e) =>
                                handleChange(
                                  "addendum_c",
                                  "title",
                                  { id: currentTeb?.id, value: e.target.value },
                                  "UPDATE_ADDENDUM_A_B"
                                )
                              }
                              value={currentTeb?.title}
                            />
                            {currentTebErrors.title.map((item) => {
                              if (item === currentTeb?.id) {
                                return (
                                  <div
                                    className="error-message-validation"
                                    key={"error_3" + item}
                                  >
                                    {props.errors.title}
                                  </div>
                                );
                              }
                            })}
                          </div>
                          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                            <label htmlFor="descriptionOfBond">
                              {t("labels.descOfBond")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="descriptionOfBond"
                              onChange={(e) =>
                                handleChange(
                                  "addendum_c",
                                  "description",
                                  { id: currentTeb?.id, value: e.target.value },
                                  "UPDATE_ADDENDUM_A_B"
                                )
                              }
                              value={currentTeb?.description}
                            />
                          </div>
                          <div className="form-group col-md-6 col-xs-4  col-lg-3 px-4">
                            <label htmlFor="valueOfBond">
                              {t("labels.valueOfBond")}
                            </label>
                            <input
                              type="number"
                              min={0}
                              className="form-control"
                              id="valueOfBond"
                              onChange={(e) =>
                                handleChange(
                                  "addendum_c",
                                  "value_of_bond",
                                  {
                                    id: currentTeb?.id,
                                    value: parseFloat(e.target.value),
                                  },
                                  "UPDATE_ADDENDUM_A_B"
                                )
                              }
                              value={currentTeb?.value_of_bond}
                            />
                            {currentTebErrors.other.bondValue.map((item) => {
                              if (item === currentTeb?.id) {
                                return (
                                  <div
                                    className="error-message-validation"
                                    key={"error_3" + item}
                                  >
                                    {props.errors.value_of_bond}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </>
                      ) : currentTeb?.addendum_item_type === "ContraBand" ? (
                        <>
                          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                            <label htmlFor="contraband">
                              {t("labels.contraband")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="contraband"
                              onChange={(e) =>
                                handleChange(
                                  "addendum_c",
                                  "title",
                                  { id: currentTeb?.id, value: e.target.value },
                                  "UPDATE_ADDENDUM_A_B"
                                )
                              }
                              value={currentTeb?.title}
                            />
                            {currentTebErrors.title.map((item) => {
                              if (item === currentTeb?.id) {
                                return (
                                  <div
                                    className="error-message-validation"
                                    key={"error_3" + item}
                                  >
                                    {props.errors.title}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                            <label htmlFor="miscellaneousTitle">
                              {t("labels.miscellaneous")}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="miscellaneousTitle"
                              onChange={(e) =>
                                handleChange(
                                  "addendum_c",
                                  "title",
                                  { id: currentTeb?.id, value: e.target.value },
                                  "UPDATE_ADDENDUM_A_B"
                                )
                              }
                              value={currentTeb?.title}
                            />
                            {currentTebErrors.title.map((item) => {
                              if (item === currentTeb?.id) {
                                return (
                                  <div
                                    className="error-message-validation"
                                    key={"error_3" + item}
                                  >
                                    {props.errors.title}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </>
                      )}
                      <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                        <label htmlFor="miscellaneousQuantity">
                          {t("labels.quantity")}
                        </label>
                        <input
                          type="number"
                          min={0}
                          className="form-control"
                          id="miscellaneousQuantity"
                          onChange={(e) =>
                            handleChange(
                              "addendum_c",
                              "quantity",
                              {
                                id: currentTeb?.id,
                                value: parseInt(e.target.value),
                              },
                              "UPDATE_ADDENDUM_A_B"
                            )
                          }
                          value={currentTeb?.quantity}
                        />
                        {currentTebErrors.quantity.map((item) => {
                          if (item === currentTeb?.id) {
                            return (
                              <div
                                className="error-message-validation"
                                key={"error_3" + item}
                              >
                                {props.errors.quantity}
                              </div>
                            );
                          }
                        })}
                      </div>
                      <div
                        className="form-group col-md-6 col-xs-4  col-lg-2 px-4"
                        id={`document_group_1_${currentTeb?.id}`}
                      >
                        <input
                          type="file"
                          id={`input_document_${currentTeb?.id}`}
                          multiple
                          style={{ display: "none" }}
                          onChange={(e) => {
                            handleAddDocument(e, currentTeb, listItem?.type);
                          }}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                        ></input>

                        <label
                          htmlFor={`input_document_${currentTeb?.id}`}
                          className="btn btn-primary btn-sm mt-3"
                          style={{ cursor: "pointer" }}
                        >
                          {" "}
                          <i
                            id={`icon_upload_${currentTeb?.id}`}
                            className="fa fa-upload fa-1x me-1"
                            aria-hidden="true"
                          ></i>{" "}
                          Document
                        </label>
                      </div>
                      <div
                        className="form-group col-md-6 col-xs-4  col-lg-2 px-4"
                        id={`document_group_1_${currentTeb?.id}`}
                      >
                        <button
                          id={`document_add_picture_1_${currentTeb?.id}`}
                          type="button"
                          className="btn btn-primary btn-sm mt-3"
                          onClick={() => {
                            setIsModelEnabled(true);
                            setSelectedAddendumItem(currentTeb?.id);
                            setAddendumtype(listItem?.type);
                          }}
                        >
                          <i
                            id={`icon_camera_${currentTeb?.id}`}
                            className="fa fa-camera me-1"
                            aria-hidden="true"
                          ></i>{" "}
                          Picture
                        </button>
                      </div>
                      <div
                        className="form-group col-md-6 col-xs-4  col-lg-1 px-4 pt-1"
                        id={`card_group_5_${listItem.id}`}
                      >
                        <i
                          id={`icon_trash_${listItem.id}`}
                          className="fa fa-2x fa-trash mt-3"
                          aria-hidden="true"
                          onClick={() => {
                            deleteAddendumItem(listItem?.type, currentTeb?.id);
                          }}
                        ></i>
                      </div>
                      <div
                        className={
                          currentTeb?.pictures.length > 0
                            ? "row card-border col-lg-11"
                            : ""
                        }
                        id={`document_row_${currentTeb?.id}`}
                      >
                        <div
                          className="form-row align-items-end mt-5"
                          id={`document_camera_row_${currentTeb?.id}`}
                        >
                          <div
                            className="form-group col-12 px-4"
                            id={`document_camera_group_1_${currentTeb?.id}`}
                          >
                            <WebcamCapture
                              className="card-border"
                              id={`web_cam_capture_${currentTeb?.id}`}
                              itemId={
                                selectedAddendumItem
                                  ? selectedAddendumItem
                                  : currentTeb?.id
                              }
                              addendumType={addendumType}
                              currentAddendumType={listItem?.type}
                              pictureList={currentTeb?.pictures}
                              onCapturePicture={(
                                picture,
                                itemId,
                                addendumType
                              ) =>
                                onCapturePicture(picture, itemId, addendumType)
                              }
                              onDeletePicture={(index, itemId, addendumType) =>
                                OnDeletePicture(index, itemId, addendumType)
                              }
                              isModelEnabled={isModelEnabled}
                              disableModal={() => {
                                setIsModelEnabled(false);
                                setSelectedAddendumItem(null);
                              }}
                            ></WebcamCapture>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {currentTeb?.documents.length > 0 && (
                  <>
                    <div
                      className="row col-lg-12 justify-content-center"
                      id={`document_heading_${currentTeb?.id}`}
                    >
                      DOCUMENTS
                    </div>
                    <div
                      className="row card-border col-lg-11"
                      id={`document_row_${currentTeb?.id}`}
                    >
                      {currentTeb?.documents.length > 0 && (
                        <DocumentsHorizontal
                          id={`documents_horizontal_${currentTeb?.id}`}
                          key={currentTeb?.id}
                          itemId={currentTeb?.id}
                          documentList={currentTeb?.documents}
                          addendumType={listItem?.type}
                          onDeleteDocument={(documentId, itemId, type) =>
                            handleDeleteDocument(documentId, itemId, type)
                          }
                          onUpdateDocComment={(
                            documentId,
                            comment,
                            itemId,
                            type
                          ) =>
                            handleUpdateDocumentComment(
                              documentId,
                              comment,
                              itemId,
                              type
                            )
                          }
                        />
                      )}
                      {/* <hr className="px-0"></hr> */}
                    </div>
                  </>
                )}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

export default Addendums;
