import React, { useEffect, useState } from "react";
import { DATE_MODULE_CONSTANT } from "../constants/Constants";
import {
  getDateConfigureData,
  putDateConfigureData,
} from "../api/ConfigureDatesService";
import { useSelector } from "react-redux";
import { CustomAlert } from "./customAlert";

function ConfigureDates() {
  const [selectedField, setSelectedField] = useState("");
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const [moduleData, setModuleData] = useState();
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [description, setDescription] = useState();
  // Get module by field name
  function getModuleByField(fieldName) {
    const entry = DATE_MODULE_CONSTANT.find((item) => item.field === fieldName);
    return entry ? entry.module : null;
  }

  // Fetch selected field data
  const fetchSelectedFieldData = async () => {
    try {
      const module = getModuleByField(selectedField);
      const response = await getDateConfigureData(
        selectedField,
        module,
        currentUser?.jwtToken
      );

      if (response?.status === 200) {
        setModuleData(response?.data);
        setMinDate(response?.data?.min_value);
        setMaxDate(response?.data?.max_value);
        setDescription(
          response?.data?.description ? response?.data?.description : ""
        );
      }
    } catch (error) {
      console.error("Error fetching field data:", error);
    }
  };
  const onSubmit = async () => {
    // Prepare the payload for updating configuration data
    // If the selected field is "Audit Lock Period", set max_value to 0 since "Audit Lock Period"
    // has no maximum number of days. This is required in the backend, so we send 0 to satisfy the constraint.
    // Otherwise, set max_value to the value of maxDate if it's a valid number.
    let payload = {
      module_name: moduleData?.module_name,
      field_name: moduleData?.field_name,
      min_value: Number(minDate),
      max_value: isNotAuditLockPeriod(moduleData, selectedField)
        ? 0
        : Number(maxDate),
      description: description || "", // Default to empty string if no description
    };

    // Call the backend service to update the configurations
    putDateConfigureData(payload, currentUser.jwtToken).then((response) => {
      if (response?.data?.status === "success") {
        CustomAlert(
          "Updated",
          response?.data?.message,
          "success",
          "OK",
          false,
          document.getElementById("swal-div")
        ).then(() => {
          setModuleData(); // Reset module data on successful update
        });
      } else {
        CustomAlert(
          "Failed",
          "Configurations are failed to update.",
          "error",
          "OK",
          false,
          document.getElementById("swal-div")
        );
      }
    });
  };

  const isAuditLockPeriod = (moduleData, selectedField) => {
    return (
      moduleData?.module_name === "Audit" &&
      selectedField === "Audit Lock Period"
    );
  };

  const isNotAuditLockPeriod = (moduleData, selectedField) => {
    return (
      moduleData?.module_name !== "Audit" &&
      selectedField !== "Audit Lock Period"
    );
  };

  useEffect(() => {
    if (selectedField !== "") {
      fetchSelectedFieldData();
    }
  }, [selectedField]);

  return (
    <>
      <div className="row white-background pt-3">
        <div className="card-border mx-2" style={{ borderRadius: "10px" }}>
          <div className="form-row">
            <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
              <label htmlFor="from_storage_type"> Select a Field </label>
              <select
                className="custom-select mr-sm-2"
                id="from_storage_type"
                name="from_storage_type"
                defaultValue=""
                value={selectedField}
                onChange={(e) => setSelectedField(e.target.value)}
              >
                <option value="" disabled>
                  Choose...
                </option>
                {DATE_MODULE_CONSTANT.map(({ field }, index) => (
                  <option key={index} value={field}>
                    {field}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {moduleData && (
            <>
              <div className="form-row">
                <div className="form-group col-md-6 col-xs-4 col-lg-3 px-4">
                  <label htmlFor="from_storage_type"> Module </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={moduleData?.module_name}
                  />
                </div>

                {minDate !== null && (
                  <div className="form-group col-md-6 col-xs-4 col-lg-3 px-4">
                    <label htmlFor="from_storage_type">
                      {isAuditLockPeriod(moduleData, selectedField)
                        ? "Audit Lock Period"
                        : "No. of Days Prior"}
                      {/* {!isAuditLockPeriod(moduleData, selectedField) && ( */}
                        <i
                          data-toggle="tooltip"
                          data-placement="top"
                          title="You can select up to 30 days prior."
                          className="fa fa-info-circle"
                          style={{
                            marginLeft: "5px",
                            fontSize: "16px",
                            color: "#007bff",
                          }}
                        ></i>
                      {/* )} */}
                    </label>

                    <input
                      type="number"
                      className="form-control"
                      value={minDate}
                      onChange={(e) => {
                        let value = Math.abs(e.target.value);
                        setMinDate(value > 30 ? 30 : value); // Limit the value to 30
                      }}
                      min="0"
                      max="30"
                    />
                  </div>
                )}

                {maxDate !== null &&
                  isNotAuditLockPeriod(moduleData, selectedField) && (
                    <div className="form-group col-md-6 col-xs-4 col-lg-3 px-4">
                      <label htmlFor="from_storage_type">
                        Max No. of Days
                        <i
                          className="fa fa-info-circle"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="You can select up to 30 days after."
                          style={{
                            marginLeft: "5px",
                            fontSize: "16px",
                            color: "#007bff",
                          }}
                        ></i>
                      </label>

                      <input
                        type="number"
                        className="form-control"
                        value={maxDate}
                        onChange={(e) => {
                          let value = Math.abs(e.target.value);
                          setMaxDate(value > 30 ? 30 : value); // Limit the value to 30
                        }}
                        min="0"
                        max="30"
                      />
                    </div>
                  )}
              </div>

              <div className="form-group col-md-6 col-xs-4 col-lg-6 px-4">
                <label htmlFor="from_storage_type"> Description </label>
                <textarea
                  name="textarea"
                  className="form-control"
                  value={description}
                  rows="5"
                  cols="4"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="form-row justify-content-center mt-3">
                <button
                  className="btn btn-primary btn-sm center"
                  disabled={
                    moduleData?.description === description &&
                    moduleData?.min_value === minDate &&
                    moduleData?.max_value === maxDate
                  }
                  onClick={onSubmit}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ConfigureDates;
