import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";
import { EDIT_FIELDS } from "../constants/ApiUrlConstants";

export const getEditFieldsModule = async (module_name, token) => {
  return axios
    .get(service.baseUrl + EDIT_FIELDS + "?module_name=" + module_name, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((response) => {
      return response;
    });
};

export const updateFieldsConfiguration = async (payload, token) => {
  return axios
    .patch(service.baseUrl + EDIT_FIELDS, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((response) => {
      return response;
    });
};
