import { MDBCard, MDBCardBody } from "mdbreact";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getHistory } from "../api/History";
import CustomHeader from "../components/CustomHeader";
import HistoryTimeline from "../components/HistoryTimeline";
import "../style/checkinHistory.css";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DISPLAY_DATE_FORMAT } from "../constants/Constants";
import { ConsoleError } from "../utils/ErrorUtils";
import { SHIPMENT_HISTORY_LIMIT } from "../constants/ShipmentConstants";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { saveAs } from "file-saver";

function ShipmentHistory(props) {
  let navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [clickid, setClickId] = useState();
  const [selectedEvent, setSelectedEvent] = useState(0);
  const [shipmentData, setShipmentData] = useState();
  const [bagId, setbagId] = useState();
  const [fromLocation, setFromLocation] = useState();
  const [toLocation, setToLocation] = useState();
  const [notes, setNotes] = useState();
  const [start, setStart] = useState();
  const [estimated, setEstimated] = useState();
  const [loader, setLoader] = useState(false);
  const [documents, setDocuments] = useState([]);
  const event = "Shipment";
  const Id = state.tamperevidentbag_id ? state.tamperevidentbag_id : null;
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const tebId = state?.tamper_evident_bag?.tamper_evident_bag_id
    ? state?.tamper_evident_bag?.tamper_evident_bag_id
    : state?.tamper_evident_bag_id
    ? state?.tamper_evident_bag_id
    : null;

    useEffect(() => {
      if (shipmentData && shipmentData.length > 0) {
        // Determine the index of the current event based on state
        const currentEventIndex = shipmentData.findIndex(
                (e) => e.shipment.shipment_id === state?.shipment?.shipment_id
              )    
        // Fallback to 0 if no valid index is found
        const resolvedIndex = currentEventIndex >= 0 ? currentEventIndex : 0;
    
        // Trigger the onClick function with the selected event and index
        onClick(shipmentData[resolvedIndex], resolvedIndex);
      }
    }, [shipmentData]);
    

  const getTimelineData = async () => {
    setLoader(true);
    getHistory(
      "ship-content",
      tebId,
      1,
      SHIPMENT_HISTORY_LIMIT,
      currentUser?.jwtToken
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response.status === 200) {
          setLoader(false);

          setShipmentData(
            response?.data ? response?.data : null
          );
          setbagId(
            response?.data[0].tamper_evident_bag?.tamper_evident_bag_number
          );
          setFromLocation(response?.data[0].shipment?.from_location?.name);
          setToLocation(response?.data[0].shipment?.to_location?.name);
          setStart(response?.data[0].shipment?.start_date);
          setEstimated(response?.data[0].shipment?.estimated_arrival_date);
          setNotes(response?.data[0].shipment?.notes);
          setDocuments(response?.data[0]?.shipment?.documents);
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };
  dayjs.extend(utc);
  dayjs.extend(tz);
  const localTimezone = dayjs.tz.guess();

  const onClick = (item, index) => {
    setSelectedEvent(index);
    setbagId(item?.tamper_evident_bag?.tamper_evident_bag_number);
    setFromLocation(item?.shipment?.from_location?.name);
    setToLocation(item?.shipment?.to_location?.name);
    setStart(item?.shipment?.start_date);
    setEstimated(item?.shipment?.estimated_arrival_date);
    setNotes(item?.shipment?.notes);
    setClickId(index);
    setDocuments(item?.shipment?.documents);
  };

  const download = (link, name) => {
    saveAs(link, name);
  };
  useEffect(() => {
    getTimelineData();
    //eslint-disable-next-line
  }, [Id]);
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 ">
      <div className="row flex pt-4 pl-4 pr-4 pb-0 ">
        <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden">
          <div className="row pt-1 pb-2">
            <div className="d-flex justify-content-end">
              <div className="p-0">
                {currentUser?.shipment_save_bulk_upload_access_flag && (
                  <Link to="/bulkShipment">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      id="bulk-checkin-button"
                    >
                      BULK SHIPMENT{" "}
                    </button>
                  </Link>
                )}
              </div>
              <div className="p-0">
                {currentUser?.shipment_save_record_access_flag && (
                  <Link to="/createShipment">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      id="bulk-checkin-button"
                    >
                      SINGLE SHIPMENT{" "}
                    </button>
                  </Link>
                )}
              </div>
              <div className="p-0">
                <Link to="/viewAllShipment">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    id="checkin-records-button"
                  >
                    SHIPMENT RECORDS{" "}
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <MDBCard className="card p-0 m-0 ">
            <CustomHeader label={"SHIPMENT HISTORY"} />
            {loader ? (
              <div className="text-center py-5 mt-5 alignself-center">
                <Spinner animation="border" />
              </div>
            ) : (
              <MDBCardBody id="scroll-bar">
                <div className="row text-center ">
                  <div className="col-lg-12 col-md-12 pt-4 pb-4 ">
                    <HistoryTimeline
                      historyData={shipmentData}
                      onClick={onClick}
                      clickid={clickid}
                      selectedEvent={selectedEvent}
                      event={event}
                    />
                  </div>
                  <div className="event-div">
                    <div className="d border border-5 event-border h-100 ml-5 mr-5">
                      <div className="row col-lg-12 justify-content-center pt-3 pb-3">
                        EVENT DETAILS
                      </div>{" "}
                      <div className="row col-lg-12 col-md-12 col-sm-12 ml-2 pb-3 bs-scroll">
                        <div className={"col-lg-12 col-md-12 col-sm-12  "}>
                          <form>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                {t("labels.teb")}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {" "}
                                  {bagId ? bagId : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                From Location{" "}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {fromLocation ? fromLocation : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                To Location{" "}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {toLocation ? toLocation : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Start{" "}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start">
                                  {start
                                    ? dayjs(start)
                                        .tz(localTimezone)
                                        .format(DISPLAY_DATE_FORMAT)
                                    : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Estimated{" "}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start text-left">
                                  {estimated
                                    ? dayjs(estimated)
                                        .tz(localTimezone)
                                        .format(DISPLAY_DATE_FORMAT)
                                    : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                Shipping Notes{" "}
                              </label>
                              <div className="col-sm-8 p-0">
                                <div className="float-start text-left">
                                  {notes ? notes : null}
                                </div>
                              </div>
                            </div>
                            {documents?.length > 0 && (
                              <div className="form-group row">
                                <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                                  Documents{" "}
                                </label>
                                <div className="col-sm-8 p-0">
                                  {documents?.filter(
                                    (document) => document?.type === "DOCUMENT"
                                  ).length > 0 && (
                                    <div className="row ">
                                      <div className="col-8 fw-bold d-flex pl-3">
                                        <span className="float-start">
                                          Uploaded Document Name
                                        </span>
                                      </div>
                                      <div className="col-4 fw-bold">
                                        Description
                                      </div>
                                    </div>
                                  )}
                                  {documents
                                    ?.filter(
                                      (document) =>
                                        document?.type === "DOCUMENT"
                                    )
                                    .map((filteredDocument) => (
                                      <div className="row">
                                        <div className="col-8 d-flex">
                                          <span
                                            className="cursor-pointer float-start"
                                            onClick={() =>
                                              download(
                                                filteredDocument?.url,
                                                filteredDocument?.name
                                              )
                                            }
                                          >
                                            {filteredDocument?.name}
                                            <i className="fa fa-download pl-2" />
                                          </span>
                                        </div>
                                        <div className="col-4 ">
                                          {filteredDocument?.description}
                                        </div>
                                      </div>
                                    ))}
                                  {documents?.filter(
                                    (document) => document?.type === "PICTURE"
                                  ).length > 0 && (
                                    <div className="row pt-3">
                                      <div className="col-8 fw-bold d-flex pl-3">
                                        <span className="float-start">
                                          Added Picture Name
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {documents
                                    ?.filter(
                                      (document) => document?.type === "PICTURE"
                                    )
                                    .map((filteredPicture) => (
                                      <div className="row">
                                        <div className="col-8 d-flex">
                                          <span
                                            className="cursor-pointer float-start"
                                            onClick={() =>
                                              download(
                                                filteredPicture?.url,
                                                filteredPicture?.name
                                              )
                                            }
                                          >
                                            {filteredPicture?.name}
                                            <i className="fa fa-download pl-2" />
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </MDBCardBody>
            )}
          </MDBCard>
        </div>
      </div>
    </div>
  );
}

export default ShipmentHistory;
