import { MDBCard, MDBCardBody } from "mdbreact";
import user from "../assets/user.png";
import CustomHeader from "../components/CustomHeader";
import Search from "../components/Search";
import "../style/inbox.css";
import { useSelector } from "react-redux";

function Inbox() {
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  let openInboxData = [
    {
      id: 1,
      mesg_header: "New Shipment Request1",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
      msg_full_body:
        "New Shipping Request raised by ERIC J  (Asst.MAnager) for Tamper bag number TEB-103.Shipping from - Arizona Shipping to - Ohio Box Details - Owner Name - James",
    },
  ];

  let inboxData = [
    {
      id: 1,
      mesg_header: "New Shipment Request2",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
      msg_full_body:
        "New Shipping Request raised by ERIC J  (Asst.MAnager) for Tamper bag number TEB-103.Shipping from - Arizona Shipping to - Ohio Box Details - Owner Name - James",
    },
    {
      id: 2,
      mesg_header: "New Shipment Request3",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
      msg_full_body:
        "New Shipping Request raised by ERIC J  (Asst.MAnager) for Tamper bag number TEB-103.Shipping from - Arizona Shipping to - Ohio Box Details - Owner Name - James",
    },
    {
      id: 3,
      mesg_header: "New Shipment Request4",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },
    {
      id: 4,
      mesg_header: "New Shipment Request5",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },
    {
      id: 5,
      mesg_header: "New Shipment Request6",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },
    {
      id: 6,
      mesg_header: "New Shipment Request7",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },
    {
      id: 7,
      mesg_header: "New Shipment Request8",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },
    {
      id: 8,
      mesg_header: "New Shipment Request9",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },
    {
      id: 9,
      mesg_header: "New Shipment Request10",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },
    {
      id: 10,
      mesg_header: "New Shipment Request11",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },
    {
      id: 11,
      mesg_header: "New Shipment Request12",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },
    {
      id: 12,
      mesg_header: "New Shipment Request13",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },
    {
      id: 13,
      mesg_header: "New Shipment Request14",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },
    {
      id: 14,
      mesg_header: "New Shipment Request15",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },
    {
      id: 15,
      mesg_header: "New Shipment Request16",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },
    {
      id: 16,
      mesg_header: "New Shipment Request17",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },
    {
      id: 17,
      mesg_header: "New Shipment Request18",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },
    {
      id: 18,
      mesg_header: "New Shipment Request19",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },
    {
      id: 19,
      mesg_header: "New Shipment Request20",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },

    {
      id: 20,
      mesg_header: "New Shipment Request21",
      date: "19/09/2020",
      emp_name: currentUser.employee_name,
      msg_body: "Shipment Arriving from California to Columbo",
    },
  ];
  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 body">
        <div className="row flex p-4">
          <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden">
            <div className="row pt-2 pb-3">
              <div className="col-lg-6 col-md-12 ">
                <Search />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 pl-0 pr-0"></div>
            </div>
            <MDBCard id="inbox-card" className="card   p-0 m-0">
              <CustomHeader label={"INBOX"} />
              <MDBCardBody>
                <div id="inboxScreen">
                  <div className="col-md-4">
                    <div className="left-side messageBox" id="cs-scroll">
                      <div className="table-responsive">
                        <table className="table" id="inboxTable">
                          <tbody>
                            {inboxData &&
                              inboxData.map((item, index) => {
                                return (
                                  <tr key={index} className="table-row">
                                    <td id="profileInbox">
                                      <div>
                                        {" "}
                                        <img
                                          className="img-responsive "
                                          src={user}
                                          alt="logo"
                                          width="30px"
                                          height="30px"
                                        ></img>
                                      </div>
                                    </td>

                                    <td>
                                      <div
                                        className="d-flex align-items-start"
                                        id="msgHeaderDetails"
                                      >
                                        {item.mesg_header}
                                      </div>
                                      <div
                                        className="d-flex align-items-start"
                                        id="empName"
                                      >
                                        {item.emp_name}
                                      </div>
                                      <div
                                        className="d-flex align-items-start"
                                        id="msgBody"
                                      >
                                        {item.msg_body}
                                      </div>
                                    </td>

                                    <td>
                                      <div id="dateInbox">{item.date}</div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="right-side">
                      <div className="table-responsive">
                        <table className="table">
                          <tbody>
                            {openInboxData &&
                              openInboxData.map((item, index) => {
                                return (
                                  <div id="rowDataInbox" key={index}>
                                    <tr className="table-row detailsInbox">
                                      <td>
                                        <div id="msgHeaderDetails">
                                          {item.mesg_header}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr className="table-row detailsInbox">
                                      <td>
                                        <div className="d-flex bd-highlight mb-3">
                                          <div className="p-2 bd-highlight">
                                            {" "}
                                            <img
                                              className="img-responsive "
                                              src={user}
                                              alt="logo"
                                              width="30px"
                                              height="30px"
                                            ></img>
                                          </div>
                                          <div
                                            className="p-2 bd-highlight"
                                            id="empNameDetails"
                                          >
                                            {item.emp_name}
                                          </div>
                                          <div
                                            className="ml-auto p-2 bd-highlight"
                                            id="dateDetails"
                                          >
                                            {item.date}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr className="table-row detailsInbox">
                                      <td>
                                        <div
                                          className="d-flex align-items-start"
                                          id="msgFullBodyDetails"
                                        >
                                          {item.msg_full_body}
                                        </div>
                                      </td>
                                    </tr>
                                  </div>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
          </div>
        </div>
      </div>
    </>
  );
}

export default Inbox;
