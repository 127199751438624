import {  getStorageLocationRecords } from "../api/StorageLocationServices";

export const fetchStorageOptions = async (serachvalue, page, storageList, storageType, Token ) => {
    try {
      const limit = 20; // Number of options per page
      let UpdatedpageNo = 1
      if(storageList.length>0 && serachvalue.length===0){
        UpdatedpageNo = page +1
      }
      
      const response = await getStorageLocationRecords(
        serachvalue,
        UpdatedpageNo,
        limit,
        Token,// currentUser?.jwtToken,
        storageType
      );

      if (!response?.data?.storage_locations) {
        throw new Error("Invalid response from the server");
      }
    //   return response
       return {
        "locations": response?.data?.storage_locations,
        "UpdatedpageNo": UpdatedpageNo
       }
    //   // Append new options to the existing list
   
    } catch (error) {
      console.error("Error fetching options:", error);
      return [];
    }
  };