import React from "react";

const CustomHeader = (props) => {
  return (
    <div
      style={{
        width: "100%",
        background: "#6F7D95",
        alignItems: "center",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        verticalAlign: "center",
        marginBottom: "8px",
        padding: "8px",
        color: "white",
        position: "sticky",
        top: 0,
      }}
      className="fw-bold"
    >
      {props?.label?.toLocaleUpperCase()}
      {props?.children}
    </div>
  );
};

export default CustomHeader;
