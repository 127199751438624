import { MDBCard, MDBCardBody } from "mdbreact";
import CustomHeader from "../CustomHeader";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { getIRPHandling, deleteCheckOut } from '../../api/CheckOutService'
import { cognitoSignOut } from "../../api/CognitoServices";
import { useNavigate } from "react-router-dom";
import { ConsoleError } from "../../utils/ErrorUtils";
import { useSelector } from "react-redux";
import Pagination from "../../components/Pagination";
import { CustomAlert } from "../../components/customAlert";
import Swal from "sweetalert2";
import { ERROR_MESSAGE } from "../../constants/MessagesConstants"



function IRPData() {
    let navigate = useNavigate();

    const [saveLoader, setSaveLoader] = useState(false);
    const [irpView, setIRPView] = useState([]);
    const [noRecordsFound, setNoRecordsFound] = useState();
    const [pages, setPages] = useState();
    const [initial, setInitial] = useState(0);
    const [rowCount, setRowCount] = useState(10);
    const [end, setEnd] = useState(3);
    // eslint-disable-next-line
    const [filteredData, setFilteredData] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
   

    const currentUser = useSelector(
        (reduxData) => reduxData?.userReducer?.value?.userDetails
    );


    const setRowsPage = (e) => {
        setRowCount(e);
        setSaveLoader(true);
        getIRPHandling(pageNumber,
            e, currentUser?.jwtToken)
            .then((response) => {
                if (response.status === 401) {
                    cognitoSignOut()
                        .then(() => {
                            navigate("/");
                        })
                        .catch((error) => {
                            ConsoleError(error);
                        });
                } else if (response?.status === 200) {
                    setSaveLoader(false);
                    let count = response?.data.count;
                    let c = Math.ceil(count / e);
                    setPages([...Array(c)].map((_, i) => i + 1));
                    let data = response?.data.check_out_list;
                    setIRPView(
                        filteredData !== null && filteredData !== undefined
                            ? filteredData.filterData
                            : data
                    );
                }
            })
            .catch((err) => { });
    };


    const onNextClick = () => {
        setInitial(initial + 3);
        setEnd(end + 3);
    };

    const onPreviousNext = () => {
        setInitial(initial - 3);
        setEnd(end - 3);
    };

    const displayAlert = (text, type, title) => {
        let confirmButtonText = "OK";
        let showCancelButton = false;
        let target = document.getElementById("swal-div");
        CustomAlert(title, text, type, confirmButtonText, showCancelButton, target);
    };

    const onClickDeleteItem = (checkOutId) => {
        CustomAlert(
            "Warning",
            "Are you sure want to delete",
            "warning",
            "Yes",
            true,
            "No",
            document.getElementById("swal-div")
        ).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: "Processing, please wait",
                    html: "loading...",
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Swal.showLoading();
                    },
                });
                deleteCheckOut(checkOutId, currentUser?.jwtToken)
                    .then((response) => {
                        Swal.close();
                        if (response.status === 401) {
                            cognitoSignOut()
                                .then(() => {
                                    navigate("/");
                                })
                                .catch((error) => {
                                    ConsoleError(error);
                                });
                        } else if (response.status === 200) {
                            if (response?.status === 200) {
                                displayAlert(
                                    "Check Out deleted successfully",
                                    "success",
                                    "Deleted"
                                );
                                getIRPCallMethod();
                            } else {
                                displayAlert(
                                    "Check Out Not Found",
                                    "fail",
                                    "Failed"
                                );
                            }
                        } 
                    })
                    .catch((error) => {
                        ConsoleError(error);
                        Swal.close();
                        displayAlert(ERROR_MESSAGE, "fail", "Failed");
                    });
            }
        })
    }



    const onPageClick = (item) => {
        setSaveLoader(true);
        let page = item;
        setPageNumber(page);
        getIRPHandling(pageNumber,
            rowCount, currentUser?.jwtToken).then((response) => {
                if (response.status === 401) {
                    cognitoSignOut()
                        .then(() => {
                            navigate("/");
                        })
                        .catch((error) => {
                            ConsoleError(error);
                        });
                } else if (response.status === 200) {
                    setSaveLoader(false);

                    let data = response?.data.check_out_list;
                    setIRPView(
                        filteredData !== null && filteredData !== undefined
                            ? filteredData.filterData
                            : data
                    );
                }
            })
            .catch((err) => {
                setIRPView([]);
            });
    };

    const getIRPCallMethod = () => {
        getIRPHandling(pageNumber,
            rowCount, currentUser?.jwtToken).then((response) => {
                if (response.status === 401) {
                    cognitoSignOut()
                        .then(() => {
                            navigate("/");
                        })
                        .catch((error) => {
                            ConsoleError(error);
                        });
                } else if (response.status === 200) {
                    setSaveLoader(false);
                    let count = response?.data.pagination?.total_records;
                    if (response?.status === 204 || count < 1) {
                        setNoRecordsFound("No records available");
                    }
                    let c = Math.ceil(count / rowCount);
                    setPages([...Array(c)].map((_, i) => i + 1));
                    let data = response?.data.check_out_list;
                    setIRPView(filteredData ? filteredData.filterData : data);

                }
            })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setSaveLoader(true);
        getIRPCallMethod();
    }, [])


    useEffect(() => {
        setSaveLoader(true);
        setSaveLoader(false);
        setIRPView(filteredData?.filterData);
        if (filteredData?.filterData?.length < 1) {
            setNoRecordsFound("No  records available");
        } else {
            setNoRecordsFound("");
        }
        //eslint-disable-next-line
    }, [filteredData]);

    return (
        <MDBCard className="card overflow-hidden h-md-100 table-box">
            <CustomHeader label={"IRP RECORDS"} />
            <MDBCardBody>
                <div className="table-responsive">
                    <table className="table safeChiefTable">
                        <thead className="headerArea">
                            <tr>
                                <th scope="col" className="tableHeading">
                                    TAMPER EVIDENT BAG #
                                </th>
                                <th scope="col" className="tableHeading">
                                    SAFE DEPOSIT BOX
                                </th>
                                <th scope="col" className="tableHeading">
                                    OWNER'S NAME
                                </th>
                                <th scope="col" className="tableHeading">
                                    BRANCH DID
                                </th>
                                <th scope="col" className="tableHeading">
                                    CHECKOUT TYPE
                                </th>
                                <th scope="col" className="tableHeading">
                                    ENTITY NAME
                                </th>
                                <th scope="col" className="tableHeading">
                                    PERFORMED BY
                                </th>
                                <th scope="col" className="tableHeading">
                                    STATUS
                                </th>
                                <th scope="col" className="tableHeading">
                                    ACTION
                                </th>



                            </tr>
                        </thead>

                        <tbody id="cs-scroll">
                            {saveLoader ? (
                                <div className="text-center py-5 alignself-center">
                                    <Spinner animation="border" />
                                </div>
                            ) : (
                                <>
                                    {irpView !== null &&
                                        irpView !== undefined &&
                                        irpView.map((item, index) => {
                                            return (

                                                <tr className="table-row" key={index}
                                                    
                                                >

                                                    <td className="tableDetails" onClick={(e) =>
                                                        
                                                   navigate("/adminViewIRPTEBHistory", { state: item })
                                               }>
                                                        {
                                                            item.tamper_evident_bag
                                                                ?.tamper_evident_bag_number
                                                        }
                                                    </td>

                                                    <td className="tableDetails">
                                                        {
                                                            item.tamper_evident_bag
                                                                ?.safe_deposit_box_number
                                                        }
                                                    </td>

                                                    <td className="tableDetails">
                                                        {
                                                            item.tamper_evident_bag
                                                                ?.owner_name
                                                        }
                                                    </td>

                                                    <td className="tableDetails">
                                                        {
                                                            item.tamper_evident_bag
                                                                ?.branch_did
                                                        }
                                                    </td>

                                                    <td className="tableDetails">
                                                        {item?.action?.action}
                                                    </td>
                                                    <td className="tableDetails shipmentBody5">
                                                        {item?.entity[0]?.entity_name}
                                                    </td>
                                                    <td className="tableDetails shipmentBody5">
                                                        {item?.performed_by?.employee_name}
                                                    </td>
                                                    <td className="tableDetails shipmentBody5">
                                                        {item?.status.status}
                                                    </td>
                                                    <td className="tableDetails">
                                                        <i
                                                            className="fa fa-2x fa-trash mt-2"
                                                            aria-hidden="true"
                                                            onClick={() => {
                                                                onClickDeleteItem(item.check_out_id);
                                                            }}
                                                        ></i>
                                                    </td>

                                                </tr>
                                            );
                                        })}
                                    <div className="text-center py-5 alignself-center">
                                        {noRecordsFound}
                                    </div>
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            </MDBCardBody>
            <Pagination
                pages={pages}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousNext}
                onPageClick={onPageClick}
                initial={initial}
                end={end}
                setRowsPerPage={setRowsPage}
            />
        </MDBCard>
    )
}


export default IRPData;
