import { MDBCard, MDBCardBody } from "mdbreact";
import SearchPanel from "../components/SearchPanel";
//eslint-disable-next-line
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { getTamperEvidentBags } from "../api/CheckInServices";
import { saveAuditRecord } from "../api/RecordAuditService";
import { CustomAlert } from "../components/customAlert";
import CustomHeader from "../components/CustomHeader";
import DocumentHorizontal from "../components/DocumentHorizontal";
import PerformedBy from "../components/PerformedBy";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { WebcamCapture } from "../components/webcamComponent";
//eslint-disable-next-line
import {
  checkAuditEligibilityService,
  systemSuggestedTeb,
  editAuditDetails,
} from "../api/ViewAuditService";
import Alert from "react-bootstrap/Alert";
import "../style/audit.css";
import {
  ERROR_MESSAGE,
  //eslint-disable-next-line
  AUDIT_ELIGIBILITY_MESSAGE_P1,
  //eslint-disable-next-line
  AUDIT_ELIGIBILITY_MESSAGE_P2,
} from "../constants/MessagesConstants";
import { getUpdatedDateInUtc } from "../utils/DateUtils";
import {
  AUDIT_TEB_LIMIT,
  SAVE_AUDIT_REQUEST,
  EDIT_FORM_VALUES,
} from "../constants/AuditConstants";
import { useTranslation } from "react-i18next";
import { ConsoleError } from "../utils/ErrorUtils";
import uuid from "react-uuid";
//eslint-disable-next-line
import { getActionList } from "../api/ActionService";
import { getDateConfigureData } from "../api/ConfigureDatesService";
import { getStorageListOnsearch } from "../api/StorageListService";
import { prepareOwnerData } from "../utils/AuditUtils";
import { getEditFieldsModule } from "../api/EditFieldService";
import { formatFieldName } from "../utils/ProductConfiguration";
import { MODULES } from "../constants/Constants";

function RecordAudit(props) {
  let navigate = useNavigate();
  const { state } = useLocation();
  let data = {};
  const initialValues = {
    safeDepositBox: "",
    tamperEviBagId: "",
    actionDate: "",
    actionTypes: "",
    notes: "",
    description: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  //eslint-disable-next-line
  const [isAuditEgibile, setIsAuditEgibile] = useState(false);
  const [isAuditEgibileWarning, setIsAuditEgibileWarning] = useState(false);
  //eslint-disable-next-line
  const [auditEligibleMessage, setAuditEligibleMessage] = useState("");
  const { t } = useTranslation();
  const [tebOptionList, setTebOptionList] = useState([]);
  const [tebList, setTebList] = useState([]);
  const [showTebOptions, setShowTebOptions] = useState(true);
  const [tebSearchString, setTebSearchString] = useState("");
  const [isTebNameAssigned, setTebNameAssigned] = useState(false);
  const [tebId, setTebId] = useState("");
  const [tebSelectionType, setTebSelectionType] = useState("Choose your own");
  const [isModelEnabled, setIsModelEnabled] = useState(false);
  const [storageType, setStorageType] = useState("");
  const [storageName, setStorageName] = useState("");
  // const [setStorageName] = useState("");
  const [storageTypeList, setStorageTypeList] = useState([]);
  const [searchString, setSearchString] = useState([]);
  const [storageList, setStorageList] = useState([]);
  const [storageOptionList, setStorageOptionList] = useState([]);
  const [showOptions, setShowOptions] = useState(true);
  const [isStorageNameAssigned, setStorageNameAssigned] = useState(false);
  const [formLoad, setFormLoad] = useState(false);
  const [storageId, setStorageId] = useState("");
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  //eslint-disable-next-line
  const [pictureList, setPictureList] = useState([]);
  const cancelButton = (e) => {
    navigate("/auditView");
  };
  const [actionTypeList, setActionTypeList] = useState([]);
  const [tebDetails, setTebDetails] = useState([]);
  const [tebDocumentList, setTebDocumentList] = useState([]);
  //eslint-disable-next-line
  const [formData, setFormData] = useState({
    step3: {
      Documentrows: [],
      filename: "",
      comments: "",
    },
  });

  const [isEdit, setIsEdit] = useState(false);
  const [editFormvalue, setEditFormValues] = useState(EDIT_FORM_VALUES);
  const [isEditing, setIsEditing] = useState(false);

  const [minActionDate, setMinActionDate] = useState();
  const [maxActionDate, setMaxActionDate] = useState();
  const [editFieldsVisibility, setEditFieldsVisibility] = useState();

  const fetchDateFieldData = async (selectedField) => {
    getDateConfigureData(
      selectedField,
      MODULES.AUDIT,
      currentUser?.jwtToken
    ).then((response) => {
      if (response?.status === 200) {
        setMinActionDate(getUpdatedDateInUtc(-response?.data?.min_value));
        setMaxActionDate(getUpdatedDateInUtc(response?.data?.max_value));
      }
    });
  };

  const fetchEditFields = async () => {
    getEditFieldsModule(MODULES.AUDIT, currentUser?.jwtToken)
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response?.status === 200) {
          const data = response?.data || [];
          // Check if any `display_flag` is true
          const hasVisibleFields = data.some((field) => field.display_flag);
          // Set visibility state based on the presence of at least one visible field
          setEditFieldsVisibility(hasVisibleFields ? data: []);
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };

  function loadActionType() {
    getActionList("Activity", currentUser?.jwtToken)
      .then((response) => {
        setActionTypeList(
          response?.map(function (actionTypeRow) {
            return actionTypeRow;
          })
        );
      })
      .catch((error) => {
        ConsoleError(error);
      });
  }

  function getTebList() {
    getTamperEvidentBags(
      "audit",
      tebSearchString,
      1,
      AUDIT_TEB_LIMIT,
      currentUser?.jwtToken
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((err) => {
              console.error(err);
            });
        } else if (response?.status === 200) {
          setTebList(response?.data);
          setTebOptionList(
            response?.data?.map(function (row) {
              return row?.tamper_evident_bag_number
                ? row?.tamper_evident_bag_number
                : "";
            })
          );
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  }

  useEffect(() => {
    if (!isTebNameAssigned && tebSearchString.length > 0) {
      getTebList();
    }
    //eslint-disable-next-line
  }, [tebSearchString]);
  useEffect(() => {
    if (!isStorageNameAssigned && searchString.length > 0) {
      getStorageList();
    }
    //eslint-disable-next-line
  }, [searchString]);

  useEffect(() => {
    getActionList("StorageType", currentUser?.jwtToken)
      .then((response) => {
        setStorageTypeList(
          response?.map(function (storageTypeRow) {
            return storageTypeRow;
          })
        );
      })
      .catch((error) => {
        ConsoleError(error);
      });

    if (state) {
      setEditFormValues(state?.owner);
      setTebSearchString(state?.auditDetails?.teb);
      setIsEditing(true);
      setIsEdit(true);
      setTebNameAssigned(true);
      setShowTebOptions(false);
      setIsAuditEgibile(true);
      setFormValues((prevData) => {
        return {
          ...prevData,
          actionDate: state?.auditDetails?.actionDate,
          audit_notes: state?.auditDetails?.notes,
        };
      });
    }
  }, [currentUser?.jwtToken]);

  const onCapturePicture = (picture, itemId) => {
    let addedImageList = [...pictureList, { src: picture, typeId: itemId }];
    setPictureList(addedImageList);
    setIsModelEnabled(false);
  };

  const OnDeletePicture = (index, itemId) => {
    let shallowCopyImageList = Array.from(pictureList);
    if (index > -1) {
      shallowCopyImageList.splice(index, 1);
    }
    setPictureList(shallowCopyImageList);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (formErrors[name] !== undefined && formErrors[name].length > 0) {
      delete formErrors[name];
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = validate(formValues);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setIsSubmit(true);
      Swal.fire({
        title: "Processing, please wait",
        html: "loading...",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      data = SAVE_AUDIT_REQUEST;
      data.tamper_evident_bags = [tebId];
      data.audit_date = formValues.actionDate;
      data.notes = formValues.audit_notes;
      data.action = "Audit";
      data.documents = documentList;
      data.pictures = pictureList;
      data.performed_by_id = currentUser?.employee_id;
      data = { ...data, editdetails: { ...editFormvalue }, edited: isEdit };
      saveAuditRecord(JSON.stringify(data), currentUser?.jwtToken)
        .then((response) => {
          Swal.fire({
            title: "Processing, please wait",
            html: "loading...",
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          if (response.status === 401) {
            cognitoSignOut()
              .then(() => {
                navigate("/");
              })
              .catch((error) => {
                ConsoleError(error);
              });
          } else if (response?.status === 201) {
            Swal.close();
            showSuccessAlert("Audit saved successfully");
          } else if (response?.status === 400) {
            Swal.close();
            showFailAlert("Create Audit failed due to invalid data");
          } else {
            Swal.close();
            showFailAlert(
              "Create Audit failed, please upload document with description"
            );
          }
        })
        .catch((error) => {
          Swal.close();
          showFailAlert(ERROR_MESSAGE);
          ConsoleError(error);
        });
    }
  };
  const showSuccessAlert = (response) => {
    let title = "Saved";
    let text = response;
    let type = "success";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      target
    ).then((result) => {
      if (result.value) {
        navigate("/auditView");
      }
    });
  };
  const showFailAlert = (response) => {
    let title = "Error";
    let text = response;
    let type = "error";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let target = document.getElementById("swal-div");
    CustomAlert(title, text, type, confirmButtonText, showCancelButton, target);
  };
  const validate = (values) => {
    const errors = {};
    if (!isTebNameAssigned && tebSelectionType === "Choose your own") {
      errors.tamperEviBagId = t("labels.teb") + " is required";
    }
    if (!values.actionDate) {
      errors.actionDate = "Action Date is required";
    }
    // if (!values.actionTypes) {
    //   errors.actionTypes = "Action Type is required";
    // }
    if (!values.audit_notes) {
      errors.audit_notes = "Note is required";
    }
    return errors;
  };
  const setBranchDid = (branch_did) => {
    setFormValues({ ...formValues, to_branch_did: branch_did });
  };

  function getStorageList() {
    getStorageListOnsearch(storageType, searchString, currentUser?.jwtToken)
      .then((response) => {
        setStorageList(response);
        setStorageOptionList(
          response?.map(function (branchRow) {
            return (
              (branchRow.name ? branchRow.name : "") +
              (branchRow.branch_did ? " - " + branchRow.branch_did : "")
            );
          })
        );
      })
      .catch((error) => {
        ConsoleError(error);
      });
  }

  const checkAuditEligibility = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsAuditEgibileWarning(false);
    if (isTebNameAssigned) {
      Swal.fire({
        title: "Processing, please wait",
        html: "loading...",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      checkAuditEligibilityService(
        currentUser?.employee_id,
        tebId,
        currentUser?.jwtToken
      )
        .then((response) => {
          Swal.close();
          if (response.status === 200) {
            if (!response?.data.is_eligible) {
              setIsAuditEgibileWarning(true);
              setAuditEligibleMessage(
                AUDIT_ELIGIBILITY_MESSAGE_P1 +
                  tebSearchString +
                  AUDIT_ELIGIBILITY_MESSAGE_P2
              );
            } else {
              setIsAuditEgibile(true);
              setFormErrors("");
              setTebDetails(response?.data?.safe_deposit_box);
              setTebDocumentList(
                response?.data?.addendum_a_b.length > 0
                  ? response?.data?.addendum_a_b[0]?.documents
                  : response?.data?.addendum_c[0]?.documents
              );
              assignEditValues(response?.data?.safe_deposit_box?.owner);
            }
          }
        })
        .catch((error) => {
          Swal.close();
          showFailAlert(ERROR_MESSAGE);
        });
    }
  };

  const assignEditValues = (data) => {
    setEditFormValues(prepareOwnerData(data));
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors, isSubmit]);
  useEffect(() => {
    loadActionType();
    fetchDateFieldData("Action Date");
    fetchEditFields();
    //eslint-disable-next-line
  }, []);
  /**
   * Function to handle updates the comments made to the document
   */
  // editFormvalue?.address_id !== "" &&
  // &&editFormvalue?.scra!==""&&editFormvalue?.scra!==undefined
  // && editFormvalue?.owner_fields_id !=="" && editFormvalue?.owner_id !==""
  const submitUpdateBtnStatus = () => {
    const {
      city,
      company_name,
      dormancy_id,
      first_name,
      last_name,
      owner_property_type_code,
      owner_relation_code,
      state,
      street,
      zipcode,
    } = editFormvalue || {};

    return !(
      city &&
      company_name &&
      dormancy_id &&
      first_name &&
      last_name &&
      owner_property_type_code &&
      owner_relation_code &&
      state &&
      street &&
      zipcode
    );
  };

  const handleUpdateDocumentComment = (documentId, value) => {
    let shallowCopyDocumentList = Array.from(documentList);
    const docIndex = shallowCopyDocumentList.findIndex(
      (doc) => doc.documentId === documentId
    );
    shallowCopyDocumentList[docIndex].comments = value;
    setDocumentList(shallowCopyDocumentList);
  };

  /**
   * Function to read files & set to props
   */
  const handleAddDocument = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        const docSrcArray = reader.result.split(";")[0];
        let val = document.getElementById("input_document");
        let file = val.files.item(0).name;
        const documentItem = {
          documentId: uuid(),
          comments: "",
          src: reader.result,
          docType: docSrcArray,
          fileName: file,
        };
        handleNewDocument(documentItem);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  /**
   * Function to handle new documents
   */
  const handleNewDocument = (document) => {
    let addedDocumentList = [...documentList, document];
    setDocumentList(addedDocumentList);
  };
  /**
   * Function to handle delete documents
   */
  const handleDeleteDocument = (documentId) => {
    let shallowCopyDocList = Array.from(documentList);
    const docIndex = shallowCopyDocList.findIndex(
      (doc) => doc.documentId === documentId
    );

    if (docIndex > -1) {
      shallowCopyDocList.splice(docIndex, 1);
    }
    setDocumentList(shallowCopyDocList);
  };

  const download = (link, name) => {
    saveAs(link, name);
  };
  const handleOptionChange = (event) => {
    setFormErrors("");
    setIsAuditEgibileWarning(false);
    setTebSearchString("");

    if (event === "System suggested") {
      Swal.fire({
        title: "Processing, please wait",
        html: "loading...",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      setTebNameAssigned(false);
      systemSuggestedTeb(
        currentUser?.employee_id,
        storageId,
        storageType,
        currentUser?.jwtToken
      )
        .then((response) => {
          Swal.close();
          if (response.status === 401) {
            cognitoSignOut()
              .then(() => {
                navigate("/");
              })
              .catch((err) => {
                console.error(err);
              });
          } else if (
            response?.data?.message === "No TEB is available for Audit"
          ) {
            CustomAlert(
              "Bags Not Found",
              response?.data?.message,
              "warning",
              "OK",
              false,
              document.getElementById("swal-div")
            );
            setFormLoad(false);
            setTebSelectionType("System suggested");
            setStorageNameAssigned(false);
          } else if (
            response !== undefined &&
            (response.status === 201 || response.status === 200)
          ) {
            setFormValues({
              ...formValues,
              suggested_teb: response?.data?.tamper_evident_bag_number,
            });
            setTebId(response?.data?.tamper_evident_bag_id);
            setFormLoad(true);
            setTebDetails(response?.data?.safe_deposit_box);
            setTebDocumentList(
              response?.data?.addendum_a_b.length > 0
                ? response?.data?.addendum_a_b[0]?.documents
                : response?.data?.addendum_c[0]?.documents
            );
            assignEditValues(response?.data?.safe_deposit_box?.owner);
          } else {
            let type = "fail";
            let text = "Failed to provide System Suggested TEB ";
            showFailAlert(text, type);
          }
        })
        .catch((error) => {
          Swal.close();
          ConsoleError(error);
          let type = "fail";
          let text = "Failed to provide System Suggested TEB";
          showFailAlert(text, type);
        });
    } else {
      setIsAuditEgibile(false);
    }
  };
  const submitNewDetails = async () => {
    Swal.fire({
      title: "Processing, please wait",
      html: "loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    let payload = {
      ...editFormvalue,
      notes: formValues.audit_notes,
      audit_id: state?.auditDetails?.audit_id,
      performed_by_id: currentUser?.employee_id,
    };
    editAuditDetails(payload, currentUser?.jwtToken).then((response) => {
      Swal.close();
      if (response?.data?.status === "SUCCESS") {
        CustomAlert(
          "Updated",
          response?.data?.message,
          "success",
          "OK",
          false,
          document.getElementById("swal-div")
        ).then((result) => {
          if (result.value) {
            navigate("/auditView");
          }
        });
      } else {
        CustomAlert(
          "Failed",
          "Failed To Update the Values",
          "error",
          "OK",
          false,
          document.getElementById("swal-div")
        );
      }
    });
  };

  const handleEditDetailsChange = (key, newValue) => {
    setEditFormValues((prevData) => {
      return {
        ...prevData,
        [key]: newValue,
      };
    });
  };

  const handleStorageTypeChange = (e) => {
    setStorageName("");
    setStorageList([]);
    setSearchString("");
    setFormLoad(false);
    setStorageOptionList([]);
    setStorageNameAssigned(false);
    setStorageId("");
    setIsAuditEgibile(false);
    setTebSearchString("");
    setTebNameAssigned(false);
    setFormLoad(false);
  };

  const handleStorageNameChange = (e) => {
    setStorageName("");
    setStorageList([]);
    setSearchString("");
    setFormLoad(false);
    setStorageOptionList([]);
    setStorageNameAssigned(false);
    setStorageId("");
    setIsAuditEgibile(false);
    setTebSearchString("");
    setTebNameAssigned(false);
    setFormLoad(false);
  };
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-auto p-0 body">
      <div className="row flex pl-4 pt-4 pr-4 pb-0">
        <div className="col-lg-12 pb-3 pl-0 pr-0">
          <div className="row pt-1 pb-2 mt-5">
            <div className="d-flex justify-content-end">
              <div className="p-0">
                <Link to="/auditView">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    id="checkin-records-button"
                  >
                    AUDIT RECORDS
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <MDBCard className="card p-0 m-0">
            <CustomHeader label={" AUDIT"} />
            <MDBCardBody id="cs-scroll">
              <form>
                <div className="row mt-1 card-border">
                  <div className="row col-lg-12 justify-content-center pt-2 pl-5">
                    AUDIT DETAILS
                  </div>
                  {isAuditEgibileWarning ? (
                    <>
                      <div className="col-lg-7 mx-auto row align-items-center">
                        <Alert
                          show={isAuditEgibileWarning}
                          variant="warning"
                          className="justify-content-center d-flex "
                        >
                          <div className="font-weight-bold">
                            {auditEligibleMessage}
                          </div>
                          <button
                            type="button"
                            className="btn-close float-right ml-5"
                            aria-label="Close"
                            onClick={() => setIsAuditEgibileWarning(false)}
                          ></button>
                        </Alert>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="form-row">
                    <div className="form-group col-md-8 col-xs-4  col-lg-4 px-4">
                      <label htmlFor="inlineRadioActivityTypeForceOpen">
                        Activity Type{" "}
                      </label>
                      <div className="form-radio form-radio-inline form-row">
                        <div className="col-md-6 form-group">
                          <input
                            className="form-radio-input"
                            type="radio"
                            name="inlineRadioActivityTypeForceOpen"
                            id="inlineRadioActivityTypeForceOpen"
                            value="Choose your own"
                            disabled={isEditing}
                            onClick={() => {
                              setTebSelectionType("Choose your own");
                            }}
                            onChange={(e) => {
                              setTebSelectionType(e.target.value);
                              setStorageName("");
                              setStorageList([]);
                              setStorageType("");
                              setSearchString("");
                              setFormLoad(false);
                              setStorageOptionList([]);
                              setStorageNameAssigned(false);
                              setStorageId("");
                              setIsAuditEgibile(false);
                              setTebSearchString("");
                              setTebNameAssigned(false);
                              setFormLoad(false);
                            }}
                            checked={tebSelectionType === "Choose your own"}
                          />
                          <label
                            className="form-check-label pl-2"
                            htmlFor="inlineRadioActivityTypeForceOpen"
                          >
                            Choose your own
                          </label>
                        </div>
                        <div className="col-md-6 form-group">
                          <input
                            className="form-radio-input"
                            type="radio"
                            name="inlineRadioActivityTypeShipContent"
                            id="inlineRadioActivityTypeShipContent"
                            value="System suggested"
                            disabled={isEditing}
                            onClick={() => {
                              setTebSelectionType("System suggested");
                            }}
                            onChange={(e) => {
                              setTebSelectionType(e.target.value);
                              setStorageName("");
                              setStorageList([]);
                              setStorageType("");
                              setSearchString("");
                              setFormLoad(false);
                              setStorageOptionList([]);
                              setStorageNameAssigned(false);
                              setStorageId("");
                              setIsAuditEgibile(false);
                              setTebSearchString("");
                              setTebNameAssigned(false);
                              setFormLoad(false);
                            }}
                            checked={tebSelectionType === "System suggested"}
                          />
                          <label
                            className="form-check-label pl-2"
                            htmlFor="inlineRadioActivityTypeShipContent"
                          >
                            System suggested
                          </label>
                        </div>
                      </div>
                    </div>
                    {((!isAuditEgibile &&
                      tebSelectionType === "Choose your own") ||
                      (isAuditEgibile &&
                        tebSelectionType === "Choose your own")) && (
                      <>
                        <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                          <SearchPanel
                            label={t("labels.teb")}
                            placeholder="Search by TEB Number"
                            options={tebOptionList}
                            setOptionList={setTebOptionList}
                            setShowOptions={setShowTebOptions}
                            name="teb_id"
                            id="teb_id"
                            showOptions={showTebOptions}
                            onFocusSearch={(e) => {
                              setTebId("");
                              setTebSearchString("");
                              setIsAuditEgibile(false);
                              setIsEdit(false);
                              setIsEditing(false);
                              setTebNameAssigned(false);
                            }}
                            handleChange={(e) => {
                              if (isTebNameAssigned) {
                                setTebSearchString(
                                  e.target.value.charAt(e.length - 1)
                                );
                                setTebNameAssigned(false);
                                setShowTebOptions(true);
                              } else {
                                setTebSearchString(e.target.value);
                              }
                              setShowTebOptions(true);
                            }}
                            searchString={tebSearchString}
                            onSelectValue={(selectedTeb) => {
                              setTebNameAssigned(true);
                              setShowTebOptions(false);
                              const teb = tebList.filter(
                                (row) =>
                                  row?.tamper_evident_bag_number === selectedTeb
                              )[0];
                              setTebSearchString(
                                teb?.tamper_evident_bag_number
                              );
                              setTebId(teb?.tamper_evident_bag_id);
                              if (
                                formErrors["teb_id"] !== undefined &&
                                formErrors["teb_id"].length > 0
                              ) {
                                delete formErrors["teb_id"];
                              }
                            }}
                          />

                          <i
                            className="fa fa-barcode float-right pr-2 input-scanner-icon h2"
                            aria-hidden="true"
                            title="Scan Tamper Evident Bag"
                          ></i>

                          <div className="error-message-validation">
                            {formErrors.teb_id}
                          </div>
                        </div>
                        {!isAuditEgibile && (
                          <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4 mt-3">
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={checkAuditEligibility}
                            >
                              Check Eligibility
                            </button>
                          </div>
                        )}
                      </>
                    )}
                    {tebSelectionType !== "Choose your own" && (
                      <>
                        <div className="form-group col-md-4 col-xs-4  col-lg-2 px-4">
                          <label htmlFor="storage_type">
                            Choose Storage Type:
                          </label>
                          <select
                            name="storage_type"
                            className="custom-select mr-sm-2"
                            id="storage_type"
                            onChange={(e) => {
                              setStorageType(
                                e.target.value === "Branch"
                                  ? "branch"
                                  : e.target.value === "Vault"
                                  ? "vault"
                                  : "warehouse"
                              );
                              handleStorageTypeChange();
                            }}
                          >
                            <option
                              className="form-group col-md-1 col-xs-4  col-lg-1 p-0 "
                              value=""
                              disabled
                              selected
                            >
                              Choose...
                            </option>
                            {storageTypeList?.map((opt, index) => {
                              return (
                                <option key={index} value={opt.action}>
                                  {opt.action}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </>
                    )}
                    {tebSelectionType !== "Choose your own" &&
                      storageType.length > 0 && (
                        <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                          <SearchPanel
                            label={"Storage Name"}
                            placeholder={"Storage Name"}
                            options={storageOptionList}
                            setOptionList={setStorageOptionList}
                            setShowOptions={setShowOptions}
                            name="storage_location_id"
                            id="storage_location_id"
                            showOptions={showOptions}
                            onFocusSearch={(e) => {
                              setSearchString("");
                              setBranchDid("");
                              handleStorageNameChange();
                            }}
                            handleChange={(e) => {
                              if (isStorageNameAssigned) {
                                setSearchString(
                                  e.target.value.charAt(e.length - 1)
                                );
                                setStorageNameAssigned(false);
                              } else {
                                setSearchString(e.target.value);
                              }
                              setShowOptions(true);
                            }}
                            searchString={searchString}
                            onSelectValue={(selectedStorage) => {
                              setStorageNameAssigned(true);
                              setShowOptions(false);
                              if (storageType === "branch") {
                                const branch = storageList.filter(
                                  (branchRow) =>
                                    branchRow?.branch_did ===
                                    selectedStorage.split(" - ")[1]
                                )[0];
                                setSearchString(branch.name);
                                setStorageId(branch.branch_id);
                                setBranchDid(branch?.branch_did);
                              } else if (storageType === "vault") {
                                const vault = storageList.filter(
                                  (vaultRow) =>
                                    vaultRow?.name === selectedStorage
                                )[0];
                                setSearchString(vault.name);
                                setStorageId(vault.vault_id);
                              } else {
                                const warehouse = storageList.filter(
                                  (warehouseRow) =>
                                    warehouseRow?.name === selectedStorage
                                )[0];
                                setSearchString(warehouse.name);
                                setStorageId(warehouse.warehouse_id);
                              }
                              if (
                                formErrors["storage_location_id"] !==
                                  undefined &&
                                formErrors["storage_location_id"].length > 0
                              ) {
                                delete formErrors["storage_location_id"];
                              }
                            }}
                          />

                          <div className="error-message-validation">
                            {formErrors.storage_location_id}
                          </div>
                        </div>
                      )}
                    {tebSelectionType !== "Choose your own" &&
                      storageType.length > 0 && (
                        <div className="form-group col-md-4 col-xs-4 col-lg-2 mt-3">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={() =>
                              handleOptionChange("System suggested")
                            }
                            disabled={storageId.length < 1 ? true : false}
                          >
                            Get TEB
                          </button>
                        </div>
                      )}
                    {formLoad && (
                      <>
                        <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                          <label htmlFor="suggested_teb">TEB</label>
                          <input
                            type="text"
                            className="form-control"
                            id="suggested_teb"
                            name="suggested_teb"
                            disabled
                            autoComplete="off"
                            value={formValues.suggested_teb}
                          />
                        </div>
                      </>
                    )}
                    {(formLoad || isAuditEgibile) && (
                      <>
                        <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                          <label htmlFor="actionDate">Action Date</label>
                          <input
                            type="date"
                            className="form-control"
                            id="date"
                            placeholder="mm/dd/yyyy"
                            autoComplete="off"
                            name="actionDate"
                            value={formValues.actionDate}
                            onChange={handleChange}
                            min={minActionDate}
                            max={maxActionDate}
                            disabled={isEditing}
                          />
                          <div className="error-message-validation">
                            {formErrors.actionDate}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {(tebSelectionType === "System suggested" && formLoad) ||
                  isAuditEgibile ? (
                    <>
                      <div className="form-row">
                        <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                          <label htmlFor="audit_notes">Notes</label>
                          <textarea
                            className="form-control"
                            rows="5"
                            name="audit_notes"
                            id="audit_notes"
                            placeholder="Enter Comments"
                            value={formValues.audit_notes}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </div>
                        {!isEditing && (
                          <>
                            <div
                              className="form-group col-md-6 col-xs-4  col-lg-2 px-4"
                              id={`document_group_1`}
                            >
                              <div className="row" id={`document_group_row`}>
                                <div
                                  className="col-lg-12 col-md-12 col-sm-12"
                                  id={`document_group_row_col`}
                                >
                                  <input
                                    type="file"
                                    id={`input_document`}
                                    multiple
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                      handleAddDocument(e);
                                    }}
                                    onClick={(event) => {
                                      event.target.value = null;
                                    }}
                                  ></input>

                                  <label
                                    htmlFor={`input_document`}
                                    className="btn btn-primary btn-sm mt-3"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {" "}
                                    <i
                                      id={`icon_upload`}
                                      className="fa fa-upload fa-1x me-1"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Document
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-md-6 col-xs-4  col-lg-2 px-4">
                              <button
                                type="button"
                                className="btn btn-primary btn-sm mt-3"
                                onClick={() => {
                                  setIsModelEnabled(true);
                                }}
                              >
                                <i
                                  className="fa fa-camera me-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                Picture
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="error-message-validation ml-3">
                        {formErrors.audit_notes}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {(tebSelectionType === "System suggested" && formLoad) ||
                isAuditEgibile ? (
                  <>
                    <div className="row col-lg-12 justify-content-center">
                      Owner Info
                    </div>
                    <div className="row card-border">
                      {!isEdit && (
                        <>
                          <div className="form-group row">
                            <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                              Owner{" "}
                            </label>
                            <div className="col-sm-2 p-0">
                              <div className="float-start">
                                {(
                                  editFormvalue?.first_name +
                                  " " +
                                  editFormvalue?.last_name
                                ).length > 1
                                  ? editFormvalue?.first_name +
                                    " " +
                                    editFormvalue?.last_name
                                  : "Mystrey User"}
                              </div>
                            </div>
                            {editFieldsVisibility?.length > 0 && <div
                              className="col-lg-6"
                              style={{ marginTop: "-10px" }}
                            >
                              <button
                                className="btn btn-primary btn-sm float-start"
                                onClick={() => setIsEdit(true)}
                              >
                                Edit
                              </button>
                            </div>}
                          </div>
                          <div className="form-group row">
                            <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                              Address{" "}
                            </label>
                            <div className="col-sm-2 p-0">
                              <div className="float-start">
                                {editFormvalue?.street +
                                  " " +
                                  editFormvalue?.city}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {isEdit && (
                        <>
                          {editFieldsVisibility &&
                            editFieldsVisibility.find((item) => {
                              return item?.field_name === "scra";
                            }).display_flag && (
                              <div>
                                <div className="form-check col-md-6 col-xs-4  col-lg-4 px-4">
                                  <input
                                    type="checkbox"
                                    id="owner_service_member"
                                    autoComplete="off"
                                    name="owner_service_member"
                                    onChange={(e) =>
                                      handleEditDetailsChange(
                                        "scra",
                                        e.target.checked
                                      )
                                    }
                                    checked={editFormvalue?.scra}
                                  />
                                  <label
                                    className="form-check-label pl-3"
                                    htmlFor="owner_service_member"
                                  >
                                    Service Member
                                  </label>
                                </div>
                              </div>
                            )}
                          <div className="form-row">
                            {editFieldsVisibility &&
                              editFieldsVisibility.map((field) => {
                                return field?.field_name !== "scra" ? (
                                  <>
                                    {field?.display_flag && (
                                      <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                                        <label htmlFor="ownerFirstName">
                                          {formatFieldName(field.field_name)}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="ownerFirstName"
                                          autoComplete="off"
                                          value={
                                            editFormvalue[field.field_name]
                                          }
                                          onChange={(e) => {
                                            handleEditDetailsChange(
                                              field.field_name,
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                );
                              })}
                          </div>
                        </>
                      )}
                      {tebDocumentList?.length > 0 && (
                        <div className="form-group row">
                          <label className=" d-flex col-sm-4 col-form-label input-label p-0">
                            Documents{" "}
                          </label>
                          <div className="col-sm-8 p-0">
                            {tebDocumentList?.filter(
                              (document) => document?.type === "DOCUMENT"
                            ).length > 0 && (
                              <div className="row ">
                                <div className="col-8 fw-bold d-flex pl-3">
                                  <span className="float-start">
                                    Uploaded Document Name
                                  </span>
                                </div>
                                <div className="col-4 fw-bold">Description</div>
                              </div>
                            )}
                            {tebDocumentList
                              ?.filter(
                                (document) => document?.type === "DOCUMENT"
                              )
                              .map((filteredDocument) => (
                                <div className="row">
                                  <div className="col-8 d-flex">
                                    <span
                                      className="cursor-pointer float-start"
                                      onClick={() =>
                                        download(
                                          filteredDocument?.url,
                                          filteredDocument?.name
                                        )
                                      }
                                    >
                                      {filteredDocument?.name}
                                      <i className="fa fa-download pl-2" />
                                    </span>
                                  </div>
                                  <div className="col-4 ">
                                    {filteredDocument?.description}
                                  </div>
                                </div>
                              ))}
                            {tebDocumentList?.filter(
                              (document) => document?.type === "PICTURE"
                            ).length > 0 && (
                              <div className="row pt-3">
                                <div className="col-8 fw-bold d-flex pl-3">
                                  <span className="float-start">
                                    Added Picture Name
                                  </span>
                                </div>
                              </div>
                            )}
                            {tebDocumentList
                              ?.filter(
                                (document) => document?.type === "PICTURE"
                              )
                              .map((filteredPicture) => (
                                <div className="row">
                                  <div className="col-8 d-flex">
                                    <span
                                      className="cursor-pointer float-start"
                                      onClick={() =>
                                        download(
                                          filteredPicture?.url,
                                          filteredPicture?.name
                                        )
                                      }
                                    >
                                      {filteredPicture?.name}
                                      <i className="fa fa-download pl-2" />
                                    </span>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                    {documentList.length > 0 && (
                      <>
                        <div className="row col-lg-12 justify-content-center">
                          DOCUMENTS
                        </div>
                        <div className="row card-border">
                          <DocumentHorizontal
                            itemId="audit"
                            id={`documents_horizontal`}
                            documentList={documentList}
                            onDeleteDocument={handleDeleteDocument}
                            onUpdateDocComment={handleUpdateDocumentComment}
                          />
                        </div>
                      </>
                    )}
                    {pictureList.length > 0 && (
                      <div className="row col-lg-12 justify-content-center">
                        PICTURES
                      </div>
                    )}
                    <div
                      className={
                        pictureList.length > 0 ? "row card-border" : ""
                      }
                    >
                      <div className="form-row align-items-end">
                        <div className="form-group col-12 px-4">
                          <WebcamCapture
                            itemId="audit"
                            pictureList={pictureList}
                            onCapturePicture={(picture, itemId) =>
                              onCapturePicture(picture, itemId)
                            }
                            onDeletePicture={(index, itemId) =>
                              OnDeletePicture(index, itemId)
                            }
                            isModelEnabled={isModelEnabled}
                            disableModal={() => {
                              setIsModelEnabled(false);
                            }}
                          ></WebcamCapture>
                        </div>
                      </div>
                    </div>
                    <PerformedBy />
                    <div id="submitButtonAreaRecordAudit">
                      <button
                        onClick={cancelButton}
                        className="btn  btn-sm btn-primary"
                        type="reset"
                      >
                        CANCEL
                      </button>
                      {isEditing ? (
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={submitNewDetails}
                          type="button"
                          disabled={submitUpdateBtnStatus()}
                        >
                          UPDATE
                        </button>
                      ) : (
                        <button
                          type="file"
                          className="btn btn-sm btn-primary"
                          onClick={handleSubmit}
                        >
                          SUBMIT
                        </button>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </form>
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </div>
  );
}
export default RecordAudit;
