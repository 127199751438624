import { useState } from "react";
import * as XLSX from "xlsx";
import { CustomAlert } from "../../components/customAlert";
import CustomModal from "../../components/CustomModal";
import DocumentUpload from "../../components/documentUpload";
import Inputupload from "../../components/Inputupload";
import "../../style/BulkCheckin.css";

const StepOne = (props) => {
  const [currentEventData, setCurrentEventData] = useState({
    EventType: "Alert",
    EventData: "",
  });
  const [showMessage, setShowMessage] = useState(false);
  const [showData, setShowData] = useState([{}])
  const EXTENSIONS = ["xlsx", "xls", "csv"];

  const getExention = (file) => {
    const parts = file.name.split(".");
    const extension = parts[parts.length - 1];
    return EXTENSIONS.includes(extension);
  };
  const [fileName, setFileName] = useState(" File Name ");
  const importExcel = async (e) => {
    let file = e.target.files[0];
    setFileName(e.target.files[0].name);
    let Headers = [];
    if (file) {
      if (getExention(file)) {
        if (file.size > 10e6) {
          setCurrentEventData({
            EventType: "Alert",
            EventData: "Please upload a file smaller than 10 MB",
          });
          setShowMessage(true);
        }
      } else {
        setCurrentEventData({
          EventType: "Alert",
          EventData: "Invalid file input, Select Excel, CSV file",
        });
        setShowMessage(true);
      }
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);
          let headers = XLSX.utils.sheet_to_json(ws, { header: 1 });
          Headers = headers[0];
          resolve(data);
          props.onChange("step1", "fileuploadrows", data);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
      promise.then((d) => {
        let keysFromFile = Headers;
        if (keysFromFile.indexOf("SAFE DEPOSITE BOX #") > -1 &&
          keysFromFile.indexOf("TAMPER EVIDENT BAG #")>-1 &&
          keysFromFile.indexOf("OWNERS NAME")>-1)  {
            setShowData(d);
          }
            else
           {   setShowData([]);
          let text = "Missing fields, file upload failed ";
          let confirmButtonText = "Ok";
          let showConfirmButton = false;
          let title = "Oops ! ";
          let type = "warning";
          CustomAlert(title, text, type, confirmButtonText, showConfirmButton);}
        props.onChange("step1", "fileuploadrows", d);
      });
    }
  };

  return (
    <>
      <CustomModal
        currentEventData={currentEventData}
        show={showMessage}
        onHide={() => setShowMessage(false)}
      />
      <div className="divUpload">
        <DocumentUpload onChange={importExcel} fileName={fileName} />
      </div>
      {props.formData?.step1.fileuploadrows?.length && showData.length ? (
        <div className="table_wrapper" >
          <div id="vscroll">
            <table className="tableData">
              <tr key={"header"} id="heading" >
                {Object.keys(showData[0]).map((key) => (
                  <th >{key}</th>
                ))}
              </tr>
              {showData.map((item) => (
                <tr key={item.id}>
                  {Object.values(item).map((val) => (
                    <td>{val}</td>
                  ))}
                </tr>
              ))}
            </table>
          </div>

        </div>
      ) : (
        <div className="d-flex row align-items-center justify-content-center vertical-middle text-center divTableMain">
          <Inputupload onChange={importExcel} label="Upload Document" />
        </div>
      )}

    </>
  );
};

export default StepOne;
