import React, { useEffect, useState } from "react";
import { MDBCard, MDBCardBody } from "mdbreact";
import CustomHeader from "../CustomHeader";
import Spinner from "react-bootstrap/Spinner";
import { cognitoSignOut } from "../../api/CognitoServices";
import { useNavigate } from "react-router-dom";
import { ConsoleError } from "../../utils/ErrorUtils";
import { useSelector } from "react-redux";
import { CustomAlert } from "../../components/customAlert";
import Swal from "sweetalert2";
import {
  getEditFieldsModule,
  updateFieldsConfiguration,
} from "../../api/EditFieldService";
import { formatFieldName } from "../../utils/ProductConfiguration";
import { MODULES } from "../../constants/Constants";

function ConfigureEditFields() {
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  let navigate = useNavigate();
  const [moduleName, setModueleName] = useState(MODULES.AUDIT);
  const [moduleData, setModuleData] = useState();
  const [formData, setFormData] = useState();

  useEffect(() => {
    //Fetch the fields configureation based on the module name
    getFields();
  }, [moduleName]);

  const getFields = () => {
    getEditFieldsModule(moduleName, currentUser?.jwtToken)
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response?.status === 200) {
          setModuleData(response?.data);
          setFormData(response?.data);
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };

  const handleChange = (moduleName, newDisplayFlag) => {
    //handle the data change
    setFormData((prevData) =>
      prevData.map((item) => {
        if (item.field_name === moduleName) {
          return {
            ...item,
            display_flag: newDisplayFlag,
          };
        }
        return item;
      })
    );
  };

  const generateData = () => {
    //generating the payload of the fields with differentiated values
    let result = [];
    for (let i = 0; i < moduleData.length; i++) {
      let data = moduleData[i];
      let item = formData.find(
        (value) =>
          value?.fields_configuration_id === data?.fields_configuration_id
      );
      if (data.display_flag !== item.display_flag) {
        result.push(item);
      }
    }
    return result;
  };

  const submitData = async () => {
    //Submitting the changes and rendering the message according to status
    Swal.fire({
      title: "Processing, please wait",
      html: "loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    let payload = generateData();
    updateFieldsConfiguration(payload, currentUser?.jwtToken).then(
      (response) => {
        Swal.close();
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response?.data?.message === "success") {
          CustomAlert(
            "Updated",
            "Configurations has been saved.",
            "success",
            "OK",
            false,
            document.getElementById("swal-div")
          ).then(() => {
            getFields();
          });
        } else {
          CustomAlert(
            "Failed",
            "Configurations are failed to update.",
            "error",
            "OK",
            false,
            document.getElementById("swal-div")
          );
        }
      }
    );
  };

  return (
    <div className="row white-background pt-3">
      <div className="card-border mx-2" style={{ borderRadius: "10px" }}>
        <div className="form-row">
          {formData &&
            formData?.map((field, index) => {
              return (
                // rendering the fields for each data field fetched
                <div className="form-group col-lg-6" key={"group" + index}>
                  <div className="form-row">
                    <div className="form-group col-md-6 col-xs-4  col-lg-3 ps-5 pe-0 mt-4">
                      <label className="me-2">Module:</label>
                      <span htmlFor="from_storage_type">
                        {field?.module_name}
                      </span>
                    </div>
                    <div className="form-group col-md-6 col-xs-4  col-lg-3 px-4 mt-4">
                      <label htmlFor="from_storage_type">
                        {formatFieldName(field?.field_name)}:
                      </label>
                    </div>
                    <div className="form-group col-md-6 col-xs-4  col-lg-1 pe-4 mt-4">
                      <input
                        type="checkbox"
                        checked={field?.display_flag}
                        onChange={(e) => {
                          handleChange(field?.field_name, e.target.checked);
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="justify-content-center col-md-6 col-lg-12 col-sm-12 mb-4">
        <button
          className="btn btn-primary btn-sm float-right"
          disabled={JSON.stringify(moduleData) === JSON.stringify(formData)}
          onClick={() => submitData()}
        >
          Submit
        </button>
        <button
          className="btn btn-primary btn-sm float-right"
          onClick={() => {
            //reset the data to the original state
            setFormData(moduleData);
          }}
        >
          Reset
        </button>
      </div>
    </div>
  );
}

export default ConfigureEditFields;
