import { MDBCard, MDBCardBody } from "mdbreact";
import { useEffect, useState } from "react";
import SearchPanel from "../components/SearchPanel";
import { Link, useNavigate } from "react-router-dom";
import { saveCheckOut } from "../api/SingleCheckOutService";
import { CustomAlert } from "../components/customAlert";
import CustomHeader from "../components/CustomHeader";
import DocumentHorizontal from "../components/DocumentHorizontal";

import PerformedBy from "../components/PerformedBy";
import { WebcamCapture } from "../components/webcamComponent";
import Swal from "sweetalert2";
import { formatPhoneNumber } from "../components/common/Utils";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";
import { getTamperEvidentBags } from "../api/CheckInServices";
import "../style/CheckOut.css";
import ConfigData from "../config";

import { regexEmail, regexPhone, regexZipCode } from "../constants/Regex";
import { useTranslation } from "react-i18next";

import { ConsoleError } from "../utils/ErrorUtils";
import {
  CHECKOUT_TEB_LIMIT,
  SAVE_CHECKOUT_REQUEST,
} from "../constants/CheckOutConstants";
import uuid from "react-uuid";
import { getEmployeesOnsearch } from "../api/EmployeeService";
import { SearchSelectedListTable } from "../components/SearchSelectedListTable";
import { getAuthorizationDataModule } from "../api/AuthorizationConfigurationService";
import { MODULES } from "../constants/Constants";
import { getActionList } from "../api/ActionService";
import Select from 'react-select';
import { fetchStorageOptions } from "../utils/StorageManagement";


function CreateCheckOut() {
  const [formHeader, setformHeader] = useState("CUSTOMER DETAILS");
  //eslint-disable-next-line
  const [topRightBtn, setTopRightBtn] = useState(false);
  //eslint-disable-next-line
  const [storageLoc, setStorageLoc] = useState();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  let navigate = useNavigate();
  const { t } = useTranslation();
  const initialValues = {
    name: "",
    zip: "",
    address: "",
    phone: "",
    email: "",
    notes: "",
    contents: "",
  };
  const [isModelEnabled, setIsModelEnabled] = useState(false);

  const [documentList, setDocumentList] = useState([]);
  const [pictureList, setPictureList] = useState([]);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  //eslint-disable-next-line
  const [isSubmit, setIsSubmit] = useState(false);
  //eslint-disable-next-line
  const [showLoader, setShowLoader] = useState(false);

  const [radio, setRadio] = useState("Handing over to Customer");
  const [tebOptionList, setTebOptionList] = useState([]);
  const [tebList, setTebList] = useState([]);
  const [showTebOptions, setShowTebOptions] = useState(true);
  const [tebSearchString, setTebSearchString] = useState("");
  const [isTebNameAssigned, setTebNameAssigned] = useState(false);

  const [employeeOptionList, setEmployeeOptionList] = useState([]);

  const [employeeList, setEmployeeList] = useState([]);

  const [authorizerShowOptions, setAuthorizerShowOptions] = useState(true);
  const [authorizerSearchString, setAuthorizerSearchString] = useState("");
  const [isAuthorizerAssigned, setIsAuthorizerAssigned] = useState(false);
  const [selectedTebList, setSelectedTebList] = useState([]);
  const [selectedAuthorizerList, setSelectedAuthorizerList] = useState([]);
  const [isAuthorization, setIsAuthorization] = useState();

  const [storageTypeList, setStorageTypeList] = useState([]);
  const [storageList, setStorageList] = useState([]);
  const [storageLocation, setStorageLocation]= useState([]);
  const [storageType, setStorageType] = useState("");
  const [page, setPage] = useState(1);



  

  const onClickDeleteTEB = (item, index) => {
    const newSelectedTebList = [...selectedTebList]; // make a copy of the original array
    newSelectedTebList.splice(index, 1); // remove the element at the specified index
    setSelectedTebList(newSelectedTebList);
  };

  const onClickDeleteAuthorizer = (item, index) => {
    const newSelectedAuthorizerList = [...selectedAuthorizerList]; // make a copy of the original array
    newSelectedAuthorizerList.splice(index, 1); // remove the element at the specified index
    setSelectedAuthorizerList(newSelectedAuthorizerList);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      let formattedPhoneNumber = formatPhoneNumber(e.target.value);
      setFormValues({ ...formValues, [name]: formattedPhoneNumber });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    if (formErrors[name] !== undefined && formErrors[name].length > 0) {
      delete formErrors[name];
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowLoader(true);
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length === 0) {
      Swal.fire({
        title: "Processing, please wait",
        html: "loading...",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      setIsSubmit(true);
      let checkout_data = SAVE_CHECKOUT_REQUEST;
      if (radio !== "Destroy Contents") {
        checkout_data.tamper_evident_bags = selectedTebList.map(
          (teb) => teb.tamper_evident_bag_id
        );
        checkout_data.activity_type = radio;
        checkout_data.performed_by_id = currentUser?.employee_id;
        checkout_data.contents = formValues.contents ? formValues.contents : "";
        checkout_data.entity_name = formValues.name ? formValues.name : "";
        checkout_data.phone_number = formValues.phone ? formValues.phone : "";
        checkout_data.email_id = formValues.email ? formValues.email : "";
        checkout_data.address = formValues.address ? formValues.address : "";
        checkout_data.zipcode = formValues.zip ? formValues.zip : "";
        checkout_data.authorizer_id = selectedAuthorizerList.map(
          (authorizer) => authorizer.employee_id
        );
        checkout_data.notes = "";
        checkout_data.pictures = pictureList;
        checkout_data.documents = documentList;
      } else {
        checkout_data.tamper_evident_bags = selectedTebList.map(
          (teb) => teb.tamper_evident_bag_id
        );
        checkout_data.activity_type = "Destroy Contents";
        checkout_data.performed_by_id = currentUser?.employee_id;
        checkout_data.contents = formValues.contents ? formValues.contents : "";
        checkout_data.authorizer_id = selectedAuthorizerList.map(
          (authorizer) => authorizer.employee_id
        );
        checkout_data.notes = "";
        checkout_data.pictures = pictureList;
        checkout_data.documents = documentList;
      }
      saveCheckOut(checkout_data, currentUser?.jwtToken)
        .then((response) => {
          if (response.status === 401) {
            cognitoSignOut()
              .then(() => {
                navigate("/");
              })
              .catch((err) => {
                console.error(err);
              });
          } else if (response?.status === 201) {
            Swal.close();
            showSuccessAlert();
          } else {
            Swal.close();
            showFailAlert();
          }
        })
        .catch((error) => {
          Swal.close();
          showFailAlert();
        });
    }
  };

  const onCapturePicture = (picture, itemId) => {
    let addedImageList = [...pictureList, { src: picture, typeId: itemId }];
    setPictureList(addedImageList);
    setIsModelEnabled(false);
  };

  const OnDeletePicture = (index, itemId) => {
    let shallowCopyImageList = Array.from(pictureList);
    if (index > -1) {
      shallowCopyImageList.splice(index, 1);
    }
    setPictureList(shallowCopyImageList);
  };

  const showSuccessAlert = () => {
    let title = "Saved";
    let text = "Check out saved successfully";
    let type = "success";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    CustomAlert(title, text, type, confirmButtonText, showCancelButton).then(
      (result) => {
        if (result.value) {
          navigate("/viewAllCheckout");
        }
      }
    );
  };
  const showFailAlert = () => {
    let title = "Error";
    let text = "Check out data is not saved";
    let type = "error";
    let confirmButtonText = "OK";
    CustomAlert(title, text, type, confirmButtonText).then((result) => {
      if (result.value) {
        navigate("/createcheckout");
      }
    });
  };
  const validate = (values) => {
    const errors = {};
    const authoriser_error = "Please select an Authorizer";
    const tamper_evident_bag_error = t("labels.teb") + " is required";

    // Updated regex for email, name, and address to include `.`
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Updated for valid email format
    const regexName = /^[a-zA-Z\s.'-]+$/; // Updated to include `.`
    const regexAddress = /^[a-zA-Z0-9\s.,'-]+$/; // Updated to include `.`

    if (radio !== "Destroy Contents") {
      if (!values.email) {
        let emailErrorText = "Required";
        errors.email = emailErrorText;
      }
      if (values.email && !regexEmail.test(values.email)) {
        let emailErrorText = "Please provide valid email";
        errors.email = emailErrorText;
      }
      if (!values.name) {
        let nameErrorText = "Name is required";
        errors.name = nameErrorText;
      } else if (values.name.trim().length < 2) {
        let nameErrorText = "Name should be at least 2 characters long";
        errors.name = nameErrorText;
      } else if (!regexName.test(values.name.trim())) {
        let nameErrorText =
          "Name should only contain letters, spaces, dots, hyphens, and apostrophes";
        errors.name = nameErrorText;
      }
      if (!values.phone) {
        let phoneErrorText = "Required";
        errors.phone = phoneErrorText;
      }
      if (values.phone && !regexPhone.test(values.phone)) {
        let phoneErrorText = "Please provide valid phone no";
        errors.phone = phoneErrorText;
      }
      if (!values.zip) {
        let zipErrorText = "Required";
        errors.zip = zipErrorText;
      }
      if (values.zip && !regexZipCode.test(values.zip)) {
        let zipErrorText = "Please provide valid zip code";
        errors.zip = zipErrorText;
      }
      if (!values.address) {
        let addressErrorText = "Address is required";
        errors.address = addressErrorText;
      } else if (values.address.trim().length < 5) {
        let addressErrorText = "Address should be at least 5 characters long";
        errors.address = addressErrorText;
      } else if (!regexAddress.test(values.address.trim())) {
        let addressErrorText =
          "Address should only contain letters, numbers, spaces, dots, commas, apostrophes, and hyphens";
        errors.address = addressErrorText;
      }
      if (isAuthorization && selectedAuthorizerList.length < 1) {
        errors.authoriser = authoriser_error;
      }

      if (selectedTebList.length < 1) {
        errors.teb_id = tamper_evident_bag_error;
      }
    } else {
      if (selectedTebList.length < 1) {
        errors.selectedTamperBag = tamper_evident_bag_error;
      }
      if (!values.contents) {
        errors.contents = "Please enter contents";
      }
      if (!values.notes) {
        errors.notes = "Please enter notes";
      }
      if (isAuthorization && selectedAuthorizerList.length < 1) {
        errors.authoriser = authoriser_error;
      }
    }
    return errors;
  };
  const inputArr = [
    {
      type: "text",
      id: 1,
      value: "",
    },
  ];
  //eslint-disable-next-line
  const [arr, setArr] = useState(inputArr);
  //eslint-disable-next-line
  const addInput = () => {
    setArr((s) => {
      return [
        ...s,
        {
          type: "text",
          value: "",
        },
      ];
    });
  };
  //eslint-disable-next-line
  const handleTextChange = (e) => {
    e.preventDefault();
    const index = e.target.id;
    setArr((s) => {
      const newArr = s.slice();
      newArr[index].value = e.target.value;
      return newArr;
    });
  };

  function getTebList() {
    getTamperEvidentBags(
      "check_out",
      tebSearchString,
      1,
      CHECKOUT_TEB_LIMIT,
      currentUser?.jwtToken,
      storageLocation?.value,
      storageLocation?.storageType,
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((error) => {
              ConsoleError(error);
            });
        } else if (response?.status === 200) {
          setTebList(response?.data);

          setTebOptionList(
            response?.data
              ?.filter(
                (row) =>
                  !selectedTebList.some(
                    (selectedTeb) =>
                      selectedTeb.tamper_evident_bag_id ===
                      row.tamper_evident_bag_id
                  )
              )
              .map(function (teb) {
                return teb?.tamper_evident_bag_number;
              })
          );
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  }

  const getEmployeesBySearch = () => {
    const approvalAccessFlag = true;
    const authorizerAcessFlag = null;
    const auditAccessFlag = null;
    getEmployeesOnsearch(
      approvalAccessFlag,
      authorizerAcessFlag,
      auditAccessFlag,
      authorizerSearchString,
      currentUser?.jwtToken
    )
      .then((response) => {
        response = response?.data;
        setEmployeeList(response);
        setEmployeeOptionList(
          response
            .filter(
              (employee) => employee.employee_id !== currentUser?.employee_id
            )
            .filter(
              (row) =>
                !selectedAuthorizerList.some(
                  (authorizer) => authorizer.employee_id === row.employee_id
                )
            )
            .map(function (emp) {
              return (
                (emp.employee_name ? emp.employee_name : "") +
                (emp.employee_email ? " - " + emp.employee_email : "") +
                (emp.employee_org_id ? " - " + emp.employee_org_id : "")
              );
            })
        );
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };

  useEffect(() => {
    if (!isAuthorizerAssigned && authorizerSearchString.length > 0) {
      getEmployeesBySearch();
    }
    //eslint-disable-next-line
  }, [authorizerSearchString]);

  useEffect(() => {
    if (!isTebNameAssigned && tebSearchString.length > 0) {
      getTebList();
    }
    //eslint-disable-next-line
  }, [tebSearchString]);

  useEffect(() => {
    getAuthorizationFlag();
  }, []);

  /**
   * Function to handle updates the comments made to the document
   */
  const handleUpdateDocumentComment = (documentId, value) => {
    let shallowCopyDocumentList = Array.from(documentList);
    const docIndex = shallowCopyDocumentList.findIndex(
      (doc) => doc.documentId === documentId
    );
    shallowCopyDocumentList[docIndex].comments = value;
    setDocumentList(shallowCopyDocumentList);
  };

  /**
   * Function to read files & set to props
   */
  const handleAddDocument = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        const docSrcArray = reader.result.split(";")[0];
        let val = document.getElementById("input_document");
        let file = val.files.item(0).name;
        const documentItem = {
          documentId: uuid(),
          comments: "",
          src: reader.result,
          docType: docSrcArray,
          fileName: file,
        };
        handleNewDocument(documentItem);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  /**
   * Function to handle new documents
   */
  const handleNewDocument = (document) => {
    let addedDocumentList = [...documentList, document];
    setDocumentList(addedDocumentList);
  };
  /**
   * Function to handle delete documents
   */
  const handleDeleteDocument = (documentId) => {
    let shallowCopyDocList = Array.from(documentList);
    const docIndex = shallowCopyDocList.findIndex(
      (doc) => doc.documentId === documentId
    );

    if (docIndex > -1) {
      shallowCopyDocList.splice(docIndex, 1);
    }
    setDocumentList(shallowCopyDocList);
  };
  const getAuthorizationFlag = async () => {
    getAuthorizationDataModule(MODULES.CHECK_OUT, currentUser?.jwtToken).then(
      (response) => {
        if (response?.status === 200) {
          setIsAuthorization(response?.data?.authorization_flag);
          if (!response?.data?.authorization_flag) {
            setSelectedAuthorizerList([
              { employee_id: response?.data?.authorization?.employee_id },
            ]);
          }
        }
      }
    );
  };

  const handleTebSearch = (searchValue) => {
    const hasMatchingOption = storageList.some(option =>
      option?.name.toLowerCase().includes(searchValue?.toLowerCase())
    );

    if (!hasMatchingOption && searchValue?.trim() !== '') {      
      fetchStorageoptionsOnScroll(searchValue);
    }
  };


  const removeTebBags = (selectedOption) =>{
    let text = "Selected TamperEvidentBags will be cleared!";
    let confirmButtonText = "OK";
    let cancelButtonText = "Cancel";
    let showCancelButton = true;
    let title = "";
    let type = "warning";
    let target = document.getElementById("swal-div")
    console.log("object")
    if(storageLocation && selectedTebList.length>0){
  
      CustomAlert(
        title,
        text,
        type,
        confirmButtonText,
        showCancelButton,
        cancelButtonText,
        target
      ).then((result) => {
        if (result.value) {
          setSelectedTebList([]);
          setStorageLocation(selectedOption)
        }
      });
    }
    else{
      setStorageLocation(selectedOption)
    }
  
   }
   const fetchStorageoptionsOnScroll = async(searchValue) => {
  
      const response = await fetchStorageOptions("", page, storageList, storageType, currentUser?.jwtToken, storageType);
      
        setStorageList((prevList) => [...prevList, ...response["locations"]]);
        
        setPage(response["UpdatedpageNo"]);
        
   }
  
  
  
    useEffect(() => {
      if(storageType){
  
        fetchStorageoptionsOnScroll("")
      }
    }, [storageType]);


  useEffect(() => {
    getActionList("StorageType", currentUser?.jwtToken)
      .then((response) => {
        setStorageTypeList(
          response?.map(function (storageTypeRow) {
            return storageTypeRow;
          })
        );
      })
      .catch((error) => {
        ConsoleError(error);
      });
    // getAuthorizationFlag();
  }, [currentUser?.jwtToken]);

  function destroyContForm() {
    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className="row col-lg-12 justify-content-center">
            CHECK-OUT DETAILS
          </div>
          <div className="row card-border">
            <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
              <SearchPanel
                label={t("labels.teb")}
                placeholder="Search by TEB Number"
                options={tebOptionList}
                setOptionList={setTebOptionList}
                setShowOptions={setShowTebOptions}
                name="teb_id"
                id="teb_id"
                showOptions={showTebOptions}
                onFocusSearch={(e) => {
                  setTebSearchString("");
                }}
                handleChange={(e) => {
                  if (e.target.value.length > 0) {
                    setShowTebOptions(true);
                    if (isTebNameAssigned) {
                      setTebSearchString(e.target.value.charAt(e.length - 1));
                      setTebNameAssigned(false);
                    } else {
                      setTebSearchString(e.target.value);
                    }
                    setShowTebOptions(true);
                  } else {
                    setTebSearchString(e.target.value.charAt(e.length - 1));
                    setShowTebOptions(false);
                  }
                }}
                searchString={tebSearchString}
                onSelectValue={(selectedTeb) => {
                  setTebNameAssigned(true);
                  setShowTebOptions(false);
                  const teb = tebList.filter(
                    (row) => row?.tamper_evident_bag_number === selectedTeb
                  )[0];
                  setTebSearchString("");
                  setSelectedTebList([teb, ...selectedTebList]);
                  if (
                    formErrors["selectedTamperBag"] !== undefined &&
                    formErrors["selectedTamperBag"].length > 0
                  ) {
                    delete formErrors["selectedTamperBag"];
                  }
                }}
              />
              <i
                className="fa fa-barcode float-right pr-2 input-scanner-icon h2"
                aria-hidden="true"
                title="Scan Tamper Evident Bag"
              ></i>
              <div className="error-message-validation errorMessage">
                {formErrors.selectedTamperBag}
              </div>
            </div>
            {selectedTebList.length > 0 && (
              <SearchSelectedListTable
                label={"TAMPER EVIDENT BAGS"}
                headers={ConfigData.viewSelectedTEBDetails}
                recordsList={selectedTebList}
                responseKeys={ConfigData.viewTEBListResponseKeys}
                onClickDeleteItem={(teb, idx) => onClickDeleteTEB(teb, idx)}
              />
            )}
          </div>
          <div className="row card-border">
            <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
              <label className="col-sm-4" id="uploadDocTextCheckOut">
                Contents
              </label>
              <div className="col-sm-8 notesArea">
                <textarea
                  type="text"
                  className="formcontrol notesSmScreen"
                  cols="40"
                  rows="10"
                  name="contents"
                  placeholder="Enter contents"
                  value={formValues.contents}
                  onChange={handleInputChange}
                />
                <div className="error-message-validation">
                  {formErrors.contents}
                </div>
              </div>
            </div>
            <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
              <label className="col-sm-4" id="uploadDocTextCheckOut2">
                Notes
              </label>
              <div className="col-sm-8 notesArea">
                <textarea
                  type="text"
                  className="formcontrol notesSmScreen"
                  cols="40"
                  rows="10"
                  name="notes"
                  value={formValues.notes}
                  placeholder="Enter notes"
                  onChange={handleInputChange}
                />
                <div className="error-message-validation">
                  {formErrors.notes}
                </div>
              </div>
            </div>
          </div>
          <div className="row card-border">
            {isAuthorization && (
              <>
                <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                  <SearchPanel
                    label="Authorizer Assigned"
                    placeholder="Search by Employee Name or Email"
                    options={employeeOptionList}
                    setOptionList={setEmployeeOptionList}
                    setShowOptions={setAuthorizerShowOptions}
                    showOptions={authorizerShowOptions}
                    onFocusSearch={(e) => {
                      setAuthorizerSearchString("");
                    }}
                    handleChange={(str) => {
                      if (str.target.value.length > 0) {
                        setAuthorizerShowOptions(true);
                        if (isAuthorizerAssigned) {
                          setAuthorizerSearchString(
                            str.target.value.charAt(str.length - 1)
                          );
                          setIsAuthorizerAssigned(false);
                        } else {
                          setAuthorizerSearchString(str.target.value);
                        }
                      } else {
                        setAuthorizerSearchString(
                          str.target.value.charAt(str.length - 1)
                        );
                        setAuthorizerShowOptions(false);
                      }
                      if (
                        formErrors["authoriser"] !== undefined &&
                        formErrors["authoriser"].length > 0
                      ) {
                        delete formErrors["authoriser"];
                      }
                    }}
                    searchString={authorizerSearchString}
                    onSelectValue={(selectedAuthorizer) => {
                      setIsAuthorizerAssigned(true);
                      setAuthorizerShowOptions(false);
                      const authorizer = employeeList.filter(
                        (emp) =>
                          emp.employee_email ===
                          selectedAuthorizer.split(" - ")[1]
                      )[0];
                      setAuthorizerSearchString("");
                      setSelectedAuthorizerList([
                        authorizer,
                        ...selectedAuthorizerList,
                      ]);
                    }}
                  />
                  <div className="error-message-validation errorMessage">
                    {formErrors.authoriser}
                  </div>
                </div>
                {selectedAuthorizerList.length > 0 && (
                  <SearchSelectedListTable
                    label={"AUTHORIZERS"}
                    headers={ConfigData.viewSelectedAuthorizerDetails}
                    recordsList={selectedAuthorizerList}
                    responseKeys={ConfigData.viewAuthorizerListResponeKeys}
                    onClickDeleteItem={(authorizer, idx) =>
                      onClickDeleteAuthorizer(authorizer, idx)
                    }
                  />
                )}
              </>
            )}
            <div
              className="form-group col-md-6 col-xs-4  col-lg-2 px-4"
              id={`document_group_1`}
            >
              <div className="row" id={`document_group_row`}>
                <div
                  className="col-lg-12 col-md-12 col-sm-12"
                  id={`document_group_row_col`}
                >
                  <input
                    type="file"
                    id={`input_document`}
                    multiple
                    style={{ display: "none" }}
                    onChange={(e) => {
                      handleAddDocument(e);
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  ></input>

                  <label
                    htmlFor={`input_document`}
                    className="btn btn-primary btn-sm mt-3"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    <i
                      id={`icon_upload`}
                      className="fa fa-upload fa-1x me-1"
                      aria-hidden="true"
                    ></i>{" "}
                    Document
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group col-md-6 col-xs-4  col-lg-2 px-4">
              <label
                type="button"
                className="btn btn-primary btn-sm mt-3"
                onClick={() => {
                  setIsModelEnabled(true);
                }}
              >
                <i className="fa fa-camera me-1" aria-hidden="true"></i>Picture
              </label>
            </div>
          </div>
          {documentList.length > 0 && (
            <>
              <div className="row col-lg-12 justify-content-center">
                DOCUMENTS
              </div>
              <div className="row card-border">
                <DocumentHorizontal
                  itemId="checkout"
                  id={`documents_horizontal`}
                  documentList={documentList}
                  onDeleteDocument={handleDeleteDocument}
                  onUpdateDocComment={handleUpdateDocumentComment}
                />
              </div>
            </>
          )}
          {pictureList.length > 0 && (
            <div className="row col-lg-12 justify-content-center">PICTURES</div>
          )}
          <div className={pictureList.length > 0 ? "row card-border" : ""}>
            <div className="form-row align-items-end">
              <div className="form-group col-12 px-4">
                <WebcamCapture
                  itemId="checkout"
                  pictureList={pictureList}
                  onCapturePicture={(picture, itemId) =>
                    onCapturePicture(picture, itemId)
                  }
                  onDeletePicture={(index, itemId) =>
                    OnDeletePicture(index, itemId)
                  }
                  isModelEnabled={isModelEnabled}
                  disableModal={() => {
                    setIsModelEnabled(false);
                  }}
                ></WebcamCapture>
              </div>
            </div>
          </div>
          <PerformedBy />
          <div id="submitButtonAreaRecordAudit">
            <button
              className="btn  btn-sm btn-primary"
              id="cancel_checkout_history"
              type="reset"
              value="Reset"
              style={{ color: "#fff" }}
              onClick={() => {
                navigate("/ViewAllCheckout");
              }}
            >
              CANCEL
            </button>
            <button
              type="file"
              className="btn btn-sm btn-primary"
              id="submit_checkout"
              onClick={handleSubmit}
            >
              SUBMIT
            </button>
          </div>
        </form>
      </>
    );
  }
  function commonForm() {
    return (
      <>
        <form onSubmit={handleSubmit}>
          <div className="row col-lg-12 justify-content-center">
            {formHeader}
          </div>
          <div className="row card-border">
            <div className="form-group row">
              <label className="col-sm-4 col-form-label input-label">
                Name
              </label>
              <div className="col-sm-8 singleCheckOutEachInput">
                <input
                  type="text"
                  className="form-control-plaintext"
                  placeholder="Enter Name"
                  name="name"
                  value={formValues.name}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
                <div className="error-message-validation">
                  <p>{formErrors.name}</p>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label input-label">
                Phone Number
              </label>
              <div className="col-sm-8 singleCheckOutEachInput">
                <input
                  type="text"
                  className="form-control-plaintext"
                  placeholder="Enter Phone Number"
                  name="phone"
                  value={formValues.phone}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
                <div className="error-message-validation">
                  <p>{formErrors.phone}</p>
                </div>
              </div>
            </div>
            <div className="form-group row singleCheckOutEachInput">
              <label className="col-sm-4 col-form-label input-label">
                Email-Id
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control-plaintext"
                  placeholder="Enter Email-id"
                  name="email"
                  value={formValues.email}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
                <div className="error-message-validation">
                  <p>{formErrors.email}</p>
                </div>
              </div>
            </div>
            <div className="form-group row singleCheckOutEachInput">
              <label className="col-sm-4 col-form-label input-label">
                Address
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control-plaintext"
                  placeholder="Enter Address"
                  name="address"
                  value={formValues.address}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
                <div className="error-message-validation">
                  <p>{formErrors.address}</p>
                </div>
              </div>
            </div>
            <div className="form-group row singleCheckOutEachInput">
              <label className="col-sm-4 col-form-label input-label">
                Zip Code
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control-plaintext"
                  placeholder="Enter Zip Code"
                  name="zip"
                  value={formValues.zip}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
                <div className="error-message-validation">
                  <p>{formErrors.zip}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row col-lg-12 justify-content-center">
            CHECK-OUT DETAILS
          </div>
          <div className="row card-border">
          <form className="d-flex flex-row justify-content-between align-items-center">
                    <div className="form-group" style={{width:"30%"}}>
                      <label htmlFor="storageType" className="form-label">
                        Select Storage Type
                      </label>
                      <select
                        id="storageType"
                        className="form-select"
                        onChange={(e) => {
                          setStorageType(e.target.value);
                          setStorageList([]); // Clear storage list on type change
                          setStorageLocation({});
                        }}
                      >
                        <option value="">Select Storage Type</option>
                        {storageTypeList?.map((opt) => (
                          <option key={opt?.action_id} value={opt?.action_id}>
                            {opt?.action}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group" style={{width:"30%"}} >
                      <label htmlFor="storageLocation" className="form-label">
                        Select Storage Name
                      </label>
                      
                      <Select
                        isDisabled={storageList.length === 0}
                        id="storageLocation"
                        onInputChange={(e)=>{handleTebSearch(e)}}
                        value={storageLocation}
                        onChange={(selectedOption) => {removeTebBags(selectedOption);}}
                        options={storageList.map((record) => ({
                          value:
                            record?.branch_id || record?.Vault_id || record?.Warehouse_id, // Unique identifier
                            label: record?.name, // Display field
                            storageType: record?.storage_type,
                            branchDid: record?.branch_did || null,
                        }))}
                        isClearable
                        onMenuScrollToBottom={() => {fetchStorageoptionsOnScroll("")}} // Trigger fetch when scrolled to bottom
                        menuPlacement="auto"
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            maxHeight: "100px", // Set max height for scrollable dropdown
                            overflowY: "auto", // Enable scrolling
                          }),
                        }}
                        placeholder="Select Storage Location"
                      />
                    </div>
                    
                    <div className="form-group" style={{ width: "30%" }}>
                      {storageLocation?.branchDid !==  undefined && (
                        <>
                          <label htmlFor="branch_did" className="form-label">
                            Branch DID
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="branch_did"
                            name="branch_did"
                            disabled
                            autoComplete="off"
                            value={storageLocation?.branchDid}
                            placeholder="No Branch DID available"
                          />
                        </>
                      )} 
                    </div>

                  </form>
            <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
              <SearchPanel
                label={t("labels.teb")}
                placeholder="Search by TEB Number"
                options={tebOptionList}
                setOptionList={setTebOptionList}
                setShowOptions={setShowTebOptions}
                name="teb_id"
                id="teb_id"
                showOptions={showTebOptions}
                onFocusSearch={(e) => {
                  setTebSearchString("");
                }}
                handleChange={(e) => {
                  if(storageLocation?.value){
                    let errors = {teb_id: "" };
  
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          ...errors, // Add/overwrite errors
                        }));

                    if (e.target.value.length > 0) {
                      setShowTebOptions(true);
                      if (isTebNameAssigned) {
                        setTebSearchString(e.target.value.charAt(e.length - 1));
                        setTebNameAssigned(false);
                      } else {
                        setTebSearchString(e.target.value);
                      }
                      setShowTebOptions(true);
                    } else {
                      setTebSearchString(e.target.value.charAt(e.length - 1));
                      setShowTebOptions(false);
                    }
                  }
                  else{
                    let errors = {teb_id: "Please select the storage name." };
  
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          ...errors, // Add/overwrite errors
                        }));
                  }
                }}
                searchString={tebSearchString}
                onSelectValue={(selectedTeb) => {
                  setTebNameAssigned(true);
                  setShowTebOptions(false);
                  const teb = tebList.filter(
                    (row) => row?.tamper_evident_bag_number === selectedTeb
                  )[0];
                  setTebSearchString("");
                  setSelectedTebList([teb, ...selectedTebList]);
                  if (
                    formErrors["teb_id"] !== undefined &&
                    formErrors["teb_id"].length > 0
                  ) {
                    delete formErrors["teb_id"];
                  }
                }}
              />

              <i
                className="fa fa-barcode float-right pr-2 input-scanner-icon h2"
                aria-hidden="true"
                title="Scan Tamper Evident Bag"
              ></i>
              <div className="error-message-validation errorMessage">
                {formErrors.teb_id}
              </div>
            </div>
            {selectedTebList.length > 0 && (
              <SearchSelectedListTable
                label={"TAMPER EVIDENT BAGS"}
                headers={ConfigData.viewSelectedTEBDetails}
                recordsList={selectedTebList}
                responseKeys={ConfigData.viewTEBListResponseKeys}
                onClickDeleteItem={(teb, idx) => onClickDeleteTEB(teb, idx)}
              />
            )}
          </div>
          <div className="row card-border">
            {isAuthorization && (
              <>
                <div className="form-group col-md-6 col-xs-4  col-lg-4 px-4">
                  <SearchPanel
                    label="Authorizer Assigned"
                    placeholder="Search by Employee Name or Email"
                    options={employeeOptionList}
                    setOptionList={setEmployeeOptionList}
                    setShowOptions={setAuthorizerShowOptions}
                    showOptions={authorizerShowOptions}
                    onFocusSearch={(e) => {
                      setAuthorizerSearchString("");
                      setAuthorizerShowOptions(true);
                    }}
                    handleChange={(str) => {
                      if (str.target.value.length > 0) {
                        if (isAuthorizerAssigned) {
                          setAuthorizerSearchString(
                            str.target.value.charAt(str.length - 1)
                          );
                          setIsAuthorizerAssigned(false);
                          setAuthorizerShowOptions(true);
                        } else {
                          setAuthorizerSearchString(str.target.value);
                        }
                      } else {
                        setAuthorizerSearchString(
                          str.target.value.charAt(str.length - 1)
                        );
                        setAuthorizerShowOptions(false);
                      }
                      if (
                        formErrors["authoriser"] !== undefined &&
                        formErrors["authoriser"].length > 0
                      ) {
                        delete formErrors["authoriser"];
                      }
                    }}
                    searchString={authorizerSearchString}
                    onSelectValue={(selectedAuthorizer) => {
                      setIsAuthorizerAssigned(true);
                      setAuthorizerShowOptions(false);
                      const authorizer = employeeList.filter(
                        (emp) =>
                          emp.employee_email ===
                          selectedAuthorizer.split(" - ")[1]
                      )[0];
                      setAuthorizerSearchString("");
                      setSelectedAuthorizerList([
                        authorizer,
                        ...selectedAuthorizerList,
                      ]);
                    }}
                  />
                  <div className="error-message-validation errorMessage">
                    {formErrors.authoriser}
                  </div>
                </div>
                {selectedAuthorizerList.length > 0 && (
                  <SearchSelectedListTable
                    label={"AUTHORIZERS"}
                    headers={ConfigData.viewSelectedAuthorizerDetails}
                    recordsList={selectedAuthorizerList}
                    responseKeys={ConfigData.viewAuthorizerListResponeKeys}
                    onClickDeleteItem={(authorizer, idx) =>
                      onClickDeleteAuthorizer(authorizer, idx)
                    }
                  />
                )}
              </>
            )}
            <div
              className="form-group col-md-6 col-xs-4  col-lg-2 px-4"
              id={`document_group_1`}
            >
              <div className="row" id={`document_group_row`}>
                <div
                  className="col-lg-12 col-md-12 col-sm-12"
                  id={`document_group_row_col`}
                >
                  <input
                    type="file"
                    id={`input_document`}
                    multiple
                    style={{ display: "none" }}
                    onChange={(e) => {
                      handleAddDocument(e);
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                  ></input>

                  <label
                    htmlFor={`input_document`}
                    className="btn btn-primary btn-sm mt-3"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    <i
                      id={`icon_upload`}
                      className="fa fa-upload fa-1x me-1"
                      aria-hidden="true"
                    ></i>{" "}
                    Document
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group col-md-6 col-xs-4  col-lg-2 px-4">
              <button
                type="button"
                className="btn btn-primary btn-sm mt-3"
                onClick={() => {
                  setIsModelEnabled(true);
                }}
              >
                <i className="fa fa-camera me-1" aria-hidden="true"></i>Picture
              </button>
            </div>
          </div>

          {documentList.length > 0 && (
            <>
              <div className="row col-lg-12 justify-content-center">
                DOCUMENTS
              </div>
              <div className="row card-border">
                <DocumentHorizontal
                  itemId="checkout"
                  id={`documents_horizontal`}
                  documentList={documentList}
                  onDeleteDocument={handleDeleteDocument}
                  onUpdateDocComment={handleUpdateDocumentComment}
                />
              </div>
            </>
          )}
          {pictureList.length > 0 && (
            <div className="row col-lg-12 justify-content-center">PICTURES</div>
          )}
          <div className={pictureList.length > 0 ? "row card-border" : ""}>
            <div className="form-row align-items-end">
              <div className="form-group col-12 px-4">
                <WebcamCapture
                  itemId="checkout"
                  pictureList={pictureList}
                  onCapturePicture={(picture, itemId) =>
                    onCapturePicture(picture, itemId)
                  }
                  onDeletePicture={(index, itemId) =>
                    OnDeletePicture(index, itemId)
                  }
                  isModelEnabled={isModelEnabled}
                  disableModal={() => {
                    setIsModelEnabled(false);
                  }}
                ></WebcamCapture>
              </div>
            </div>
          </div>
          <PerformedBy />
          <div id="submitButtonAreaRecordAudit">
            <button
              className="btn  btn-sm btn-primary"
              id="cancel_checkout"
              type="reset"
              value="Reset"
              style={{ color: "#fff" }}
              onClick={() => {
                navigate("/viewAllCheckout");
              }}
            >
              CANCEL
            </button>
            <button
              type="file"
              className="btn btn-sm btn-primary"
              onClick={handleSubmit}
              id="submit_checkout"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </>
    );
  }
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 body">
      <div className="row flex p-4 ">
        <div className="col-lg-12 pb-3 pl-0 pr-0 overflow-hidden">
          <div className="row pt-2 pb-4 mt-5 ">
            <div className="col-lg-6 col-md-10"></div>
            <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 "></div>
            <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 ">
              {currentUser?.check_out_save_bulk_upload_access_flag && (
                <Link to="/bulkCheckOut">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm col-lg-11 col-md-11 col-sm-11 m-0 float-end"
                    id="bulk-checkin-button"
                  >
                    BULK CHECK-OUT
                  </button>
                </Link>
              )}
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 ">
              {" "}
              <Link to="/viewAllCheckout">
                <button
                  type="button"
                  className="btn btn-primary btn-sm col-lg-11 col-md-11 col-sm-11 m-0 float-end"
                  id="checkin-records-button"
                >
                  CHECK-OUT RECORDS
                </button>
              </Link>
            </div>
          </div>
          <MDBCard className="card h-md-10 p-0 m-0">
            <CustomHeader label={"SINGLE CHECK-OUT"} />
            <MDBCardBody id="cs-scroll">
              <div className="pb-2 col-md-12 col-sm-12 col-lg-12 col-xs-12 parentRadioDiv">
                <div className="col-md-3 col-sm-3 col-lg-3 col-xs-3">
                  <input
                    id="radio_label_over_to_customer"
                    type="radio"
                    name="checkout_type"
                    defaultChecked
                    value="Handing over to Customer"
                    onChange={(e) => {
                      setRadio(e.target.value);
                      setFormErrors({});
                      setformHeader("CUSTOMER DETAILS");
                      setTopRightBtn(false);
                      setFormValues({});
                    }}
                  />
                  <label
                    className="radio_label_single_check_out"
                    htmlFor="radio_label_over_to_customer"
                  >
                    Handing over to Customer
                  </label>
                </div>
                <div className="col-md-3 col-sm-3 col-lg-3 col-xs-3">
                  <input
                    id="radio_label_over_to_state"
                    type="radio"
                    name="checkout_type"
                    value="Handing over to the State"
                    onChange={(e) => {
                      setRadio(e.target.value);
                      setFormErrors({});
                      setFormValues({});
                      setformHeader("STATE PERSON / ENTITY DETAILS");
                      setTopRightBtn(true);
                    }}
                  />
                  <label
                    htmlFor="radio_label_over_to_state"
                    className="radio_label_single_check_out"
                  >
                    Handing over to the State
                  </label>
                </div>
                <div className="col-md-3 col-sm-3 col-lg-3 col-xs-3">
                  <input
                    id="radio_label_over_to_auctioneer"
                    type="radio"
                    name="checkout_type"
                    value="Handing over to Auctioneer"
                    onChange={(e) => {
                      setRadio(e.target.value);
                      setformHeader("AUCTIONEER / ENTITY DETAILS");
                      setTopRightBtn(true);
                      setFormErrors({});
                      setFormValues({});
                    }}
                  />
                  <label
                    id="radio_label_over_to_auctioneer"
                    htmlFor="radio_label_over_to_auctioneer"
                    className="radio_label_single_check_out"
                  >
                    Handing over to Auctioneer
                  </label>
                </div>
                <div className="col-md-3 col-sm-3 col-lg-3 col-xs-3">
                  <input
                    id="radio_label_destory_contents"
                    type="radio"
                    name="checkout_type"
                    value="Destroy Contents"
                    onChange={(e) => {
                      setRadio(e.target.value);
                      setTopRightBtn(false);
                      setFormErrors({});
                      setFormValues({});
                    }}
                  />
                  <label
                    className="radio_label_single_check_out"
                    htmlFor="radio_label_destory_contents"
                  >
                    Destroy Contents
                  </label>
                </div>
              </div>
              {radio === "Destroy Contents" && destroyContForm()}
              {radio === "Handing over to Customer" && commonForm()}
              {radio === "Handing over to the State" && commonForm()}
              {radio === "Handing over to Auctioneer" && commonForm()}
            </MDBCardBody>
          </MDBCard>
        </div>
      </div>
    </div>
  );
}
export default CreateCheckOut;
