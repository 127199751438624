export const MAX_START_DATE = 0;

export const MIN_START_DATE = -180;

export const MAX_END_DATE = 0;

export const MIN_END_DATE = -180;

export const REPORT_TYPE_DATE_RANGE_OPTION = [
  "Checked In Bags",
  "Checked Out Bags",
  "Time in warehouse",
  "Inventory Cycle Count",
];

export const REPORT_ATTRIBUTIES = [
  "TAMPER EVIDENT BAG #",
  "LOCATION OF CONTENTS",
  "OWNER FIRST NAME",
  "OWNER MIDDLE NAME",
  "OWNER LAST NAME",
  "COMPANY NAME",
  "Owner SSN/Tax ID",
  "OWNER ADDRESS",
  "OWNER CITY",
  "OWNER STATE",
  "OWNER ZIP",
  "REPORTING STATE",
  "OWNER RELATION CODE",
  "OWNER PROPERTY TYPE CODE",
  "DORMANCY ID",
  "PROPERTY STARTING TRANSACTION DATE",
  "DATE OF LAST TRANSACTION",
  "AMOUNT REPORTED",
  "BOX NUMBER",
  "BRANCH NAME",
  "BRANCH DID",
  "DATE ENTERED",
  "CONTENTS Y/N",
  "TANGIBLE INVENTORY",
  "LOUISIANA SAVINGS BOND",
  "MILITARY MEDAL",
  "SCRA",
  "BAG COUNT",
  "TAMPER EVIDENT BAG NUMBERS",
];

export const REPORT_HEADERS = [
  { label: "TAMPER EVIDENT BAG #", key: "tamper_evident_bag" },
  { label: "LOCATION OF CONTENTS", key: "location_of_contents" },
  { label: "OWNER FIRST NAME", key: "owner_first_name" },
  { label: "OWNER MIDDLE NAME", key: "owner_middle_name" },
  { label: "OWNER LAST NAME", key: "owner_last_name" },
  { label: "COMPANY NAME", key: "company_name" },
  { label: "Owner SSN/Tax ID", key: "owner_ssn_tax_id" },
  { label: "OWNER ADDRESS", key: "owner_address" },
  { label: "OWNER CITY", key: "owner_city" },
  { label: "OWNER STATE", key: "owner_state" },
  { label: "OWNER ZIP", key: "owner_zip" },
  { label: "REPORTING STATE", key: "reporting_state" },
  { label: "OWNER RELATION CODE", key: "owner_relation_code" },
  { label: "OWNER PROPERTY TYPE CODE", key: "owner_property_type_code" },
  { label: "DORMANCY ID", key: "dormancy_id" },
  {
    label: "PROPERTY STARTING TRANSACTION DATE",
    key: "property_starting_transaction_date",
  },
  { label: "DATE OF LAST TRANSACTION", key: "date_of_last_transaction" },
  { label: "AMOUNT REPORTED", key: "amount_reported" },
  { label: "BOX NUMBER", key: "box_number" },
  { label: "BRANCH NAME", key: "branch_name" },
  { label: "BRANCH DID", key: "branch_did" },
  { label: "DATE ENTERED", key: "date_entered" },
  { label: "CONTENTS Y/N", key: "contents" },
  { label: "TANGIBLE INVENTORY", key: "tangible_inventory" },
  { label: "LOUISIANA SAVINGS BOND", key: "louisiana_savings_bond" },
  { label: "MILITARY MEDAL", key: "military_medal" },
  { label: "SCRA", key: "scra" },
  { label: "BAG COUNT", key: "bag_count" },
  { label: "TAMPER EVIDENT BAG NUMBERS", key: "tamper_evident_bag_numbers" },
];
export const REPORT_TYPE_STORAGE_LOCATION_OPTION = ["Inventory Cycle Count"];

export const DISABLED_CAPACITY_ATTRIBUTES = [
  "MILITARY MEDAL",
  "LOUISIANA SAVINGS BOND",
  "LOCATION OF CONTENTS",
];

export const DISABLED_COMMON_ATTRIBUTES = [
  "TAMPER EVIDENT BAG NUMBERS",
  "BAG COUNT",
];

export const CAPACITY_REPORT = "Capacity Report";
