import { getHeader } from "../utils/Headers";
import service from "./service";
import axios from "axios";
import {
  UPDATE_WAREHOUSE_UNITS,
  STORAGE_LOCATION,
  SAVE_STORAGE_UNIT,
  DELETE_WAREHOUSE_UNITS,
  GET_STORAGE_UNITS,
  SEARCH_WAREHOUSE_UNITS,
  SEARCH_UNITS,
  WAREHOUSE_ITEM,
} from "../constants/ApiUrlConstants";

//We are using cancelTokenSource to cancel the running api call and start new api call
let cancelTokenSource = axios.CancelToken.source();

//save
export const saveStorageLocation = async (data, token) => {
  return axios
    .post(service.baseUrl + STORAGE_LOCATION, data, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    });
};

export const saveAisleBayToteApi = async (unitType, data, empId, token) => {
  return axios
    .post(
      service.baseUrl +
        SAVE_STORAGE_UNIT +
        "/warehouse?unit_type=" +
        unitType +
        "&emp_id=" +
        empId,
      data,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
export const upadateAisleBayToteApi = async (unitType, data, empId, token) => {
  return axios
    .put(
      service.baseUrl +
        UPDATE_WAREHOUSE_UNITS +
        "/" +
        unitType +
        "&emp_id=" +
        empId,
      data,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
export const deleteAisleBayToteApi = async (unitType, unitID, empId, token) => {
  return axios
    .delete(
      service.baseUrl +
        DELETE_WAREHOUSE_UNITS +
        "?unit_type=" +
        unitType +
        "&unit_id=" +
        unitID +
        "&emp_id=" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const updateStorageLocation = async (payload, token) => {
  return axios
    .patch(service.baseUrl + STORAGE_LOCATION, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    });
};

export const getStorageLocationRecords = async (
  searchValue,
  pageNo,
  pageSize,
  token,
  storageType="all_records",
) => {
  return axios
    .get(
      service.baseUrl +
        STORAGE_LOCATION +
        "?search_value=" +
        searchValue +
        "&page_no=" +
        pageNo +
        "&page_size=" +
        pageSize+
        "&storageType=" +
        storageType,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const pagination = async (pageNumber, pageCount, empId, token) => {
  return axios
    .get(
      service.baseUrl +
        STORAGE_LOCATION +
        "?page=" +
        pageNumber +
        "&records_per_page=" +
        pageCount +
        "&emp_id=" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const handleSubmitStorageUnit = async (data, empId, token) => {
  return axios
    .post(
      service.baseUrl + SAVE_STORAGE_UNIT + "/vault?emp_id=" + empId,
      data,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

// to post vault units
export const handleSubmitVaultStorageUnit = async (data, empId, token) => {
  return axios
    .post(
      service.baseUrl + SAVE_STORAGE_UNIT + "/vault?emp_id=" + empId,
      data,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
//to post branch units
export const handleSubmitBranchStorageUnit = async (data, empId, token) => {
  return axios
    .post(
      service.baseUrl + SAVE_STORAGE_UNIT + "/branch?emp_id=" + empId,
      data,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

//to get units present in Branch
export const getStorageLocationUnitbranchRecords = async (
  locID,
  empId,
  token
) => {
  return axios
    .get(
      service.baseUrl +
        GET_STORAGE_UNITS +
        "?storage_location_id=" +
        locID +
        "&storage_type=branch" +
        "&emp_id=" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
//to get units present in vault
export const getStorageLocationUnitVaultRecords = async (
  locID,
  empId,
  token
) => {
  return axios
    .get(
      service.baseUrl +
        GET_STORAGE_UNITS +
        "?storage_location_id=" +
        locID +
        "&storage_type=vault" +
        "&emp_id=" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
export const getStorageLocationUnitRecords = async (locID, empId, token) => {
  return axios
    .get(
      service.baseUrl +
        GET_STORAGE_UNITS +
        "?storage_location_id=" +
        locID +
        "&emp_id=" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
export const getWarehouseLocationAilse = async (locID, value, token) => {
  // http://localhost:8000/api/search-units?unit_id=unit_id&search_type=unit_type&search_text=search_text
  return axios
    .get(
      service.baseUrl +
        SEARCH_UNITS +
        "?unit_id=" +
        locID +
        "&unit_type=aisle&search_text=" +
        value,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
export const getWarehouseLocationBay = async (locID, value, token) => {
  return axios
    .get(
      service.baseUrl +
        SEARCH_UNITS +
        "?unit_id=" +
        locID +
        "&unit_type=bay&search_text=" +
        value,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
export const getWarehouseLocationShelf = async (locID, value, token) => {
  return axios
    .get(
      service.baseUrl +
        SEARCH_UNITS +
        "?unit_id=" +
        locID +
        "&unit_type=shelf&search_text=" +
        value,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
export const getWarehouseLocationTote = async (locID, value, token) => {
  return axios
    .get(
      service.baseUrl +
        SEARCH_UNITS +
        "?unit_id=" +
        locID +
        "&unit_type=tote&search_text=" +
        value,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
export const saveWareHouseStorageUnit = async (data, emp_id, token) => {
  return axios
    .post(
      service.baseUrl + SAVE_STORAGE_UNIT + "/warehouse?emp_id=" + emp_id,
      data,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
export const getWareHouseStorageUnitRecords = async (
  locID,
  storagetype,
  empId,
  token
) => {
  return axios
    .get(
      service.baseUrl +
        GET_STORAGE_UNITS +
        "?storage_location_id=" +
        locID +
        "&storage_type=" +
        storagetype +
        "&emp_id=" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
export const searchWareHouseStorageUnitRecords = async (
  searchText,
  storageLocationId,
  empId,
  token
) => {
  cancelTokenSource.cancel();
  cancelTokenSource = axios.CancelToken.source();

  return axios
    .get(
      service.baseUrl +
        SEARCH_WAREHOUSE_UNITS +
        "?search_text=" +
        searchText +
        "&storage_location_id=" +
        storageLocationId,
      "&emp_id=" + empId,
      { cancelToken: cancelTokenSource.token },
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )

    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "error";
    });
};

export const getWareHouseStorageUnitPagination = async (
  locID,
  storagetype,
  page,
  empId,
  token
) => {
  if (page === 1) {
    return axios
      .get(
        service.baseUrl +
          GET_STORAGE_UNITS +
          "?storage_location_id=" +
          locID +
          "&storage_type=" +
          storagetype +
          "&emp_id=" +
          empId,
        {
          headers: getHeader(token),
          validateStatus: function (status) {
            return status < 500;
          },
        }
      )
      .then((response) => {
        return response;
      });
  } else {
    return axios
      .get(
        service.baseUrl +
          GET_STORAGE_UNITS +
          "?page=" +
          page +
          "&storage_location_id=" +
          locID +
          "&storage_type=" +
          storagetype +
          "&emp_id=" +
          empId,
        {
          headers: getHeader(token),
          validateStatus: function (status) {
            return status < 500;
          },
        }
      )
      .then((response) => {
        return response;
      });
  }
};

//api's for Optimized Code
export const deleteWareHouseUnit = async (itemType, itemID, empId, token) => {
  return axios
    .delete(
      service.baseUrl +
        WAREHOUSE_ITEM +
        "?item_type=" +
        itemType +
        "&item_id=" +
        itemID +
        "&performed_by_id=" +
        empId,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const updateUnits = async (unitType, data, empId, token) => {
  return axios
    .patch(
      service.baseUrl +
      WAREHOUSE_ITEM,
      data,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const AddUnits = async (unitType, data, empId, token) => {
  return axios
    .post(
      service.baseUrl +
      WAREHOUSE_ITEM,
      data,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const getSuggestions = async (parentId, type, value, token) => {
  return axios
    .get(
      service.baseUrl +
      WAREHOUSE_ITEM +
        "?search_value=" +
        value +
        "&item_type=" +
        type +
        "&parent_id=" +
        parentId +
        "&page_no=" +
        1 +
        "&page_size=" + 20,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const getLocationUnits = async (locID, type, emp_id, token) => {
  return axios
    .get(
      service.baseUrl +
        GET_STORAGE_UNITS +
        "?storage_location_id=" +
        locID +
        "&storage_type=" +
        type +
        "&emp_id=" +
        emp_id,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const updateBranchVault = async (type, data, emp_id, token) => {
  return axios
    .post(
      service.baseUrl + SAVE_STORAGE_UNIT + type + "?emp_id=" + emp_id,
      data,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const getWareHouseUnits = async (locID, storagetype, emp_id, token) => {
  return axios
    .get(
      service.baseUrl +
        GET_STORAGE_UNITS +
        "?storage_location_id=" +
        locID +
        "&storage_type=" +
        storagetype +
        "&emp_id=" +
        emp_id,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const searchWareHouseUnits = async (
  searchText,
  itemType,
  storageLocationId,
  pageNo,
  pageSize,
  token
) => {
  cancelTokenSource.cancel();
  cancelTokenSource = axios.CancelToken.source();

  return axios
    .get(
      service.baseUrl +
        WAREHOUSE_ITEM +
        "?search_value=" +
        searchText +
        "&item_type=" +
        itemType +
        "&parent_id=" +
        storageLocationId +
        "&page_no=" +
        pageNo +
        "&page_size=" +
        pageSize,
      { cancelToken: cancelTokenSource.token },
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )

    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "error";
    });
};

export const deleteStorageLocation = async (
  storageLocId,
  storageType,
  emp_id,
  token
) => {
  return axios
    .delete(
      service.baseUrl +
        STORAGE_LOCATION +
        "?storage_location_id=" +
        storageLocId +
        "&storage_location_type=" +
        storageType +
        "&performed_by_id=" +
        emp_id,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};

export const createWarehouseItem = async (payload, token) => {
  return axios
    .post(service.baseUrl + WAREHOUSE_ITEM, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    });
};

export const updateWarehouseItem = async (payload, token) => {
  return axios
    .put(service.baseUrl + WAREHOUSE_ITEM, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    });
};

export const deleteWarehouseItem = async (
  item_type,
  item_id,
  emp_id,
  token
) => {
  return axios
    .delete(
      service.baseUrl +
        WAREHOUSE_ITEM +
        "?item_type=" +
        item_type +
        "&item_id=" +
        item_id +
        "&performed_by_id=" +
        emp_id,
      {
        headers: getHeader(token),
        validateStatus: function (status) {
          return status < 500;
        },
      }
    )
    .then((response) => {
      return response;
    });
};
