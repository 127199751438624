export const ERROR_MESSAGE = "Something went wrong, please try again.";
export const AUDIT_ELIGIBILITY_MESSAGE_P1 =
  "You are previous auditor for this Tamper Evident Bag";
export const AUDIT_ELIGIBILITY_MESSAGE_P2 =
  ".  Auditor rotation such that no one person conducts the audit more than once in consecutive audits.\n Please select another Tamper Evident Bag.";
export const FORCE_OPEN_ABANDON_PROP_EMAIL_MESSAGE =
  "*******Send signed copy to AbandonedProperty@capitalone.com within 24 hours*******";
export const FORCE_OPEN_CONFIRMATION_MSG_2 =
  "Click on Upload Signed Forms to upload the Signed forms and then click on SUBMIT to Complete the FORCE OPEN";
export const FORCE_OPEN_CONFIRMATION_MSG_1 =
  "Click On Print Forms to Download and Sign";
export const SAFE_DEPOSIT_BOX_NOT_FOUND = "Safe Deposit Box not exists";
export const ADD_ADDENDUM_CATEGORY_SUCCESS = "Addendum Category Added Successfully";
export const ADD_ADDENDUM_CATEGORY_FAILED = "Failed to Add New Addendum Category";
export const ADD_ADDENDUM_ITEM_SUCCESS = "Addendum Item Added Successfully";
export const ADD_ADDENDUM_ITEM_FAILED = "Failed to Add New Addendum Item";
export const UPDATE_ADDENDUM_ITEM_SUCCESS = "Addendum Item Updated Successfully";
export const UPDATE_ADDENDUM_ITEM_FAILED = "Failed to Update Addendum Item";
export const DELETE_ADDENDUM_ITEM_SUCCESS = "Addendum Item Deleted Successfully";
export const DELETE_ADDENDUM_ITEM_FAILED = "Failed to Delete Addendum Item";

export const ROLE_FLAGS_UPDATED_SUCCESS = "Successfully Updated the Roles"
export const ROLE_FLAGS_UPDATED_FAILED = "Role Flag Updation Failed"
export const ADD_ADDENDUM_VALUE_SUCCESS = "Addendum Item and value Added Successfully";
export const ADD_ADDENDUM_VALUE_FAILED = "Failed to Add New Addendum Itemn and value";