import React from "react";
import { Box, Grid, Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "../style/Pager.css";

const TablePageOptions = [5, 10, 20];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .Mui-selected": {
      backgroundColor: "transparent",
      color: "white",
    },
  },
}));

export const Pager = ({
  currentPage,
  totalPages,
  onPageChange,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className="divMainPager p-1">
        <div className="divRowPerPage">
          {rowsPerPage && setRowsPerPage ? (
            <Grid item>
              <Box className="boxData" lineHeight={2}>
                <label className="lblRecords">Records Per Page :</label>

                <select
                  className="ddlSelectPage"
                  value={rowsPerPage}
                  aria-label="Default select example"
                  onChange={(event) => {
                    setRowsPerPage(Number(event.target.value));
                  }}
                >
                  {TablePageOptions &&
                    TablePageOptions.map((ele) => (
                      <option style={{ color: "white" }} key={ele} value={ele}>
                        {`${ele} `}
                      </option>
                    ))}
                </select>
              </Box>
            </Grid>
          ) : null}
        </div>
        <div className="divPaging">
          <Box lineHeight={2}>
            <Pagination
              className={classes.root}
              count={totalPages}
              page={currentPage}
              siblingCount={0}
              onChange={(_, value) => onPageChange(value)}
              showFirstButton
              showLastButton
            />
          </Box>
        </div>
      </div>
    </>
  );
};
