import { Modal } from "react-bootstrap";
import { MDBCard, MDBCardBody, MDBCardHeader } from "mdbreact";
import ConfigData from "../config";


const WarehouseModal = ({
  dropDownType,
  dropdownSearch,
  clearData,
  showUnits,
  locationDetails,
  onClickSubmit
}) => {
  return (
    <Modal
      className="modal-dialog modal-xl"
      show={dropDownType.length > 0 ? true : false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <MDBCard>
        <MDBCardHeader className="card text-white justify-content-center form-heading text-center  mb-3">
          <div>
            <h6 className="p-0 m-0">
              {dropDownType === "Edit Unit"
                ? "UPDATE STORAGE UNIT (UNIT)"
                : dropDownType === "Add Unit"
                  ? "ADD STORAGE UNIT (UNIT)"
                  : dropDownType === "Delete Unit"
                    ? "DELETE STORAGE UNIT (UNIT)"
                    : dropDownType === "Edit Tote"
                      ? "UPDATE STORAGE UNIT (TOTE)"
                      : dropDownType === "Add Tote"
                        ? "ADD STORAGE UNIT (TOTE)"
                        : dropDownType === "Delete Tote"
                          ? "DELETE STORAGE UNIT (TOTE)"
                          : dropDownType === "Add Aisle"
                            ? "ADD STORAGE UNIT (AISLE)"
                            : dropDownType === "Edit Aisle"
                              ? "UPDATE STORAGE UNIT (AISLE)"
                              : dropDownType === "Delete Aisle"
                                ? "DELETE STORAGE UNIT (AISLE)"
                                : dropDownType === "Add Bay"
                                  ? "ADD STORAGE UNIT (BAY)"
                                  : dropDownType === "Edit Bay"
                                    ? "UPDATE STORAGE UNIT (BAY)"
                                    : dropDownType === "Delete Bay"
                                      ? "DELETE STORAGE UNIT (BAY)"
                                      : dropDownType === "Add Shelf"
                                        ? "ADD STORAGE UNIT (SHELF)"
                                        : dropDownType === "Edit Shelf"
                                          ? "UPDATE STORAGE UNIT (SHELF)"
                                          : dropDownType === "Delete Shelf" &&
                                          "DELETE STORAGE UNIT (SHELF)"} 
            </h6>
          </div>
        </MDBCardHeader>

        <MDBCardBody>
          {dropDownType === "Edit Tote" ||
            dropDownType === "Add Tote" ||
            dropDownType === "Delete Tote" ? (
            <div className="ms-scroll">
              {dropdownSearch("Unit", "Search for Unit Name", "Unit")}
              {dropdownSearch("AISLE ID", "Search for Aisle Id", "Aisle")}

              {dropdownSearch("BAY ID", "Search for Bay Id", "Bay")}

              {dropdownSearch("SHELF ID", "Search for Shelf Id", "Shelf")}

              {dropdownSearch(
                dropDownType === "Edit Tote"
                  ? "OLD TOTE ID"
                  : "TOTE ID",

                dropDownType === "Edit Tote"
                  ? "Search for Tote Id"
                  : "Enter the Tote Id",
                "Tote"
              )}
              {dropDownType === "Edit Tote" &&
                dropdownSearch("NEW TOTE ID", "Enter the Tote Id", "New Tote")}
            </div>
          ) : null}

          {dropDownType === "Edit Unit" ||
            dropDownType === "Add Unit" ||
            dropDownType === "Delete Unit" ? (
            <div className="col-lg-12 form-group row  pt-3">
              <div className="form-group row">
                      <label className="col-sm-3 col-form-label pl-5 input-label">
                        Name
                      </label>
                      <div className="col-sm-8 pt-1">
                        {locationDetails?.name}
                      </div>
                    </div>
              {dropdownSearch(
                dropDownType === "Edit Unit"
                  ? "OLD Unit ID"
                  : "Unit ID",
                dropDownType === "Edit Unit"
                  ? "Search for Unit Id"
                  : "Enter the Unit Id",
                "Unit")}
              {dropDownType === "Edit Unit" &&
                dropdownSearch(
                  "NEW UNIT ID",
                  "Enter the Unit Id",
                  "New Unit"
                )}
            </div>
          ) : null}

          {dropDownType === "Edit Aisle" ||
            dropDownType === "Add Aisle" ||
            dropDownType === "Delete Aisle" ? (
            <div className="col-lg-12 form-group row  pt-3">
              {/* {Object.entries(ConfigData.aisleLocationDetails).map(
                ([key, value]) => {
                  return (
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label pl-5 input-label">
                        {value}
                      </label>
                      <div className="col-sm-8 pt-1">
                        {locationDetails[key]}
                      </div>
                    </div>
                  );
                }
              )} */}
              {dropdownSearch("Unit", "Search for Unit Name", "Unit")}
              {dropdownSearch(
                dropDownType === "Edit Aisle"
                  ? "OLD AISLE ID"
                  : "AISLE ID",
                dropDownType === "Edit Aisle"
                  ? "Search for Aisle Id"
                  : "Enter the Aisle Id",
                "Aisle")}
              {dropDownType === "Edit Aisle" &&
                dropdownSearch(
                  "NEW AISLE ID",
                  "Enter the Aisle Id",
                  "New Aisle"
                )}
            </div>
          ) : null}

          {dropDownType === "Edit Bay" ||
            dropDownType === "Add Bay" ||
            dropDownType === "Delete Bay" ? (
            <div>
              {dropdownSearch("Unit", "Search for Unit Name", "Unit")}
              {dropdownSearch("AISLE ID", "Search for Aisle Id", "Aisle")}
              {dropdownSearch(
                dropDownType === "Edit Bay"
                  ? "OLD BAY ID"
                  : "BAY ID",
                dropDownType === "Edit Bay"
                  ? "Search for Bay Id"
                  : "Enter the BAY Id",
                "Bay")}
              {dropDownType === "Edit Bay" &&
                dropdownSearch("NEW BAY ID", "Enter the BAY Id", "New Bay")}
            </div>
          ) : null}

          {dropDownType === "Edit Shelf" ||
            dropDownType === "Add Shelf" ||
            dropDownType === "Delete Shelf" ? (
            <div>
              {dropdownSearch("Unit", "Search for Unit Name", "Unit")}
              {dropdownSearch("AISLE ID", "Search for Aisle Id", "Aisle")}
              {dropdownSearch("BAY ID", "Search for BAY Id", "Bay")}
              {dropdownSearch(
                dropDownType === "Edit Shelf"
                  ? "OLD SHELF ID"
                  : "Shelf ID",
                dropDownType === "Edit Shelf"
                  ? "Search for Shelf Id"
                  : "Enter the Shelf Id",
                "Shelf")}
              {dropDownType === "Edit Shelf" &&
                dropdownSearch("NEW SHELF ID", "Enter the Shelf Id", "New Shelf")}
            </div>
          ) : null}

          <div className="fieldArea7 p-3">
            <button
              className="btn  btn-sm btn-primary"
              onClick={() => {
                clearData(dropDownType.split(" ")[1], "CANCEL");
              }}
            >
              CANCEL
            </button>
            <button
              className="btn  btn-sm btn-primary"
              onClick={() => {
                onClickSubmit(dropDownType.split(" ")[1], dropDownType.split(" ")[0])
              }}
            >
              {" "}
              {dropDownType === "Add Tote" ||
                dropDownType === "Add Aisle" ||
                dropDownType === "Add Bay" ||
                dropDownType === "Add Shelf" ||
                dropDownType === "Add Unit"
                ? "ADD"
                : dropDownType === "Edit Tote" ||
                  dropDownType === "Edit Aisle" ||
                  dropDownType === "Edit Bay" ||
                  dropDownType === "Edit Shelf" ||
                  dropDownType === "Edit Unit"
                  ? "UPDATE"
                  : (dropDownType === "Delete Tote" ||
                    dropDownType === "Delete Aisle" ||
                    dropDownType === "Delete Bay" ||
                    dropDownType === "Delete Unit" ||
                    dropDownType === "Delete Shelf") &&
                  "DELETE"}{" "}
            </button>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                clearData(dropDownType.split(" ")[1], "CANCEL");
                showUnits();
              }}
            >
              VIEW UNITS
            </button>
          </div>
        </MDBCardBody>
      </MDBCard>
    </Modal>
  );
};

export default WarehouseModal;
