import axios from "axios";
import service from "./service";
import { getHeader } from "../utils/Headers";
import {
  POST_ADDENDUM_CATEGORY,
  POST_ADDENDUM_ITEM,
  PUT_ADDENDUM_ITEM,
  DELTE_ADDENDUM_ITEM,
} from "../constants/ApiUrlConstants";

export const postAddendumCategory = async (payload, token) => {
  return axios
    .post(service.baseUrl + POST_ADDENDUM_CATEGORY, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const postAddendumItem = async (payload, token) => {
  return axios
    .post(service.baseUrl + POST_ADDENDUM_ITEM, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateAddendumItem = async (payload, token) => {
  return axios
    .put(service.baseUrl + PUT_ADDENDUM_ITEM, payload, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteAddendumItem = async (item_id, token) => {
  return axios
    .delete(service.baseUrl + DELTE_ADDENDUM_ITEM + "?item_id=" + item_id, {
      headers: getHeader(token),
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
