import { MDBCard } from "mdbreact";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { handleSubmitBulkCheckIn } from "../api/BulkCheckinServices";
import { CustomAlert } from "../components/customAlert";
import CustomHeader from "../components/CustomHeader";
import CustomModal from "../components/CustomModal";
import CustomStepper from "../components/StepperComponent";
import "../style/customalert.css";
import StepOne from "./BulkCheckInDetails/Step1";
import StepTwo from "./BulkCheckInDetails/Step2";
import StepThree from "./BulkCheckInDetails/Step3";
import { cognitoSignOut } from "../api/CognitoServices";
import { useSelector } from "react-redux";

const BulkCheckIn = (props) => {
  let navigate = useNavigate();
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  const [currentEventData, setCurrentEventData] = useState({
    EventType: "Alert",
    EventData: "",
  });
  const [showCommentHistory, setShowCommentHistory] = useState(false);
  const steps = ["Section I", "Section II", "Section III"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setFormData] = useState({
    step1: {
      fileuploadrows: [],
      wsFileName: "",
    },
    step2: {
      activityType: true,
      storageLoc: "",
      storagerows: [],
      comments: "",
      // TO-DO check variable name camelcasing
      EmpID: currentUser?.employee_id,
      EmpName: currentUser.eployee_name,
      EmpDesignation: currentUser.employee_designation,
    },
    step3: {
      Documentrows: [],
    },
  });
  const handleChange = (step, key, value) => {
    const data = { ...formData };
    const selectedStep = { ...data[step] };
    selectedStep[key] = value;
    if (step === "step2" && key === "storageLoc") {
      selectedStep["storagerows"] = [{ storageunitid: "", storageunitddl: 0 }];
    }
    data[step] = selectedStep;
    setFormData(data);
  };
  const showAlert = () => {
    let title = "Closing";
    let text = "Redirect to checkin records";
    let type = "info";
    let confirmButtonText = "Ok";
    let showCancelButton = true;
    let target = document.getElementById("swal-div");
    // let target = "swal-div";
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      target
    ).then((result) => {
      if (result.value) {
        navigate("/viewAllCheckIn");
      }
    });
  };

  const handleSave = () => {
    let fileuploadrowsdata = formData?.step1?.fileuploadrows;
    fileuploadrowsdata = fileuploadrowsdata.map((filedata) => {
      return {
        safedepositbox_number: filedata.SDBoxNo.toString(),
        tamper_evident_bag: filedata.TamperEvidentBagNo,
      };
    });

    let storagedata = {
      storage_location_id: formData.step2.storageLoc,
      activity_type: formData.step2.activityType
        ? "force open"
        : "shipped content",
      status: "open",
      notes: formData.step2.comments,
    };
    let docData = formData?.step3?.Documentrows;
    docData = docData.map((docRowsData) => {
      return {
        documents: docRowsData.imgSource,
        description: docRowsData.ImgComments.toString(),
      };
    });

    const dataSource = {
      bulk_data: fileuploadrowsdata,
      checkin_data: storagedata,
      documents_data: docData,
    };

    handleSubmitBulkCheckIn(
      JSON.stringify(dataSource),
      currentUser?.employee_id,
      currentUser?.jwtToken
    )
      .then((response) => {
        if (response.status === 401) {
          cognitoSignOut()
            .then(() => {
              navigate("/");
            })
            .catch((err) => {
              console.error(err);
            });
        } else if (response.status === 201) {
          setCurrentEventData({
            EventType: " ",
            EventData: "Record Saved Successfully",
          });
        } else {
          setCurrentEventData({
            EventType: "Alert",
            EventData: "Please upload proper data",
          });
        }
        setShowCommentHistory(true);
      })
      .catch((err) => {
        setCurrentEventData({
          EventType: "Alert",
          EventData: "No Data Available",
        });
        setShowCommentHistory(true);
      });
  };

  const renderActiveStepComponent = () => {
    switch (activeStep) {
      case 0:
        return <StepOne formData={formData} onChange={handleChange} />;

      case 1:
        return <StepTwo formData={formData} onChange={handleChange} />;
      case 2:
        return <StepThree formData={formData} onChange={handleChange} />;
      default:
        break;
    }
  };
  // const isDisabled = () => {
  //   switch (activeStep) {
  //     case 0:
  //       return formData?.step1.fileuploadrows?.length;
  //     case 1:
  //       return formData?.step2.storageLoc?.length;
  //     case 2:
  //       return formData?.step3.Documentrows?.length;
  //     default:
  //       return true;
  //   }
  // };
  return (
    <>
      <CustomModal
        currentEventData={currentEventData}
        show={showCommentHistory}
        onHide={() => setShowCommentHistory(false)}
        label2={"Go to  VIEW CHECK-IN RECORDS"}
        label2Callback={() => navigate("/viewAllCheckIn")}
      />
      <div className="col-lg-12 col-md-12 col-sm-12 h-md-100 p-0 ">
        <div className="row flex p-4 " id="swal-div">
          <div className="col-lg-12 col-md-12 col-sm-12 pb-3 pl-0 pr-0 overflow-hidden">
            <div className="row pt-2 pb-4">
              <div className="col-lg-6 col-md-10"></div>
              <div
                className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 "
                id="btns-checkin"
              >
                {currentUser?.check_in_save_bulk_upload_access_flag && (
                  <Link to="/customBulkCheckin">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm col-lg-11 col-md-11 col-sm-11 m-0 float-end"
                      id="bulk-checkin-button"
                    >
                      CUSTOM BULK CHECK-IN
                    </button>
                  </Link>
                )}
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 ">
                {currentUser?.check_in_save_record_access_flag && (
                  <Link to="/singleCheckIn">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm col-lg-11 col-md-11 col-sm-11 m-0 float-end"
                      id="bulk-checkin-button"
                    >
                      SINGLE CHECK-IN
                    </button>
                  </Link>
                )}
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 pl-0 pr-0 ">
                {" "}
                <Link to="/viewAllCheckIn">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm col-lg-11 col-md-11 col-sm-11 m-0 float-end"
                    id="checkin-records-button"
                  >
                    CHECK-IN RECORDS
                  </button>
                </Link>
              </div>
            </div>
            <MDBCard className="card p-0 m-0">
              <CustomHeader label={"Bulk Check-In"} />
              <CustomStepper steps={steps} activeStep={activeStep} />
              {renderActiveStepComponent()}
              <div className="fieldArea7 pt-2 pb-2 justify-content-center">
                {!activeStep ? (
                  <button
                    type="file"
                    className="btn  btn-sm btn-primary"
                    onClick={() => {
                      showAlert();
                    }}
                  >
                    CLOSE
                  </button>
                ) : (
                  <></>
                )}
                {activeStep !== 0 ? (
                  <button
                    type="file"
                    className="btn  btn-sm btn-primary"
                    onClick={() => {
                      setActiveStep((prev) => prev - 1);
                    }} // setActiveStep((prev) => prev + 1);
                  >
                    PREVIOUS
                  </button>
                ) : (
                  <></>
                )}
                {activeStep === 2 ? (
                  <button
                    type="file"
                    className="btn  btn-sm btn-primary"
                    onClick={handleSave}
                    // setActiveStep((prev) => prev + 1);
                  >
                    SUBMIT
                  </button>
                ) : (
                  <></>
                )}

                {activeStep < 2 ? (
                  <button
                    type="file"
                    className="btn  btn-sm btn-primary"
                    onClick={() => {
                      setActiveStep((prev) => prev + 1);
                    }}
                  >
                    NEXT
                  </button>
                ) : (
                  <div></div>
                )}
              </div>
            </MDBCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkCheckIn;
