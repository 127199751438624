import React, { useState } from "react";
import "../../style/bulkshipment.css";
import DocumentHorizontal from "../../components/DocumentHorizontal";
import uuid from "react-uuid";
import { WebcamCapture } from "../../components/webcamComponent";
import {PrintDisable}  from "../../utils/PrintDisable"
import { useSelector } from "react-redux";

const StepThree = (props) => {
  const [documentList, setDocumentList] = useState([]);
  const [isModelEnabled, setIsModelEnabled] = useState(false);
  const [pictureList, setPictureList] = useState([]);

  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );
  PrintDisable(currentUser?.print_access_flag)

  const onCapturePicture = (picture, itemId) => {
    let addedImageList = [...pictureList, { src: picture, typeId: itemId }];
    setPictureList(addedImageList);
    setIsModelEnabled(false);
    props.onChange("step3", "pictureList", addedImageList);
  };

  const OnDeletePicture = (index, itemId) => {
    let shallowCopyImageList = Array.from(pictureList);
    if (index > -1) {
      shallowCopyImageList.splice(index, 1);
    }
    setPictureList(shallowCopyImageList);
  };

  /**
   * Function to read files & set to props
   */
  const handleAddDocument = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        const docSrcArray = reader.result.split(";")[0];
        let val = document.getElementById("input_document");
        let file = val.files.item(0).name;
        const documentItem = {
          documentId: uuid(),
          comments: "",
          src: reader.result,
          docType: docSrcArray,
          fileName: file,
        };
        handleNewDocument(documentItem);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  /**
   * Function to handle new documents
   */
  const handleNewDocument = (document) => {
    let addedDocumentList = [...documentList, document];
    setDocumentList(addedDocumentList);
    props.onChange("step3", "documentList", addedDocumentList);
  };
  /**
   * Function to handle delete documents
   */
  const handleDeleteDocument = (documentId) => {
    let shallowCopyDocList = Array.from(documentList);
    const docIndex = shallowCopyDocList.findIndex(
      (doc) => doc.documentId === documentId
    );

    if (docIndex > -1) {
      shallowCopyDocList.splice(docIndex, 1);
    }
    setDocumentList(shallowCopyDocList);
  };

  const handleUpdateDocumentComment = (documentId, value) => {
    let shallowCopyDocumentList = Array.from(documentList);
    const docIndex = shallowCopyDocumentList.findIndex(
      (doc) => doc.documentId === documentId
    );
    shallowCopyDocumentList[docIndex].comments = value;
    setDocumentList(shallowCopyDocumentList);
  };

  return (
    <>
      <div className="divContent">
        <div className="d border divMainBorder h-100">
          <div style={{ overflow: "hidden auto" }}>
            <div className="row" id={`document_group_row`}>
              <div
                className="col-lg-12 col-md-12 col-sm-12"
                id={`document_group_row_col`}
              >
                <input
                  type="file"
                  id={`input_document`}
                  multiple
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleAddDocument(e);
                  }}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                ></input>

                <label
                  htmlFor={`input_document`}
                  className="btn btn-primary btn-sm mt-3"
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  <i
                    id={`icon_upload`}
                    className="fa fa-upload fa-1x"
                    aria-hidden="true"
                  ></i>{" "}
                  Add Document
                </label>
              </div>
            </div>
          </div>
          <DocumentHorizontal
            itemId="shipment"
            id={`documents_horizontal`}
            documentList={documentList}
            onDeleteDocument={handleDeleteDocument}
            onUpdateDocComment={handleUpdateDocumentComment}
          />

          <div className="form-group col-md-6 col-xs-4  col-lg-2 px-4">
            <button
              type="button"
              className="btn btn-primary btn-sm mt-3"
              onClick={() => {
                setIsModelEnabled(true);
              }}
            >
              <i className="fa fa-camera " aria-hidden="true"></i> Add Picture
            </button>
          </div>

          <div className="form-row align-items-end">
            <div className="form-group col-12 px-4">
              <WebcamCapture
                itemId="shipment"
                pictureList={pictureList}
                onCapturePicture={(picture, itemId) =>
                  onCapturePicture(picture, itemId)
                }
                onDeletePicture={(index, itemId) =>
                  OnDeletePicture(index, itemId)
                }
                isModelEnabled={isModelEnabled}
                disableModal={() => {
                  setIsModelEnabled(false);
                }}
              ></WebcamCapture>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepThree;
