import { useState } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveSystemUser } from "../api/SystemUserService";
import { CustomAlert } from "../components/customAlert";
import { ConsoleError } from "../utils/ErrorUtils";

function ProductSetUp() {
  const [systemUserName, setSystemUserName] = useState("");
  const [systemUserPswd1, setSystemUserPswd1] = useState("");
  const [systemUserPswd2, setSystemUserPswd2] = useState("");
  //eslint-disable-next-line
  const [formErrors, setFormErrors] = useState({});
  const currentUser = useSelector(
    (reduxData) => reduxData?.userReducer?.value?.userDetails
  );

  let navigate = useNavigate();

  const productSetUpAlert = (response) => {
    let title = "System User Creation.";
    let text = response?.data?.message;
    let type = response?.data?.status === "FAILURE" ? "error" : "success";
    let confirmButtonText = "OK";
    let showCancelButton = false;
    let cancelButtonText = "CANCEL";
    let target = document.getElementById("swal-div");
    CustomAlert(
      title,
      text,
      type,
      confirmButtonText,
      showCancelButton,
      cancelButtonText,
      target
    ).then((result) => {
      if (result.value === true && response?.data?.status === "SUCCESS") {
        navigate("/verify-safechief-setup");
      }
    });
  };

  const saveUser = () => {
    Swal.fire({
      title: "Processing, please wait",
      html: "loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    const payload = {
      system_user_name: systemUserName,
      system_user_password: systemUserPswd1,
      employee_id: currentUser.employee_id,
    };
    saveSystemUser(payload, currentUser?.jwtToken)
      .then((response) => {
        Swal.close();
        if (response.status === 401) {
        } else if (response.status === 201 || response.status === 200) {
          productSetUpAlert(response);
        }
      })
      .catch((error) => {
        ConsoleError(error);
      });
  };

  function validateSystemUserName(e) {
    setSystemUserName(e.target.value);
  }

  function validateSystemUserPassword1(e) {
    setSystemUserPswd1(e.target.value);
    if (e.target.value.length < 8) {
      formErrors.password = "Enter a password of minimum length 8.";
    } else {
      formErrors.password = "";
    }
  }

  const validateSystemUserPassword2 = (e) => {
    setSystemUserPswd2(e.target.value);
    if (systemUserPswd1 !== e.target.value) {
      formErrors.re_enter_password = "Password's do not match.";
    } else {
      formErrors.re_enter_password = "";
    }
    if (e.target.value.length < 8) {
      formErrors.re_enter_password = "Enter a password of minimum length 8.";
    } else {
      formErrors.re_enter_password = "";
    }
  };
  return (
    <>
      <div className="d-md-flex align-items-center justify-content-center pl-5 pr-5 pt-5 row">
        <div className="align-items-center pt-5">
          <div className="row white-background">
            <div className="col-lg-12 py-3 ">
              Please create your sytem user. Already created one, continue{" "}
              <a href="/verify-safechief-setup" className="link">
                here
              </a>
            </div>
          </div>
          <form name="create_system_user">
            <div className="row white-background">
              <div className="col-lg-4 py-3 ">
                <label className="pr-2 float-left">System User Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={systemUserName}
                  onChange={validateSystemUserName}
                />
              </div>
            </div>
            <div className="row white-background">
              <div className="col-lg-4 py-3 ">
                <label className="pr-2 float-left" htmlFor="password">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={systemUserPswd1}
                  onChange={validateSystemUserPassword1}
                />
              </div>
              <div className="error-message-validation">
                {formErrors.password}
              </div>
            </div>
            <div className="row white-background">
              <div className="col-lg-4 py-3 ">
                <label className="pr-2 float-left" htmlFor="re_enter_password">
                  Re-Enter Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="re_enter_password"
                  value={systemUserPswd2}
                  onChange={validateSystemUserPassword2}
                />
              </div>
              <div className="error-message-validation">
                {formErrors.re_enter_password}
              </div>
            </div>

            <div className="row white-background">
              <div className="col-lg-4 py-3">
                <button
                  type="button"
                  className="btn btn-primary btn-sm float-right"
                  onClick={saveUser}
                >
                  SAVE
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ProductSetUp;
